<template>
    <div class="col responsive-width">
        <div class="container-fluid">
          <div class="content">
            <div class="headers d-flex">
              <h1 class="mb-0">Customers</h1>
              <h6>Create new customer</h6>
            </div>
            <div class="customer-showcase">
              <h2>Customer 134 - John Doe</h2>
              <ul class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center">
                <li><router-link :to="{ name : 'createCustomers'}">Basic Details</router-link></li>
                <li class="active"> <router-link :to="{ name : 'createInvoice' }">Invoice Details</router-link></li>
              </ul>
            </div>
            <div class="box invoice-details">
              <h3>Invoice Details</h3>
              <form class="row">
                <div class="col">
                  <h4>Payment and delivery terms</h4>
                  <div class="desc"> 
                    <label>Payment terms</label>
                    <select>
                      <option> </option>
                    </select>
                  </div>
                  <div class="desc"> 
                    <label>Delivery terms</label>
                    <select>
                      <option></option>
                    </select>
                  </div>
                  <div class="desc"> 
                    <label>Delivery method</label>
                    <select>
                      <option></option>
                    </select>
                  </div>
                  <!-- <div class="desc"> 
                    <label>Interest billing</label>
                    <div class="radio">
                      <input type="radio" value="yes" name="interest-billing" id="billing-yes" checked>
                      <label for="billing-yes">Yes</label>
                      <input type="radio" value="no" name="interest-billing" id="billing-no">
                      <label for="billing-no">No</label>
                    </div>
                  </div> -->
                </div>
                <div class="col">
                  <h4>Invoicing</h4>
                  <div class="parent-small d-flex">
                    <div class="desc"> 
                      <label>Price list</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div>
                    <div class="desc"> 
                      <label>Currency</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div>
                  </div>
                  <div class="desc"> 
                    <label>Invoice discount (%)</label>
                    <input type="text" value="">
                  </div>
                  <div class="parent-small d-flex">
                    <div class="desc"> 
                      <label>Invoice charge</label>
                      <input class="small" type="text" value="">
                    </div>
                    <div class="desc"> 
                      <label>Shipping charge</label>
                      <input class="small" type="text" value="">
                    </div>
                  </div>
                  <div class="desc">
                    <label>Prices incl VAT</label>
                    <div class="radio">
                      <input type="radio" value="yes" name="price-vat" id="vat-yes" checked>
                      <label for="vat-yes">Yes</label>
                      <input type="radio" value="no" name="price-vat" id="vat-no">
                      <label for="vat-no">No</label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <h4>References</h4>
                  <div class="desc"> 
                    <label>Our reference</label>
                    <input type="text" value="">
                  </div>
                  <div class="desc"> 
                    <label>Your reference</label>
                    <input type="text" value="">
                  </div>
                  <div class="desc"> 
                    <label>Customer manage</label>
                    <input type="text" value="">
                  </div>
                  <div class="desc"> 
                    <label>External reference</label>
                    <input type="text" value="">
                  </div>
                </div>
                <div class="col">
                  <h4>Accounting</h4>
                  <div class="desc"> 
                    <label>Web address</label>
                    <input type="text">
                  </div>
                  <div class="desc"> 
                    <label>Type of VAT</label>
                    <select>
                        <option value="SEVAT">SE</option>
                        <option value="SEREVERSEDVAT">SE reverse Vat</option>
                        <option value="EUREVERSEDVAT">EU reverse Vat</option>
                        <option value="EUVAT">Subject to EU VAT</option>
                        <option value="EXPORT">Export</option>
                    </select>
                  </div>
                  <div class="parent-small d-flex">
                    <div class="desc"> 
                      <label>Sales account</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div>
                    <div class="desc"> 
                      <label>VAT number</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div>
                  </div>
                  <div class="parent-small d-flex">
                    <div class="desc"> 
                      <label>Cost centre (CC)</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div>
                    <div class="desc"> 
                      <label>Project (Pr)</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="box">
              <div class="row g-0">
                <div class="col col-xl-9 e-invoice-details">
                  <h3>E-Invoice Details</h3>
                  <form class="row">
                    <div class="col-xl-4 col d-flex flex-wrap">
                      <div class="headers-e w-100">
                        <h4>E-document</h4><span>(Invoice)</span>
                      </div>
                      <div class="desc"> 
                        <label>Default delivery type</label>
                        <div class="radio">
                          <input type="radio" value="email" name="delivery-type" id="type-email" checked>
                          <label for="type-email">Email</label>
                          <input type="radio" value="print" name="delivery-type" id="type-print">
                          <label for="type-print">Print</label>
                          <input type="radio" value="e-invoice" name="delivery-type" id="type-invoice">
                          <label for="type-invoice">E-invoice</label>
                        </div>
                      </div>
                      <div class="desc"> 
                        <label>Email</label>
                        <input type="text" value="">
                      </div>
                      <div class="desc"> 
                        <label>Copy</label>
                        <input type="text" value="">
                      </div>
                      <div class="desc"> 
                        <label>Confidential copy</label>
                        <input type="text" value="">
                      </div>
                      <div class="desc"> 
                        <label>GLN number</label>
                        <input type="text" value="">
                      </div>
                      <div class="desc"> 
                        <label>GLN number for delivery</label>
                        <input type="text" value="">
                      </div>
                    </div>
                    <div class="col-xl-8 col">
                      <div class="row">
                        <div class="col d-flex flex-wrap">
                          <h4 class="w-100">Quote</h4>
                          <div class="desc"> 
                            <label>Default delivery type</label>
                            <div class="radio">
                              <input type="radio" value="email" name="delivery-type2" id="type-email2" checked>
                              <label for="type-email2">Email</label>
                              <input type="radio" value="print" name="delivery-type2" id="type-print2">
                              <label for="type-print2">Print</label>
                            </div>
                          </div>
                          <div class="desc"> 
                            <label>Email</label>
                            <input type="text" value="">
                          </div>
                          <div class="desc"> 
                            <label>Copy</label>
                            <input type="text" value="">
                          </div>
                          <div class="desc"> 
                            <label>Confidential copy</label>
                            <input type="text" value="">
                          </div>
                        </div>
                        <div class="col d-flex flex-wrap">
                          <h4 class="w-100">Order</h4>
                          <div class="desc"> 
                            <label>Default delivery type</label>
                            <div class="radio">
                              <input type="radio" value="email" name="delivery-type3" id="type-email3" checked>
                              <label for="type-email3">Email</label>
                              <input type="radio" value="print" name="delivery-type3" id="type-print3">
                              <label for="type-print3">Print</label>
                            </div>
                          </div>
                          <div class="desc"> 
                            <label>Email</label>
                            <input type="text" value="">
                          </div>
                          <div class="desc"> 
                            <label>Copy</label>
                            <input type="text" value="">
                          </div>
                          <div class="desc"> 
                            <label>Confidential copy</label>
                            <input type="text" value="">
                          </div>
                        </div>
                      </div>
                      <div class="desc">
                        <label>Invoice text</label>
                        <textarea></textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col col-xl-3">
                  <h3 class="templates">Templates</h3>
                  <form class="d-flex flex-wrap">
                    <h4 class="w-100">Preselcted templates</h4>
                    <div class="parent-small">
                      <h5 class="w-100">Invoice</h5>
                      <div class="d-flex">
                        <div class="desc"> 
                          <label>Template</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                        <div class="desc"> 
                          <label>Language</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="parent-small">
                      <h5 class="w-100">Quote</h5>
                      <div class="d-flex">
                        <div class="desc"> 
                          <label>Template</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                        <div class="desc"> 
                          <label>Language</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="parent-small">
                      <h5 class="w-100">Order</h5>
                      <div class="d-flex">
                        <div class="desc"> 
                          <label>Template</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                        <div class="desc"> 
                          <label>Language</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="parent-small">
                      <h5 class="w-100">Cash invoice</h5>
                      <div class="d-flex">
                        <div class="desc"> 
                          <label>Template</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                        <div class="desc"> 
                          <label>Language</label>
                          <select class="small">
                            <option> </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="buttons flex-wrap align-items-center"><router-link :to="{ name : 'customers'}">Cancel</router-link>
              <button type="submit">Create customer</button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {
          

        return {}
    }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}
.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}
.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}
.content .customer-showcase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px 0 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 638px) {
  .content .customer-showcase {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.content .customer-showcase h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}
.content .customer-showcase ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}
.content .customer-showcase ul li.active {
  pointer-events: none;
}
.content .customer-showcase ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}
.content .customer-showcase ul li:first-of-type a {
  margin-right: 10px;
  padding-right: 10px;
}
.content .customer-showcase ul li:last-of-type a {
  margin-left: 10px;
  padding-left: 10px;
}
.content .customer-showcase ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}
.content .customer-showcase ul p:first-of-type {
  margin-right: 20px;
}
.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}
.content .box.invoice-details h3 {
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}
.content .box .e-invoice-details h3 {
  background-image: url("./../../assets/images/table/e-invoice.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}
.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-top-left-radius: 5px;
  margin: 0;
}
.content .box h3.templates {
  border-top-left-radius: 0;
  border-top-right-radius: 5px;
}
@media (max-width: 834px) {
  .content .box h3.templates {
    display: none;
  }
}
.content .box form {
  padding: 30px;
}
@media (max-width: 834px) {
  .content .box form {
    padding-bottom: 0;
  }
}
.content .box form .parent-small .desc:last-of-type {
  margin-left: 10px;
}
.content .box form .parent-small h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin-bottom: 10px;
}
.content .box form h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
}
.content .box form .headers-e {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.content .box form .headers-e h4 {
  margin: 0;
}
.content .box form .headers-e span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}
.content .box form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 5px;
  margin-bottom: 20px;
}
.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}
.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 192px;
  height: 24px;
  border: none;
  text-indent: 5px;
}
@media (max-width: 576px) {
  .content .box form .desc select {
    width: 98%;
  }
}
.content .box form .desc select.small {
  width: 91px;
}
.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 192px;
  text-indent: 10px;
  padding-right: 5px;
}
@media (max-width: 576px) {
  .content .box form .desc input {
    width: 98%;
  }
}
.content .box form .desc input.small {
  width: 91px;
}
.content .box form .desc input:focus {
  outline: none;
}
.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1px;
}
.content .box form .desc .radio input {
  display: none;
}
.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}
.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}
.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}
.content .box form textarea {
  background: #f0eff5;
  width: 451px;
  height: 159px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}
@media (min-width: 1200px) {
  .content .box form textarea {
    width: -webkit-calc(50% + 207px);
    width: calc(50% + 207px);
  }
}
@media (max-width: 767.98px) {
  .content .box form textarea {
    width: 400px;
  }
}
@media (max-width: 575.98px) {
  .content .box form textarea {
    width: 300px;
    height: 139px;
  }
}
.content .box form textarea:focus {
  border: none;
  outline: none;
}
.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.content .buttons a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.content .buttons a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}
.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="headers d-flex">
          <h1>{{ $t('users') }}</h1>
          <p v-if="packageInfo.length > 0">{{ $t('package_user_text_1') }}
            {{ packageInfo[0].number_of_allowed_users }}{{ $t('package_user_text_2') }}
            <router-link :to="{ name : 'planDetails'}">{{ $t('subscription') }} {{ $t('page') }}</router-link>
            {{ $t('pacakge_user_text_3') }}.
          </p>
        </div>
        <div class="box">
          <div class="box-header flex-wrap">
            <h3>{{ $services.helpers.capitalizeNames($t('current')) }} {{ $t('users').toLowerCase() }}</h3><a class="my-2 my-sm-0"
                                                                                                href="javascript:void(0);"
                                                                                                @click="openInviteModal"
          >{{ $t('invite_user') }} </a>
          </div>
          <div class="box-responsive">
            <table>
              <thead>
              <tr>
                <th>
                  <div class="checkbox">
                    <!--                          <input type="checkbox">-->
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </th>
                <th>{{ $services.helpers.capitalizeNames($t('name')) }}</th>
                <th>{{ $services.helpers.capitalizeNames($t('email')) }}</th>
                <th>{{ $services.helpers.capitalizeNames($t('role')) }}</th>
                <th>{{ $services.helpers.capitalizeNames($t('access')) }}</th>
                <th>{{ $t('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in GET_USERS" :key="user.id">
                <userTable @updateUserInfo="showUserUpdateForm" @deletedUser='userToBeRemove' :data="user"/>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="GET_USERS.length < 1" class="d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalBoxVue v-if="showUserUpdate" @handleClose="showUserUpdate = false" :close="!true">
    <h4>Edit {{ updateData.full_name }}</h4>
    <form class="popup-body" @submit.prevent="updateUserInformation">
      <label>{{ $services.helpers.capitalizeNames($t('name')) }}<span>*</span></label>
      <input type="text" v-model="updateData.full_name"/>
      <label>{{ $services.helpers.capitalizeNames($t('email')) }}<span>*</span></label>
      <input class="text-decoration-underline" type="text" v-model="updateData.email"/>
      <label>{{ $services.helpers.capitalizeNames($t('role')) }}</label>
      <select v-model="updateData.role">
        <option value="Owner">{{ $t('owner') }}</option>
        <option value="Accountant">{{ $t('accountant') }}</option>
        <option value="Staff">{{ $t('staff') }}</option>
        <option value="Other">{{ $t('other') }}</option>
      </select>
      <label>{{ $services.helpers.capitalizeNames($t('access')) }}<span>*</span></label>
      <select v-model="updateData.access">
        <option value="Limited">{{ $t('limited_access') }}</option>
        <option value="Full">{{ $t('full_access') }}</option>
        <option value="Read">{{ $t('read_access') }}</option>
      </select>
      <div class="popup-bottons">
        <button @click="showUserUpdate = false">{{ $services.helpers.capitalizeNames($t('cancel')) }}</button>
        <button :disabled='updating' type="submit">
          <span v-if="updating">{{ $services.helpers.capitalizeNames($t('updating')) }}...</span>
          <span v-else>{{ $services.helpers.capitalizeNames($t('update')) }}</span>
        </button>
      </div>
    </form>
  </ModalBoxVue>
  <ModalBoxVue v-if="showInviteModal" @handleClose="showInviteModal = false" :close="!true">
    <h4>{{ $services.helpers.capitalizeNames($t('invite_new_member_text')) }}</h4>
    <form class="popup-body" @submit.prevent="inviteUser">
      <label>{{ $services.helpers.capitalizeNames($t('name')) }}<span>*</span></label>
      <input type="text" v-model="userInvite.name"/>
      <label>{{ $services.helpers.capitalizeNames($t('email')) }}<span>*</span></label>
      <input class="text-decoration-underline" type="email" v-model="userInvite.email"/>
      <label>{{ $t('role') }}<span class="tooltip-custom">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"/>
                                </svg>
                                <span class="tooltiptext" v-if="GET_LOCALE === 'en'">{{ role.english_text }}</span>
                                <span class="tooltiptext" v-else>{{ role.swedish_text }}</span>
                            </span></label>
      <select v-model="userInvite.role">
        <option value="Owner">{{ $t('owner') }}</option>
        <option value="Accountant">{{ $t('accountant') }}</option>
        <option value="Staff">{{ $t('staff') }}</option>
        <option value="Other">{{ $t('other') }}</option>
      </select>
      <label>{{ $services.helpers.capitalizeNames($t('access')) }} <span class="tooltip-custom">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"/>
                                </svg>
                                <span class="tooltiptext" v-if="GET_LOCALE === 'en'">{{ access.english_text }}</span>
                                <span class="tooltiptext" v-else>{{ access.swedish_text }}</span>
                            </span></label>
      <select v-model="userInvite.access">
        <option value="Limited">{{ $t('limited_access') }}</option>
        <option value="Full">{{ $t('full_access') }}</option>
        <option value="Read">{{ $t('read_access') }}</option>
      </select>
      <div class="popup-footer"><img src="./../../assets/images/popup/user-invite.png" alt=""/>
        <div class="popup-bottons">
          <button @click="showInviteModal = false">Cancel</button>
          <button :disabled='processing' type="submit">
            <span v-if="processing">{{ $services.helpers.capitalizeNames($t('inviting')) }}...</span>
            <span v-else>{{ $services.helpers.capitalizeNames($t('send')) }}</span>
          </button>
        </div>
      </div>
    </form>
  </ModalBoxVue>
  <ModalAlertVue v-if="showUserDeleteModal" @handleClose="showUserDeleteModal = false" :close="!true">
    <h4>{{ $t('delete_confirmation_text') }} <span class="user-confirm text-danger">{{ updateData.full_name }}</span>?
    </h4>
    <div class="popup-bottons">
      <button @click="showUserDeleteModal = false">{{ $t('cancel_delete_request') }}</button>
      <button :disabled='processing' @click="deleteSelectedUser">
        <span v-if="processing">{{ $t('deleting') }} {{ $t('user') }}...</span>
        <span v-else>{{ $t('accept_delete_request') }}</span>

      </button>
    </div>
  </ModalAlertVue>
</template>

<script>
import {mapGetters} from 'vuex'
import userTable from './reusableComponents/UserTable.vue'
import ModalBoxVue from '../../components/ModalBox.vue'
import ModalAlertVue from '../../components/ModalAlert.vue'

export default {
  name: 'Users',
  components: {userTable, ModalBoxVue, ModalAlertVue},
  data() {
    return {
      showUserUpdate: false,
      showInviteModal: false,
      showUserDeleteModal: false,
      updateData: {},
      packageInfo: [],
      userInvite: {
        name: '',
        email: '',
        role: '',
        access: ''
      },
      processing: false,
      updating: false,
      access: {
        english_text: '',
        swedish_text: 'Good and nice'
      },
      role: {
        english_text: '',
        swedish_text: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      GET_USERS: 'clients/GET_USERS',
      CURRENT_USER: 'clients/GET_CURRENT_USER',
      GET_PACKAGE: 'clients/GET_PLAN_DETAIL',
      GET_USER_SUBSCRIPTION: 'clients/GET_USER_SUBSCRIPTION',
      GET_LOCALE: 'locale/GET_LOCALE'
    })
  },
  mounted() {
    this.openAccess()
    this.getHelpText()
  },
  methods: {
    openAccess() {
      this.$store.dispatch('clients/getCurrentUserInfo').then(res => {
        if (this.CURRENT_USER.role !== 'Owner' || this.CURRENT_USER.access !== 'Full') {
          window.Bus.emit('access-denied', 'user')
        } else {
          this.getUserSubscription()
          this.getUsers()
        }
      })
    },
    openInviteModal() {
      if (this.GET_USERS.length >= this.packageInfo[0].number_of_allowed_users) {
        // return this.$services.helpers.notification('You have reached max number of user to invite ,Kindly upgrade to higher package', 'error', this)
        return this.$toast.info(this.$t('max_users'));
      }
      this.showInviteModal = true
    },
    getUsers() {
      this.$store.dispatch('clients/getUsers')
    },
    showUserUpdateForm(data) {
      this.updateData = data.data
      this.showUserUpdate = true
    },
    userToBeRemove(data) {
      this.updateData = data.data
      this.showUserDeleteModal = true
    },
    getUserSubscription() {
      this.$store.dispatch('clients/getUserSubscription')
          .then(res => {
            if (res.data.data) {
              if (!['free', 'Free', 'FREE'].includes(this.GET_USER_SUBSCRIPTION[0].current_package)) {
                this.getPackageInfo(res.data.data[0])
              } else {
                window.Bus.emit('access-denied', 'free')
              }

            }
          })
          .catch(err => {
          })
    },
    getPackageInfo(data) {
      const payload = {
        URL: this.$services.endpoints.PACKAGE_ENDPOINT + `?package=${data.current_package.toLowerCase()}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.packageInfo = res.data.results
            if (!res.data.results[0].dashboard_page_access.includes(this.$route.name)) {
              window.Bus.emit('access-denied', 'no-access')
            }
          })
          .catch(err => {

          })
    },
    updateUserInformation() {
      if (this.updateData.full_name === '' || this.updateData.email === '') {
        return
      } else {
        this.updating = true
        this.$store.dispatch('clients/updateUser', this.updateData)
            .then(res => {
              this.updating = false
              this.showUserUpdate = false
              this.$services.helpers.notification(res.data.message, 'success', this)
            })
            .catch(err => {
              this.updating = false
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            })
      }
    },
    inviteUser() {
      if (this.userInvite.name === '' || this.userInvite.email === '' || this.userInvite.role === '' ||
          this.userInvite.access === '') {
        return
      } else {
        this.processing = true
        this.$store.dispatch('clients/inviteUser', this.userInvite)
            .then(res => {
              this.getUsers()
              this.processing = false
              this.showInviteModal = false
              // this.$services.helpers.notification('Invitation sent successfully', 'success', this)
              this.$toast.success(this.$t('invite_sent'));
            })
            .catch(err => {
              this.processing = false
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            })
      }
    },
    deleteSelectedUser() {
      this.processing = true
      this.$store.dispatch('clients/deleteSelectedUser', this.updateData)
          .then(res => {
            this.processing = false
            this.showUserDeleteModal = false
            // this.$services.helpers.notification('User removed successfully', 'success', this)
            this.$toast.success(this.$t('user_removed'));
          })
          .catch(err => {
            this.processing = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    getHelpText: function () {
      const payload = {
        URL: this.$services.endpoints.HELPTEXT_ENDPOINT + `?dashboard_page=users`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            let access = res.data.results.filter(item => item.field === 'access' && item.dashboard_page === "users")
            let role = res.data.results.filter(item => item.field === 'role' && item.dashboard_page === 'users')
            if (access.length > 0) {
              this.access = access[0]
            }
            if (role.length > 0) {
              this.role = role[0]
            }
          })
          .catch(err => {

          })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
  margin-bottom: 20px;
}

.content .headers h1 ~ p {
  font-size: 18px;
  line-height: 28px;
  color: #333269;
}

.content .headers h1 ~ p a {
  text-decoration: none;
  color: #5855d6;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content .headers h1 ~ p a:hover {
  color: #4946ae;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box .box-header {
  background: #5855d6;
  padding: 15px 30px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box .box-header h3 {
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  margin: 0;
}

.content .box .box-header a {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  color: #5855d6;
  background: #fffdfd;
  padding: 12px 16px;
  border: 1px solid #fffdfd;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box .box-header a:hover {
  color: #4946ae;
  background-color: #fbfafb;
  border-color: #fbfafb;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.content .box table thead th:first-of-type {
  padding-left: 7px;
}

.content .box table thead th:last-of-type {
  padding-right: 7px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f6f8fc;
}

.content .box table tbody td {
  font-size: 16px;
  line-height: 24px;
  color: #1d1c40;
  padding: 15px 0;
}

.content .box table tbody td .edit {
  margin-right: 10px;
}

.content .box table tbody td .edit,
.content .box table tbody td .delete {
  cursor: pointer;
}

.content .box table tbody td:first-of-type {
  padding-left: 7px;
}

.content .box table tbody td:last-of-type {
  padding-right: 7px;
}

.users-edit-popup .popup-box h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0e1566;
  padding: 25px 40px 20px;
  border-bottom: 1px solid #cbd4e1;
  margin: 0;
  text-align: center;
}

.users-edit-popup .popup-box .popup-body {
  padding: 30px 40px 40px;
  margin: 0;
}

.users-edit-popup .popup-box .popup-body label {
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.users-edit-popup .popup-box .popup-body label span {
  color: #ff3333;
  font-weight: bold;
}

.users-edit-popup .popup-box .popup-body input,
.users-edit-popup .popup-box .popup-body select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 37.83px;
  color: #0f1a2a;
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100%;
  margin: 10px 0 20px;
  padding: 0 10px;
}

.users-edit-popup .popup-box .popup-body input:focus,
.users-edit-popup .popup-box .popup-body select:focus {
  outline: none;
  border: none;
}

.users-edit-popup .popup-box .popup-bottons {
  text-align: right;
  margin-top: 20px;
}

.users-edit-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}

.users-edit-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-edit-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.users-edit-popup .popup-box .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-edit-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

.users-invite-popup .popup-box .popup-footer {
  position: relative;
}

.users-invite-popup .popup-box .popup-footer img {
  position: absolute;
  left: -30px;
  bottom: 0;
  max-width: 100%;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons {
  text-align: right;
  margin-top: 20px;
  width: -webkit-calc(100% - 92px);
  width: calc(100% - 92px);
  margin-left: auto;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

.general-user-confirm-popup {
  position: relative;
  display: flex;
  background: rgba(10, 4, 28, 0.36);
}

.general-user-confirm-popup .popup-box {
  min-width: 300px;
  max-width: 90%;
  width: 430px;
  background: #ffffff;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 40px 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.general-user-confirm-popup .popup-box h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 25px;
  text-align: center;
}

.general-user-confirm-popup .popup-box .popup-bottons {
  text-align: center;
}

.general-user-confirm-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  min-width: 140px;
  height: 32px;
  line-height: 22px;
  border: none;
  margin: 5px 10px;
}

.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type {
  background: #419e6a;
  color: #ffffff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #1f8c4d;
}
.content {
    padding: 90px 30px 10px !important;
}
</style>
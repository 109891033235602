<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content min-vh-100">
        <authorization-banner/>
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t("recurringContractUpdate") }}</h1>

        </div>
        <nav>
          <div class="row">
            <div class="col-lg-12">
              <div class="right-parent">
                <div class="filter d-flex">

                  <label>Filter</label>
                  <custom-select-drop-down :font-size="`14px`" :width="`240px`" @onPicked="onPicked" :height="`30px`"
                                           :default-value="addon.filterType" :key="addon.filterType"
                                           :options="optionList"/>
                  <button @click="clearFilter">{{ $t("clearFilter") }}</button>
                  <select v-model="addon.filterType" style="display: none;    width: 240px;"
                          v-on:change="onChangeFilter">
                    <option value="All">{{ $t("all") }}</option>
                    <option value="status=ongoing">{{ $t("ongoing") }}</option>
                    <option :value="`update_type=${item.key}`" v-for="(item,key) in actionList" :key="key">
                      {{ $t(`${item.locale}`) }}
                    </option>
                  </select>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  <div class="search">
                    <label for="search">
                      <svg
                          width="15"
                          height="15"
                          viewbox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M14.6219 13.5596L10.0568 8.99453C10.7652 8.07871 11.1484 6.95898 11.1484 5.78125C11.1484 4.37148 10.5982 3.04961 9.60332 2.05293C8.6084 1.05625 7.28301 0.507812 5.875 0.507812C4.46699 0.507812 3.1416 1.05801 2.14668 2.05293C1.15 3.04785 0.601562 4.37148 0.601562 5.78125C0.601562 7.18926 1.15176 8.51465 2.14668 9.50957C3.1416 10.5063 4.46523 11.0547 5.875 11.0547C7.05273 11.0547 8.1707 10.6715 9.08652 9.96484L13.6516 14.5281C13.6649 14.5415 13.6808 14.5521 13.6983 14.5594C13.7158 14.5666 13.7346 14.5704 13.7535 14.5704C13.7725 14.5704 13.7912 14.5666 13.8087 14.5594C13.8262 14.5521 13.8421 14.5415 13.8555 14.5281L14.6219 13.7635C14.6353 13.7501 14.6459 13.7342 14.6531 13.7167C14.6604 13.6992 14.6641 13.6805 14.6641 13.6615C14.6641 13.6426 14.6604 13.6238 14.6531 13.6063C14.6459 13.5889 14.6353 13.573 14.6219 13.5596ZM8.65938 8.56563C7.91406 9.30918 6.92617 9.71875 5.875 9.71875C4.82383 9.71875 3.83594 9.30918 3.09063 8.56563C2.34707 7.82031 1.9375 6.83242 1.9375 5.78125C1.9375 4.73008 2.34707 3.74043 3.09063 2.99688C3.83594 2.25332 4.82383 1.84375 5.875 1.84375C6.92617 1.84375 7.91582 2.25156 8.65938 2.99688C9.40293 3.74219 9.8125 4.73008 9.8125 5.78125C9.8125 6.83242 9.40293 7.82207 8.65938 8.56563Z"
                            fill="#8F8E94"
                        ></path>
                      </svg>
                    </label>
                    <input
                        v-model="addon.search"
                        id="search"
                        type="text"
                        v-on:input="onSearch"
                        :placeholder="$t('search')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div class="box">
          <div
              v-if="loading"
              class="d-flex justify-content-center"
          >
            <div class="loader">Loading...</div>
          </div>
          <div v-else
               :class="[
              'box-responsive',
            ]"
          >
            <table>
              <thead>
              <tr>
                <th v-for="(item, i) in tableHeader" :key="i">
                  <div style="display: flex">
                      <span>{{
                          $services.helpers.capitalizeNames($t(item.name))
                        }}</span
                      ><span
                      v-if="item.has_sort === true"
                      class="th-soring"
                  >
                        <i
                            class="bx bx-chevron-up bx-xs el-clickable"
                            v-on:click="sortContract('sort',`${item.sort_name}`)"
                        ></i>
                        <i
                            class="bx bx-chevron-down bx-xs el-clickable"
                            v-on:click="sortContract('sort',`-${item.sort_name}`)"
                        ></i>
                      </span>
                  </div>
                </th>
                <th width="90">
                  <span>{{ $t("actions") }}</span>
                </th>
              </tr>
              </thead>
              <tbody v-if="GET_SCHEDULES_LIST.results.length > 0">
              <tr v-for="(schedule,key) in GET_SCHEDULES_LIST.results" :key="key">
                <recurring-contract-row :key="key"
                                        :schedule="schedule" @removeSchedule="removeSchedule" :help-text="helpText"/>
              </tr>

              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="6">
                  <span class="ell-center">{{ $t('noData') }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <ModalBoxVue v-if="newType" @handleClose="newType = false" :close="!true">
      <div class="p-3">
        <div class="mb-3">
          <p class="mb-2">{{ $t("changeType") }}</p>
          <select
              v-model="scheduling.change_type"
              style="width: 100%"
              class="inputFields"
          >
            <option value="increase_discount">{{ $t("increaseDiscount") }}</option>
            <option value="decrease_discount">{{ $t("decreaseDiscount") }}</option>
            <option value="remove_discount">{{ $t("removeDiscount") }}</option>
            <option value="increase_quantity">{{ $t("increaseDeliveredQuantity") }}</option>
            <option value="decrease_quantity">{{ $t("decreaseDeliveredQuantity") }}</option>
            <option value="increase_price">{{ $t("increasePrice") }}</option>
            <option value="decrease_price">{{ $t("decreasePrice") }}</option>
            <option value="change_article">{{ $t("changeArticle") }}</option>
            <option value="remove_row">{{ $t("removeRow") }}</option>
          </select>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary m-3">OK</button>
        </div>
      </div>
    </ModalBoxVue>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalBoxVue from "../../components/ModalBox.vue";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
import AuthorizationBanner from "./reusableComponents/AuthorizationBanner";
import recurringContractRow from "./reusableComponents/recurringContractRow";
import customSelectDropDown from "../../components/customSelectDropDown";
import debounce from "lodash.debounce"

const TODAY = moment().format("YYYY-MM-DD");
export default {
  name: "recurringContractUpdate",
  components: {
    AuthorizationBanner, recurringContractRow, ModalBoxVue, customSelectDropDown
  },
  data: function () {
    return {
      applyFilter: '',
      openInvoiceCustomizationModal: false,
      defaultValue: '',
      searchField: '',
      loading: true,
      newType: false,
      helpText: [],
      actionList: [
        {key: 'update_type=increase_discount', locale: 'increaseDiscount'},
        {key: 'decrease_discount', locale: 'decreaseDiscount'},
        {key: 'increase_quantity', locale: 'increaseDeliveredQuantity'},
        {key: 'decrease_quantity', locale: 'decreaseDeliveredQuantity'},
        {key: 'increase_price', locale: 'increasePrice'},
        {key: 'decrease_price', locale: 'decreasePrice'},
        {key: 'change_article', locale: 'changeArticle'},
        {key: 'remove_row', locale: 'removeRow'}
      ],
      optionList: [
        {value: 'All', label: 'all'},
        {value: 'status=ongoing', label: 'ongoing'},
        {value: 'update_type=increase_discount', label: 'increaseDiscount'},
        {value: 'update_type=decrease_discount', label: 'decreaseDiscount'},
        {value: 'update_type=increase_quantity', label: 'increaseDeliveredQuantity'},
        {value: 'update_type=decrease_quantity', label: 'decreaseDeliveredQuantity'},
        {value: 'update_type=increase_price', label: 'increasePrice'},
        {value: 'update_type=decrease_price', label: 'decreasePrice'},
        {value: 'update_type=change_article', label: 'changeArticle'},
        {value: 'update_type=remove_row', label: 'removeRow'}
      ],
      sortValue: '',
      newEventType: '',
      scheduling: {
        row_id: "",
        contract_number: "",
        date_of_action: "",
        change_type: "",
        new_value: "",
      },
      scheduleOptionsList: [
        {key: 'contract cancellation', name: 'contractCancel'},
        {key: 'contract price change', name: 'contractPriceChange'},
        // {key: 'invoice sendout', name: 'invoiceSendout'},
      ],
      tableHeader: [
        {name: "documentNumber", has_sort: true, sort_name: 'document_number'},
        {name: "lastUpdated", has_sort: true, sort_name: 'date_of_action'},
        {name: "updateType", has_sort: false, sort_name: 'date_of_action'},
        // {name: "event", has_sort: false, sort_name: 'event'},
        {name: "status", has_sort: false, sort_name: 'status'},
      ],
      addon: {
        limit: 50,
        total_resources: 1,
        total_page: 1,
        page: 1,
        filterType: '',
        search: '',
        sortValue: '',
      }
    }
  },
  methods: {
    getHelptextList() {
      const payload = {
        URL:
        this.$services.endpoints.HELPTEXT_ENDPOINT
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            this.helpText = res.data.results

          })
          .catch((err) => {
          });
    },
    removeSchedule: function (scheduleID) {
      let findIndex = this.GET_SCHEDULES_LIST.results.findIndex(item => item.id = scheduleID)
      if (findIndex > -1) {
        this.GET_SCHEDULES_LIST.results.splice(findIndex, 1)
      }
    },
    getUserSubscription() {
      this.$store
          .dispatch("clients/getUserSubscription")
          .then((res) => {
            this.getPackageInfo();
          })
          .catch((err) => {
          });
    },
    getPackageInfo() {
      if (this.GET_USER_SUBSCRIPTION.length > 0) {
        if (
            !["free", "Free", "FREE"].includes(
                this.GET_USER_SUBSCRIPTION[0].current_package
            )
        ) {
          const payload = {
            URL:
                this.$services.endpoints.PACKAGE_ENDPOINT +
                `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
          };
          this.$store
              .dispatch("clients/getPlanDetails", payload)
              .then((res) => {
                if (
                    !res.data.results[0].dashboard_page_access.includes(
                        this.$route.name
                    )
                ) {
                  window.Bus.emit("access-denied", "invoice");
                }
              });
        } else {
          window.Bus.emit("access-denied", "invoice");
        }
      } else {
        this.getUserSubscription();
      }
    },
    onPicked: function (option) {
      this.addon.filterType = option.label
      this.$store.commit('clients/UPDATE_RECURRING_CONTRACT_FILTER', {filter: option.value})
      this.onChangeFilter()
    },
    clearFilter: function () {
      this.addon.filterType = ''
      this.$store.commit('clients/CLEAR_FILTER', 'recurring-contract-update')
      this.addon.search = ''
      document.getElementById('search').value = ''
      this.onChangeFilter()
    },
    computeQueryParams() {
      if (Object.keys(this.RECURRING_CONTRACT_FILTER).length <= 0) {
        return null
      }
      let payload = ""
      Object.keys(this.RECURRING_CONTRACT_FILTER).map(key => {
        if (key === 'filter') {
          if (this.RECURRING_CONTRACT_FILTER[key] !== 'all') {
            payload += `&${this.RECURRING_CONTRACT_FILTER[key]}`
          }

        } else {
          if (this.RECURRING_CONTRACT_FILTER[key]) {
            payload += `&${key}=${this.RECURRING_CONTRACT_FILTER[key]}`
          }
          if (key === 'search') {
            document.getElementById(key).value = this.RECURRING_CONTRACT_FILTER[key]
            this.addon.search = this.RECURRING_CONTRACT_FILTER[key]
          }
        }
      })
      return payload
    },
    onChangeFilter: function () {
      let payload = `?limit=${this.addon.limit}`
      let params = this.computeQueryParams()
      if (params) {
        payload += params
      }
      this.$store.dispatch('clients/getScheduleList', payload).then(resp => {
        this.loading = false
        // this.computePageNation(resp)
      }).catch(er => {
        this.loading = false
      })
    },
    onSearch: debounce(function () {
      this.$store.commit('clients/UPDATE_RECURRING_CONTRACT_FILTER', {search: this.addon.search})
      this.onChangeFilter()
    }, 1000),
    sortContract: function (type, sortValue) {
      this.$store.commit('clients/UPDATE_RECURRING_CONTRACT_FILTER', {ordering: sortValue})
      this.onChangeFilter()
    },
    getScheduleList() {
      this.loading = true
      Object.keys(this.addon).map(key => {
            if (['filterType', 'search', 'sortValue'].includes(key)) {
              this.addon[key] = ''
            }
          }
      )
      this.$store.dispatch('clients/getScheduleList', '').then(resp => {
        this.loading = false
        // this.computePageNation(resp)
      }).catch(er => {
        this.loading = false
      })
    },
    changePageNumber: function (number) {
      let params = `?limit=${this.addon.limit}&page=${number}`
      let query = this.computeQueryParams()
      if (query) {
        params += query
      }
      // this.loading = true
      this.$store.dispatch('clients/getScheduleList', params).then(resp => {
        this.loading = false
      }).catch(er => {
        this.loading = false
      })
    },
    computePageNation: function (res) {
      this.addon.total_resources = res.data.count
      if (res.data.count > this.addon.limit) {
        this.addon.total_page = Math.ceil(res.data.count / this.addon.limit)
      } else {
        this.addon.total_page = 1
      }
    },
  },
  mounted() {
    this.getUserSubscription()
    this.getScheduleList()
    this.getHelptextList()
  },
  computed: {
    ...mapGetters({
      GET_SCHEDULES_LIST: 'clients/GET_SCHEDULES_LIST',
      RECURRING_CONTRACT_FILTER: 'clients/GET_RECURRING_CONTRACT_FILTER'

    })
  }
}
</script>

<style lang="scss" scoped>
// TODO: change styling for active-filter class
.active-filter {
  box-shadow: 5px 5px 3px #888888 !important;
}

.list-summary {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0px;
  font-weight: 600;
}

.applyFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.applyFilter p {
  margin: 0px;
}

.applyFilter select {
  height: 30px;
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}

@-webkit-keyframes disable-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
}

@keyframes disable-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
}

.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .headers a:hover svg path {
  fill: #4946ae;
}

.content nav {
  margin: 20px 0 0 5px;
}

.el-clickable {
  cursor: pointer !important;
}

@media (max-width: 1094px) {
  .content nav .row {
    display: block;
  }
  .content nav .row > div:last-of-type {
    margin-top: 10px;
  }
  .content nav .row ul {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content nav .row ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.content nav ul {
  margin: 0;
  text-align: center;
  margin-top: 10px;
}

.content nav ul li {
  border-radius: 3.24215px;
  font-size: 11.3475px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  width: 81.05px;
  height: 26.11px;
  -webkit-transition: color 0.3s ease-in-out,
  -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, -webkit-transform 0.35s ease-in-out,
  -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, transform 0.35s ease-in-out,
  box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, transform 0.35s ease-in-out,
  box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, transform 0.35s ease-in-out,
  box-shadow 0.3s ease-in-out, -webkit-transform 0.35s ease-in-out,
  -webkit-box-shadow 0.3s ease-in-out;
}

.content nav ul li:hover {
  -webkit-transform: translateY(-0.25em);
  -ms-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.content nav ul li.all {
  background: #c0beff;
}

.content nav ul li.all:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
  box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
}

.content nav ul li.booked {
  background: #4d82f3;
}

.content nav ul li.booked:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
  box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
}

.content nav ul li.not-booked {
  background: #efb008;
}

.content nav ul li.not-booked:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #efb008;
  box-shadow: 0 0.5em 0.5em -0.1em #efb008;
}

.content nav ul li.unpaid {
  background: #fb4b4b;
}

.content nav ul li.unpaid:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
  box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
}

.content nav ul li.fully-paid {
  background: #419e6a;
}

.content nav ul li.fully-paid:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
  box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
}

.content nav ul li.voided {
  background: #64748b;
  color: #cbd4e1;
}

.content nav ul li.voided:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #64748b;
  box-shadow: 0 0.5em 0.5em -0.1em #64748b;
}

.content nav ul li:not(:last-of-type) {
  margin-right: 15px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.content nav .right-parent .filter {
  margin-right: 20px;
  margin-top: 10px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  //width: 136.73px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 298.73px;
  margin-left: 0px !important;
  padding-left: 20px !important;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

.content nav .right-parent .customize {
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .customize {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .customize {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.content nav .right-parent .customize a {
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content nav .right-parent .customize a svg {
  margin-right: 10px;
}

.content nav .right-parent .customize a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content nav .right-parent .customize a:hover {
  color: #4946ae;
}

.content nav .right-parent .customize a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
  0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
  0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox svg span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  //   white-space: nowrap;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  //   white-space: nowrap;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .box table tbody tr .booked td a {
  color: #4d82f3;
}

.content .box table tbody tr.booked td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.notbooked td a {
  color: #efb008;
}

.content .box table tbody tr.notbooked td:not(:last-of-type) path {
  fill: #64748b;
}

.content .box table tbody tr.unpaid td a {
  color: #fb4b4b;
}

.content .box table tbody tr.unpaid td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.fullypaid td a {
  color: #419e6a;
}

.content .box table tbody tr.fullypaid td button:nth-child(1),
.content .box table tbody tr.fullypaid td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.voided td a {
  color: #64748b;
}

.content .box table tbody tr.voided td:not(:last-of-type) path {
  fill: #64748b;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

.currency-container {
  position: relative;
}

.currency {
  position: absolute;
  top: 41px;
  right: 10px;
}

@media (max-width: 575.98px) {
  .content .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content .actions * {
    margin: 5px !important;
  }
}

.sync-btn {
  height: 30px;
  width: 31px;
  position: relative;
  margin-left: 10px;
}

.sync-btn i {
  position: absolute;
  top: 3px;
  left: 4px;
  font-size: 20px !important;
}

.content {
  padding: 90px 30px 10px !important;
}

.search {
  width: 298.73px;
  margin-left: 0px !important;
  padding-left: 10px !important;
}

// TODO: removing default styles of date picker
// .dp__input_icon_pad {
//   box-shadow: none !important;
//   background: transparent !important;
//   width: 100% !important;
// }
</style>

<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <authorization-banner/>
        <div class="headers d-flex">
          <div>
            <h1 class="mb-0">{{ $t("schedules") }}</h1>
          </div>
          <span class="ms-auto" @click="addNew()">
            <svg
                width="31"
                height="30"
                viewbox="0 0 31 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M22.6541 0C27.7541 0 30.6641 2.88 30.6641 7.995V22.005C30.6641 27.09 27.7691 30 22.6691 30H8.65906C3.54406 30 0.664062 27.09 0.664062 22.005V7.995C0.664062 2.88 3.54406 0 8.65906 0H22.6541ZM15.6491 8.265C14.9591 8.265 14.4041 8.82 14.4041 9.51V13.74H10.1591C9.82906 13.74 9.51406 13.875 9.27406 14.1C9.04906 14.34 8.91406 14.6535 8.91406 14.985C8.91406 15.675 9.46906 16.23 10.1591 16.245H14.4041V20.49C14.4041 21.18 14.9591 21.735 15.6491 21.735C16.3391 21.735 16.8941 21.18 16.8941 20.49V16.245H21.1541C21.8441 16.23 22.3991 15.675 22.3991 14.985C22.3991 14.295 21.8441 13.74 21.1541 13.74H16.8941V9.51C16.8941 8.82 16.3391 8.265 15.6491 8.265Z"
                  fill="#7754FB"
              ></path>
            </svg>
          </span>
        </div>
        <nav>
          <div class="row g-0">
            <div class="col">
              <ul class="list-unstyled d-flex">
                <li
                    @click="filterSchedules('All')"
                    :class="selectedFilter === 'All' ? 'active-filter' : ''"
                    class="all"
                >
                  {{ $t("all") }}
                </li>
                <li
                    @click="filterSchedules('invoice')"
                    :class="selectedFilter === 'invoice' ? 'active-filter' : ''"
                    class="enabled"
                >
                  {{ $t("invoices") }}
                </li>
                <li
                    @click="filterSchedules('contract')"
                    :class="selectedFilter === 'contract' ? 'active-filter' : ''"
                    class="inactive"
                >
                  {{ $t("contract") }}
                </li>
              </ul>
            </div>
            <div class="col">
              <div class="right-parent">
                <div class="filter d-flex">
                  <label>{{ $t("filter") }}</label>
                  <custom-select-drop-down :width="`175px`" :height="`30px`" :default-value="applyFilter"
                                           :key="applyFilter" :options="optionsList"
                                           @onPicked="onPicked"/>
                  <button @click="clearFilter">{{ $t("clearFilter") }}</button>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  <div class="search">
                    <label for="search">
                      <svg
                          width="15"
                          height="15"
                          viewbox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M14.6219 13.5596L10.0568 8.99453C10.7652 8.07871 11.1484 6.95898 11.1484 5.78125C11.1484 4.37148 10.5982 3.04961 9.60332 2.05293C8.6084 1.05625 7.28301 0.507812 5.875 0.507812C4.46699 0.507812 3.1416 1.05801 2.14668 2.05293C1.15 3.04785 0.601562 4.37148 0.601562 5.78125C0.601562 7.18926 1.15176 8.51465 2.14668 9.50957C3.1416 10.5063 4.46523 11.0547 5.875 11.0547C7.05273 11.0547 8.1707 10.6715 9.08652 9.96484L13.6516 14.5281C13.6649 14.5415 13.6808 14.5521 13.6983 14.5594C13.7158 14.5666 13.7346 14.5704 13.7535 14.5704C13.7725 14.5704 13.7912 14.5666 13.8087 14.5594C13.8262 14.5521 13.8421 14.5415 13.8555 14.5281L14.6219 13.7635C14.6353 13.7501 14.6459 13.7342 14.6531 13.7167C14.6604 13.6992 14.6641 13.6805 14.6641 13.6615C14.6641 13.6426 14.6604 13.6238 14.6531 13.6063C14.6459 13.5889 14.6353 13.573 14.6219 13.5596ZM8.65938 8.56563C7.91406 9.30918 6.92617 9.71875 5.875 9.71875C4.82383 9.71875 3.83594 9.30918 3.09063 8.56563C2.34707 7.82031 1.9375 6.83242 1.9375 5.78125C1.9375 4.73008 2.34707 3.74043 3.09063 2.99688C3.83594 2.25332 4.82383 1.84375 5.875 1.84375C6.92617 1.84375 7.91582 2.25156 8.65938 2.99688C9.40293 3.74219 9.8125 4.73008 9.8125 5.78125C9.8125 6.83242 9.40293 7.82207 8.65938 8.56563Z"
                            fill="#8F8E94"
                        ></path>
                      </svg>
                    </label>
                    <input
                        @input="searchField"
                        id="search"
                        type="text"
                        :placeholder="$t('search')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div class="box">
          <div
              v-if="loading"
              class="d-flex justify-content-center"
          >
            <div class="loader">{{ $t("loading") }}...</div>
          </div>
          <div v-else
               :class="[
              'box-responsive',
              `cols-count-${customerCustomizeItem.length + 2}`,
            ]"
          >
            <table>
              <thead>
              <tr>
                <th v-for="(item, i) in tableHeader" :key="i">
                  <div style="display: flex">
                      <span v-if="['active'].includes(item)">Status</span
                      ><span v-else>{{ $t(item) }}</span>
                    <span
                        v-if="!item_to_exclude.includes(item)"
                        class="th-soring"
                    >
                        <i
                            class="bx bx-chevron-up bx-xs el-clickable"
                            v-if="sortableColumns.includes(item)"
                            v-on:click="sortAscBy(i, item)"
                        ></i>
                        <i
                            class="bx bx-chevron-down bx-xs el-clickable"
                            v-if="sortableColumns.includes(item)"
                            v-on:click="sortDescBy(i, item)"
                        ></i>
                      </span>
                  </div>
                </th>

                <th width="90"><span>Åtgärder</span></th>
              </tr>
              </thead>
              <tbody v-if="GET_SCHEDULES.length > 0">
              <tr v-for="schedules in GET_SCHEDULES" :key="schedules.id">
                <ScheduledTable
                    @removeSchedule="removeSchedule"
                    ref="table"
                    :help-text="helpText"
                    :data="schedules"
                    :head="tableHeader"

                />
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="7" class="text-center">
                  <span>{{ $t('noData') }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="actions d-flex align-items-center">
          <!--          <label>{{ $t("actions") }} </label>-->
          <div class="d-flex justify-content-end w-full">
            <div style="margin-right: 20px">
              <p class="list-summary">
                <select
                    v-model="scheduleAddon.limit"
                    v-on:change="onChange"
                    style="width: 54px"
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                {{ $t("of") }} {{ scheduleAddon.total_resources }}
              </p>
            </div>
            <v-pagination
                v-model="scheduleAddon.page"
                :pages="scheduleAddon.total_page"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="changePageNumber"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalBoxVue
      v-if="openCustomerCustomizationModal"
      @handleClose="openCustomerCustomizationModal = false"
      :close="!true"
  >
    <div class="container p-3">
      <p>Uppdatera ordningen på kolumnerna <small> (drag &amp; drop)</small></p>
      <!-- list all visible columns -->
      <div class="row small">
        <div class="col-md-12">
          <!-- draggable list of visible columns -->
          <ul class="list-group" id="sortlist">
            <li
                v-for="item in customerCustomizeItem"
                :key="item"
                class="list-group-item list-group-item-action"
                style="cursor: grab"
            >
              <div class="d-flex justify-content-start">
                <img
                    style="width: 20px; height: 20px; margin-right: 10px"
                    src="../../assets/images/icon-utility/drag-and-drop.svg"
                    alt="d&amp;d-icon"
                />
                <div>
                  <span>{{ $t(item) }}</span>
                </div>
              </div>
            </li>
          </ul>
          <!-- <div class="list-group" v-for="(item, i) in tableHeader" :key="i">
            <p
              class="list-group-item list-group-item-action"
              :class="{
                active: item == activeColumn,
                disabled: item_to_exclude.includes(item),
              }"
              @click="setActiveColumn(item)"
            >
              {{ $t(item) }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <h4>{{ $t("customization_customer") }}</h4>
    <div class="container p-3">
      <small>{{ $t("select_columns") }}</small>
      <form @submit.prevent="updateCustomerCustomization">
        <div class="customize-cols-scrollar">
          <div
              class="form-check"
              v-for="(item, i) in Object.keys(CUSTOMER_CUSTOMIZATION)"
              :key="i"
          >
            <input
                class="form-check-input"
                @change="onChangeCheckbox"
                v-model="customerCustomizeItem"
                type="checkbox"
                :value="item"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ CUSTOMER_CUSTOMIZATION[item] }}
            </label>
          </div>
        </div>

        <div class="col-md-12 mt-2">
          <button type="submit" :disabled="updating" class="btn btn-primary">
            <span v-if="updating">{{ $t("updating") }}...</span>
            <span v-else>{{ $t("update") }}</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
      v-if="openTagAddition"
      @handleClose="openTagAddition = false"
      :close="!true"
  >
    <h4>Tags</h4>
    <div class="container p-3">
      <!-- <small>Select table heads (Multiple)</small> -->
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(item, i) in customerTags" :key="i">
          <span
              class="position-absolute tags-box cursor-pointer"
              @click="removeTag(item.id)"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style="color: red"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
              />
            </svg>
          </span>
          <div class="border rounded p-2 bg-gray t2">
            <span class="d-flex align-items-center">
              <input
                  class="form-check-input"
                  :value="item.name"
                  type="checkbox"
                  v-model="tagSelection"
              />
              <small class="ml-2 tag-label">{{ item.name }}</small>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <input
              class="form-control"
              v-model="tag"
              type="text"
              :placeholder="$t('add_tags')"
          />
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <button
              v-if="showAssignBtn"
              @click="assignTagCustomers"
              :disabled="assigning"
              class="btn btn-success me-2 w-100"
          >
            <span v-if="assigning">{{ $t("assigning") }}...</span>
            <span v-else>{{ $t("assign") }}</span>
          </button>
          <button
              type="submit"
              @click="addNewTags"
              :disabled="updating"
              class="btn btn-primary w-100"
          >
            <span v-if="updating">{{ $t("updating") }}...</span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </div>
      </div>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
      v-if="eventFilter"
      @handleClose="eventFilter = false"
      :close="!true"
  >
    <div class="p-3">
      <h4>Enter Event Type</h4>
      <el-select v-model="customFilter" class="w-100 mt-2" :placeholder="$t('select')" size="large">
        <el-option
            v-for="(item,key) in scheduleOptionsList"
            :key="`_list_${key}`"
            :label="$t(`${item.name}`)"
            :value="item.key"
        />
      </el-select>
      <button @click="sentFilter" class="btn btn-primary m-3">
        OK
      </button>
    </div>
  </ModalBoxVue>
  <ModalBoxVue v-if="newType" @handleClose="newType = false" :close="!true">
    <div class="p-3">
      <h4>{{ $t("selectType") }} </h4>
      <el-select v-model="newEventType" class="w-100 mt-2" :placeholder="$t('select')" size="large">
        <el-option
            v-for="(item,key) in eventListType"
            :key="`_list_${key}`"
            :label="$t(`${item.name}`)"
            :value="item.key"
        />
      </el-select>
      <div class="d-flex justify-content-center">
        <button @click="typeSelectModal()" class="btn btn-primary m-3">{{ $t('ok') }}</button>
      </div>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
      v-if="typeSelect"
      @handleClose="typeSelect = false"
      :close="!true"
  >
    <div class="p-3">
      <h4 class="mb-2">{{ $t("search") }} {{ selectionType }}</h4>
      <!--      <input type="text" v-model="searchFieldValue" placeholder="Sök" class="inputFields"/>-->
      <div class="mb-2 mt-2">
        <el-select v-if="selectionType === `invoice`"
                   v-model="newInvoiceId"
                   filterable
                   remote
                   class="w-100"
                   size="large"
                   reserve-keyword
                   :placeholder="$t('searchInvoice')"
                   :remote-method="remoteInvoiceSearch"
                   :loading="loadingData"
        >
          <el-option
              v-for="(item,key) in invoiceList"
              :key="key"
              :label="item.document_number"
              :value="item.document_number"
          />
        </el-select>
        <el-select v-if="selectionType === `contract`"
                   v-model="newContractId"
                   filterable
                   remote
                   reserve-keyword
                   class="w-100"
                   size="large"
                   :placeholder="$t('searchContract')"
                   :remote-method="remoteContractSearch"
                   :loading="loadingData"
                   @change="onChangeContract"
        >
          <el-option
              v-for="item in contractList"
              :key="item.document_number"
              :label="item.document_number"
              :value="item.document_number"
          />
        </el-select>
      </div>
      <br/>
      <div class="d-flex justify-between w-100">
        <button
            v-if="newInvoiceId !== `` || newContractId !== ``"
            @click="addModals()"
            class="btn btn-primary w-20 m-3 ell-center"
        >
          Ok
        </button>
      </div>
    </div>
  </ModalBoxVue>
  <!-- schedules -->
  <ModalBoxVue
      v-if="schedulesModal"
      @handleClose="schedulesModal = false"
      :close="!true"
  >
    <div class="popup-box2">
      <h4>
        {{ $t("schedule_invoice_send_out") }}
      </h4>
    </div>
  </ModalBoxVue>
  <!-- openScheduleInvoiceSendoutModal -->
  <ModalBoxVue
      v-if="openScheduleInvoiceSendoutModal"
      @handleClose="openScheduleInvoiceSendoutModal = false"
      :close="!true"
  >
    <div class="popup-box2">
      <h4>
        {{ $t("schedule_invoice_send_out") }}
      </h4>
      <div class="container p-3" style="text-align: left !important">
        <!-- <form @submit.prevent="updateContractCustomization"> -->
        <form @submit.prevent>
          <div class="customize-cols-scrollar custom-form">
            <div class="col-md-12">
              <div class="mb-3">
                <small class="w-100 mb-8px">{{ $t("date_to_send") }}</small>
                <Datepicker
                    class="form-control w-100"
                    v-model="date_to_send"
                    format="yyyy-MM-dd"
                    :enableTimePicker="false"
                    :minDate="today"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="w-100 mb-1">
                <label class="mb-8px">{{ $t("invoice_type") }}</label>
                <select v-model="invoice_type" class="form-control">
                  <option value="" selected>{{ $t("choose_an_option") }}</option>
                  <option value="nox_finans">
                    {{ $t("fortnox_invoice_service") }}
                  </option>
                  <option value="regular">{{ $t("regular") }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="mb-8px">{{ $t("distribution_method") }}</label>
                <select v-model="distribution_method" class="form-control">
                  <option value="" selected>{{ $t("choose_an_option") }}</option>
                  <option value="email">{{ $t("as_email") }}</option>
                  <option value="einvoice">{{ $t("e_invoice") }}</option>
                  <option v-if="invoice_type === 'nox_finans'" value="print">
                    {{ $t("letter") }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12 d-flex">
              <div class="mb-3 d-flex">
                <input
                    v-model="bookkeep"
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="bookkeep-id"
                    name="bookkeep-name"
                />
                <label for="bookkeep-id"> {{ $t("bookkeep_invoice") }} </label
                >
              </div>
              <br/>
            </div>
            <div class="col-md-12 d-flex">
              <div class="mb-3 d-flex">
                <input
                    v-model="send_failed_nox_as_reg"
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="send-failed-nox-as-reg-id"
                    name="send-failed-nox-as-reg-name"
                />

                <label for="send-failed-nox-as-reg-id">
                  {{ $t("send_failed_nox_as_reg_2") }} </label
                ><br/>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <span v-if="targetRow.scheduled_object">
              <button
                  class="btn btn-primary"
                  @click="updateScheduleInvoiceSendout()"
              >
                {{ $t("update") }}
              </button>
              <button
                  class="btn btn-warning"
                  @click="deleteScheduleInvoiceSendout"
              >
                {{ $t("delete") }}
              </button>
            </span>
            <!--  -->
            <button
                v-else
                class="btn btn-primary mr-10px"
                @click="addScheduleInvoiceSendout"
            >
              {{ $t("save") }}
            </button>

            <button
                class="btn btn-danger"
                @click="openScheduleInvoiceSendoutModal = false"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ModalBoxVue>
  <!-- cancel -->
  <ModalBoxVue
      v-if="openScheduleContractCancelationModal"
      @handleClose="onClose"
      :close="!true"
  >
    <h4>{{ $t("schedule_contract_cancelling") }}</h4>
    <div class="container p-3" style="text-align: left !important">
      <form @submit.prevent>
        <div class="customize-cols-scrollar custom-form">
          <div class="col-md-12">
            <div class="mb-3">
              <small class="mb-8px">{{ $t("date_to_cancel") }}</small>
              <Datepicker
                  class="form-control w-100"
                  v-model="date_to_cancel"
                  format="yyyy-MM-dd"
                  :enableTimePicker="false"
                  :minDate="today"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-8px">{{ $t("type_of_cancellation") }}</label>
              <select v-model="type_of_cancellation" class="form-control">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option value="inactivate">{{ $t("inactivate") }}</option>
                <option value="terminate">{{ $t("terminate") }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <div>
            <!-- if current row already has scheduled contract cancellation -->
            <span v-if="targetRow.scheduled_object" class="mr-2">
            <button
                class="btn btn-primary"
                @click="updateScheduleContractCancelation()"
            >
              {{ $t("update") }}
            </button>
            <button
                class="btn btn-warning "
                @click="deleteScheduleContractCancelation"
            >
              {{ $t("delete") }}
            </button>
          </span>

            <!-- if current row does not have a scheduled contract cancellation -->
            <button
                v-else
                class="btn btn-primary mr-10px"
                @click="addScheduleContractCancelation()"
            >
              {{ $t("save") }}
            </button>

            <button
                class="btn btn-danger mr-10px"
                @click="onClose"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <!-- Price change modal -->
  <ModalBoxVue
      v-if="openPriceChangeModal"
      @handleClose="openPriceChangeModal = false"
      :close="!true"
  >
    <h4>{{ $t("update_contract_price") }}</h4>
    <div class="container p-3" style="text-align: left !important">
      <!-- <form @submit.prevent="updateContractCustomization"> -->

      <form @submit.prevent class="">
        <div class="customize-cols-scrollar form">
          <div class="col-md-12">
            <div class="mb-3">
              <small class="mb-8px">{{ $t("date_to_change_price") }}</small>
              <Datepicker
                  class="form-control"
                  v-model="contractPriceUpdateDate"
                  format="yyyy-MM-dd"
                  :enableTimePicker="false"
                  :minDate="today"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-8px">{{ $t("type_of_price_change") }}</label>
              <select v-model="contractPriceChangeType" class="form-control w-100">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option v-for="(item,key) in contractChangeType" :value="item" :key="key">{{ $t(`${item}`) }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1 d-flex">
                <div>

            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("contract_price_update_price_change")
                      }}</span>
                  </span>
                </div>
                <div>
                  <span
                      v-if="['increase_percentage', 'decrease_percentage'].includes(contractPriceChangeType) === true">{{
                      $t("percentage_to_change_price")
                    }}</span>
                  <span v-else> {{ $t("amount_to_change_price") }}</span>
                </div>
              </label>
              <input
                  v-model="contractPriceChangePercentage"
                  class="form-control w-100 inputFields"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1">

               <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("contract_price_update_rows_to_exclude")
                      }}</span>
                  </span>
                <span>
                {{ $t("rowToExclude") }}
              </span>
              </label>
              <el-select class="w-100" v-model="excludeRow" :placeholder="$t('select')" size="large" multiple>
                <el-option
                    v-for="(item,key) in contractRowList"
                    :key="`_list_${key}`"
                    :label="`Article number : ${item.article_number}, Description:  ${item.description}, Price : ${item.price} Quantity : ${item.delivered_quantity}`"
                    :value="item.row_id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("contract_price_update_invoice_text")
                      }}</span>
                  </span>
                <span>
                {{ $t("invoiceTextOnPriceChange") }}
              </span>
              </label>
              <textarea class="form-control " cols="5" row="5" maxlength="1000"
                        v-model="invoiceTextAfterPriceChange"></textarea>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-2">
          <span v-if="targetRow.scheduled_object">
            <button
                class="btn btn-primary"
                @click="updateContractPriceUpdate()"
            >
              {{ $t("update") }}
            </button>
            <button class="btn btn-warning" @click="deleteContractPriceUpdate">
              {{ $t("delete") }}
            </button>
          </span>

          <button
              v-else
              class="btn btn-primary mr-10px"
              @click="addContractPriceUpdate"
          >
            {{ $t("save") }}
          </button>

          <button class="btn btn-danger" @click="openPriceChangeModal = false">
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <!-- row change -->
  <ModalBoxVue
      v-if="schedulingModal"
      @handleClose="schedulingModal = false"
      :close="!true"
  >
    <div class="p-4">
      <h5>{{ $t("scheduleRowChange") }}</h5>
      <hr/>
      <div
          class="container p-3 confirmModal"
          style="text-align: left !important"
      >
        <p>{{ $t("dateOfAction") }}</p>
        <Datepicker
            class="form-control"
            v-model="scheduling.date_of_action"
            format="yyyy-MM-dd"
            style="width: 100% !important"
            :enableTimePicker="false"
            :minDate="today"
        />
        <p>{{ $t("changeType") }}</p>
        <select
            v-model="scheduling.change_type"
            style="width: 100%"
            class="inputFields"
        >
          <option value="increase_discount">{{ $t("increaseDiscount") }}</option>
          <option value="decrease_discount">{{ $t("decreaseDiscount") }}</option>
          <option value="remove_discount">{{ $t("removeDiscount") }}</option>
          <option value="increase_quantity">{{ $t("increaseDeliveredQuantity") }}</option>
          <option value="decrease_quantity">{{ $t("decreaseDeliveredQuantity") }}</option>
          <option value="increase_price">{{ $t("increasePrice") }}</option>
          <option value="decrease_price">{{ $t("decreasePrice") }}</option>
          <option value="change_article">{{ $t("changeArticle") }}</option>
          <option value="remove_row">{{ $t("removeRow") }}</option>
        </select>
        <span v-if="data.scheduled_object">
          <p></p>
          <input
              :disabled="newValueField"
              class="inputFields"
              v-model="scheduling.new_value"
              id="search"
              style="width: 100%"
              type="text"
          />
        </span>
        <span v-if="!data.scheduled_object">
          <p>{{ $t("newValue") }}</p>
          <input
              :disabled="newValueField"
              class="inputFields"
              v-model="newValue"
              id="search"
              style="width: 100%"
              type="text"
          />
        </span>
        <span v-if="data.scheduled_object">
          <p>{{ $t("changeArticle") }}</p>
          <input
              :disabled="changeValueField"
              class="inputFields"
              v-model="scheduling.change_with_value"
              id="search"
              style="width: 100%"
              type="text"
          />
        </span>
        <span v-if="!data.scheduled_object">
          <p>{{ $t("changeArticle") }}</p>

          <input
              :disabled="newValueField"
              class="inputFields"
              v-model="changeValue"
              id="search"
              style="width: 100%"
              type="text"
          />
        </span>
        <div class="confirmModalButtons mt-2">
          <button class="btn btn-outline-dark" @click="schedulingModal = false">
            {{ $t("cancel") }}
          </button>
          <button class="btn btn-primary" @click="event()">{{ $t("proceed") }}</button>
        </div>
      </div>
    </div>
  </ModalBoxVue>
</template>

<script>
import {mapGetters} from "vuex";
import debounce from "lodash.debounce";
import ScheduledTable from "./reusableComponents/ScheduledTable.vue";
import ModalBoxVue from "../../components/ModalBox.vue";
import {
  CUSTOMER_CUSTOMIZATION,
  CUSTOMER_DEFAULT,
} from "./composables/customization";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import AuthorizationBanner from "./reusableComponents/AuthorizationBanner";
import moment from "moment";
import customSelectDropDown from "../../components/customSelectDropDown";

const TODAY = moment().format("YYYY-MM-DD");
export default {
  name: "Customers",
  components: {
    ScheduledTable,
    ModalBoxVue,
    customSelectDropDown,
    VPagination,
    AuthorizationBanner,
  },
  data() {
    return {
      item_to_exclude: ["tags"],
      excludeRow: [],
      contractRowList: [],
      optionsList: [
        {value: 'All', label: 'all'},
        {value: 'handled', label: 'handled'},
        {value: 'upcoming', label: 'upcoming'},
        {value: 'event type', label: 'eventType'}],
      invoiceTextAfterPriceChange: '',
      scheduleOptionsList: [
        {key: 'Update invoice row', name: 'InvoiceRowUpdate'},
        {key: 'Send invoice', name: 'invoiceSendout'},
        {key: 'Update contract price', name: 'contractPriceChange'},
        {key: 'Cancel contract', name: 'cancelContract'},
      ],
      eventListType: [
        {key: 'contract cancellation', name: 'contractCancel'},
        {key: 'contract price change', name: 'contractPriceChange'},
        {key: 'invoice sendout', name: 'invoiceSendout'},
      ],
      contractChangeType: ['increase_percentage', 'decrease_percentage', 'increase_amount', 'decrease_amount'],
      tableHeader: [
        "Faktura-/avtalsnummer",
        "Dokumenttyp",
        "Schemalagt datum",
        "Händelsetyp",
        "Lagd händelse",
        "Status",
      ],
      customerCustomizeItem: [],
      customerTags: [],
      invoiceList: [],
      contractList: [],
      loadingData: false,
      newType: false,
      schedulingModal: false,
      scheduling: {
        row_id: "",
        contract_number: "",
        date_of_action: "",
        change_type: "",
        new_value: "",
        change_with_value: "",
      },
      targetRow: {},
      newEventType: "",
      typeSelect: "",
      selectionType: "",
      newInvoiceId: "",
      newContractId: "",
      searchFieldValue: "",
      tag: "",
      action: "",
      customFilter: "",
      applyFilter: "All",
      limit: 50,
      eventFilter: false,
      openCustomerCustomizationModal: false,
      CUSTOMER_CUSTOMIZATION: CUSTOMER_CUSTOMIZATION,
      updating: false,
      filter: null,
      toggleChangeCheckbox: false,
      openTagAddition: false,
      tagSelection: [],
      selectedData: {},
      assigning: false,
      applying: false,
      syncing: false,
      loading: true,
      scheduleAddon: {
        total_page: 1,
        total_resources: 1,
        page: 1,
        limit: 50
      },
      showAssignBtn: false,
      selectedFilter: "All",
      orderByColumn: null, // which column to order
      orderByQuery: "",
      sortableColumns: ["Document Number", "Date of Action"],
      openScheduleInvoiceSendoutModal: false,
      date_to_send: '',
      distribution_method: '',
      invoice_type: '',
      send_failed_nox_as_reg: false,
      bookkeep: false,
      openPriceChangeModal: false,
      openScheduleContractCancelationModal: false,
      contractPriceChangePercentage: '',
      contractPriceUpdateDate: '',
      contractPriceChangeType: '',
      date_to_cancel: '',
      type_of_cancellation: '',
      helpText: []

    };
  },
  watch: {
    // event type
    applyFilter(newVal, oldVal) {
      if (newVal === `event type`) {
        this.eventFilter = true;
      }
    },
    tagSelection: function (newVal, oldVal) {
      if (
          (this.selectedData && this.selectedData.customer_number) ||
          (newVal.length > 0 && this.GET_CUSTOMER_CHECKED.data.length > 0)
      ) {
        this.showAssignBtn = true;
      } else {
        this.showAssignBtn = false;
      }
    },

    openCustomerCustomizationModal(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.slist(document.getElementById("sortlist"));
        }, 200);
      }
    },

    customerCustomizeItem: function (newVal) {
      const list = document.getElementById("sortlist");
      if (list) {
        this.slist(list);
      }
    },
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DD");
    },
    ...mapGetters({
      CUSTOMERS: "clients/GET_CUSTOMERS",
      GET_SCHEDULES: "clients/GET_SCHEDULES",
      GET_CUSTOMER_CHECKED: "clients/GET_CUSTOMER_CHECKED",
      GET_PACKAGE: "clients/GET_PLAN_DETAIL",
      GET_USER_SUBSCRIPTION: "clients/GET_USER_SUBSCRIPTION",
      CURRENT_USER: "clients/GET_CURRENT_USER",
      GET_LOCALE: 'locale/GET_LOCALE',
      SCHEDULED_FILTER: 'clients/GET_SCHEDULE_FILTER'
    }),
  },
  mounted() {
    // this.getUserSubscription();
    this.getScheduleList();
    this.getHelptextList()
    window.Bus.on("reload-schedule", e => {
      this.getScheduleList()
    })
    // this.getCustomerTags();
    // this.getSchedulesHeader();
  },
  methods: {
    onPicked: function (option) {
      this.applyFilter = option.value

      if (this.applyFilter === "event type") {
        this.customFilter = ''
        this.eventFilter = true;
      } else {
        this.sentFilter()
      }

    },
    computeQueryParam() {
      if (Object.keys(this.SCHEDULED_FILTER).length <= 0) {
        return null
      }
      let payload = ''
      Object.keys(this.SCHEDULED_FILTER).map(k => {
        if (k !== 'filterType') {
          if (k === 'selectedFilter') {
            if (this.SCHEDULED_FILTER[k] !== "All") {
              payload += `&filter=${this.SCHEDULED_FILTER[k]}`
            }
            this.selectedFilter = this.SCHEDULED_FILTER[k]
          } else if (k === 'filter') {
            payload += this.SCHEDULED_FILTER[k]
            this.applyFilter = this.SCHEDULED_FILTER['filterType']
          } else {
            if (this.SCHEDULED_FILTER[k]) {
              payload += `&${k}=${this.SCHEDULED_FILTER[k]}`
            }
          }
        }

      })
      return payload

    },
    getHelpText: function (field) {
      let text = this.helpText.filter(item => item.field === field)
      if (text.length > 0) {
        if (this.GET_LOCALE === 'se') {
          return text[0].swedish_text
        } else {
          return text[0].english_text
        }
      }

    },
    onChangeContract: function (e) {
      this.newContractId = e
    },
    removeSchedule: function (scheduleID) {
      let findIndex = this.GET_SCHEDULES.findIndex(item => item.id = scheduleID)
      if (findIndex > -1) {
        this.GET_SCHEDULES.splice(findIndex, 1)
      }
    },
    onClose: function () {
      this.openScheduleContractCancelationModal = false
    },
    updateContractPriceUpdate() {
      this.$store
          .dispatch("clients/updateContractPriceUpdate", {
            id: this.targetRow.scheduled_object.id,
            date_to_update: moment(this.contractPriceUpdateDate).format(
                "YYYY-MM-DD"
            ),
            change_type: this.contractPriceChangeType,
            percentage: this.contractPriceChangePercentage,
          })
          .then((res) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    deleteContractPriceUpdate() {
      this.$store
          .dispatch(
              "clients/deleteContractPriceUpdate",
              this.targetRow.scheduled_object.id
          )
          .then((res) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    addScheduleInvoiceSendout() {
      let invoices = [];
      invoices = [this.newInvoiceId];
      this.payload = {
        invoices: invoices,
        date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
        send_channel: this.invoice_type,
        send_method: this.distribution_method,
        bookkeep: this.bookkeep,
        send_failed_nox_as_reg: this.send_failed_nox_as_reg,
      };
      this.$store
          .dispatch("clients/addScheduleInvoiceSendout", {
            invoices: invoices,
            date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
            send_channel: this.invoice_type,
            send_method: this.distribution_method,
            bookkeep: this.bookkeep,
            send_failed_nox_as_reg: this.send_failed_nox_as_reg,
          })
          .then((res) => {
            this.$emit("getSchedule");
            // this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            // this.saving = false
            // this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    getHelptextList() {
      const payload = {
        URL:
        this.$services.endpoints.HELPTEXT_ENDPOINT
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            this.helpText = res.data.results.filter(item => ['contract_price_update_price_change', 'contract_price_update_rows_to_exclude',
              'contract_price_update_invoice_text', 'invoice_row_update_type',
              'invoice_row_update_new_value', 'invoice_row_update_change_current_value_with',
            ].includes(item.field) === true)

          })
          .catch((err) => {
          });
    },
    updateScheduleInvoiceSendout() {
      this.$store
          .dispatch("clients/updateScheduleInvoiceSendout", {
            id: this.targetRow.scheduled_object.id,
            date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
            send_channel: this.invoice_type,
            send_method: this.distribution_method,
            bookkeep: this.bookkeep,
            send_failed_nox_as_reg: this.send_failed_nox_as_reg,
          })
          .then((res) => {
            this.$emit("getSchedule");
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    deleteScheduleInvoiceSendout() {
      this.$store;
      this.$store
          .dispatch(
              "clients/deleteScheduleInvoiceSendout",
              this.targetRow.scheduled_object
          )
          .then((res) => {
            this.$emit("getSchedule");
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    addScheduleContractCancelation() {
      let contracts = [];
      contracts = [this.newContractId];
      this.$store
          .dispatch("clients/addScheduleContractCancelation", {
            contracts: contracts,
            type_of_cancellation: this.type_of_cancellation,
            date_to_cancel: moment(this.date_to_cancel).format("YYYY-MM-DD"),
          })
          .then((res) => {
            this.$emit("getSchedule");
            this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
            this.type_of_cancellation = "";
            this.date_to_cancel = TODAY;
          })
          .catch((err) => {
            this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateScheduleContractCancelation() {
      this.$store
          .dispatch("clients/updateScheduleContractCancelation", {
            id: this.targetRow.scheduled_object.id,
            type_of_cancellation: this.type_of_cancellation,
            date_to_cancel: moment(this.date_to_cancel).format("YYYY-MM-DD"),
          })
          .then((res) => {
            this.$emit("getSchedule");
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    deleteScheduleContractCancelation() {
      // this function hits the DELETE route for scheduling contract cancellation
      this.$store
          .dispatch(
              "clients/deleteScheduleContractCancelation",
              this.targetRow.scheduled_object.id
          )
          .then((res) => {
            this.$emit("getSchedule");
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.scheduling = false;
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateToday() {
      let payload = {};

      payload = {
        selected: [this.newContractId],
        change_type: this.contractPriceChangeType,
        percentage_or_amount: this.contractPriceChangePercentage,
        invoice_text: this.invoiceTextAfterPriceChange,
      };
      payload['rows_to_exclude'] = []
      if (this.excludeRow.length > 0) {
        payload['rows_to_exclude'] = this.excludeRow
      }
      this.$store
          .dispatch("clients/contractPriceActionToday", {
            ...payload,
          })
          .then((res) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateLater() {
      let payload = {};
      payload = {
        contracts: [this.newContractId],
        selected: [this.newContractId],
        date_to_update: this.contractPriceUpdateDate,
        change_type: this.contractPriceChangeType,
        percentage_or_amount: this.contractPriceChangePercentage,
        invoice_text: this.invoiceTextAfterPriceChange,
      };
      payload['rows_to_exclude'] = []
      if (this.excludeRow.length > 0) {
        payload['rows_to_exclude'] = this.excludeRow
      }
      this.$store
          .dispatch("clients/createContractPriceUpdate", {
            ...payload,
          })
          .then((res) => {
            window.Bus.emit('reload-schedule')
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    addContractPriceUpdate() {
      this.contractPriceUpdateDate = moment(
          this.contractPriceUpdateDate
      ).format("YYYY-MM-DD");

      // check the update date and then call respective function
      if (this.contractPriceUpdateDate === TODAY) {
        // update immediately
        this.updateToday();
      } else {
        // schedule for later
        this.updateLater();
      }

      this.openPriceChangeModal = false;
    },
    addModals() {
      this.newType = false;
      this.typeSelect = false;
      this.targetRow = {};

      if (this.newEventType == `contract cancellation`) {
        (this.date_to_cancel = TODAY),
            (this.type_of_cancellation = ""),
            (this.openScheduleContractCancelationModal = true);
      } else if (this.newEventType == `contract price change`) {
        (this.contractPriceUpdateDate = TODAY),
            (this.contractPriceChangeType = ""),
            (this.contractPriceChangePercentage = 0),
            (this.openPriceChangeModal = true);
        let rows = this.contractList.filter(item => item.document_number === this.newContractId)
        this.excludeRow = []
        this.contractRowList = []
        if (rows.length > 0) {
          this.contractRowList = rows[0]['invoice_rows']
        }
      } else if (this.newEventType === `invoice row change`) {
        (this.scheduling = {
          // row_id: this.newInvoiceId,
          contract_number: this.newInvoiceId,
          date_of_action: TODAY,
          change_type: "",
          new_value: null,
          change_with_value: null,
        })((this.schedulingModal = true));
      } else if (this.newEventType === `invoice sendout`) {
        (this.date_to_send = TODAY),
            (this.invoice_type = ""),
            (this.distribution_method = ""),
            (this.bookkeep = false),
            (this.send_failed_nox_as_reg = false),
            (this.openScheduleInvoiceSendoutModal = true);
      }
    },
    remoteInvoiceSearch: debounce(function (query) {
      if (query) {
        this.loadingData = true
        this.newInvoiceId = "";
        this.$store
            .dispatch("clients/getInvoice", `search=${query}&limit=100`)
            .then((res) => {
              this.loadingData = false
              this.invoiceList = res.data.data
            })
            .catch((err) => {
              this.loadingData = false
            });
      }
    }, 500),
    remoteContractSearch: debounce(function (query) {
      if (query) {
        this.loadingData = true
        this.newContractId = "";
        this.$store.commit("clients/UPDATE_CONTRACTS", []);
        this.$store
            .dispatch("clients/getContracts", `search=${query}&limit=100`)
            .then((res) => {
              this.loadingData = false
              this.contractList = res.data.data
            })
            .catch((err) => {
              this.loadingData = false
            });
      }
    }, 500),
    addNew() {
      this.newType = true
    },
    typeSelectModal() {
      this.newType = false;
      this.typeSelect = true;
      this.selectionType = this.newEventType.split(" ")[0];
    },
    getUserSubscription() {
      this.$store
          .dispatch("clients/getUserSubscription")
          .then((res) => {
            this.getPackageInfo();
          })
          .catch((err) => {
          });
    },
    getPackageInfo() {
      if (this.GET_USER_SUBSCRIPTION.length > 0) {
        if (
            !["free", "Free", "FREE"].includes(
                this.GET_USER_SUBSCRIPTION[0].current_package
            )
        ) {
          const payload = {
            URL:
                this.$services.endpoints.PACKAGE_ENDPOINT +
                `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
          };
          this.$store
              .dispatch("clients/getPlanDetails", payload)
              .then((res) => {
                if (
                    !res.data.results[0].dashboard_page_access.includes(
                        "users" || "Users"
                    )
                ) {
                  window.Bus.emit("access-denied", "customers");
                }
              });
        }
        // else {
        //   window.Bus.emit("access-denied", "customers");
        // }
      } else {
        this.getUserSubscription();
      }
    },
    syncCustomer: function () {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.syncing = true;
      this.$store
          .dispatch("clients/syncResource", "customers")
          .then(() => {
            this.syncing = false;
            // this.$services.helpers.notification('Customers synchronization in progress', 'success', this)
            this.$toast.info(this.$t("sync_customers"));
          })
          .catch((err) => {
            this.syncing = false;
            // this.$services.helpers.notification('Customers could not be synchronized', 'error', this)
            this.$toast.info(this.$t("fail_sync_customers"));
          });
    },
    sortAscBy(index, item) {
      this.orderByColumn = "";
      if (item === "Document Number") {
        this.orderByColumn = "document_number";
      } else {
        this.orderByColumn = "date_of_action";
      }
      // this.orderByQuery = `?ordering=${this.orderByColumn}`;
      this.$store.commit('clients/UPDATE_SCHEDULE_FILTER', {ordering: `${this.orderByColumn}`})
      this.onChange();
    },
    sortDescBy(index, item) {
      this.orderByColumn = "";
      if (item === "Document Number") {
        this.orderByColumn = "document_number";
      } else {
        this.orderByColumn = "date_of_action";
      }
      // this.orderByQuery = `?ordering=-${this.orderByColumn}`;
      this.$store.commit('clients/UPDATE_SCHEDULE_FILTER', {ordering: `-${this.orderByColumn}`})
      this.onChange();
    },
    onChange() {
      let payload = `?limit=${this.limit}`
      let params = this.computeQueryParam()
      if (params) {
        payload += params
      }
      this.loading = true
      this.$store.dispatch("clients/getSchedules", payload).then(res => {
        this.computePageNation(res)
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },
    searchField: debounce(function (event) {
      this.$store.commit('clients/UPDATE_SCHEDULE_FILTER', {search: event.target.value})
      this.onChange()
      // if (event.target.value !== "") {
      //   this.filterSearch(event.target.value);
      //
      // } else {
      //   this.getScheduleList();
      // }
    }, 500),
    filterSearch(data) {
      this.filter = `?search=${data}`;
      this.$store.commit("clients/GET_SCHEDULES", []);
      let payload = this.filter + this.orderByQuery;
      this.loading = true
      this.$store.dispatch("clients/getSchedules", payload).then(res => {
        this.computePageNation(res)
        this.loading = false
      }).catch(() => {
        this.loading = false
      });
    },
    getScheduleList() {
      this.filter = null;
      this.selectedFilter = "All";
      this.loading = true
      let URL = `?limit=${this.scheduleAddon.limit}`
      this.$store.dispatch("clients/getSchedules", URL).then(res => {
        this.loading = false
        this.scheduleAddon.total_resources = res.data.count
        this.computePageNation(res)
      }).catch(err => {
        this.loading = false
      });
    },
    computePageNation: function (res) {
      if (res.data.count > this.scheduleAddon.limit) {
        this.scheduleAddon.total_page = Math.ceil(res.data.count / this.scheduleAddon.limit)
      } else {
        this.scheduleAddon.total_page = 1
      }
      this.scheduleAddon.total_resources = res.data.count
    },
    getCustomerTags() {
      this.$store
          .dispatch("clients/getTags", "CUSTOMER")
          .then((res) => {
            this.customerTags = res.data.results;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.commit("auth/CLEAR_AUTH_USER", null);
              window.Bus.emit("logout");
            }
          });
    },
    toggleAllCheckbox(e) {
      this.$store.commit(
          "clients/TOGGLE_ALL_CUSTOMER_CHECKBOX",
          this.toggleChangeCheckbox
      );
    },
    changePageNumber(number) {
      let payload = `?page=${number}&limit=${this.scheduleAddon.limit}`;
      let params = this.computeQueryParam()
      if (params) {
        payload += params
      }
      // if (this.filter) {
      //   payload += `&${this.filter}`;
      // }
      // if (this.applyFilter != "All") {
      //   if (this.applyFilter === "handled") {
      //     payload += `&handled=true`;
      //   } else if (this.applyFilter === "upcoming") {
      //     payload += `&handled=false`;
      //   } else if (this.applyFilter === "event type") {
      //     payload += `&event_type=${this.coustomFilter}`;
      //   }
      // }

      // payload += this.orderByQuery;
      this.$store.commit("clients/GET_SCHEDULES", []);
      this.loading = true
      this.$store.dispatch("clients/getSchedules", payload).then(res => {
        this.loading = false
        this.scheduleAddon.total_resources = res.data.count
        this.computePageNation(res)
      }).catch(() => {
        this.loading = false
      });
    },
    filterSchedules(data) {
      let payload = `?filter=${data}`;
      this.selectedFilter = data
      this.$store.commit('clients/UPDATE_SCHEDULE_FILTER', {selectedFilter: data})

      this.$store.commit("clients/GET_SCHEDULES", []);
      this.onChange()
      // this.filter = payload;
      // this.loading = true
      // this.$store.dispatch("clients/getSchedules", payload).then(res => {
      //   this.computePageNation(res)
      //   this.loading = false
      // }).catch(() => {
      //   this.loading = false
      // });
    },
    clearFilter() {
      this.applyFilter = ''
      this.customFilter = ''
      this.$store.commit('clients/CLEAR_FILTER', 'scheduled')
      document.getElementById('search').value = ''
      this.getScheduleList();
    },
    sentFilter() {
      let payload = "";
      if (this.applyFilter !== "All") {
        if (this.applyFilter === "handled") {
          payload = `&handled=true`;
        } else if (this.applyFilter === "upcoming") {
          payload = `&handled=false`;
        } else if (this.applyFilter === "event type") {
          payload = `&event_type=${this.customFilter}`;
        }
      }
      this.$store.commit('clients/UPDATE_SCHEDULE_FILTER', {filter: payload, filterType: this.applyFilter})

      this.onChange()
      // this.$store.dispatch("clients/getSchedules", payload + this.orderByQuery).then(res => {
      //   this.computePageNation(res)
      //   this.loading = false
      // }).catch(() => {
      //   this.loading = false
      // });
      this.eventFilter = false
    },

  },
};
</script>

<style lang="scss" scoped>
// TODO: change styling for active-filter class
.active-filter {
  box-shadow: 5px 5px 3px #888888 !important;
}

@-webkit-keyframes disable-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
}

@keyframes disable-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
}

.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .headers a:hover svg path {
  fill: #4946ae;
}

.content .headers span {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
}

.content nav {
  margin: 20px 0 0 5px;
}

@media (max-width: 1094px) {
  .content nav .row {
    display: block;
  }
  .content nav .row > div:last-of-type {
    margin-top: 10px;
  }
  .content nav .row ul {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content nav .row ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.content nav ul {
  margin: 0;
  text-align: center;
  margin-top: 10px;
}

.content nav ul li {
  border-radius: 4px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  width: 100px;
  height: 32px;
  -webkit-transition: -webkit-transform 0.35s ease-in-out,
  -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.35s ease-in-out,
  -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out,
  -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.content nav ul li:hover {
  -webkit-transform: translateY(-0.25em);
  -ms-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.content nav ul li.all {
  background: #c0beff;
}

.content nav ul li.all:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
  box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
}

.content nav ul li.enabled {
  background: #419e6a;
}

.text-green {
  color: #419e6a;
}

.text-yellow {
  color: #efb008;
}

.content nav ul li.enabled:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
  box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
}

.content nav ul li.inactive {
  background: #efb008;
}

.content nav ul li.inactive:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #efb008;
  box-shadow: 0 0.5em 0.5em -0.1em #efb008;
}

.content nav ul li:not(:last-of-type) {
  margin-right: 15px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.list-summary {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0px;
  font-weight: 600;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.el-clickable {
  cursor: pointer !important;
}

.content nav .right-parent .filter {
  margin-top: 10px;
  margin-right: 20px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 207.73px
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 77%;
  margin-left: auto;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

.content nav .right-parent .customize {
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .customize {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .customize {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.content nav .right-parent .customize a {
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content nav .right-parent .customize a svg {
  margin-right: 10px;
}

.content nav .right-parent .customize a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content nav .right-parent .customize a:hover {
  color: #4946ae;
}

.content nav .right-parent .customize a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
  0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
  0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

@media (max-width: 767.98px) {
  .content .box {
    margin-top: 10px;
  }
}

.content .box table {
  width: 100%;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .content .box table {
    min-width: 1000px;
  }
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 5px;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead tr {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr.inactive td:last-of-type button:nth-of-type(1) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td .email {
  padding: 10px 5px;
}

.content .box table tbody td .email input {
  background: #f0eff5;
  border: 1px solid #c8c7cd;
  padding: 2px 12px;
  width: 153px;
}

.content .box table tbody td .email input:focus {
  border: 1px solid #c8c7cd;
  outline: none;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td span {
  border-radius: 4px;
  min-width: 79.24px;
  display: inline-block;
  text-align: center;
}

.content .box table tbody td span.inactive {
  background: #ffde81;
  color: #976400;
}

.content .box table tbody td span.enabled {
  background: #a5e1bf;
  color: #00632b;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button:last-of-type {
  margin-left: 5px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  text-indent: 4px;
  padding-left: 3px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

.sync-btn {
  height: 30px;
  width: 31px;
  position: relative;
  margin-left: 10px;
}

.sync-btn i {
  position: absolute;
  top: 3px;
  left: 4px;
  font-size: 20px !important;
}

.content {
  padding: 90px 30px 10px !important;
}

.form select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 42px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.form select.big {
  width: 192px;
}

.form .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.formc .radio input {
  display: none;
}

.form.radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.form .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.form .radio label:first-of-type {
  margin-right: 10px;
}


.form button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.form button:hover {
  background-color: #4947b0;
  color: #fff;
}

.form textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.form textarea:focus {
  border: none;
  outline: none;
}

.form .inputFields {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 42px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;

}

.form .inputFields.big {
  width: 192px;
}

.form .inputFields:-moz-read-only {
  background: #f0eff5;
}

.form .inputFields:read-only {
  background: #f0eff5;
}

.form .inputFields:focus {
  outline: none;
}

.form .inputFields.input[type="date"] {
  text-indent: 5px;
}

.form .inputFields.input[type="date"]::-webkit-clear-button,
.form .inputFields.input[type="date"]::-webkit-inner-spin-button,
.form .inputFields.input[type="date"]::-webkit-calendar-picker-indicator,
.form .inputFields.input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.form .inputFields.input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.form .inputFields.input[type="number"]::-webkit-outer-spin-button,
.form .inputFields.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

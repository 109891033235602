<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t('customers') }}</h1>
          <h6>{{ $t('create_new_customer') }}</h6>
        </div>
        <div class="customer-showcase">
          <h2>{{ $t('customer') }}</h2> <!--134 - John Doe-->
          <ul class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center">
            <li :class="tab === 1 ? 'active' : ''"><a @click="tab = 1" href="javascript:void(0);">{{
                $t('basic_detail')
              }}</a></li>
            <li :class="tab === 2 ? 'active' : ''"><a @click="tab = 2"
                                                      href="javascript:void(0);">{{ $t('invoice_detail') }}</a>
            </li>
          </ul>
        </div>
        <div v-if="tab === 1">
          <div class="box customer-details">
            <h3>{{ $t('customer_detail') }}</h3>
            <form class="row">
              <div class="col">
                <div class="desc">
                  <label>{{ $t('customer_number') }}</label>
                  <input type="number" min="0" v-model="customerData.customer_number">
                </div>
                <div class="desc">
                  <label>{{ $t('name') }}</label>
                  <input type="text" v-model="customerData.name">
                </div>
                <div class="desc">
                  <label>{{ $t('invoice_address') }} 1</label>
                  <input type="text" v-model="customerData.address1">
                </div>
                <div class="desc">
                  <label>{{ $t('invoice_address') }} 2</label>
                  <input type="text" v-model="customerData.address2">
                </div>
              </div>
              <div class="col">
                <div class="desc">
                  <label>{{ $t('customer') }} {{ $t('type') }}</label>
                  <!-- <SwitchButton v-model="customerData.type" :switchLong='customerData.type' :long="true" :longValues="['COMPANY', 'PRIVATE']" /> -->
                  <div class="radio">
                    <input type="radio" v-model="customerData.type" value="COMPANY" name="customer-type" id="company">
                    <label for="company">{{ $t('company') }}</label>
                    <input type="radio" v-model="customerData.type" value="PRIVATE" name="customer-type" id="private">
                    <label for="private">{{ $t('private') }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('phone') }}</label>
                  <input type="text" v-model="customerData.phone1">
                </div>
                <div class="desc">
                  <label>{{ $t('phone') }} 2</label>
                  <input type="text" v-model="customerData.phone2">
                </div>
                <div class="parent-small d-flex">
                  <div class="desc">
                    <label>{{ $t('zip_code') }}</label>
                    <input class="small" type="text" v-model="customerData.zip_code">
                  </div>
                  <div class="desc">
                    <label>{{ $t('city') }}</label>
                    <input class="small" type="text" v-model="customerData.city">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="desc">
                  <label>{{ $t('organization') }}-/{{ $t('personal') }} {{ $t('number') }}</label>
                  <input type="text" v-model="customerData.organisation_number">
                </div>
                <div class="desc">
                  <label>{{ $t('email') }}</label>
                  <input type="email" v-model="customerData.email">
                </div>
                <div class="desc">
                  <label>{{ $t('fax') }}</label>
                  <input type="text" v-model="customerData.fax">
                </div>
                <div class="desc">
                  <label>{{ $t('country') }}</label>
                  <select v-model="customerData.country_code">
                    <option value=""></option>
                    <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                  </select>
                </div>

              </div>
              <div class="col">
                <!--                <div class="desc">-->
                <!--                  <label>Country Code</label>-->
                <!--                  <select v-model="customerData.country_code">-->
                <!--                    <option value=""></option>-->
                <!--                    <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>-->
                <!--                  </select>-->
                <!--                </div>-->
                <div class="desc">
                  <label>{{ $t('active') }}</label>
                  <!-- <SwitchButton v-model="customerData.active" :switchValue='customerData.active' /> -->
                  <div class="radio">
                    <input type="radio" v-model="customerData.active" :value="true" name="active" id="ac-yes-active">
                    <label for="ac-yes-active">{{ $t('yes') }}</label>
                    <input type="radio" v-model="customerData.active" :value="false" name="active" id="ac-no-active">
                    <label for="ac-no-active">{{ $t('no') }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('web_address') }}</label>
                  <input type="text" v-model="customerData.www">
                </div>
              </div>
            </form>
          </div>
          <div class="row">
            <div class="col-md">
              <div class="box delivery">
                <h3>{{ $t('delivery_address') }}</h3>
                <form class="row">
                  <div class="col">
                    <div class="desc">
                      <label>{{ $t('name') }}</label>
                      <input type="text" v-model="customerData.delivery_name">
                    </div>
                    <div class="desc">
                      <label>{{ $t('delivery_address') }} 1</label>
                      <input type="text" v-model="customerData.delivery_address1">
                    </div>
                    <div class="desc">
                      <label>{{ $t('delivery_address') }} 2 </label>
                      <input type="text" v-model="customerData.delivery_address2">
                    </div>
                  </div>
                  <div class="col">
                    <div class="desc">
                      <label>{{ $t('phone') }} 1</label>
                      <input type="text" v-model="customerData.delivery_phone1">
                    </div>
                    <div class="desc">
                      <label>{{ $t('phone') }} 2</label>
                      <input type="text" v-model="customerData.delivery_phone2">
                    </div>
                    <div class="parent-small d-flex">
                      <div class="desc">
                        <label>{{ $t('zip_code') }}</label>
                        <input class="small" type="text" v-model="customerData.delivery_zip_code">
                      </div>
                      <div class="desc">
                        <label>{{ $t('city') }}</label>
                        <input class="small" type="text" v-model="customerData.delivery_city">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md">
              <div class="box visiting">
                <h3>{{ $t('visiting_address') }}</h3>
                <form class="row">
                  <div class="col">
                    <!-- <div class="desc">
                        <label>Name</label>
                        <input type="text" value="">
                    </div> -->
                    <div class="desc">
                      <label>{{ $t('visiting_address') }} </label>
                      <input type="text" v-model="customerData.visiting_address">
                    </div>
                    <!--                    <div class="desc">-->
                    <!--                      <label>Visiting address 2</label>-->
                    <!--                      <input type="text" v-model="customerData.visiting_address">-->
                    <!--                    </div>-->
                    <div class="parent-small d-flex">
                      <div class="desc">
                        <label>{{ $t('zip_code') }}</label>
                        <input class="small" type="text" v-model="customerData.visiting_zip_code">
                      </div>
                      <div class="desc">
                        <label>{{ $t('city') }}</label>
                        <input class="small" type="text" v-model="customerData.visiting_city">
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="desc">
                      <label>{{ $t('country') }}</label>
                      <select v-model="customerData.visiting_country">
                        <option value=""></option>
                        <option :value="item.name" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                      </select>
                    </div>
                    <div class="desc">
                      <label>{{ $t('visiting_country_code') }}</label>
                      <select v-model="customerData.visiting_country_code">
                        <option value=""></option>
                        <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
                      </select>
                    </div>
                    <!-- <div class="parent-small d-flex">
                        <div class="desc">
                        <label>Zip code</label>
                        <input class="small" type="text" v-model="customerData.visiting_zip_code">
                        </div>
                        <div class="desc">
                        <label>City</label>
                        <input class="small" type="text" v-model="customerData.visiting_city">
                        </div>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="box customer-details">
            <h3>{{ $t('more_customer_detail') }}</h3>
            <form class="row">
              <div class="col-md-4 col-lg-7 d-flex justify-content-lg-between flex-wrap">
                <!-- <div class="left">
                    <div class="desc">
                    <label>Indisutry Code (SNI)</label>
                    <input type="text" value="">
                    </div>
                    <div class="desc">
                    <label>Place of work no. (CFAR)</label>
                    <input type="text" value="">
                    </div>
                    <div class="desc">
                    <label>Shop ID</label>
                    <input type="text" value="">
                    </div>
                </div> -->
                <div class="desc">
                  <label>{{ $t('note') }}</label>
                  <textarea v-model='customerData.comments'></textarea>
                </div>
              </div>
              <div class="col-md col-lg"><img class="mx-auto me-md-0 ms-auto"
                                              src="./../../assets/images/table/more-customer-details.png"></div>
            </form>
          </div>
          <div class="buttons flex-wrap align-items-center">
            <router-link :to="{ name : 'customers' }">{{ $t('cancel') }}</router-link>
            <button @click="tab = 2" type="submit">
              <span>{{ $t('continue') }}</span>
            </button>
          </div>
        </div>
        <div v-if="tab === 2">
          <div class="box invoice-details">
            <h3>{{ $t('invoice') }} {{ $t('detail') }}</h3>
            <form class="row">
              <div class="col">
                <h4>{{ $t('payment_and_delivery_terms') }}</h4>
                <div class="desc">
                  <label>{{ $t('terms_of_payment') }}</label>
                  <select v-model="customerData.terms_of_payment">
                    <option value=""></option>
                    <option :value="item.code" v-for="item in paymentTerm" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('terms_of_delivery') }}</label>
                  <select v-model="customerData.terms_of_delivery">
                    <option value=""></option>
                    <option :value="item.code" v-for="item in deliveryTerm" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('way_of_delivery') }}</label>
                  <select v-model="customerData.way_of_delivery">
                    <option value=""></option>
                    <option :value="item.code" v-for="item in deliveryWay" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <!--                <div class="desc">-->
                <!--                  <label>Interest billing</label>-->
                <!--                  <div class="radio">-->
                <!--                    <input type="radio" v-model="customerData.inter" :value="true" name="interest-billing" id="billing-yes" checked>-->
                <!--                    <label for="billing-yes">Yes</label>-->
                <!--                    <input type="radio" :value="false" name="interest-billing" id="billing-no">-->
                <!--                    <label for="billing-no">No</label>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="col">
                <h4>{{ $t('invoicing') }}</h4>
                <div class="parent-small d-flex">
                  <div class="desc">
                    <label>{{ $t('price_list') }}</label>
                    <select v-model="customerData.price_list" class="small">
                      <option value=""></option>
                      <option :value="item.code" v-for="item in pricelist" :key="item.id">{{
                          item.description
                        }}
                      </option>
                    </select>
                  </div>
                  <div class="desc">
                    <label>{{ $t('currency') }}</label>
                    <select v-model="customerData.currency" class="small">
                      <option value=""></option>
                      <option :value="item.code" v-for="item in currency" :key="item.id">{{ item.code }}</option>
                    </select>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('invoice_discount') }} (%)</label>
                  <input v-model="customerData.invoice_discount" type="text">
                </div>
                <!-- <div class="parent-small d-flex">
                  <div class="desc">
                    <label>Invoice charge</label>
                    <input class="small" type="text" value="">
                  </div>
                  <div class="desc">
                    <label>Shipping charge</label>
                    <input class="small" type="text" value="">
                  </div>
                </div> -->
                <div class="desc">
                  <label>{{ $t('show_price_vat_included') }}</label>
                  <div class="radio">
                    <input v-model="customerData.show_price_vat_included" type="radio" :value="true" name="price-vat"
                           id="vat-yes">
                    <label for="vat-yes">{{ $t('yes') }}</label>
                    <input v-model="customerData.show_price_vat_included" type="radio" :value="false" name="price-vat"
                           id="vat-no">
                    <label for="vat-no">{{ $t('no') }}</label>
                  </div>
                </div>
              </div>
              <div class="col">
                <h4>{{ $t('references') }}</h4>
                <div class="desc">
                  <label>{{ $t('our_reference') }}</label>
                  <input v-model="customerData.our_reference" type="text">
                </div>
                <div class="desc">
                  <label>{{ $t('your_reference') }}</label>
                  <input v-model="customerData.your_reference" type="text">
                </div>
                <!--                <div class="desc">-->
                <!--                  <label>Customer manage</label>-->
                <!--                  <input type="text" value="">-->
                <!--                </div>-->
                <div class="desc">
                  <label>{{ $t('external_reference') }}</label>
                  <input v-model="customerData.external_reference" type="text">
                </div>
              </div>
              <div class="col">
                <h4>{{ $t('accounting') }}</h4>
                <div class="desc">
                  <label>{{ $t('web_address') }}</label>
                  <input v-model="customerData.www" type="text">
                </div>
                <div class="desc">
                  <label>{{ $t('type_of_vat') }}</label>
                  <select v-model="customerData.vat_type">
                    <option value=""></option>
                    <option value="SEVAT">SE</option>
                    <option value="SEREVERSEDVAT">SE reverse Vat</option>
                    <option value="EUREVERSEDVAT">EU reverse Vat</option>
                    <option value="EUVAT">Subject to EU VAT</option>
                    <option value="EXPORT">Export</option>
                  </select>
                </div>
                <div class="parent-small d-flex">
                  <div class="desc">
                    <label>{{ $t('sales_account') }}</label>
                    <!-- <select v-model="customerData.sales_account" class="small">
                          <option :value="item.number" v-for="item in accounts" :key="item.id">{{ item.description }}</option>
                    </select> -->
                    <input class="small" @input="searchField($event, 'sales')" type="text"
                           v-model="customerData.sales_account">
                    <div v-if="showSalesAccount" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'sales')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="desc">
                    <label>{{ $t('vat_number') }}</label>
                    <input class="small" v-model="customerData.vat_number" type="text">
                  </div>
                </div>
                <div class="parent-small d-flex">
                  <div class="desc">
                    <label>{{ $t('cost_center') }} (CC)</label>
                    <select v-model="customerData.cost_center" class="small">
                      <option value=""></option>
                      <option :value="item.code" v-for="item in costCenter" :key="item.id">{{
                          item.description
                        }}
                      </option>
                    </select>
                  </div>
                  <div class="desc">
                    <label>{{ $t('project') }} (Pr)</label>
                    <select v-model="customerData.project" class="small">
                      <option value=""></option>
                      <option :value="item.code" v-for="item in project" :key="item.id">{{ item.description }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="box">
            <div class="row g-0">
              <div @click="removeAccountBox" v-if="showSalesAccount" class="position-absolute inset-0"
                   style="z-index:999"></div>
              <div class="col col-xl-9 e-invoice-details">
                <h3>{{ $t('e_invoice_detail') }}</h3>
                <form class="row">
                  <div class="col-xl-4 col d-flex flex-wrap">
                    <h4 class="m-0" style="height: 26px;">{{ $t('invoice') }}</h4>
                    <!-- <div class="headers-e w-100">
                      
                    </div> -->
                    <div class="desc" style="min-height: 70px;">
                      <label>{{ $t('default_delivery_type') }}</label>
                      <div class="radio">
                        <input v-model="customerData.default_delivery_types.invoice" type="radio" value="EMAIL"
                               name="delivery-type" id="type-email">
                        <label for="type-email">{{ $t('email') }}</label>
                        <input v-model="customerData.default_delivery_types.invoice" type="radio" value="PRINT"
                               name="delivery-type" id="type-print">
                        <label for="type-print">{{ $t('print') }}</label>
                        <input v-model="customerData.default_delivery_types.invoice" type="radio"
                               value="ELECTRONICINVOICE" name="delivery-type" id="type-invoice">
                        <label for="type-invoice">{{ $t('e_invoice') }}</label>
                      </div>
                    </div>
                    <div class="desc">
                      <label>{{ $t('email') }}</label>
                      <input v-model="customerData.email_invoice" type="text">
                    </div>
                    <div class="desc">
                      <label>{{ $t('copy') }}</label>
                      <input v-model="customerData.email_invoice_cc" type="text">
                    </div>
                    <div class="desc">
                      <label>{{ $t('confidential') }} {{ $t('copy') }}</label>
                      <input v-model="customerData.email_invoice_bcc" type="text">
                    </div>
                    <div class="desc">
                      <label>{{ $t('gln') }} {{ $t('number') }}</label>
                      <input v-model="customerData.gln" type="text">
                    </div>
                    <div class="desc">
                      <label>{{ $t('gln') }} {{ $t('number') }} {{ $t('for') }} {{ $t('delivery') }}</label>
                      <input v-model="customerData.gln_delivery" type="text">
                    </div>
                  </div>
                  <div class="col-xl-8 col">
                    <div class="row">
                      <div class="col d-flex flex-wrap">
                        <h4 class="w-100">{{ $t('quote') }}</h4>
                        <div class="desc w-100">
                          <label>{{ $t('default_delivery_type') }}</label>
                          <div class="radio">
                            <input v-model="customerData.default_delivery_types.offer" type="radio" value="EMAIL"
                                   name="delivery-type2" id="type-email2">
                            <label for="type-email2">{{ $t('email') }}</label>
                            <input v-model="customerData.default_delivery_types.offer" type="radio" value="PRINT"
                                   name="delivery-type2" id="type-print2">
                            <label for="type-print2">{{ $t('print') }}</label>
                          </div>
                        </div>
                        <br>
                        <div class="desc">
                          <label>{{ $t('email') }}</label>
                          <input v-model="customerData.email_offer" type="text">
                        </div>
                        <div class="desc">
                          <label>{{ $t('copy') }}</label>
                          <input v-model="customerData.email_offer_cc" type="text">
                        </div>
                        <div class="desc">
                          <label>{{ $t('confidential') }} {{ $t('copy') }}</label>
                          <input v-model="customerData.email_offer_bcc" type="text">
                        </div>
                      </div>
                      <div class="col d-flex flex-wrap">
                        <h4 class="w-100">{{ $t('order') }}</h4>
                        <div class="desc w-100">
                          <label>{{ $t('default_delivery_type') }}</label>
                          <div class="radio">
                            <input v-model="customerData.default_delivery_types.order" type="radio" value="EMAIL"
                                   name="delivery-type3" id="type-email3">
                            <label for="type-email3">{{ $t('email') }}</label>
                            <input v-model="customerData.default_delivery_types.order" type="radio" value="PRINT"
                                   name="delivery-type3" id="type-print3">
                            <label for="type-print3">{{ $t('print') }}</label>
                          </div>
                        </div>
                        <br>
                        <div class="desc">
                          <label>{{ $t('email') }}</label>
                          <input v-model="customerData.email_order" type="text">
                        </div>
                        <div class="desc">
                          <label>{{ $t('print') }}</label>
                          <input v-model="customerData.email_order_cc" type="text">
                        </div>
                        <div class="desc">
                          <label>{{ $t('confidential') }} {{ $t('copy') }}</label>
                          <input v-model="customerData.email_order_bcc" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="desc">
                      <label>{{ $t('invoice_remark') }}</label>
                      <textarea v-model="customerData.invoice_remark"></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col col-xl-3">
                <h3 class="templates">{{ $services.helpers.capitalizeNames($t('templates')) }}</h3>
                <form class="d-flex flex-wrap">
                  <h4 class="w-100">{{ $t('preselected_templates') }}</h4>
                  <div class="w-100">
                  <div class="parent-small desc">
                    <h5 class="w-100">{{ $t('invoice') }}</h5>
                    <div class="d-flex">
                      <div class="desc">
                        <label>{{ $services.helpers.capitalizeNames($t('templates')) }}</label>
                        <select class="w-100" v-model="customerData.default_templates.invoice">
                          <option value=""></option>
                          <option v-for="(item, i) in printOutInvoiceTem" :value="item.template" :key="i">{{
                              item.name
                            }}
                          </option>
                        </select>
                      </div>
                      <!-- <div class="desc">
                        <label>Language</label>
                        <select class="small">
                          <option> </option>
                        </select>
                      </div> -->
                    </div>
                  </div>
                  <div class="parent-small desc">
                    <h5 class="w-100">{{ $t('quote') }}</h5>
                    <div class="d-flex">
                      <div class="desc">
                        <label>{{ $services.helpers.capitalizeNames($t('templates')) }}</label>
                        <select class="w-100" v-model="customerData.default_templates.offer">
                          <option value=""></option>
                          <option v-for="(item, i) in printOutQuoteTerm" :value="item.template" :key="i">{{
                              item.name
                            }}
                          </option>

                        </select>
                      </div>
                      <!-- <div class="desc">
                        <label>Language</label>
                        <select class="small">
                          <option> </option>
                        </select>
                      </div> -->
                    </div>
                  </div>
                  </div>
                  <div class="w-100">
                  <div class="parent-small desc">
                    <h5 class="w-100">{{ $t('order') }}</h5>
                    <div class="d-flex">
                      <div class="desc">
                        <label>{{ $services.helpers.capitalizeNames($t('templates')) }}</label>
                        <select class="w-100" v-model="customerData.default_templates.order">
                          <option value=""></option>
                          <option v-for="(item, i) in printOutOrderTem" :value="item.template" :key="i">{{
                              item.name
                            }}
                          </option>
                        </select>
                      </div>
                      <!-- <div class="desc">
                        <label>Language</label>
                        <select class="small">
                          <option> </option>
                        </select>
                      </div> -->
                    </div>
                  </div>
                  <div class="parent-small desc">
                    <h5 class="w-100">{{ $t('cash_invoice') }}</h5>
                    <div class="d-flex">
                      <div class="desc">
                        <label>{{ $t('templates') }}</label>
                        <select class="w-100" v-model="customerData.default_templates.cash_invoice">
                          <option value=""></option>
                          <option v-for="(item, i) in printOutCashTerm" :value="item.template" :key="i">{{
                              item.name
                            }}
                          </option>
                        </select>
                      </div>
                      <!-- <div class="desc">
                        <label>Language</label>
                        <select class="small">
                          <option> </option>
                        </select>
                      </div> -->
                    </div>
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="buttons flex-wrap align-items-center">
            <a href="javascript:void(0);" @click="tab = 1">{{ $t('back') }}</a>
            <button :disabled='processing' @click="createCustomer" type="submit">
              <span v-if="processing">{{ $t('creating') }}...</span>
              <span v-else>{{ $t('create_customer') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import {customerData} from './composables/customer.create'
import {getCountries} from './composables/countries'
import {mapGetters} from "vuex"

export default {
  name: 'CreateCustomer',
  // components : { SwitchButton },
  data() {
    return {
      tab: 1,
      customerData: customerData,
      processing: false,
      paymentTerm: [],
      deliveryTerm: [],
      deliveryWay: [],
      pricelist: [],
      currency: [],
      accounts: [],
      costCenter: [],
      project: [],
      countries: getCountries(),
      printOutInvoiceTem: [],
      printOutOrderTem: [],
      printOutQuoteTerm: [],
      printOutCashTerm: [],
      showSalesAccount: false,
    }
  },
  mounted() {
    this.getDeliveryTerm()
    this.getPriceList()
    this.getPaymentTerm()
    this.getDeliveryWay()
    this.getCurrency()
    this.getAccount()
    this.getCostCenter()
    this.getProjects()
    this.getPrintOutTemplate('invoice')
    this.getPrintOutTemplate('order')
    this.getPrintOutTemplate('offer')
    this.getPrintOutTemplate('cashinvoice')
    this.ordering()
  },
  methods: {
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            if (mode === 'sales') {
              this.showSalesAccount = true
            }
          })
          .catch(err => {

          })
    },
    ordering() {
      const URL = this.$services.endpoints.CUSTOMER_ENDPOINT + '?ordering=-1'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            if (res.data.data) {
              const array = res.data.data.map(el => el.customer_number)
              this.customerData.customer_number = parseInt(Math.max(...array) + 1)
            } else {
              this.customerData.customer_number = 1
            }
          })
          .catch(err => {
          })
    },
    chooseSales(item, mode) {
      if (mode === 'sales') {
        this.customerData.sales_account = item.number
        this.showSalesAccount = false
      }
    },
    createCustomer() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      if (this.customerData.customer_number === '' || this.customerData.name === '') {
        // return this.$services.helpers.notification('Customer number and name is required', 'error', this)
        return this.$toast.warning(this.$t('customer_number_name_required'));
      } else {
        // process data before sending to BE
        this.processing = true
        let default_templates = {}
        let default_delivery_types = {}
        let payload = this.customerData
        let data = {}
        for (const [key, value] of Object.entries(payload)) {
          if (!['default_templates', 'default_delivery_types'].includes(key)) {
            if (value !== null && value !== '' && value !== undefined) {
              data[key] = value
            }
          }
        }
        for (const [key, value] of Object.entries(payload.default_templates)) {
          if (value !== null && value !== '' && value !== undefined) {
            default_templates[key] = value
          }
        }
        for (const [key, value] of Object.entries(payload.default_delivery_types)) {
          if (value !== null && value !== '' && value !== undefined) {
            default_delivery_types[key] = value
          }
        }
        if (Object.keys(default_templates).length > 0) {
          data['default_templates'] = default_templates
        }
        if (Object.keys(default_delivery_types).length > 0) {
          data['default_delivery_types'] = default_delivery_types
        }
        this.$store.dispatch('clients/createCustomer', data)
            .then(res => {
              this.processing = false
              this.$services.helpers.notification(res.data.message, 'success', this)
            })
            .catch(err => {
              this.processing = false

              if ([400, 406].includes(err.response.status)) {
                if ('error' in err.response.data) {
                  if (Array.isArray(err.response.data.error)) {
                    this.$services.helpers.notification(err.response.data.error.join(','), 'error', this)
                  } else if (typeof err.response.data.error === 'object') {
                    for (const [key, value] of Object.entries(err.response.data.error)) {
                      if (Array.isArray(value)) {
                        this.$services.helpers.notification(key.replace('_', ' ') + ': ' + value.join(','), 'error', this)
                      } else {
                        this.$services.helpers.notification(value, 'error', this)
                      }
                      return false
                    }
                  } else {
                    this.$services.helpers.notification(err.response.data.error, 'error', this)
                  }
                } else if ('message' in err.response.data) {
                  if (Array.isArray(err.response.data.message)) {
                    this.$services.helpers.notification(err.response.data.message.join(''), 'error', this)
                  } else {
                    this.$services.helpers.notification(err.response.data.message, 'error', this)
                  }
                }
              } else {
                // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
                this.$toast.error(this.$t('request_failure'));
              }
            })
      }
    },
    getDeliveryTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'termsofdelivery'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.deliveryTerm = res.data.results
          })
          .catch(err => {
          })
    },
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'pricelists'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.pricelist = res.data.results
          })
          .catch(err => {
          })
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'termsofpayment'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.paymentTerm = res.data.results
          })
          .catch(err => {
          })
    },
    getDeliveryWay() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'wayofdelivery'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.deliveryWay = res.data.results
          })
          .catch(err => {
          })
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'currencies'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.currency = res.data.results
          })
          .catch(err => {
          })
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'accounts'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.accounts = res.data.results
          })
          .catch(err => {
          })
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'costcenters'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.costCenter = res.data.results
          })
          .catch(err => {
          })
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'projects'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.project = res.data.results
          })
          .catch(err => {
          })
    },
    getPrintOutTemplate(data) {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + `printtemplates?type=${data}`
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            if (data === 'order') {
              this.printOutOrderTem = res.data.results
            } else if (data === 'invoice') {
              this.printOutInvoiceTem = res.data.results
            } else if (data === 'cashinvoice') {
              this.printOutCashTerm = res.data.results
            } else if (data === 'offer') {
              this.printOutQuoteTerm = res.data.results
            }
          })
          .catch(err => {
          })
    },
    removeAccountBox() {
      this.showSalesAccount = false
    }
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .customer-showcase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 638px) {
  .content .customer-showcase {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.content .customer-showcase h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .customer-showcase ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.content .customer-showcase ul li.active {
  pointer-events: none;
}

.content .customer-showcase ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.content .customer-showcase ul li:first-of-type a {
  margin-right: 10px;
  padding-right: 10px;
}

.content .customer-showcase ul li:last-of-type a {
  margin-left: 10px;
  padding-left: 10px;
}

.content .customer-showcase ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.content .customer-showcase ul p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.customer-details h3 {
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.delivery h3 {
  background-image: url("./../../assets/images/table/delivery.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.visiting h3 {
  background-image: url("./../../assets/images/table/visiting.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

.content .box form .parent-small .desc:last-of-type {
}

.content .box form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 15px !important;
}

@media (max-width: 991.98px) {
  .content .box form .desc {
    /*margin: 5px;*/
  }
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 192px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 576px) {
  .content .box form .desc input {
    width: 98%;
  }
}

.content .box form .desc input.small {
  width: 91px;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box form textarea {
  background: #f0eff5;
  width: 303px;
  height: 159px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
}

@media (max-width: 767.98px) {
  .content .box form textarea {
    width: 90%;
    min-width: 200px;
  }
}

.content .box form textarea:focus {
  border: none;
  outline: none;
}

.content .box form .left {
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .content .box form .left {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .content .box form .left ~ .desc {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .content .box form .left input {
    width: 70%;
    min-width: 192px;
  }
}

.content .box form img {
  max-width: 100%;
  display: block;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.content .buttons a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box.invoice-details h3 {
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box .e-invoice-details h3 {
  background-image: url("./../../assets/images/table/e-invoice.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box form .headers-e {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .box form .headers-e h4 {
  margin: 0;
}

.content .box form .headers-e span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.content .box form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 5px 5px 5px 0;
  margin-bottom: 20px;
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 100%;
  height: 24px;
  border: none;
  text-indent: 5px;
}

@media (max-width: 576px) {
  .content .box form .desc select {
    width: 98%;
  }
}

.content .box form .desc select.small {
  width: 91px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 192px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 576px) {
  .content .box form .desc input {
    width: 98%;
  }
}

.content .box form .desc input.small {
  width: 91px;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box form textarea {
  background: #f0eff5;
  width: 451px;
  height: 159px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

@media (min-width: 1200px) {
  .content .box form textarea {
    width: -webkit-calc(50% + 207px);
    width: calc(50% + 207px);
  }
}

@media (max-width: 767.98px) {
  .content .box form textarea {
    width: 400px;
  }
}

@media (max-width: 575.98px) {
  .content .box form textarea {
    width: 300px;
    height: 139px;
  }
}

.content .box form textarea:focus {
  border: none;
  outline: none;
}

.content .box form .parent-small .desc:last-of-type {
  /*margin-left: 10px;*/
}

.content .box form .parent-small h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin-bottom: 10px;
}

.content .box form h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
}

</style>
<template>

  <td>
    {{ schedule.contract_number }}
  </td>
  <td>{{ formatDateTime(schedule.last_update) }}</td>
  <td>
    {{ $t(getUpdateType()) }}
  </td>
  <td>
    <span v-if="schedule.status === 'ongoing'" class="badge w-100 handled text-white">{{ $t('ongoing') }}</span>
    <span v-else class="badge success w-100 text-white ">{{ $t('completed') }}</span>
  </td>
  <td class="d-flex ell-center">
<span class="cursor-pointer" v-on:click="onDelete">
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M6.41699 19.25C5.91283 19.25 5.48138 19.0706 5.12266 18.7119C4.76333 18.3526 4.58366 17.9208 4.58366 17.4167V5.5H3.66699V3.66667H8.25033V2.75H13.7503V3.66667H18.3337V5.5H17.417V17.4167C17.417 17.9208 17.2376 18.3526 16.8789 18.7119C16.5196 19.0706 16.0878 19.25 15.5837 19.25H6.41699ZM15.5837 5.5H6.41699V17.4167H15.5837V5.5ZM8.25033 15.5833H10.0837V7.33333H8.25033V15.5833ZM11.917 15.5833H13.7503V7.33333H11.917V15.5833ZM6.41699 5.5V17.4167V5.5Z"
    fill="#F24E1E"/>
</svg>

</span>
    <span class="cursor-pointer" v-on:click="onShowContractPopUp">
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M4.16667 18.3337C3.70833 18.3337 3.31583 18.1706 2.98917 17.8445C2.66306 17.5178 2.5 17.1253 2.5 16.667V5.00033C2.5 4.54199 2.66306 4.14977 2.98917 3.82366C3.31583 3.49699 3.70833 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.2917 3.33366 16.6842 3.49699 17.0108 3.82366C17.3369 4.14977 17.5 4.54199 17.5 5.00033V10.0003H15.8333V8.33366H4.16667V16.667H10V18.3337H4.16667ZM18.4375 14.167L16.6667 12.3962L17.2708 11.792C17.4236 11.6392 17.6181 11.5628 17.8542 11.5628C18.0903 11.5628 18.2847 11.6392 18.4375 11.792L19.0417 12.3962C19.1944 12.5489 19.2708 12.7434 19.2708 12.9795C19.2708 13.2156 19.1944 13.41 19.0417 13.5628L18.4375 14.167ZM11.6667 19.167V17.3962L16.0833 12.9795L17.8542 14.7503L13.4375 19.167H11.6667ZM4.16667 6.66699H15.8333V5.00033H4.16667V6.66699ZM4.16667 6.66699V5.00033V6.66699Z"
    fill="#9747FF"/>
</svg>

    </span>
  </td>
  <ModalAlertVue
      v-if="showDeleteModal"
      @handleClose="showDeleteModal = false"
      :close="!true"
  >
    <h4>
      {{ $t("delete_confirmation_text") }}
      <span class="user-confirm text-danger">{{ $t("schedule") }}</span
      >?
    </h4>
    <div class="popup-bottons">
      <button @click="showDeleteModal = false">
        {{ $t("cancel_delete_request") }}
      </button>
      <button :disabled="processingDelete" @click="processDelete">
        <span v-if="processingDelete">{{ $t("deleting") }}...</span>
        <span v-else>{{ $t("accept_delete_request") }}</span>
      </button>
    </div>
  </ModalAlertVue>
  <ModalBoxVue
      v-if="showContractPopUp"
      @handleClose="showContractPopUp = false"
      :close="!true"
  >
    <div class="p-4 form">
      <div>
        <h4>{{ $t('updateRecurringContract') }}</h4>
      </div>
      <div class="mb-3 mt-2">
        <label class="form-check-label d-flex mb-2">
                <span class="tooltip-custom position-relative tooltip-position" style="top: 4px">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_type")
                      }}</span>
                  </span>
          <span>
                {{ $t("changeType") }}
              </span>
        </label>
        <select
            v-model="contractUpdate.update_type"
            readonly
            disabled
            style="width: 100%"
            class="big"
        >
          <option v-for="(item,key) in actionList" :key="key" :value="item.key">
            {{ $t(item.locale) }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex">
          <span class="ml-1">

             <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_until_specific_value")
                      }}</span>
                  </span>
            {{ $t('updateUntilSpecificValue') }}</span>
          <span class="mt-1 ml-20px">
              <input v-model=contractUpdateType
                     readonly
                     disabled
                     class="form-check-input"
                     value="update_until_specific_value"
                     type="radio"
              />
           </span>

        </label>

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex">
          <span class="ml-1">
            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_number_of_times")
                      }}</span>
                  </span>
            {{ $t('updateNumberEachTime') }}</span>
          <span class="mt-1 ml-35px">
              <input
                  readonly
                  disabled
                  v-model=contractUpdateType
                  class="form-check-input"
                  value="update_number_of_times"
                  type="radio"
              />
           </span>

        </label>

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex mb-2">
          <span class="ml-1">{{ $t('invoiceRow') }}</span>

        </label>
        <input
            readonly
            disabled
            class="inputFields"
            style="width: 100%"
            :value="rowText"
            type="text"
        />

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex mb-2">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_with_value")
                      }}</span>
                  </span>
          <span>
                {{ $t("eachTimeUpdateWithValue") }}
              </span>
        </label>
        <input
            class="inputFields"
            style="width: 100%"
            v-model="contractUpdate.update_with_value"
            type="text"
        />

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex mb-2">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_final")
                      }}</span>
                  </span>
          <span>
                {{ $t("updateManyTimeUtilValue") }}
              </span>
        </label>
        <input
            class="inputFields"
            style="width: 100%" v-model="contractUpdate.final_value"
            type="text"
        />
      </div>

      <div class="confirmModalButtons">
        <button class="btn outline-purple" @click="showContractPopUp = false" :disabled="processingDelete">
          {{ $t("cancel") }}
        </button>
        <button class="btn btn-info--confirm" @click="updateContract" :disabled="processingDelete">
          <span v-if="processingUpdate">{{ $t('pleaseWait') }}</span>
          <span v-else>{{ $t("proceed") }}</span>
        </button>
        <button class="btn btn-danger" @click="processDelete">
          <span v-if="processingDelete">{{ $t('pleaseWait') }}</span>
          <span v-else>{{ $t("delete") }}</span>
        </button>
      </div>

    </div>
  </ModalBoxVue>
</template>

<script>
import {mapGetters} from "vuex"
import ModalAlertVue from "../../../components/ModalAlert.vue";
import ModalBoxVue from "@/components/ModalBox.vue";
import {RECURRING_CONTRACT_UPDATE_OPTION_LIST} from "../../../services/helpers/constant.js";
import moment from "moment";

export default {
  name: "recurringContractRow",
  components: {ModalAlertVue, ModalBoxVue},
  props: {
    schedule: {
      type: Object
    },
    helpText: {
      type: Array
    }
  },
  data: function () {
    return {
      actionList: RECURRING_CONTRACT_UPDATE_OPTION_LIST,
      showDeleteModal: false,
      processingDelete: false,
      processingUpdate: false,
      showContractPopUp: false,
      rowText: '',
      contractUpdate: {
        "final_value": 0,
        "update_with_value": 0,
        "update_type": "",
        "update_number_of_times": false,
        "update_until_specific_value": true
      },
      contractUpdateType: "update_until_specific_value"
    }
  },
  computed: {
    eventKey: function () {
      return this.LOCALE === 'se' ? 'swe_event' : 'eng_event'
    },
    eventTypeKey: function () {
      return this.LOCALE === 'se' ? 'swe_event_type' : 'eng_event_type'
    },
    ...mapGetters({
      LOCALE: 'locale/GET_LOCALE'
    })
  },
  methods: {
    getHelpText: function (field) {
      let text = this.helpText.filter(item => item.field === field)
      if (text.length > 0) {
        if (this.GET_LOCALE === 'se') {
          return text[0].swedish_text
        } else {
          return text[0].english_text
        }
      }

    },
    formatDateTime(dateTimeString) {
      const dateTime = moment(dateTimeString);
      if (dateTime.isValid()) {
        return dateTime.format("YYYY-MM-DD, HH:mm");
      } else {
        return "";
      }
    },
    onShowContractPopUp: function () {
      this.showContractPopUp = true
      this.getContract(this.schedule.contract_number)
    },
    onDelete: function () {
      this.showDeleteModal = true
    },
    processDelete: function () {
      this.processingDelete = true

      this.$store.dispatch('clients/deleteSchedule', {id: this.schedule.id}).then(() => {
        this.$services.helpers.notification(
            this.$t('deletedSuccessfully'),
            "success",
            this
        );
        this.processingDelete = false
        this.showDeleteModal = false
        this.showContractPopUp = false
        this.$emit('removeSchedule', this.schedule.id)
      }).catch(err => {
        this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
        );
      })
    },
    getUpdateType: function () {
      let ft = this.actionList.filter(item => item.key === this.schedule.update_type)
      if (ft.length > 0) {
        return ft[0].locale
      }
      return ''
    },
    updateContract: function () {
      if (this.contractUpdateType === 'update_until_specific_value') {
        this.contractUpdate['update_until_specific_value'] = true
        this.contractUpdate['update_number_of_times'] = false
      } else {
        this.contractUpdate['update_until_specific_value'] = false
        this.contractUpdate['update_number_of_times'] = true
      }
      this.contractUpdate["contract_number"] = this.schedule.contract.id
      this.contractUpdate["row_id"] = this.schedule.row_id
      this.processingUpdate = true
      this.$store
          .dispatch("clients/updateRecurringSchedule", {data: this.contractUpdate, id: this.schedule.id})
          .then((res) => {
            this.showContractPopUp = false;
            this.processingUpdate = false
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.schedulingModal = false;
            this.processingUpdate = false
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    onUpdate: function () {
      this.showContractPopUp = true
    },
    getContract(contractNumber) {
      this.$store.dispatch("clients/getSingleContract", contractNumber).then(res => {
        let row = res.data.data.invoice_rows.filter(item => String(item.row_id) === String(this.schedule.row_id))
        if (row.length > 0) {
          this.rowText = `Article: ${row[0]['article_number']} | Description: ${row[0]['description']} | Price: ${row[0]['price']}`
        } else {
          this.rowText = ''
        }
      });
    }
  },
  mounted() {
    Object.keys(this.contractUpdate).map(key => {
      this.contractUpdate[key] = this.schedule[key]
    })
    this.contractUpdate.final_value = this.schedule.final_value
    this.contractUpdate.update_with_value = this.schedule.update_with_value
    if (this.schedule.update_until_specific_value === true) {
      this.contractUpdateType = "update_until_specific_value"
    } else {
      this.contractUpdateType = "update_number_of_times"
    }
  }
}
</script>

<style scoped>
.handled {
  background: #9747FF;
  color: #FFFFFF;
  border: 1px solid #9747FF;
  border-right: 5px !important;
}

.success {
  background: #4ade80;
  color: #00632B;
  border: 1px solid #A5E1BF;
  border-right: 5px !important;

}

.form select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 42px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.form select.big {
  width: 192px;
}

.form .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.formc .radio input {
  display: none;
}

.form.radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.form .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.form .radio label:first-of-type {
  margin-right: 10px;
}


.form button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.form button:hover {
  background-color: #4947b0;
  color: #fff;
}

.form textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.form textarea:focus {
  border: none;
  outline: none;
}

.form .inputFields {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 42px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;

}

.form .inputFields.big {
  width: 192px;
}

.form .inputFields:-moz-read-only {
  background: #f0eff5;
}

.form .inputFields:read-only {
  background: #f0eff5;
}

.form .inputFields:focus {
  outline: none;
}

.form .inputFields.input[type="date"] {
  text-indent: 5px;
}

.form .inputFields.input[type="date"]::-webkit-clear-button,
.form .inputFields.input[type="date"]::-webkit-inner-spin-button,
.form .inputFields.input[type="date"]::-webkit-calendar-picker-indicator,
.form .inputFields.input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.form .inputFields.input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.form .inputFields.input[type="number"]::-webkit-outer-spin-button,
.form .inputFields.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.text-left {
  text-align: left;
}

.outline-purple {
  background: #fff !important;
  color: #5855d6 !important;
  border: 1px solid #5855d6 !important;
}

.btn-danger {
  background-color: rgb(239 68 68) !important;
  color: #FFFFFF !important;
}
</style>
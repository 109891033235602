<template>
  <td>
    <p>
      {{ data.document_number }}
    </p>
  </td>
  <td>
    <p v-if="$i18n.locale == 'en'">
      {{ data.eng_document_type }}
    </p>
    <p v-if="$i18n.locale === 'se'">
      {{ data.swe_document_type }}
    </p>
  </td>
  <td>{{ data.date_of_action }}</td>
  <td>
    <p v-if="$i18n.locale === 'en'">
      {{ data.eng_event_type }}
    </p>
    <p v-if="$i18n.locale === 'se'">
      {{ data.swe_event_type }}
    </p>
  </td>
  <td>
    <p v-if="$i18n.locale === 'en'">
      {{ data.eng_event }}
    </p>
    <p v-if="$i18n.locale === 'se'">
      {{ data.swe_event }}
    </p>
  </td>
  <td>
    <span
        :class="
        data.eng_status == `Handled` || data.swe_status == `Hanterad`
          ? ` badge badge-success`
          : `badge badge-info`
      "
    >
      <p v-if="$i18n.locale === 'en'">
        {{ data.eng_status }}
      </p>
      <p v-if="$i18n.locale === 'se'">
        {{ data.swe_status }}
      </p>
    </span>
  </td>
  <td class="d-flex">
    <svg
        @click="
        (confirmModal = true),
          (indexNumber = data.document_number),
          (targetRow = data)
      "
        class="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        style="width: 24px; height: 24px"
        viewBox="0 0 24 24"
    >
      <!-- fill="currentColor" >? -->
      <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
      ></path>
    </svg>
    <!--    -->
    <svg
        v-if="data.eng_status != `Handled`"
        @click="(targetRow = data), handleModal()"
        style="width: 24px; height: 24px"
        viewBox="0 0 24 24 "
        class="cursor-pointer"
    >
      <!-- :fill="data.scheduled_object ? '#13b010' : '#5855d6'" -->
      <path
          fill="#5855d6"
          d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z"
      ></path>
    </svg>
  </td>
  <ModalBoxVue
      v-if="confirmModal"
      @handleClose="confirmModal = false"
      :close="!true"
  >
    <div class="container p-3 confirmModal">
      <img src="@/assets/images/modal/notify-warning.png" width="25%"/>
      <p>{{ $t("deleteEventConfirmation") }} {{ indexNumber }}?</p>
      <div class="confirmModalButtons">
        <button class="btn btn-outline-dark" @click="confirmModal = false">
          {{ $t("no") }}, {{ $t("cancel").toLowerCase() }}
        </button>
        <button
            class="btn btn-primary"
            @click="(targetRow = data), deleteEvent()"
        >
          {{ $t("yes") }}, {{ $t("continue").toLowerCase() }}
        </button>
      </div>
    </div>
  </ModalBoxVue>
  <!-- schedules -->
  <ModalBoxVue
      v-if="schedulesModal"
      @handleClose="schedulesModal = false"
      :close="!true"
  >
    <div class="popup-box2">
      <h4>
        {{ $t("schedule_invoice_send_out") }}
      </h4>
    </div>
  </ModalBoxVue>
  <!-- openScheduleInvoiceSendoutModal -->
  <ModalBoxVue
      v-if="openScheduleInvoiceSendoutModal"
      @handleClose="openScheduleInvoiceSendoutModal = false"
      :close="!true"
  >
    <div class="popup-box2">
      <h4>
        {{ $t("schedule_invoice_send_out") }}
      </h4>
      <div class="container p-3" style="text-align: left !important">
        <!-- <form @submit.prevent="updateContractCustomization"> -->
        <form @submit.prevent>
          <div class="customize-cols-scrollar">
            <div class="col-md-8">
              <small>{{ $t("date_to_send") }}</small>
              <Datepicker
                  class="form-control"
                  v-model="date_to_send"
                  format="yyyy-MM-dd"
                  :enableTimePicker="false"
                  :minDate="today"
              />
            </div>
            <div class="col-md-8">
              <label>{{ $t("invoice_type") }}</label>
              <select v-model="invoice_type" class="form-control">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option value="nox_finans">
                  {{ $t("fortnox_invoice_service") }}
                </option>
                <option value="regular">{{ $t("regular") }}</option>
              </select>
            </div>
            <div class="col-md-8">
              <label>{{ $t("distribution_method") }}</label>
              <select v-model="distribution_method" class="form-control">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option value="email">{{ $t("as_email") }}</option>
                <option value="einvoice">{{ $t("e_invoice") }}</option>
                <option v-if="invoice_type === 'nox_finans'" value="print">
                  {{ $t("letter") }}
                </option>
              </select>
            </div>
            <div class="col-md-8 d-flex">
              <input
                  v-model="bookkeep"
                  style="box-shadow: none !important"
                  class="checkbox"
                  type="checkbox"
                  id="bookkeep-id"
                  name="bookkeep-name"
              />
              <label for="bookkeep-id"> {{ $t("bookkeep_invoice") }} </label
              ><br/>
            </div>
            <div class="col-md-12 d-flex">
              <input
                  v-model="send_failed_nox_as_reg"
                  style="box-shadow: none !important"
                  class="checkbox"
                  type="checkbox"
                  id="send-failed-nox-as-reg-id"
                  name="send-failed-nox-as-reg-name"
              />

              <label for="send-failed-nox-as-reg-id">
                {{ $t("send_failed_nox_as_reg_2") }} </label
              ><br/>
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <span v-if="targetRow.scheduled_object">
              <button
                  class="btn btn-primary"
                  @click="updateScheduleInvoiceSendout()"
              >
                {{ $t("update") }}
              </button>
              <button
                  class="btn btn-warning"
                  @click="deleteScheduleInvoiceSendout"
              >
                {{ $t("delete") }}
              </button>
            </span>
            <!--  -->
            <button
                v-else
                class="btn btn-primary"
                @click="addScheduleInvoiceSendout"
            >
              {{ $t("save") }}
            </button>

            <button
                class="btn btn-danger"
                @click="openScheduleInvoiceSendoutModal = false"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ModalBoxVue>
  <!-- cancel -->
  <ModalBoxVue
      v-if="openScheduleContractCancelationModal"
      @handleClose="openScheduleContractCancelationModal = false"
      :close="!true"
  >
    <h4>{{ $t("schedule_contract_cancelling") }}</h4>
    <div class="container p-3" style="text-align: left !important">
      <!-- <form @submit.prevent="updateContractCustomization"> -->
      <form @submit.prevent>
        <div class="customize-cols-scrollar">
          <div class="col-md-8">
            <small>{{ $t("date_to_cancel") }}</small>
            <Datepicker
                class="form-control"
                v-model="date_to_cancel"
                format="yyyy-MM-dd"
                :enableTimePicker="false"
                :minDate="today"
            />
          </div>
          <div class="col-md-8">
            <label>{{ $t("type_of_cancellation") }}</label>
            <select v-model="type_of_cancellation" class="form-control">
              <option value="" selected>{{ $t("choose_an_option") }}</option>
              <option value="inactivate">{{ $t("inactivate") }}</option>
              <option value="terminate">{{ $t("terminate") }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <!-- if current row already has scheduled contract cancellation -->
          <span v-if="targetRow.scheduled_object">
            <button
                class="btn btn-primary mr-2"
                @click="updateScheduleContractCancelation()"
            >
              {{ $t("update") }}
            </button>
            <button
                class="btn btn-warning mr-2"
                @click="deleteScheduleContractCancelation"
            >
              {{ $t("delete") }}
            </button>
          </span>

          <!-- if current row does not have a scheduled contract cancellation -->
          <button
              v-else
              class="btn btn-primary mr-2"
              @click="addScheduleContractCancelation()"
          >
            {{ $t("save") }}
          </button>

          <button
              class="btn btn-danger"
              @click="openScheduleContractCancelationModal = false"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <!-- Price change modal -->
  <ModalBoxVue
      v-if="openPriceChangeModal"
      @handleClose="openPriceChangeModal = false"
      :close="!true"
  >
    <h4>{{ $t("update_contract_price") }}</h4>
    <div class="container p-3" style="text-align: left !important">
      <form @submit.prevent>
        <div class="customize-cols-scrollar custom-form">
          <div class="col-md-12">
            <div class="mb-3">
              <small class="mb-1">{{ $t("date_to_change_price") }}</small>
              <Datepicker
                  class="form-control"
                  v-model="contractPriceUpdateDate"
                  format="yyyy-MM-dd"
                  :enableTimePicker="false"
                  :minDate="today"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1">{{ $t("type_of_price_change") }}</label>
              <select v-model="contractPriceChangeType" class="form-control">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option :value="item" v-for="(item,key) in contractChangeType" :key="key">{{ $t(`${item}`) }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1 d-flex">
                <div>

            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("contract_price_update_price_change")
                      }}</span>
                  </span>
                </div>
                <div>
                  <span
                      v-if="['increase_percentage', 'decrease_percentage'].includes(contractPriceChangeType) === true">{{
                      $t("percentage_to_change_price")
                    }}</span>
                  <span v-else> {{ $t("amount_to_change_price") }}</span>
                </div>
              </label>
              <input
                  v-model="contractPriceChangePercentage"
                  class="form-control"
              />
            </div>

          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1">

               <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("contract_price_update_rows_to_exclude")
                      }}</span>
                  </span>
                <span>
                {{ $t("rowToExclude") }}
              </span>
              </label>
              <el-select class="w-100" v-model="excludeRow" :placeholder="$t('select')" size="large" multiple>
                <el-option
                    v-for="(item,key) in contractRowList"
                    :key="`_list_${key}`"
                    :label="`Article number : ${item.article_number}, Description:  ${item.description}, Price : ${item.price} Quantity : ${item.delivered_quantity}`"
                    :value="parseInt(item.row_id)"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("contract_price_update_invoice_text")
                      }}</span>
                  </span>
                <span>
                {{ $t("invoiceTextOnPriceChange") }}
              </span>
              </label>
              <textarea class="form-control " cols="5" row="5" maxlength="1000"
                        v-model="invoiceTextAfterPriceChange"></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <span v-if="targetRow.scheduled_object">
            <button
                class="btn btn-primary mr-10px"
                @click="updateContractPriceUpdate()"
            >
              {{ $t("update") }}
            </button>
            <button class="btn btn-warning mr-10px" @click="deleteContractPriceUpdate">
              {{ $t("delete") }}
            </button>
          </span>

          <button
              v-else
              class="btn btn-primary mr-10px"
              @click="addContractPriceUpdate"
          >
            {{ $t("save") }}
          </button>

          <button class="btn btn-danger" @click="openPriceChangeModal = false">
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <!-- row change -->
  <ModalBoxVue
      v-if="schedulingModal"
      @handleClose="schedulingModal = false"
      :close="!true"
  >
    <div class="p-4">
      <h5>{{ $t("scheduleRowChange") }}</h5>
      <hr/>
      <div
          class="container p-3 confirmModal custom-form"
          style="text-align: left !important"
      >
        <p>{{ $t("dateOfAction") }}</p>
        <Datepicker
            class="form-control"
            v-model="scheduling.date_of_action"
            format="yyyy-MM-dd"
            style="width: 100% !important"
            :enableTimePicker="false"
            :minDate="today"
        />
        <div class="mt-3">
          <label class="mb-1 d-flex">
            <div>

            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("invoice_row_update_type")
                      }}</span>
                  </span>
            </div>
            <div>
              <span> {{ $t("changeType") }}</span>
            </div>
          </label>
          <select
              v-model="scheduling.change_type"
              style="width: 100%" :disabled="true"

          >
            <option value="increase_discount">{{ $t("increaseDiscount") }}</option>
            <option value="decrease_discount">{{ $t("decreaseDiscount") }}</option>
            <option value="remove_discount">{{ $t("removeDiscount") }}</option>
            <option value="increase_quantity">{{ $t("increaseDeliveredQuantity") }}</option>
            <option value="decrease_quantity">{{ $t("decreaseDeliveredQuantity") }}</option>
            <option value="increase_price">{{ $t("increasePrice") }}</option>
            <option value="decrease_price">{{ $t("decreasePrice") }}</option>
            <option value="change_article">{{ $t("changeArticle") }}</option>
            <option value="remove_row">{{ $t("removeRow") }}</option>
          </select>
        </div>
        <div class="mt-3">
          <label class="mb-1 d-flex">
            <div>

            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("invoice_row_update_new_value")
                      }}</span>
                  </span>
            </div>
            <div>
              <span> {{ $t("newValue") }}</span>
            </div>
          </label>
          <input
              :disabled="newValueField"
              class="inputFields"
              v-model="newValue"
              id="search"
              style="width: 100%"
              type="text"
          />
        </div>

        <div class="mt-3">
          <label class="mb-1 d-flex">
            <div>

            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("invoice_row_update_change_current_value_with")
                      }}</span>
                  </span>
            </div>
            <div>
              <span> {{ $t("changeCurrentValue") }}</span>
            </div>
          </label>
          <input
              :disabled="changeValueField"
              class="inputFields"
              v-model="changeValue"
              style="width: 100%"
              type="text"
          />
        </div>
        <div class="confirmModalButtons mt-2 d-flex">
          <button class="btn btn-outline-dark mr-10px" :disabled="deletingValue" @click="schedulingModal = false">
            {{ $t("cancel") }}
          </button>
          <button class="btn btn-primary mr-10px" @click="event()" :disabled="deletingValue">{{
              $t("proceed")
            }}
          </button>
          <button class="btn btn-danger mr-2" v-on:click="deleteEvent">
            <span v-if="deletingValue"> {{ $t('pleaseWait') }}</span>
            <span v-else>
              {{ $t("delete") }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </ModalBoxVue>

</template>

<script>
import {useStore, mapGetters} from "vuex";
import icon_loading from "../../../components/icon_loading";
import ModalBoxVue from "@/components/ModalBox.vue";
import moment from "moment";

const TODAY = moment().format("YYYY-MM-DD");

export default {
  props: {
    data: Object,
    head: Array,
    item: Function,
    helpText: Array
  },
  // components: { icon_loading },
  emits: ["openActivity", "openTag"],
  setup(props, {emit}) {
    const store = useStore();

    const checkboxOrder = (e) =>
        store.commit("clients/TOGGLE_CUSTOMER_CHECKBOX", {
          id: props.data.id,
          value: props.data.checked,
        });
    // const openCustomerActivity = () => {
    //   // store.commit('clients/TOGGLE_CUSTOMER_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
    //   emit('openActivity', props.data)
    // }
    const openCustomerTag = () => {
      // store.commit('clients/TOGGLE_CUSTOMER_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      emit("openTag", props.data);
    };

    return {
      props,
      openCustomerTag,
      checkboxOrder,
    };
  },
  components: {
    ModalBoxVue,
  },
  data: function () {
    return {
      loading: false,
      contractChangeType: ['increase_percentage', 'decrease_percentage', 'increase_amount', 'decrease_amount'],
      badge: ["active"],
      invoiceList: [],
      contractList: [],
      contractRowList: [],
      excludeRow: [],
      confirmModal: false,
      loadingData: false,
      targetRow: {},
      selectionType: "",
      invoiceTextAfterPriceChange: "",
      newInvoiceId: "",
      newContractId: "",
      searchFieldValue: "",
      searchType: "",
      searchBtn: true,
      newType: false,
      schedulesModal: false,
      openScheduleInvoiceSendoutModal: false,
      openScheduleContractCancelationModal: false,
      openPriceChangeModal: false,
      schedulingModal: false,
      typeSelect: false,
      newEventType: "",
      indexNumber: "",
      date_to_send: TODAY,
      invoice_type: "",
      distribution_method: "",
      bookkeep: false,
      send_failed_nox_as_reg: false,
      date_to_cancel: TODAY,
      type_of_cancellation: "",
      contractPriceUpdateDate: TODAY,
      contractPriceChangeType: "",
      contractPriceChangePercentage: 0,
      newValueField: false,
      newValue: "",
      changeValueField: false,
      deletingValue: false,
      changeValue: "",
      scheduling: {
        row_id: "",
        contract_number: "",
        date_of_action: "",
        change_type: "",
        new_value: "",
        change_with_value: "",
      },
    };
  },
  methods: {
    getHelpText: function (field) {
      let text = this.helpText.filter(item => item.field === field)
      if (text.length > 0) {
        if (this.GET_LOCALE === 'se') {
          return text[0].swedish_text
        } else {
          return text[0].english_text
        }
      }

    },
    scheduleEvent() {
      this.newType = true;
    },

    openCustomerActivity(status) {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      let payload = {
        active: status,
      };
      let data = this.data;
      this.loading = true;
      this.$store
          .dispatch("clients/updateCustomerInfo", {
            id: data.customer_number,
            data: payload,
          })
          .then((_) => {
            this.loading = false;
            data.active = status;
            // this.$services.helpers.notification('Updated customers information', 'success', this)
            this.$toast.success(this.$t("updated_customer_info"));
          })
          .catch((err) => {
            data.active = !status;
            this.loading = false;
            if (err.response.status === 403) {
              this.$services.helpers.notification(
                  err.response.data,
                  "error",
                  this
              );
            } else {
              try {
                if ([400, 406].includes(err.response.status)) {
                  if ("error" in err.response.data) {
                    if (Array.isArray(err.response.data.error)) {
                      this.$services.helpers.notification(
                          err.response.data.error.join(","),
                          "error",
                          this
                      );
                    } else if (typeof err.response.data.error === "object") {
                      for (const [key, value] of Object.entries(
                          err.response.data.error
                      )) {
                        if (Array.isArray(value)) {
                          this.$services.helpers.notification(
                              key.replace("_", " ") + ": " + value.join(","),
                              "error",
                              this
                          );
                        } else {
                          this.$services.helpers.notification(
                              value,
                              "error",
                              this
                          );
                        }
                        return false;
                      }
                    } else {
                      this.$services.helpers.notification(
                          err.response.data.error,
                          "error",
                          this
                      );
                    }
                  } else if ("message" in err.response.data) {
                    if (Array.isArray(err.response.data.message)) {
                      this.$services.helpers.notification(
                          err.response.data.message.join(""),
                          "error",
                          this
                      );
                    } else if (typeof err.response.data.message === "object") {
                      if ("message" in err.response.data.message) {
                        if (
                            "ErrorInformation" in err.response.data.message.message
                        ) {
                          this.$services.helpers.notification(
                              err.response.data.message.message.ErrorInformation
                                  .message,
                              "error",
                              this
                          );
                        }
                      }
                    } else {
                      this.$services.helpers.notification(
                          err.response.data.message,
                          "error",
                          this
                      );
                    }
                  }
                } else {
                  // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
                  this.$toast.error(this.$t("request_failure"));
                }
              } catch (e) {
                // this.$services.helpers.notification('Action could not be perform', 'error', this)
                this.$toast.error(this.$t("action_failed"));
              }
            }
          });
    },
    deleteEvent() {
      let payload = "";
      if (this.targetRow.code == 111) {
        payload = `contract_termination/${this.targetRow.id}`;
      } else if (this.targetRow.code == 222) {
        payload = `contract_price_change/${this.targetRow.id}`;
      } else if (this.targetRow.code == 333) {
        payload = `invoice_row_change/${this.targetRow.id}`;
      } else {
        payload = `invoice_sendout/${this.targetRow.id}`;
      }
      this.deletingValue = true
      this.$store
          .dispatch("clients/deleteSchedules", payload)
          .then((res) => {
            this.confirmModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
            window.Bus.emit('reload-schedule')
            // this.$emit('removeSchedule', this.targetRow.id)
            this.schedulingModal = false
            this.deletingValue = false
          })
          .catch((err) => {
            this.confirmModal = false;
            this.deletingValue = false
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
      // /schedules/contract_termination/id
    },
    openTag() {
      window.Bus.emit("open-single-tag-customer", this.data);
    },
    typeSelectModal() {
      this.newType = false;
      this.typeSelect = true;
      this.selectionType = this.newEventType.split(" ")[0];
    },
    addModals() {
      this.newType = false;
      this.typeSelect = false;
      this.targetRow = {};

      if (this.newEventType == `contract cancellation`) {
        (this.date_to_cancel = TODAY),
            (this.type_of_cancellation = ""),
            (this.openScheduleContractCancelationModal = true);
      } else if (this.newEventType == `contract price change`) {
        (this.contractPriceUpdateDate = TODAY),
            (this.contractPriceChangeType = ""),
            (this.contractPriceChangePercentage = 0),
            (this.openPriceChangeModal = true);
      } else if (this.newEventType == `invoice row change`) {
        (this.scheduling = {
          // row_id: this.newInvoiceId,
          contract_number: this.newInvoiceId,
          date_of_action: TODAY,
          change_type: "",
          new_value: null,
          change_with_value: null,
        })((this.schedulingModal = true));
      } else if (this.newEventType == `invoice sendout`) {
        (this.date_to_send = TODAY),
            (this.invoice_type = ""),
            (this.distribution_method = ""),
            (this.bookkeep = false),
            (this.send_failed_nox_as_reg = false),
            (this.openScheduleInvoiceSendoutModal = true);
      }
    },
    getContract(documentNumber) {
      this.$store.dispatch('clients/getSingleContract', documentNumber).then(res => {
        this.contractRowList = res.data.data['invoice_rows']
        if (this.data.scheduled_object.rows_to_exclude) {
          this.excludeRow = this.data.scheduled_object.rows_to_exclude
        }
      })
    },
    handleModal() {
      this.contractRowList = []
      this.excludeRow = []
      this.invoiceTextAfterPriceChange = ''
      if (this.targetRow.code == 111) {
        (this.date_to_cancel = this.targetRow.scheduled_object.date_to_cancel),
            (this.type_of_cancellation = this.targetRow.scheduled_object.action),
            (this.openScheduleContractCancelationModal = true);
      } else if (this.targetRow.code == 222) {
        this.contractPriceUpdateDate = this.targetRow.scheduled_object.date_to_update
        this.contractPriceChangeType = this.targetRow.scheduled_object.change_type
        this.contractPriceChangePercentage = this.targetRow.scheduled_object.percentage
        this.openPriceChangeModal = true
        if (this.data.scheduled_object) {
          this.contractPriceChangeType = this.data.scheduled_object.change_type
          this.contractPriceChangePercentage = this.data.scheduled_object.percentage_or_amount
          this.invoiceTextAfterPriceChange = this.data.invoice_text
        }
        this.getContract(this.data.document_number)

      } else if (this.targetRow.code == 333) {
        this.scheduling = {
          row_id: this.targetRow.scheduled_object.row_id,
          contract_number: this.targetRow.scheduled_object.contract_number,
          date_of_action: this.targetRow.scheduled_object.date_of_action,
          change_type: this.targetRow.scheduled_object.change_type,
        }
        if (this.targetRow.scheduled_object.new_value !== '' && this.targetRow.scheduled_object.new_value !== null && this.targetRow.scheduled_object.new_value !== undefined) {

          this.newValue = this.targetRow.scheduled_object.new_value
        }
        if (this.targetRow.scheduled_object.change_with_value) {
          this.changeValue = this.targetRow.scheduled_object.change_with_value
        }
        this.schedulingModal = true
      } else if (this.targetRow.code == 444) {
        (this.date_to_send = this.targetRow.scheduled_object.date_to_send),
            (this.invoice_type = this.targetRow.scheduled_object.invoice_type),
            (this.distribution_method =
                this.targetRow.scheduled_object.distribution_method),
            (this.bookkeep = this.targetRow.scheduled_object.bookkeep),
            (this.send_failed_nox_as_reg =
                this.targetRow.scheduled_object.send_failed_nox_as_reg),
            (this.openScheduleInvoiceSendoutModal = true);
      }
    },
    addScheduleInvoiceSendout() {
      let invoices = [];
      invoices = [this.newInvoiceId];
      this.payload = {
        invoices: invoices,
        date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
        send_channel: this.invoice_type,
        send_method: this.distribution_method,
        bookkeep: this.bookkeep,
        send_failed_nox_as_reg: this.send_failed_nox_as_reg,
      };
      this.$store
          .dispatch("clients/addScheduleInvoiceSendout", {
            invoices: invoices,
            date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
            send_channel: this.invoice_type,
            send_method: this.distribution_method,
            bookkeep: this.bookkeep,
            send_failed_nox_as_reg: this.send_failed_nox_as_reg,
          })
          .then((res) => {
            window.Bus.emit("reload-schedule");
            // this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            // this.saving = false
            // this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateScheduleInvoiceSendout() {
      this.$store
          .dispatch("clients/updateScheduleInvoiceSendout", {
            id: this.targetRow.scheduled_object.id,
            date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
            send_channel: this.invoice_type,
            send_method: this.distribution_method,
            bookkeep: this.bookkeep,
            send_failed_nox_as_reg: this.send_failed_nox_as_reg,
          })
          .then((res) => {
            window.Bus.emit("reload-schedule");
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    deleteScheduleInvoiceSendout() {
      this.$store;
      this.$store
          .dispatch(
              "clients/deleteScheduleInvoiceSendout",
              this.targetRow.scheduled_object
          )
          .then((res) => {
            window.Bus.emit("reload-schedule");
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.openScheduleInvoiceSendoutModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    addScheduleContractCancelation() {
      let contracts = [];
      contracts = [this.newContractId];
      this.$store
          .dispatch("clients/addScheduleContractCancelation", {
            contracts: contracts,
            type_of_cancellation: this.type_of_cancellation,
            date_to_cancel: moment(this.date_to_cancel).format("YYYY-MM-DD"),
          })
          .then((res) => {
            window.Bus.emit("reload-schedule");
            this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
            this.type_of_cancellation = "";
            this.date_to_cancel = TODAY;
          })
          .catch((err) => {
            this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateScheduleContractCancelation() {
      this.$store
          .dispatch("clients/updateScheduleContractCancelation", {
            id: this.targetRow.scheduled_object.id,
            type_of_cancellation: this.type_of_cancellation,
            date_to_cancel: moment(this.date_to_cancel).format("YYYY-MM-DD"),
          })
          .then((res) => {
            window.Bus.emit("reload-schedule");
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    deleteScheduleContractCancelation() {
      // this function hits the DELETE route for scheduling contract cancellation
      this.$store
          .dispatch(
              "clients/deleteScheduleContractCancelation",
              this.targetRow.scheduled_object.id
          )
          .then((res) => {
            window.Bus.emit("reload-schedule");
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.scheduling = false;
            // this.openScheduleContractCancelationModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    addContractPriceUpdate() {
      this.contractPriceUpdateDate = moment(
          this.contractPriceUpdateDate
      ).format("YYYY-MM-DD");

      // check the update date and then call respective function
      if (this.contractPriceUpdateDate === TODAY) {
        // update immediately
        this.updateToday();
      } else {
        // schedule for later
        this.updateLater();
      }

      this.openPriceChangeModal = false;
    },
    updateToday() {
      let payload = {};

      payload = {
        selected: [this.newContractId],
        change_type: this.contractPriceChangeType,
        percentage_or_amount: this.contractPriceChangePercentage,
        invoice_text: this.invoiceTextAfterPriceChange,
      };
      payload['rows_to_exclude'] = []
      if (this.excludeRow.length > 0) {
        payload['rows_to_exclude'] = this.excludeRow
      }
      this.$store
          .dispatch("clients/onAddcontractPriceUpdateToday", {
            ...payload,
          })
          .then((res) => {
            window.Bus.emit("reload-schedule")
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            // window.Bus.emit("reload-schedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateLater() {
      let payload = {};
      payload = {
        contracts: [this.newContractId],
        date_to_update: this.contractPriceUpdateDate,
        change_type: this.contractPriceChangeType,
        percentage_or_amount: this.contractPriceChangePercentage,
        invoice_text: this.invoiceTextAfterPriceChange,
      };
      payload['rows_to_exclude'] = []
      if (this.excludeRow.length > 0) {
        payload['rows_to_exclude'] = this.excludeRow
      }
      this.$store
          .dispatch("clients/initiateContractPriceUpdate", {
            ...payload,
          })
          .then((res) => {
            window.Bus.emit("reload-schedule")
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data.message,
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    updateContractPriceUpdate() {
      let payload = {
        date_to_update: moment(this.contractPriceUpdateDate).format(
            "YYYY-MM-DD"
        ),
        change_type: this.contractPriceChangeType,
        percentage_or_amount: this.contractPriceChangePercentage,
        invoice_text: this.invoiceTextAfterPriceChange,
      }
      payload['rows_to_exclude'] = []
      if (this.excludeRow.length > 0) {
        payload['rows_to_exclude'] = this.excludeRow
      }
      this.$store
          .dispatch("clients/onUpdateContractPriceUpdate", {id: this.targetRow.scheduled_object.id, data: payload})
          .then((res) => {
            window.Bus.emit('reload-schedule')
            this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
          })
          .catch((err) => {
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    deleteContractPriceUpdate() {
      this.$store
          .dispatch(
              "clients/deleteContractPriceUpdate",
              this.targetRow.scheduled_object.id
          )
          .then((res) => {
            window.Bus.emit("reload-schedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
          })
          .catch((err) => {
            this.$emit("getSchedule");
            // this.openPriceChangeModal = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    event() {
      this.$store
          .dispatch("clients/updateScheduleEvent", {data: this.scheduling, id: this.data.id})
          .then((res) => {
            window.Bus.emit("reload-schedule");
            this.schedulingModal = false;
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
          })
          .catch((err) => {
            // this.$emit("getSchedule");
            this.schedulingModal = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
      // this.$emit("event", this.scheduling);
      // this.$emit('removeRow', this.data)
    },
    searchNumber() {
      if (this.searchType == `contract`) {
        this.newContractId = "";
        this.$store.commit("clients/UPDATE_CONTRACTS", []);
        this.$store
            .dispatch("clients/getContracts", `search=${this.searchFieldValue}`)
            .then((res) => {
              if (res.data.data.length > 0) {
                this.searchBtn = false;
                if (res.data.data.length == 1) {
                  this.newContractId = res.data.data[0].document_number;
                }
              } else {
                this.searchBtn = true;
              }
            })
            .catch((err) => {
              this.searchBtn = true;
            });
      } else if (this.searchType == `invoice`) {
        this.newInvoiceId = "";
        this.$store.commit("clients/UPDATE_INVOICE", []);
        this.$store
            .dispatch("clients/getInvoice", `search=${this.searchFieldValue}`)
            .then((res) => {
              if (res.data.data.length > 0) {
                this.searchBtn = false;
                if (res.data.data.length == 1) {
                  this.newInvoiceId = res.data.data[0].document_number;
                }
              } else {
                this.searchBtn = true;
              }
            })
            .catch((err) => {
              this.searchBtn = true;
            });
      }
    },
  },
  watch: {
    "scheduling.date_of_action": function (newVal, oldVal) {
      if (newVal instanceof Date) {
        let value = `${newVal.getFullYear()}-${
            newVal.getMonth() + 1
        }-${newVal.getDate()}`;
        this.scheduling.date_of_action = value;
      }
    },
    "changeValue": function (newVal, oldVal) {
      if (String(newVal).length > 0) {
        this.newValueField = true;
        delete this.scheduling.new_value;
        this.scheduling.change_with_value = newVal;
        this.scheduling.new_value = '';
      } else {
        this.newValueField = false;
      }
    },
    "newValue": function (newVal, oldVal) {
      let val = String(newVal)
      if (val.length > 0) {
        this.changeValueField = true;
        this.scheduling.change_with_value = '';
        this.scheduling.new_value = newVal;
      } else {
        this.changeValueField = false;
      }

    },
    selectionType(newVal, oldVal) {
      if (newVal == `contract`) {
        this.searchType = newVal;
        this.searchFieldValue = "";
      } else if (newVal == `invoice`) {
        this.searchType = newVal;
        this.searchFieldValue = "";
      }
    },
    searchFieldValue(newVal, oldVal) {
      this.searchBtn = true;
    },
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: "clients/GET_CURRENT_USER",
      GET_INVOICE: "clients/GET_INVOICE",
      CONTRACTS: "clients/GET_CONTRACTS",
      GET_LOCALE: 'locale/GET_LOCALE'
    }),
    today() {
      return moment().format("YYYY-MM-DD");
    },
    locale_message() {
      return this.GET_LOCALE === 'se' ? 'message' : 'eng_message'
    }
  },
};
</script>

<style lang="scss" scoped>
.content .box table {
  width: 100%;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .content .box table {
    min-width: 1000px;
  }
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 5px;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead tr {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.text-green {
  color: #419e6a !important;
}

.text-yellow {
  color: #efb008;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr.inactive td:last-of-type button:nth-of-type(1) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td .email {
  padding: 10px 5px;
}

.content .box table tbody td .email input {
  background: #f0eff5;
  border: 1px solid #c8c7cd;
  padding: 2px 12px;
  width: 153px;
}

.content .box table tbody td .email input:focus {
  border: 1px solid #c8c7cd;
  outline: none;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td span {
  border-radius: 4px;
  min-width: 79.24px;
  display: inline-block;
  text-align: center;
}

.content .box table tbody td span.inactive {
  background: #ffde81;
  color: #976400;
}

.content .box table tbody td span.enabled {
  background: #a5e1bf;
  color: #00632b;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button:last-of-type {
  margin-left: 5px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  text-indent: 4px;
  padding-left: 3px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

.el-pointer {
  cursor: pointer;
  pointer-events: auto;
}

.badge-success {
  height: 21.259172439575195px;
  width: 79.23873901367188px;
  left: 912.7255859375px;
  border-radius: 4px;
  line-height: initial;
  background: #a5e1bf;
}

.badge-warning {
  height: 21.259172439575195px;
  width: 79.23873901367188px;
  left: 912.7255859375px;
  border-radius: 4px;
  line-height: initial;
  background: #ffde81;
}

.badge-info {
  background: #5755d6d8;
}

.inputFields {
  width: 100%;
  height: 44px;
  padding-left: 6px;
  border-radius: 5px;
  padding-right: 7px;
}
</style>

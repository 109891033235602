<template>
  <BlogHeader @change-locale="changeLocale" />
  <ShimmerLoading v-if="loading === true" :num="15" />
  <div v-else>
    <!-- Blog Detail Banner -->
    <section class="blog-detail-banner">
      <div class="container">
        <ul class="breadcrumb">
          <li>
            <router-link to="/blog">Blog</router-link>
            <i class="fa fa-caret-right"></i>
          </li>

          <li v-if="blogPost.category">
            {{
              locale === "en"
                ? blogPost.category.eng_category
                : blogPost.category.swe_category
            }}
          </li>
        </ul>

        <div class="row align-items-center">
          <div class="col-lg-6">
            <img
              v-if="blogPost.image"
              :src="
                blogPost.image || require('../../../assets/likvio_logo.png')
              "
              :alt="blogPost.eng_title"
              class="main"
            />
            <img
              v-else
              src="../../../assets/likvio_logo.png"
              :alt="blogPost.eng_title"
              class="main"
            />
          </div>

          <div class="col-lg-6">
            <div class="text">
              <h6>{{ new Date(blogPost.created_at).toLocaleString() }}</h6>
              <h4>
                {{ locale === "en" ? blogPost.eng_title : blogPost.swe_title }}
              </h4>
              <p>
                {{
                  locale === "en"
                    ? blogPost.eng_preamble
                    : blogPost.swe_preamble
                }}
              </p>

              <!-- <div class="user">
                <img src="images/materials/user04.png" alt="" />
                <span>Ryan Pollock on Product Updates</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog Detail Banner End -->

    <!-- Blog Content -->
    <section class="blog-content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="text">
              <div
                v-html="locale === 'en' ? blogPost.eng_text : blogPost.swe_text"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Blog Content End -->
  </div>
</template>

<script>
import BlogHeader from "./BlogHeader.vue";
import ShimmerLoading from "../../Clients/reusableComponents/ShimmerLoading.vue";

export default {
  created() {
    this.addFontAwesomeToHead();
    this.fetchBlogPost();
  },

  data() {
    return {
      loading: false,
      locale: "eng",
      blogPost: {},
    };
  },

  components: {
    BlogHeader,
    ShimmerLoading,
  },

  methods: {
    async fetchBlogPost() {
      this.loading = true;
      const blogPost = await this.$store.dispatch(
        "clients/getSingleBlogPost",
        this.$route.params.id
      );
      this.blogPost = blogPost.data;
      this.loading = false;
    },

    changeLocale(locale) {
      this.locale = locale;
    },

    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },
  },
};
</script>

<style scoped>
.blog-detail-banner {
  padding: 70px 0;
  background-color: #eaf1fa;
  position: relative;
  z-index: -1;
  overflow: hidden;
}
@media (max-width: 992px) {
  .blog-detail-banner {
    z-index: 1;
  }
}
.blog-detail-banner .breadcrumb {
  padding: 12px 25px;
  border-radius: 100px;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  margin-bottom: 35px;
}
.blog-detail-banner .breadcrumb li {
  font-size: 14px;
  color: #045e62;
}
.blog-detail-banner .breadcrumb li:not(:last-child) {
  margin-right: 15px;
}
.blog-detail-banner .breadcrumb li i {
  margin-left: 15px;
}
.blog-detail-banner .breadcrumb li a {
  color: #045e62;
  font-size: 14px;
}
.blog-detail-banner .main {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 25px;
}
@media (max-width: 992px) {
  .blog-detail-banner .main {
    height: 250px;
    margin-bottom: 25px;
  }
}
.blog-detail-banner .text {
  margin-left: 30px;
}
@media (max-width: 992px) {
  .blog-detail-banner .text {
    margin-left: 0;
  }
}
.blog-detail-banner .text h6 {
  color: #045e62;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 5px;
}
.blog-detail-banner .text h4 {
  color: #1a232d;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}
.blog-detail-banner .text p {
  margin: 0;
  color: #1a232d;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-detail-banner .text .user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.blog-detail-banner .text .user img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}
.blog-detail-banner .text .user span {
  margin: 0;
  color: #045e62;
  font-size: 14px;
  font-weight: 500;
}

.blog-content {
  padding: 70px 0;
}
.blog-content .text h4 {
  color: #1a232d;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}
.blog-content .text p {
  color: #3f3f3a;
  margin: 0;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .blog-content .text p {
    font-size: 14px;
    line-height: 25px;
  }
}
</style>

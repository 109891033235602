<template>
  <div class="position-absolute  inset-0" style="z-index:10" v-if="open === true" @click="open=false"></div>
  <div class="position-relative">

    <div class="lik-filter d-flex justify-between position-relative"
         :style="`width: ${width}; height: ${height};`"
         v-on:click="onOpen">
                    <span style="font-size: 13px;padding-top: 4px" class="text-truncate">
                      {{ $t(applyFilter) }}
                    </span>
      <span>
                      <i class="bx bx-chevron-down bx-xs arrow-pt" v-if="open === false"></i>
                      <i class="bx bx-chevron-up bx-xs arrow-pt" v-else></i>
                    </span>
    </div>
    <div class="position-absolute w-100 top-100 " style="z-index: 80;" v-if="open === true">
      <ul class="bg-white shadow border-1 h-auto rounded  w-100 dropdown-list" :style="`font-size: ${fontSize}`">
        <li v-for="(option,key) in options" :key="key" v-on:click="onSelect(option)" class="cursor-pointer">
          {{ $t(option.label) }}
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  name: "customSelectDropDown",
  props: {
    width: {
      default: '175px'
    },
    height: {
      default: '27px'
    },
    options: {
      default: []
    },
    fontSize: {
      default: '12px'
    },
    defaultValue: {
      default: ''
    }
  },
  data: function () {
    return {
      open: false,
      applyFilter: ''
    }
  },
  methods: {
    onOpen: function () {
      this.open = !this.open
    },
    onSelect: function (option) {
      this.applyFilter = option.label
      this.$emit('onPicked', option)
      this.open = false
    }
  },
  mounted() {
    if (this.defaultValue) {
      this.applyFilter = this.defaultValue
    }
  }
}
</script>

<style scoped>
.lik-filter {
  border: 1px solid #eee;
  padding-left: 10px;
  background: #fff;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-list {
  list-style-type: none;
  padding: 8px;
  /*font-size: 12px;*/
}

.dropdown-list > li {
  padding-top: 1px;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;
}

.dropdown-list li.active, .dropdown-list li:focus, .dropdown-list li:active {
  box-shadow: 0 0 10px #ffffff80;
  border: 1px solid #eeeeee;
}

.arrow-pt {
  padding-top: 0.33rem !important;
}
</style>
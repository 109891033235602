<template>
  <div>
    <div class="p-4 form">
      <div>
        <h4 v-if="contractUpdateActionKey !== 'create'">{{ $t('updateRecurringContract') }}</h4>
        <h4 v-else>{{ $t('createRecurringContract') }}</h4>
      </div>
      <div class="mb-3" v-if="hasRecurringUpdate !== 'create'">
        <p class="mb-2">{{ $t("pickScheduleAction") }}</p>
        <select
            v-model="contractUpdateActionKey"
            style="width: 100%"
            v-on:change="onChangeRecurringActionKey"
            class=""
        >
          <option value="create">{{ $t("createNewSchedule") }}</option>
          <option :value="schedule.id" v-for="(schedule, i) in recurList" :key="i">
            <span v-if="GET_LOCALE === 'se'">Uppdatera instans - {{ schedule.swe_update_type }} med
            {{ schedule.update_with_value }}</span>
            <span v-else> Update instance - {{
                $services.helpers.capitalizeNames(schedule.update_type.replace('_', ' '))
              }} with
            {{ schedule.update_with_value }}</span>

          </option>
        </select>
      </div>
      <div class="mb-3 mt-2">
        <!--        <p class="mb-2">{{ $t("changeType") }}</p>-->
        <label class="form-check-label d-flex mb-2">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_type")
                      }}</span>
                  </span>
          <span>
                {{ $t("changeType") }}
              </span>
        </label>
<!--        {{ optionsList }}-->
        <select
            :disabled="contractUpdateActionKey !== 'create'"
            v-model="contractUpdate.update_type"
            style="width: 100%"
            class="big"
        >
          <option :value="item.key" v-for="(item,key) in optionsList" :key="key">{{ $t(`${item.locale}`) }}</option>
          <!--          <option value="increase_discount">{{ $t("increaseDiscount") }}</option>-->
          <!--          <option value="decrease_discount">{{ $t("decreaseDiscount") }}</option>-->
          <!--          <option value="increase_quantity">{{ $t("increaseDeliveredQuantity") }}</option>-->
          <!--          <option value="decrease_quantity">{{ $t("decreaseDeliveredQuantity") }}</option>-->
        </select>
      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex">
          <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_until_specific_value")
                      }}</span>
                  </span>
          <span class="ml-1">{{ $t('updateUntilSpecificValue') }}</span>
          <span class="mt-1 ml-20px">
              <input v-model=contractUpdateType
                     class="form-check-input"
                     :disabled="contractUpdateActionKey !== 'create'"
                     value="update_until_specific_value"
                     type="radio"
              />
           </span>

        </label>

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex">
           <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_number_of_times")
                      }}</span>
                  </span>
          <span class="ml-1">{{ $t('updateNumberEachTime') }}</span>

          <span class="mt-1 ml-35px">
              <input
                  v-model=contractUpdateType
                  class="form-check-input"
                  :disabled="contractUpdateActionKey !== 'create'"
                  value="update_number_of_times"
                  type="radio"
              />
           </span>

        </label>

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex mb-2">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_with_value")
                      }}</span>
                  </span>
          <span>
                {{ $t("eachTimeUpdateWithValue") }}
              </span>
        </label>
        <input
            class="inputFields"
            style="width: 100%"
            v-model="contractUpdate.update_with_value"
            type="text"
        />

      </div>
      <div class="mb-3">
        <label class="form-check-label d-flex mb-2">
                <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext shadow-tooltip" style="">{{
                        getHelpText("recurring_contract_update_final")
                      }}</span>
                  </span>
          <span>
                {{ $t("updateManyTimeUtilValue") }}
              </span>
        </label>
        <input
            class="inputFields"
            style="width: 100%"
            v-model="contractUpdate.final_value"
            type="text"
        />
      </div>

      <div class="confirmModalButtons">
        <button :disabled="processingDelete" class="btn outline-purple" @click="closePopup">
          {{ $t("cancel") }}
        </button>
        <button :disabled="processingDelete" class="btn btn-info--confirm" @click="createOrUpdateRecurringContract()">
          {{ $t("proceed") }}
        </button>
        <button :disabled="processingDelete" class="btn btn-danger" @click="deleteContractUpdate()"
                v-if="recurringContractList.length > 0 && contractUpdateActionKey !== 'create'">
          {{ $t("delete") }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {RECURRING_CONTRACT_UPDATE_OPTION_LIST} from "./../../../services/helpers/constant.js"

export default {
  name: "recurringContractActionComponent",
  props: {
    recurringContractList: {
      default: []
    },
    dataObj: {
      type: Object
    },
    access: {
      type: String
    },
    rowIndex: {
      type: Number,
      required: false
    },
    helpText: {
      type: Array,
      required: false
    },
  },
  data: function () {
    return {
      contractUpdate: {
        "final_value": 0,
        "update_with_value": 0,
        "contract_number": this.$route.params.num,
        "row_id": '',
        "update_type": "",
        "update_number_of_times": false,
        "update_until_specific_value": true
      },
      contractUpdateType: "update_until_specific_value",
      contractUpdateActionKey: 'create',
      processingDelete: false,
      optionsList: RECURRING_CONTRACT_UPDATE_OPTION_LIST,
      recurList: [],
      row: {row_id: ''}
    }
  },
  methods: {
    closePopup: function () {
      window.Bus.emit('close-recurring-contract-popup')
    },
    getHelpText: function (field) {
      let text = this.helpText.filter(item => item.field === field)
      if (text.length > 0) {
        if (this.GET_LOCALE === 'se') {
          return text[0].swedish_text
        } else {
          return text[0].english_text
        }
      }

    },
    createOrUpdateRecurringContract: function () {
      if (this.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.contractUpdate['contract_number'] = this.$route.params.num
      this.contractUpdate['row_id'] = this.dataObj.row_id
      if (this.contractUpdateType === 'update_until_specific_value') {
        this.contractUpdate['update_until_specific_value'] = true
        this.contractUpdate['update_number_of_times'] = false

      } else {
        this.contractUpdate['update_until_specific_value'] = false
        this.contractUpdate['update_number_of_times'] = true
        // this.contractUpdate['final_value'] = this.contractUpdate.update_with_value
      }
      if (this.contractUpdateActionKey === 'create') {
        this.$store
            .dispatch("clients/createRecurringSchedule", {data: this.contractUpdate})
            .then((res) => {
              let dt = res.data.data
              dt['status'] = 'ongoing'
              window.Bus.emit('update-recurring-contract-update', {index: this.rowIndex, data: dt, action: 'add'})

              this.$services.helpers.notification(
                  res.data.message,
                  "success",
                  this
              );
            })
            .catch((err) => {
              this.schedulingModal = false;
              this.$services.helpers.notification(
                  err.response.data.message,
                  "error",
                  this
              );
            });
      } else {
        this.$store
            .dispatch("clients/updateRecurringSchedule", {data: this.contractUpdate, id: this.contractUpdateActionKey})
            .then((res) => {
              window.Bus.emit('update-recurring-contract-update', {
                index: this.rowIndex,
                data: {
                  final_value: this.contractUpdate.final_value,
                  update_with_value: this.contractUpdate.update_with_value,
                  id: this.contractUpdateActionKey,
                  row_id: this.contractUpdate.row_id,
                },
                'action': 'update'
              })
              window.Bus.emit('reload-single-contract')
              this.$services.helpers.notification(
                  res.data.message,
                  "success",
                  this
              );
            })
            .catch((err) => {
              this.schedulingModal = false;
              this.$services.helpers.notification(
                  err.response.data.message,
                  "error",
                  this
              );
            });
      }
    },
    deleteContractUpdate: function () {
      this.processingDelete = true
      this.$store.dispatch('clients/deleteSchedule', {id: this.contractUpdateActionKey}).then(() => {
        this.$services.helpers.notification(
            this.$t('deletedSuccessfully'),
            "success",
            this
        );
        this.processingDelete = false
        window.Bus.emit('remove-recurring-contract', {rowIndex: this.rowIndex, key: this.contractUpdateActionKey})
      }).catch(err => {
        this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
        );
      })
    },
    onChangeRecurringActionKey: function () {
      if (this.contractUpdateActionKey === 'create') {
        this.contractUpdate = {
          "final_value": 0,
          "update_with_value": 0,
          "contract_number": this.$route.params.num,
          "row_id": this.dataObj.row_id,
          "update_type": "",
          "update_number_of_times": false,
          "update_until_specific_value": true
        }
      } else {
        let schedule = this.recurringContractList.filter(item => item.id === this.contractUpdateActionKey)
        if (schedule.length > 0) {
          Object.keys(this.contractUpdate).map(key => {
            this.contractUpdate[key] = schedule[0][key]
          })
          this.contractUpdate.update_number_of_times = schedule[0].update_number_of_times
          this.contractUpdate.update_until_specific_value = schedule[0].update_until_specific_value
          this.contractUpdate.final_value = schedule[0].final_value
          this.contractUpdate.update_with_value = schedule[0].update_with_value
          if (this.contractUpdate.update_until_specific_value === true) {
            this.contractUpdateType = 'update_until_specific_value'
          } else {
            this.contractUpdateType = 'update_number_of_times'
          }

        }
      }
    },
  },
  computed: {
    ...mapGetters({
      GET_LOCALE: 'locale/GET_LOCALE'
    }),
    hasRecurringUpdate() {
      if (this.recurringContractList.length > 0) {
        return "create_and_update"
      } else {
        return 'create'
      }
    }
  },
  mounted() {
    this.row = this.dataObj
    this.contractUpdate = {
      "final_value": 0,
      "update_with_value": 0,
      "contract_number": this.$route.params.num,
      "row_id": '',
      "update_type": "",
      "update_number_of_times": false,
      "update_until_specific_value": true
    }
    this.recurList = []
    if (this.recurringContractList.length > 0) {
      this.recurList = this.recurringContractList
      this.recurList.sort(function (a, b) {
        return b - a
      });
      this.contractUpdateActionKey = this.recurList[0].id
      this.onChangeRecurringActionKey()
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-none {
  cursor: none !important;
}

.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .contract-duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
}

.content .contract-duration h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .contract-duration .duration p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.contract-details img {
  width: 55px;
  height: 55px;
  margin-left: auto;
  cursor: pointer;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

@media (min-width: 992px) {
  .content .box form > div:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .box form .desc label {
  margin-bottom: 10px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 32px;
  width: 150px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 32px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .content .box.contract-details form > div:first-of-type {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .content .box.customer-details .desc {
    margin-right: 5%;
  }
}

.content .box table {
  width: 94%;
  margin: 10px auto;
  background: #fff;
}

.content .box table thead th {
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table thead th:last-of-type {
  padding-right: 15px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px);
  height: 1px;
  background-color: #cbd4e1;
  left: 15px;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.tooltip-position {

  top: 4px !important;
}

.content .box table tbody tr td select.mid,
.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td input.small {
  width: 31px;
}

.content .box table tbody tr td:not(:last-of-type):after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box table tbody tr td:nth-of-type(1) input,
.content .box table tbody tr td:nth-of-type(2) input {
  margin-left: 5px;
  text-align: left;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.content .box textarea:focus {
  border: none;
  outline: none;
}

.content .box.others-details {
  min-height: 320px;
}

.content .box.price {
  padding: 30px 20px;
}

.content .box.price ul {
  margin: 0;
}

.content .box.price ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box.price ul li .border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 10px;
  margin-top: 2px;
}

.content .box.price ul li:not(:first-of-type) {
  margin-top: 10px;
}

.content .box.price ul li:last-of-type {
  margin-top: 0;
}

.content .box.price ul li:last-of-type span:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box.price ul li:last-of-type span:last-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:first-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:last-of-type {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  background-color: #de4343;
  color: #fff;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.period_end_desc {
  background: #f0eff5;
  height: 34px;
  width: 150px;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
}

.period_end_desc > span {
  position: absolute;
  top: 10px;
  left: 27px;
}

.input-select {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  text-indent: 5px;
}

.col-form {
  background: #e6e5eb;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
  width: 100px !important;
}

.mid-2 {
  width: 100px;
}

.amount-dropdown {
  right: 0;
}

.inputFields {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 30px;

  &:focus-visible {
    border: none;
  }
}

.datePicker {
  & .dp__input_icon_pad {
    width: 100% !important;
  }
}

.form select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 42px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.form select.big {
  width: 192px;
}

.form .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.formc .radio input {
  display: none;
}

.form.radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.form .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.form .radio label:first-of-type {
  margin-right: 10px;
}


.form button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.form button:hover {
  background-color: #4947b0;
  color: #fff;
}

.form textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.form textarea:focus {
  border: none;
  outline: none;
}

.form .inputFields {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 42px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;

}

.form .inputFields.big {
  width: 192px;
}

.form .inputFields:-moz-read-only {
  background: #f0eff5;
}

.form .inputFields:read-only {
  background: #f0eff5;
}

.form .inputFields:focus {
  outline: none;
}

.form .inputFields.input[type="date"] {
  text-indent: 5px;
}

.form .inputFields.input[type="date"]::-webkit-clear-button,
.form .inputFields.input[type="date"]::-webkit-inner-spin-button,
.form .inputFields.input[type="date"]::-webkit-calendar-picker-indicator,
.form .inputFields.input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.form .inputFields.input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.form .inputFields.input[type="number"]::-webkit-outer-spin-button,
.form .inputFields.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.outline-purple {
  background: #fff !important;
  color: #5855d6 !important;
  border: 1px solid #5855d6 !important;
}
</style>
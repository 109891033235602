<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="d-flex">
          <router-link :to="{ name : 'eventlogs' }">
            <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z"
                  fill="#5855D6"></path>
            </svg>
            Go back to eventlogs
          </router-link>
          <h1 class="mb-0">Events</h1>
          <span v-if="SINGLE_EVENT.eng_subject && LOCALE === 'en'">{{ SINGLE_EVENT.eng_subject }}</span>
          <span v-if="SINGLE_EVENT.swe_subject && LOCALE === 'se'">{{ SINGLE_EVENT.swe_subject }}</span>
        </div>
        <div class="box">
          <h2>Details </h2>
          <div class="desc">
            <h3>{{ $services.helpers.capitalizeNames($t('event_type')) }}</h3>
            <span v-if="SINGLE_EVENT.event === 'SUCCESS'" class="success">{{ $services.helpers.capitalizeNames($t('event_success')) }}</span>
            <span v-if="SINGLE_EVENT.event === 'INFO'" class="info">{{ $services.helpers.capitalizeNames($t('event_info')) }}</span>
            <span v-if="SINGLE_EVENT.event === 'ERROR'" class="error">{{ $services.helpers.capitalizeNames($t('event_error')) }}</span>
          </div>
          <div class="desc">
            <h3>{{ $services.helpers.capitalizeNames($t('subject')) }}</h3>
            <input type="text" disabled readonly :value="SINGLE_EVENT.eng_subject"
                   v-if="SINGLE_EVENT.eng_subject && LOCALE === 'en'">
            <input type="text" disabled readonly :value="SINGLE_EVENT.swe_subject"
                   v-if="SINGLE_EVENT.swe_subject && LOCALE === 'se'">
          </div>
          <div class="desc">
            <h3>{{ $services.helpers.capitalizeNames($t('received')) }}</h3>
            <input type="text" disabled readonly :value="SINGLE_EVENT.received">
          </div>
          <div class="desc">
            <h3>{{ $services.helpers.capitalizeNames($t('event_message')) }}</h3>
            <textarea disabled readonly :value="SINGLE_EVENT.eng_message" v-if="LOCALE === 'en'"></textarea>
            <textarea disabled readonly :value="SINGLE_EVENT.swe_message" v-else></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'

export default {
  name: 'EventFullLog',
  setup() {
    const store = useStore()
    const route = useRoute()
    const getSingleEventLog = (id) => store.dispatch('clients/getSingleEvent', id)
    const SINGLE_EVENT = computed(() => store.getters['clients/GET_SINGLE_EVENT'])
    const LOCALE = computed(() => store.getters['locale/GET_LOCALE'])

    onMounted(() => {
      const {id} = route.params
      if (id) {
        getSingleEventLog(id)
      }
    })
    return {
      SINGLE_EVENT,
      LOCALE
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content > div {
  margin: 20px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content > div > a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content > div > a svg {
  margin-top: -2px;
}

.content > div > a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content > div > a:hover {
  color: #4946ae;
}

.content > div > a:hover svg path {
  fill: #4946ae;
}

.content > div h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content > div h1 ~ span {
  font-size: 18px;
  line-height: 28px;
  color: #27364b;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding-bottom: 40px;
  margin-top: 20px;
  min-width: 550px;
}

.content .box h2 {
  background: #5855d6;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px 40px;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 40px;
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box .desc {
  margin-top: 20px;
  padding: 5px 40px;
}

.content .box .desc:nth-of-type(1) h3 {
  color: #0e1566;
}

.content .box .desc:nth-of-type(1) span {
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  border-radius: 4px;
  width: 79px;
  height: 27px;
  border: none;
  display: block;
  text-align: center;
  text-transform: capitalize;
}

.content .box .desc:nth-of-type(1) span.success {
  background: #a5e1bf;
  color: #00632b;
}

.content .box .desc:nth-of-type(1) span.error {
  background: #fc9595;
  color: #b01212;
}

.content .box .desc:nth-of-type(1) span.info {
  background: #7ea5f8;
  color: #cbd4e1;
}

.content .box .desc:nth-of-type(3) input {
  width: 40%;
}

@media (min-width: 576px) {
  .content .box .desc:nth-of-type(3) input {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .content .box .desc:nth-of-type(3) input {
    width: 20%;
  }
}

.content .box .desc h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.content .box .desc input,
.content .box .desc textarea {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 5px 40px 5px 10px;
  border: none;
}

.content .box .desc input {
  width: 80%;
}

@media (min-width: 576px) {
  .content .box .desc input {
    width: 55%;
  }
}

@media (min-width: 768px) {
  .content .box .desc input {
    width: 40%;
  }
}

@media (min-width: 992px) {
  .content .box .desc input {
    width: 30%;
  }
}

.content .box .desc textarea {
  resize: none;
  min-height: 103px;
  width: 100%;
}

@media (min-width: 576px) {
  .content .box .desc textarea {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .content .box .desc textarea {
    width: 70%;
  }
}
</style>
<template>
  <div class="col responsive-width">
    <FullScreenLoading v-if="processing"/>
    <div class="container-fluid" v-if="loading">
      <div class="content">
        <div class="headers d-flex">
          <div class="row">
            <div class="col-md-12">
              <h1 class="mb-0">{{ $t("contracts") }}</h1>
              <h6>{{ $t("edit_contract") }}</h6>
            </div>
          </div>
          <div>

          </div>


        </div>
        <div class="contract-duration flex-wrap justify-content-between">
          <h2 class="mr-3">{{ $t("contract") }} {{ SINGLE_CONTRACT.document_number }}</h2>
          <div
              v-if="SINGLE_CONTRACT.period_start"
              class="duration my-2 flex-wrap"
          >
            <p>
              {{ $t("contract") }} {{ $t("start") }}:
              {{ SINGLE_CONTRACT.contract_date }}
            </p>
            <p>{{ $t("contract") }} {{ $t("end") }}: {{ contract_end }}</p>
          </div>
        </div>
        <div class="box contract-details">
          <h3>{{ $t("contract_detail") }}</h3>
          <form>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("customer") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="SINGLE_CONTRACT.customer_name"
                    readonly
                    :disabled="terminated"
                />
              </div>
              <div class="desc">
                <label>{{ $t("contract_date") }}</label>
                <Datepicker
                    v-model="SINGLE_CONTRACT.contract_date"
                    format="yyyy-MM-dd"
                    readonly
                    :disabled="terminated"
                ></Datepicker>
              </div>
              <div class="desc">
                <label>{{ $t("period_start") }}</label>
                <Datepicker
                    v-model="SINGLE_CONTRACT.period_start"
                    format="yyyy-MM-dd"
                    readonly
                    :disabled="terminated"
                ></Datepicker>
                <!--                <input type="date" :value="SINGLE_CONTRACT.period_start" readonly>-->
              </div>
              <div class="desc">
                <label>{{ $t("period_end") }}</label>
                <Datepicker
                    v-model="SINGLE_CONTRACT.period_end"
                    format="yyyy-MM-dd"
                    readonly
                    :disabled="terminated"
                ></Datepicker>
                <!--                <input type="date" :value="SINGLE_CONTRACT.period_end" readonly>-->
              </div>
              <div class="desc">
                <label>{{ $t("active") }}</label>
                <div class="radio">
                  <input
                      type="radio"
                      v-model="SINGLE_CONTRACT.active"
                      :disabled="terminated"
                      :value="true"
                      name="stock-item-active"
                      id="stock-yes-active"
                  />
                  <label for="stock-yes-active">{{ $t("yes") }}</label>
                  <input
                      type="radio"
                      v-model="SINGLE_CONTRACT.active"
                      :disabled="terminated"
                      :value="false"
                      name="stock-item-active"
                      id="stock-no-active"
                  />
                  <label for="stock-no-active">{{ $t("no") }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("ongoing") }}</label>
                <div class="radio">
                  <input
                      type="radio"
                      v-model="SINGLE_CONTRACT.continuous"
                      :disabled="terminated"
                      :value="true"
                      name="stock-item-continuous"
                      id="stock-yes-continuous"
                  />
                  <label for="stock-yes-continuous">{{ $t("yes") }}</label>
                  <input
                      type="radio"
                      v-model="SINGLE_CONTRACT.continuous"
                      :disabled="terminated"
                      :value="false"
                      name="stock-item-continuous"
                      id="stock-no-continuous"
                  />
                  <label for="stock-no-continuous">{{ $t("no") }}</label>
                </div>
              </div>
              <div class="desc ms-3">
                <label>{{ $t("contract_length") }}</label>
                <input
                    type="number"
                    v-model="SINGLE_CONTRACT.contract_length"
                    :disabled="terminated"
                    :readonly="terminated"
                    v-on:focusout="computeDate"
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice_interval") }}</label>
                <input
                    type="number"
                    :value="SINGLE_CONTRACT.invoice_interval"
                    readonly
                />
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("our_reference") }}</label>
                <input
                    type="text"
                    :value="SINGLE_CONTRACT.our_reference"
                    :disabled="terminated"
                />
              </div>
              <div class="desc">
                <label>{{ $t("your_reference") }}</label>
                <input
                    type="text"
                    :value="SINGLE_CONTRACT.your_reference"
                    :disabled="terminated"
                />
              </div>
              <!--              <div class="desc">-->
              <!--                <label>Your order date</label>-->
              <!--                <input type="text" value="">-->
              <!--              </div>-->
              <div class="desc">
                <label>{{ $t("your_order_number") }}</label>
                <input
                    type="text"
                    v-model="SINGLE_CONTRACT.your_order_number"
                    :disabled="terminated"
                />
              </div>
              <div class="desc">
                <label>{{ $t("terms_of_payment") }}</label>
                <select
                    v-model="SINGLE_CONTRACT.terms_of_payment"
                    :disabled="terminated"
                >
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in paymentTerm"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("terms_of_delivery") }}</label>
                <select
                    v-model="SINGLE_CONTRACT.terms_of_delivery"
                    :disabled="terminated"
                >
                  <option value=""></option>
                  <option
                      :value="term.code"
                      v-for="term in GET_TERM_OF_DELIVERY"
                      :key="term.id"
                  >
                    {{ term.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("way_of_delivery") }}</label>
                <select
                    v-model="SINGLE_CONTRACT.way_of_delivery"
                    :disabled="terminated"
                >
                  <option value=""></option>
                  <option
                      :value="delivery.code"
                      v-for="delivery in GET_WAY_OF_DELIVERY"
                      :key="delivery.id"
                  >
                    {{ delivery.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("cost_center") }} (CC)</label>
                <select
                    :disabled="terminated"
                    v-model="SINGLE_CONTRACT.cost_center"
                >
                  <option value=""></option>
                  <option
                      :value="cost.code"
                      v-for="cost in GET_COST_CENTER"
                      :key="cost.id"
                  >
                    {{ cost.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("project") }} (Pr no)</label>
                <select
                    :disabled="terminated"
                    v-model="SINGLE_CONTRACT.project"
                >
                  <option value=""></option>
                  <option
                      :value="project.code"
                      v-for="project in GET_PROJECT"
                      :key="project.id"
                  >
                    {{ project.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("price_list") }}</label>
                <select
                    :disabled="terminated"
                    v-model="SINGLE_CONTRACT.price_list"
                >
                  <option value=""></option>
                  <option
                      :value="pricelist.code"
                      v-for="pricelist in GET_PRICELIST"
                      :key="pricelist.id"
                  >
                    {{ pricelist.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("currency") }}</label>
                <select
                    :disabled="terminated"
                    v-model="SINGLE_CONTRACT.currency"
                >
                  <option value=""></option>
                  <option
                      :value="currency.code"
                      v-for="currency in GET_CURRENCY"
                      :key="currency.id"
                  >
                    {{ currency.code }}
                  </option>
                </select>
              </div>
              <!--              <div class="desc">-->
              <!--                <label>Price list</label>-->
              <!--                <select v-model="SINGLE_CONTRACT.price_list" :disabled='terminated'>-->
              <!--                  <option value=""></option>-->
              <!--                  <option :value="pricelist.code" v-for="pricelist in GET_PRICELIST" :key="pricelist.id">-->
              <!--                    {{ pricelist.description }}-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--              </div>-->
              <div class="desc">
                <label>{{ $t("vat_included") }}</label>
                <div class="radio">
                  <input
                      v-model="SINGLE_CONTRACT.vat_included"
                      type="radio"
                      :disabled="terminated"
                      :value="true"
                      name="vat"
                      id="vat-yes"
                  />
                  <label for="vat-yes">{{ $t("yes") }}</label>
                  <input
                      v-model="SINGLE_CONTRACT.vat_included"
                      type="radio"
                      :disabled="terminated"
                      :value="false"
                      name="vat"
                      id="vat-no"
                  />
                  <label for="vat-no">{{ $t("no") }}</label>
                </div>
              </div>
              <!-- <div class="desc">
                <label>Type of VAT</label>
                <div class="radio">
                  <input type="radio" value="yes" name="vat" id="vat-yes" checked>
                  <label for="vat-yes">Yes</label>
                  <input type="radio" value="no" name="vat" id="vat-no">
                  <label for="vat-no">No</label>
                </div>
              </div> -->
              <!-- <img class="mt-2" src="./../../assets/images/table/edit-chat.png"> -->
            </div>
          </form>
        </div>

        <div class="box customer-details">
          <h3>{{ $t("customer") }} {{ $t("details") }}</h3>
          <form>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("name") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="SINGLE_CONTRACT.customer_name"
                    readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice_address") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="CURRENT_CUSTOMER.address1"
                    readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("zip_code") }}</label>
                <input
                    type="text"
                    :value="CURRENT_CUSTOMER.zip_code"
                    readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("city") }}</label>
                <input type="text" :value="CURRENT_CUSTOMER.city" readonly/>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("organization_number") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="CURRENT_CUSTOMER.organisation_number"
                    readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice_address2") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="CURRENT_CUSTOMER.address2"
                    readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("country") }}</label>
                <input type="text" :value="CURRENT_CUSTOMER.country" readonly/>
              </div>
              <div class="desc">
                <label>{{ $t("phone_number") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="CURRENT_CUSTOMER.phone1"
                    readonly
                />
              </div>
            </div>
          </form>
        </div>

        <custom-invoice-row-component
            :invoice_rows="SINGLE_CONTRACT.invoice_rows"
            :key="sortKey"
            :invoice_country="CURRENT_CUSTOMER.country_code"
            @removeRow="removeRow"
            @sortRows="sortRows"
            :help-text="helpText"
            @calculateRow="reCalculateValues"
            @updateContract="addContractInvoiceRow"
            :booked="SINGLE_CONTRACT.booked"
            :cancelled="terminated"
            :show-schedule="true"
            :show-contract-update="true"
            :vat_included="SINGLE_CONTRACT.vat_included"
            :submitForm="submitForm"
            :allowSorting="true"
        />

        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="box others-details">
              <h3>{{ $t("other_detail") }}</h3>
              <form>
                <div class="d-flex mt-xl-3 flex-wrap col-flex-wrap">
                  <div class="desc me-0 w-full">
                    <label>{{ $t("remarks") }}</label>
                    <textarea
                        class="w-full"
                        v-model="SINGLE_CONTRACT.remarks"
                        :disabled="terminated"
                    ></textarea>
                  </div>
                  <div class="desc me-0 w-full">
                    <label>{{ $t("comment") }}</label>
                    <textarea
                        class="w-full"
                        v-model="SINGLE_CONTRACT.comments"
                        :disabled="terminated"
                    >
                    </textarea>
                  </div>

                  <!-- <div class="d-flex flex-wrap"> -->
                  <!-- <div class="desc">
                        <label>Shipping charge</label>
                        <input type="text">
                      </div>
                      <div class="desc">
                        <label>Invoice charge</label>
                        <input type="text">
                      </div> -->
                  <!-- <div class="desc">
                        <label>Invoice dicsount %</label>
                        <input type="text" :value="SINGLE_CONTRACT.invoice_discount">
                      </div> -->
                  <!-- </div> -->

                  <div class="desc">
                    <label>{{ $t("print_template") }}</label>
                    <select
                        class="big"
                        :disabled="terminated"
                        v-model="SINGLE_CONTRACT.print_template"
                    >
                      <option value=""></option>
                      <option
                          v-for="(item, i) in printOutTemplate"
                          :value="item.template"
                          :key="i"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="desc">
                        <label>Language</label>
                        <select :value="SINGLE_CONTRACT.languagprintOutOrderTeme">
                          <option>{{ SINGLE_CONTRACT.language }}</option>
                        </select>
                      </div> -->
                </div>
              </form>
            </div>
          </div>
          <div class="col">
            <div class="box price">
              <ul class="list-unstyled">
                <!-- <li><span class="text">{{ $t('net') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ SINGLE_CONTRACT.total }}</span>
                </li>
                <li><span class="text">{{ $t('gross') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ SINGLE_CONTRACT.gross }}</span>
                </li> -->
                <!-- summary -->
                <li>
                  <span class="text">{{ $t("vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ SINGLE_CONTRACT.total_vat }}</span>
                </li>
                <li>
                  <span class="text">{{ $t("total_exclude_vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ SINGLE_CONTRACT.total }}</span>
                </li>
                <hr/>
                <li>
                  <span class="text">{{ $t("total") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ SINGLE_CONTRACT.total_to_pay }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="buttons flex-wrap align-items-center">
          <button
              type="submit"
              :disabled="terminating || terminated"
              @click="terminateContract"
          >
            <span v-if="terminating">{{ $t("terminating") }}...</span>
            <span v-else>{{ $t("terminate_contract") }}</span>
          </button>
          <div class="right flex-wrap my-2 my-sm-0">
            <router-link :to="{ name: 'contracts' }">{{
                $t("cancel")
              }}
            </router-link>
            <button
                @click="submitForm = true"
                type="submit"
                :disabled="processing || terminated"
            >
              <span v-if="processing">{{ $t("updating") }}...</span>
              <span v-else>{{ $t("update_contract") }}</span>
            </button>
            <!-- <button @click="updateContract" type="submit" :disabled='processing || terminated'>
              <span v-if="processing">{{ $t('updating') }}...</span>
              <span v-else>{{ $t('update_contract') }}</span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="content">
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12">
            <div class="box square">
              <shimmer-loading :num="5"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalBoxVue v-if="newType" @handleClose="newType = false" :close="!true">
      <div class="p-3 form">
        <div>
          <h4>{{ $t('updateRecurringContract') }}</h4>
        </div>
        <div class="mb-3 mt-2">
          <p class="mb-2">{{ $t("changeType") }}</p>
          <select
              v-model="scheduling.change_type"
              style="width: 100%"
              class="big"
          >
            <option value="increase_discount">{{ $t("increaseDiscount") }}</option>
            <option value="decrease_discount">{{ $t("decreaseDiscount") }}</option>
            <option value="remove_discount">{{ $t("removeDiscount") }}</option>
            <option value="increase_quantity">{{ $t("increaseDeliveredQuantity") }}</option>
            <option value="decrease_quantity">{{ $t("decreaseDeliveredQuantity") }}</option>
            <option value="increase_price">{{ $t("increasePrice") }}</option>
            <option value="decrease_price">{{ $t("decreasePrice") }}</option>
            <option value="change_article">{{ $t("changeArticle") }}</option>
            <option value="remove_row">{{ $t("removeRow") }}</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-check-label d-flex">
            <span class="ml-1">{{ $t('updateUntilSpecificValue') }}</span>
            <span class="mt-1 ml-20px">
              <input
                  class="form-check-input"
                  type="radio"
              />
           </span>

          </label>

        </div>
        <div class="">
          <label class="form-check-label d-flex">
            <span class="ml-1">{{ $t('updateNumberEachTime') }}</span>
            <span class="mt-1 ml-35px">
              <input
                  class="form-check-input"
                  type="radio"
              />
           </span>

          </label>

        </div>
        <div class="mb-3">
          <label class="form-check-label d-flex mb-2">
            <span class="ml-1">{{ $t('eachTimeUpdateWithValue') }}</span>

          </label>
          <input
              class="inputFields"
              style="width: 100%"
              type="text"
          />

        </div>
        <div class="mb-3">
          <label class="form-check-label d-flex mb-2">
            <span class="ml-1">{{ $t('updateManyTimeUtilValue') }}</span>

          </label>
          <input
              class="inputFields"
              style="width: 100%"
              type="text"
          />
        </div>

        <div class="d-flex justify-content-center">
          <button class="btn btn-primary m-3">OK</button>
        </div>
      </div>
    </ModalBoxVue>
  </div>
</template>

<script>
import FullScreenLoading from "../../components/FullScreenLoading.vue";

import {mapGetters} from "vuex";
import debounce from "lodash.debounce";
import {contractRow} from "./composables/contract.create";
import Datepicker from "vue3-date-time-picker";
import ShimmerLoading from "./reusableComponents/ShimmerLoading";
import ModalBoxVue from "../../components/ModalBox.vue";
// import InvoiceRowForm from "./reusableComponents/InvoiceRowForm";
import CustomInvoiceRowComponent from "./reusableComponents/CustomInvoiceRowComponent";
import moment from "moment";

export default {
  name: "ContractEdit",
  components: {
    Datepicker,
    ShimmerLoading,
    CustomInvoiceRowComponent,
    FullScreenLoading,
    ModalBoxVue
  },
  data() {
    return {
      sortKey: 0,
      invoiceRow: 0,
      row: {...contractRow},
      showArticle: false,
      showAccount: false,
      searchArticle: [],
      accounts: [],
      helpText: [],
      printOutTemplate: [],
      pricelist: [],
      paymentTerm: [],
      deliveryWay: [],
      project: [],
      currency: [],
      costCenter: [],
      units: [],
      processing: false,
      terminating: false,
      loading: false,
      terminated: false,
      contract_end: "",
      submitForm: false,
      newType: false,
      sortValue: '',
      newEventType: '',
      scheduling: {
        row_id: "",
        contract_number: "",
        date_of_action: "",
        change_type: "",
        new_value: "",
      },
    };
  },
  watch: {
    "SINGLE_CONTRACT.continuous": function (n, o) {
      if (n) {
        this.SINGLE_CONTRACT.contract_length = null;
      } else {
        this.SINGLE_CONTRACT.contract_length = 12;
      }
    },
    "SINGLE_CONTRACT.vat_included": function (newVal, oldVal) {
      this.reCalculateValues(null);
    },
  },
  computed: {
    ...mapGetters({
      SINGLE_CONTRACT: "clients/GET_SINGLE_CONTRACT",
      GET_COST_CENTER: "clients/GET_COST_CENTER",
      GET_CURRENCY: "clients/GET_CURRENCY",
      GET_PRICELIST: "clients/GET_PRICELIST",
      GET_TERM_OF_DELIVERY: "clients/GET_TERM_OF_DELIVERY",
      GET_TERM_OF_PAYMENT: "clients/GET_TERM_OF_PAYMENT",
      GET_WAY_OF_DELIVERY: "clients/GET_WAY_OF_DELIVERY",
      GET_PROJECT: "clients/GET_PROJECT",
      CURRENT_USER: "clients/GET_CURRENT_USER",
      CURRENT_CUSTOMER: "clients/GET_CURRENT_CUSTOMER",
      GET_LOCALE: 'locale/GET_LOCALE'
    }),
    locale_message() {
      return this.GET_LOCALE === 'se' ? 'message' : 'eng_message'
    }
  },
  mounted() {
    const {num} = this.$route.params;
    if (num) {
      this.getContract(num);
      this.getCostCenter();
      this.getCurrencies();
      this.getHelptextList();
      this.getPricelists();
      this.getPaymentTerm();
      this.getTermsOfDelivery();
      this.getTermsOfPayment();
      this.getWayOfDelivery();
      this.getProject();
      // this.getAccount()
      this.getUnit();
      this.getPrintOutTemplate();
      window.Bus.on('reload-single-contract', e => {
        this.reloadContract(num);
      })
    }
  },
  methods: {
    getDiscountedTotal(total, discount, discountType) {
      if (discount) {
        if (discountType === "AMOUNT") {
          total = total - discount
        } else {
          total = total *
          (
              1 -
              parseFloat(
                  this.$services.helpers.removePercentage(discount) / 100
              )
          )
        }
      }
      return parseFloat(total.toFixed(2));
    },
    getCurrentCustomer(customerNumber) {
      this.$store.dispatch("clients/getCurrentCustomer", customerNumber);
    },

    computeDate: function () {
      if (this.SINGLE_CONTRACT.continuous === false) {
        if (this.SINGLE_CONTRACT.contract_date) {
          this.contract_end = moment(this.SINGLE_CONTRACT.contract_date)
              .add(this.SINGLE_CONTRACT.contract_length, "M")
              .format("YYYY-MM-DD");
        } else {
          this.contract_end = "";
        }
      } else {
        this.contract_end = "";
      }
    },
    searchAccountField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterAccountSearch(event.target.value);
      } else {
        this.showAccount = false;
      }
    }, 500),
    filterAccountSearch(data) {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.showAccount = true;
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    chooseAccount(item) {
      this.row.account_number = item.number;
      this.showAccount = false;
    },
    searchArticleField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticleSearch(event.target.value);
      } else {
        this.showArticle = false;
      }
    }, 500),
    filterArticleSearch(data) {
      const payload = {
        URL:
            this.$services.endpoints.ARTICLE_ENDPOINT +
            `?search=${data}&customize=no`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showArticle = true;
              this.searchArticle = res.data.data.filter(
                  (item) => item.active === true
              );
            }
          })
          .catch((err) => {
          });
    },
    chooseArticle(item) {
      this.row.row_id = item.article_number;
      this.row.description = item.description;
      this.row.price = (item.sales_price == null) ? 0 : item.sales_price;
      // this.row.total = item.
      // this.row.cost_center = item.
      if (this.row.price && this.row.delivered_quantity) {
        this.row.total = this.row.delivered_quantity * this.row.price;
      }
      this.row.vat = item.vat;
      this.row.account_number = item.sales_account;
      this.row.unit = item.unit;
      this.showArticle = false;

      this.reCalculateValues(null);
    },

    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.SINGLE_CONTRACT.invoice_rows.forEach((element) => {
        let vat_amount = 0;
        let vat_rate;
        if (!element.vat) {
          vat_rate = 0;
        } else {
          vat_rate = element.vat;
        }
        total_excluding_vat += parseFloat(element.total_excluding_vat);

        vat_amount =
            element.total_excluding_vat * (1 + vat_rate / 100) -
            element.total_excluding_vat;
        total += parseFloat(
            element.total_excluding_vat * (1 + vat_rate / 100)
        );
        total_vat += vat_amount;

        if (this.SINGLE_CONTRACT.vat_included) {
          element.total = (
              parseFloat(element.delivered_quantity) * 
              parseFloat(element.price_excluding_vat) *
              parseFloat(1 + parseFloat(vat_rate) / 100)
          );
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);
          element.price = (
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(vat_rate) / 100).toFixed(2)
          ).toFixed(2);
        } else {
          element.total = (
              parseFloat(element.delivered_quantity) * parseFloat(element.price_excluding_vat) 
          );
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);
          element.total_excluding_vat = parseFloat(element.total_excluding_vat.toFixed(2));
          
          element.price_excluding_vat = parseFloat(
              element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        let vat_rate;
        if (!item.vat) {
          vat_rate = 0;
        } else {
          vat_rate = item.vat;
        }
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.SINGLE_CONTRACT.vat_included) {
            item.total = parseFloat(item.delivered_quantity) * parseFloat(item.price_per_unit) 
            item.total = this.getDiscountedTotal(item.total, item.discount, item.discount_type);
            item.total_excluding_vat =
                item.total / (1 + parseFloat(vat_rate) / 100);
          } else {
            item.total_excluding_vat = parseFloat(item.delivered_quantity) * parseFloat(item.price_per_unit)
            item.total_excluding_vat = this.getDiscountedTotal(item.total_excluding_vat, item.discount, item.discount_type);
            item.total =
                item.total_excluding_vat * (1 + parseFloat(vat_rate) / 100);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat);

          vat = item.total - item.total_excluding_vat;
          total_vat += vat;
        }
      }

      this.SINGLE_CONTRACT.gross = parseFloat(total).toFixed(2);
      this.SINGLE_CONTRACT.total_vat = parseFloat(total_vat).toFixed(2);
      this.SINGLE_CONTRACT.total = parseFloat(total_excluding_vat).toFixed(2);
      this.SINGLE_CONTRACT.total_to_pay = parseFloat(total).toFixed(2);
    },
    // reCalculateValues(item = null) {
    //   let gross = 0
    //   let rowVatTotal = 0

    //   this.SINGLE_CONTRACT.invoice_rows.forEach(element => {
    //     let vat = 0
    //     if (this.SINGLE_CONTRACT.vat_included) {
    //       element.total = parseInt(element.delivered_quantity) * parseFloat(element.price * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(element.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //       vat = element.total * parseFloat(element.vat / 100)
    //     } else {
    //       const rowExVat = parseInt(element.delivered_quantity) * parseFloat(element.price * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(element.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //       // element.vat = rowExVat * parseFloat(25 / 100).toFixed(2)
    //       vat = rowExVat * parseFloat(element.vat / 100)
    //       element.total = parseFloat(rowExVat + element.vat).toFixed(2)
    //     }
    //     gross += parseFloat(element.total)
    //     rowVatTotal += parseFloat(vat)
    //   })
    //   if (this.SINGLE_CONTRACT.vat_included === true) {
    //     if (item !== null) {
    //       if (item.delivered_quantity && item.price) {
    //         let total = parseInt(item.delivered_quantity) * parseFloat(parseFloat(item.price) * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(item.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //         let vat = parseFloat(total) * (parseFloat(item.vat) / 100)
    //         gross += parseFloat(total)
    //         rowVatTotal += parseFloat(vat)
    //       }
    //     }
    //   } else {
    //     if (item !== null) {
    //       if (item.delivered_quantity && item.price) {
    //         let rowExVat = parseInt(item.delivered_quantity) * parseFloat(item.price * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(item.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //         let vat = rowExVat * parseFloat(item.vat / 100).toFixed(2)
    //         let total = parseFloat(rowExVat + item.vat).toFixed(2)
    //         gross += parseFloat(total)
    //         rowVatTotal += parseFloat(vat)
    //       }
    //     }
    //   }

    //   this.SINGLE_CONTRACT.gross = parseFloat(gross).toFixed(2)
    //   this.SINGLE_CONTRACT.total_vat = parseFloat(rowVatTotal).toFixed(2)
    //   this.SINGLE_CONTRACT.total = parseFloat(gross - rowVatTotal).toFixed(2)
    //   this.SINGLE_CONTRACT.total_to_pay = parseFloat(gross).toFixed(2)

    // },
    addContractInvoiceRow() {
      this.setDefaultRows();
      this.reCalculateValues(null);
      this.updateContract();
      // if (this.CURRENT_USER.access === 'Read') {
      //   return this.$toast.info(this.$t('limited_read_access'));
      // }
      // if (this.row.row_id === '' || this.row.account_number === '') {
      //   // this.$services.helpers.notification('Please fill up the invoice row', 'error', this)
      //   return this.$toast.info('New invoice row was not properly filled. It will not be saved');
      // } else {
      //   this.SINGLE_CONTRACT.invoice_rows.push(JSON.parse(JSON.stringify(this.row)))
      //   // Object.keys(this.row).forEach(element => {
      //   //     this.row[element] = ''
      //   // })
      //
      // }
    },
    getHelptextList() {
      const payload = {
        URL:
        this.$services.endpoints.HELPTEXT_ENDPOINT
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            this.helpText = res.data.results

          })
          .catch((err) => {
          });
    },
    updateContract() {
      this.submitForm = false;
      this.processing = true;
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.SINGLE_CONTRACT.category === "Finished") {
        return this.$services.helpers.notification(
            "Terminated contract can not be updated",
            "error",
            this
        );
      }
      if (
          this.SINGLE_CONTRACT.contract_date === "" ||
          this.SINGLE_CONTRACT.period_start === "" ||
          this.SINGLE_CONTRACT.period_end === "" ||
          this.SINGLE_CONTRACT.customer_name === ""
      ) {
        // this.$services.helpers.notification('Fields mark with astericks are required', 'error', this)
        this.$toast.warning(this.$t("starred_fields_required"));
      } else {
        let payload = this.SINGLE_CONTRACT;
        let invoice_rows = this.SINGLE_CONTRACT.invoice_rows;
        let invoice_rows_data = [];
        let data = {};
        invoice_rows.map((item) => {
          let val = item;
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete val[key];
            }
          }
          invoice_rows_data.push(JSON.parse(JSON.stringify(val)));
        });
        // adjust invoice rows before sending to BE
        if (this.previousVatIncluded !== this.SINGLE_CONTRACT.vat_included) {
          if (this.SINGLE_CONTRACT.vat_included === true) {
            invoice_rows_data.forEach((item) => {
              let vat_rate;
              if (!item.vat) {
                vat_rate = 0;
              } else {
                vat_rate = item.vat;
              }
              // alter invoice row values
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                    item.price / (1 + vat_rate / 100)
                ).toFixed(2);
                item.total = parseFloat(
                    item.total / (1 + vat_rate / 100)
                ).toFixed(2);
              }
            });
          } else {
            invoice_rows_data.forEach((item) => {
              let vat_rate;
              if (!item.vat) {
                vat_rate = 0;
              } else {
                vat_rate = item.vat;
              }
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                    item.price * (1 + vat_rate / 100)
                ).toFixed(2);
                item.total = parseFloat(
                    item.total * (1 + vat_rate / 100)
                ).toFixed(2);
              }
            });
          }
        }
        for (const [key, value] of Object.entries(payload)) {
          if (key !== "invoice_rows") {
            if (value !== "" || value !== null) {
              data[key] = value;
            }
          }
        }
        data["invoice_rows"] = invoice_rows_data;
        for (const [key, value] of Object.entries(payload)) {
          if (key !== "invoice_rows") {
            if (value === "" || value === null) {
              delete data[key];
            }
          }
        }

        this.$store
            .dispatch("clients/updateContract", data)
            .then((res) => {
              this.processing = false;
              this.submitForm = false;
              this.$services.helpers.notification(
                  res.data[this.locale_message],
                  "success",
                  this
              );

              // const { num } = this.$route.params;
              // this.$router.replace({
              //   name: "editContract",
              //   params: { num },
              // });
              setTimeout(() => {
                window.location.reload(true);
              }, 500);
            })
            .catch((err) => {
              this.processing = false;
              this.submitForm = false;
              this.$services.helpers.notification(
                  err.response.data[this.locale_message],
                  "error",
                  this
              );
            });
      }
    },
    terminateContract() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.terminating = true;

      const payload = {
        action: "finish",
        id: this.SINGLE_CONTRACT.document_number,
      };

      this.$store
          .dispatch("clients/updateContractSingleAction", payload)
          .then((res) => {
            this.terminating = false;
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
            setTimeout(() => {
              window.location.reload(true);
            }, 500);
          })
          .catch((err) => {
            this.terminating = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    getContract(data) {
      this.$store
          .dispatch("clients/getSingleContract", data)
          .then((res) => {
            this.loading = true;
            if (this.SINGLE_CONTRACT.category === "Finished") {
              this.terminated = true;
            }
            this.previousVatIncluded = this.SINGLE_CONTRACT.vat_included;
            this.computeDate();

            this.getCurrentCustomer(this.SINGLE_CONTRACT.customer_number);
          })
          .catch((err) => {
            this.loading = true;
          });
    },
    reloadContract(data) {
      this.$store
          .dispatch("clients/getSingleContract", data)
          .then((res) => {
            if (this.SINGLE_CONTRACT.category === "Finished") {
              this.terminated = true;
            }
            this.previousVatIncluded = this.SINGLE_CONTRACT.vat_included;
            this.computeDate();
          })
          .catch((err) => {
          });
    },
    getCostCenter() {
      this.$store.dispatch("clients/getCostCenter");
    },
    getCurrencies() {
      this.$store.dispatch("clients/getCurrencies");
    },
    getPricelists() {
      this.$store.dispatch("clients/getPricelists");
    },
    getTermsOfDelivery() {
      this.$store.dispatch("clients/getTermsOfDelivery");
    },
    getTermsOfPayment() {
      this.$store.dispatch("clients/getTermsOfPayment");
    },
    getWayOfDelivery() {
      this.$store.dispatch("clients/getWayOfDelivery");
    },
    getProject() {
      this.$store.dispatch("clients/getProjects");
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "accounts";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.units = res.data.results;
          })
          .catch((err) => {
          });
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "termsofpayment";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.paymentTerm = res.data.results;
          })
          .catch((err) => {
          });
    },
    getPrintOutTemplate() {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT +
          `printtemplates?type=invoice`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.printOutTemplate = res.data.results;
          })
          .catch((err) => {
          });
    },
    incrementRow() {
      this.$state.invoiceRow += 1;
    },
    setDefaultRows() {
      this.row = {...contractRow};
    },
    removeRow(item) {
      this.SINGLE_CONTRACT.invoice_rows = this.SINGLE_CONTRACT.invoice_rows.filter(el => el.id !== item.id);
      this.sortKey++;
      this.reCalculateValues(null);
    },
    sortRows(column, order) {
      this.SINGLE_CONTRACT.invoice_rows.sort((a, b) => {
        let valA = a[column];
        let valB = b[column];

        // Convert string numbers to floats for proper numeric comparison
        if (typeof valA === 'string' && /^\d+\.?\d*$/.test(valA)) {
          valA = parseFloat(valA);
        } else if (typeof valA === 'string') {
          valA = valA.toLowerCase();
        }

        if (typeof valB === 'string' && /^\d+\.?\d*$/.test(valB)) {
          valB = parseFloat(valB);
        } else if (typeof valB === 'string') {
          valB = valB.toLowerCase();
        }

        // Compare numbers or strings accordingly
        if (order === 'asc') {
          return valA > valB ? 1 : (valA < valB ? -1 : 0);
        } else {
          return valA < valB ? 1 : (valA > valB ? -1 : 0);
        }
      });
      this.SINGLE_CONTRACT.invoice_rows = [...this.SINGLE_CONTRACT.invoice_rows];
      this.sortKey++;
    },
    rowChange(item) {
      this.reCalculateValues(null);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .contract-duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
}

.content .contract-duration h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .contract-duration .duration p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.contract-details h3 {
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box.contract-details img {
  width: 55px;
  height: 55px;
  margin-left: auto;
  cursor: pointer;
}

.content .box.customer-details h3 {
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.invoice h3 {
  background-image: url("./../../assets/images/table/invoice-row.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.others-details h3 {
  background-image: url("./../../assets/images/table/others.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

@media (min-width: 992px) {
  .content .box form > div:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .box form .desc label {
  margin-bottom: 10px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input:-moz-read-only {
  background: #f0eff5;
}

.content .box form .desc input:read-only {
  background: #f0eff5;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="date"] {
  text-indent: 5px;
}

.content .box form .desc input[type="date"]::-webkit-clear-button,
.content .box form .desc input[type="date"]::-webkit-inner-spin-button,
.content .box form .desc input[type="date"]::-webkit-calendar-picker-indicator,
.content .box form .desc input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 95px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .content .box.contract-details form > div:first-of-type {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .content .box.customer-details .desc {
  }
}

.content .box table {
  width: 94%;
  margin: 10px auto;
  background: #fff;
  min-width: 945px;
}

.content .box table thead th {
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding: 20px 15px;
  text-align: center;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px);
  height: 1px;
  background-color: #cbd4e1;
  left: 15px;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td input.small {
  width: 31px;
}

.content .box table tbody tr td:not(:last-of-type):after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box table tbody tr td:nth-of-type(1) input,
.content .box table tbody tr td:nth-of-type(2) input {
  margin-left: 5px;
  text-align: left;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.content .box textarea:focus {
  border: none;
  outline: none;
}

.content .box.others-details {
  min-height: 320px;
}

.content .box.price {
  padding: 30px 20px;
}

.content .box.price ul {
  margin: 0;
}

.content .box.price ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box.price ul li .border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 10px;
  margin-top: 2px;
}

.content .box.price ul li:not(:first-of-type) {
  margin-top: 10px;
}

.content .box.price ul li:last-of-type {
  margin-top: 0;
}

.content .box.price ul li:last-of-type span:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box.price ul li:last-of-type span:last-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:first-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:last-of-type {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  background-color: #de4343;
  color: #fff;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.col-form {
  background: #e6e5eb;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
  width: 100px !important;
}

.float-right {
  float: right !important;
}

.dp__main.dp__theme_light,
.dp__input_wrap {
  min-width: 130px;
}

.form select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 42px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.form select.big {
  width: 192px;
}

.form .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.formc .radio input {
  display: none;
}

.form.radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.form .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.form .radio label:first-of-type {
  margin-right: 10px;
}


.form button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.form button:hover {
  background-color: #4947b0;
  color: #fff;
}

.form textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.form textarea:focus {
  border: none;
  outline: none;
}

.form .inputFields {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 42px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;

}

.form .inputFields.big {
  width: 192px;
}

.form .inputFields:-moz-read-only {
  background: #f0eff5;
}

.form .inputFields:read-only {
  background: #f0eff5;
}

.form .inputFields:focus {
  outline: none;
}

.form .inputFields.input[type="date"] {
  text-indent: 5px;
}

.form .inputFields.input[type="date"]::-webkit-clear-button,
.form .inputFields.input[type="date"]::-webkit-inner-spin-button,
.form .inputFields.input[type="date"]::-webkit-calendar-picker-indicator,
.form .inputFields.input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.form .inputFields.input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.form .inputFields.input[type="number"]::-webkit-outer-spin-button,
.form .inputFields.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<template>
  <div>
    <!-- <shimmer-loading v-if="false" :num="10"></shimmer-loading> -->
    <div class="row">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <div
                  class="d-flex flex-row align-items-center justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showArticleCategorySelector =
                          !showArticleCategorySelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showArticleCategorySelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseArticleCategory('highest')"
                        >
                          {{ $t("highest_earning") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseArticleCategory('lowest')"
                        >
                          {{ $t("lowest_earning") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h2>
                    {{
                      articleCategory == "highest"
                        ? $t("highest_earning") + " " + $t("articles")
                        : $t("lowest_earning") + " " + $t("articles")
                    }}
                  </h2>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showHighestEarningArticlesInfo =
                        !showHighestEarningArticlesInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showHighestEarningArticlesInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.highestEarningArticles.eng
                          : infoMessages.highestEarningArticles.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>
              <div v-else>
                <apexchart
                  v-if="articleCategory == 'highest'"
                  type="bar"
                  :options="articleEarningGraph.options"
                  :series="articleEarningGraph.highest_series"
                ></apexchart>
                <apexchart
                  v-else
                  type="bar"
                  :options="articleEarningGraph.options"
                  :series="articleEarningGraph.lowest_series"
                ></apexchart>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2
                  v-if="salesFor == 'article'"
                  style="margin: 10px 10px 5px 10px"
                >
                  {{ $t("sales_for_article") }} "{{
                    chosenArticle.description || "..."
                  }}"
                </h2>
                <h2 v-else style="margin: 10px 10px 5px 10px">
                  {{ $t("sales_for_segment") }} "{{
                    chosenSegment.name || "..."
                  }}"
                </h2>
                <div
                  class="d-flex flex-row align-items-end justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showSalesCategorySelector = !showSalesCategorySelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showSalesCategorySelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseSalesCategory('article')"
                        >
                          {{ $t("article") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseSalesCategory('segment')"
                        >
                          {{ $t("segment") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-if="salesFor == 'article'" style="position: relative">
                    <label style="font-size: 11px" for="search-article">{{
                      $t("search") + " " + $t("article")
                    }}</label>
                    <input
                      @input="searchArticle"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="articleKey"
                    />
                    <div
                      v-if="showArticleSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in articles.slice(0, 5)"
                          :key="item.id"
                          @click="chooseArticle(item)"
                        >
                          {{ item.article_number + " - " + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-else style="position: relative">
                    <label style="font-size: 11px" for="search-segment">{{
                      $t("search") + " " + $t("segment")
                    }}</label>
                    <input
                      @input="searchSegment"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="segmentKey"
                    />
                    <div
                      v-if="showSegmentSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in segments.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSegment(item)"
                        >
                          {{ item.name + " - " + item.id }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="sales_vat_included"
                      type="radio"
                      :value="true"
                      name="sales_vat"
                      id="sales_vat-yes"
                    />
                    <label for="sales_vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="sales_vat_included"
                      type="radio"
                      :value="false"
                      name="sales_vat"
                      id="sales_vat-no"
                    />
                    <label for="sales_vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="showSalesInfo = !showSalesInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showSalesInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.sales.eng
                          : infoMessages.sales.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading
                v-if="loading || salesLoading"
                :num="6"
              ></shimmer-loading>
              <div v-else>
                <div v-if="salesFor == 'article'">
                  <apexchart
                    v-if="sales_vat_included"
                    type="line"
                    :options="salesByArticleGraph.options"
                    :series="salesByArticleGraph.article_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesByArticleGraph.options"
                    :series="salesByArticleGraph.article_ex_vat_series"
                  ></apexchart>
                </div>
                <div v-else>
                  <apexchart
                    v-if="sales_vat_included"
                    type="line"
                    :options="salesByArticleGraph.options"
                    :series="salesByArticleGraph.segment_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesByArticleGraph.options"
                    :series="salesByArticleGraph.segment_ex_vat_series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="
                  padding: 5px 1rem 1rem;
                  border-bottom: 1px solid #cbd4e199;
                  padding-bottom: 10px;
                "
              >
                <h2
                  style="margin: 10px 10px 5px 10px"
                  v-if="salesAmountCategory === 'most'"
                >
                  {{ $t("most_sold_articles") }}
                </h2>
                <h2 style="margin: 10px 10px 5px 10px" v-else>
                  {{ $t("least_sold_articles") }}
                </h2>
                <div class="desc">
                  <label>{{ $t("category") }}</label>
                  <div class="radio">
                    <input
                      v-model="salesAmountCategory"
                      type="radio"
                      value="most"
                      name="sales_amount_category"
                      id="most"
                    />
                    <label for="most">{{ $t("most") }}</label>
                    <input
                      v-model="salesAmountCategory"
                      type="radio"
                      value="least"
                      name="sales_amount_category"
                      id="least"
                    />
                    <label for="least">{{ $t("least") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showMostSoldArticlesInfo = !showMostSoldArticlesInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showMostSoldArticlesInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.mostSoldArticles.eng
                          : infoMessages.mostSoldArticles.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else-if="salesAmountCategory === 'most'"
                type="bar"
                :options="mostSoldArticlesGraph.options"
                :series="mostSoldArticlesGraph.most_series"
              ></apexchart>
              <apexchart
                v-else
                type="bar"
                :options="mostSoldArticlesGraph.options"
                :series="mostSoldArticlesGraph.least_series"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="
                  padding: 5px 1rem 1rem;
                  border-bottom: 1px solid #cbd4e199;
                  padding-bottom: 10px;
                "
              >
                <h2
                  style="margin: 10px 10px 5px 10px"
                  v-if="ratio_category === 'best'"
                >
                  {{ $t("best") + " " + $t("benefit_cost_ratio") }}
                </h2>
                <h2 style="margin: 10px 10px 5px 10px" v-else>
                  {{ $t("worst") + " " + $t("benefit_cost_ratio") }}
                </h2>
                <div class="desc">
                  <label>{{ $t("category") }}</label>
                  <div class="radio">
                    <input
                      v-model="ratio_category"
                      type="radio"
                      value="best"
                      name="ratio_category"
                      id="best"
                    />
                    <label for="best">{{ $t("best") }}</label>
                    <input
                      v-model="ratio_category"
                      type="radio"
                      value="worst"
                      name="ratio_category"
                      id="worst"
                    />
                    <label for="worst">{{ $t("worst") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showBenefitCostRatioInfo = !showBenefitCostRatioInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showBenefitCostRatioInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.benefitCostRatio.eng
                          : infoMessages.benefitCostRatio.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else-if="ratio_category === 'best'"
                type="bar"
                :options="benefitCostRatioGraph.options"
                :series="benefitCostRatioGraph.series_best"
              ></apexchart>
              <apexchart
                v-else
                type="bar"
                :options="benefitCostRatioGraph.options"
                :series="benefitCostRatioGraph.series_worst"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShimmerLoading from "../reusableComponents/ShimmerLoading";
import debounce from "lodash.debounce";

export default {
  name: "ContractStatistics",
  components: {
    ShimmerLoading,
  },

  async created() {
    this.getArticleEarningData();
    this.getBenefitCostRatioStatistics();
    this.getMostSoldArticlesStatistics();
  },

  computed: {
    ...mapGetters({
      locale: "locale/GET_LOCALE",
    }),
  },

  data() {
    return {
      showHighestEarningArticlesInfo: false,
      showSalesInfo: false,
      showMostSoldArticlesInfo: false,
      showBenefitCostRatioInfo: false,

      infoMessages: {
        highestEarningArticles: {},
        sales: {},
        mostSoldArticles: {},
        benefitCostRatio: {},
      },

      articleSalesCategory: "most",

      salesAmountCategory: "most",

      ratio_category: "best",

      chosenArticle: {},

      chosenSegment: {},

      loading: false,

      salesLoading: false,

      showArticleCategorySelector: false,

      showSalesCategorySelector: false,

      showArticleSalesCategorySelector: false,

      salesFor: "article",

      sales_vat_included: true,

      articlekey: "",

      segmentKey: "",

      showArticleSearch: false,

      ratioCategories: {},

      showSegmentSearch: false,

      articles: [],

      segments: [],

      articleCategory: "highest",

      salesCategories: {
        article_vat_categories: [],
        article_ex_vat_categories: [],
        segment_vat_categories: [],
        segment_ex_vat_categories: [],
      },

      ratio_categories: {},

      articleSalesCategories: {
        most: [],
        least: [],
      },

      articleCategories: {
        highest: [],
        lowest: [],
      },

      articleEarningGraph: {
        options: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "50%",
              borderRadius: 10,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            title: {
              text: this.$t("articles"),
            },
            categories: [],
          },
          yaxis: {
            title: {
              text: this.$t("earning") + "(SEK)",
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.3,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
        },
        highest_series: [
          {
            name: this.$t("profit"),
            data: [],
          },
        ],
        lowest_series: [
          {
            name: this.$t("profit"),
            data: [],
          },
        ],
      },

      salesByArticleGraph: {
        options: {
          chart: {
            id: "sales-by-article-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("months"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("sales"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          stroke: {
            width: 1.5,
          },

          markers: {
            size: 5,
          },

          colors: ["#FE3E7F"],

          dataLabels: {
            enabled: false,
          },
        },
        article_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        article_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        segment_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        segment_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],
      },

      mostSoldArticlesGraph: {
        options: {
          chart: {
            id: "most-sold-articles",
            type: "bar",
          },

          dataLabels: {
            enabled: false,
          },

          colors: ["#FE3E7F"],

          xaxis: {
            categories: [],
            title: {
              text: this.$t("articles"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("sales"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.3,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },

          plotOptions: {
            bar: {
              columnWidth: "55%",
              borderRadius: 10,
            },
          },
        },

        most_series: [
          {
            name: this.$t("delivered") + " " + this.$t("quantity"),
            data: [],
          },
        ],
        least_series: [
          {
            name: this.$t("delivered") + " " + this.$t("quantity"),
            data: [],
          },
        ],
      },

      benefitCostRatioGraph: {
        options: {
          chart: {
            id: "benefit-cost-ratio-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("article"),
            },
          },

          yaxis: {
            title: {
              text: `${this.$t("benefit")} / ${this.$t("cost")}`,
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
            },
          },
          colors: ["#3BFF9B"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
          },
          dataLabels: {
            enabled: false,
          },
        },
        series_best: [
          {
            name: this.$t("benefit_cost_ratio"),
            type: "bar",
            data: [],
          },
        ],

        series_worst: [
          {
            name: this.$t("benefit_cost_ratio"),
            type: "bar",
            data: [],
          },
        ],
      },
    };
  },

  mounted() {
    this.addFontAwesomeToHead();
  },

  methods: {
    searchArticle: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticles(event.target.value);
      } else {
        this.showArticleSearch = false;
      }
    }, 500),

    searchSegment: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSegments(event.target.value);
      } else {
        this.showSegmentSearch = false;
      }
    }, 500),

    filterArticles(q) {
      let payload = {
        URL: this.$services.endpoints.ARTICLE_ENDPOINT + `?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showArticleSearch = true;
          this.articles = res.data.data;
        })
        .catch((err) => {
          // left blank
        });
    },

    filterSegments(q) {
      let payload = {
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `articles?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showSegmentSearch = true;
          this.segments = res.data.results;
        })
        .catch((err) => {
          // left blank
        });
    },

    async getBenefitCostRatioStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getArticleBenefitCostRatioStatistics"
      );
      this.loading = false;
      this.infoMessages.benefitCostRatio.eng = res.data.eng_info;
      this.infoMessages.benefitCostRatio.swe = res.data.swe_info;
      const data = res.data.data;

      const categories_best = [];
      const categories_worst = [];
      const series_best = [];
      const series_worst = [];

      for (const key in data) {
        data[key].forEach((el) => {
          if (key === "highest") {
            categories_best.push(el.article_number);
            series_best.push(el.benefit_cost);
          } else {
            categories_worst.push(el.article_number);
            series_worst.push(el.benefit_cost);
          }
        });
      }

      this.benefitCostRatioGraph.options = {
        ...this.benefitCostRatioGraph.options,
        xaxis: {
          ...this.benefitCostRatioGraph.options.xaxis,
          type: "category",
          categories: categories_best,
        },
      };
      this.benefitCostRatioGraph.series_best[0].data = series_best;
      this.benefitCostRatioGraph.series_worst[0].data = series_worst;
      this.ratioCategories.best = categories_best;
      this.ratioCategories.worst = categories_worst;
    },

    chooseSalesCategory(salesCategory) {
      this.showSalesCategorySelector = false;
      this.salesFor = salesCategory;
      if (salesCategory === "article") {
        this.chooseArticle(this.chosenArticle);
      } else {
        this.chooseSegment(this.chosenSegment);
      }
    },

    chooseArticleSalesCategory(category) {
      this.showArticleSalesCategorySelector = false;
      this.articleSalesCategory = category;
    },

    chooseArticle(article) {
      if (Object.keys(article).length == 0) {
        return;
      }

      this.showArticleSearch = false;
      this.salesLoading = true;
      this.articleKey = article.description;
      const payload = {
        type: "article",
        article_number: article.article_number,
      };
      this.chosenArticle = article;
      this.$store
        .dispatch("clients/getArticleSalesStatistics", payload)
        .then((res) => {
          this.infoMessages.sales.eng = res.data.eng_info;
          this.infoMessages.sales.swe = res.data.swe_info;
          this.getSalesStatistics("article", res.data.data);
          this.salesLoading = false;
        })
        .catch((err) => {
          this.salesLoading = false;
        });
    },

    chooseSegment(segment) {
      // check if segment object has no keys
      if (Object.keys(segment).length === 0) {
        return;
      }

      this.showSegmentSearch = false;
      this.salesLoading = true;
      this.segmentKey = segment.name;
      const payload = {
        type: "segment",
        segment_id: segment.id,
      };
      this.chosenSegment = segment;
      this.$store
        .dispatch("clients/getArticleSalesStatistics", payload)
        .then((res) => {
          this.infoMessages.sales.eng = res.data.eng_info;
          this.infoMessages.sales.swe = res.data.swe_info;
          this.getSalesStatistics("segment", res.data.data);
          this.salesLoading = false;
        })
        .catch((err) => {
          this.salesLoading = false;
        });
    },

    chooseArticleCategory(category) {
      this.articleCategory = category;
      this.showArticleCategorySelector = false;
    },

    async getArticleEarningData() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getArticleEarningStatistics"
      );
      this.loading = false;
      this.infoMessages.highestEarningArticles.eng = res.data.eng_info;
      this.infoMessages.highestEarningArticles.swe = res.data.swe_info;
      const data = res.data.data;

      let lowest_series = [];
      let highest_series = [];
      let highest_categories = [];
      let lowest_categories = [];

      for (const key in data) {
        if (key == "highest") {
          highest_series = data[key].map((item) => {
            return item.profit;
          });
          highest_categories = data[key].map((item) => {
            return item.article_number;
          });
        } else if (key == "lowest") {
          lowest_series = data[key].map((item) => {
            return item.profit;
          });
          lowest_categories = data[key].map((item) => {
            return item.article_number;
          });
        }
      }

      this.articleCategories = {
        highest: highest_categories,
        lowest: lowest_categories,
      };

      this.articleEarningGraph.options = {
        ...this.articleEarningGraph.options,
        xaxis: {
          ...this.articleEarningGraph.options.xaxis,
          categories: highest_categories,
        },
      };

      this.articleEarningGraph.highest_series[0].data = highest_series;

      this.articleEarningGraph.lowest_series[0].data = lowest_series;
    },

    async getSalesStatistics(type, data) {
      const article_ex_vat_categories = [];
      const article_ex_vat_series = [];

      const article_vat_categories = [];
      const article_vat_series = [];

      const segment_ex_vat_categories = [];
      const segment_ex_vat_series = [];

      const segment_vat_categories = [];
      const segment_vat_series = [];

      if (type == "article") {
        if (typeof data !== "object") {
          this.$services.helpers.notification(
            "No prior sales for this article",
            "error"
          );
          return;
        }
        const articleData = {
          ...data.article_number[Object.keys(data.article_number)[0]],
        };
        for (const key in articleData) {
          article_vat_categories.push(key);
          article_vat_series.push(articleData[key].total_amount_inc_vat);
          article_ex_vat_categories.push(key);
          article_ex_vat_series.push(articleData[key].total_amount_ex_vat);
        }

        this.salesCategories.article_vat_categories = article_vat_categories;
        this.salesCategories.article_ex_vat_categories =
          article_ex_vat_categories;

        this.salesByArticleGraph.options = {
          ...this.salesByArticleGraph.options,
          xaxis: {
            ...this.salesByArticleGraph.options.xaxis,
            type: "category",
            categories: article_vat_categories,
          },
        };

        this.salesByArticleGraph.article_vat_series[0].data =
          article_vat_series;
        this.salesByArticleGraph.article_ex_vat_series[0].data =
          article_ex_vat_series;
      } else {
        const segmentData = { ...data.article_segment };
        for (const key in segmentData) {
          segment_vat_categories.push(key);
          segment_ex_vat_categories.push(key);
          segment_vat_series.push(
            this.fixDecimals(parseFloat(segmentData[key].total_inc_vat))
          );
          segment_ex_vat_series.push(
            this.fixDecimals(parseFloat(segmentData[key].total_ex_vat))
          );
        }

        this.salesCategories.segment_vat_categories = segment_vat_categories;
        this.salesCategories.segment_ex_vat_categories =
          segment_ex_vat_categories;

        this.salesByArticleGraph.options = {
          ...this.salesByArticleGraph.options,
          xaxis: {
            ...this.salesByArticleGraph.options.xaxis,
            type: "category",
            categories: segment_vat_categories,
          },
        };
        this.salesByArticleGraph.segment_vat_series[0].data =
          segment_vat_series;
        this.salesByArticleGraph.segment_ex_vat_series[0].data =
          segment_ex_vat_series;
      }
    },

    async getMostSoldArticlesStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getMostSoldArticlesStatistics"
      );
      this.loading = false;
      this.infoMessages.mostSoldArticles.eng = res.data.eng_info;
      this.infoMessages.mostSoldArticles.swe = res.data.swe_info;
      const data = res.data.data;
      let most_sold_categories = [];
      let least_sold_categories = [];
      let most_sold_series = [];
      let least_sold_series = [];

      for (const key in data) {
        if (key == "most") {
          most_sold_categories = data[key].map((item) => {
            return item.article_number;
          });
          most_sold_series = data[key].map((item) => {
            return item.delivered_quantity;
          });
        } else if (key == "least") {
          least_sold_categories = data[key].map((item) => {
            return item.article_number;
          });
          least_sold_series = data[key].map((item) => {
            return item.delivered_quantity;
          });
        }
      }

      this.articleSalesCategories = {
        most: most_sold_categories,
        least: least_sold_categories,
      };

      this.mostSoldArticlesGraph.options = {
        ...this.mostSoldArticlesGraph.options,
        xaxis: {
          ...this.mostSoldArticlesGraph.options.xaxis,
          categories: most_sold_categories,
        },
      };

      this.mostSoldArticlesGraph.most_series[0].data = most_sold_series;
      this.mostSoldArticlesGraph.least_series[0].data = least_sold_series;
    },

    fixDecimals(value) {
      return value.toFixed(2);
    },

    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },
  },

  watch: {
    articleCategory(val) {
      if (val == "highest") {
        this.articleEarningGraph.options.xaxis = {
          ...this.articleEarningGraph.options.xaxis,
          categories: this.articleCategories.highest,
        };
      } else if (val == "lowest") {
        this.articleEarningGraph.options.xaxis = {
          ...this.articleEarningGraph.options.xaxis,
          categories: this.articleCategories.lowest,
        };
      }
    },

    ratio_category(val) {
      if (val == "best") {
        this.benefitCostRatioGraph.options = {
          ...this.benefitCostRatioGraph.options,
          xaxis: {
            ...this.benefitCostRatioGraph.options.xaxis,
            type: "category",
            categories: this.ratioCategories.best,
          },
        };
      } else {
        this.benefitCostRatioGraph.options = {
          ...this.benefitCostRatioGraph.options,
          xaxis: {
            ...this.benefitCostRatioGraph.options.xaxis,
            type: "category",
            categories: this.ratioCategories.worst,
          },
        };
      }
    },

    salesAmountCategory(val) {
      if (val == "most") {
        this.mostSoldArticlesGraph.options = {
          ...this.mostSoldArticlesGraph.options,
          xaxis: {
            ...this.mostSoldArticlesGraph.options.xaxis,
            type: "category",
            categories: this.articleSalesCategories.most,
          },
        };
      } else if (val == "least") {
        this.mostSoldArticlesGraph.options = {
          ...this.mostSoldArticlesGraph.options,
          xaxis: {
            ...this.mostSoldArticlesGraph.options.xaxis,
            type: "category",
            categories: this.articleSalesCategories.least,
          },
        };
      }
    },

    articleSalesCategory(val) {
      if (val == "most") {
        this.mostSoldArticlesGraph.options.xaxis = {
          ...this.mostSoldArticlesGraph.options.xaxis,
          categories: this.articleSalesCategories.most,
        };
      } else {
        this.mostSoldArticlesGraph.options.xaxis = {
          ...this.mostSoldArticlesGraph.options.xaxis,
          categories: this.articleSalesCategories.least,
        };
      }
    },

    sales_vat_included(val) {
      if (val) {
        this.salesByArticleGraph.options = {
          ...this.salesByArticleGraph.options,
          xaxis: {
            ...this.salesByArticleGraph.options.xaxis,
            type: "category",
            categories:
              this.salesFor == "article"
                ? this.salesCategories.article_vat_categories
                : this.salesCategories.segment_vat_categories,
          },
        };
      } else {
        this.salesByArticleGraph.options = {
          ...this.salesByArticleGraph.options,
          xaxis: {
            ...this.salesByArticleGraph.options.xaxis,
            type: "category",
            categories:
              this.salesFor == "article"
                ? this.salesCategories.article_ex_vat_categories
                : this.salesCategories.segment_ex_vat_categories,
          },
        };
      }
    },
  },
};
</script>

<style scoped>
.desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;
  /* line-height: 14px; */
  color: #000000;
  width: min-content;
}

.desc label {
  margin-bottom: 2px;
  font-size: 11px;
}

.desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.desc .radio input {
  display: none;
}

.desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.desc .radio label:first-of-type {
  margin-right: 10px;
}

.box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
}

.box h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e1566;
  margin-bottom: 0;
}

.box h6 {
  font-weight: 400;
  font-size: 14px;
  color: #0e1566;
}

.amount-dropdown {
  right: 0;
}
#sales-for-selector > .amount-dropdown {
  right: auto;
  left: 0;
}
.amount-dropdown .text-xs {
  font-size: 11px !important;
  line-height: 120%;
  padding: 2px 0;
}
.amount-dropdown ul {
  margin-bottom: 0 !important;
}
.amount-dropdown ul li:last-child {
  border: 0 !important;
}

input.filter-input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}
</style>

<template>
  <div class="box invoice">
    <h3>{{ $t("invoice_rows") }}</h3>
    <div class="table-responsive2" style="overflow-x: auto;">
      <table>
        <thead>
        <tr>
          <th scope="col" class="article-number">{{ $t("article_number") }}
            <i
              class="bx bx-chevron-up bx-xs el-clickable"
              v-if="allowSorting"
              v-on:click="sortRows('article_number', 'asc')"
            ></i>
            <i
                class="bx bx-chevron-down bx-xs el-clickable"
                v-if="allowSorting"
                v-on:click="sortRows('article_number', 'desc')"
            ></i>
          </th>
          <th colspan="2" scope="col" class="description">{{ $t("description") }}
            <i
              class="bx bx-chevron-up bx-xs el-clickable"
              v-if="allowSorting"
              v-on:click="sortRows('description', 'asc')"
            ></i>
            <i
                class="bx bx-chevron-down bx-xs el-clickable"
                v-if="allowSorting"
                v-on:click="sortRows('description', 'desc')"
            ></i>
          </th>
          <th scope="col">
            {{ $services.helpers.capitalizeNames($t("quantity")) }}
            <i
              class="bx bx-chevron-up bx-xs el-clickable"
              v-if="allowSorting"
              v-on:click="sortRows('delivered_quantity', 'asc')"
            ></i>
            <i
                class="bx bx-chevron-down bx-xs el-clickable"
                v-if="allowSorting"
                v-on:click="sortRows('delivered_quantity', 'desc')"
            ></i>
          </th>
          <th scope="col">{{ $t("unit") }}</th>
          <th scope="col">{{ $t("price_per_unit") }}
            <i
              class="bx bx-chevron-up bx-xs el-clickable"
              v-if="allowSorting"
              v-on:click="sortRows('price', 'asc')"
            ></i>
            <i
                class="bx bx-chevron-down bx-xs el-clickable"
                v-if="allowSorting"
                v-on:click="sortRows('price', 'desc')"
            ></i>
          </th>
          <th scope="col">{{ $t("discount") }}
          </th>
          <th scope="col">
            {{ $services.helpers.capitalizeNames($t("total")) }}
            <i
              class="bx bx-chevron-up bx-xs el-clickable"
              v-if="allowSorting"
              v-on:click="sortRows('total', 'asc')"
            ></i>
            <i
                class="bx bx-chevron-down bx-xs el-clickable"
                v-if="allowSorting"
                v-on:click="sortRows('total', 'desc')"
            ></i>
          </th>
          <th scope="col">{{ $t("cost_center") }}</th>
          <th scope="col">{{ $t("project") }}</th>
          <th scope="col">{{ $t("vat") }}</th>
          <th scope="col">{{ $t("account") }}</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <invoice-row-form
            :vat_included="vat_included"
            :index="i"
            :help-text="helpText"
            :show-schedule="showSchedule"
            :show-contract-update="showContractUpdate"
            @onRowChange="rowChange"
            @removeRow="removeRow"
            @event="event"
            v-for="(item, i) in local_invoice_rows"
            :data="item"
            :key="i"
            :booked="booked"
            :cancelled="cancelled"
            :fully_paid="fully_paid"
        />

        <tr>
          <td>
            <input
                @input="searchArticleField"
                class="mid-2"
                type="text"
                style="width: 100%"
                v-model="row.article_number"
                :disabled="cancelled || booked"
            />
            <div
                v-if="showArticle"
                style="z-index: 9999; width: 150px"
                class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
            >
              <ul
                  style="padding-left: 0.3rem !important; list-style-type: none"
              >
                <li
                    class="border-bottom mb-1 cursor-pointer text-xs"
                    v-for="item in searchArticle.slice(0, 5)"
                    :key="item.article_number"
                    @click="chooseArticle(item)"
                >
                  {{ item.article_number }} - {{ item.description }}
                </li>
              </ul>
            </div>
          </td>
          <td colspan="2">
            <input
                class="big description"
                type="text"
                style="width: 100%"
                v-model="row.description"
                :disabled="cancelled || booked"
            />
          </td>
          <td>
            <input
                class="mid"
                min="1"
                type="number"
                v-model="row.delivered_quantity"
                @input="rowChange"
                :disabled="cancelled || booked"
            />
          </td>
          <td>
            <!-- <input class="small" type="text" v-model="row.unit"> -->
            <select
                class="mid col-form"
                v-model="row.unit"
                :disabled="cancelled || booked"
            >
              <option value=""></option>
              <option :value="item.code" v-for="item in units" :key="item.id">
                {{ GET_LOCALE === "se" ? item.code : item.code_english }}
              </option>
            </select>
          </td>
          <td>
            <input
                v-if="vat_included"
                class="small col-form"
                type="text"
                v-model="row.price"
                :disabled="cancelled || booked"
                @change="rowChange"
            />
            <input
                v-else
                class="small col-form"
                type="text"
                v-model="row.price_excluding_vat"
                :disabled="cancelled || booked"
                @change="rowChange"
            />
          </td>
          <td>
            <input
                class="mid"
                type="text"
                v-model="row.discount"
                :disabled="cancelled || booked"
                @input="rowChange"
            />
          </td>
          <td>
            <input
                v-if="vat_included"
                class="small col-form"
                type="text"
                :value="row.total"
                :disabled="cancelled || booked"
            />
            <input
                v-else
                class="small col-form"
                type="text"
                :value="row.total_excluding_vat"
                :disabled="cancelled || booked"
            />
          </td>
          <td>
            <!-- <input class="small" type="text" v-model="row.cost_center"> -->
            <select
                class="mid col-form"
                v-model="row.cost_center"
                :disabled="cancelled || booked"
            >
              <option value=""></option>
              <option
                  :value="item.code"
                  v-for="item in costCenter"
                  :key="item.id"
              >
                {{ item.description }}
              </option>
            </select>
          </td>
          <td>
            <select
                class="big input-select col-form"
                v-model="row.project"
                style="width: 64px"
                :disabled="cancelled || booked"
            >
              <option value=""></option>
              <option
                  :value="item.project_number"
                  v-for="item in project"
                  :key="item.id"
              >
                {{ item.description }}
              </option>
            </select>
          </td>
          <td>
            <input
                class="mid"
                min="1"
                type="number"
                v-model="row.vat"
                @input="rowChange"
                :disabled="cancelled || booked"
            />
          </td>
          <td>
            <!-- <input class="mid" type="text" v-model="row.account_number"> -->
            <input
                @input="searchAccountField"
                class="mid col-form"
                type="text"
                v-model="row.account_number"
                :disabled="cancelled || booked"
            />
            <div
                v-if="showAccount"
                style="z-index: 9999; width: 150px"
                class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
            >
              <ul
                  style="padding-left: 0.3rem !important; list-style-type: none"
              >
                <li
                    class="border-bottom mb-1 cursor-pointer text-xs"
                    v-for="item in accounts.slice(0, 5)"
                    :key="item.number"
                    @click="chooseAccount(item)"
                >
                  {{ item.number }} - {{ item.description }}
                </li>
              </ul>
            </div>
          </td>
          <!-- <td>
          <span @click="addContractInvoiceRow">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
            </svg>
          </span>
        </td> -->
        </tr>
        </tbody>
      </table>
    </div>
    <button
        type="submit"
        @click="addContractInvoiceRow"
        :disabled="cancelled || booked"
    >
      {{ $t("add_invoice_row") }}
    </button>
  </div>
</template>

<script>
import {contractRow} from "../composables/contract.create";
import InvoiceRowForm from "./InvoiceRowForm";
import debounce from "lodash.debounce";
import {mapGetters} from "vuex";

export default {
  name: "CustomInvoiceRowComponent",
  components: {InvoiceRowForm},
  props: {
    invoice_rows: {
      type: Array,
      required: true,
    },
    helpText: {
      type: Array,
      required: true,
    },
    invoice_country: {
      type: String,
      required: true,
    },
    booked: {
      type: Boolean,
      default: false,
      required: false,
    },
    cancelled: {
      type: Boolean,
      default: false,
      required: false,
    },
    vat_included: {
      type: Boolean,
      default: false,
      required: false,
    },
    fully_paid: {
      type: Boolean,
      default: false,
      required: false,
    },
    submitForm: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSchedule: {
      type: Boolean,
      default: true,
      required: false
    },
    showContractUpdate: {
      type: Boolean,
      default: false,
      required: false,
    },
    allowSorting: {
      type: Boolean,
      default: false,
      required: false,
    },

  },
  data: function () {
    return {
      costCenter: [],
      project: [],
      units: [],
      local_invoice_rows: [],
      row: {...contractRow},
      showAccount: false,
      showArticle: false,
      accounts: [],
      searchArticle: [],
      isNewInvoiceRowComplete: false,
    };
  },
  watch: {
    submitForm: function (n, o) {
      if (this.submitForm === false) return;
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (
          this.row.description === "" &&
          (this.row.price || this.row.delivered_quantity > 1)
      ) {
        // this.$toast.warning(
        //     "Invoice row was not properly filled. New invoice row will not be saved!"
        // );
      }
      // else if (!this.row.article_number && !this.row.account_number)
      if (this.row.description) {
        this.local_invoice_rows.push(JSON.parse(JSON.stringify(this.row)));
      }
      this.setDefaultRows();
      this.$emit("updateContract");
    },
  },
  methods: {
    sortRows(column, order) {
      if (this.allowSorting) {
        console.log(column)
        console.log(order)
        this.$emit("sortRows", column, order);
      }
    },
    getDiscountedTotal(total, discount, discountType) {
      if (discount) {
        if (discountType === "AMOUNT") {
          total = total - discount
        } else {
          total = total *
          (
              1 -
              parseFloat(
                  this.$services.helpers.removePercentage(discount) / 100
              )
          )
        }
      }
      return parseFloat(total.toFixed(2));
    },
    addContractInvoiceRow() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (!this.row.account_number && this.row.price) {
        // return this.$services.helpers.notification('Account number is required', 'error', this)
        return this.$toast.warning(this.$t("select_account_number"));
      }
      if (this.row.article_number === "") {
        if (this.row.description === "") {
          // return this.$services.helpers.notification('Price and Description is required for this invoice row', 'error', this)
          return this.$toast.warning(this.$t("description_article_required"));
        }
      }
      this.row.newRow = true;
      this.local_invoice_rows.push(JSON.parse(JSON.stringify(this.row)));
      this.setDefaultRows();
      this.$emit("calculateRow", {});
    },
    setDefaultRows() {
      this.row = {...contractRow};
    },
    searchField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSearch(event.target.value);
      } else {
        this.showCustomer = false;
      }
    }, 500),
    filterSearch(data) {
      const payload = {
        URL: this.$services.endpoints.CUSTOMER_ENDPOINT + `?search=${data}`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showCustomer = true;
              this.searchCustomer = res.data.data;
            }
          })
          .catch((err) => {
          });
    },
    searchArticleField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticleSearch(event.target.value);
      } else {
        this.showArticle = false;
      }
    }, 500),
    searchAccountField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterAccountSearch(event.target.value);
      } else {
        this.showAccount = false;
      }
    }, 500),
    filterArticleSearch(data) {
      const payload = {
        URL:
            this.$services.endpoints.ARTICLE_ENDPOINT +
            `?search=${data}&customize=no&active=1`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showArticle = true;
              this.searchArticle = res.data.data.filter(
                  (item) => item.active === true
              );
            }
          })
          .catch((err) => {
          });
    },
    filterAccountSearch(data) {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.showAccount = true;
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    chooseArticle(item) {
      if (!item.active) {
        this.$toast.warning(
            "This article is not active. Please select another article."
        );
        return;
      }
      this.row.article_number = item.article_number;
      this.row.description = item.description;
      this.row.vat = item.vat;

      // array of all countries in the European Union
      const EUCountries = [
        "AT",
        "BE",
        "BG",
        "CY",
        "CZ",
        "DE",
        "DK",
        "EE",
        "ES",
        "FI",
        "FR",
        "GB",
        "GR",
        "HU",
        "HR",
        "IE",
        "IT",
        "LT",
        "LU",
        "LV",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SI",
        "SK",
      ];

      if (this.invoice_country == "SE") {
        this.row.account_number = item.sales_account;
      } else if (EUCountries.includes(this.invoice_country)) {
        this.row.account_number = item.euvat_account;
      } else {
        this.row.account_number = item.export_account;
      }

      // HERE WE ARE ASSUMING THAT THE SALES_PRICE (price in Fortnox) IS EXCL. VAT, HENCE ADDING VAT
      if (item.vat) {
        this.row.price = (item.sales_price == null) ? 0 : item.sales_price * (1 + item.vat / 100);
      } else {
        this.row.price = (item.sales_price == null) ? 0 : item.sales_price;
      }
      this.row.price_excluding_vat = (item.sales_price == null) ? 0 : item.sales_price;
      
      // HERE WE ARE ASSUMING THAT THE SALES_PRICE IS INCLUDING VAT HENCE REMOVING VAT
      // this.row.price = item.sales_price;
      // this.row.price_excluding_vat = item.sales_price / (1 + item.vat / 100);
      
      if (item.sales_price !== null && this.row.delivered_quantity !== null) {
        let total = this.row.price * this.row.delivered_quantity;
        let total_excluding_vat = item.vat ? total / (1 + item.vat / 100) : total;

        if (!isNaN(total) && !isNaN(total_excluding_vat)) {
          this.row.total = total;
          this.row.total_excluding_vat = total_excluding_vat;
        } else {
          this.row.total = 0;
          this.row.total_excluding_vat = 0;
        }
      }
      this.row.cost_center = item.cost_center;
      this.row.project = item.project;
      this.row.unit = item.unit;

      this.$emit("calculateRow", {});
      // this.reCalculateValues();
      this.showArticle = false;
    },

    chooseAccount(item) {
      if (!item.active) {
        this.$toast.warning(
            "This account is not active. Please select another account."
        );
        return;
      }
      this.row.account_number = item.number;
      this.showAccount = false;

      // this.reCalculateValues();
    },
    removeRow(item) {
      // this.local_invoice_rows = this.local_invoice_rows.filter(element =>{
      //   return  element.id !== item.id
      // })
      // // this.local_invoice_rows.splice(item, 1)
      this.$emit("removeRow", item);
      this.$emit("calculateRow", {});
    },
    event(item) {
      // left blank
    },

    rowChange: debounce(function () {
      if (
          this.row.delivered_quantity !== "" &&
          this.row.delivered_quantity !== null &&
          this.row.price_excluding_vat !== "" &&
          this.row.price_excluding_vat !== null &&
          this.row.price !== "" &&
          this.row.price !== null
      ) {
        if (this.vat_included) {
          this.row.total = (
              parseFloat(this.row.delivered_quantity) * parseFloat(this.row.price)
          );
          this.row.total = this.getDiscountedTotal(this.row.total, this.row.discount, this.row.discount_type);
          if (this.row.vat) {
            this.row.total_excluding_vat = (
              this.row.total /
              (1 + parseFloat(this.row.vat) / 100)
          );
          } else {
            this.row.total_excluding_vat = this.row.total;
          }
          this.row.total_excluding_vat = parseFloat(this.row.total_excluding_vat.toFixed(2));
          
          // price_per_unit is including VAT
          if (this.row.vat) {
            this.row.price_excluding_vat = (
              parseFloat(this.row.price) /
              parseFloat(1 + parseFloat(this.row.vat) / 100)
          );
          } else {
            this.row.price_excluding_vat = parseFloat(this.row.price); 
          }
          this.row.price_excluding_vat = parseFloat(this.row.price_excluding_vat.toFixed(2));

          this.row.price = parseFloat(this.row.price).toFixed(2);
        } else {
          this.row.total_excluding_vat = (
              parseFloat(this.row.delivered_quantity) * parseFloat(this.row.price_excluding_vat)
          );
          this.row.total_excluding_vat = this.getDiscountedTotal(this.row.total_excluding_vat, this.row.discount, this.row.discount_type);
          if (this.row.vat) {
            this.row.total = (
              this.row.total_excluding_vat *
              (1 + parseFloat(this.row.vat) / 100)
          );
          } else {
            this.row.total = this.row.total_excluding_vat.toFixed(2);
          }
          this.row.total = parseFloat(this.row.total.toFixed(2));
          
          if (this.row.vat) {
            this.row.price = (
              parseFloat(this.row.price_excluding_vat) *
              parseFloat(1 + this.row.vat / 100).toFixed(2)
          );
          } else {
            this.row.price = parseFloat(this.row.price_excluding_vat);
          }
          this.row.price = parseFloat(this.row.price.toFixed(2));
          

          this.row.price_excluding_vat = parseFloat(
              this.row.price_excluding_vat
          ).toFixed(2);
        }
        this.row.price_per_unit = this.row.price_excluding_vat;
      } else {
        this.row.delivered_quantity = 0;
        this.row.price_excluding_vat = 0;
        this.row.price = 0;
        this.row.total = 0;
        this.row.total_excluding_vat = 0;
      }
      this.$emit("calculateRow", {});
    }),
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "costcenters";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.costCenter = res.data.results;
          })
          .catch((err) => {
          });
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "projects";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.project = res.data.results;
          })
          .catch((err) => {
          });
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.units = res.data.results;
          })
          .catch((err) => {
          });
    },
    computeRowOnInput: debounce(function () {
      this.$emit("computeRowOnInput", this.row);
    }),
  },

  mounted() {
    this.local_invoice_rows = this.invoice_rows;

    this.getCostCenter();
    this.getUnit();
    this.getProjects();
    let self = this;
    setTimeout(function () {
      self.local_invoice_rows = self.invoice_rows;
    }, 1000);
  },

  computed: {
    ...mapGetters({
      CURRENT_USER: "clients/GET_CURRENT_USER",
      GET_VAT_CONSTANT: "clients/GET_VAT_CONSTANT",
      GET_LOCALE: "locale/GET_LOCALE",
      SINGLE_CONTRACT: "clients/GET_SINGLE_CONTRACT",
    }),

    // currentPath() {
    //   return this.$route.name;
    // },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .contract-duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
}

.content .contract-duration h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .contract-duration .duration p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.contract-details img {
  width: 55px;
  height: 55px;
  margin-left: auto;
  cursor: pointer;
}

.content .box.invoice h3 {
  background-image: url("./../../../assets/images/table/invoice-row.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.others-details h3 {
  background-image: url("./../../../assets/images/table/others.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

@media (min-width: 992px) {
  .content .box form > div:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .box form .desc label {
  margin-bottom: 10px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="date"] {
  text-indent: 5px;
}

.content .box form .desc input[type="date"]::-webkit-clear-button,
.content .box form .desc input[type="date"]::-webkit-inner-spin-button,
.content .box form .desc input[type="date"]::-webkit-calendar-picker-indicator,
.content .box form .desc input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 95px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .content .box.contract-details form > div:first-of-type {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .content .box.customer-details .desc {
    margin-right: 5%;
  }
}

.content .box table {
  width: 100%;
  margin: 0 auto 10px;
  background: #fff;
  min-width: 945px;
}

.content .box table thead th {
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding: 20px 15px;
  text-align: center;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td select.mid,
.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td input.small {
  width: 31px;
}

.content .box table tbody tr td:not(:last-of-type):after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box table tbody tr td:nth-of-type(1) input,
.content .box table tbody tr td:nth-of-type(2) input {
  margin-left: 5px;
  text-align: left;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.content .box textarea:focus {
  border: none;
  outline: none;
}

.content .box.others-details {
  min-height: 320px;
}

.content .box.price {
  padding: 30px 20px;
}

.content .box.price ul {
  margin: 0;
}

.content .box.price ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box.price ul li .border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 10px;
  margin-top: 2px;
}

.content .box.price ul li:not(:first-of-type) {
  margin-top: 10px;
}

.content .box.price ul li:last-of-type {
  margin-top: 0;
}

.content .box.price ul li:last-of-type span:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box.price ul li:last-of-type span:last-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:first-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:last-of-type {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  background-color: #de4343;
  color: #fff;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.col-form {
  background: #e6e5eb;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
  width: 100px !important;
}

.mid-2 {
  width: 100px;
}

.content .box .table-responsive2 table {
  min-width: auto;
}

.content .box table thead th:last-of-type {
  min-width: 40px;
}

.amount-dropdown {
  right: 0;
}

.amount-dropdown .text-xs {
  font-size: 11px !important;
  line-height: 120%;
  padding: 2px 0;
}

.amount-dropdown ul {
  margin-bottom: 0 !important;
}

.amount-dropdown ul li:last-child {
  border: 0 !important;
}
.article-number {
  min-width: 110px; /* Adjust as needed */
}

.description {
  min-width: 350px; /* Adjust as needed to make it the longest */
}
</style>

<template>
  <td class="text-center">
    <div class="checkbo">
      <input type="checkbox" v-model="props.data.checked" :value="props.data.checked" @change="checkboxOrder"
             class="form-check-input">
    </div>
  </td>
  <td v-for="(item, i) in head" :key="i">
    <router-link class="unset-pad"
                 :to="{ name : 'editCustomers', params : { id : props.data.customer_number ? props.data.customer_number : 1 } }">
      <p v-if="badge.includes(item)">
        <span class="badge badge-success" v-if="data[item] === true"> {{ $t('active_resources') }}</span>
        <span class="badge badge-warning" v-else> {{ $t('inactive_resources') }}</span>
      </p>
      <p v-else-if="['tags'].includes(item)" :class="props.data.active ? 'text-green' : 'text-yellow'">{{
          $services.helpers.concatWord(data[item])
        }}</p>
      <p v-else :class="props.data.active ? 'text-green' : 'text-yellow'">{{
          $services.helpers.parseContent(data[item])
        }}</p>
    </router-link>
  </td>
  <td>
    <div v-if="loading === false">
      <button type="button" title="Deactivate customer" @click="openCustomerActivity(false)"
              v-if="props.data.active === true">
        <svg width="8" height="15" viewbox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.777303 0.612305C0.97996 0.612305 1.17432 0.69281 1.31762 0.83611C1.46092 0.97941 1.54142 1.17377 1.54142 1.37642V13.6023C1.54142 13.805 1.46092 13.9993 1.31762 14.1426C1.17432 14.2859 0.97996 14.3664 0.777303 14.3664C0.574646 14.3664 0.380289 14.2859 0.236989 14.1426C0.0936889 13.9993 0.0131836 13.805 0.0131836 13.6023V1.37642C0.0131836 1.17377 0.0936889 0.97941 0.236989 0.83611C0.380289 0.69281 0.574646 0.612305 0.777303 0.612305ZM6.89026 0.612305C7.09291 0.612305 7.28727 0.69281 7.43057 0.83611C7.57387 0.97941 7.65438 1.17377 7.65438 1.37642V13.6023C7.65438 13.805 7.57387 13.9993 7.43057 14.1426C7.28727 14.2859 7.09291 14.3664 6.89026 14.3664C6.6876 14.3664 6.49324 14.2859 6.34994 14.1426C6.20664 13.9993 6.12614 13.805 6.12614 13.6023V1.37642C6.12614 1.17377 6.20664 0.97941 6.34994 0.83611C6.49324 0.69281 6.6876 0.612305 6.89026 0.612305Z"
              fill="#5855D6"></path>
        </svg>
      </button>
      <button type="button" title="Activate customer" class="el-pointer" @click="openCustomerActivity(true)"
              v-if="props.data.active === false">
        <img src="../../../assets/images/icons-clickable/play.png" width="15" height="15" class="el-pointer"/>
      </button>
      <button type="button" title="apply tags" @click="openTag">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path :fill="props.data.tags !== null && props.data.tags.length ? '#13b010' : '#5855d6'" d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z" />
        </svg>
      </button>
    </div>
    <div v-else>
      <icon_loading/>
    </div>
  </td>
</template>

<script>
import {useStore, mapGetters} from 'vuex'
import icon_loading from "../../../components/icon_loading";

export default {
  props: {
    data: Object,
    head: Array,
  },
  components: {icon_loading},
  emits: ['openActivity', 'openTag'],
  setup(props, {emit}) {
    const store = useStore()

    const checkboxOrder = (e) => store.commit('clients/TOGGLE_CUSTOMER_CHECKBOX', {
      id: props.data.id,
      value: props.data.checked
    })
    // const openCustomerActivity = () => {
    //   // store.commit('clients/TOGGLE_CUSTOMER_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
    //   emit('openActivity', props.data)
    // }
    const openCustomerTag = () => {
      // store.commit('clients/TOGGLE_CUSTOMER_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      emit('openTag', props.data)
    }


    return {
      props,
      openCustomerTag,
      checkboxOrder
    }
  },
  data: function () {
    return {
      loading: false,
      badge: ['active']
    }
  },
  methods: {
    openCustomerActivity(status) {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      let payload = {
        'active': status
      }
      let data = this.data
      this.loading = true
      this.$store.dispatch('clients/updateCustomerInfo', {id: data.customer_number, data: payload})
          .then(_ => {
            this.loading = false
            data.active = status
            // this.$services.helpers.notification('Updated customers information', 'success', this)
            this.$toast.success(this.$t('updated_customer_info'));
          })
          .catch(err => {
            data.active = !status
            this.loading = false
            if (err.response.status === 403) {
              this.$services.helpers.notification(err.response.data, 'error', this)
            } else {
               try {
                if ([400, 406].includes(err.response.status)) {
                  if ('error' in err.response.data) {
                    if (Array.isArray(err.response.data.error)) {
                      this.$services.helpers.notification(err.response.data.error.join(','), 'error', this)
                    } else if (typeof err.response.data.error === 'object') {
                      for (const [key, value] of Object.entries(err.response.data.error)) {
                        if (Array.isArray(value)) {
                          this.$services.helpers.notification(key.replace('_', ' ') + ': ' + value.join(','), 'error', this)
                        } else {
                          this.$services.helpers.notification(value, 'error', this)
                        }
                        return false
                      }
                    } else {
                      this.$services.helpers.notification(err.response.data.error, 'error', this)
                    }
                  } else if ('message' in err.response.data) {
                    if (Array.isArray(err.response.data.message)) {
                      this.$services.helpers.notification(err.response.data.message.join(''), 'error', this)
                    } else if (typeof err.response.data.message === 'object') {
                      if ('message' in err.response.data.message) {
                        if ('ErrorInformation' in err.response.data.message.message) {
                          this.$services.helpers.notification(err.response.data.message.message.ErrorInformation.message, 'error', this)
                        }
                      }
                    } else {
                      this.$services.helpers.notification(err.response.data.message, 'error', this)
                    }
                  }
                } else {
                  // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
                  this.$toast.error(this.$t('request_failure'));
                }
              } catch (e) {
                // this.$services.helpers.notification('Action could not be perform', 'error', this)
                this.$toast.error(this.$t('action_failed'));
              }
            }
          })
    },
    openTag() {
      window.Bus.emit('open-single-tag-customer', this.data)
    },
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  }
}
</script>

<style lang="scss" scoped>
.content .box table {
  width: 100%;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .content .box table {
    min-width: 1000px;
  }
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 5px;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead tr {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.text-green {
  color: #419e6a !important;
}

.text-yellow {
  color: #efb008;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr.inactive td:last-of-type button:nth-of-type(1) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td .email {
  padding: 10px 5px;
}

.content .box table tbody td .email input {
  background: #f0eff5;
  border: 1px solid #c8c7cd;
  padding: 2px 12px;
  width: 153px;
}

.content .box table tbody td .email input:focus {
  border: 1px solid #c8c7cd;
  outline: none;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td span {
  border-radius: 4px;
  min-width: 79.24px;
  display: inline-block;
  text-align: center;
}

.content .box table tbody td span.inactive {
  background: #ffde81;
  color: #976400;
}

.content .box table tbody td span.enabled {
  background: #a5e1bf;
  color: #00632b;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button:last-of-type {
  margin-left: 5px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  text-indent: 4px;
  padding-left: 3px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

.el-pointer {
  cursor: pointer;
  pointer-events: auto;
}

.badge-success {
  height: 21.259172439575195px;
  width: 79.23873901367188px;
  left: 912.7255859375px;
  border-radius: 4px;
  line-height: initial;
  background: #A5E1BF;
}

.badge-warning {
  height: 21.259172439575195px;
  width: 79.23873901367188px;
  left: 912.7255859375px;
  border-radius: 4px;
  line-height: initial;
  background: #FFDE81;
}
</style>
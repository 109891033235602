const RECURRING_CONTRACT_UPDATE_OPTION_LIST = [
    {key: 'increase_discount', locale: 'increaseDiscount'},
    {key: 'decrease_discount', locale: 'decreaseDiscount'},
    {key: 'increase_quantity', locale: 'increaseDeliveredQuantity'},
    {key: 'decrease_quantity', locale: 'decreaseDeliveredQuantity'},
    {key: 'increase_price_amount', locale: 'increasePriceAmount'},
    {key: 'decrease_price_amount', locale: 'decreasePriceAmount'},
    {key: 'increase_price_percentage', locale: 'increasePricePercentage'},
    {key: 'decrease_price_percentage', locale: 'decreasePricePercentage'},
]

export {
    RECURRING_CONTRACT_UPDATE_OPTION_LIST
}
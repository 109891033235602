<template>
  <div>
    <BlogHeader @change-locale="changeLocale" />
    <ShimmerLoading v-if="loading === true" :num="15" />
    <div v-else>
      <!-- Blog Banner -->
      <section class="blog-banner">
        <div class="container">
          <div class="title">
            <h4><span>Likvio blogg</span></h4>
            <p>
              Här kan du läsa om allt från företagarnyheter till Fortnox tips &
              tricks
            </p>
          </div>
        </div>
      </section>
      <!-- Blog Banner End -->

      <!-- Featured Posts -->
      <section class="featured-posts">
        <div class="container">
          <h2 class="title">Featured posts</h2>

          <div class="row">
            <div
              v-for="(post, i) in featuredBlogPosts"
              :key="post.slug + i"
              class="col-lg-4"
            >
              <div class="item">
                <router-link :to="`/blog/${post.slug}`">
                  <img
                    v-if="post.image"
                    :src="
                      post.image || require('../../../assets/likvio_logo.png')
                    "
                    :alt="post.eng_title"
                  />
                  <img
                    v-else
                    src="../../../assets/likvio_logo.png"
                    :alt="post.eng_title"
                  />
                </router-link>
                <!-- post.image is available, src=post.image. else src= image from assets fo -->
                <div class="text">
                  <a href="blog-detail.html"
                    ><h4>
                      {{ locale == "se" ? post.swe_title : post.eng_title }}
                    </h4></a
                  >
                  <!-- <div
                    v-html="locale == 'se' ? post.swe_text : post.eng_text"
                  ></div> -->
                  <p>
                    {{ locale == "se" ? post.swe_preamble : post.eng_preamble }}
                  </p>

                  <a href="blog-detail.html" class="button"
                    >Read more <span class="fas fa-arrow-right"></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Featured Posts End -->

      <!-- Blog Main -->
      <section id="blog-main" class="blog-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="left">
                <h4>Topics</h4>
                <ul>
                  <li v-for="category in blogCategories" :key="category.id">
                    <a
                      @click="selectedCategory = category.swe_category"
                      href="#blog-main"
                    >
                      {{
                        locale === "se"
                          ? category.swe_category
                          : category.eng_category
                      }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-9">
              <div class="blog-filter" v-if="selectedCategory !== ''">
                <h6>Selected Topic</h6>
                <div class="filter-item">
                  <span>
                    {{ selectedCategory }}
                  </span>
                  <span
                    style="cursor: pointer"
                    @click="selectedCategory = ''"
                    class="fas fa-times"
                  ></span>
                </div>
              </div>
              <div
                v-for="(blog, i) in filteredBlogPosts"
                :key="'blog-' + blog.slug + i"
                class="item"
              >
                <div class="row align-items-center">
                  <div class="col-lg-4">
                    <router-link :to="`/blog/${blog.slug}`">
                      <img
                        v-if="blog.image"
                        :src="blog.image"
                        :alt="blog.eng_title"
                        class="main"
                      />
                      <img
                        style="object-fit: cover; width: auto; height: 100%"
                        v-else
                        src="../../../assets/likvio_logo.png"
                        :alt="blog.eng_title"
                        class="main"
                      />
                    </router-link>
                  </div>

                  <div class="col-lg-8">
                    <div class="text">
                      <router-link :to="`/blog/${blog.slug}`">
                        <h4>
                          {{
                            locale === "se" ? blog.swe_title : blog.eng_title
                          }}
                        </h4>
                      </router-link>
                      <div class="alt">
                        <h6>
                          {{ new Date(blog.created_at).toLocaleString() }}
                        </h6>
                      </div>
                      <p>
                        {{
                          locale === "se"
                            ? blog.swe_preamble
                            : blog.eng_preamble
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Blog Main End -->
    </div>
  </div>
</template>

<script>
import BlogHeader from "./BlogHeader.vue";
import ShimmerLoading from "../../Clients/reusableComponents/ShimmerLoading.vue";

export default {
  data() {
    return {
      selectedCategory: "",
      blogPosts: [],
      featuredBlogPosts: [],
      loading: false,
      locale: "en",
    };
  },

  async created() {
    this.getBlogCategories();
    this.getBlogPosts();
  },

  mounted() {
    this.addFontAwesomeToHead();
  },

  methods: {
    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },

    changeLocale(locale) {
      this.locale = locale;
    },

    async getBlogPosts() {
      this.loading = true;
      const res = await this.$store.dispatch("clients/getBlogPosts");
      this.blogPosts = res.data.results;
      this.featuredBlogPosts = this.blogPosts
        .filter((post) => post.featured)
        .slice(0, 3);
      this.loading = false;
    },

    async getBlogCategories() {
      const res = await this.$store.dispatch("clients/getBlogCategories");
      this.blogCategories = res.data.results;
    },
  },

  computed: {
    filteredBlogPosts() {
      if (this.selectedCategory === "") {
        return this.blogPosts;
      }
      return this.blogPosts.filter((blog) => {
        return (
          blog.category.swe_category === this.selectedCategory ||
          blog.category.eng_category === this.selectedCategory
        );
      });
    },
  },

  components: {
    BlogHeader,
    ShimmerLoading,
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
h1 {
  font-size: 30px;
}

.featured-posts {
  padding: 70px 0;
  background-color: #eaf1fa;
}
.featured-posts .title {
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 25px;
  color: #1a232d;
}
.featured-posts .item {
  display: block;
}
@media (max-width: 992px) {
  .featured-posts .item {
    margin-bottom: 25px;
  }
}
.featured-posts .item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 15px;
}
.featured-posts .item .text h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
  color: #1a232d;
}
.featured-posts .item .text p {
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 15px;
  color: #1a232d;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.featured-posts .item .text .button {
  color: #045e62;
  text-decoration: underline;
  display: table;
}
.featured-posts .item .text .button img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-left: 7px;
  margin-bottom: 0;
}

.blog-main {
  padding: 70px 0;
}
@media (max-width: 992px) {
  .blog-main .left {
    margin-bottom: 25px;
    padding: 25px;
  }
}
.blog-main .left h4 {
  margin: 0;
  color: #1a232d;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
  margin-left: -20px;
}
.blog-main .left ul {
  position: relative;
}
.blog-main .left ul::before {
  content: "";
  width: 3px;
  height: 100%;
  background-color: #045e62;
  display: block;
  position: absolute;
  top: 0;
  left: -15px;
}
.blog-main .left ul li {
  margin-bottom: 20px;
}
.blog-main .left ul li a {
  color: #045e62;
}
.blog-main .item {
  margin-bottom: 20px;
  display: block;
}
@media (max-width: 992px) {
  .blog-main .item {
    margin-left: 0;
  }
}
.blog-main .item .main {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .blog-main .item .main {
    margin-bottom: 15px;
  }
}
.blog-main .item .text h4 {
  color: #1a232d;
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 15px;
}
@media (max-width: 992px) {
  .blog-main .item .text h4 {
    font-size: 20px;
  }
}
.blog-main .item .text p {
  margin: 0;
  color: #3f3f3a;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-main .item .text .alt {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.blog-main .item .text .alt .user {
  display: flex;
  align-items: center;
}
.blog-main .item .text .alt .user img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}
.blog-main .item .text .alt .user span {
  margin: 0;
  color: #045e62;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 992px) {
  .blog-main .item .text .alt .user span {
    font-size: 13px;
  }
}
.blog-main .item .text .alt h6 {
  color: #045e62;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-left: 25px;
}

.blog-detail-banner {
  padding: 70px 0;
  background-color: #eaf1fa;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
@media (max-width: 992px) {
  .blog-detail-banner {
    z-index: 1;
  }
}
.blog-detail-banner .breadcrumb {
  padding: 12px 25px;
  border-radius: 100px;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  margin-bottom: 35px;
}
.blog-detail-banner .breadcrumb li {
  font-size: 14px;
  color: #045e62;
}
.blog-detail-banner .breadcrumb li:not(:last-child) {
  margin-right: 15px;
}
.blog-detail-banner .breadcrumb li i {
  margin-left: 15px;
}
.blog-detail-banner .breadcrumb li a {
  color: #045e62;
  font-size: 14px;
}
.blog-detail-banner .main {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 25px;
}
@media (max-width: 992px) {
  .blog-detail-banner .main {
    height: 250px;
    margin-bottom: 25px;
  }
}
.blog-detail-banner .text {
  margin-left: 30px;
}
@media (max-width: 992px) {
  .blog-detail-banner .text {
    margin-left: 0;
  }
}
.blog-detail-banner .text h6 {
  color: #045e62;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 5px;
}
.blog-detail-banner .text h4 {
  color: #1a232d;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}
.blog-detail-banner .text p {
  margin: 0;
  color: #1a232d;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-detail-banner .text .user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.blog-detail-banner .text .user img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}
.blog-detail-banner .text .user span {
  margin: 0;
  color: #045e62;
  font-size: 14px;
  font-weight: 500;
}

.blog-content {
  padding: 70px 0;
}
.blog-content .text h4 {
  color: #1a232d;
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}
.blog-content .text p {
  color: #3f3f3a;
  margin: 0;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .blog-content .text p {
    font-size: 14px;
    line-height: 25px;
  }
}

.blog-filter {
  padding: 20px 0;
}

.filter-item {
  font-size: 12px;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border: 1px solide #e5e5e5;
  background-color: #e5e5e5;
  border-radius: 5px;
}
</style>

export const segmentArticle = {
    name: '',
    description: '',
    notes: '',
    average_monthly_earnings: 0,
    manufacturer: '',
    supplier: '',
    inventory_location: '',
    unit: '',
    discontinued: true,
    stock_item: true,
    external_webshop: true,
    product_type: 'STOCK',
    sales_account_se: '',
    sales_account_eu_vat: '',
    sales_account_export: '',
    purchase_account: '',
}
export default {segmentArticle}

<template>
  <div class="col responsive-width bg-light" style="min-height: 100vh">
    <div class="content" style="margin-top: 10px">
      <div class="container-fluid">
        <h3>{{ $t("contact_support") }}</h3>

        <!-- contact support form with name email and message content using bootstrap -->
        <form @submit.prevent="submitMessage" class="w-75">
          <div class="form-group">
            <label for="name">{{ $t("subject") }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('enter_subject')"
              v-model="subject"
              required
            />
          </div>

          <div class="form-group">
            <label for="message">{{ $t("message") }}</label>
            <textarea
              required
              class="form-control"
              id="message"
              rows="6"
              :placeholder="$t('enter_message')"
              v-model="message"
            ></textarea>
          </div>
          <button
            type="submit"
            class="btn"
            style="
              background-color: #333269;
              color: white;
              padding-left: 30px;
              padding-right: 30px;
            "
          >
            {{ $t("submit") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Support",
  data() {
    return {
      subject: "",
      message: "",
    };
  },
  methods: {
    async submitMessage() {
      if (this.subject == "" || this.message == "") {
        return;
      }

      const res = await this.$store.dispatch("clients/openSupportTicket", {
        subject: this.subject,
        message: this.message,
      });

      this.$services.helpers.notification(res.data.message, "success");
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}

label {
  margin-bottom: 3px;
}

input:focus {
  outline: none;
}
</style>

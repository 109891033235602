<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="headers d-flex me-3">
            <h1 class="mb-0">{{ $t('products') }}</h1>
            <h6>{{ $t('edit') }} <strong>{{ GET_SEGMENT_ARTICLE_INFO.name }}</strong> {{ $t('segment') }} </h6>
          </div>
          <router-link :to="{ name : 'productSegment' }" class="my-2 my-sm-0">
            <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z"
                  fill="#5855D6"></path>
            </svg>
            {{ $t('go_back_to_segment_overview') }}
          </router-link>
        </div>
        <div class="box product">
          <h3>{{ $t('product_segment') }}</h3>
          <form class="row">
            <div class="col">
              <div class="desc">
                <label>{{ $t('name') }}</label>
                <input type="text" v-model="GET_SEGMENT_ARTICLE_INFO.name">
              </div>
              <div class="desc">
                <label>{{ $t('supplier') }}</label>
                <select v-model="GET_SEGMENT_ARTICLE_INFO.supplier">
                    <option value=""></option>
                  <option :value="item.supplier_number" v-for="item in suppliers" :key="item.id">{{
                      item.name
                    }}
                  </option>
                </select>
              </div>
            </div>
            <!--            <div class="col">-->
            <!--              <div class="desc">-->
            <!--                <label>Inventory location</label>-->
            <!--                <input type="text"-->
            <!--                       v-model="GET_SEGMENT_ARTICLE_INFO.inventory_location">-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="col">
              <div class="desc">
                <label>{{ $t('unit') }}</label>
                <select class="small" v-model="GET_SEGMENT_ARTICLE_INFO.unit">
                    <option value=""></option>
                  <option :value="item.code" v-for="item in units" :key="item.id">{{ item.code }}</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="desc">
                <label>{{ $t('stock_item') }}</label>
                <div class="radio">
                  <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.stock_item" :value="true" name="stock-item" v-on:change="handleTypeChange"
                         id="stock-yes" checked>
                  <label for="stock-yes">{{ $t('yes') }}</label>
                  <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.stock_item" :value="false" name="stock-item" v-on:change="handleTypeChange"
                         id="stock-no">
                  <label for="stock-no">{{ $t('no') }}</label>
                </div>
              </div>
              <div class="desc">
                <div class="desc">
                  <label>{{$t('product_type')}}</label>
                  <div class="radio">
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.product_type" value="STOCK" v-on:change="handleTypeChange"
                           name="product-type" id="product-goods" checked>
                    <label for="product-goods">{{ $t('goods') }}</label>
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.product_type" value="SERVICE" v-on:change="handleTypeChange"
                           name="product-type" id="product-services">
                    <label for="product-services">{{ $t('services') }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="desc">
                <label>{{$t('external_webshop')}}</label>
                <div class="radio">
                  <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.external_webshop" :value="true"
                         name="external-webshop" id="external-yes" checked>
                  <label for="external-yes">{{ $t('yes') }}</label>
                  <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.external_webshop" :value="false"
                         name="external-webshop" id="external-no">
                  <label for="external-no">{{ $t('no') }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('discontinued') }}</label>
                <div class="radio">
                  <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.discontinued" :value="true" name="discontinued"
                         id="discontinued-yes" checked>
                  <label for="discontinued-yes">{{ $t('yes') }}</label>
                  <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.discontinued" :value="false" name="discontinued"
                         id="discontinued-no">
                  <label for="discontinued-no">{{ $t('no') }}</label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="box account">
          <h3>{{ $t('account_detail') }}</h3>
          <form class="row">
            <div class="col-lg col">
              <div class="row">
                <div class="col">
                  <div class="desc">
                    <label>{{$t('sales_account_eu_vat')}}</label>
                    <input @input="searchField($event, 'eu_vat')" type="text"
                           v-model="payload.sales_account_eu_vat">
                    <div v-if="showSalesAccount" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'eu_vat')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="desc">
                    <label>{{ $t('sales_account_se') }}</label>
                    <input @input="searchField($event, 'se_vat')" type="text"
                           v-model="payload.sales_account_se">
                    <div v-if="showSalesSeReverseVat" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'se_vat')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col">

                  <div class="desc">
                    <label>{{ $t('sales_account_export') }}</label>
                    <input @input="searchField($event, 'sales')" type="text"
                           v-model="payload.sales_account_export">
                    <div v-if="showSalesEuReverseVat" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'sales')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t('purchase_account') }}</label>
                    <input @input="searchField($event, 'purchase')" type="text"
                           v-model="payload.purchase_account">
                    <div v-if="showPurchaseAccount" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'purchase')">
                          {{ item.number + ' ' + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="desc">
                      <label>{{ $t('description') }}</label>
                      <textarea v-model="GET_SEGMENT_ARTICLE_INFO.description"></textarea>
                    </div>
                  </div>
                  <div class="col">
                    <div class="desc mb-0">
                      <label>{{ $t('note') }}</label>
                      <textarea v-model="GET_SEGMENT_ARTICLE_INFO.notes"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 align-self-md-end"><img class="ms-auto d-block"
                                                                  src="./../../assets/images/table/products-account-details.png"
                                                                  alt=""></div>
          </form>
        </div>
        <!--        <div class="d-flex justify-content-end">-->
        <!--          <div class="checkbox">-->
        <!--            <input type="checkbox">-->
        <!--            <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--              <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"-->
        <!--                    stroke="#5855D6" stroke-width="0.978073"></rect>-->
        <!--              <path-->
        <!--                  d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"-->
        <!--                  fill="#F6F8FC"></path>-->
        <!--            </svg>-->
        <!--          </div>-->
        <!--          <p>-->
        <!--            * I understand that the above settings will be<br>-->
        <!--            applied to all customers in this segment.-->
        <!--          </p>-->
        <!--        </div>-->
        <div class="buttons flex-wrap align-items-center justify-content-end justify-content-sm-between">
          <button type="button" v-on:click="showDeleteModal = true">{{ $t('delete') }} {{ $t('segment') }}</button>
          <div class="right flex-wrap my-2 my-sm-0"><a href="javascript:void(0);" @click="$router.go(-1)">{{ $t('cancel') }}</a>
            <button class="my-2 my-sm-0" :disabled="updating" type="button" v-on:click="updateProductSegment">
              <span v-if="updating === false"> {{ $t('update') }}</span>
              <span v-else> {{ $t('updating') }}...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAlertVue v-if="showDeleteModal" @handleClose="showDeleteModal = false" :close="!true">
    <h4>{{ $t('delete_confirmation_text') }} <span class="user-confirm text-danger">{{ $t('article_segment').toLowerCase() }}</span>?</h4>
    <div class="popup-bottons">
      <button @click="showDeleteModal = false">{{ $t('cancel_delete_request') }}</button>
      <button :disabled='loading' @click="deleteArticleSegment">
        <span v-if="loading">{{ $t('deleting') }} {{ $t('product') }}...</span>
        <span v-else>{{ $t('accept_delete_request') }}</span>
      </button>
    </div>
  </ModalAlertVue>
</template>

<script>
import ModalAlertVue from "../../components/ModalAlert";
import {mapGetters} from "vuex";
import debounce from "lodash.debounce";

export default {
  name: 'ProductSegmentAll',
  components: {ModalAlertVue},
  data() {
    return {
      payload: {
        sales_account_eu_vat: '',
        sales_account_se: '',
        sales_account_export: '',
        purchase_account: '',

      },
      showSalesAccount: false,
      showPurchaseAccount: false,
      showSalesEuReverseVat: false,
      showSalesSeReverseVat: false,
      accounts: [],
      units: [],
      suppliers: [],
      loading: false,
      updating: false,
      showDeleteModal: false,
    }
  },
  computed: {
    ...mapGetters({
      GET_SEGMENT_ARTICLE_INFO: 'clients/GET_SEGMENT_ARTICLE_INFO',
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  },
  mounted() {
    const {id} = this.$route.params
    this.getSegmentProductDetails(id)
    this.getUnit()
    this.getSupplier()
  },
  methods: {
    handleTypeChange: function () {
      if (this.GET_SEGMENT_ARTICLE_INFO.product_type === 'SERVICE') {
        this.GET_SEGMENT_ARTICLE_INFO.stock_item = false
      }
    },
    getSupplier() {
      const URL = this.$services.endpoints.BASE_ENDPOINT + 'suppliers'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.suppliers = res.data.results
          })
          .catch(err => {
          })
    },
    deleteArticleSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.processing = true
      this.$store.dispatch('clients/removeProductSegment', {id: this.GET_SEGMENT_ARTICLE_INFO.id})
          .then(res => {
            this.processing = false
            // this.$services.helpers.notification('Article Segment removed successfully', 'success', this)
            this.$toast.success(this.$t('segment_removed'));
            this.$router.push({name: 'productSegment'})

          })
          .catch(err => {
            this.processing = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)

          })
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'units'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.units = res.data.results
          })
          .catch(err => {
          })
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            if (mode === 'eu_vat') {
              this.showSalesAccount = true
            } else if (mode === 'se_vat') {
              this.showSalesSeReverseVat = true
            } else if (mode === 'sales') {
              this.showSalesEuReverseVat = true
            } else if (mode === 'purchase') {
              this.showPurchaseAccount = true
            }
          })
          .catch(err => {

          })
    },
    chooseSales(item, mode) {
      if (mode === 'sales') {
        this.payload.sales_account_export = item.number
        this.showSalesEuReverseVat = false
      } else if (mode === 'se_vat') {
        this.showSalesSeReverseVat = false
        this.payload.sales_account_se = item.number
      } else if (mode === 'eu_vat') {
        this.payload.sales_account_eu_vat = item.number
        this.showSalesAccount = false
      } else if (mode === 'purchase') {
        this.payload.purchase_account = item.number
        this.showPurchaseAccount = false
      }
    },
    getSegmentProductDetails(data) {
      this.$store.dispatch('clients/getSegmentProductDetails', data).then(_ => {
        let self = this
        setTimeout(function () {
          if (self.GET_SEGMENT_ARTICLE_INFO) {
            if (self.GET_SEGMENT_ARTICLE_INFO.sales_account_se) {
              self.payload.sales_account_se = self.GET_SEGMENT_ARTICLE_INFO.sales_account_se.number
            }
            if (self.GET_SEGMENT_ARTICLE_INFO.purchase_account) {
              self.payload.purchase_account = self.GET_SEGMENT_ARTICLE_INFO.purchase_account
            }
            if (self.GET_SEGMENT_ARTICLE_INFO.sales_account_eu_vat) {
              self.payload.sales_account_eu_vat = self.GET_SEGMENT_ARTICLE_INFO.sales_account_eu_vat.number
            }
            if (self.GET_SEGMENT_ARTICLE_INFO.sales_account_export) {
              self.payload.sales_account_export = self.GET_SEGMENT_ARTICLE_INFO.sales_account_export.number
            }
          }
        }, 400)
      })
    },
    updateProductSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.updating = true
      let data_to_save = {
        description: this.GET_SEGMENT_ARTICLE_INFO.description,
        note: this.GET_SEGMENT_ARTICLE_INFO.notes,
        name: this.GET_SEGMENT_ARTICLE_INFO.name,
        sales_account_se: this.payload.sales_account_se,
        purchase_account: this.payload.purchase_account,
        sales_account_eu_vat: this.payload.sales_account_eu_vat,
        sales_account_export: this.payload.sales_account_export,
        discontinued: this.GET_SEGMENT_ARTICLE_INFO.discontinued,
        stock_item: this.GET_SEGMENT_ARTICLE_INFO.stock_item,
        external_webshop: this.GET_SEGMENT_ARTICLE_INFO.external_webshop,
        product_type: this.GET_SEGMENT_ARTICLE_INFO.product_type,
        unit: this.GET_SEGMENT_ARTICLE_INFO.unit,
        supplier: this.GET_SEGMENT_ARTICLE_INFO.supplier,
        inventory_location: this.GET_SEGMENT_ARTICLE_INFO.inventory_location,
      }
      for (const [key, value] of Object.entries(data_to_save)) {
        if (value === null || value === '' || value === undefined) {
          delete data_to_save[key]
        }
      }
      const payload = {
        data: data_to_save,
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `articles/${this.GET_SEGMENT_ARTICLE_INFO.id}/`
      }
      this.$store.dispatch('clients/customPutRequest', payload)
          .then(res => {
            this.updating = false
            // this.$services.helpers.notification('Product segment updated successfully', 'success', this)
            this.$toast.success(this.$t('segment_updated'));
          })
          .catch(err => {
            this.updating = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .headers ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .headers ~ a svg {
  margin-top: -2px;
}

.content .headers ~ a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .headers ~ a:hover {
  color: #4946ae;
}

.content .headers ~ a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.product h3 {
  background-image: url("./../../assets/images/table/product.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box.account h3 {
  background-image: url("./../../assets/images/table/account.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box h3 {
  background: #5855d6;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  margin: 0;
}

.content .box form {
  padding: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content .box form h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.content .box form .desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 195px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.small {
  width: 94px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.small {
  width: 94px;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 12.469px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box form textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

.content .box form textarea:focus {
  border: none;
  outline: none;
}

.content .box:last-of-type {
  margin-bottom: 20px;
}

.content .box img {
  max-width: 100%;
}

.content .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 18px;
  width: 18px;
  z-index: 33;
  background: #fffbfb;
}

.content .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .checkbox input,
.content .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .checkbox input:checked ~ svg {
  display: block;
}

.content .checkbox svg {
  position: absolute;
  height: 20px;
  width: 20px;
  display: none;
  z-index: 1;
}

.content .checkbox ~ p {
  margin: 0;
  margin-left: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-top: 20px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
  min-width: 120px;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  color: #fff;
  background-color: #de4343;
}

.content .buttons .right {
  text-align: right;
  margin-left: auto;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  margin-left: 20px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="headers d-flex">
          <h1 class="mb-0">
            {{ $services.helpers.capitalizeNames($t("invoices")) }}
          </h1>
          <h6>{{ $t("create_invoice") }}</h6>
        </div>
        <div class="invoice-numbers flex-wrap justify-content-between">
          <h2 class="mb-0">
            {{ $services.helpers.capitalizeNames($t("invoice")) }}
            <strong> {{ invoice.document_number }}</strong>
          </h2>
          <div class="numbers my-2 flex-wrap">
            <!--            <p>OCR NUMBER: {{ invoice.ocr }}</p>-->
            <!--            <p>VOUCHER NUMBERS: {{ invoice.voucher_number }}</p>-->
          </div>
        </div>
        <div class="box invoice-details">
          <h3>{{ $t("invoice") }} {{ $t("detail") }}</h3>
          <form class="position-relative">
            <!-- <img src="./../../assets/images/table/create-chat.png"> -->
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label
                >{{ $t("customer") }}<span class="asterisk">*</span></label
                >
                <input
                    @input="searchField"
                    class="big"
                    type="text"
                    v-model="invoice.customer_name"
                />
                <div
                    v-if="showCustomer"
                    style="z-index: 9999; width: 12%"
                    class="position-absolute bg-white rounded shadow-md border mt-5"
                >
                  <ul
                      style="padding-left: 0rem !important; list-style-type: none"
                  >
                    <li
                        class="border-bottom p-2 mb-1 cursor-pointer"
                        v-for="item in searchCustomer.slice(0, 5)"
                        :key="item.id"
                        @click="chooseCustomer(item)"
                    >
                      {{ item.customer_number }} - {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("invoice_date") }}</label>
                <Datepicker
                    v-model="invoice.invoice_date"
                    @internalModelChange="computeDueDate"
                    format="yyyy-MM-dd"
                ></Datepicker>
              </div>
              <div class="desc">
                <label>{{ $t("due_date") }}</label>
                <!--                <input type="date" v-model="invoice.due_date">-->
                <Datepicker
                    v-model="invoice.due_date"
                    format="yyyy-MM-dd"
                ></Datepicker>
              </div>
              <div class="desc">
                <label>{{ $t("invoice") }} {{ $t("type") }}</label>
                <!-- <SwitchButton v-model="invoice.invoice_type" :switchLong='invoice.invoice_type' :long="true" :longValues="['INVOICE', 'CASH']"/> -->
                <div class="radio">
                  <input
                      type="radio"
                      v-model="invoice.invoice_type"
                      value="INVOICE"
                      name="invoice-type"
                      id="type-yes"
                  />
                  <label for="type-yes">{{ $t("invoice") }}</label>
                  <input
                      type="radio"
                      v-model="invoice.invoice_type"
                      value="CASH"
                      name="invoice-type"
                      id="type-no"
                  />
                  <label for="type-no">{{ $t("cash_invoice") }}</label>
                </div>
              </div>
              <!--              <div class="desc">-->
              <!--                <label>Credit invoice</label>-->
              <!--                <div class="radio">-->
              <!--                  <input type="radio" v-model="invoice.credit" :value="true" name="active" id="ac-yes" checked>-->
              <!--                  <label for="ac-yes">Yes</label>-->
              <!--                  <input type="radio" v-model="invoice.credit" :value="false" name="active" id="ac-no">-->
              <!--                  <label for="ac-no">No</label>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("terms_of_payment") }}</label>
                <select
                    v-model="invoice.terms_of_payment"
                    v-on:change="computeDueDate"
                >
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in paymentTerm"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("our_reference") }}</label>
                <input type="text" v-model="invoice.our_reference"/>
              </div>
              <div class="desc">
                <label>{{ $t("ocr") }}</label>
                <input type="text" min="1" v-model="invoice.ocr" required/>
              </div>
              <div class="desc">
                <label>{{ $t("your_order_number") }}</label>
                <input type="text" v-model="invoice.your_order_number"/>
              </div>
              <div class="desc">
                <label>{{ $t("terms_of_delivery") }}</label>
                <select v-model="invoice.terms_of_delivery">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in deliveryTerm"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("way_of_delivery") }}</label>
                <select v-model="invoice.way_of_delivery">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in deliveryWay"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <!--              <div class="desc">-->
              <!--                <label>Your order number</label>-->
              <!--                <input type="text" v-model="invoice.your_order_number">-->
              <!--              </div>-->
              <div class="desc">
                <label>{{ $t("your_reference") }}</label>
                <input type="text" v-model="invoice.your_reference"/>
              </div>
              <div class="desc">
                <label>{{ $t("price_list") }}</label>
                <select v-model="invoice.price_list">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in pricelist"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("price_incl_vat") }}</label>
                <div class="radio">
                  <input
                      v-model="invoice.vat_included"
                      type="radio"
                      :value="true"
                      name="vat"
                      id="vat-yes"
                  />
                  <label for="vat-yes">{{ $t("yes") }}</label>
                  <input
                      v-model="invoice.vat_included"
                      type="radio"
                      :value="false"
                      name="vat"
                      id="vat-no"
                  />
                  <label for="vat-no">{{ $t("no") }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("currency") }}</label>
                <select v-model="invoice.currency">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in currency"
                      :key="item.id"
                  >
                    {{ item.code }}
                  </option>
                </select>
              </div>
              <!-- <div class="desc">
                <label>Rate</label>
                <input v-model="invoice.currency_rate" type="text" readonly>
              </div>
              <div class="desc">
                <label>Unit</label>
                <input v-model="invoice.unit" type="text" readonly>
              </div> -->
              <!--              <div class="desc">-->
              <!--                <label>VAT type</label>-->
              <!--                <select>-->
              <!--                  <option value=""></option>-->
              <!--                  <option value="SE">SE</option>-->
              <!--                  <option value="SE reverse charge">SE reverse charge</option>-->
              <!--                  <option value="EU reverse charge">EU reverse charge</option>-->
              <!--                  <option value="Subject to EU VAT">Subject to EU VAT</option>-->
              <!--                  <option value="Export">Export</option>-->
              <!--                </select>-->
              <!--              </div>-->
            </div>
          </form>
        </div>
        <div class="row vertical-desc">
          <div class="col">
            <div class="box customer-details">
              <h3>{{ $t("customer_detail") }}</h3>
              <form class="row g-0">
                <div class="col">
                  <div class="desc">
                    <label
                    >{{ $t("name") }} <span class="asterisk">*</span></label
                    >
                    <input
                        class="big"
                        type="text"
                        :value="invoice.customer_name"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("invoice_address") }}</label>
                    <input class="big" type="text" :value="invoice.address1"/>
                  </div>
                  <div class="desc">
                    <label>{{ $t("invoice_address") }} 2 </label>
                    <input class="big" type="text" :value="invoice.address2"/>
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("zip_code") }}</label>
                      <input
                          class="small"
                          type="text"
                          :value="invoice.zip_code"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input class="small" type="text" :value="invoice.city"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("organization_number") }}</label>
                    <input
                        class="big"
                        type="text"
                        :value="invoice.organisation_number"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("phone") }}</label>
                    <input class="big" type="text" :value="invoice.phone1"/>
                  </div>
                  <div class="desc">
                    <label>{{ $t("emailAddress") }}</label>
                    <input
                        v-if="invoice.email_information"
                        class="big"
                        type="text"
                        :value="invoice.email_information.email_address_from"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input class="small" type="text" :value="invoice.city"/>
                    </div>
                    <div class="desc">
                      <label>{{ $t("country") }}</label>
                      <!-- <input class="small" type="text" :value="invoice.country"> -->
                      <select class="small" v-model="invoice.country">
                        <option value=""></option>
                        <option
                            :value="item.name"
                            v-for="(item, i) in countries"
                            :key="i"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col">
            <div class="box delivery-details">
              <h3>{{ $t("delivery_detail") }}</h3>
              <form class="row g-0">
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("name") }}</label>
                    <input
                        class="big"
                        type="text"
                        :value="invoice.delivery_name"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("delivery_address1") }}</label>
                    <input
                        class="big"
                        type="text"
                        :value="invoice.delivery_address1"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("delivery_address2") }}</label>
                    <input
                        class="big"
                        type="text"
                        :value="invoice.delivery_address2"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("delivery_zip_code") }}</label>
                      <input
                          class="small"
                          type="text"
                          :value="invoice.delivery_zip_code"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("delivery_city") }}</label>
                      <input
                          class="small"
                          type="text"
                          :value="invoice.delivery_city"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("delivery_date") }}</label>
                    <input
                        class="big"
                        type="text"
                        :value="invoice.delivery_date"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("phone") }}</label>
                    <input class="big" :value="invoice.phone2" type="text"/>
                  </div>
                  <div class="desc">
                    <label>{{ $t("emailAddress") }}</label>
                    <input
                        :value="invoice.email_information.email_address_to"
                        class="big"
                        type="text"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input
                          class="small"
                          type="text"
                          :value="invoice.delivery_city"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("country") }}</label>
                      <!-- <input class="small" type="text" :value="invoice.delivery_country"> -->
                      <select v-model="invoice.delivery_country">
                        <option value=""></option>
                        <option
                            :value="item.name"
                            v-for="(item, i) in countries"
                            :key="i"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <custom-invoice-row-component
            :key="sortKey"
            :invoice_rows="invoice.invoice_rows"
            :invoice_country="invoice.country_code"
            @calculateRow="reCalculateValues"
            :vat_included="invoice.vat_included"
            :submitForm="submitForm"
            @updateContract="addInvoice"
            @removeRow="removeRow"
        />
        <div class="row align-items-center">
          <div class="col-lg-8">
            <div class="box others-details">
              <h3>{{ $t("other_detail") }}</h3>
              <form>
                <div class="row">
                  <div class="col-lg-7">
                    <div class="d-flex flex-wrap col-flex-wrap">
                      <div class="desc me-0 w-full">
                        <label>{{ $t("invoice_text") }}</label>
                        <textarea
                            class="w-full"
                            v-model="invoice.remarks"
                        ></textarea>
                      </div>
                      <div class="desc me-0 w-full">
                        <label>{{ $t("comment") }}</label>
                        <textarea class="w-full" v-model="invoice.comments">
                        </textarea>
                      </div>
                    </div>
                    <div class="d-flex mt-lg-3 flex-wrap"></div>
                  </div>
                  <div class="col-lg d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("print_template") }}</label>
                      <select class="big" v-model="invoice.print_template">
                        <option value=""></option>
                        <option
                            v-for="(item, i) in printOutTemplate"
                            :value="item.print_template"
                            :key="i"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="desc me-0 w-full">
                      <label>Invoice text</label>
                      <textarea class="w-full" v-model="invoice.remarks"></textarea>
                    </div>
                    <div class="desc me-0 w-full">
                      <label>Comment</label>
                      <textarea class="w-full" v-model="invoice.comments"> </textarea>
                    </div> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg col-md-4 col-sm-6 mx-auto mx-lg-0">
            <div class="box price">
              <ul class="list-unstyled">
                <!-- <li><span class="text">{{ $t('net') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ total_excluding_vat }}</span>
                </li>
                <li><span class="text">{{ $t('gross') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoice.gross }}</span>
                </li> -->
                <li>
                  <span class="text">{{ $t("vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoice.total_vat }}</span>
                </li>
                <li>
                  <span class="text">{{ $t("total_excluding_vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoice.total }}</span>
                </li>
                <hr/>
                <li>
                  <span class="text">{{ $t("total") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoice.total_to_pay }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <div class="col-lg-4">
            <!-- <div class="send">
              <label>Send Invoice</label>
              <select>
                <option value="">Email</option>
              </select>
              <button type="submit">Send</button>
            </div> -->
          </div>
          <div class="col-lg">
            <div class="buttons">
              <router-link :to="{ name: 'invoices' }">{{
                  $t("cancel")
                }}
              </router-link>
              <button
                  @click="submitForm = true"
                  :disabled="processing"
                  type="submit"
              >
                <span v-if="processing">{{ $t("creating") }}...</span>
                <span v-else>{{ $t("create_invoice") }}</span>
              </button>
              <!-- <button @click="addInvoice" :disabled='processing' type="submit">
                <span v-if="processing">Saving...</span>
                <span v-else>Save invoice</span>
              </button> -->
              <!-- <button type="submit">Bookkeep invoice</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import debounce from "lodash.debounce";
import {invoiceCreate, invoiceRow} from "./composables/invoice.create";
import {getCountries} from "./composables/countries";
import moment from "moment";
import Datepicker from "vue3-date-time-picker";
// import SwitchButton from './reusableComponents/SwitchButton.vue'
import CustomInvoiceRowComponent from "./reusableComponents/CustomInvoiceRowComponent";

export default {
  name: "CreateInvoice",
  components: {Datepicker, CustomInvoiceRowComponent},
  data() {
    return {
      sortKey: 0,
      invoice: {...invoiceCreate},
      row: {...invoiceRow},
      processing: false,
      deliveryWay: [],
      countries: getCountries(),
      currency: [],
      deliveryTerm: [],
      pricelist: [],
      paymentTerm: [],
      searchCustomer: [],
      printOutTemplate: [],
      accounts: [],
      costCenter: [],
      units: [],
      showArticle: false,
      showAccount: false,
      project: [],
      searchArticle: [],
      showCustomer: false,
      total_excluding_vat: 0,
      submitForm: false,
    };
  },
  watch: {
    "invoice.vat_included": function (newVal, oldVal) {
      // Identified BUG -- Results to 0 in Total after change in value
      this.reCalculateValues();
    },
  },
  mounted() {
    // const invoiceStorage = window.localStorage.getItem("__invoice__");
    // if (invoiceStorage) {
    //   this.row = JSON.parse(invoiceStorage).invoice_rows;
    //   this.invoice = JSON.parse(invoiceStorage);
    // }
    this.getDeliveryWay();
    this.getDeliveryTerm();
    this.getCurrency();
    this.getPriceList();
    this.getPaymentTerm();
    this.getUnit();
    this.getProjects();
    // this.getAccount()
    this.getCostCenter();
    this.getPrintOutTemplate();
  },
  // beforeUnmount() {
  //   const invoiceStorage = window.localStorage.getItem("__invoice__");
  //   if (invoiceStorage) {
  //     window.localStorage.removeItem("__invoice__");
  //   }
  // },

  methods: {
    getDiscountedTotal(total, discount, discountType) {
      if (discount) {
        if (discountType === "AMOUNT") {
          total = total - discount
        } else {
          total = total *
          (
              1 -
              parseFloat(
                  this.$services.helpers.removePercentage(discount) / 100
              )
          )
        }
      }
      return parseFloat(total.toFixed(2));
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "projects";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.project = res.data.results;
          })
          .catch((err) => {
          });
    },
    searchField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSearch(event.target.value);
      } else {
        this.showCustomer = false;
      }
    }, 500),
    filterSearch(data) {
      const payload = {
        URL:
            this.$services.endpoints.CUSTOMER_ENDPOINT +
            `?search=${data}&customize=no`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showCustomer = true;
              this.searchCustomer = res.data.data.filter(
                  (item) => item.active === true
              );
            }
          })
          .catch((err) => {
          });
    },
    searchArticleField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticleSearch(event.target.value);
      } else {
        this.showArticle = false;
      }
    }, 500),
    searchAccountField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterAccountSearch(event.target.value);
      } else {
        this.showAccount = false;
      }
    }, 500),
    filterArticleSearch(data) {
      const payload = {
        URL:
            this.$services.endpoints.ARTICLE_ENDPOINT +
            `?search=${data}&customize=no&active=1`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showArticle = true;
              this.searchArticle = res.data.data.filter(
                  (item) => item.active === true
              );
            }
          })
          .catch((err) => {
          });
    },
    filterAccountSearch(data) {
      this.accounts = [];
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.showAccount = true;
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    chooseArticle(item) {
      this.row.article_number = item.article_number;
      this.row.description = item.description;
      this.row.price = (item.sales_price == null) ? 0 : item.sales_price;
      // this.row.total = item.
      // this.row.cost_center = item.
      if (this.row.price && this.row.delivered_quantity) {
        this.row.total = this.row.delivered_quantity * this.row.price;
      }
      this.row.vat = item.vat;
      this.row.account_number = item.sales_account;
      this.row.unit = item.unit;
      this.showArticle = false;

      this.reCalculateValues(null);
    },
    chooseCustomer(customer) {
      if (!customer.active) {
        this.$toast.warning(
            "This customer is not active. Please select another customer."
        );
        return;
      }
      this.invoice.customer_number = customer.customer_number;
      this.invoice.customer_name = customer.name;
      this.invoice.address1 = customer.address1;
      this.invoice.address2 = customer.address2;
      this.invoice.zip_code = customer.zip_code;
      this.invoice.city = customer.city;
      this.invoice.currency = customer.currency;
      this.invoice.organisation_number = customer.organisation_number;
      this.invoice.phone1 = customer.phone1;
      this.invoice.city = customer.city;
      this.invoice.country = customer.country;
      this.invoice.country_code = customer.country_code;
      this.invoice.delivery_name = customer.delivery_name;
      this.invoice.delivery_address1 = customer.delivery_address1;
      this.invoice.delivery_address2 = customer.delivery_address2;
      this.invoice.delivery_zip_code = customer.delivery_zip_code;
      this.invoice.delivery_city = customer.delivery_city;
      this.invoice.delivery_date = customer.delivery_date;
      this.invoice.phone2 = customer.phone2;
      this.invoice.email = customer.email;
      this.invoice.email_address_to = customer.email_address_to;
      this.invoice.delivery_city = customer.delivery_city;
      this.invoice.delivery_country = customer.delivery_country;
      this.invoice.terms_of_payment = customer.terms_of_payment;
      this.invoice.terms_of_delivery = customer.terms_of_delivery;
      this.invoice.way_of_delivery = customer.way_of_delivery;
      this.invoice.our_reference = customer.our_reference;
      this.invoice.your_reference = customer.your_reference;
      this.invoice.price_list = customer.price_list;

      this.showCustomer = false;
    },
    chooseAccount(item) {
      this.row.account_number = item.number;
      this.showAccount = false;

      this.reCalculateValues();
    },
    addInvoiceRow() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.row.price && this.row.delivered_quantity) {
        if (!this.row.account_number && this.row.price) {
          // this.$services.helpers.notification('Kindly specify account number', 'error', this)
          this.$toast.warning(this.$t("select_account_number"));
          return false;
        } else if (this.row.description === "" && this.row.row_id === "") {
          // this.$services.helpers.notification('Description or Article number is required', 'error', this)
          this.$toast.warning(this.$t("description_article_required"));
          return false;
        } else {
          this.invoice.invoice_rows.push(JSON.parse(JSON.stringify(this.row)));
          this.row = {...invoiceRow};
          this.reCalculateValues();
        }
      } else {
        if (this.row.description || this.row.row_id) {
          this.invoice.invoice_rows.push(JSON.parse(JSON.stringify(this.row)));
          this.row = {...invoiceRow};
          this.reCalculateValues();
        } else {
          // this.$services.helpers.notification('Kindly specify a valid invoice row information', 'error', this)
          this.$toast.warning(this.$t("invalid_invoice_row"));
          return false;
        }
      }
    },
    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.invoice.invoice_rows.forEach((element) => {
        let vat_amount = 0;
        let vat_rate;
        if (!element.vat) {
          vat_rate = 0;
        } else {
          vat_rate = element.vat;
        }
        total_excluding_vat += parseFloat(element.total_excluding_vat);

        vat_amount =
            element.total_excluding_vat * (1 + vat_rate / 100) -
            element.total_excluding_vat;
        total += parseFloat(
            element.total_excluding_vat * (1 + vat_rate / 100)
        );
        total_vat += vat_amount;

        if (this.invoice.vat_included) {
          element.total = (
              parseFloat(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat) *
              parseFloat(1 + parseFloat(vat_rate) / 100)
          )
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);
          element.price = (
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(vat_rate) / 100).toFixed(2)
          ).toFixed(2);
        } else {
          element.total = parseFloat(element.delivered_quantity) * parseFloat(element.price_excluding_vat);
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);
          element.total_excluding_vat = parseFloat(
              element.total_excluding_vat
          ).toFixed(2);

          element.price_excluding_vat = parseFloat(
              element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        let vat_rate;
        if (!item.vat) {
          vat_rate = 0;
        } else {
          vat_rate = item.vat;
        }
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.invoice.vat_included) {
            item.total = parseFloat(item.delivered_quantity).toFixed(2) * parseFloat(item.price_per_unit) 
            item.total = this.getDiscountedTotal(item.total, item.discount, item.discount_type);
            item.total_excluding_vat = (
                item.total /
                (1 + parseFloat(vat_rate) / 100)
            ).toFixed(2);
          } else {
            item.total_excluding_vat = parseFloat(item.delivered_quantity)* parseFloat(item.price_per_unit)
            item.total_excluding_vat = this.getDiscountedTotal(item.total_excluding_vat, item.discount, item.discount_type);
            item.total = (
                item.total_excluding_vat *
                (1 + parseFloat(vat_rate) / 100)
            ).toFixed(2);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat).toFixed(
              2
          );

          vat = (item.total - item.total_excluding_vat).toFixed(2);
          total_vat += vat;
        }
      }

      this.invoice.gross = parseFloat(total).toFixed(2);
      this.invoice.total_vat = parseFloat(total_vat).toFixed(2);
      this.invoice.total = parseFloat(total_excluding_vat).toFixed(2);
      this.invoice.total_to_pay = parseFloat(total).toFixed(2);
    },

    computeRowOnInput(item) {
      let gross = 0;
      let rowVatTotal = 0;
      if (this.invoice.vat_included === true) {
        this.invoice.invoice_rows.forEach((element) => {
          let vat_rate;
          if (!element.vat) {
            vat_rate = 0;
          } else {
            vat_rate = element.vat;
          }
          let vat = 0;
          element.total = parseFloat(element.delivered_quantity) * parseFloat(element.price) 
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);
          vat = parseFloat(element.total) * (parseFloat(vat_rate) / 100);
          gross += parseFloat(element.total);
          rowVatTotal += parseFloat(vat);
        });
      } else {
        this.invoice.invoice_rows.forEach((element) => {
          let vat = 0;
          let vat_rate;
          if (!element.vat) {
            vat_rate = 0;
          } else {
            vat_rate = element.vat;
          }
          let rowExVat = parseFloat(element.delivered_quantity) * parseFloat(element.price)
          rowExVat = this.getDiscountedTotal(rowExVat, element.discount, element.discount_type);
          vat = rowExVat * parseFloat(vat_rate / 100).toFixed(2);
          element.total = parseFloat(rowExVat).toFixed(2);
          gross += parseFloat(element.total);
          rowVatTotal += parseFloat(vat);
        });
      }
      this.invoice.gross = parseFloat(gross).toFixed(2);
      this.invoice.total_vat = parseFloat(rowVatTotal).toFixed(2);
      this.invoice.total = parseFloat(gross - rowVatTotal).toFixed(2);
      this.invoice.total_to_pay = parseFloat(gross).toFixed(2);
      this.total_excluding_vat = parseFloat(gross) - parseFloat(rowVatTotal);
    },
    // calculateAllTotalValue () {
    //     let gross = 0
    //     let vat = 0
    //     let rowVatTotal = 0
    //     let total = 0
    //     this.invoice.invoice_rows.forEach(element => {
    //         gross += parseFloat(element.total)
    //         rowVatTotal += parseFloat(element.vat)
    //     })
    //     this.invoice.net = parseFloat(gross - rowVatTotal).toFixed(2)
    //     this.invoice.gross = parseFloat(gross).toFixed(2)
    //     this.invoice.total_vat = parseFloat(rowVatTotal).toFixed(2)
    //     this.invoice.total = parseFloat(gross - rowVatTotal).toFixed(2)
    // },,
    addInvoice() {
      this.submitForm = false;
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (
          this.invoice.customer_number === "" ||
          this.invoice.customer_name === ""
      ) {
        // this.$services.helpers.notification('Customer information required', 'error', this)
        this.$toast.warning(this.$t("customer_number_name_required"));
      } else {
        let exclude = [
          "email_information",
          "edi_information",
          "invoice_rows",
          "labels",
          "due_date",
          "invoice_date",
          "not_completed",
          "delivery_date",
        ];
        let data_to_save = {};
        let invoiceData = this.invoice;
        let email_information = {};
        let edi_information = {};
        let invoice_row = this.invoice.invoice_rows;
        let new_invoice_rows = [];
        for (const [key, value] of Object.entries(invoiceData)) {
          if (!exclude.includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = value;
            }
          }
          if (["due_date", "invoice_date", "delivery_date"].includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = moment(value).format("YYYY-MM-DD");
            }
          }
        }
        for (const [key, value] of Object.entries(
            invoiceData.email_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            email_information[key] = value;
          }
        }
        for (const [key, value] of Object.entries(
            invoiceData.edi_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            edi_information[key] = value;
          }
        }
        if (Object.keys(email_information).length > 0) {
          data_to_save["email_information"] = email_information;
        }
        if (Object.keys(edi_information).length > 0) {
          data_to_save["edi_information"] = edi_information;
        }
        invoice_row.map((item) => {
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete item[key];
            }
          }
          new_invoice_rows.push(JSON.parse(JSON.stringify(item)));
        });

        // new_invoice_rows.forEach((item) => {
        //   if (this.invoice.vat_included === false) {

        //     item.price = parseFloat(item.price / (1 + item.vat / 100)).toFixed(
        //       2
        //     );
        //     item.total = parseFloat(item.total / (1 + item.vat / 100)).toFixed(
        //       2
        //     );
        //   }
        // });

        data_to_save.invoice_rows = new_invoice_rows;
        this.processing = true;
        this.$store
            .dispatch("clients/addInvoice", data_to_save)
            .then((res) => {
              this.processing = false;
              this.$services.helpers.notification(
                  res.data[this.locale_message],
                  "success",
                  this
              );
              // const invoiceStorage = window.localStorage.getItem("__invoice__");
              // if (invoiceStorage) {
              //   window.localStorage.removeItem("__invoice__");
              // }
              this.invoice = {...invoiceCreate};
              // this.invoice.invoice_rows = [];
              this.row = {...invoiceRow};

              // while (this.invoice.invoice_rows.length > 0) {
              //   this.invoice.invoice_rows.pop();
              // }

              this.invoice.invoice_rows.length = 0;

              this.$router.push({
                name: "editInvoice",
                params: {
                  id: res.data.data.document_number,
                },
              });
            })
            .catch((err) => {
              this.processing = false;
              this.errorMessage(err);
            });
      }
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "termsofpayment";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.paymentTerm = res.data.results;
          })
          .catch((err) => {
          });
    },
    errorMessage(err) {
      try {
        if ([400, 406].includes(err.response.status)) {
          if ("error" in err.response.data) {
            if (Array.isArray(err.response.data.error)) {
              this.$services.helpers.notification(
                  err.response.data.error.join(","),
                  "error",
                  this
              );
            } else if (typeof err.response.data.error === "object") {
              for (const [key, value] of Object.entries(
                  err.response.data.error
              )) {
                if (Array.isArray(value)) {
                  this.$services.helpers.notification(
                      key.replace("_", " ") + ": " + value.join(","),
                      "error",
                      this
                  );
                } else {
                  this.$services.helpers.notification(value, "error", this);
                }
                return false;
              }
            } else {
              this.$services.helpers.notification(
                  err.response.data.error,
                  "error",
                  this
              );
            }
          } else if ("message" in err.response.data) {
            if (Array.isArray(err.response.data.message)) {
              this.$services.helpers.notification(
                  err.response.data.message.join(""),
                  "error",
                  this
              );
            } else if (typeof err.response.data.message === "object") {
              if ("message" in err.response.data.message) {
                if ("ErrorInformation" in err.response.data.message.message) {
                  this.$services.helpers.notification(
                      err.response.data.message.message.ErrorInformation.message,
                      "error",
                      this
                  );
                }
              }
            } else {
              this.$services.helpers.notification(
                  err.response.data.message,
                  "error",
                  this
              );
            }
          }
        } else {
          // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
          this.$toast.error(this.$t("request_failure"));
        }
      } catch (e) {
        // this.$services.helpers.notification('Action could not be perform', 'error', this)
        this.$toast.error(this.$t("action_failed"));
      }
    },
    getDeliveryWay() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "wayofdelivery";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.deliveryWay = res.data.results;
          })
          .catch((err) => {
          });
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "currencies";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.currency = res.data.results;
          })
          .catch((err) => {
          });
    },
    getDeliveryTerm() {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT + "termsofdelivery";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.deliveryTerm = res.data.results;
          })
          .catch((err) => {
          });
    },
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "pricelists";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.pricelist = res.data.results;
          })
          .catch((err) => {
          });
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.units = res.data.results;
          })
          .catch((err) => {
          });
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "accounts";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "costcenters";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.costCenter = res.data.results;
          })
          .catch((err) => {
          });
    },
    getPrintOutTemplate() {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT +
          `printtemplates?type=invoice`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.printOutTemplate = res.data.results;
          })
          .catch((err) => {
          });
    },
    removeRow(item) {
      this.invoice.invoice_rows = this.invoice.invoice_rows.filter(el => el.id !== item.id);
      this.sortKey++;
      this.reCalculateValues(null);
    },
    computeDueDate() {
      if (!isNaN(parseInt(this.invoice.terms_of_payment))) {
        this.invoice.due_date = moment(this.invoice.invoice_date)
            .add(parseInt(this.invoice.terms_of_payment), "days")
            .format("YYYY-MM-DD");
      } else {
        this.invoice.due_date = this.invoice.invoice_date;
      }
    },
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: "clients/GET_CURRENT_USER",
      GET_LOCALE: 'locale/GET_LOCALE'
    }),

    invoiceRowCompKey() {
      return this.invoice.invoice_rows.length;
    },
    locale_message() {
      return this.GET_LOCALE === 'se' ? 'message' : 'eng_message'
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .invoice-numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
}

.content .invoice-numbers h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .invoice-numbers .numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .invoice-numbers .numbers p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin: 0;
}

.content .invoice-numbers .numbers p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.invoice-details h3 {
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box.invoice-details form img {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.content .box.customer-details h3 {
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.delivery-details h3 {
  background-image: url("./../../assets/images/table/delivery.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.invoice h3 {
  background-image: url("./../../assets/images/table/invoice-row.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.others-details h3 {
  background-image: url("./../../assets/images/table/others.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box:after {
  content: "";
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
  min-width: 100%;
}

.content .box form {
  padding: 30px;
}

.content .box form .desc {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 0 0 10px 0;
  padding-right: 10px;
}

.content .box form .desc label {
  margin-bottom: 10px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 991.98px) {
  .content .box form .desc input {
    width: 130px;
  }
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="date"] {
  text-indent: 5px;
}

.content .box form .desc input[type="date"]::-webkit-clear-button,
.content .box form .desc input[type="date"]::-webkit-inner-spin-button,
.content .box form .desc input[type="date"]::-webkit-calendar-picker-indicator,
.content .box form .desc input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 95px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

@media (max-width: 991.98px) {
  .content .box form .desc select.big {
    width: 130px;
  }
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box.customer-details .desc {
  margin-right: 5%;
}

.content .box table {
  width: 94%;
  margin: 10px auto;
  background: #fff;
}

.content .box table thead th {
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table thead th:last-of-type {
  padding-right: 15px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0;
  bottom: 0;
}

.content .box table tbody tr:last-of-type:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td input.small {
  width: 31px;
}

.content .box table tbody tr td:not(:last-of-type):after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box table tbody tr td:nth-of-type(1) input,
.content .box table tbody tr td:nth-of-type(2) input {
  margin-left: 5px;
  text-align: left;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

@media (max-width: 991.98px) {
  .content .box textarea {
    width: 90%;
  }
}

.content .box textarea:focus {
  border: none;
  outline: none;
}

.content .box.others-details {
  min-height: 320px;
}

.content .box.others-details form {
  padding: 30px 20px 0;
}

.content .box.price {
  padding: 30px 20px;
}

.content .box.price ul {
  margin: 0;
}

.content .box.price ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box.price ul li .border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 10px;
  margin-top: 2px;
}

.content .box.price ul li:not(:first-of-type) {
  margin-top: 10px;
}

.content .box.price ul li:last-of-type {
  margin-top: 0;
}

.content .box.price ul li:last-of-type span:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box.price ul li:last-of-type span:last-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:first-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:last-of-type {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .box.price ul {
  min-width: 162px;
}

.content .send {
  margin: 15px 0 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .send label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .send select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .send select:focus {
  outline: none;
}

.content .send button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .send button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.content .buttons button,
.content .buttons a {
  border-radius: 4px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f6f8fc;
  margin: 5px 0;
  padding: 0.6rem 1.1rem;
}

.content .buttons button:not(:first-child),
.content .buttons a:not(:first-child) {
  margin-left: 10px;
}

.content .buttons button:nth-child(1),
.content .buttons a:nth-child(1) {
  color: #64748b;
  border: 1px solid #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(1):hover,
.content .buttons a:nth-child(1):hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons button:nth-child(2),
.content .buttons a:nth-child(2) {
  background: #27ae60;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(2):hover,
.content .buttons a:nth-child(2):hover {
  background-color: #1f8c4d;
  color: #fff;
}

.content .buttons button:nth-child(3),
.content .buttons a:nth-child(3) {
  background: #2f80ed;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(3):hover,
.content .buttons a:nth-child(3):hover {
  background-color: #296fcc;
  color: #fff;
}

.content .vertical-desc .desc {
  margin-bottom: 17px;
}

.input-select {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  text-indent: 5px;
}
</style>

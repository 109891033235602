<template>
  <div class="col responsive-width bg-light" style="min-height: 100vh">
    <div class="content" style="margin-top: 10px">
      <div class="container-fluid">
        <div class="d-flex justify-content-between w-100">
          <h3 class="mb-4">{{ $t("frequently_asked_questions") }}</h3>
          <form @submit.prevent="searchFAQ" class="search w-40">
            <label for="search">
              <svg
                width="15"
                height="15"
                viewbox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6219 13.5596L10.0568 8.99453C10.7652 8.07871 11.1484 6.95898 11.1484 5.78125C11.1484 4.37148 10.5982 3.04961 9.60332 2.05293C8.6084 1.05625 7.28301 0.507812 5.875 0.507812C4.46699 0.507812 3.1416 1.05801 2.14668 2.05293C1.15 3.04785 0.601562 4.37148 0.601562 5.78125C0.601562 7.18926 1.15176 8.51465 2.14668 9.50957C3.1416 10.5063 4.46523 11.0547 5.875 11.0547C7.05273 11.0547 8.1707 10.6715 9.08652 9.96484L13.6516 14.5281C13.6649 14.5415 13.6808 14.5521 13.6983 14.5594C13.7158 14.5666 13.7346 14.5704 13.7535 14.5704C13.7725 14.5704 13.7912 14.5666 13.8087 14.5594C13.8262 14.5521 13.8421 14.5415 13.8555 14.5281L14.6219 13.7635C14.6353 13.7501 14.6459 13.7342 14.6531 13.7167C14.6604 13.6992 14.6641 13.6805 14.6641 13.6615C14.6641 13.6426 14.6604 13.6238 14.6531 13.6063C14.6459 13.5889 14.6353 13.573 14.6219 13.5596ZM8.65938 8.56563C7.91406 9.30918 6.92617 9.71875 5.875 9.71875C4.82383 9.71875 3.83594 9.30918 3.09063 8.56563C2.34707 7.82031 1.9375 6.83242 1.9375 5.78125C1.9375 4.73008 2.34707 3.74043 3.09063 2.99688C3.83594 2.25332 4.82383 1.84375 5.875 1.84375C6.92617 1.84375 7.91582 2.25156 8.65938 2.99688C9.40293 3.74219 9.8125 4.73008 9.8125 5.78125C9.8125 6.83242 9.40293 7.82207 8.65938 8.56563Z"
                  fill="#8F8E94"
                ></path>
              </svg>
            </label>
            <input
              v-model="searchQuery"
              id="search"
              class="w-40"
              type="text"
              :placeholder="$t('search')"
            />
          </form>
        </div>
        <shimmer-loading v-if="!faqs && !categories" :num="6"></shimmer-loading>
        <div v-else>
          <div
            v-if="categories"
            class="headers d-flex flex-wrap justify-content-start"
          >
            <div class="stats">
              <ul
                class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center"
              >
                <li :class="selectedCategory == 'all' ? 'active' : ''">
                  <a @click="switchTab('all')" href="javascript:void(0);">
                    {{ $t("all") }}
                  </a>
                </li>
                <li
                  v-for="(cat, i) in categories"
                  :key="'cat-' + i"
                  :class="selectedCategory == cat ? 'active' : ''"
                >
                  <a @click="switchTab(cat)" href="javascript:void(0);">
                    {{ locale == "en" ? cat.eng_category : cat.swe_category }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="faqs" class="accordion">
            <div
              v-for="(faq, index) in faqs.results"
              :key="index"
              class="accordion-item"
            >
              <button
                :id="'accordion-button-' + (index + 1)"
                aria-expanded="false"
              >
                <span class="accordion-title">{{
                  locale === "en" ? faq.eng_question : faq.swe_question
                }}</span>
                <span class="icon" aria-hidden="true"></span>
              </button>
              <div class="accordion-content">
                <p>
                  {{ locale === "en" ? faq.eng_answer : faq.swe_answer }}
                </p>
              </div>
            </div>
          </div>
          <shimmer-loading v-else :num="6"></shimmer-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShimmerLoading from "./reusableComponents/ShimmerLoading.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ShimmerLoading,
  },

  data() {
    return {
      faqs: null,
      categories: null,
      selectedCategory: "all",
      searchQuery: "",
    };
  },

  computed: {
    ...mapGetters({
      locale: "locale/GET_LOCALE",
    }),
  },

  async created() {
    const categoryRes = await this.$store.dispatch("clients/getFAQCategories");
    this.categories = categoryRes.data.results;
    const res = await this.$store.dispatch("clients/getFAQs");
    this.faqs = res.data;
    const interval = setInterval(() => {
      const items = document.querySelectorAll(".accordion button");
      if (items.length > 0) {
        clearInterval(interval);
        this.mountAccordion();
      }
    }, 5);
  },

  methods: {
    async searchFAQ() {
      this.faqs = null;
      this.$store
        .dispatch("clients/getFAQs", { search: this.searchQuery })
        .then((res) => {
          this.faqs = res.data;
          const interval = setInterval(() => {
            const items = document.querySelectorAll(".accordion button");
            if (items.length > 0) {
              clearInterval(interval);
              this.mountAccordion();
            }
          }, 5);
          this.searchQuery = "";
        });
    },

    switchTab(cat) {
      this.selectedCategory = cat;
      this.faqs = null;
      this.$store.dispatch("clients/getFAQs", { category: cat }).then((res) => {
        this.faqs = res.data;
        const interval = setInterval(() => {
          const items = document.querySelectorAll(".accordion button");
          if (items.length > 0) {
            clearInterval(interval);
            this.mountAccordion();
          }
        }, 5);
      });
    },
    mountAccordion() {
      const items = document.querySelectorAll(".accordion button");
      function toggleAccordion() {
        const itemToggle = this.getAttribute("aria-expanded");
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false");
        }
        if (itemToggle == "false") {
          this.setAttribute("aria-expanded", "true");
        }
      }

      items.forEach((item) => item.addEventListener("click", toggleAccordion));
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Hind:300,400&display=swap");

* {
  box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  padding: 4rem;
  width: 48rem;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
  background: transparent !important;
  border: none;
}

.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid black;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #7288a2;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: black;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: black;
  border: 1px solid black;
}

.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border-radius: 22px;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded="true"] {
  color: #000;
}
.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 20em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}

.headers {
  margin: 5px 0 5px;
}

.headers h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #333269;
}

.headers h1 ~ h6 {
  font-size: 14px;
  line-height: 20px;
  color: #333269;
  font-weight: 400;
}

.stats ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 6px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.stats ul li.active {
  pointer-events: none;
}

.stats ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.stats ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.search {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 298.73px;
}

.search label {
  position: absolute;
  left: 0.5rem;
}

.search input {
  border: none;
  width: 77%;
  margin-left: auto;
  font-size: 12px;
}

.search input:focus {
  outline: none;
}
</style>

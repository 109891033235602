import moment from 'moment'

export const invoiceCreate = {
    customer_number: '',
    house_work : true,
    edi_information: {
        edi_global_location_number: '',
        edi_global_location_number_delivery: '',
        edi_invoice_extra1: '',
        edi_invoice_extra2: '',
        edi_our_electronic_reference: '',
        edi_your_electronic_reference: '',
        edi_status: ''
    },
    email_information: {
        email_address_from: '',
        email_address_to: '',
        email_address_cc: '',
        email_address_bcc: '',
        email_subject: '',
        email_body: ''
    },
    labels: [
        {
        label_id: 0
        }
    ],
    invoice_rows: [],
    accounting_method: 'ACCRUAL',
    address1: '',
    address2: '',
    administration_fee: 0,
    city: '',
    comments: '',
    cost_center: '',
    country: '',
    currency: 'SEK',
    credit : false,
    customer_name: '',
    delivery_address1: '',
    delivery_address2: '',
    delivery_city: '',
    delivery_country: '',
    delivery_date: '',
    delivery_name: '',
    delivery_zip_code: '',
    document_number: '',
    due_date: moment().format('YYYY-MM-DD'),
    eu_quarterly_report: true,
    external_invoice_reference1: '',
    external_invoice_reference2: '',
    final_pay_date: '',
    freight: 0,
    invoice_date: moment().format('YYYY-MM-DD'),
    invoice_reference: '',
    invoice_type: 'INVOICE',
    language: '',
    not_completed: true,
    our_reference: '',
    outbound_date: '',
    payment_way: '',
    phone1: '',
    phone2: '',
    price_list: '',
    print_template: '',
    project: '',
    remarks: '',
    tax_reduction_type: '',
    terms_of_delivery: '',
    terms_of_payment: '',
    time_basis_reference: '',
    total_to_pay: 0,
    vat_included: true,
    warehouse_ready: true,
    way_of_delivery: '',
    your_order_number: '',
    your_reference: '',
    zip_code: ''
}
export const invoiceRow = {
    account_number: '',
    article_number: '',
    contribution_percent: '',
    contribution_value: '',
    cost_center: '',
    delivered_quantity: 1,
    description: '',
    discount: 0,
    discount_type: 'AMOUNT',
    house_work: true,
    house_work_hours_to_report: 0,
    house_work_type: '',
    price: 0,
    project: '',
    total: 0,
    total_excluding_vat: 0,
    unit: '',
    vat: 25,
    stock_point_code: ''
}

export default { invoiceCreate, invoiceRow }

<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content" v-if="GET_SINGLE_CUSTOMER_SEGMENT">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t('customers') }}</h1>
          <h6>{{ $t('edit_segment') }} {{ GET_SINGLE_CUSTOMER_SEGMENT.name }}</h6>
        </div>
        <div class="box segment-deltails">
          <div class="box-header flex-wrap">
            <h3>{{ $t('segment_details') }}</h3>
            <router-link class="my-2 my-sm-0"
                         :to="{ name : 'editCustomerSegmentSettings', params : { id : $route.params.id }}">{{
                $t('see_all_segment_setting')
              }}
            </router-link>
          </div>
          <form class="row">
            <div class="col-12 d-flex justify-content-lg-between flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t('name') }}</label>
                <input type="text" v-model="GET_SINGLE_CUSTOMER_SEGMENT.name">
              </div>
              <div class="desc">
                <label>{{ $t('sales_account') }}</label>

                <input class="small" @input="searchField($event, 'sales')" type="text"
                       v-model="pseudo_sales_account">
                <div v-if="showSalesAccount" style="z-index:9999999; width:12%"
                     class="position-absolute bg-white rounded shadow-md border mt-2">
                  <ul style="padding-left: 0rem !important; list-style-type:none">
                    <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                        :key="item.id" @click="chooseSales(item, 'sales')">{{ item.number + ' ' + item.description }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('price_list') }}</label>
                <select class="small" v-model="price_list">
                  <option value=""></option>
                  <option :value="item.id" v-for="item in pricelists" :key="item.id">{{ item.description }}</option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t('currency') }}</label>
                <select class="small" v-model="currency">
                  <option value=""></option>
                  <option :value="item.id" v-for="item in currencies" :key="item.id">{{ item.description }}</option>
                </select>
              </div>
            </div>
            <div class="col-12 d-sm-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t('description') }}</label>
                <textarea v-model="GET_SINGLE_CUSTOMER_SEGMENT.description"></textarea>
              </div>
              <div class="desc">
                <label>{{ $t('note') }}</label>
                <textarea v-model="GET_SINGLE_CUSTOMER_SEGMENT.notes"></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="box customer-deltails">
          <div class="box-header flex-wrap">
            <h3>{{ $t('added_customers') }}</h3>
            <router-link class="my-2 my-sm-0"
                         :to="{ name : 'customerSegmentAddCustomer', params: {id: $route.params.id}}">
              {{ $t('add_customer_to_segment') }}
            </router-link>
          </div>
          <div class="box-responsive">
            <table>
              <thead>
              <tr>
                <th>
                  <div class="checkbox">
                    <input type="checkbox">
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </th>
                <th><span>{{ $t('customer_number') }}</span></th>
                <th><span>{{ $t('name') }}</span></th>
                <th><span>{{ $t('organization_number') }}</span></th>
                <th><span>{{ $t('type') }}</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in GET_SINGLE_CUSTOMER_SEGMENT.customers" :key="key">
                <td>
                  <div class="checkbox">
                    <input type="checkbox" v-model="item.checked" v-on:change="checkboxOrder(item.id)"
                           :value="item.checked">
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </td>
                <td>{{ item.customer_number }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.organisation_number }}</td>
                <td>{{ item.type }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="buttons flex-wrap-reverse align-items-center">
          <button type="button" v-on:click="removeCustomerSegment" style="background: #fb4b4b !important">
            <span v-if="processing">{{ $t('processing') }}</span>
            <span v-else>{{ $t('remove_from_segment') }}</span>
          </button>
          <div class="right flex-wrap my-2 my-sm-0"><a href='javascript:void(0);'
                                                       @click='$router.go(-1);'>{{ $t('cancel') }}</a>
            <button class="my-2 my-sm-0" type="button" v-on:click="updateCustomerSegment">{{
                $t('update_segment')
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAlertVue v-if="popUpDelete" @handleClose="popUpDelete = false" :close="!true">
    <h4>{{ $t('delete_confirmation_text') }} <span class="user-confirm text-danger">{{ $t('customer_segment').toLowerCase() }}</span>?</h4>
    <div class="popup-bottons">
      <button @click="popUpDelete = false">{{ $t('cancel_delete_request') }}</button>
      <button :disabled='deleting' @click="deleteCustomerSegment">
        <span v-if="deleting">{{ $t('deleting') }}...</span>
        <span v-else>{{ $t('accept_delete_request') }}</span>
      </button>
    </div>
  </ModalAlertVue>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from "lodash.debounce";
import ModalAlertVue from '../../components/ModalAlert.vue'

export default {
  name: 'EditCustomerSegment',
  components: {ModalAlertVue},
  data() {
    return {
      showSalesAccount: false,
      addingCustomer: false,
      deleting: false,
      customerSegmentArr: [],
      customerSegmentToRemoveArr: [],
      customers: [],
      accounts: [],
      accountList: [],
      costCenter: [],
      currencies: [],
      pricelists: [],
      projects: [],
      updating: false,
      processing: false,
      popUpDelete: false,
      sales_account: '',
      pseudo_sales_account: '',
      segment_id: '',
      project: '',
      cost_center: '',
      price_list: '',
      currency: '',
    }
  },
  computed: {
    ...mapGetters({
      GET_SINGLE_CUSTOMER_SEGMENT: 'clients/GET_SINGLE_CUSTOMER_SEGMENT',
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  },
  mounted() {
    const {id} = this.$route.params
    this.getSingleCustomerSegment(id)
    // this.getAccounts()
    this.getCustomers()
    this.getPriceList()
    this.getCurrency()
    let self = this
    setTimeout(function () {
      if (self.GET_SINGLE_CUSTOMER_SEGMENT) {
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.sales_account) {
          self.pseudo_sales_account = self.GET_SINGLE_CUSTOMER_SEGMENT.sales_account.number
          self.sales_account = self.GET_SINGLE_CUSTOMER_SEGMENT.sales_account.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.cost_center) {
          self.cost_center = self.GET_SINGLE_CUSTOMER_SEGMENT.cost_center.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.project) {
          self.project = self.GET_SINGLE_CUSTOMER_SEGMENT.project.id
        }
      }
    }, 1000)

  },
  methods: {
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'pricelists'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.pricelists = res.data.results
          })
          .catch(err => {
          })
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'currencies'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.currencies = res.data.results
          })
          .catch(err => {
          })
    },
    updateCustomerSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.updating = true
      let data_to_save = {
        description: this.GET_SINGLE_CUSTOMER_SEGMENT.description,
        note: this.GET_SINGLE_CUSTOMER_SEGMENT.notes,
        name: this.GET_SINGLE_CUSTOMER_SEGMENT.name,
        sales_account: this.sales_account,
        currency: this.currency,
        price_list: this.price_list
      }
      for (const [key, value] of Object.entries(data_to_save)) {
        if (value === null || value === '' || value === undefined) {
          delete data_to_save[key]
        }
      }
      const payload = {
        data: data_to_save,
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.GET_SINGLE_CUSTOMER_SEGMENT.id}/`
      }
      this.$store.dispatch('clients/customPutRequest', payload)
          .then(res => {
            this.updating = false
            // this.$services.helpers.notification('Customer segment updated successfully', 'success', this)
            this.$toast.success(this.$t('segment_updated'));

          })
          .catch(err => {
            this.updating = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    removeCustomerSegment() {
      if (this.customerSegmentToRemoveArr.length > 0) {
        let payload = {
          'data': {
            'customers': this.customerSegmentToRemoveArr
          },
          'URL': this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.GET_SINGLE_CUSTOMER_SEGMENT.id}/remove_customers/`
        }
        this.processing = true
        this.$store.dispatch('clients/customPutRequest', payload).then(_ => {
          this.customerSegmentToRemoveArr = []
          this.getSingleCustomerSegment(this.$route.params.id)
          // this.$services.helpers.notification('Customers removed successfully', 'success', this)
          this.$toast.success(this.$t('customer_removed_segment'));
          this.processing = false
        }).catch(err => {
          this.processing = false
          this.$services.helpers.notification(err.response.data.message, 'success', this)
        })
      } else {
        this.$services.helpers.notification('Vänligen markera en eller flera kunder', 'error', this)
      }

    },
    deleteCustomerSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.deleting = true
      const payload = {
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.GET_SINGLE_CUSTOMER_SEGMENT.id}/`
      }
      this.$store.dispatch('clients/customDeleteRequest', payload)
          .then(res => {
            this.deleting = false
            // this.$services.helpers.notification('Customer segment deleted successfully', 'success', this)
            this.$toast.success(this.$t('segment_removed'));
            this.$router.go(-1)
          })
          .catch(err => {
            this.deleting = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'costcenters'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.costCenter = res.data.results
          })
          .catch(err => {
          })
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'projects'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.projects = res.data.results
          })
          .catch(err => {
          })
    },
    getAccounts() {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accountList = res.data.results
          })
          .catch(err => {

          })
    },
    chooseSales(item, mode) {
      this.pseudo_sales_account = item.number
      this.sales_account = item.id
      this.showSalesAccount = false
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    getSingleCustomerSegment(data) {
      this.$store.dispatch('clients/getSingleCustomerSegment', data).then(resp => {
        if (resp.data.currency) {
          this.currency = resp.data.currency.id
        }
        if (resp.data.price_list) {
          this.price_list = resp.data.price_list.id
        }

      })
    },
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            if (mode === 'sales') {
              this.showSalesAccount = true
            }
          })
          .catch(err => {

          })
    },
    getCustomers() {
      this.$store.dispatch('clients/getCustomers', 'customize=no').then(resp => {
        this.customers = resp.data
        this.customers.data.map(item => {
          item.checked = false
        })
      })
    },
    changePageNumber(number) {
      let payload = ''
      if (this.filter) {
        payload = `page=${number}&${this.filter}`
      } else {
        payload = `page=${number}&customize=no`
      }
      this.$store.commit('clients/UPDATE_CUSTOMERS', [])
      this.$store.dispatch('clients/getCustomers', payload).then(resp => {
        this.customers = resp.data
        this.customers.data.map(item => {
          if (this.customerSegmentArr.includes(item.id)) {
            item.checked = true
          } else {
            item.checked = false
          }

        })
      })
    },
    checkboxOrder(id) {
      if (this.customerSegmentToRemoveArr.includes(id)) {
        let indexID = this.customerSegmentToRemoveArr.findIndex(item => item === id)
        if (indexID !== -1) {
          this.customerSegmentToRemoveArr.splice(indexID, 1)
        }
      } else {
        this.customerSegmentToRemoveArr.push(id)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.segment-deltails .box-header {
  background-image: url("./../../assets/images/table/segment.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.customer-deltails .box-header {
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box .box-header {
  background: #5855d6;
  padding: 30px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box .box-header h3 {
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  margin: 0;
}

.content .box .box-header a {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  color: #5855d6;
  background: #fffdfd;
  padding: 12px 16px;
  border: 1px solid #fffdfdee;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box .box-header a:hover {
  color: #4946ae;
  background-color: #fbfafb;
}

.content .box form {
  padding: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content .box form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .content .box form .desc:not(:last-of-type) {
    margin-right: 5%;
  }
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 575.98px) {
  .content .box form .desc input {
    width: 90%;
  }
  .content .box form .desc select {
    width: 90%;
  }
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

@media (max-width: 575.98px) {
  .content .box form .desc textarea {
    width: 90%;
  }
}

.content .box form .desc textarea:focus {
  border: none;
  outline: none;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 5px 0;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td:nth-of-type(8).good {
  color: #419e6a;
}

.content .box table tbody td:nth-of-type(8).bad {
  color: #fb4b4b;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  color: #fff;
  background-color: #de4343;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button:last-of-type {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>
<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.segment-details h3 {
  background-image: url("./../../assets/images/table/segment.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box.e-document h3 {
  background-image: url("./../../assets/images/table/e-document.png");
  background-repeat: no-repeat;
  background-position: center right;
}

@media (max-width: 575.98px) {
  .content .box.e-document .d-flex {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content .box.e-document .left {
    margin-right: 0;
  }
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  margin: 0;
}

.content .box .form {
  padding: 30px;
}

@media (min-width: 992px) {
  .content .box .form {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 991.98px) {
  .content .box .form.segment-details .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.content .box .form h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

@media (max-width: 991.98px) {
  .content .box .form h4 {
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.content .box .form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 5px;
}

@media (min-width: 992px) {
  .content .box .form .desc {
    margin-bottom: 20px;
  }
}

.content .box .form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box .form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 195px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box .form .desc select.small {
  width: 94px;
}

.content .box .form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .content .box .form .desc input {
    width: 90%;
  }
}

.content .box .form .desc input.small {
  width: 94px;
}

.content .box .form .desc input:focus {
  outline: none;
}

.content .box .form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box .form .desc .radio input {
  display: none;
}

.content .box .form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box .form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 12.469px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box .form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box .form textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

@media (max-width: 575.98px) {
  .content .box .form textarea {
    width: 100%;
    min-width: 150px;
  }
}

.content .box .form textarea:focus {
  border: none;
  outline: none;
}

.content .box h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box .left {
  margin-right: 40px;
}

@media (min-width: 992px) {
  .content .box .parent-small.more-margin .desc:not(:first-of-type) {
    margin-left: 30px;
  }
}

.content .box:last-of-type {
  margin-bottom: 20px;
}

.content .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 18px;
  width: 18px;
  z-index: 33;
  background: #fffbfb;
}

.content .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .checkbox input,
.content .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .checkbox input:checked ~ svg {
  display: block;
}

.content .checkbox svg {
  position: absolute;
  height: 20px;
  width: 20px;
  display: none;
  z-index: 1;
}

.content .checkbox ~ p {
  margin: 0;
  margin-left: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-top: 20px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: end;
  -webkit-justify-content: space-between;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: space-between;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
  text-align: center;
}

.content .buttons a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

.content .buttons a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content nav {
  margin: 20px 0 0 5px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.content nav .right-parent .filter {
  margin-right: 20px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 136.73px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 77%;
  margin-left: auto;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .customize {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .customize {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.content nav .right-parent .customize a {
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content nav .right-parent .customize a svg {
  margin-right: 10px;
}

.content nav .right-parent .customize a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content nav .right-parent .customize a:hover {
  color: #4946ae;
}

.content nav .right-parent .customize a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2), 0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2), 0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #fffbfb;
  cursor: pointer;
  z-index: 33;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}
.content .box table td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 5px 0;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td:nth-of-type(8).good {
  color: #419e6a;
}

.content .box table tbody td:nth-of-type(8).bad {
  color: #fb4b4b;
}
</style>
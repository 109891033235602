<template>
  <td>
    <a>{{ data.company }} </a>
  </td>
  <td>
    <a>{{ data.amount_due }}</a>
  </td>
  <td>
    <a>{{ data.date }}</a>
  </td>
  <td>
    <a>{{ data.due_date }}</a>
  </td>
  <td>
    <a>{{ data.status }}</a>
  </td>
  <!-- UNCOMMENT THIS TO LET USER DOWNLOAD INVOICE -->
  <td>
    <span class="cursor-pointer" @click="getDownloadedFile(data)">
      <img src="./../../../assets/images/icon-utility/download.svg" alt="" />
    </span>
  </td>
</template>

<script>
import FileSaver from "file-saver";

export default {
  name: "BillingTable",
  props: {
    data: Object,
  },
  data() {
    return {
      downloading: false,
    };
  },
  methods: {
    getDownloadedFile(data) {
      this.downloading = true;
      const payload = {
        URL:
          this.$services.endpoints.BILLING_ENDPOINTS + `${data.id}/download/`,
      };
      this.$store
        .dispatch("clients/downloadFile", payload)
        .then((res) => {
          const fileName = data.id + ".pdf";
          FileSaver.saveAs(res.data, fileName);
          this.downloading = false;
        })
        .catch((err) => {
          this.downloading = false;
          // this.$services.helpers.notification(
          //   err.response.data.message,
          //   "error",
          //   this
          // );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table thead {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: nowrap;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  white-space: nowrap;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .box table tbody tr.booked td a {
  color: #4d82f3;
}

.content .box table tbody tr.booked td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.inactive td a {
  color: #efb008;
}

.content .box table tbody tr.inactive td:not(:last-of-type) path {
  fill: #64748b;
}

.content .box table tbody tr.terminate td a {
  color: #fb4b4b;
}

.content .box table tbody tr.terminate td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.active td a {
  color: #419e6a;
}

.content .box table tbody tr.active td button:nth-child(1),
.content .box table tbody tr.active td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.voided td a {
  color: #64748b;
}

.content .box table tbody tr.voided td:not(:last-of-type) path {
  fill: #64748b;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 575.98px) {
  .content .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content .actions * {
    margin: 5px !important;
  }
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #ccc;
  border-width: 1px;
}
</style>

<template>
  <div class="col responsive-width">
    <div class="content">
      <div class="container-fluid">
        <div class="headers d-flex flex-wrap justify-content-between">
          <div>
            <router-link class="link-click" :to="{ name : 'configurations' }">
              <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z"
                    fill="#5855D6"></path>
              </svg>
              {{ $t('go_back_to_configuration') }}
            </router-link>
            <h1 class="mb-0">{{ $t('configuration') }}</h1>
          </div>
        </div>
        <div class="box p-4 mb-5" v-if="guideInfo.length > 0">
          <div class="d-flex justify-content-between flex-wrap">
            <div><h5 class="header">{{ $t('contract_configuration_page') }}</h5></div>
          </div>
          <div v-if="guideInfo[0].image">
            <img :src="baseURL + guideInfo[0].image" alt="">
          </div>
           <div class="mt-3 fs-7" v-html="swedishInfo[0].text" v-if="GET_LOCAL === 'se'">
          </div>
           <div class="mt-3 fs-7" v-html="englishInfo[0].text" v-if="GET_LOCAL === 'en'">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ConfigContractGuide',
  data() {
    return {
      tab: 1,
      baseURL: process.env.VUE_APP_BASE_URL,
      guideInfo: [],
      swedishInfo: [],
      englishInfo: []
    }
  },
  mounted() {
    this.getGuides()
  },
  methods: {
    getGuides() {
      this.$store.dispatch('clients/getGuide')
          .then(res => {
            if (res.data.data) {
              const filter = res.data.data.filter(el => el.guide_type === 'contract_config')
              if (filter.length > 0) {
                this.swedishInfo = filter.filter(el => el.language === 'swe')
                this.englishInfo = filter.filter(el => el.language === 'eng')
                this.guideInfo = filter
              }
            }
          })
    }
  },
  computed: {
    ...mapGetters({
      GET_LOCAL: 'locale/GET_LOCALE'
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .configuration-showcase ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.content .configuration-showcase ul li.active {
  pointer-events: none;
}

.content .configuration-showcase ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.content .configuration-showcase ul li:first-of-type a {
  margin-right: 10px;
  padding-right: 10px;
}

.content .configuration-showcase ul li:last-of-type a {
  margin-left: 10px;
  padding-left: 10px;
}

.content .configuration-showcase ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.content .box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content div .link-click ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  cursor: pointer;
}

.link-click {
  color: #5855d6;
  text-decoration: none;
}

.content div ~ a:hover {
  color: #4946ae;
  border-color: #4946ae;
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px 0px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .header, .fs-8 {
  color: #27364B;
}

.content > div > a svg {
  margin-top: -2px;
}

.content > div > a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content > div > a:hover {
  color: #4946ae;
}

.content > div > a:hover svg path {
  fill: #4946ae;
}

.content .header div ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  cursor: pointer;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <authorization-banner/>
        <h1>{{ $t('welcome_back') }} <span v-if="USER.full_name">{{ USER.full_name.split(' ')[0] }}</span> &#128075;
        </h1>
        <div class="row g-0">
          <div class="col-lg-9 pe-lg-3">
            <div class="row g-3">
              <div class="col-md-6 col-12">
                <div class="box">
                  <h2>{{ $t('billed_since') }} {{ $services.moment().startOf('month').format('YYYY-MM-DD') }}</h2>
                  <shimmer-loading :num="5" v-if="showBilledGraph === false"/>
                  <apexchart v-else type="line" :options="billGraph.options"
                             :series="billGraph.series"></apexchart>
                  <!-- <apexchart v-else width="400" type="line" :options="billGraph.options"
                             :series="billGraph.series"></apexchart> -->
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="box">
                  <h2>{{ $t('received_payment_since') }} {{
                      $services.moment().startOf('month').format('YYYY-MM-DD')
                    }}</h2>
                  <shimmer-loading :num="5" v-if="showPaymentGraph === false"/>
                  <apexchart v-else type="line" :options="paymentGraph.options"
                             :series="paymentGraph.series"></apexchart>
                  <!-- <apexchart v-else width="400" type="line" :options="paymentGraph.options"
                             :series="paymentGraph.series"></apexchart> -->
                  <!-- <img src="./../../assets/images/charts/homepage/billed-since.png" alt=""/> -->
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="box-responsive">
                  <div class="box contracts">
                    <h2>{{ $t('contract_close_to_ending') }}</h2>
                    <table>
                      <thead>
                      <tr>
                        <th>{{ $t('contract') }}</th>
                        <th>{{ $t('end_date') }}</th>
                        <th>{{ $t('invoice_left') }}</th>
                      </tr>
                      </thead>
                      <tbody v-if="contractClosing.length > 0">
                      <tr v-for="item in contractClosing.slice(0, 5)" :key="item.id">
                        <td>
                          <router-link
                              :to="{ name : 'editContract', params : { id : item.id, num : item.document_number ? item.document_number : 1 }}">
                            {{ item.document_number ? item.document_number : 'x' }}
                          </router-link>
                        </td>
                        <td>
                          <router-link
                              :to="{ name : 'editContract', params : { id : item.id, num : item.document_number ? item.document_number : 1 }}">
                            {{ item.period_end }}
                          </router-link>
                        </td>
                        <td>
                          <router-link
                              :to="{ name : 'editContract', params : { id : item.id, num : item.document_number ? item.document_number : 1 }}">
                            {{ item.invoices_remaining ? item.invoices_remaining : $t('not_available') }}
                          </router-link>
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                      <tr>
                        <td class="text-center" colspan="3">
                          <empty-component :is_icon="true"
                                           :title="$t('no_latest_contract_ending')"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <router-link :to="{ name : 'contracts' }">{{ $t('see_all_contract') }}</router-link>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-12">
                <div class="box-responsive">
                  <div class="box latest">
                    <h2>{{ $t('latest_event') }}</h2>
                    <table>
                      <thead>
                      <tr>
                        <th>{{ $t('type') }}</th>
                        <th>{{ $t('event') }}</th>
                        <th width="100">{{ $t('occurred_at') }}</th>
                      </tr>
                      </thead>
                      <tbody v-if="EVENT.length > 0">
                      <tr v-for="event in EVENT.slice(0, 5)" :key="event.id">
                        <td>
                          <a href="javascript:void(0);">
                            <span class="success"
                                  v-if="event.event === 'SUCCESS'">{{ ($t('event_success')).toUpperCase() }}</span>
                            <span class="update" v-if="event.event === 'INFO'">{{
                                ($t('event_info_short')).toUpperCase()
                              }}</span>
                            <span class="error"
                                  v-if="event.event === 'ERROR'">{{ ($t('event_error_short')).toUpperCase() }}</span>
                          </a>
                        </td>
                        <td>
                          <a href="javascript:void(0);">
                            <h3 v-if="GET_LOCALE === 'en'">{{ event.eng_subject }}</h3>
                            <h3 v-else>{{ event.swe_subject }}</h3>

                            <p v-if="GET_LOCALE === 'en'">{{ event.eng_message }}</p>
                            <p v-else>{{ event.swe_message }}</p>
                          </a>
                        </td>
                        <td class="date">
                          <a href="javascript:void(0);">
                            <span>{{
                                event.received
                              }}</span>
                          </a>
                        </td>
                      </tr>

                      </tbody>
                      <tbody v-else>
                      <tr>
                        <td class="text-center" colspan="3">
                          <empty-component :is_icon="true"
                                           :title="$t('no_latest_event')"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <router-link :to="{ name : 'eventlogs'}">{{ $t('see_all_events') }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mt-3 mt-lg-0">
            <div class="box-responsive">
              <div class="box alerts">
                <div class="header-group d-flex">
                  <img class="rounded-img-full" v-if="USER.image" :src="USER.image" alt="user image | likvio">
                  <img v-else src="./../../assets/images/profile-img/profile.png" alt="user image | likvio">
                  <!-- <img src="./../../assets/images/profile-img/alerts-profile.png"> -->
                  <h2>{{ $t('alerts') }}</h2>
                </div>
                <ul class="list-unstyled">
                  <li v-for="alert in ALERT.slice(1,15)" :key="alert.id">
                    <a href="javascript:void(0);">
                      <div class="left w-full">
                        <div class="d-flex justify-content-between">
                          <h3 v-if="GET_LOCALE === 'se'">{{ alert.alert_code.subject }}</h3>
                          <h3 v-if="GET_LOCALE === 'en'">{{ alert.alert_code.code }}</h3>
                          <small style="font-size:0.7rem">{{ $services.moment(alert.received).fromNow() }}</small>
                        </div>
                        <p v-if="GET_LOCALE === 'se'">{{ alert.swe_message.slice(0, 46) + '...' }}</p>
                        <p v-if="GET_LOCALE === 'en'">{{ alert.eng_message.slice(0, 46) + '...' }}</p>
                      </div>
                      <div class="right">
                        <span v-if="!alert.read">1</span>
                      </div>
                    </a>
                  </li>
                  <li class="text-xs" v-if="ALERT.length < 1">
                    <empty-component :is_icon="true" style="margin: auto;display: table"
                                     :title="$t('no_unarchived_alert')"/>
                  </li>
                </ul>
                <router-link :to="{ name : 'alertinbox' }"> {{ $t('see_all_alerts') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EmptyComponent from "./reusableComponents/EmptyComponent";
import ShimmerLoading from "./reusableComponents/ShimmerLoading";
import AuthorizationBanner from "./reusableComponents/AuthorizationBanner";
import moment from 'moment';

export default {
  name: 'Dashboard',
  components: {EmptyComponent, ShimmerLoading, AuthorizationBanner},
  data() {
    return {
      contractClosing: [],
      showBilledGraph: false,
      showPaymentGraph: false,
      billGraph: {
        options: {
          chart: {
            id: 'bill-graph'
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: {
              format: 'dd'
            }
          },
          legend: {
            show: true
          },
          // xaxis: {
          //   labels: {
          //     format: 'dd'
          //   }
          // },
          markers: {
            size: 5,
          },
          stroke: {
            curve: 'straight',
            width: 1,
          },
          // fill: {
          //   type: 'gradient',
          // },
        },
        series: [{
          name: 'billed',
          type: 'line',
          data: []
        }],
      },
      paymentGraph: {
        options: {
          chart: {
            id: 'payment-graph'
          },
          xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
          },
          // legend: {
          //   show: false
          // },
          // grid: {
          //   show: false
          // },
          // yaxis: {
          //   show: false
          // },
          markers: {
            size: [3, 6],
            strokeWidth: 2,
          },
          stroke: {
            curve: 'smooth',
            width: 1,
          },
          fill: {
            type: 'gradient',
          },
          noData: {
            text: this.$t('no_graph_data'),
            align: 'center',
            verticalAlign: 'middle',
            // offsetX: 0,
            // offsetY: 0,
            style: {
              color: 'black',
              fontSize: '15px',
              // fontFamily: undefined
            }
          }
        },
        series: [{
          name: 'payment',
          type: 'area',
          data: []
        }],
      }
    }
  },
  computed: {
    ...mapGetters({
      ALERT: 'clients/GET_NOTIFY_ALERT',
      EVENT: 'clients/GET_NOTIFY_EVENT',
      USER: 'clients/GET_CURRENT_USER',
      GET_LOCALE: 'locale/GET_LOCALE',
      GET_USER_SUBSCRIPTION: 'clients/GET_USER_SUBSCRIPTION',
      GET_TOKEN: 'auth/USER_TOKEN',
    })
  },
  mounted() {
    this.getContractClosing()
    this.getNotifyAlert()
    this.getNotifyEvent()
    this.getBilledGraph()
    this.getReceivedPaymentGraph()
    this.$store.dispatch('clients/getCurrentUserInfo')
    this.getUserSubscription()
  },
  methods: {
    getUserSubscription() {
      this.$store.dispatch('clients/getUserSubscription')
          .then(res => {
            this.getPackageInfo()
          })
          .catch(err => {
          })
    },
    getPackageInfo() {
      if (this.GET_USER_SUBSCRIPTION.length > 0) {
        if (!['free', 'Free', 'FREE'].includes(this.GET_USER_SUBSCRIPTION[0].current_package)) {
          const payload = {
            URL: this.$services.endpoints.PACKAGE_ENDPOINT + `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`
          }
          this.$store.dispatch('clients/getPlanDetails', payload).then(res => {
            if (!res.data.results[0].dashboard_page_access.includes(this.$route.name)) {
              window.Bus.emit('access-denied', 'dashboard')
            }
          })
        } else {
          window.Bus.emit('access-denied', 'dashboard')
        }

      } else {
        this.getUserSubscription()
      }

    },
    getNotifyAlert() {
      this.$store.dispatch('clients/getNotificationAlert')
          .then(res => {
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    getBilledGraph() {
      this.$store.dispatch('clients/getCustomRequest', {URL: this.$services.endpoints.STATS_ENDPOINT + 'basics/billed'})
          .then(res => {
            if (Object.keys(res.data.data)) {
              const x = Object.keys(res.data.data)
              const y = Object.values(res.data.data)
              const {xAxis, yAxis} = this.formatBilledGraphData(x, y)

              this.billGraph.options.xaxis.categories = xAxis
              this.billGraph.series[0].data = yAxis
            }
            this.showBilledGraph = true

          })
          .catch(err => {
            this.showBilledGraph = true
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    getReceivedPaymentGraph() {
      this.$store.dispatch('clients/getCustomRequest', {URL: this.$services.endpoints.STATS_ENDPOINT + 'basics/received'})
          .then(res => {
            if (Object.keys(res.data.data)) {
              const x = Object.keys(res.data.data)
              const y = Object.values(res.data.data)
              this.paymentGraph.options.xaxis.categories = x
              this.paymentGraph.series[0].data = y

            }
            this.showPaymentGraph = true
          })
          .catch(err => {
            // if there is any error or there are no payments, don't show the graph
            this.paymentGraph.options = {
              ...this.paymentGraph.options,
              legend: {
                show: false
              },
              grid: {
                show: false
              },
              yaxis: {
                show: false
              }
            }

            this.showPaymentGraph = true
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    getNotifyEvent() {
      this.$store.dispatch('clients/getNotificationEvent')
          .then(res => {
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    getContractClosing() {
      this.$store.dispatch('clients/getCustomRequest', {URL: this.$services.endpoints.CONTRACTS_ENDPOINT + '?invoices_remaining=1&customize=no'})
          .then(res => {
            this.contractClosing = res.data.data
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            }
          })
    },
    formatBilledGraphData(x, y) {
      let startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
      let currentDate = moment().format('YYYY-MM-DD')
      let days = moment().format('DD')

      let xAxis = []
      let yAxis = []
      // fill all the xAxis dates
      for (let i = 0; i < days; i++) {
        let dayOfMonth = moment(startOfMonth).add(i, 'days').format('YYYY-MM-DD')
        xAxis.push(dayOfMonth)
      }

      for (let i = 0; i < xAxis.length; i++) {
        if (x.includes(xAxis[i])) {
          let indexOfPoint = x.indexOf(xAxis[i])
          yAxis.push(y[indexOfPoint])
        } else {
          yAxis.push(0)
        }
      }

      return {
        xAxis,
        yAxis
      }
    }
  },
}
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
// * {
//   font-family: "Roboto", sans-serif;
//   -webkit-box-sizing: border-box;
//      -moz-box-sizing: border-box;
//           box-sizing: border-box;
// }

@-webkit-keyframes disable-pointer-events {
  0%, 99% {
    pointer-events: none;
  }
}

@keyframes disable-pointer-events {
  0%, 99% {
    pointer-events: none;
  }
}

.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content h1 {
  margin: 20px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .box {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 0;
  padding-bottom: 30px;
}

.content .box.contracts {
  min-width: 300px;
}

.content .box.latest {
  min-width: 400px;
}

.content .box h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #0e1566;
  padding: 5px 1rem 1rem;
  border-bottom: 1px solid #cbd4e199;
  margin-bottom: 0;
}

.content .box img {
  max-width: 100%;
  padding: 0 0.3rem;
  margin: 0 auto;
  display: block;
}

.content .box table {
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  text-align: center;
  margin: 15px 10px;
}

.content .box table thead th {
  padding: 10px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  background: #f1f4f9;
}

.content .box table thead th:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 20px;
}

.content .box table thead th:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-right: 20px;
}

.content .box table tbody {
  border-top: 15px solid #fff;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff;
  cursor: pointer;
}

.content .box table tbody tr:not(:last-of-type) {
  border-bottom: 1px solid rgba(149, 146, 166, 0.18);
}

.content .box table tbody tr td {
  font-size: 16px;
  line-height: 20px;
  color: #27364b;
}

.content .box table tbody tr td:nth-of-type(1) span {
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  width: 50px;
  height: 20px;
  border-radius: 2px;
  display: block;
  padding: 3px;
  text-align: center;
}

.content .box table tbody tr td:nth-of-type(1) span.success {
  background-color: #419e6a;
}

.content .box table tbody tr td:nth-of-type(1) span.update {
  background-color: #5855d6;
}

.content .box table tbody tr td:nth-of-type(1) span.error {
  background-color: #c41843;
}

.content .box table tbody tr a {
  text-decoration: none;
  display: block;
  color: #021442;
  padding: 10px 5px;
}

.content .box table tbody tr h3 {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #021442;
  margin: 0;
}

.content .box table tbody tr p {
  font-size: 12px;
  text-align: left;
  color: #9592a6;
  margin: 0;
}

.content .box table tbody tr .date span {
  background: #e7e7ff;
  display: block;
  font-size: 12px;
  line-height: 11px;
  color: #333269;
  text-align: center;
}

.content .box table tbody tr .date span:first-of-type {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  padding: 5px 10px 0;
}

.content .box table tbody tr .date span:last-of-type {
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  padding: 3px 10px;
}

.content .box > a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #5855d6;
  display: block;
  padding: 0 1rem;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.content .box > a:hover {
  color: #4946ae;
}

.content .alerts .header-group {
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #cbd4e199;
}

.content .alerts .header-group img {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
}

.content .alerts .header-group h2 {
  border-bottom: none;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
}

.content .alerts ul {
  padding-top: 15px;
}

.content .alerts ul li {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content .alerts ul li:hover {
  background-color: #f8fbff;
  cursor: pointer;
}

.content .alerts ul li > a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  text-decoration: none;

}

.content .alerts ul li:not(:last-of-type) {
  border-bottom: 1px solid rgba(149, 146, 166, 0.18);
}

.content .alerts ul li h3 {
  font-weight: 500;
  font-size: 14px;
  color: #333269;
  margin-bottom: 10px;
}

.content .alerts ul li p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #64748b;
  margin: 0;
}

.content .alerts ul li .right {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 1rem;
}

.content .alerts ul li .right span {
  font-size: 10px;
  line-height: 18px;
  display: block;
}

.content .alerts ul li .right span:first-of-type {
  color: #77838f;
  text-align: right;
  margin-bottom: 10px;
}

.content .alerts ul li .right span:last-of-type {
  color: #fff;
  background: #fb4b4b;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
}

.reauthorize {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 19px;
  margin-bottom: 19px;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <form @submit.prevent="cancelSubscription" class="content mt-5">
        <h1>{{ $services.helpers.capitalizeNames($t('cancel') )}} {{$t('subscription')}}</h1>
        <div class="box">
          <h3> {{$t('details')}}</h3>
          <div class="form-sec row">
            <p>{{ $t('cancel_subscription_text_1') }} {{ period_end_date}} {{ $t('cancel_subscription_text_2') }}. </p><span>{{$t('cancel_subscription_text_3')}}:</span>
            <div class="desc">
              <div class="checkbo">
                <input id="check1" v-model="cancelReason" type="checkbox" class="unset-box" value="No Finance">
                <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
                  <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
                </svg> -->
              </div>
              <label for="check1">{{ $t('cancel_reason_1') }}</label>
            </div>
            <div class="desc">
              <div class="checkbo">
                <input id="check2" v-model="cancelReason" type="checkbox" class="unset-box" value="Too Expensive">
                <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
                  <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
                </svg> -->
              </div>
              <label for="check2">{{ $t('cancel_reason_2') }}</label>
            </div>
            <div class="desc">
              <div class="checkbo">
                <input id="check3" v-model="cancelReason" type="checkbox" class="unset-box" value="Leaving Fortnox">
                <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
                  <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
                </svg> -->
              </div>
              <label for="check3">{{ $t('cancel_reason_3') }}</label>
            </div>
            <div class="desc">
              <div class="checkbo">
                <input id="check4" v-model="cancelReason" type="checkbox" class="unset-box" value="Other">
                <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
                  <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
                </svg> -->
              </div>
              <label class="me-2" for="check4">{{ $t('others') }}: </label>
              <input type="text" style="width: 230px !important" v-model="cancelText">
            </div>
          </div>
        </div>
        <button :disabled='processing' type="submit">
          <span v-if="processing">{{ $t('cancelling') }}...</span>
          <span v-else>{{$t('confirm_cancellation')}}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'BillingCancellation',
  data() {
    return {
      processing: false,
      cancelReason: [],
      cancelText: '',
      period_end_date:''
    }
  },
  computed: {
    ...mapGetters({
      GET_USER_SUBSCRIPTION: 'clients/GET_USER_SUBSCRIPTION'
    })
  },
  mounted() {
    this.getUserSubscription()
  },
  methods: {
    getUserSubscription() {
      this.$store.dispatch('clients/getUserSubscription')
          .then(res => {
          this.period_end_date =this.$services.moment(this.GET_USER_SUBSCRIPTION[0].period_end_date).format('YYYY-MM-DD')
          })
          .catch(err => {

          })
    },
    cancelSubscription() {
      if (this.cancelReason.length < 1) {
        // this.$services.helpers.notification('Please select a reason', 'error', this)
        this.$toast.warning(this.$t('select_reason'));
        
      } else if (this.cancelReason.includes('Other') && this.cancelText === '') {
        // this.$services.helpers.notification('Please input the reason text', 'error', this)
        this.$toast.warning(this.$t('write_reason'));
      } else {
        if (this.GET_USER_SUBSCRIPTION.length > 0) {
          this.processing = true
          const payload = {
            id: this.GET_USER_SUBSCRIPTION[0].uuid,
            cancellation_text: this.cancelText,
            reasons_for_cancellation: this.cancelReason
          }
          if (payload.cancellation_text === '') {
            delete payload.cancellation_text
          }
          this.$store.dispatch('clients/cancelSubscription', payload)
              .then(res => {
                this.processing = false
                this.$services.helpers.notification(res.data.message, 'success', this)
                this.$router.replace({name: 'planDetails'})
              })
              .catch(err => {
                this.processing = false
                this.$services.helpers.notification(err.response.data.message, 'error', this)
              })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 50px;
  background-color: #f6f8fc;
}

.content h1 {
  margin: 20px 0 50px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box h3 {
  background: #5855d6;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-top-left-radius: 5px;
  margin: 0;
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box .form-sec {
  padding: 30px;
  color: #000000;
}

.content .box .form-sec p {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 40px;
}

.content .box .form-sec span {
  display: block;
  color: #27364b;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 36px;
}

.content .box .form-sec .desc {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box .form-sec .desc .checkbox {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box .form-sec .desc .checkbox input {
  cursor: pointer;
  border: 1px solid #64748b;
  border-radius: 3.91229px;
  height: 22px;
  width: 22px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box .form-sec .desc .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box .form-sec .desc .checkbox input,
.content .box .form-sec .desc .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box .form-sec .desc .checkbox input:checked ~ svg {
  display: block;
}

.content .box .form-sec .desc .checkbox svg {
  position: absolute;
  height: 24px;
  width: 24px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box .form-sec .desc label {
  font-size: 16px;
  margin-left: 25px;
}

.content .box .form-sec .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 22px;
  text-indent: 10px;
  padding-right: 5px;
  font-size: 12px;
}

.content .box .form-sec .desc input.small {
  width: 91px;
}

.content .box .form-sec .desc input:focus {
  outline: none;
}

.content button {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
  background: #fb4b4b;
  color: #f6f8fc;
  margin: 30px 0 30px auto;
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content button:hover {
  background-color: #de4343;
  color: #fff;
}

.unset-box {
  box-shadow: unset !important;
}
</style>
<template>
  <div class="header">
    <div class="container-fluid">
      <router-link :to="{ name : 'login' }"><img class="ps-md-3" src="./../../assets/images/logo/logo-dark.png">
      </router-link>
    </div>
  </div>
  <div class="row g-0 overflow-hidden align-items-md-center justify-content-center ps-lg-5 content">
    <div class="col-lg-4 col-xl-3 col-12">
      <div class="desc">
        <h2>Hoppsan!</h2>
        <p>Nu har du hamnat fel...</p>
        <router-link :to="{ name : 'login' }">Till inloggningssidan</router-link>
      </div>
    </div>
    <div class="col-lg-8 col-xl-9 col-12"><img class="ms-auto d-block" src="./../../assets/images/error/404.png"></div>
  </div>
</template>

<script>
import {onMounted} from '@vue/runtime-core'

export default {
  setup() {

    onMounted(() => {
      const body = document.body
      body.classList.add('style-404')
    })

    return {}
  }
}
</script>

<style lang="scss" scoped>
body {
  background-color: #e9fcff !important;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header img {
  max-width: 100%;
}

.content h2 {
  font-weight: bold;
  font-size: 42px;
  line-height: 58px;
  color: #333269;
}

.content p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #64748b;
  margin-bottom: 40px;
}

.content a {
  text-decoration: none;
  display: block;
  width: 231px;
  background: #5855d6;
  border-radius: 4px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f6f8fc;
  text-align: center;
}

.content img {
  max-width: 100%;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

@media (max-width: 991.98px) {
  .content {
    text-align: center;
    padding-top: 55px;
  }
  .content p {
    margin-bottom: 15px;
  }
  .content img {
    margin-top: -50px;
    position: relative;
    z-index: -1;
  }
  .content a {
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .content img {
    margin-top: -20px;
  }
}
</style>
<template>
  <div>
    <h4 v-if="actionKey === 'create'">{{ $t("create_contract_price") }}</h4>
    <h4 v-else>{{ $t("update_contract_price") }}</h4>
    <div class="container p-3">
      <form @submit.prevent>
        <div class="customize-cols-scrollar form">
          <!--          <div class="col-md-12"-->
          <!--               v-if="isBulk === false && targetRow.scheduled_price_change.length > 0 && hasManyContract === false">-->
          <!--            <div class="mb-3">-->
          <!--              <label class="mb-1">{{ $t("pickScheduleAction") }}</label>-->
          <!--              <select v-model="actionKey" class="form-control w-100" v-on:change="onChangeAction">-->
          <!--                <option value="create" selected>{{ $t("create_contract_price") }}</option>-->
          <!--                <option :value="schedule.id" v-for="(schedule, i) in targetRow.scheduled_price_change" :key="i">-->
          <!--            <span v-if="GET_LOCALE === 'se'">Uppdatera instans - {{ schedule.swe_change_type }} med-->
          <!--            {{ schedule.contractPriceChangePercentage }}</span>-->
          <!--                  <span v-else> Update instance - {{-->
          <!--                      $services.helpers.capitalizeNames(schedule.change_type.replace('_', ' '))-->
          <!--                    }} with-->
          <!--            {{ schedule.contractPriceChangePercentage }}</span>-->

          <!--                </option>-->
          <!--              </select>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-md-12">
            <div class="mb-3">
              <small class="mb-1">{{ $t("date_to_change_price") }}</small>
              <Datepicker
                  class="form-control"
                  :disabled="actionKey !== 'create'"
                  v-model="contractPriceUpdateDate"
                  format="yyyy-MM-dd"
                  :enableTimePicker="false"
                  :minDate="today"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1">{{ $t("type_of_price_change") }}</label>
              <select v-model="contractPriceChangeType" class="form-control w-100" :disabled="actionKey !== 'create'">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option :value="item" v-for="(item,i) in actionList" :key="i">{{ $t(`${item}`) }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1 d-flex">
                <div>

            <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext" style="">{{
                        getHelpText("contract_price_update_price_change")
                      }}</span>
                  </span>
                </div>
                <div>
                  <span
                      v-if="['increase_percentage', 'decrease_percentage'].includes(contractPriceChangeType) === true">{{
                      $t("percentage_to_change_price")
                    }}</span>
                  <span v-else> {{ $t("amount_to_change_price") }}</span>
                </div>
              </label>
              <input
                  v-model="contractPriceChangePercentage"
                  class="form-control"
              />
            </div>
          </div>
          <div class="col-md-12" v-if="hasManyContract === false">
            <div class="mb-3">
              <label class="mb-1 d-flex">
                <div>
                    <span class="tooltip-custom position-relative tooltip-position">
                     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext" style="">{{
                        getHelpText("contract_price_update_rows_to_exclude")
                      }}</span>
                  </span>
                </div>
                <div> {{ $t("rowToExclude") }}</div>
              </label>
              <el-select class="w-100" v-model="excludedRow" :placeholder="$t('select')" size="large" multiple>
                <el-option
                    v-for="(item,key) in contractRowList"
                    :key="`_list_${key}`"
                    :label="`Article number : ${item.article_number}, Description:  ${item.description}, Price : ${item.price} Quantity : ${item.delivered_quantity}`"
                    :value="parseInt(item.row_id)"
                />
              </el-select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="mb-1 d-flex">
              <span class="tooltip-custom position-relative tooltip-position">
                   <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75ZM7.5 12.5C8.82608 12.5 10.0979 11.9732 11.0355 11.0355C11.9732 10.0979 12.5 8.82608 12.5 7.5C12.5 6.17392 11.9732 4.90215 11.0355 3.96447C10.0979 3.02678 8.82608 2.5 7.5 2.5C6.17392 2.5 4.90215 3.02678 3.96447 3.96447C3.02678 4.90215 2.5 6.17392 2.5 7.5C2.5 8.82608 3.02678 10.0979 3.96447 11.0355C4.90215 11.9732 6.17392 12.5 7.5 12.5ZM6.875 9.375H8.125V10.625H6.875V9.375ZM8.125 8.34687V8.75H6.875V7.8125C6.875 7.64674 6.94085 7.48777 7.05806 7.37056C7.17527 7.25335 7.33424 7.1875 7.5 7.1875C7.67755 7.18749 7.85144 7.13706 8.00145 7.04208C8.15146 6.9471 8.27142 6.81148 8.34736 6.65099C8.4233 6.4905 8.45211 6.31175 8.43043 6.13553C8.40875 5.95931 8.33747 5.79287 8.2249 5.65557C8.11232 5.51827 7.96307 5.41577 7.79451 5.35998C7.62596 5.30419 7.44502 5.29742 7.27277 5.34044C7.10051 5.38346 6.94401 5.47452 6.82148 5.60301C6.69895 5.7315 6.61542 5.89215 6.58063 6.06625L5.35438 5.82062C5.4304 5.44068 5.60594 5.08773 5.86308 4.79787C6.12021 4.508 6.4497 4.29161 6.81787 4.17083C7.18604 4.05004 7.57968 4.02918 7.95855 4.11038C8.33742 4.19159 8.68794 4.37195 8.97426 4.63302C9.26059 4.89408 9.47245 5.2265 9.58819 5.59629C9.70394 5.96608 9.71942 6.35997 9.63304 6.73769C9.54666 7.11542 9.36153 7.46344 9.09658 7.74616C8.83162 8.02889 8.49633 8.23619 8.125 8.34687Z"
                      fill="#5A58BA"/>
                </svg>
                    <span class="tooltiptext" style="">{{
                        getHelpText("contract_price_update_invoice_text")
                      }}</span>
                  </span>
                <div> {{ $t("invoiceTextOnPriceChange") }}</div>
              </label>
              <textarea class="form-control " cols="5" row="5" maxlength="1000"
                        v-model="invoiceTextAfterPriceChange"></textarea>
            </div>
          </div>


        </div>
        <div class="col-md-12 mt-2">
          <span v-if="!isBulk && targetRow.scheduled_price_change.length">
            <button
                :disabled="!canUpdateContractPrice"
                class="btn btn-primary mr-10px"
                @click="updateContractPriceUpdate()"
            >
              {{ $t("update") }}
            </button>
            <button class="btn btn-warning mr-10px" @click="deleteContractPriceUpdate">
              {{ $t("delete") }}
            </button>
          </span>

          <button
              v-else
              :disabled="!canUpdateContractPrice"
              class="btn btn-primary mr-10px"
              @click="addContractPriceUpdate"
          >
            {{ $t("save") }}
          </button>

          <button
              :disabled="scheduling"
              class="btn btn-danger"
              @click="onClose"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import debounce from "lodash.debounce";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const TODAY = moment().format("YYYY-MM-DD");
export default {
  name: "contractPriceUpdateComponent",
  components: {
    Datepicker,
  },
  props: {
    helpText: {
      type: Array,
    },
    isBulk: {
      type: Boolean,
      default: false
    },
    mode: {
      default: 'default'
    },
    hasManyContract: {
      type: Boolean
    },
    targetRow: {
      default: {}
    },
    bulkScheduled: {
      default: []
    },
    excludeRow: {
      default: []
    },
    contractRowList: {
      default: []
    },

  },
  data: function () {
    return {
      today: TODAY,
      contractPriceUpdateDate: "",
      contractPriceChangeType: '',
      actionKey: 'create',
      invoiceTextAfterPriceChange: '',
      contractPriceChangePercentage: '',
      parsedContractPriceChangePercentage: '',
      scheduling: false,
      actionList: ['increase_percentage', 'decrease_percentage', 'increase_amount', 'decrease_amount'],
      excludedRow: [],
      targetRow_: {}
    }
  },

  methods: {
    onClose: function () {
      window.Bus.emit('close-contract-modal')
    },
    getHelpText: function (field) {
      let text = this.helpText.filter(item => item.field === field)
      if (text.length > 0) {
        if (this.GET_LOCALE === 'se') {
          return text[0].swedish_text
        } else {
          return text[0].english_text
        }
      }

    },
    addContractPriceUpdate() {
      this.contractPriceUpdateDate = moment(
          this.contractPriceUpdateDate
      ).format("YYYY-MM-DD");

      // check the update date and then call respective function
      if (this.contractPriceUpdateDate === TODAY) {
        // update immediately
        this.updateToday();
      } else {
        // schedule for later
        this.updateLater();
      }

      this.openPriceChangeModal = false;
    },
    updateToday() {
      let payload = {};

      // check if current operation is a bulk action, and prepare the payload accordingly
      if (!this.isBulk) {
        payload = {
          selected: [this.targetRow.document_number],
          change_type: this.contractPriceChangeType,
          percentage_or_amount: this.parsedContractPriceChangePercentage,
          invoice_text: this.invoiceTextAfterPriceChange,
        };
      } else {
        payload = {
          selected: this.bulkScheduled,
          change_type: this.contractPriceChangeType,
          percentage_or_amount: this.parsedContractPriceChangePercentage,
          invoice_text: this.invoiceTextAfterPriceChange,
        };
      }
      payload['rows_to_exclude'] = []
      if (this.hasManyContract === false) {
        if (this.excludedRow.length > 0) {
          payload['rows_to_exclude'] = this.excludedRow
        }

      }
      if (this.hasManyContract === false && 'scheduled_price_change' in this.targetRow && this.isBulk === false) {
        if (this.actionKey === 'create') {
          this.processAddContractPriceToday(payload)
        } else {
          this.processUpdateContractPriceToday(payload)
        }
      } else {
        this.processAddContractPriceToday(payload)
      }
    },
    processAddContractPriceToday(payload) {
      this.$store
          .dispatch("clients/onAddcontractPriceUpdateToday", {
            ...payload,
          })
          .then((res) => {
            // this.openPriceChangeModal = false     //hide the cancellation modal
            // this.clearContractPriceUpdateInputs();
            // this.getContracts();
            // this.getContractCustomizeHeader();
            window.Bus.emit('reload-contract')
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
          })
          .catch((err) => {
            this.scheduling = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    processUpdateContractPriceToday(payload) {
      this.$store
          .dispatch("clients/onUpdateContractPriceUpdate", {
            data: payload,
            id: this.actionKey,
          })
          .then((res) => {
            window.Bus.emit('reload-contract')
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
          })
          .catch((err) => {
            this.scheduling = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    updateLater() {
      let payload = {};

      // check if current operation is a bulk action, and prepare the payload accordingly
      if (!this.isBulk) {
        payload = {
          contracts: [this.targetRow.document_number],
          date_to_update: this.contractPriceUpdateDate,
          change_type: this.contractPriceChangeType,
          percentage_or_amount: this.parsedContractPriceChangePercentage,
          invoice_text: this.invoiceTextAfterPriceChange,
        };
      } else {
        payload = {
          contracts: this.bulkScheduled,
          date_to_update: this.contractPriceUpdateDate,
          change_type: this.contractPriceChangeType,
          invoice_text: this.invoiceTextAfterPriceChange,
          percentage_or_amount: this.parsedContractPriceChangePercentage,
        };
      }
      payload['rows_to_exclude'] = []
      if (this.hasManyContract === false) {

        if (this.excludedRow.length > 0) {
          payload['rows_to_exclude'] = this.excludedRow
        }
      }
      if (this.hasManyContract === false && 'scheduled_price_change' in this.targetRow && this.isBulk === false) {
        if (this.actionKey === 'create') {
          this.processAddContractPriceUpdateLater(payload)
        } else {
          this.processUpdateContractPriceLater(payload)
        }
      } else {
        this.processAddContractPriceUpdateLater(payload)
      }

    },
    processAddContractPriceUpdateLater(payload) {
      this.$store
          .dispatch("clients/onAddContractPriceUpdate", {
            ...payload,
          })
          .then((res) => {
            // this.openPriceChangeModal = false     //hide the cancellation modal
            this.clearContractPriceUpdateInputs();

            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
            window.Bus.emit('reload-contract')
          })
          .catch((err) => {
            this.scheduling = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    processUpdateContractPriceLater(payload) {
      this.scheduling = true;
      this.$store
          .dispatch("clients/onUpdateContractPriceUpdate", {
            data: payload,
            id: this.actionKey,
          })
          .then((res) => {
            this.scheduling = false;
            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
            window.Bus.emit('reload-contract')
          })
          .catch((err) => {
            this.scheduling = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    updateContractPriceUpdate() {
      this.scheduling = true;
      let payload = {
        // id: this.targetRow.scheduled_price_change[0].id,
        date_to_update: moment(this.contractPriceUpdateDate).format(
            "YYYY-MM-DD"
        ),
        change_type: this.contractPriceChangeType,
        percentage_or_amount: this.parsedContractPriceChangePercentage,
        invoice_text: this.invoiceTextAfterPriceChange,
      }
      payload['rows_to_exclude'] = []
      if (this.hasManyContract === false) {

        if (this.excludedRow.length > 0) {
          payload['rows_to_exclude'] = this.excludedRow
        }

      }

      this.processUpdateContractPriceLater(payload)
    },
    deleteContractPriceUpdate() {
      this.scheduling = true;
      this.$store
          .dispatch(
              "clients/deleteContractPriceUpdate",
              this.actionKey
          )
          .then((res) => {
            this.clearContractPriceUpdateInputs();
            window.Bus.emit('reload-contract')

            this.$services.helpers.notification(
                res.data[this.locale_message],
                "success",
                this
            );
            let deleteIndex = this.targetRow.scheduled_price_change.findIndex(item => String(item.id) === String(this.actionKey))
            if (deleteIndex > -1) {
              this.targetRow_.scheduled_price_change.splice(deleteIndex, 1)
              if (this.targetRow_.scheduled_price_change.length <= 0) {
                this.actionKey = 'create'
              }
            }
          })
          .catch((err) => {
            this.scheduling = false;
            this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
            );
          });
    },
    clearContractPriceUpdateInputs() {
      this.scheduling = false;
      // this.targetRow = {};
      this.alreadyScheduled = [];
      this.contractPriceChangePercentage = 0;
      this.parsedContractPriceChangePercentage = 0;
      this.contractPriceChangeType = "";
      this.contractPriceUpdateDate = TODAY;
      this.openPriceChangeModal = false;
      this.openAlreadyPriceChangeModal = false;
    },
  },
  watch: {
    contractPriceChangePercentage(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Convert newValue to a string if it's not already
        let stringValue = String(newValue);

        // Replace comma with dot and parse to float
        this.parsedContractPriceChangePercentage = parseFloat(stringValue.replace(/,/g, '.'));
      }
    }
  },

  computed: {
    ...mapGetters({
      GET_LOCALE: 'locale/GET_LOCALE'
    }),
    canUpdateContractPrice() {
      return (
          this.parsedContractPriceChangePercentage > 0 &&
          this.contractPriceChangeType &&
          this.contractPriceUpdateDate
      );
    },
    locale_message() {
      return this.GET_LOCALE === 'se' ? 'message' : 'eng_message'
    }
  },
  mounted() {
    if (this.hasManyContract === false) {
      if ('scheduled_price_change' in this.targetRow) {
        if (this.targetRow.scheduled_price_change.length > 0) {
          let handled = this.targetRow.scheduled_price_change.filter(item => item.handled === false)
          if (handled.length > 0) {
            this.excludedRow = handled[0].rows_to_exclude
            this.invoiceTextAfterPriceChange = handled[0].invoice_text
            this.contractPriceChangePercentage = handled[0].percentage_or_amount
            this.contractPriceChangeType = handled[0].change_type
            this.contractPriceUpdateDate = handled[0].date_to_update
            this.actionKey = handled[0].id
          } else {
            this.excludedRow = []
            this.actionKey = 'create'
          }
        } else {
          this.excludedRow = []
          this.actionKey = 'create'
        }
      }
    }
    // this.excludedRow = this.excludeRow
    this.targetRow_ = this.targetRow
  }
}
</script>

<style lang="scss" scoped>
// TODO: change styling for active-filter class
.active-filter {
  box-shadow: 5px 5px 3px #888888 !important;
}

.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .headers a:hover svg path {
  fill: #4946ae;
}

.content nav {
  margin: 10px 0 0 5px;
}

@media (max-width: 1094px) {
  .content nav .row {
    display: block;
  }
  .content nav .row > div:last-of-type {
    margin-top: 10px;
  }
  .content nav .row ul {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content nav .row ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.content nav ul {
  margin: 0;
  margin-top: 10px;
  text-align: center;
}

.content nav ul li {
  border-radius: 4px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  width: 100px;
  height: 32px;
  -webkit-transition: -webkit-transform 0.35s ease-in-out,
  -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.35s ease-in-out,
  -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out,
  -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.content nav ul li:hover {
  -webkit-transform: translateY(-0.25em);
  -ms-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.content nav ul li.all {
  background: #c0beff;
}

.content nav ul li.all:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
  box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
}

.content nav ul li.inactive {
  background: #efb008;
}

.content nav ul li.inactive:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #efb008;
  box-shadow: 0 0.5em 0.5em -0.1em #efb008;
}

.content nav ul li.terminated {
  background: #fb4b4b;
}

.el-clickable {
  cursor: pointer !important;
}

.list-summary {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0px;
  font-weight: 600;
}

.content nav ul li.terminated:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
  box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
}

.content nav ul li.active {
  background: #419e6a;
}

.content nav ul li.active:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
  box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
}

.content nav ul li.upcoming {
  background: #4d82f3;
}

.content nav ul li.upcoming:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
  box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
}

.content nav ul li:not(:last-of-type) {
  margin-right: 15px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.content nav .right-parent .filter {
  margin-right: 20px;
  margin-top: 10px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.tooltiptext {
  margin-left: 0px !important;
}

.position-relative {
  position: relative !important;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 207.73px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 77%;
  margin-left: auto;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

.content nav .right-parent .customize {
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .customize {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .customize {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.content nav .right-parent .customize a {
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content nav .right-parent .customize a svg {
  margin-right: 10px;
}

.content nav .right-parent .customize a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content nav .right-parent .customize a:hover {
  color: #4946ae;
}

.content nav .right-parent .customize a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
  0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
  0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

@media (max-width: 767.98px) {
  .content .box {
    margin-top: 10px;
  }
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 1041px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 5px;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead tr {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead th .checkbox {
  margin: 0 5px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr.terminated td:last-of-type button:nth-of-type(1),
.content .box table tbody tr.terminated td:last-of-type button:nth-of-type(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.inactive td:last-of-type button:nth-of-type(1) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
  text-align: right;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td span {
  font-size: 13.5286px;
  border-radius: 4px;
  width: 79.24px;
  height: 21.26px;
  line-height: 21.26px;
  display: block;
  text-align: center;
}

.content .box table tbody td span.inactive {
  background: #ffde81;
  color: #976400;
}

.content .box table tbody td span.terminated {
  background: #ffc4c4;
  color: #fb0000;
}

.content .box table tbody td span.active {
  background: #a5e1bf;
  color: #00632b;
}

.content .box table tbody td span.upcoming {
  background: #7ea5f8;
  color: #fbfbfb;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 575.98px) {
  .content .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content .actions * {
    margin: 5px !important;
  }
}

.sync-btn {
  height: 30px;
  width: 31px;
  position: relative;
  margin-left: 10px;
}

.sync-btn i {
  position: absolute;
  top: 3px;
  left: 4px;
  font-size: 20px !important;
}

.content {
  padding: 90px 30px 10px !important;
}

// TODO: removing default styles of date picker
// .dp__input_icon_pad {
//   box-shadow: none !important;
//   background: transparent !important;
//   width: 100% !important;
// }

.confirmModal {
  text-align: center;

  & .confirmModalButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;

    & .btn-info--confirm {
      color: #fff;
      background-color: #00d4c1;
      border-color: #00d4c1;
    }
  }
}

.form select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 42px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.form select.big {
  width: 192px;
}

.form .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.formc .radio input {
  display: none;
}

.form.radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.form .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.form .radio label:first-of-type {
  margin-right: 10px;
}


.form button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.form button:hover {
  background-color: #4947b0;
  color: #fff;
}

.form textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.form textarea:focus {
  border: none;
  outline: none;
}

.form .inputFields {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 42px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;

}

.form .inputFields.big {
  width: 192px;
}

.form .inputFields:-moz-read-only {
  background: #f0eff5;
}

.form .inputFields:read-only {
  background: #f0eff5;
}

.form .inputFields:focus {
  outline: none;
}

.form .inputFields.input[type="date"] {
  text-indent: 5px;
}

.form .inputFields.input[type="date"]::-webkit-clear-button,
.form .inputFields.input[type="date"]::-webkit-inner-spin-button,
.form .inputFields.input[type="date"]::-webkit-calendar-picker-indicator,
.form .inputFields.input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.form .inputFields.input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.form .inputFields.input[type="number"]::-webkit-outer-spin-button,
.form .inputFields.input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.text-left {
  text-align: left;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f0eff5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.outline-purple {
  background: #fff !important;
  color: #5855d6 !important;
  border: 1px solid #5855d6 !important;
}

.tooltip-position {
  position: relative;
  left: -10px;
}
</style>
export const articleObject = {
    description: '',
    tags: [],
    active: true,
    article_number: '',
    bulky: false,
    construction_account: 0,
    depth: 0,
    disposable_quantity: 0,
    ean: '',
    eu_account: 0,
    euvat_account: 3106,
    export_account: 3105,
    height: 0,
    housework: true,
    housework_type: 'CONSTRUCTION',
    manufacturer: '',
    manufacturer_article_number: '',
    note: '',
    purchase_account: 4000,
    purchase_price: 0,
    quantity_in_stock: 0,
    reserved_quantity: 0,
    sales_price: 0,
    sales_account: 3001,
    stock_goods: true,
    stock_place: '',
    stock_value: 0,
    stock_warning: 0,
    supplier_name: '',
    supplier_number: '',
    type: 'STOCK',
    unit: '',
    vat: 25,
    webshop_article: true,
    weight: 0,
    width: 0,
    expired: true,
    cost_calculation_method: '',
    stock_account: 0,
    stock_change_account: 0,
    direct_cost: 0,
    freight_cost: 0,
    other_cost: 0,
    deleted : false,
    default_stock_point: '',
    default_stock_location: ''
}

export default { articleObject }


<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t('products') }}</h1>
          <h6>{{ $t('edit') }} <strong>{{ GET_SEGMENT_ARTICLE_INFO.name }}</strong> {{ $t('segment') }}</h6>
        </div>
        <div class="box segment">
          <div class="box-header flex-wrap">
            <h3>{{ $t('segment_details') }}</h3>
            <router-link :to="{ name : 'editProductSegmentAll', param:{id: GET_SEGMENT_ARTICLE_INFO.id} }"
                         class="my-2 my-sm-0">{{ $t('see_all_segment_setting') }}
            </router-link>
          </div>
          <form class="row">
            <div class="col-md-7 col-lg">
              <div class="d-flex flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('name') }}</label>
                  <input type="text" v-model="GET_SEGMENT_ARTICLE_INFO.name">
                </div>
                <div class="desc">
                  <div class="desc">
                    <label>{{ $t('sales_account_se') }}</label>
                    <input @input="searchField($event, 'se_vat')" type="text"
                           v-model="payload.sales_account_se">
                    <div v-if="showSalesSeReverseVat" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'se_vat')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('unit') }}</label>
                  <select class="small" v-model="GET_SEGMENT_ARTICLE_INFO.unit">
                    <option value=""></option>
                    <option :value="item.code" v-for="item in units" :key="item.id">{{ item.code }}</option>
                  </select>
                </div>
              </div>
              <div class="d-sm-flex flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('description') }}</label>
                  <textarea v-model="GET_SEGMENT_ARTICLE_INFO.description"></textarea>
                </div>
                <div class="desc">
                  <label>{{ $t('note') }}</label>
                  <textarea v-model="GET_SEGMENT_ARTICLE_INFO.notes"></textarea>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-4 col-xl-3">
              <div class="d-flex">
                <div class="desc">
                  <label>{{ $t('discontinued') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.discontinued" :value="true"
                           name="discontinued" id="discontinued-yes">
                    <label for="discontinued-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.discontinued" :value="false"
                           name="discontinued" id="discontinued-no">
                    <label for="discontinued-no">{{ $t('no') }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('stock_item') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.stock_item" :value="true" name="stock-item"
                           id="stock-yes">
                    <label for="stock-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.stock_item" :value="false" name="stock-item"
                           id="stock-no">
                    <label for="stock-no">{{ $t('no') }}</label>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="desc">
                  <label>{{ $t('external_webshop') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.external_webshop" :value="true"
                           v-on:change="handleTypeChange"
                           name="external-webshop" id="external-yes">
                    <label for="external-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.external_webshop" :value="false"
                           v-on:change="handleTypeChange"
                           name="external-webshop" id="external-no">
                    <label for="external-no">{{ $t('no') }}</label>
                  </div>
                </div>
                <div class="desc">
                  <div class="desc">
                    <label>{{ $t('product_type') }}</label>
                    <div class="radio">
                      <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.product_type" value="STOCK"
                             v-on:change="handleTypeChange"
                             name="product-type" id="product-goods">
                      <label for="product-goods">{{ $t('goods') }}</label>
                      <input type="radio" v-model="GET_SEGMENT_ARTICLE_INFO.product_type" value="SERVICE"
                             v-on:change="handleTypeChange"
                             name="product-type" id="product-services">
                      <label for="product-services">{{ $t('services') }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="box product">
          <div class="box-header flex-wrap">
            <h3>{{ $t('added_products') }}</h3>
            <router-link class="my-2 my-sm-0"
                         :to="{name: 'addProductToSegment' ,param: {id: GET_SEGMENT_ARTICLE_INFO.id}}">{{ $t('add_product_to_segment') }}
            </router-link>
          </div>
          <div class="box-responsive">
            <table>
              <thead>
              <tr>
                <th>
                  <div class="checkbox" v-on:change="checkAll" tooltip="Remove all">
                    <input type="checkbox">
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </th>
                <th><span>{{ $t('article_number') }}</span></th>
                <th><span>{{ $t('description') }}</span></th>
                <th><span>{{ $t('price_list') }}</span></th>
                <th><span>{{ $t('purchase_price') }}</span></th>
                <th><span>{{ $t('unit') }}</span></th>
<!--                <th><span>{{ $t('amount_sold') }}</span></th>-->
                <th><span>{{ $t('type') }}</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in GET_SEGMENT_ARTICLE_INFO.articles" :key="key">
                <td>
                  <div class="checkbox">
                    <input type="checkbox" v-model="item.checked" v-on:change="checkboxOrder(item.id)"
                           :value="item.checked">
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </td>
                <td>{{ item.article_number }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.sales_price }}</td>
                <td>{{ item.purchase_price }}</td>
                <td>{{ item.unit }}</td>
<!--                <td>{{ item.amount_sold }}</td>-->
                <!--                <td class="no">No</td>-->
                <!--                <td>{{  item }}</td>-->
                <td>{{ item.type }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="buttons flex-wrap-reverse align-items-center">
          <button type="button" v-on:click="removeProductFromSegment" :disabled="processing">
            <span v-if="processing">{{ $t('processing') }}</span>
            <span v-else>{{ $t('remove_from_segment') }}</span>
          </button>
          <div class="right flex-wrap my-2 my-sm-0"><a href="javascript:void(0);"
                                                       @click="$router.go(-1)">{{ $t('cancel') }}</a>
            <button class="my-2 my-sm-0" :disabled="updating" type="button" v-on:click="updateProductSegment">
              <span v-if="updating === false">{{ $t('update') }} {{ $t('segment') }}</span>
              <span v-else>{{ $t('updating') }} {{ $t('segment') }}...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAlertVue v-if="showDeleteModal" @handleClose="showDeleteModal = false" :close="!true">
    <h4>{{ $t('delete_confirmation_text') }} <span class="user-confirm text-danger">{{ $t('article_segment').toLowerCase() }}</span>?</h4>
    <div class="popup-bottons">
      <button @click="showDeleteModal = false">{{ $t('cancel_delete_request') }}</button>
      <button :disabled='loading' @click="deleteArticleSegment">
        <span v-if="loading">{{ $t('deleting') }}...</span>
        <span v-else>{{ $t('accept_delete_request') }}</span>
      </button>
    </div>
  </ModalAlertVue>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from "lodash.debounce";
import ModalAlertVue from '../../components/ModalAlert.vue'

export default {
  name: 'ProductEditSegment',
  components: {ModalAlertVue},
  data() {
    return {
      productsSegmentArr: [],
      productSegmentToRemoveArr: [],
      payload: {
        sales_account_eu_vat: '',
        pseudo_sales_account_eu_vat: '',
        sales_account_se: '',
        pseudo_sales_account_se: '',
        sales_account_eu_reverse_vat: '',
        pseudo_sales_account_eu_reverse_vat: '',
        purchase_account: '',
        pseudo_purchase_account: '',

      },
      showSalesAccount: false,
      showPurchaseAccount: false,
      showSalesEuReverseVat: false,
      showSalesSeReverseVat: false,
      accounts: [],
      units: [],
      loading: false,
      updating: false,
      showDeleteModal: false,
      processing: false,
    }
  },
  computed: {
    ...mapGetters({
      GET_SEGMENT_ARTICLE_INFO: 'clients/GET_SEGMENT_ARTICLE_INFO',
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  },
  mounted() {
    const {id} = this.$route.params
    this.getSegmentProductDetails(id)
    this.getUnit()
  },
  methods: {
    handleTypeChange: function () {
      if (this.GET_SEGMENT_ARTICLE_INFO.product_type === 'SERVICE') {
        this.GET_SEGMENT_ARTICLE_INFO.stock_item = false
      }
    },
    checkAll(status) {

    },
    deleteArticleSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.processing = true
      this.$store.dispatch('clients/removeProductSegment', {id: this.GET_SEGMENT_ARTICLE_INFO.id})
          .then(res => {
            this.processing = false
            // this.$services.helpers.notification('Article Segment removed successfully', 'success', this)
            this.$toast.success(this.$t('segment_removed'));
            this.$router.push({name: 'productSegment'})

          })
          .catch(err => {
            this.processing = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)

          })
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'units'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.units = res.data.results
          })
          .catch(err => {
          })
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            this.showSalesSeReverseVat = true
          })
          .catch(err => {

          })
    },
    chooseSales(item, mode) {
      if (mode === 'sales') {
        this.segmentArticleObj.sales_account_eu_vat = item.id
        this.payload.sales_account_eu_vat = item.number
        this.showSalesAccount = false
      } else if (mode === 'se_vat') {
        this.showSalesSeReverseVat = false
        this.payload.sales_account_se = item.number
      } else if (mode === 'eu_vat') {
        this.payload.sales_account_eu_reverse_vat = item.id
        this.payload.pseudo_sales_account_eu_reverse_vat = item.number
        this.showSalesEuReverseVat = false
      } else if (mode === 'purchase') {
        this.payload.purchase_account = item.id
        this.payload.pseudo_purchase_account = item.number
        this.showPurchaseAccount = false
      }
    },
    getSegmentProductDetails(data) {
      this.$store.dispatch('clients/getSegmentProductDetails', data).then(_ => {
        let self = this
        if (self.GET_SEGMENT_ARTICLE_INFO) {
          if (self.GET_SEGMENT_ARTICLE_INFO.sales_account_se) {
            self.payload.sales_account_se = self.GET_SEGMENT_ARTICLE_INFO.sales_account_se.number
          }
          // this.GET_SEGMENT_ARTICLE_INFO.articles.map(item => {
          //   item.checked = true
          // })
        }

      })
    },
    updateProductSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.updating = true
      let data_to_save = {
        description: this.GET_SEGMENT_ARTICLE_INFO.description,
        note: this.GET_SEGMENT_ARTICLE_INFO.notes,
        name: this.GET_SEGMENT_ARTICLE_INFO.name,
        sales_account_se: this.payload.sales_account_se,
        discontinued: this.GET_SEGMENT_ARTICLE_INFO.discontinued,
        stock_item: this.GET_SEGMENT_ARTICLE_INFO.stock_item,
        external_webshop: this.GET_SEGMENT_ARTICLE_INFO.external_webshop,
        product_type: this.GET_SEGMENT_ARTICLE_INFO.product_type,
      }
      for (const [key, value] of Object.entries(data_to_save)) {
        if (value === null || value === '' || value === undefined) {
          delete data_to_save[key]
        }
      }
      const payload = {
        data: data_to_save,
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `articles/${this.GET_SEGMENT_ARTICLE_INFO.id}/`
      }
      this.$store.dispatch('clients/customPutRequest', payload)
          .then(res => {
            this.updating = false
            // this.$services.helpers.notification('Product segment updated successfully', 'success', this)
            this.$toast.success(this.$t('segment_updated'));

          })
          .catch(err => {
            this.updating = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    checkboxOrder(id) {
      if (this.productSegmentToRemoveArr.includes(id)) {
        let indexID = this.productSegmentToRemoveArr.findIndex(item => item === id)
        if (indexID !== -1) {
          this.productSegmentToRemoveArr.splice(indexID, 1)
        }
      } else {
        this.productSegmentToRemoveArr.push(id)
      }
    },
    removeProductFromSegment() {
      if (this.productSegmentToRemoveArr.length > 0) {
        let payload = {
          'data': {
            'articles': this.productSegmentToRemoveArr
          },
          'URL': this.$services.endpoints.SEGMENT_ENDPOINT + `articles/${this.GET_SEGMENT_ARTICLE_INFO.id}/remove_articles/`
        }
        this.processing = true
        this.$store.dispatch('clients/customPutRequest', payload).then(_ => {
          this.getSegmentProductDetails(this.$route.params.id)
          // this.$services.helpers.notification('Product removed successfully', 'success', this)
          this.$toast.success(this.$t('article_removed_segment'));

          this.processing = false
        }).catch(err => {
          this.processing = false
          this.$services.helpers.notification(err.response.data.message, 'success', this)
        })
      } else {
        this.$services.helpers.notification('Vänligen markera en eller flera artiklar', 'error', this)

      }

    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.segment .box-header {
  background-image: url("./../../assets/images/table/segment.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}

.content .box.product .box-header {
  background-image: url("./../../assets/images/table/product.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box .box-header {
  background: #5855d6;
  font-family: Roboto;
  font-style: normal;
  padding: 30px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box .box-header h3 {
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  margin: 0;
}

.content .box .box-header a {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  color: #5855d6;
  background: #fffdfd;
  padding: 12px 16px;
  border: 1px solid #fffdfd;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box .box-header a:hover {
  color: #4946ae;
  background-color: #fbfafb;
}

.content .box form {
  padding: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content .box form .col-md-5 .desc:not(:last-of-type) {
  margin-right: 5% !important;
}

.content .box form .desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 12.469px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

@media (min-width: 576px) {
  .content .box form .desc:not(:last-of-type) {
    margin-right: 2%;
  }
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 575.98px) {
  .content .box form .desc input {
    width: 90%;
  }
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

@media (max-width: 575.98px) {
  .content .box form .desc textarea {
    width: 90%;
  }
}

.content .box form .desc textarea:focus {
  border: none;
  outline: none;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 5px 0;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td:nth-of-type(8).no {
  color: #419e6a;
}

.content .box table tbody td:nth-of-type(8).yes {
  color: #fb4b4b;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  color: #fff;
  background-color: #de4343;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button:last-of-type {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 195px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

select.small {
  width: 94px;
}
</style>
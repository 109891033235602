<template>
  <td width="30" class="text-center">
    <div class="checkbo">
      <input
        type="checkbox"
        v-model="props.data.checked"
        :value="props.data.checked"
        @change="checkboxOrder"
        class="form-check-input"
      />
      <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
      <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
      </svg> -->
    </div>
  </td>
  <td
    v-for="(item, i) in head"
    :key="i"
    :class="centeredColumns.includes(item) ? 'data-center' : ''"
  >
    <router-link
      class="unset-pad"
      :to="{
        name: 'editproductbasic',
        params: { id: props.data.article_number },
      }"
    >
      <p v-if="['tags'].includes(item)">
        {{ $services.helpers.concatWord(data[item]) }}
      </p>
      <p v-else-if="['segment'].includes(item)">
        <span v-if="data[item]"> {{ data[item].name }} </span>
        <span v-else>--</span>
      </p>
      <p v-else>{{ $services.helpers.parseContent(data[item]) }}</p>
    </router-link>
  </td>
  <td>
    <div v-if="loading === false" style="display: flex">
      <button
        type="button"
        class="me-3"
        title="Deactivate article"
        @click="toggleActivity"
        v-if="data.active === true"
      >
        <svg
          width="8"
          height="15"
          viewbox="0 0 8 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.777303 0.612305C0.97996 0.612305 1.17432 0.69281 1.31762 0.83611C1.46092 0.97941 1.54142 1.17377 1.54142 1.37642V13.6023C1.54142 13.805 1.46092 13.9993 1.31762 14.1426C1.17432 14.2859 0.97996 14.3664 0.777303 14.3664C0.574646 14.3664 0.380289 14.2859 0.236989 14.1426C0.0936889 13.9993 0.0131836 13.805 0.0131836 13.6023V1.37642C0.0131836 1.17377 0.0936889 0.97941 0.236989 0.83611C0.380289 0.69281 0.574646 0.612305 0.777303 0.612305ZM6.89026 0.612305C7.09291 0.612305 7.28727 0.69281 7.43057 0.83611C7.57387 0.97941 7.65438 1.17377 7.65438 1.37642V13.6023C7.65438 13.805 7.57387 13.9993 7.43057 14.1426C7.28727 14.2859 7.09291 14.3664 6.89026 14.3664C6.6876 14.3664 6.49324 14.2859 6.34994 14.1426C6.20664 13.9993 6.12614 13.805 6.12614 13.6023V1.37642C6.12614 1.17377 6.20664 0.97941 6.34994 0.83611C6.49324 0.69281 6.6876 0.612305 6.89026 0.612305Z"
            fill="#5855D6"
          ></path>
        </svg>
        <!--        <img src="../../../assets/images/icons-clickable/play.png" width="15" height="15" v-else/>-->
      </button>
      <button
        type="button"
        class="me-3"
        title="Activate article "
        @click="activateActivity"
        v-if="data.active === false"
      >
        <img
          src="../../../assets/images/icons-clickable/play.png"
          width="15"
          height="15"
        />
      </button>
      <button type="button" title="Assign tags" @click="openCustomerTag">
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path
            :fill="
              props.data.tags !== null && props.data.tags.length
                ? '#13b010'
                : '#5855d6'
            "
            d="M21.41 11.58L12.41 2.58A2 2 0 0 0 11 2H4A2 2 0 0 0 2 4V11A2 2 0 0 0 2.59 12.42L11.59 21.42A2 2 0 0 0 13 22A2 2 0 0 0 14.41 21.41L21.41 14.41A2 2 0 0 0 22 13A2 2 0 0 0 21.41 11.58M13 20L4 11V4H11L20 13M6.5 5A1.5 1.5 0 1 1 5 6.5A1.5 1.5 0 0 1 6.5 5Z"
          />
        </svg>
      </button>
    </div>
    <icon_loading v-else />
  </td>
</template>

<script>
import { useStore } from "vuex";
import icon_loading from "../../../components/icon_loading";

export default {
  props: {
    data: Object,
    head: Array,
    centeredColumns: Array,
  },
  components: { icon_loading },
  emits: ["openActivity", "openTag"],
  setup(props, { emit }) {
    const store = useStore();

    const checkboxOrder = (e) => {
      store.commit("clients/TOGGLE_ARTICLE_CHECKBOX", {
        id: props.data.article_number,
        value: props.data.checked,
      });
    };
    const openArticleActivity = () => {
      // store.commit('clients/TOGGLE_ARTICLE_CHECKBOX', { id : props.data.article_number, value : !props.data.checked  })
      emit("openActivity", props.data);
    };
    // const openCustomerTag = () => {
    //   // store.commit('clients/TOGGLE_ARTICLE_CHECKBOX', { id : props.data.article_number, value : !props.data.checked  })
    //   emit('openTag', props.data)
    // }

    return {
      props,
      openArticleActivity,
      checkboxOrder,
    };
  },
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    concatWord(item) {
      if (Array.isArray(item)) {
        if (item !== "" || item !== null) {
          try {
            if (item.length > 0) {
              return item.join(",");
            } else {
              return "";
            }
          } catch (e) {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    openCustomerTag: function () {
      window.Bus.emit("open-single-tag-article", this.data);
    },
    toggleActivity() {
      this.loading = true;
      let payload = {
        active: false,
        article_number: this.data.article_number,
      };
      let data = this.data;
      this.$store
        .dispatch("clients/updateArticle", payload)
        .then((res) => {
          this.loading = false;
          data.active = false;
          // this.$services.helpers.notification('Updated article information', 'success', this)
          this.$toast.success(this.$t("updated_article_info"));
        })
        .catch((err) => {
          data.active = true;
          this.loading = false;
          if (err.response.status === 403) {
            this.$services.helpers.notification(
              err.response.data,
              "error",
              this
            );
          } else {
            try {
              this.$services.helpers.notification(
                err.response.data.message.data,
                "error",
                this
              );
            } catch (e) {
              // this.$services.helpers.notification('Action could not be perform', 'error', this)
              this.$toast.error(this.$t("action_failed"));
            }
          }
        });
    },
    activateActivity() {
      this.loading = true;
      let payload = {
        active: true,
        article_number: this.data.article_number,
      };
      let data = this.data;
      this.$store
        .dispatch("clients/updateArticle", payload)
        .then((res) => {
          this.loading = false;
          data.active = true;
          // this.$services.helpers.notification('Updated article information', 'success', this)
          this.$toast.success(this.$t("updated_article_info"));
        })
        .catch((err) => {
          data.active = false;
          this.loading = false;
          if (err.response.status === 403) {
            this.$services.helpers.notification(
              err.response.data,
              "error",
              this
            );
          } else {
            try {
              this.$services.helpers.notification(
                err.response.data.message.data,
                "error",
                this
              );
            } catch (e) {
              // this.$services.helpers.notification('Action could not be perform', 'error', this)
              this.$toast.error(this.$t("action_failed"));
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: #419e6a !important;
}

.text-yellow {
  color: #efb008;
}

.text-blue {
  color: #4d82f3;
}

.text-red {
  color: #fb4b4b;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
    0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2),
    0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox svg span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th.editable {
  width: 155px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr:first-of-type {
  border-top: 0.982163px solid rgba(0, 0, 0, 0.13);
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr.enabled td a {
  color: #419e6a;
}

.content .box table tbody tr.inactive td a {
  color: #efb008;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td span.retail-price,
.content .box table tbody td span.purchase-price,
.content .box table tbody td span.in-stock,
.content .box table tbody td input.retail-price,
.content .box table tbody td input.purchase-price,
.content .box table tbody td input.in-stock {
  color: #000;
  width: 100px;
  height: 26px;
  background: #f0eff5;
  border: 1px solid #c8c7cd;
  line-height: 26px;
  display: block;
  margin: 0 auto;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td .editable {
  padding: 10px 5px;
}

.content .box table tbody td .editable input {
  background: #f0eff5;
  border: 1px solid #c8c7cd;
  padding: 2px 12px;
  width: 100px;
  text-align: center;
}

.content .box table tbody td .editable input:focus {
  border: 1px solid #c8c7cd;
  outline: none;
}

.content .box table tbody td .editable input.lower {
  border-color: #fb4b4b;
  background: #fb4b4b;
  color: #fff;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>

<template>
  <div class="player-container">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: "videoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
      loading: false
    }
  },
  mounted() {
    try {
      let self = this
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        self.loading = true
        this.player.log('onPlayerReady', this);
      });
      let player = document.getElementsByClassName('video-js')
    } catch (e) {
      // left blank
    }


  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <td class="text-center">
    <div class="checkbo">
      <input
        type="checkbox"
        v-model="props.data.checked"
        :value="props.data.checked"
        @change="checkboxOrder"
        class="form-check-input"
      />
    </div>
  </td>
  <td v-for="(item, i) in head" :key="i">
    <!--    {{ data['booked']}}-->
    <router-link
      class="unset-pad"
      :to="{ name: 'editInvoice', params: { id: props.data.document_number } }"
    >
      <p v-if="['tags'].includes(item)">
        {{ $services.helpers.concatWord(data[item]) }}
      </p>
      <p v-else>{{ $services.helpers.parseContent(data[item]) }}</p>
    </router-link>
  </td>
  <td>
    <span style="white-space: nowrap" v-if="loading === false">
      <button
        @click="registerPayment"
        title="Register payment for invoice"
        class="cursor-pointer"
        type="button"
        :disabled="
          data['booked'] === false ||
          data['fully_paid'] === true ||
          data['cancelled'] === true
        "
      >
        <svg
          width="22"
          height="17"
          viewbox="0 0 22 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7227 11.8423C14.5237 11.8423 14.333 11.9266 14.1923 12.0766C14.0517 12.2266 13.9727 12.4301 13.9727 12.6423C13.9727 12.8545 14.0517 13.0579 14.1923 13.208C14.333 13.358 14.5237 13.4423 14.7227 13.4423H17.7227C17.9216 13.4423 18.1123 13.358 18.253 13.208C18.3936 13.0579 18.4727 12.8545 18.4727 12.6423C18.4727 12.4301 18.3936 12.2266 18.253 12.0766C18.1123 11.9266 17.9216 11.8423 17.7227 11.8423H14.7227Z"
            fill="#5855D6"
            fill-opacity="0.9"
          ></path>
          <path
            d="M0.472656 3.84209C0.472656 2.9934 0.788727 2.17946 1.35134 1.57935C1.91395 0.979232 2.67701 0.64209 3.47266 0.64209H18.4727C19.2683 0.64209 20.0314 0.979232 20.594 1.57935C21.1566 2.17946 21.4727 2.9934 21.4727 3.84209V13.4421C21.4727 14.2908 21.1566 15.1047 20.594 15.7048C20.0314 16.3049 19.2683 16.6421 18.4727 16.6421H3.47266C2.67701 16.6421 1.91395 16.3049 1.35134 15.7048C0.788727 15.1047 0.472656 14.2908 0.472656 13.4421V3.84209ZM19.9727 3.84209C19.9727 3.41774 19.8146 3.01078 19.5333 2.71072C19.252 2.41066 18.8705 2.24209 18.4727 2.24209H3.47266C3.07483 2.24209 2.6933 2.41066 2.412 2.71072C2.13069 3.01078 1.97266 3.41774 1.97266 3.84209V5.44209H19.9727V3.84209ZM1.97266 13.4421C1.97266 13.8664 2.13069 14.2734 2.412 14.5735C2.6933 14.8735 3.07483 15.0421 3.47266 15.0421H18.4727C18.8705 15.0421 19.252 14.8735 19.5333 14.5735C19.8146 14.2734 19.9727 13.8664 19.9727 13.4421V7.04209H1.97266V13.4421Z"
            fill="#5855D6"
            fill-opacity="0.9"
          ></path>
        </svg>
      </button>
      <button
        class="cursor-pointer"
        title="Book invoice"
        type="button"
        v-on:click="bookInvoiceData"
        v-if="data['booked'] === false && data['cancelled'] === false"
      >
        <img
          src="../../../assets/images/icons-clickable/book.png"
          width="15"
          height="15"
        />
      </button>
      <button
        type="button"
        title="Send invoice"
        @click="sendInvoice"
        v-if="data['cancelled'] === false"
      >
        <svg
          width="21"
          height="21"
          viewbox="0 0 21 21"
          fill="#7977de"
          xmlns="http://www.w3.org/2000/svg"
          style="opacity: 1 !important"
        >
          <path
            d="M19.908 1.22407C19.4078 0.710688 18.6675 0.519427 17.9772 0.720754L1.88065 5.40161C1.15235 5.60395 0.636143 6.18478 0.497086 6.92264C0.355028 7.67359 0.851231 8.62688 1.4995 9.02551L6.53256 12.1189C7.04877 12.436 7.71504 12.3565 8.14222 11.9256L13.9056 6.12639C14.1957 5.8244 14.6759 5.8244 14.966 6.12639C15.2561 6.41832 15.2561 6.89144 14.966 7.19343L9.19265 12.9937C8.76447 13.4235 8.68444 14.0929 8.99957 14.6123L12.0748 19.6959C12.435 20.2998 13.0552 20.6421 13.7355 20.6421C13.8155 20.6421 13.9056 20.6421 13.9856 20.632C14.7659 20.5314 15.3862 19.9978 15.6163 19.2429L20.3882 3.16688C20.5983 2.48237 20.4082 1.73746 19.908 1.22407Z"
            fill="#7977de"
            fill-opacity="0.8"
            data-v-b713c744=""
          ></path>
        </svg>
      </button>
      <button type="button" @click="createCopyInvoice">
        <svg
          width="15"
          height="19"
          viewbox="0 0 15 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.7227 0.89209H3.25391C3.16797 0.89209 3.09766 0.962402 3.09766 1.04834V2.14209C3.09766 2.22803 3.16797 2.29834 3.25391 2.29834H12.9414V15.7358C12.9414 15.8218 13.0117 15.8921 13.0977 15.8921H14.1914C14.2773 15.8921 14.3477 15.8218 14.3477 15.7358V1.51709C14.3477 1.17139 14.0684 0.89209 13.7227 0.89209ZM11.2227 3.39209H1.22266C0.876953 3.39209 0.597656 3.67139 0.597656 4.01709V14.3823C0.597656 14.5483 0.664062 14.7065 0.78125 14.8237L4.16602 18.2085C4.20898 18.2515 4.25781 18.2866 4.31055 18.3159V18.353H4.39258C4.46094 18.3784 4.5332 18.3921 4.60742 18.3921H11.2227C11.5684 18.3921 11.8477 18.1128 11.8477 17.7671V4.01709C11.8477 3.67139 11.5684 3.39209 11.2227 3.39209ZM4.30859 16.3647L2.62695 14.6812H4.30859V16.3647ZM10.4414 16.9858H5.55859V14.2124C5.55859 13.7808 5.20898 13.4312 4.77734 13.4312H2.00391V4.79834H10.4414V16.9858Z"
            fill="#5855D6"
          ></path>
        </svg>
      </button>
      <button
        v-if="!data.fully_paid && !data.cancelled"
        type="button"
        :title="$t('schedule_invoice_send_out')"
        @click="scheduleInvoiceSendout(data)"
      >
        <svg style="width: 20px; height: 19px" viewBox="0 0 24 24">
          <path
            :fill="
              data.scheduled_invoice_sending.length &&
              data.scheduled_invoice_sending.some((i) => !i.handled)
                ? '#13b010'
                : '#5855d6'
            "
            d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z"
          />
        </svg>
      </button>
    </span>
    <icon_loading v-else />
  </td>
</template>

<script>
import { useStore, mapGetters } from "vuex";
import icon_loading from "../../../components/icon_loading";

export default {
  name: "InvoiceTable",
  components: { icon_loading },
  emits: [
    "registerPayment",
    "createCopyInvoice",
    "sendInvoice",
    "scheduleInvoiceSendout",
  ],
  props: {
    data: Object,
    head: Array,
  },
  setup(props, { emit }) {
    const store = useStore();

    const checkboxOrder = (e) =>
      store.commit("clients/TOGGLE_INVOICE_CHECKBOX", {
        id: props.data.id,
        value: props.data.checked,
      });
    const registerPayment = () => {
      if (props.data.booked === true && props.data.cancelled === false) {
        emit("registerPayment", props.data);
      } else {
        this.$services.helpers.notification(
          "Only booked invoice can payment be registered for",
          "error",
          this
        );
      }
    };
    const createCopyInvoice = () => {
      if (props.data.booked === true) {
        emit("createCopyInvoice", props.data);
      } else {
        this.$services.helpers.notification(
          "Only booked invoice can payment be registered for",
          "error",
          this
        );
      }
    };
    const sendInvoice = () => {
      // store.commit('clients/TOGGLE_INVOICE_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      emit("sendInvoice", props.data);
    };

    const scheduleInvoiceSendout = (data) => {
      emit("scheduleInvoiceSendout", data);
    };

    return {
      props,
      checkboxOrder,
      registerPayment,
      sendInvoice,
      createCopyInvoice,
      scheduleInvoiceSendout,
    };
  },
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    bookInvoiceData() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.loading = true;
      let data = this.data;
      const URL =
        this.$services.endpoints.INVOICE_ENDPOINT +
        `/${this.data.document_number}/bookkeep`;
      this.$store
        .dispatch("clients/customPutRequest", { URL })
        .then((res) => {
          this.loading = false;
          data.booked = true;
          // this.$services.helpers.notification('Invoice booked successfully', 'success', this)
          this.$toast.success(this.$t("invoice_booked"));
        })
        .catch((err) => {
          this.loading = false;
          data.booked = false;
          if (err.response.status === 403) {
            this.$services.helpers.notification(
              err.response.data,
              "error",
              this
            );
          } else {
            try {
              if ([400, 406].includes(err.response.status)) {
                if ("error" in err.response.data) {
                  if (Array.isArray(err.response.data.error)) {
                    this.$services.helpers.notification(
                      err.response.data.error.join(","),
                      "error",
                      this
                    );
                  } else if (typeof err.response.data.error === "object") {
                    for (const [key, value] of Object.entries(
                      err.response.data.error
                    )) {
                      if (Array.isArray(value)) {
                        this.$services.helpers.notification(
                          key.replace("_", " ") + ": " + value.join(","),
                          "error",
                          this
                        );
                      } else {
                        this.$services.helpers.notification(
                          value,
                          "error",
                          this
                        );
                      }
                      return false;
                    }
                  } else {
                    this.$services.helpers.notification(
                      err.response.data.error,
                      "error",
                      this
                    );
                  }
                } else if ("message" in err.response.data) {
                  if (Array.isArray(err.response.data.message)) {
                    this.$services.helpers.notification(
                      err.response.data.message.join(""),
                      "error",
                      this
                    );
                  } else if (typeof err.response.data.message === "object") {
                    if ("message" in err.response.data.message) {
                      if (
                        "ErrorInformation" in err.response.data.message.message
                      ) {
                        this.$services.helpers.notification(
                          err.response.data.message.message.ErrorInformation
                            .message,
                          "error",
                          this
                        );
                      }
                    }
                  } else {
                    this.$services.helpers.notification(
                      err.response.data.message,
                      "error",
                      this
                    );
                  }
                }
              } else {
                // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
                this.$toast.error(this.$t("request_failure"));
              }
            } catch (e) {
              // this.$services.helpers.notification('Action could not be perform', 'error', this)
              this.$toast.error(this.$t("action_failed"));
            }
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: "clients/GET_CURRENT_USER",
    }),
  },
};
</script>

<style lang="scss" scoped>
.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox svg span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  //   white-space: nowrap;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  //   white-space: nowrap;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .box table tbody tr.booked td a {
  color: #4d82f3;
}

.content .box table tbody tr.booked td button:nth-child(2) {
  //opacity: 0.5;
  //   pointer-events: none;
}

.content .box table tbody tr.notbooked td a {
  color: #efb008;
}

.content .box table tbody tr.notbooked td:not(:last-of-type) path {
  fill: #64748b;
}

.content .box table tbody tr.unpaid td a {
  color: #fb4b4b;
}

.content .box table tbody tr.unpaid td button:nth-child(2) {
  //opacity: 0.5;
  //   pointer-events: none;
}

.content .box table tbody tr.fullypaid td a {
  color: #419e6a;
}

.content .box table tbody tr.fullypaid td button:nth-child(1),
.content .box table tbody tr.notbooked td button:nth-child(1) {
  opacity: 0.5;

  pointer-events: none;
}

.content .box table tbody tr.voided td a {
  color: #64748b;
}

.content .box table tbody tr.voided td:not(:last-of-type) path {
  fill: #64748b;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 575.98px) {
  .content .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content .actions * {
    margin: 5px !important;
  }
}
</style>

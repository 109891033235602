<template>
  <div style="margin: auto;display: table">
    <div><img src="../../../assets/images/icons-clickable/empty-white-box.png" class="icon-img" :alt="title" v-if="is_icon"/></div>
    <small>{{ title }}</small>
  </div>
</template>

<script>
export default {
  name: "EmptyComponent",
  props: {
    is_icon: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "You currently do not have data populated",
      required: true
    }
  }
}
</script>

<style scoped>
.icon-img {
  height: 31px !important;
  width: 30px !important;
  margin: auto;
  display: table
}
</style>
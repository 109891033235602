<template>
  <div class="col responsive-width">
    <div class="plan-info" v-if="GET_USER_SUBSCRIPTION.length > 0">
      <p>{{ $t('you_are_currently_on_the') }}
        <span class="plan-type"> <strong>{{
            GET_USER_SUBSCRIPTION[0].current_package
          }}</strong></span> {{ $t('plan_paying') }} <span class="plan-pay">{{
            GET_USER_SUBSCRIPTION[0].cost
          }}</span> SEK
        {{ $t('as_a_monthly_average_your_current_tier_cover_up_to') }} <span class="active-contracts">{{
            total_contract
          }}</span>
      </p>
      <p>{{ $t('active_contract') }}
        {{ $t('you_signed_up_for') }} <span class="signup-time" v-if="GET_USER_SUBSCRIPTION[0].payment_plan === 'Yearly'"> 12 </span>  <span class="signup-time" v-else>3</span>{{ $t('months_binding_on') }} <span
            class="signup-date">{{ GET_USER_SUBSCRIPTION[0].period_start_date }}</span>. </p>
      <p v-if="GET_USER_SUBSCRIPTION[0].sla_care === true"> {{ $t('sla_addon') }}</p>
      <p v-if="GET_USER_SUBSCRIPTION[0].current_package === 'Free'">{{ $t('free_offer') }}</p>
    </div>
    <div class="container-fluid">
      <div class="content">
        <h1 class="text-center">{{ $t('plan_details') }}</h1>
        <nav v-if="loading === false">
          <ul class="list-unstyled">
            <li v-if="GET_USER_SUBSCRIPTION.length > 0">
              <input type="radio" value="Quarterly" v-model="current_payment_plan" name="plans" id="quartly">
              <label for="quartly">{{ $t('quarterly') }}</label>
              <div class="plans-content quartly-plan" v-if="current_payment_plan === 'Quarterly'">
                <div class="plans">
                  <div class="plan-row">
                    <div class="plan-col" v-for="(item,key) in quarterlyPlan" :key="key">
                      <div class="plan"
                           :class="{'popular': GET_USER_SUBSCRIPTION[0].current_package.toLowerCase() === item.name && GET_USER_SUBSCRIPTION[0].payment_plan === 'Quarterly'}">
                        <p class="price">{{ item.cost_price }} <sub>SEK / mån exkl. moms</sub></p>
                        <h3>{{ item.name }}</h3>
                        <!-- <p class="desc">For most businesses that want to optimize web queries</p> -->
                        <ul v-if="GET_LOCALE === 'se'">
                          <li v-for="(fk,k) in item.swe_features" :key="k">{{ fk }}</li>
                        </ul>
                        <ul v-else>
                          <li v-for="(fk,k) in item.features" :key="k">{{ fk }}</li>
                        </ul>
                        <button type="submit" @click="changePlan(`${item.name}`,'Quarterly')">{{ $t('choose_plan') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li v-if="GET_USER_SUBSCRIPTION.length > 0">
              <input type="radio" value="Yearly" v-model="current_payment_plan" name="plans" id="yearly"
              >
              <label for="yearly">{{ $t('yearly') }}</label>
              <div class="plans-content yearly-plan" v-if="current_payment_plan === 'Yearly'">
                <div class="plans">
                  <div class="plan-row">
                    <div class="plan-col" v-for="(item,key) in yearlyPlan" :key="key">
                      <div class="plan"
                           :class="{'popular': GET_USER_SUBSCRIPTION[0].current_package.toLowerCase() === item.name && GET_USER_SUBSCRIPTION[0].payment_plan === 'Yearly'}">
                        <p class="price">{{ item.cost_price }} <sub>SEK / mån exkl. moms</sub></p>
                        <h3>{{ item.name }}</h3>
                        <!-- <p class="desc">For most businesses that want to optimize web queries</p> -->
                        <ul v-if="GET_LOCALE === 'se'">
                          <li v-for="(fk,k) in item.swe_features" :key="k">{{ fk }}</li>
                        </ul>
                        <ul v-else>
                          <li v-for="(fk,k) in item.features" :key="k">{{ fk }}</li>
                        </ul>
                        <button type="submit" @click="changePlan(`${item.name}`,'Yearly')">{{ $t('choose_plan') }}</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <div class="" v-else>
          <div class="box square"
               style="background: #ffffff;box-shadow: 0px 2.93422px 4.89036px rgb(9 30 66 / 20%), 0px 0px 0.978073px rgb(9 30 66 / 31%);">
            <shimmer-loading :num="5"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalBoxVue v-if="openPaymentPlan" @handleClose="openPaymentPlan = false"
               :close="!true">
    <h4>Select Payment Plan</h4>
    <div class="container p-3">
      <form class="row p-3">
        <div class="" style="display: flex;justify-content: space-around">
          <div style="padding: 10px;width: 30%;border-radius: 6px; position: relative;"
               v-on:click="selectPaymentPlan = 'quarterly'"
               :class="
             selectPaymentPlan === 'quarterly' ? 'payplan-active' :'payplan-inactive'">
          <span
              style="color: #ffffff;margin-left: 21px;">Quarterly</span>
          </div>
          <div style="padding: 10px;width: 30%;border-radius: 6px;position: relative;"
               v-on:click="selectPaymentPlan = 'yearly'"
               :class=" selectPaymentPlan === 'yearly' ? 'payplan-active' :'payplan-inactive'">
          <span
              style="color: #ffffff;margin-left: 21px;">Yearly </span>
          </div>
        </div>
        <div class="col-md-12 mt-5">
          <button v-on:click="upgradeFreeToPaid" type="button" :disabled="updating" class="btn btn-primary"
                  style="margin: auto;display: table">
            <span v-if="updating">Upgrading...</span>
            <span v-else>Upgrade</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <ModalAlertVue v-if="processing_" @handleClose="processing_ = false" :close="!true">
    <div class="container p-3">
      <div class="row">
        <div class="col-md-12">
          <icon_loading/>
        </div>
        <div class="col-md-12">
          <p>{{ $t('please_wait') }}...</p>
        </div>
      </div>
    </div>
  </ModalAlertVue>
</template>

<script>
import {mapGetters} from 'vuex'
import ModalBoxVue from '../../components/ModalBox.vue'
import ShimmerLoading from "./reusableComponents/ShimmerLoading";
import ModalAlertVue from '../../components/ModalAlert.vue'
import icon_loading from "../../components/icon_loading";

export default {
  name: 'PlanDetails',
  components: {ModalBoxVue, ShimmerLoading, ModalAlertVue, icon_loading},
  data() {
    return {
      paymentPlan: ['free', 'small', 'standard', 'plus', 'enterprise'],
      packageInfo: [],
      selectPaymentPlan: 'quarterly',
      openPaymentPlan: false,
      updating: false,
      loading: true,
      processing_: false,
      quarterlyPlan: [],
      yearlyPlan: [],
      current_payment_plan: 'Quarterly',
      tier_price: 0,
      total_contract: 0
    }
  },
  computed: {
    ...mapGetters({
      GET_USER_SUBSCRIPTION: 'clients/GET_USER_SUBSCRIPTION',
      GET_USER: 'clients/GET_CURRENT_USER',
      GET_SUBSCRIPTION_PACKAGE: 'clients/GET_SUBSCRIPTION_PACKAGE',
      GET_LOCALE: 'locale/GET_LOCALE'
    })
  },
  mounted() {
    this.$store.dispatch('clients/getCurrentUserInfo')
    this.getUserSubscription()
    this.getPackageInfo()
    let self = this
    setTimeout(function () {
      self.getSubscriptionPackages()
    }, 1000)

  },
  methods: {
    getPackageInfo() {
      const payload = {
        URL: this.$services.endpoints.PACKAGE_ENDPOINT
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.packageInfo = res.data.results
          })
          .catch(err => {

          })
    },
    getUserSubscription() {
      this.$store.dispatch('clients/getUserSubscription')
          .then(res => {
            this.current_payment_plan = res.data.data[0].payment_plan
            this.getTier(res.data.data[0].tier)
          })
          .catch(err => {
          })
    },
    updatePaymentPlan(e) {
      this.$store.dispatch('clients/updatePaymentPlan', {
        payment_plan: this.GET_USER_SUBSCRIPTION[0].payment_plan,
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(res => {
            this.processing = false
            this.$services.helpers.notification(res.data.message, 'success', this)
          })
          .catch(err => {
            this.processing = false
            this.$services.helpers.notification(err.response.data.detail, 'error', this)
          })
    },
    upgradeFreeToPaid() {
      this.processing_ = true
      this.updating = true
      this.openPaymentPlan = false
      this.$store.dispatch('clients/updatePackagePlan', {
        package: 'Free',
        mode: 'upgrade',
        plan: this.selectPaymentPlan,
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(res => {
            this.updating = false
            this.processing_ = false
            this.$services.helpers.notification(res.data.message, 'success', this)
          })
          .catch(err => {
            this.updating = false
            this.processing_ = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    errorMessage(err) {
      try {
        if ([400, 406].includes(err.response.status)) {
          if ('error' in err.response.data) {
            if (Array.isArray(err.response.data.error)) {
              this.$services.helpers.notification(err.response.data.error.join(','), 'error', this)
            } else if (typeof err.response.data.error === 'object') {
              for (const [key, value] of Object.entries(err.response.data.error)) {
                if (Array.isArray(value)) {
                  this.$services.helpers.notification(key.replace('_', ' ') + ': ' + value.join(','), 'error', this)
                } else {
                  this.$services.helpers.notification(value, 'error', this)
                }
                return false
              }
            } else {
              this.$services.helpers.notification(err.response.data.error, 'error', this)
            }
          } else if ('message' in err.response.data) {
            if (Array.isArray(err.response.data.message)) {
              this.$services.helpers.notification(err.response.data.message.join(''), 'error', this)
            } else if (typeof err.response.data.message === 'object') {
              if ('message' in err.response.data.message) {
                if ('ErrorInformation' in err.response.data.message.message) {
                  this.$services.helpers.notification(err.response.data.message.message.ErrorInformation.message, 'error', this)
                }
              }
            } else {
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            }
          }
        } else {
          // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
          this.$toast.error(this.$t('request_failure'));
        }
      } catch (e) {
        // this.$services.helpers.notification('Action could not be perform', 'error', this)
        this.$toast.error(this.$t('action_failed'));
      }
    },
    changePlan(data, plan) {
      if (this.GET_USER.role !== 'Owner' && this.GET_USER.access !== 'Full') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      const indexOfItem = this.paymentPlan.indexOf(data)
      const userPlanIndex = this.paymentPlan.indexOf(this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase())
      let currentPlan = this.GET_USER_SUBSCRIPTION[0].payment_plan
      if (currentPlan === plan && this.paymentPlan[userPlanIndex] === data) {
        // return this.$services.helpers.notification('You are currently on this plan and package', 'error', this)
        return this.$toast.info(this.$t('already_on_package'));
      }
      this.processing_ = true
      if (indexOfItem > userPlanIndex) {
        if (currentPlan === plan) {
          // sending request to change package alone
          this.upgradePackageAlone(data)
        } else if (currentPlan !== plan) {
          // sending request to change package and plan
          this.changePlanAndUpgradePackage(data, plan)
        }
        // Upgrade package and change plan

      } else if (indexOfItem === userPlanIndex) {
        if (this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase() === 'free') {
          this.processing_ = false
          // return this.$services.helpers.notification('Kindly upgrade to paid package before performing this action', 'error', this)
          return this.$toast.info(this.$t('cannot_change_payment_plan_on_free'));
        }
        // change payment plan to the selected plan
        this.changePlanAlone(plan)
      } else {
        // Downgrade plan
        if (currentPlan === plan) {
          // sending request to change package alone
          this.downgradePackageAlone(data)
        } else if (currentPlan !== plan) {
          // sending request to change package and plan
          this.changePlanAndDowngradePackage(data, plan)
        }
      }
    },
    changePlanAndUpgradePackage(package_type, plan) {
      // method handle changing plan and package for new plan and package upgrade
      this.processing_ = true
      this.$store.dispatch('clients/updatePaymentPlan', {
        payment_plan: plan,
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(() => {
            this.$store.dispatch('clients/updatePackagePlan', {
              package: this.$services.helpers.removeUnderscoreAndCapString(package_type),
              mode: 'upgrade',
              uuid: this.GET_USER_SUBSCRIPTION[0].uuid
            })
                .then(res => {
                  this.processing_ = false
                  this.$services.helpers.notification(res.data.message, 'success', this)
                  this.getUserSubscription()
                  this.getSubscriptionPackages()
                  return true
                })
                .catch(err => {
                  this.processing_ = false
                  return this.errorMessage(err)
                })
          })
          .catch(err => {
            this.processing_ = false
            return this.errorMessage(err)
          })
    },
    changePlanAndDowngradePackage(package_type, plan) {
      this.processing_ = true
      this.$store.dispatch('clients/updatePaymentPlan', {
        payment_plan: plan,
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(() => {
            this.$store.dispatch('clients/updatePackagePlan', {
              package: this.$services.helpers.removeUnderscoreAndCapString(package_type),
              mode: 'downgrade',
              uuid: this.GET_USER_SUBSCRIPTION[0].uuid
            })
                .then(res => {
                  this.processing_ = false
                  this.$services.helpers.notification(res.data.message, 'success', this)
                })
                .catch(err => {
                  this.processing_ = false
                  this.errorMessage(err)
                })
          })
          .catch(err => {
            this.processing_ = false
            this.errorMessage(err)
          })
    },
    changePlanAlone(plan) {
      this.processing_ = true
      this.$store.dispatch('clients/updatePaymentPlan', {
        payment_plan: plan,
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(res => {
            this.processing_ = false
            // this.$services.helpers.notification('Subscription updated successfully', 'success', this)
            this.$toast.success(this.$t('paymentplan_updated'));
            this.getUserSubscription()
            this.getSubscriptionPackages()

          })
          .catch(err => {
            this.processing_ = false
            this.errorMessage(err)
          })
    },
    upgradePackageAlone(package_type) {
      this.processing_ = true
      this.$store.dispatch('clients/updatePackagePlan', {
        package: this.$services.helpers.removeUnderscoreAndCapString(package_type),
        mode: 'upgrade',
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(res => {
            this.processing_ = false
            this.$services.helpers.notification(res.data.message, 'success', this)
            this.getUserSubscription()
            this.getSubscriptionPackages()
            return true
          })
          .catch(err => {
            this.processing_ = false
            return this.errorMessage(err)
          })
    },
    downgradePackageAlone(package_type) {
      // downgrade plan alone
      this.processing_ = true
      this.$store.dispatch('clients/updatePackagePlan', {
        package: this.$services.helpers.removeUnderscoreAndCapString(package_type),
        mode: 'downgrade',
        uuid: this.GET_USER_SUBSCRIPTION[0].uuid
      })
          .then(res => {
            this.processing_ = false
            this.$services.helpers.notification(res.data.message, 'success', this)
          })
          .catch(err => {
            this.processing_ = false
            this.errorMessage(err)
          })
    },
    getSubscriptionPackages() {
      this.loading = true
      this.quarterlyPlan = []
      this.yearlyPlan = []
      this.$store.dispatch('clients/getSubscriptionPackages').then(resp => {
        this.loading = false
        let quarter_filter = resp.data.results.filter(item => item.plan === 'quarterly')
        let year_filter = resp.data.results.filter(item => item.plan === 'yearly')
        quarter_filter.map(item => {
          if (item.name !== 'free') {
            item.cost_price += this.tier_price
          }
        })
        year_filter.map(item => {
          if (item.name !== 'free') {
            item.cost_price += this.tier_price
          }
        })
        this.quarterlyPlan = quarter_filter
        this.yearlyPlan = year_filter
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    getTier(data) {
      const payload = {
        URL: this.$services.endpoints.TIER_ENDPOINT + `?tier_name=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            if (res.data.results.length > 0) {
              this.tier_price = res.data.results[0].add_on_price_monthly_average
              this.total_contract = res.data.results[0].number_of_allowed_contracts
            }
          })
          .catch(err => {

          })
    }
  }
}
</script>

<style lang="scss" scoped>

.payplan-active {
  background: #5855d6;

}

.payplan-inactive {
  background: #333269;

}

//.payplan-active span {
//  position: absolute;
//  left: 22px;
//}
//
//.payplan-inactive span {
//  position: absolute;
//  left: 22px;
//}

// body {
//   background-color: #f6f8fc;
// }
// body > .header ~ .row {
//   -webkit-flex-wrap: nowrap;
//       -ms-flex-wrap: nowrap;
//           flex-wrap: nowrap;
// }

@media (min-width: 1200px) {
  .responsive-width {
    overflow: hidden;
  }
}

@media (max-width: 1199.98px) {
  .responsive-width {
    width: -webkit-calc(100% - 64px);
    width: calc(100% - 64px);
  }
  .plan-info {
    padding-left: 64px !important;
  }
}

.box-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  height: 83px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.header > div {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .line {
  margin-right: 5%;
}

.header .nofication {
  margin: 0 10px;
}

.header .profile {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header .profile img {
  max-width: 100%;
  width: 40.06px;
  height: 40px;
  margin: 0 10px;
}

.header .profile .desc {
  color: #0a043c;
  margin-right: 20px;
}

.header .profile .desc h6 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.header .profile .desc span {
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  display: block;
}

.header .bubble {
  position: relative;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.header .bubble nav {
  padding: 10px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: -101px;
  width: 140px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 5px rgba(9, 30, 66, 0.2), 0px 1px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 5px 5px rgba(9, 30, 66, 0.2), 0px 1px 1px rgba(9, 30, 66, 0.31);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 33;
  min-height: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}

.header .bubble nav.active {
  visibility: visible;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.header .bubble nav a {
  position: relative;
  text-decoration: none;
  color: #0a043c;
}

.header .bubble nav a:hover:before, .header .bubble nav a:hover:after {
  width: 100%;
}

.header .bubble nav a span {
  position: relative;
  z-index: 3;
  padding: 10px 10px;
  padding: 5px 10px;
  display: block;
  font-size: 12px;
  opacity: 0.7;
}

.header .bubble nav a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0;
  background-color: #e3eefc;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.header .bubble nav a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #f8fbff;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0.4;
}

.header .bubble nav a:not(:last-of-type) {
  margin-bottom: 10px;
}

.sidebar {
  padding-top: 26px;
  background: #333269;
  position: relative;
  z-index: 3333;
  padding-right: 0;
  margin-left: 0;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  position: relative;
  z-index: 333;
}

@media (max-width: 1199.98px) {
  .sidebar {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  .sidebar:not(.close) {
    overflow: hidden auto;
  }
}

.sidebar .arrow-left {
  text-align: right;
}

.sidebar .arrow-left svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 18px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar .logo {
  text-align: center;
  max-width: 100%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar .logo img {
  max-width: 100%;
}

.sidebar nav {
  margin-top: 20px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

@media (max-width: 1199.98px) {
  .sidebar nav {
    margin-top: 5px;
  }
}

.sidebar nav ul {
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

@media (max-width: 1199.98px) {
  .sidebar nav ul {
    margin: 0;
  }
}

.sidebar nav ul li {
  cursor: pointer;
}

.sidebar nav ul li > a {
  border-radius: 7px;
  -webkit-transition: background 0.2s ease-in, color 0.1s ease-in;
  -o-transition: background 0.2s ease-in, color 0.1s ease-in;
  transition: background 0.2s ease-in, color 0.1s ease-in;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #c0beff;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-right: -3px;
}

.sidebar nav ul li > a .icon {
  margin-right: 10px;
  display: inline-block;
  margin-left: 7%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar nav ul li > a .icon path {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar nav ul li > a .icon rect {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar nav ul li > a .icon ~ span {
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.sidebar nav ul li.active > a {
  background-color: #f6f8fc;
  color: #333269;
}

.sidebar nav ul li.active > a path {
  fill: #333269;
}

.sidebar nav ul li.active > a rect {
  stroke: #333269;
}

.sidebar nav ul li.active > a .settings path {
  fill: none;
  stroke: #333269;
}

.sidebar nav ul li > a:hover {
  background-color: #f6f8fc;
  color: #333269;
}

.sidebar nav ul li > a:hover path {
  fill: #333269;
}

.sidebar nav ul li > a:hover rect {
  stroke: #333269;
}

.sidebar nav ul li > a:hover .settings path {
  fill: none;
  stroke: #333269;
}

.sidebar nav ul:not(.child-list) {
  padding: 30px 0 30px 5px;
  position: relative;
  z-index: 33;
}

@media (max-width: 1199.98px) {
  .sidebar nav ul:not(.child-list) {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar nav ul:not(.child-list):first-of-type {
    margin-bottom: 100px;
  }
}

.sidebar nav ul:not(.child-list) > li {
  background-color: #333269;
}

.sidebar nav ul:not(.child-list) > li.hover {
  -webkit-animation: disable-pointer-events 0.8s;
  animation: disable-pointer-events 0.8s;
}

.sidebar nav ul:not(.child-list) > li.active .child-list, .sidebar nav ul:not(.child-list) > li:hover .child-list {
  margin-top: 0;
  visibility: visible;
}

.sidebar nav ul:not(.child-list) > li > a {
  height: 48.36px;
}

.sidebar nav ul:not(.child-list) > li:not(:last-of-type) {
  margin-bottom: 15px;
}

.sidebar nav ul.child-list {
  margin-top: -22%;
  width: 85%;
  margin-left: auto;
  visibility: hidden;
  -webkit-transition: margin 0.4s ease-out, visibility 1ms ease-in 0.2s;
  -o-transition: margin 0.4s ease-out, visibility 1ms ease-in 0.2s;
  transition: margin 0.4s ease-out, visibility 1ms ease-in 0.2s;
}

.sidebar nav ul.child-list.active {
  margin-top: 0;
  visibility: visible;
}

.sidebar nav ul.child-list li {
  color: #333269;
}

.sidebar nav ul.child-list li.active a::before, .sidebar nav ul.child-list li:hover a::before {
  background-color: #333269;
}

.sidebar nav ul.child-list li a {
  height: 40px;
}

.sidebar nav ul.child-list li a::before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #c0beff;
  margin-right: 10px;
  display: inline-block;
  margin-left: 7%;
}

.sidebar nav ul.child-list li:first-of-type {
  margin-top: 5px;
}

.sidebar nav ul.child-list li:not(:last-of-type) {
  margin-bottom: 15px;
}

.sidebar.close {
  margin-left: -125px;
  width: 191px;
}

.sidebar.close #btn {
  -webkit-transform: rotate(0.5turn);
  -ms-transform: rotate(0.5turn);
  transform: rotate(0.5turn);
}

.sidebar.close .logo {
  max-width: 0;
}

.sidebar.close nav {
  padding-top: 20px;
}

@media (max-width: 1199.98px) {
  .sidebar.close nav {
    padding-top: 5px;
  }
}

.sidebar.close ul:not(.child-list) {
  margin: 0 !important;
  padding: 0;
}

.sidebar.close ul:not(.child-list):last-of-type {
  margin-top: 15px !important;
}

.sidebar.close ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.sidebar.close ul li a {
  padding: 7px 15px;
  margin-right: 9px;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.sidebar.close ul li a .icon {
  margin: 0;
}

.sidebar.close ul li a .icon ~ span {
  display: none;
}

.sidebar.close ul li .child-list {
  display: none;
}

@-webkit-keyframes disable-pointer-events {
  0%, 99% {
    pointer-events: none;
  }
}

@keyframes disable-pointer-events {
  0%, 99% {
    pointer-events: none;
  }
}

@media (min-width: 1200px) {
  .sidebar.close {
    position: fixed;
    height: 100%;
  }
  
}

.responsive-width {
    width: 100% !important;
    overflow: unset;
  }

.plan-info {
  padding: 123px 10px 40px;
  background: #4d82f3;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #f6f8fc;
}

.plan-info p {
  margin: 0;
}

.plan-info p strong {
  font-size: 18px;
}

.plan-info p span {
  font-size: 18px;
}

.content {
  padding: 10px 20px;
  background-color: #f6f8fc;
}

.content h1 {
  margin-top: 15px;
  font-size: 42px;
  line-height: 58px;
  color: #333269;
}

.content nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.content nav > ul {
  background: #ffffff;
  border-radius: 22px;
  -webkit-box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
  box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content nav > ul li input {
  display: none;
}

.content nav > ul li input:checked ~ label {
  pointer-events: none;
  color: #fff;
}

.content nav > ul li input:not(:checked) ~ .plans-content {
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  opacity: 0;
  z-index: -1;
}

.content nav > ul li label {
  text-align: center;
  border-radius: 22px;
  width: 111px;
  height: 44px;
  line-height: 44px;
  font-weight: 500;
  font-size: 12px;
  color: #8f8e94;
  cursor: pointer;
  position: relative;
  z-index: 3;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.content nav > ul li:last-of-type {
  margin-left: 5px;
}

.content nav > ul li:first-of-type {
  margin-right: 5px;
}

.content nav > ul li:first-of-type input:checked ~ label::before {
  left: 0;
}

.content nav > ul li:first-of-type label:before {
  content: "";
  position: absolute;
  width: 111px;
  height: 44px;
  background-color: #5243c2;
  border-radius: 22px;
  left: -webkit-calc(100% + 10px);
  left: calc(100% + 10px);
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav > ul li .plans-content {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  -webkit-transition: opacity 0.5s ease-out, -webkit-transform 1s ease-out;
  transition: opacity 0.5s ease-out, -webkit-transform 1s ease-out;
  -o-transition: transform 1s ease-out, opacity 0.5s ease-out;
  transition: transform 1s ease-out, opacity 0.5s ease-out;
  transition: transform 1s ease-out, opacity 0.5s ease-out, -webkit-transform 1s ease-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  z-index: 2;
}

@media (max-width: 767.98px) {
  .content nav > ul li .plans-content {
    top: 140px;
  }
}

.content nav > ul li .plans-content .plans {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .content nav > ul li .plans-content .plans {
    background: #ffffff;
    border-radius: 20px;
    -webkit-filter: drop-shadow(0px 7.21577px 10.8237px rgba(9, 30, 66, 0.15)) drop-shadow(0px 0px 0.901972px rgba(9, 30, 66, 0.31));
    filter: drop-shadow(0px 7.21577px 10.8237px rgba(9, 30, 66, 0.15)) drop-shadow(0px 0px 0.901972px rgba(9, 30, 66, 0.31));
  }
}

.content nav > ul li .plans-content .plans .plan {
  padding: 25px 20px 20px;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
}

@media (max-width: 991.98px) {
  .content nav > ul li .plans-content .plans .plan {
    -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  }
}

.content nav > ul li .plans-content .plans .plan .price {
  color: #231d4f;
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content nav > ul li .plans-content .plans .plan .price sub {
  color: #848199;
  font-weight: 500;
  font-size: 12.6327px;
  margin-left: 0.3rem;
}

.content nav > ul li .plans-content .plans .plan h3 {
  font-weight: 900;
  font-size: 20.8068px;
  color: #231d4f;
  margin-bottom: 15px;
}

.content nav > ul li .plans-content .plans .plan .desc {
  font-weight: 500;
  font-size: 11.1465px;
  color: #848199;
  margin-bottom: 20px;
}

.content nav > ul li .plans-content .plans .plan ul {
  padding: 0;
  margin: 0 0 20px;
}

.content nav > ul li .plans-content .plans .plan ul li {
  list-style: none;
  background: url(./../../assets/images/icons-clickable/plan-detail-regular.png) no-repeat left top;
  background-size: 14px 14px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 20px;
}

.content nav > ul li .plans-content .plans .plan ul li:not(:last-of-type) {
  margin-bottom: 15px;
}

.content nav > ul li .plans-content .plans .plan ul li::marker {
  margin-top: 10px;
}

.content nav > ul li .plans-content .plans .plan button {
  background: #64748b1a;
  border-radius: 17.8344px;
  font-weight: 900;
  font-size: 11.1465px;
  text-align: center;
  color: #27364b;
  border: none;
  display: block;
  width: 100%;
  padding: 10px;
  margin: auto auto 0;
}

.content nav > ul li .plans-content .plans .plan.current {
  position: relative;
}

.content nav > ul li .plans-content .plans .plan.current:before {
  content: "Current Plan";
  position: absolute;
  top: -52px;
  z-index: -1;
  left: 0;
  width: 80%;
  color: #ffffff;
  font-size: 16px;
  background: #333269;
  border-radius: 20px 20px 0px 0px;
  height: 65px;
  line-height: 55px;
  text-align: center;
}

.content nav > ul li .plans-content .plans .plan.popular {
  //   background: url('./../../assets/images/plan-details-popular-background.png') no-repeat right top;
  background-color: #5855d6;
  border-radius: 19.3206px;
  position: relative;
  width: 96% !important;
  left: 1px;
}

@media (min-width: 992px) {
  .content nav > ul li .plans-content .plans .plan.popular {
    -webkit-transform: translateY(-35px);
    -ms-transform: translateY(-35px);
    transform: translateY(-35px);
    -webkit-box-shadow: 0px 31.2102px 25.2654px rgba(82, 67, 194, 0.295755);
    box-shadow: 0px 31.2102px 25.2654px rgba(82, 67, 194, 0.295755);
  }
}

.content nav > ul li .plans-content .plans .plan.popular:after {
  position: absolute;
  content: "Nuvarande paket";
  color: #dffeff;
  font-weight: 900;
  font-size: 7.431px;
  text-align: center;
  letter-spacing: 0.61925px;
  background: #4031b3;
  border-radius: 10.0318px;
  top: 10px;
  right: 10px;
  padding: 4px 9px;
}

.content nav > ul li .plans-content .plans .plan.popular h3,
.content nav > ul li .plans-content .plans .plan.popular p,
.content nav > ul li .plans-content .plans .plan.popular sub,
.content nav > ul li .plans-content .plans .plan.popular button {
  color: #fff;
}

.content nav > ul li .plans-content .plans .plan.popular ul li {
  color: #f6f8fc;
  background-image: url('./../../assets/images/icons-clickable/plan-detail-popular.png');
}

.content nav > ul li .plans-content .plans .plan.popular button {
  background: #333269;
}
.plan-row{
  display: flex;
  flex-wrap: wrap;
}
.plan-row .plan-col{
  width: 20%;
}
body .content{
  padding-top: 20px !important;
}
</style>
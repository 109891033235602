<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <form @submit.prevent="createProductSegment" class="content" v-if="tab === 1">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t('products') }}</h1>
          <h6>{{ $t('create_new_product_segment') }}</h6>
        </div>
        <div class="box product">
          <h3>{{ $t('product_segment') }}</h3>
          <div class="form row">
            <div class="col">
              <div class="desc">
                <label>{{ $t('name') }}</label>
                <input type="text" v-model="segmentArticleObj.name" required>
              </div>
              <div class="desc">
                <label>{{ $t('supplier') }}</label>
                <select v-model="segmentArticleObj.supplier">
                  <option value=""></option>
                  <option :value="item.supplier_number" v-for="item in suppliers" :key="item.id">{{
                      item.name
                    }}
                  </option>
                </select>
              </div>
            </div>
            <!--            <div class="col">-->
            <!--              <div class="desc">-->
            <!--                <label>Inventory location</label>-->
            <!--                <input type="text"-->
            <!--                       v-model="segmentArticleObj.inventory_location">-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="col">
              <div class="desc">
                <label>{{ $t('unit') }}</label>
                <select class="small" v-model="segmentArticleObj.unit">
                  <option value=""></option>
                  <option :value="item.code" v-for="item in units" :key="item.id">{{ item.code }}</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="desc">
                <label>Stock item</label>
                <div class="radio">
                  <input type="radio" v-model="segmentArticleObj.stock_item" :value="true" name="stock-item"
                         v-on:change="handleTypeChange"
                         id="stock-yes">
                  <label for="stock-yes">Yes</label>
                  <input type="radio" v-model="segmentArticleObj.stock_item" :value="false" name="stock-item"
                         v-on:change="handleTypeChange"
                         id="stock-no">
                  <label for="stock-no">No</label>
                </div>
              </div>
              <div class="desc">
                <div class="desc">
                  <label>{{ $t('product_type') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="segmentArticleObj.product_type" value="STOCK"
                           name="product-type" v-on:change="handleTypeChange"
                           id="product-goods">
                    <label for="product-goods">{{ $t('goods') }}</label>
                    <input type="radio" v-model="segmentArticleObj.product_type" value="SERVICE"
                           name="product-type" v-on:change="handleTypeChange"
                           id="product-services">
                    <label for="product-services">{{ $t('services') }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="desc">
                <label>{{ $t('external_webshop') }}</label>
                <div class="radio">
                  <input type="radio" v-model="segmentArticleObj.external_webshop" :value="true" name="external-webshop"
                         id="external-yes">
                  <label for="external-yes">{{ $t('yes') }}</label>
                  <input type="radio" v-model="segmentArticleObj.external_webshop" :value="false"
                         name="external-webshop" id="external-no">
                  <label for="external-no">{{ $t('no') }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('discontinued') }}</label>
                <div class="radio">
                  <input type="radio" v-model="segmentArticleObj.discontinued" :value="true" name="discontinued"
                         id="discontinued-yes" checked>
                  <label for="discontinued-yes">{{ $t('yes') }}</label>
                  <input type="radio" v-model="segmentArticleObj.discontinued" :value="false" name="discontinued"
                         id="discontinued-no">
                  <label for="discontinued-no">{{ $t('no') }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box account">
          <h3>{{ $t('account_detail') }}</h3>
          <div class="form row">
            <div @click="removeAccountBox"
                 v-if="showSalesAccount || showSalesSeReverseVat || showSalesEuReverseVat || showPurchaseAccount"
                 class="position-absolute inset-0" style="z-index:999"></div>

            <div class="col-lg col">
              <div class="row">
                <div class="col">
                  <div class="desc">
                    <label>{{ $t('sales_account_eu_vat') }}</label>
                    <input @input="searchField($event, 'eu_vat')" type="text"
                           v-model="payload.sales_account_eu_vat">
                    <div v-if="showSalesAccount" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'eu_vat')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="desc">
                    <label>{{ $t('sales_account_se') }}</label>
                    <input @input="searchField($event, 'se_vat')" type="text"
                           v-model="payload.sales_account_se">
                    <div v-if="showSalesSeReverseVat" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'se_vat')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t('sales_account_export') }}</label>
                    <input @input="searchField($event, 'sales')" type="text"
                           v-model="payload.sales_account_export">
                    <div v-if="showSalesEuReverseVat" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'sales')">{{
                            item.number + ' ' + item.description
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t('purchase_account') }}</label>
                    <input @input="searchField($event, 'purchase')" type="text"
                           v-model="payload.purchase_account">
                    <div v-if="showPurchaseAccount" style="z-index:9999999; width:12%"
                         class="position-absolute bg-white rounded shadow-md border mt-2">
                      <ul style="padding-left: 0rem !important; list-style-type:none">
                        <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                            :key="item.id" @click="chooseSales(item, 'purchase')">
                          {{ item.number + ' ' + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="desc">
                      <label>{{ $t('description') }}</label>
                      <textarea v-model="segmentArticleObj.description" required></textarea>
                    </div>
                  </div>
                  <div class="col">
                    <div class="desc mb-0">
                      <label>{{ $t('note') }}</label>
                      <textarea v-model="segmentArticleObj.notes"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 align-self-md-end"><img class="ms-auto d-block"
                                                                  src="./../../assets/images/table/products-account-details.png"
                                                                  alt=""></div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <div class="checkbo me-2">
            <input type="checkbox" class="form-check-input" v-model="agree">
          </div>
          <p>
            *{{ $t('product_segment_agreement') }}
          </p>
        </div>
        <div class="buttons">
          <a href="javascript:void(0);" @click="$router.go(-1)">{{ $t('cancel') }}</a>
          <button type="submit" :disabled="processing" v-if="segment_id === ''">
            <span v-if="processing">{{ $t('creating') }}...</span>
            <span v-else>{{ $t('create_segment') }}</span>
          </button>
          <div v-else>
            <button type="button" :disabled="processing" v-on:click="updateProductSegment">
              <span v-if="processing">{{ $t('updating') }}...</span>
              <span v-else>{{ $t('update') }}</span>
            </button>
            <button type="button" v-on:click="switchTab(2)">
              {{ $t('assign_product') }}
            </button>
          </div>
          <!--          <button type="submit">-->
          <!--            <span v-if="processing">Creating...</span>-->
          <!--            <span v-else>Create</span>-->
          <!--          </button>-->
        </div>
      </form>
      <div class="content" v-if="tab === 2">
        <div class="headers d-flex">
          <div>
            <h1 class="mb-0">Products</h1><span>{{ $t('add_product_to') }} <strong>{{
              segmentArticleObj.name
            }}</strong>  {{ $t('segment') }}</span>
          </div>
        </div>
        <nav>
          <div class="right-parent">
            <div class="d-flex flex-wrap align-items-center">
              <div class="search">
                <label for="search">
                  <svg width="15" height="15" viewbox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.6219 13.5596L10.0568 8.99453C10.7652 8.07871 11.1484 6.95898 11.1484 5.78125C11.1484 4.37148 10.5982 3.04961 9.60332 2.05293C8.6084 1.05625 7.28301 0.507812 5.875 0.507812C4.46699 0.507812 3.1416 1.05801 2.14668 2.05293C1.15 3.04785 0.601562 4.37148 0.601562 5.78125C0.601562 7.18926 1.15176 8.51465 2.14668 9.50957C3.1416 10.5063 4.46523 11.0547 5.875 11.0547C7.05273 11.0547 8.1707 10.6715 9.08652 9.96484L13.6516 14.5281C13.6649 14.5415 13.6808 14.5521 13.6983 14.5594C13.7158 14.5666 13.7346 14.5704 13.7535 14.5704C13.7725 14.5704 13.7912 14.5666 13.8087 14.5594C13.8262 14.5521 13.8421 14.5415 13.8555 14.5281L14.6219 13.7635C14.6353 13.7501 14.6459 13.7342 14.6531 13.7167C14.6604 13.6992 14.6641 13.6805 14.6641 13.6615C14.6641 13.6426 14.6604 13.6238 14.6531 13.6063C14.6459 13.5889 14.6353 13.573 14.6219 13.5596ZM8.65938 8.56563C7.91406 9.30918 6.92617 9.71875 5.875 9.71875C4.82383 9.71875 3.83594 9.30918 3.09063 8.56563C2.34707 7.82031 1.9375 6.83242 1.9375 5.78125C1.9375 4.73008 2.34707 3.74043 3.09063 2.99688C3.83594 2.25332 4.82383 1.84375 5.875 1.84375C6.92617 1.84375 7.91582 2.25156 8.65938 2.99688C9.40293 3.74219 9.8125 4.73008 9.8125 5.78125C9.8125 6.83242 9.40293 7.82207 8.65938 8.56563Z"
                        fill="#8F8E94"></path>
                  </svg>
                </label>
                <input id="search" @input="searchProductField" type="text" :placeholder="$t('search')">
              </div>
            </div>
          </div>
        </nav>
        <div class="box">
          <div class="box-responsive">
            <table>
              <thead>
              <tr>
                <th>
                  <div class="checkbox">
                    <!--                    <input type="checkbox">-->
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </th>
                <th><span>{{ $t('article_number') }}</span></th>
                <th><span>{{ $t('description') }}</span></th>
                <th><span>{{ $t('price_list') }}</span></th>
                <th><span>{{ $t('purchase_price') }}</span></th>
                <th><span>{{ $t('unit') }}</span></th>
                <th><span>{{ $t('amount_sold') }}</span></th>
                <!--                <th><span>Discontinued</span></th>-->
                <!--                <th><span>Stock Item</span></th>-->
                <th><span>{{ $t('type') }}</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in products.data" :key="key">
                <td>
                  <div class="checkbox">
                    <input type="checkbox" v-model="item.checked" v-on:change="checkboxOrder(key,item.id)"
                           :value="item.checked">
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </td>
                <td>{{ item.article_number }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.sales_price }}</td>
                <td>{{ item.purchase_price }}</td>
                <td>{{ item.unit }}</td>
                <td>{{ item.amount_sold }}</td>
                <!--                <td class="no">No</td>-->
                <!--                <td>{{  item }}</td>-->
                <td>{{ item.type }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex justify-content-end w-full mt-3">
          <v-pagination
              v-model="products.page"
              :pages="products.total_pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="changePageNumber"
          />
        </div>
        <div class="buttons flex-wrap align-items-center">
          <a href="javascript:void(0);" @click="tab = 1">{{ $t('previous') }}</a>
          <button type="button" v-on:click="processSegmentAction">
            <span v-if="addingProduct === false">{{ $t('add') }} {{ $t('product') }} {{ $t('to') }} {{
                $t('segment')
              }}</span>
            <span v-else>{{ $t('adding') }} {{ $t('product') }} {{ $t('to') }} {{ $t('segment') }}...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import {segmentArticle} from './composables/segment.create'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import {mapGetters} from "vuex"

export default {
  name: 'CreateProductSegment',
  components: {VPagination},
  data() {
    return {
      tab: 1,
      segmentArticleObj: {...segmentArticle},
      suppliers: [],
      units: [],
      accounts: [],
      agree: false,
      showSalesAccount: false,
      showPurchaseAccount: false,
      showSalesEuReverseVat: false,
      showSalesSeReverseVat: false,
      processing: false,
      addingProduct: false,
      payload: {
        sales_account_eu_vat: '',
        sales_account_se: '',
        sales_account_export: '',
        purchase_account: '',
      },
      products: [],
      productsSegmentArr: [],
      productSegmentToRemoveArr: [],
      segment_id: ''

      // showExportAccount : false,
    }
  },
  mounted() {
    this.getSupplier()
    this.getUnit()
    this.getArticles()
    // this.getAccount()
  },
  methods: {
    handleTypeChange: function () {
      if (this.segmentArticleObj.product_type === 'SERVICE') {
        this.segmentArticleObj.stock_item = false
      }
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            if (mode === 'eu_vat') {
              this.showSalesAccount = true
            } else if (mode === 'se_vat') {
              this.showSalesSeReverseVat = true
            } else if (mode === 'sales') {
              this.showSalesEuReverseVat = true
            } else if (mode === 'purchase') {
              this.showPurchaseAccount = true
            }
          })
          .catch(err => {

          })
    },
    chooseSales(item, mode) {
      if (mode === 'sales') {
        this.segmentArticleObj.sales_account_export = item.number
        this.payload.sales_account_export = item.number
        this.showSalesEuReverseVat = false
      } else if (mode === 'se_vat') {
        this.showSalesSeReverseVat = false
        this.segmentArticleObj.sales_account_se = item.number
        this.payload.sales_account_se = item.number
      } else if (mode === 'eu_vat') {
        this.segmentArticleObj.sales_account_eu_vat = item.number
        this.payload.sales_account_eu_vat = item.number
        this.showSalesAccount = false
      } else if (mode === 'purchase') {
        this.segmentArticleObj.purchase_account = item.number
        this.payload.purchase_account = item.number
        this.showPurchaseAccount = false
      }
    },
    createProductSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      if (this.segmentArticleObj.name === '' || this.segmentArticleObj.description === '') {
        // this.$services.helpers.notification('Name and description are required', 'error', this)
        this.$toast.warning(this.$t('name_description_required'));

      } else if (!this.agree) {
        // this.$services.helpers.notification('Please agree to the terms & conditions', 'error', this)
        this.$toast.warning(this.$t('segment_settings_confirmation'));
      } else {
        let payload = this.segmentArticleObj
        for (const [key, value] of Object.entries(payload)) {
          if (value === '' || value === null) {
            delete payload[key]
          }
        }
        this.processing = true
        this.$store.dispatch('clients/createProductSegment', payload)
            .then(res => {
              this.processing = false
              // this.segmentArticleObj = {...segmentArticle}
              // this.$services.helpers.notification('Product segment created successfully', 'success', this)
              this.$toast.success(this.$t('segment_added'));
              this.segment_id = res.data.id
              this.switchTab(2)
            })
            .catch(err => {
              this.processing = false
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            })
      }
    },
    getSupplier() {
      const URL = this.$services.endpoints.BASE_ENDPOINT + 'suppliers'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.suppliers = res.data.results
          })
          .catch(err => {
          })
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'units'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.units = res.data.results
          })
          .catch(err => {
          })
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'accounts'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.accounts = res.data.results
          })
          .catch(err => {
          })
    },
    removeAccountBox() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.showSalesAccount = false
      this.showSalesSeReverseVat = false
      this.showSalesEuReverseVat = false
      this.showPurchaseAccount = false
    },
    switchTab(number) {
      this.tab = parseInt(number)
    },
    getArticles() {
      this.$store.dispatch('clients/getProduct', 'customize=no').then(resp => {
        this.products = resp.data
        this.products.data.map(item => {
          item.checked = false
        })
      })
    },
    changePageNumber(number) {
      let payload = ''
      if (this.filter) {
        payload = `page=${number}&${this.filter}&customize=no`
      } else {
        payload = `page=${number}&customize=no`
      }
      this.$store.dispatch('clients/getProduct', payload).then(resp => {
        this.products = resp.data
        this.products.data.map(item => {
          if (this.productsSegmentArr.includes(item.id)) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      })
    },
    checkboxOrder(index, id) {
      if (this.productsSegmentArr.includes(id)) {
        let indexID = this.productsSegmentArr.findIndex(item => item === id)
        if (indexID !== -1) {
          let productID = this.productsSegmentArr[indexID]
          if (!this.productSegmentToRemoveArr.includes(productID)) {
            this.productSegmentToRemoveArr.push(productID)
          }
          this.productsSegmentArr.splice(indexID, 1)
        }
      } else {
        this.productsSegmentArr.push(id)
        if (this.productSegmentToRemoveArr.includes(id)) {
          let indexID = this.productSegmentToRemoveArr.findIndex(item => item === id)
          if (indexID !== -1) {
            this.productSegmentToRemoveArr.splice(indexID, 1)
          }
        }
      }
    },
    updateProductSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      let data_to_save = this.segmentArticleObj
      for (const [key, value] of Object.entries(data_to_save)) {
        if (value === '' || value === null) {
          delete data_to_save[key]
        }
      }
      this.creating = true
      const payload = {
        data: data_to_save,
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `articles/${this.segment_id}/`
      }
      this.$store.dispatch('clients/customPutRequest', payload)
          .then(res => {
            this.creating = false
            // this.$services.helpers.notification('Products segment updated successfully', 'success', this)
            this.$toast.success(this.$t('segment_updated'));
            // this.switchTab(2)
          })
          .catch(err => {
            this.creating = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    processSegmentAction() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      } else {
        this.addProductToSegment()
        this.removeProductFromSegment()

      }
    },
    addProductToSegment() {
      if (this.productsSegmentArr.length === 0) {
        // return this.$services.helpers.notification('Kindly select one or more product', 'error', this)
        return this.$toast.warning(this.$t('chooce_article'));
      }
      if (this.segment_id === '') {
        // return this.$services.helpers.notification('Request can not be process,kindly try again', 'error', this)
        return this.$toast.error(this.$t('request_failure'));
      }
      let payload = {
        'data': {
          'articles': this.productsSegmentArr
        },
        'URL': this.$services.endpoints.SEGMENT_ENDPOINT + `articles/${this.segment_id}/add_articles/`
      }
      this.addingProduct = true
      this.$store.dispatch('clients/customPutRequest', payload).then(resp => {
        // this.$services.helpers.notification('Products added to segment successfully', 'success', this)
        this.$toast.success(this.$t('articles_added'));
        this.addingProduct = false
      }).catch(error => {
        this.$services.helpers.notification(error.response.data.message, 'error', this)
        this.addingProduct = false
      })

    },
    removeProductFromSegment() {
      if (this.productSegmentToRemoveArr.length > 0) {
        let payload = {
          'data': {
            'articles': this.productSegmentToRemoveArr
          },
          'URL': this.$services.endpoints.SEGMENT_ENDPOINT + `articles/${this.segment_id}/remove_articles/`
        }
        this.$store.dispatch('clients/customPutRequest', payload).then(_ => {
          this.productSegmentToRemoveArr = []
        })
      }
      this.$router.push({name: 'productSegment'})
    },
    searchProductField: debounce(function (event) {
      if (event.target.value !== '') {
        this.filterProductSearch(event.target.value)
      } else {
        this.getArticles()
      }
    }, 500),
    filterProductSearch(data) {
      const payload = `search=${data}&customize=no`
      this.filter = payload
      this.$store.dispatch('clients/getProduct', payload).then(resp => {
        this.products = resp.data
        this.products.data.map(item => {
          if (this.productsSegmentArr.includes(item.id)) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      })
    },
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.product h3 {
  background-image: url("./../../assets/images/table/product.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box.account h3 {
  background-image: url("./../../assets/images/table/account.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box h3 {
  background: #5855d6;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  margin: 0;
}

.content .box .form {
  padding: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.content .box .form h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.content .box .form .desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
}

.content .box .form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box .form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 195px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box .form .desc select.small {
  width: 94px;
}

.content .box .form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 100%;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box .form .desc input.small {
  width: 94px;
}

.content .box .form .desc input:focus {
  outline: none;
}

.content .box .form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box .form .desc .radio input {
  display: none;
}

.content .box .form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box .form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 12.469px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box .form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box .form textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

.content .box .form textarea:focus {
  border: none;
  outline: none;
}

.content .box:last-of-type {
  margin-bottom: 20px;
}

.content .box img {
  max-width: 100%;
}

.content .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 18px;
  width: 18px;
  z-index: 33;
  background: #fffbfb;
}

.content .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .checkbox input,
.content .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .checkbox input:checked ~ svg {
  display: block;
}

.content .checkbox svg {
  position: absolute;
  height: 20px;
  width: 20px;
  display: none;
  z-index: 1;
}

.content .checkbox ~ p {
  margin: 0;
  margin-left: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-top: 20px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
  text-align: center;
}

.content .buttons a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content nav {
  margin: 20px 0 0 5px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.content nav .right-parent .filter {
  margin-right: 20px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 width:207.5px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 77%;
  margin-left: auto;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .customize {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .customize {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.content nav .right-parent .customize a {
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content nav .right-parent .customize a svg {
  margin-right: 10px;
}

.content nav .right-parent .customize a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content nav .right-parent .customize a:hover {
  color: #4946ae;
}

.content nav .right-parent .customize a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2), 0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2), 0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 20px;
  width: 20px;
  position: relative;
  z-index: 33;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #fffbfb;
  cursor: pointer;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 20px;
  width: 20px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td:nth-of-type(8).no {
  color: #419e6a;
}

.content .box table tbody td:nth-of-type(8).yes {
  color: #fb4b4b;
}
</style>
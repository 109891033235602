<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <form class="content" @submit.prevent="createCustomerSegment" v-if="tab === 1">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t('customers') }}</h1>
          <h6>{{ $t('create_new_customer_segment') }}</h6>
        </div>
        <div class="box segment-details">
          <div @click="showSalesAccount = false" v-if="showSalesAccount" class="position-absolute inset-0"
               style="z-index:999"></div>
          <h3>{{ $t('segment_details') }}</h3>
          <div class="form segment-details row">
            <div class="col-lg-3 col flex-wrap">
              <div class="desc">
                <label>{{ $t('name') }}</label>
                <input type="text" v-model="customerSegment.name">
              </div>
              <div class="parent-small d-flex flex-wrap">
                <div class="desc">
                  <label>{{ $t('active') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="customerSegment.active" :value="true" name="active"
                           id="billing-yes">
                    <label for="billing-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="customerSegment.active" :value="false"
                           name="active" id="billing-no">
                    <label for="billing-no">{{ $t('no') }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('way_of_delivery') }}</label>
                  <select class="small" v-model="customerSegment.delivery_method">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in deliveryWay" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg col d-flex d-lg-block flex-wrap">
              <div class="parent-small d-flex flex-wrap">
                <div class="desc">
                  <label>{{ $t('terms_of_payment') }}</label>
                  <select class="small" v-model="customerSegment.terms_of_payment">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in paymentTerm" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('terms_of_delivery') }}</label>
                  <select class="small" v-model="customerSegment.terms_of_delivery">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in deliveryTerm" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('show_price_vat_included') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="customerSegment.price_include_vat" :value="true" name="vat"
                           id="vat-yes" checked>
                    <label for="vat-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="customerSegment.price_include_vat" :value="false" name="vat"
                           id="vat-no">
                    <label for="vat-no">{{ $t('no') }}</label>
                  </div>
                </div>
              </div>
              <div class="parent-small d-flex flex-wrap">
                <div class="desc">
                  <label>{{ $t('invoice_discount') }} %</label>
                  <input class="small" type="text" v-model="customerSegment.invoice_discount">
                </div>
                <div class="desc">
                  <label>{{ $t('invoice_charge') }}</label>
                  <input class="small" type="text" v-model="customerSegment.invoice_charge">
                </div>
                <div class="desc">
                  <label>{{ $t('shipping_charge') }}</label>
                  <input class="small" type="text" v-model="customerSegment.shipping_charge">
                </div>
              </div>
            </div>
            <div class="col-lg col-12 d-flex d-lg-block flex-wrap">
              <div class="parent-small d-flex flex-wrap">
                <div class="desc">
                  <label>{{ $t('sales_account') }}</label>
                  <input class="small" @input="searchField($event, 'sales')" type="text"
                         v-model="sales_account">
                  <div v-if="showSalesAccount" style="z-index:9999999; width:12%"
                       class="position-absolute bg-white rounded shadow-md border mt-2">
                    <ul style="padding-left: 0rem !important; list-style-type:none">
                      <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                          :key="item.id" @click="chooseSales(item, 'sales')">{{ item.number + ' ' + item.description }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!--                <div class="desc">-->
                <!--                  <label>Cost centre</label>-->
                <!--                  <select class="small" v-model="customerSegment.cost_center">-->
                <!--                    <option value=""></option>-->
                <!--                    <option :value="item.id" v-for="item in costCenter" :key="item.id">{{ item.description }}</option>-->
                <!--                  </select>-->
                <!--                </div>-->
                <!--                <div class="desc">-->
                <!--                  <label>Project</label>-->
                <!--                  <select class="small" v-model="customerSegment.project">-->
                <!--                    <option value=""></option>-->
                <!--                    <option :value="item.id" v-for="item in project" :key="item.id">{{ item.description }}</option>-->
                <!--                  </select>-->
                <!--                </div>-->
                <div class="desc">
                  <label>{{ $t('price_list') }}</label>
                  <select class="small" v-model="customerSegment.price_list">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in pricelist" :key="item.id">{{ item.description }}</option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('currency') }}</label>
                  <select class="small" v-model="customerSegment.currency">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in currency" :key="item.id">{{ item.code }}</option>
                  </select>
                </div>
              </div>
              <div class="parent-small d-flex flex-wrap">
                <div class="desc">
                  <label>{{ $t('type_of_vat') }}</label>
                  <select v-model="customerSegment.type_of_vat" class="small">
                    <option value=""></option>
                    <option value="SEVAT">SE</option>
                    <!-- <option value="SEREVERSEDVAT">SE reverse Vat</option>
                    <option value="EUREVERSEDVAT">EU reverse Vat</option> -->
                    <option value="EUVAT">Subject to EU VAT</option>
                    <option value="EXPORT">Export</option>
                  </select>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="box e-document">
          <h3>{{ $t('e_document_distribution_way') }}</h3>
          <div class="form row">
            <div class="col-lg">
              <h5>{{ $t('preselected_distribution_ways') }}</h5>
              <div class="parent-small d-flex more-margin flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('invoice') }}</label>
                  <select class="small" v-model="customerSegment.invoice">
                    <option value=""></option>
                    <option value="einvoice">{{ $t('e_invoice') }}</option>
                    <option value="email">{{ $t('email') }}</option>
                    <option value="print">{{ $t('print') }}</option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('quote') }}</label>
                  <select v-model="customerSegment.quote" class="small">
                    <option value=""></option>
                    <option value="email">Email</option>
                    <option value="print">Print</option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('order') }}</label>
                  <select v-model="customerSegment.order" class="small">
                    <option value=""></option>
                    <option value="email">Email</option>
                    <option value="print">Print</option>
                  </select>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('invoice_text') }}</label>
                <textarea class="w-100" v-model="customerSegment.invoice_text"></textarea>
              </div>
              <div class="desc">
                <label>{{ $t('description') }}</label>
                <textarea class="w-100" v-model="customerSegment.description" required></textarea>
              </div>
            </div>
            <div class="col-lg">
              <h5>{{ $t('preselected_template') }}</h5>
              <div class="d-flex col-flex-wrap">
                <div class="left desc">
                  <h4>{{ $t('invoice') }}</h4>
                  <div class="parent-small d-flex flex-wrap">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="customerSegment.invoice_template">
                        <option value=""></option>
                        <option v-for="(item, i) in printOutInvoiceTem" :value="item.id" :key="i">{{
                            item.name
                          }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="desc">
                      <label>Language</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div> -->
                  </div>
                  <h4>{{ $t('order') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="customerSegment.order_template">
                        <option value=""></option>
                        <option v-for="(item, i) in printOutOrderTem" :value="item.id" :key="i">{{
                            item.name
                          }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="desc">
                      <label>Language</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div> -->
                  </div>
                </div>
                <div class="right desc">
                  <h4>{{ $t('quote') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="customerSegment.offer_template">
                        <option value=""></option>
                        <option v-for="(item, i) in printOutOfferTem" :value="item.id" :key="i">{{ item.name }}</option>
                      </select>
                    </div>
                    <!-- <div class="desc">
                      <label>Language</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div> -->
                  </div>
                  <h4>{{ $t('cash_invoice') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="customerSegment.cash_invoice_template">
                        <option value=""></option>
                        <option v-for="(item,key) in printOutCashInvoiceTem" :key="key" :value="item.id">{{
                            item.name
                          }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="desc">
                      <label>Language</label>
                      <select class="small">
                        <option> </option>
                      </select>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('note') }}</label>
                <textarea class="w-100" v-model="customerSegment.notes"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <div class="checkbo me-2">
            <input type="checkbox" class="form-check-input" v-model="agree">
            <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
              <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
            </svg> -->
          </div>
          <p>
            {{
              $t('segment_agreement_text')
            }}
          </p>
        </div>
        <div class="buttons">
          <router-link class="me-2" :to="{ name : 'customersegment' }">{{ $t('cancel') }}</router-link>
          <button type="submit" :disabled="creating" v-if="segment_id === ''">
            <span v-if="creating">{{ $t('creating') }}...</span>
            <span v-else>{{ $t('complete') }}</span>
          </button>
          <div v-else>
            <button type="button" :disabled="creating" v-on:click="updateCustomerSegment">
              <span v-if="creating">{{ $t('updating') }}...</span>
              <span v-else>Update</span>
            </button>
            <button type="button" v-on:click="switchTab(2)">
              Assign customer
            </button>
          </div>

        </div>
      </form>
      <div class="content" v-if="tab === 2">
        <div class="headers d-flex">
          <div>
            <h1 class="mb-0">Customers</h1><span>Create a new customer segment</span>
          </div>
          <!--          <a class="ms-auto" href="#">-->
          <!--            <svg width="31" height="30" viewbox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--              <path-->
          <!--                  d="M22.6541 0C27.7541 0 30.6641 2.88 30.6641 7.995V22.005C30.6641 27.09 27.7691 30 22.6691 30H8.65906C3.54406 30 0.664062 27.09 0.664062 22.005V7.995C0.664062 2.88 3.54406 0 8.65906 0H22.6541ZM15.6491 8.265C14.9591 8.265 14.4041 8.82 14.4041 9.51V13.74H10.1591C9.82906 13.74 9.51406 13.875 9.27406 14.1C9.04906 14.34 8.91406 14.6535 8.91406 14.985C8.91406 15.675 9.46906 16.23 10.1591 16.245H14.4041V20.49C14.4041 21.18 14.9591 21.735 15.6491 21.735C16.3391 21.735 16.8941 21.18 16.8941 20.49V16.245H21.1541C21.8441 16.23 22.3991 15.675 22.3991 14.985C22.3991 14.295 21.8441 13.74 21.1541 13.74H16.8941V9.51C16.8941 8.82 16.3391 8.265 15.6491 8.265Z"-->
          <!--                  fill="#7754FB"></path>-->
          <!--            </svg>-->
          <!--          </a>-->
          <nav>
            <div class="right-parent">
              <!--            <div class="filter">-->
              <!--              <label>Filter</label>-->
              <!--              <select>-->
              <!--                <option value="all">All</option>-->
              <!--              </select>-->
              <!--              <button type="submit">Apply</button>-->
              <!--            </div>-->
              <div class="d-flex flex-wrap align-items-center">
                <div class="search">
                  <label for="search">
                    <svg width="15" height="15" viewbox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M14.6219 13.5596L10.0568 8.99453C10.7652 8.07871 11.1484 6.95898 11.1484 5.78125C11.1484 4.37148 10.5982 3.04961 9.60332 2.05293C8.6084 1.05625 7.28301 0.507812 5.875 0.507812C4.46699 0.507812 3.1416 1.05801 2.14668 2.05293C1.15 3.04785 0.601562 4.37148 0.601562 5.78125C0.601562 7.18926 1.15176 8.51465 2.14668 9.50957C3.1416 10.5063 4.46523 11.0547 5.875 11.0547C7.05273 11.0547 8.1707 10.6715 9.08652 9.96484L13.6516 14.5281C13.6649 14.5415 13.6808 14.5521 13.6983 14.5594C13.7158 14.5666 13.7346 14.5704 13.7535 14.5704C13.7725 14.5704 13.7912 14.5666 13.8087 14.5594C13.8262 14.5521 13.8421 14.5415 13.8555 14.5281L14.6219 13.7635C14.6353 13.7501 14.6459 13.7342 14.6531 13.7167C14.6604 13.6992 14.6641 13.6805 14.6641 13.6615C14.6641 13.6426 14.6604 13.6238 14.6531 13.6063C14.6459 13.5889 14.6353 13.573 14.6219 13.5596ZM8.65938 8.56563C7.91406 9.30918 6.92617 9.71875 5.875 9.71875C4.82383 9.71875 3.83594 9.30918 3.09063 8.56563C2.34707 7.82031 1.9375 6.83242 1.9375 5.78125C1.9375 4.73008 2.34707 3.74043 3.09063 2.99688C3.83594 2.25332 4.82383 1.84375 5.875 1.84375C6.92617 1.84375 7.91582 2.25156 8.65938 2.99688C9.40293 3.74219 9.8125 4.73008 9.8125 5.78125C9.8125 6.83242 9.40293 7.82207 8.65938 8.56563Z"
                          fill="#8F8E94"></path>
                    </svg>
                  </label>
                  <input id="search" type="text" :placeholder="$t('search')">
                </div>
              </div>
            </div>
          </nav>
        </div>
        
        <div class="box">
          <div class="box-responsive">
            <table>
              <thead>
              <tr>
                <th width="40">
                  <div class="checkbox">
                    <!--                    <input type="checkbox">-->
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </th>
                <th><span>Customer number</span></th>
                <th><span>Name</span></th>
                <th><span>Org./personal number</span></th>
                <!--                <th><span>Total billed</span></th>-->
                <!--                <th><span>Recurring</span></th>-->
                <!--                <th><span>Amount</span></th>-->
                <!--                <th><span>Late pay ratio</span></th>-->
                <!--                <th><span>Billed since</span></th>-->
                <!--                <th><span>Type</span></th>-->
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,key) in customers.data" :key="key">
                <td>
                  <div class="checkbox">
                    <input type="checkbox" v-model="item.checked" v-on:change="checkboxOrder(key,item.id)"
                           :value="item.checked">
                    <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6"
                            stroke="#5855D6" stroke-width="0.978073"></rect>
                      <path
                          d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z"
                          fill="#F6F8FC"></path>
                    </svg>
                  </div>
                </td>
                <td>{{ item.customer_number }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.organisation_number }}</td>
                <!--                <td>28000.00</td>-->
                <!--                <td>Monthly</td>-->
                <!--                <td>1000.00</td>-->
                <!--                <td class="good">2%</td>-->
                <!--                <td>2020-11-10</td>-->
                <!--                <td>Company</td>-->
              </tr>
              </tbody>
            </table>

          </div>

        </div>
        <div class="d-flex justify-content-end w-full mt-3">
          <v-pagination
              v-model="customers.page"
              :pages="customers.total_pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="changePageNumber"
          />
        </div>
        <div class="buttons flex-wrap align-items-center">
          <router-link class="me-2" :to="{ name : 'customersegment' }">
            <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z"
                  fill="#5855D6"></path>
            </svg>
            Go back to segment settings
          </router-link>
          <div class="right flex-wrap my-2"><a class="my-1 my-sm-0" @click='tab = 1'
                                               href="javascript:void(0);">Previous</a>
            <button class="my-1 my-sm-0" type="button" v-on:click="processSegmentAction">
              <span v-if="addingCustomer === false">Add Customer to segment</span>
              <span v-else>Adding customers ...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import {mapGetters} from "vuex";
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

export default {
  name: 'CustomerSegmentCreate',
  components: {VPagination},
  data() {
    return {
      tab: 1,
      customerSegment: {
        description: '',
        name: '',
        notes: '',
        recurring_billing: 0,
        price_include_vat: true,
        active: true,
        interest_billing: true,
        invoice_discount: '',
        invoice_charge: '',
        shipping_charge: '',
        type_of_vat: '',
        offer_template: '',
        order_template: '',
        invoice: '',
        order: '',
        quote: '',
        cash_invoice_template: '',
        invoice_template: '',
        invoice_text: '',
        delivery_method: '',
      },
      segment_id: '',
      sales_account: '',
      agree: false,
      accounts: [],
      paymentTerm: [],
      deliveryWay: [],
      deliveryTerm: [],
      pricelist: [],
      currency: [],
      costCenter: [],
      project: [],
      printOutOrderTem: [],
      printOutInvoiceTem: [],
      printOutOfferTem: [],
      printOutCashInvoiceTem: [],
      showSalesAccount: false,
      creating: false,
      addingCustomer: false,
      customerSegmentArr: [],
      customerSegmentToRemoveArr: [],
      customers: []
    }
  },
  mounted() {
    this.getPaymentTerm()
    this.getDeliveryWay()
    this.getDeliveryTerm()
    this.getPriceList()
    this.getCurrency()
    this.getCostCenter()
    this.getProjects()
    this.getPrintOutTemplate('invoice')
    this.getPrintOutTemplate('cashinvoice')
    this.getPrintOutTemplate('order')
    this.getPrintOutTemplate('offer')
    this.getCustomers()
  },
  methods: {
    switchTab(number) {
      this.tab = parseInt(number)
    },
    getCustomers() {
      this.$store.dispatch('clients/getCustomers', 'customize=no').then(resp => {
        this.customers = resp.data
        this.customers.data.map(item => {
          item.checked = false
        })
      })
    },
    changePageNumber(number) {
      let payload = ''
      if (this.filter) {
        payload = `page=${number}&${this.filter}`
      } else {
        payload = `page=${number}&customize=no`
      }
      this.$store.commit('clients/UPDATE_CUSTOMERS', [])
      this.$store.dispatch('clients/getCustomers', payload).then(resp => {
        this.customers = resp.data
        this.customers.data.map(item => {
          if (this.customerSegmentArr.includes(item.id)) {
            item.checked = true
          } else {
            item.checked = false
          }

        })
      })
    },
    checkboxOrder(index, id) {
      if (this.customerSegmentArr.includes(id)) {
        let indexID = this.customerSegmentArr.findIndex(item => item === id)
        if (indexID !== -1) {
          let customerID = this.customerSegmentArr[indexID]
          if (!this.customerSegmentToRemoveArr.includes(customerID)) {
            this.customerSegmentToRemoveArr.push(customerID)
          }
          this.customerSegmentArr.splice(indexID, 1)
        }
      } else {
        this.customerSegmentArr.push(id)
        if (this.customerSegmentToRemoveArr.includes(id)) {
          let indexID = this.customerSegmentToRemoveArr.findIndex(item => item === id)
          if (indexID !== -1) {
            this.customerSegmentToRemoveArr.splice(indexID, 1)
          }
        }
      }
      this.removeCustomerSegment()
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            if (mode === 'sales') {
              this.showSalesAccount = true
            }
          })
          .catch(err => {

          })
    },
    chooseSales(item, mode) {
      this.sales_account = item.number
      this.customerSegment.sales_account = item.id
      this.showSalesAccount = false
    },
    createCustomerSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      if (!this.agree) {
        // this.$services.helpers.notification('Please agree to the terms & conditions', 'error', this)
        this.$toast.warning(this.$t('segment_settings_confirmation'));
      } else {
        this.creating = true
        const payload = {
          data: this.customerSegment,
          URL: this.$services.endpoints.SEGMENT_ENDPOINT + 'customers/'
        }
        this.$store.dispatch('clients/customPostRequest', payload)
            .then(res => {
              this.creating = false
              // this.$services.helpers.notification('Customer segment added succesffully', 'success', this)
              this.$toast.success(this.$t('segment_added'));
              this.switchTab(2)
              this.segment_id = res.data.id
            })
            .catch(err => {
              this.creating = false
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            })
      }
    },
    updateCustomerSegment() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.creating = true
      const payload = {
        data: this.customerSegment,
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.segment_id}/`
      }
      this.$store.dispatch('clients/customPutRequest', payload)
          .then(res => {
            this.creating = false
            // this.$services.helpers.notification('Customer segment added succesffully', 'success', this)
            this.$toast.success(this.$t('segment_added'));
            this.switchTab(2)
            this.segment_id = res.data.id
          })
          .catch(err => {
            this.creating = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    processSegmentAction() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      } else {
        this.addCustomerSegment()
        this.removeCustomerSegment()
      }


    },
    addCustomerSegment() {
      if (this.customerSegmentArr.length === 0) {
        // return this.$services.helpers.notification('Kindly select one or more customer', 'error', this)
        return this.$toast.warning(this.$t('choose_customer'));
      }
      if (this.segment_id === '') {
        // return this.$services.helpers.notification('Request can not be process,kindly try again', 'error', this)
        return this.$toast.warning(this.$t('request_failure'));
      }
      let payload = {
        'data': {
          'customers': this.customerSegmentArr
        },
        'URL': this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.segment_id}/add_customers/`
      }
      this.addingCustomer = true
      this.$store.dispatch('clients/customPutRequest', payload).then(resp => {
        // this.$services.helpers.notification('Customers added to segment successfully', 'success', this)
        this.$toast.success(this.$t('customers_added'));

        this.addingCustomer = false
        this.$router.replace({name: 'customersegment'})
      }).catch(error => {
        this.$services.helpers.notification(error.response.data.message, 'error', this)
        this.addingCustomer = false
      })

    },
    removeCustomerSegment() {
      if (this.customerSegmentToRemoveArr.length > 0) {
        let payload = {
          'data': {
            'customers': this.customerSegmentToRemoveArr
          },
          'URL': this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.segment_id}/remove_customers/`
        }
        this.$store.dispatch('clients/customPutRequest', payload).then(_ => {
          this.customerSegmentToRemoveArr = []
        })
      }
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'termsofpayment'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.paymentTerm = res.data.results
          })
          .catch(err => {
          })
    },
    getDeliveryWay() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'wayofdelivery'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.deliveryWay = res.data.results
          })
          .catch(err => {
          })
    },
    getDeliveryTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'termsofdelivery'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.deliveryTerm = res.data.results
          })
          .catch(err => {
          })
    },
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'pricelists'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.pricelist = res.data.results
          })
          .catch(err => {
          })
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'currencies'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.currency = res.data.results
          })
          .catch(err => {
          })
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'costcenters'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.costCenter = res.data.results
          })
          .catch(err => {
          })
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'projects'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.project = res.data.results
          })
          .catch(err => {
          })
    },
    getPrintOutTemplate(data) {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + `printtemplates?type=${data}`
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            if (data === 'order') {
              this.printOutOrderTem = res.data.results
            } else if (data === 'invoice') {
              this.printOutInvoiceTem = res.data.results
            } else if (data === 'offer') {
              this.printOutOfferTem = res.data.results
            } else if (data === 'cashinvoice') {
              this.printOutCashInvoiceTem = res.data.results
            }
          })
          .catch(err => {
          })
    },
  },
  computed: {
    ...mapGetters({
      GET_CUSTOMERS: 'clients/GET_CUSTOMERS',
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.segment-details h3 {
  background-image: url("./../../assets/images/table/segment.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box.e-document h3 {
  background-image: url("./../../assets/images/table/e-document.png");
  background-repeat: no-repeat;
  background-position: center right;
}

@media (max-width: 575.98px) {
  .content .box.e-document .d-flex {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content .box.e-document .left {
    margin-right: 0;
  }
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  margin: 0;
}

.content .box .form {
  padding: 30px;
}

@media (min-width: 992px) {
  .content .box .form {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 991.98px) {
  .content .box .form.segment-details .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.content .box .form h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

@media (max-width: 991.98px) {
  .content .box .form h4 {
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.content .box .form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 5px 5px 5px 0;
}

@media (min-width: 992px) {
  .content .box .form .desc {
    margin-bottom: 20px;
  }
}

.content .box .form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box .form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 195px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box .form .desc select.small {
  width: 94px;
}

.content .box .form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .content .box .form .desc input {
    width: 90%;
  }
}

.content .box .form .desc input.small {
  width: 94px;
}

.content .box .form .desc input:focus {
  outline: none;
}

.content .box .form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box .form .desc .radio input {
  display: none;
}

.content .box .form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box .form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 12.469px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box .form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box .form textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

@media (max-width: 575.98px) {
  .content .box .form textarea {
    width: 100%;
    min-width: 150px;
  }
}

.content .box .form textarea:focus {
  border: none;
  outline: none;
}

.content .box h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box .left {
  margin-right: 40px;
}

@media (min-width: 992px) {
  .content .box .parent-small.more-margin .desc:not(:first-of-type) {
    margin-left: 30px;
  }
}

.content .box:last-of-type {
  margin-bottom: 20px;
}

.content .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 18px;
  width: 18px;
  z-index: 33;
  background: #fffbfb;
}

.content .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .checkbox input,
.content .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .checkbox input:checked ~ svg {
  display: block;
}

.content .checkbox svg {
  position: absolute;
  height: 20px;
  width: 20px;
  display: none;
  z-index: 1;
}

.content .checkbox ~ p {
  margin: 0;
  margin-left: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-top: 20px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
  text-align: center;
}

.content .buttons a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

.content .buttons a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content nav {
  margin: 20px 0 0 5px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.content nav .right-parent .filter {
  margin-right: 20px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 136.73px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 77%;
  margin-left: auto;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .customize {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .customize {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.content nav .right-parent .customize a {
  font-size: 14px;
  line-height: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content nav .right-parent .customize a svg {
  margin-right: 10px;
}

.content nav .right-parent .customize a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content nav .right-parent .customize a:hover {
  color: #4946ae;
}

.content nav .right-parent .customize a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2), 0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 2.93422px 4.89036px rgba(9, 30, 66, 0.2), 0px 0px 0.978073px rgba(9, 30, 66, 0.31);
  border-radius: 3.91229px;
  margin-top: 20px;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #fffbfb;
  cursor: pointer;
  z-index: 33;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 5px 0;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td:last-of-type {
  padding-right: 10px;
}

.content .box table tbody td:nth-of-type(8).good {
  color: #419e6a;
}

.content .box table tbody td:nth-of-type(8).bad {
  color: #fb4b4b;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="d-flex align-items-center">
          <div>
            <h1 class="mb-0">Eventlog</h1>
            <ul class="list-unstyled d-flex flex-wrap">
              <li @click="getNotifyEvent" class="all">{{ $services.helpers.capitalizeNames($t('event_all')) }}</li>
              <li @click="filterLogs('SUCCESS')" class="success">{{ $services.helpers.capitalizeNames($t('event_success')) }}</li>
              <li @click="filterLogs('INFO')" class="info">{{ $services.helpers.capitalizeNames($t('event_info')) }}</li>
              <li @click="filterLogs('ERROR')" class="error">{{ $services.helpers.capitalizeNames($t('event_error')) }}</li>
            </ul>
          </div>
        </div>
        <div class="box">
          <div class="box-responsive">
            <table>
              <thead>
              <tr>
                <th>{{ $services.helpers.capitalizeNames($t('event_subject')) }}</th>
                <th>{{ $services.helpers.capitalizeNames($t('date')) }}</th>
                <th>{{ $services.helpers.capitalizeNames($t('event_type')) }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="event in EVENT" :key="event.id">
                <td><p v-if="GET_LOCALE === 'en'">{{ event.eng_message }}</p>
                  <p v-else>{{ event.swe_message }}</p></td>
                <td width="12%">{{ event.received }}</td>
                <td>
                  <span v-if="event.event === 'SUCCESS'" class="success">{{ $services.helpers.capitalizeNames($t('event_success')) }}</span>
                  <span v-if="event.event === 'INFO'" class="info">{{ $services.helpers.capitalizeNames($t('event_info')) }}</span>
                  <span v-if="event.event === 'ERROR'" class="error">{{ $services.helpers.capitalizeNames($t('event_error')) }}</span>
                </td>
                <td>
                  <router-link :to="{ name : 'eventmessages',params: { id : event.id } }">{{ $services.helpers.capitalizeNames($t('event_view')) }}
                    <svg width="8" height="15" viewbox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M1.54651 -0.000275612C1.6959 -0.00078392 1.84351 0.0321884 1.97849 0.0962172C2.11347 0.160246 2.23239 0.253705 2.32651 0.369725L7.15651 6.36972C7.30359 6.54866 7.384 6.7731 7.384 7.00472C7.384 7.23635 7.30359 7.46079 7.15651 7.63972L2.15651 13.6397C1.98677 13.8439 1.74286 13.9724 1.47843 13.9967C1.214 14.0211 0.950724 13.9395 0.746507 13.7697C0.542291 13.6 0.413867 13.3561 0.389487 13.0916C0.365107 12.8272 0.446768 12.5639 0.616507 12.3597L5.08651 6.99972L0.766508 1.63972C0.644225 1.49294 0.566547 1.3142 0.542668 1.12465C0.518788 0.935102 0.549705 0.742677 0.631761 0.570149C0.713817 0.397622 0.843577 0.252211 1.00569 0.15112C1.1678 0.0500298 1.35547 -0.00250721 1.54651 -0.000275612Z"
                          fill="#5855D6"></path>
                    </svg>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'EventLog',
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      EVENT: 'clients/GET_NOTIFY_EVENT',
      GET_LOCALE:'locale/GET_LOCALE'
    })
  },
  mounted() {
    this.getNotifyEvent()
  },
  methods: {
    getNotifyEvent() {
      this.$store.dispatch('clients/getNotificationEvent', null)
    },
    filterLogs(data) {
      let payload = `filter=${data}`
      // this.filter = payload
      this.$store.dispatch('clients/getNotificationEvent', payload)
    },
  },

}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content h1 {
  margin: 20px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content ul {
  margin: 0;
  text-align: center;
}

.content ul li {
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  margin: 5px;
  width: 82px;
  height: 32px;
  -webkit-transition: -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.content ul li:hover {
  -webkit-transform: translateY(-0.25em);
  -ms-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.content ul li.all {
  background: #c0beff;
}

.content ul li.all:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
  box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
}

.content ul li.success {
  background: #419e6a;
}

.content ul li.success:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
  box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
}

.content ul li.info {
  background: #4d82f3;
}

.content ul li.info:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
  box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
}

.content ul li.error {
  background: #fb4b4b;
}

.content ul li.error:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
  box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
}

.content .box {
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding-bottom: 60px;
  margin-top: 20px;
}

.content .box table {
  width: 100%;
  min-width: 770px;
}

.content .box table tr {
  position: relative;
}

.content .box table thead th {
  padding: 30px 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
  border-top: 0.982163px solid rgba(0, 0, 0, 0.13);
  border-bottom: 0.982163px solid rgba(0, 0, 0, 0.13);
}

.content .box table tbody td {
  padding: 15px 40px;
}

.content .box table tbody td:nth-child(1) {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #000000;
}

.content .box table tbody td:nth-child(2) {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #000000;
}

.content .box table tbody td:nth-child(3) span {
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  border-radius: 4px;
  width: 79px;
  height: 27px;
  border: none;
  display: block;
  text-align: center;
  text-transform: capitalize;
}

.content .box table tbody td:nth-child(3) span.success {
  background: #a5e1bf;
  color: #00632b;
}

.content .box table tbody td:nth-child(3) span.error {
  background: #fc9595;
  color: #b01212;
}

.content .box table tbody td:nth-child(3) span.info {
  background: #7ea5f8;
  color: #cbd4e1;
}

.content .box table tbody td:nth-child(4) a {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content .box table tbody td:nth-child(4) a svg {
  margin-top: -2px;
}

.content .box table tbody td:nth-child(4) a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td:nth-child(4) a:hover {
  color: #4946ae;
}

.content .box table tbody td:nth-child(4) a:hover svg path {
  fill: #4946ae;
}

.content .box table tbody td:nth-child(4) a svg {
  margin-left: 5px;
}
</style>
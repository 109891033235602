<template>
  <div class="col responsive-width">
    <div class="content">
      <authorization-banner />
      <div class="container-fluid">
        <div class="headers d-flex flex-wrap justify-content-between">
          <div>
            <h1 class="mb-0">{{ $t("billing_detail_and_history") }}</h1>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-lg-9">
            <div class="box delivery">
              <h3>{{ $t("billing_details") }}</h3>
              <form @submit.prevent="updateBillingDetails">
                <div class="d-flex flex-wrap">
                  <div class="desc">
                    <label>{{ $t("invoice_address") }}</label>
                    <input type="text" v-model="billingInfo.invoice_address" />
                  </div>
                  <div class="desc">
                    <label>{{ $t("city") }}</label>
                    <input type="text" v-model="billingInfo.city" />
                  </div>
                  <div class="desc">
                    <label>{{ $t("zip_code") }}</label>
                    <input type="text" v-model="billingInfo.zip_code" />
                  </div>
                  <div class="desc">
                    <label>{{ $t("country") }}</label>
                    <select v-model="billingInfo.country">
                      <option value=""></option>
                      <option
                        :value="item.key"
                        v-for="(item, i) in countries"
                        :key="i"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="desc">
                    <label>{{ $t("billing_email") }}</label>
                    <input type="email" v-model="billingInfo.billing_email" />
                  </div>
                </div>
                <div class="buttons d-flex justify-content-end">
                  <button :disabled="processing" type="submit">
                    <span v-if="processing">{{ $t("updating") }}...</span>
                    <span v-else>{{ $t("update_billing_detail") }}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card-blue p-3">
              <div>
                <h6>{{ $t("current_plan") }}</h6>
              </div>
              <div
                class="ms-3 d-flex flex-column fs-7"
                v-if="GET_USER_SUBSCRIPTION.length > 0"
              >
                <div class="fw-bold">
                  <p>
                    {{ GET_USER_SUBSCRIPTION[0].current_package }}
                    <!-- <br> -->
                    <!-- up to 250 active contracts -->
                  </p>
                </div>
                <div class="ms-2 fw-bold">
                  <p>{{ GET_USER_SUBSCRIPTION[0].cost }} sek / month</p>
                </div>
                <div>
                  <router-link
                    class="plan-details-style"
                    :to="{ name: 'planDetails' }"
                    >{{ $t("plan_detail") }} >></router-link
                  >
                </div>
                <div>
                  <router-link
                    class="plan-details-style"
                    :to="{ name: 'billingCancel' }"
                    >{{ $t("cancel_service") }} >></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="mt-4">
          <div class="row g-0">
            <div class="col">
              <ul class="list-unstyled d-flex">
                <li @click="getBillingHistory" class="all">{{ $t("all") }}</li>
                <li @click="filterBilling('Unpaid')" class="inactive">
                  {{ $t("unpaidBilling") }}
                </li>
                <li @click="filterBilling('Overdue')" class="terminated">
                  {{ $t("overdueBilling") }}
                </li>
                <li @click="filterBilling('Paid')" class="active">
                  {{ $t("paid") }}
                </li>
              </ul>
            </div>
            <div class="col">
              <div class="right-parent">
                <!-- <div class="filter">
                  <label>Filter</label>
                  <select>
                    <option value="all">Alla</option>
                    <option value="inactive">Obetalda</option>
                    <option value="terminated">Förfallna</option>
                    <option value="active">Betalda</option>
                  </select>
                  <button type="submit">Apply</button>
                </div> -->
                <div class="d-flex flex-wrap align-items-center">
                  <div class="search">
                    <label for="search">
                      <svg
                        width="15"
                        height="15"
                        viewbox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.6219 13.5596L10.0568 8.99453C10.7652 8.07871 11.1484 6.95898 11.1484 5.78125C11.1484 4.37148 10.5982 3.04961 9.60332 2.05293C8.6084 1.05625 7.28301 0.507812 5.875 0.507812C4.46699 0.507812 3.1416 1.05801 2.14668 2.05293C1.15 3.04785 0.601562 4.37148 0.601562 5.78125C0.601562 7.18926 1.15176 8.51465 2.14668 9.50957C3.1416 10.5063 4.46523 11.0547 5.875 11.0547C7.05273 11.0547 8.1707 10.6715 9.08652 9.96484L13.6516 14.5281C13.6649 14.5415 13.6808 14.5521 13.6983 14.5594C13.7158 14.5666 13.7346 14.5704 13.7535 14.5704C13.7725 14.5704 13.7912 14.5666 13.8087 14.5594C13.8262 14.5521 13.8421 14.5415 13.8555 14.5281L14.6219 13.7635C14.6353 13.7501 14.6459 13.7342 14.6531 13.7167C14.6604 13.6992 14.6641 13.6805 14.6641 13.6615C14.6641 13.6426 14.6604 13.6238 14.6531 13.6063C14.6459 13.5889 14.6353 13.573 14.6219 13.5596ZM8.65938 8.56563C7.91406 9.30918 6.92617 9.71875 5.875 9.71875C4.82383 9.71875 3.83594 9.30918 3.09063 8.56563C2.34707 7.82031 1.9375 6.83242 1.9375 5.78125C1.9375 4.73008 2.34707 3.74043 3.09063 2.99688C3.83594 2.25332 4.82383 1.84375 5.875 1.84375C6.92617 1.84375 7.91582 2.25156 8.65938 2.99688C9.40293 3.74219 9.8125 4.73008 9.8125 5.78125C9.8125 6.83242 9.40293 7.82207 8.65938 8.56563Z"
                          fill="#8F8E94"
                        ></path>
                      </svg>
                    </label>
                    <input
                      @input="searchField"
                      id="search"
                      type="text"
                      :placeholder="$t('search')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div class="box mb-5">
          <div class="box-responsive">
            <table>
              <thead>
                <tr>
                  <th>
                    <span>{{ $t("company_name") }}</span>
                  </th>
                  <th>
                    <span>{{ $t("amount_due") }}</span>
                  </th>
                  <th>
                    <span>{{ $t("invoice_date") }}</span>
                  </th>
                  <th>
                    <span>{{ $t("due_date") }}</span>
                  </th>
                  <th>
                    <span>{{ $t("status") }}</span>
                  </th>
                  <th>
                    <span>{{ $t("file") }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="
                    history.status === 'Paid'
                      ? 'active'
                      : history.status === 'Unpaid'
                      ? 'inactive'
                      : 'terminate'
                  "
                  v-for="history in GET_BILLING_HISTORY"
                  :key="history.id"
                >
                  <BillngTableVue :data="history" />
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="loading" class="d-flex justify-content-center">
            <div class="loader">Laddar...</div>
          </div>
          <div
            v-if="!loading && GET_BILLING_HISTORY.length < 1"
            class="d-flex justify-content-center"
          >
            <small class="p-1">Inga fakturor att visa</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
import BillngTableVue from "./reusableComponents/BillngTable.vue";
import { getCountries } from "./composables/countries";
import AuthorizationBanner from "./reusableComponents/AuthorizationBanner";
export default {
  name: "Billing",
  components: { BillngTableVue, AuthorizationBanner },
  data() {
    return {
      billingInfo: {},
      loading: true,
      processing: false,
      countries: getCountries(),
    };
  },
  computed: {
    ...mapGetters({
      GET_BILLING: "clients/GET_BILLING",
      GET_BILLING_HISTORY: "clients/GET_BILLING_HISTORY",
      GET_PACKAGE: "clients/GET_PLAN_DETAIL",
      GET_USER_SUBSCRIPTION: "clients/GET_USER_SUBSCRIPTION",
      CURRENT_USER: "clients/GET_CURRENT_USER",
    }),
  },
  mounted() {
    this.openAccess();
  },
  methods: {
    openAccess() {
      this.$store.dispatch("clients/getCurrentUserInfo").then((res) => {
        if (
          this.CURRENT_USER.role !== "Owner" ||
          this.CURRENT_USER.access !== "Full"
        ) {
          window.Bus.emit("access-denied", "billings");
        } else {
          this.getBilling();
          this.getBillingHistory();
          this.getUserSubscription();
        }
      });
    },
    getPackageInfo() {
      const payload = {
        URL:
          this.$services.endpoints.PACKAGE_ENDPOINT +
          `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
      };
      this.$store.dispatch("clients/getPlanDetails", payload).then((resp) => {
        if (
          !resp.data.results[0].dashboard_page_access.includes(this.$route.name)
        ) {
          window.Bus.emit("access-denied", "billings");
        }
      });
    },
    searchField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSearch(event.target.value);
      } else {
        this.getBillingHistory();
      }
    }, 500),
    filterSearch(data) {
      const payload = `search=${data}`;
      this.$store.commit("clients/UPDATE_BILLING_HISTORY", { data: [] });
      // this.filter = payload
      this.$store.dispatch("clients/getBillingHistory", payload);
    },
    getBilling() {
      this.$store
        .dispatch("clients/getBilling")
        .then((_) => {
          this.billingInfo = this.GET_BILLING;
        })
        .catch((err) => {});
    },
    getBillingHistory() {
      this.loading = true;
      this.$store
        .dispatch("clients/getBillingHistory", null)
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getUserSubscription() {
      this.$store
        .dispatch("clients/getUserSubscription")
        .then((res) => {})
        .catch((err) => {});
    },
    filterBilling(data) {
      let payload = `status=${data}`;

      this.$store.commit("clients/UPDATE_BILLING_HISTORY", { data: [] });
      // this.filter = payload
      this.$store.dispatch("clients/getBillingHistory", payload);
    },
    updateBillingDetails() {
      this.processing = true;
      this.$store
        .dispatch("clients/updateBilling", this.billingInfo)
        .then((res) => {
          this.processing = false;
          this.$services.helpers.notification(
            res.data.message,
            "success",
            this
          );
        })
        .catch((err) => {
          this.processing = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .card-blue {
  background: #4e87ff;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  color: #ffff;
}

.content .box.delivery h3 {
  background-image: url("./../../assets/images/table/delivery.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

.content .box form .parent-small .desc:last-of-type {
  margin-left: 10px;
}

.content .box form .desc {
  display: flex;
  flex-direction: column;
  margin: 7px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  //   font-size: 12px;
  //   line-height: 14px;
  //   color: #000000;
  //   margin-bottom: 15px !important;
}

@media (max-width: 991.98px) {
  .content .box form .desc {
    margin: 5px;
  }
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 192px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 576px) {
  .content .box form .desc input {
    width: 98%;
  }
}

.content .box form .desc input.small {
  width: 91px;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content nav {
  margin: 10px 0 0 5px;
}

@media (max-width: 1094px) {
  .content nav .row {
    display: block;
  }
  .content nav .row > div:last-of-type {
    margin-top: 10px;
  }
  .content nav .row ul {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content nav .row ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.content nav ul {
  margin: 0;
  margin-top: 10px;
  text-align: center;
}

.content nav ul li {
  border-radius: 4px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  width: 100px;
  height: 32px;
  -webkit-transition: -webkit-transform 0.35s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-transform 0.35s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.35s ease-in-out, box-shadow 0.3s ease-in-out,
    -webkit-transform 0.35s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.content nav ul li:hover {
  -webkit-transform: translateY(-0.25em);
  -ms-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.content nav ul li.all {
  background: #c0beff;
}

.content nav ul li.all:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
  box-shadow: 0 0.5em 0.5em -0.1em #c0beff;
}

.content nav ul li.inactive {
  background: #efb008;
}

.content nav ul li.inactive:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #efb008;
  box-shadow: 0 0.5em 0.5em -0.1em #efb008;
}

.content nav ul li.terminated {
  background: #fb4b4b;
}

.content nav ul li.terminated:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
  box-shadow: 0 0.5em 0.5em -0.1em #fb4b4b;
}

.content nav ul li.active {
  background: #419e6a;
}

.content nav ul li.active:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
  box-shadow: 0 0.5em 0.5em -0.1em #419e6a;
}

.content nav ul li.upcoming {
  background: #4d82f3;
}

.content nav ul li.upcoming:hover {
  -webkit-box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
  box-shadow: 0 0.5em 0.5em -0.1em #4d82f3;
}

.content nav ul li:not(:last-of-type) {
  margin-right: 15px;
}

.content nav .right-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1097px) {
  .content nav .right-parent {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.content nav .right-parent .filter {
  margin-right: 20px;
  margin-top: 10px;
}

.content nav .right-parent .filter label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content nav .right-parent .filter select {
  margin: 0 10px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content nav .right-parent .filter select:focus {
  outline: none;
}

.content nav .right-parent .filter button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content nav .right-parent .filter button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .filter {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .content nav .right-parent .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content nav .right-parent .filter * {
    margin: 5px !important;
  }
}

.content nav .right-parent .search {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 207.73px;
}

@media (max-width: 767.98px) {
  .content nav .right-parent .search {
    margin-top: 10px;
  }
}

@media (max-width: 296px) {
  .content nav .right-parent .search {
    margin: 10px 0 0;
  }
}

.content nav .right-parent .search label {
  position: absolute;
  left: 0.5rem;
}

.content nav .right-parent .search input {
  border: none;
  width: 77%;
  margin-left: auto;
}

.content nav .right-parent .search input:focus {
  outline: none;
}

// tbody, td, tfoot, th, thead, tr{
th {
  border-color: #ccc;
  border-width: 1px;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table thead {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: nowrap;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  white-space: nowrap;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .box table tbody tr.booked td a {
  color: #4d82f3;
}

.content .box table tbody tr.booked td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.inactive td a {
  color: #efb008;
}

.content .box table tbody tr.inactive td:not(:last-of-type) path {
  fill: #64748b;
}

.content .box table tbody tr.terminate td a {
  color: #fb4b4b;
}

.content .box table tbody tr.terminate td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.active td a {
  color: #419e6a;
}

.content .box table tbody tr.active td button:nth-child(1),
.content .box table tbody tr.active td button:nth-child(2) {
  opacity: 0.5;
  pointer-events: none;
}

.content .box table tbody tr.voided td a {
  color: #64748b;
}

.content .box table tbody tr.voided td:not(:last-of-type) path {
  fill: #64748b;
}

.plan-details-style {
  text-decoration: none;
  color: #fff;
}

select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 192px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

@media (max-width: 576px) {
  select {
    width: 98%;
  }
}

select.small {
  width: 91px;
}
.content {
  padding: 90px 30px 10px !important;
}
</style>

<template>
  <div class="alert alert-warning auth" v-if="authStatus === true">
    <div class="" style="display: flex">
      <p>
        {{ $t('re_auth_text') }} <a class="auth-link" target="_blank" :href="auth_link"> {{ $t('re_connect_now') }}</a>
      </p>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AuthorizationBanner",
  data: function () {
    return {
      authStatus: false,
      auth_link: ''
    }
  },
  computed: {
    ...mapGetters({
      SUBSCRIPTION: 'clients/GET_USER_SUBSCRIPTION'
    })
  },
  methods: {
    getSubscription() {
      this.$store.dispatch('clients/getUserSubscription').then(resp => {
        if (this.SUBSCRIPTION.length > 0) {
          this.auth_link = this.SUBSCRIPTION[0].auth_link
          this.authStatus = this.SUBSCRIPTION[0].failed_to_refresh_token
        }
      })
    }
  },
  mounted() {
    this.getSubscription()
  }

}
</script>

<style scoped>
.auth {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 19px;
  margin-bottom: 19px;
}

.auth-link {
  margin-left: 9px;
  text-decoration: none;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="headers d-flex me-3">
            <h1 class="mb-0">{{ $t('customers') }} </h1>
            <h6>{{ $services.helpers.capitalizeNames($t('edit')) }} {{ $t('customer') }} {{ $t('segment') }} - <strong>{{
                GET_SINGLE_CUSTOMER_SEGMENT.name
              }}</strong></h6>
          </div>
          <router-link class="my-2 my-sm-0" :to="{ name : 'customersegment' }">
            <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z"
                  fill="#5855D6"></path>
            </svg>
            {{ $t('go_back_to_segment_overview') }}
          </router-link>
        </div>
        <div class="box segment-details">
          <h3>{{ $t('segment_details') }}</h3>
          <form class="segment-details row">
            <div class="col-lg-3 col flex-wrap">
              <div class="desc">
                <label>{{ $services.helpers.capitalizeNames($t('name')) }}</label>
                <input type="text" v-model="GET_SINGLE_CUSTOMER_SEGMENT.name">
              </div>
              <div class="parent-small d-flex flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('active') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="GET_SINGLE_CUSTOMER_SEGMENT.active" :value="true"
                           name="interest-billing" id="billing-yes">
                    <label for="billing-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="GET_SINGLE_CUSTOMER_SEGMENT.active" :value="false"
                           name="interest-billing" id="billing-no">
                    <label for="billing-no">{{ $t('no') }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('way_of_delivery') }}</label>
                  <select v-model="payload.delivery_method" class="small">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in deliveryWay" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg col d-flex d-lg-block flex-wrap">
              <div class="parent-small d-flex flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('terms_of_payment') }}</label>
                  <select v-model="payload.terms_of_payment" class="small">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in paymentTerm" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('terms_of_delivery') }}</label>
                  <select v-model="payload.terms_of_delivery" class="small">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in deliveryTerm" :key="item.id">{{
                        item.description
                      }}
                    </option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('show_price_vat_included') }}</label>
                  <div class="radio">
                    <input type="radio" v-model="GET_SINGLE_CUSTOMER_SEGMENT.price_include_vat" :value="true" name="vat"
                           id="vat-yes">
                    <label for="vat-yes">{{ $t('yes') }}</label>
                    <input type="radio" v-model="GET_SINGLE_CUSTOMER_SEGMENT.price_include_vat" :value="false"
                           name="vat" id="vat-no">
                    <label for="vat-no">{{ $t('no') }}</label>
                  </div>
                </div>
              </div>
              <div class="parent-small d-flex flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('invoice_discount') }} %</label>
                  <input class="small" type="text" v-model="GET_SINGLE_CUSTOMER_SEGMENT.invoice_discount">
                </div>
                <div class="desc">
                  <label>{{ $t('invoice_charge') }}</label>
                  <input class="small" type="text" v-model="GET_SINGLE_CUSTOMER_SEGMENT.invoice_charge">
                </div>
                <div class="desc">
                  <label>{{ $t('shipping_charge') }}</label>
                  <input class="small" type="text" v-model="GET_SINGLE_CUSTOMER_SEGMENT.shipping_charge">
                </div>
              </div>
            </div>
            <div class="col-lg col-12 d-flex d-lg-block flex-wrap">
              <div class="parent-small d-flex flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('sales_account') }}</label>
                  <input class="small" @input="searchField($event, 'sales')" type="text"
                         v-model="pseudo_sales_account">
                  <div v-if="showSalesAccount" style="z-index:9999999; width:12%"
                       class="position-absolute bg-white rounded shadow-md border mt-2">
                    <ul style="padding-left: 0rem !important; list-style-type:none">
                      <li class="border-bottom p-2 mb-1 cursor-pointer" v-for="item in accounts.slice(0, 5)"
                          :key="item.id" @click="chooseSales(item, 'sales')">{{ item.number + ' ' + item.description }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t('price_list') }}</label>
                  <select class="small" v-model="payload.price_list">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in price_lists" :key="item.id">{{ item.description }}</option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('currency') }}</label>
                  <select class="small" v-model="payload.currency">
                    <option value=""></option>
                    <option :value="item.id" v-for="item in currencies" :key="item.id">{{ item.description }}</option>
                  </select>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('type_of_vat') }}</label>
                <select v-model="GET_SINGLE_CUSTOMER_SEGMENT.type_of_vat">
                  <option value=""></option>
                  <option value="SEVAT">SE</option>
                  <!-- <option value="SEREVERSEDVAT">SE reverse Vat</option>
                  <option value="EUREVERSEDVAT">EU reverse Vat</option> -->
                  <option value="EUVAT">Subject to EU VAT</option>
                  <option value="EXPORT">Export</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="box e-document">
          <h3>{{ $t('e_document_distribution_way') }}</h3>
          <form class="row">
            <div class="col">
              <h5>{{ $t('preselected_distribution_ways') }}</h5>
              <div class="parent-small d-flex more-margin flex-wrap col-flex-wrap">
                <div class="desc">
                  <label>{{ $t('invoice') }}</label>
                  <select v-model="GET_SINGLE_CUSTOMER_SEGMENT.invoice" class="small">
                    <option value=""></option>
                    <option value="einvoice">{{ $t('e_invoice') }}</option>
                    <option value="email">{{ $t('email') }}</option>
                    <option value="print">{{ $t('print') }}</option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('quote') }}</label>
                  <select v-model="GET_SINGLE_CUSTOMER_SEGMENT.quote" class="small">
                    <option value=""></option>
                    <option value="email">{{ $t('email') }}</option>
                    <option value="print">{{ $t('print') }}</option>
                  </select>
                </div>
                <div class="desc">
                  <label>{{ $t('order') }}</label>
                  <select v-model="GET_SINGLE_CUSTOMER_SEGMENT.order" class="small">
                    <option value=""></option>
                    <option value="email">{{ $t('email') }}</option>
                    <option value="print">{{ $t('print') }}</option>
                  </select>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('invoice_text') }}</label>
                <textarea v-model="GET_SINGLE_CUSTOMER_SEGMENT.invoice_text"></textarea>
              </div>
              <div class="desc">
                <label>{{ $t('description') }}</label>
                <textarea v-model="GET_SINGLE_CUSTOMER_SEGMENT.description"></textarea>
              </div>
            </div>
            <div class="col">
              <h5>{{ $t('preselected_template') }}</h5>
              <div class="d-flex flex-wrap col-flex-wrap">
                <div class="left desc">
                  <h4>{{ $t('invoice') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="payload.invoice_template">
                        <option value=""></option>
                        <option v-for="(item, i) in printOutInvoiceTem" :value="item.id" :key="i">{{
                            item.name
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <h4>{{ $t('order') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{$t('template')}}}</label> -->
                      <select class="small" v-model="payload.order_template">
                        <option value=""></option>
                        <option v-for="(item, i) in printOutOrderTem" :value="item.id" :key="i">{{
                            item.name
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="right desc">
                  <h4>{{ $t('quote') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="payload.offer_template">
                        <option value=""></option>
                        <option v-for="(item, i) in printOutOfferTem" :value="item.id" :key="i">{{ item.name }}</option>
                      </select>
                    </div>
                  </div>
                  <h4>{{ $t('cash_invoice') }}</h4>
                  <div class="parent-small d-flex">
                    <div class="desc">
                      <!-- <label>{{ $t('template') }}</label> -->
                      <select class="small" v-model="payload.cash_invoice_template">
                        <option value=""></option>
                        <option v-for="(item,key) in printOutCashInvoiceTem" :key="key" :value="item.id">{{
                            item.name
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t('note') }}</label>
                <textarea v-model="GET_SINGLE_CUSTOMER_SEGMENT.notes"></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="d-flex justify-content-end">
        </div>
        <div class="buttons flex-wrap align-items-center justify-content-end justify-content-sm-between">
          <button type="submit" @click='showDeleteModal = true'>{{ $t('delete_segment') }}</button>
          <div class="right flex-wrap my-2 my-sm-0"><a href="javascript:void(0);" @click='$router.go(-1);'>{{ $t('cancel') }}</a>
            <button class="my-2 my-sm-0" type="button" :disabled="processing" v-on:click="updateCustomerSegment">
              <span v-if="processing">{{ $t('updating') }}...</span><span v-else> {{ $t('update_settings') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAlertVue v-if="showDeleteModal" @handleClose="showDeleteModal = false" :close="!true">
    <h4>{{ $t('delete_confirmation_text') }} <span class="user-confirm text-danger">{{ $t('customer_segment').toLowerCase() }}</span>?</h4>
    <div class="popup-bottons">
      <button @click="showDeleteModal = false">{{ $t('cancel_delete_request') }} </button>
      <button :disabled='processing' @click="deleteCustomerSegmentData">
        <span v-if="processing">{{ $t('deleting') }}...</span>
        <span v-else>{{ $t('accept_delete_request') }}</span>
      </button>
    </div>
  </ModalAlertVue>
</template>

<script>
import {mapGetters} from 'vuex'
import ModalAlertVue from '../../components/ModalAlert.vue'
import debounce from "lodash.debounce";

export default {
  components: {ModalAlertVue},
  name: 'EditCustomerSegment',
  data() {
    return {
      showDeleteModal: false,
      processing: false,
      printOutOrderTem: [],
      printOutInvoiceTem: [],
      printOutOfferTem: [],
      printOutCashInvoiceTem: [],
      paymentTerm: [],
      deliveryWay: [],
      deliveryTerm: [],
      accounts: [],
      pseudo_sales_account: '',
      sales_account: '',
      payload: {
        delivery_method: '',
        cash_invoice_template: '',
        invoice_template: '',
        offer_template: '',
        order_template: '',
        terms_of_payment: '',
        terms_of_delivery: '',
        currency: '',
        price_list: '',
      },
      costCenter: [],
      price_list: '',
      price_lists: [],
      currency: '',
      currencies: [],
      project: [],
    }
  },
  computed: {
    ...mapGetters({
      GET_SINGLE_CUSTOMER_SEGMENT: 'clients/GET_SINGLE_CUSTOMER_SEGMENT',
      CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  },
  mounted() {
    const {id} = this.$route.params
    this.getSingleCustomerSegment(id)
    this.getPrintOutTemplate('invoice')
    this.getPrintOutTemplate('cashinvoice')
    this.getPrintOutTemplate('order')
    this.getPrintOutTemplate('offer')
    this.getPaymentTerm()
    this.getDeliveryWay()
    this.getDeliveryTerm()
    this.getPriceList()
    this.getCurrency()
    let self = this
    setTimeout(function () {
      if (self.GET_SINGLE_CUSTOMER_SEGMENT) {
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.sales_account) {
          self.pseudo_sales_account = self.GET_SINGLE_CUSTOMER_SEGMENT.sales_account.number
          self.sales_account = self.GET_SINGLE_CUSTOMER_SEGMENT.sales_account.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.terms_of_payment) {
          self.payload.terms_of_payment = self.GET_SINGLE_CUSTOMER_SEGMENT.terms_of_payment.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.terms_of_delivery) {
          self.payload.terms_of_delivery = self.GET_SINGLE_CUSTOMER_SEGMENT.terms_of_delivery.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.cash_invoice_template) {
          self.payload.cash_invoice_template = self.GET_SINGLE_CUSTOMER_SEGMENT.cash_invoice_template.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.invoice_template) {
          self.payload.invoice_template = self.GET_SINGLE_CUSTOMER_SEGMENT.invoice_template.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.offer_template) {
          self.payload.offer_template = self.GET_SINGLE_CUSTOMER_SEGMENT.offer_template.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.order_template) {
          self.payload.order_template = self.GET_SINGLE_CUSTOMER_SEGMENT.order_template.id
        }
        // if (self.GET_SINGLE_CUSTOMER_SEGMENT.cost_center) {
        //   self.payload.cost_center = self.GET_SINGLE_CUSTOMER_SEGMENT.cost_center.id
        // }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.currency) {
          self.payload.currency = self.GET_SINGLE_CUSTOMER_SEGMENT.currency.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.delivery_method) {
          self.payload.delivery_method = self.GET_SINGLE_CUSTOMER_SEGMENT.delivery_method.id
        }
        if (self.GET_SINGLE_CUSTOMER_SEGMENT.price_list) {
          self.payload.price_list = self.GET_SINGLE_CUSTOMER_SEGMENT.price_list.id
        }
        // if (self.GET_SINGLE_CUSTOMER_SEGMENT.project) {
        //   self.payload.project = self.GET_SINGLE_CUSTOMER_SEGMENT.project.id
        // }
      }
    }, 2000)
  },
  methods: {
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'pricelists'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.price_lists = res.data.results
          })
          .catch(err => {
          })
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'currencies'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.currencies = res.data.results
          })
          .catch(err => {
          })
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'termsofpayment'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.paymentTerm = res.data.results
          })
          .catch(err => {
          })
    },
    getDeliveryWay() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'wayofdelivery'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.deliveryWay = res.data.results
          })
          .catch(err => {
          })
    },
    getDeliveryTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'termsofdelivery'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.deliveryTerm = res.data.results
          })
          .catch(err => {
          })
    },
    getPrintOutTemplate(data) {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + `printtemplates?type=${data}`
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            if (data === 'order') {
              this.printOutOrderTem = res.data.results
            } else if (data === 'invoice') {
              this.printOutInvoiceTem = res.data.results
            } else if (data === 'offer') {
              this.printOutOfferTem = res.data.results
            } else if (data === 'cashinvoice') {
              this.printOutCashInvoiceTem = res.data.results
            }
          })
          .catch(err => {
          })
    },
    getSingleCustomerSegment(data) {
      this.$store.dispatch('clients/getSingleCustomerSegment', data)
    },
    deleteCustomerSegmentData() {
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      this.processing = true
      this.$store.dispatch('clients/deleteCustomerSegment', {id: this.$route.params.id})
          .then(res => {
            this.processing = false
            this.showDeleteModal = false
            // this.$services.helpers.notification('Customer segment removed successfully', 'success', this)
            this.$toast.success(this.$t('segment_removed'));
            this.$router.push({name: 'customersegment'})
          })
          .catch(err => {
            this.processing = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    updateCustomerSegment() {
      // this.updating = true
      if (this.CURRENT_USER.access === 'Read') {
        return this.$toast.info(this.$t('limited_read_access'));
      }
      let payload = {}
      let field_to_exclude = ['customers', 'id', 'sales_account', 'subscription', 'price_list', 'order_template', 'project',
        'terms_of_delivery', 'terms_of_payment', 'cash_invoice_template', 'cost_center', 'invoice_template', 'offer_template',
        'order_template']
      for (const [key, value] of Object.entries(this.GET_SINGLE_CUSTOMER_SEGMENT)) {
        if (value !== '' || value !== null) {
          if (!field_to_exclude.includes(key)) {
            // if (typeof  )
            payload[key] = value
          }
        }

      }
      if (this.sales_account !== '') {
        payload['sales_account'] = this.sales_account
      }

      for (const [key, value] of Object.entries(this.payload)) {
        if (value !== '' || value !== null) {
          payload[key] = value
        }
      }
      const data_to_post = {
        data: payload,
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${this.GET_SINGLE_CUSTOMER_SEGMENT.id}/`
      }
      this.processing = true
      this.$store.dispatch('clients/customPutRequest', data_to_post)
          .then(res => {
            this.processing = false
            // this.$services.helpers.notification('Customer segment updated successfully', 'success', this)
            this.$toast.success(this.$t('segment_updated'));

          })
          .catch(err => {
            this.processing = false
            this.$services.helpers.notification(err.response.data.message, 'error', this)
          })
    },
    chooseSales(item, mode) {
      this.pseudo_sales_account = item.number
      this.sales_account = item.id
      this.showSalesAccount = false
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== '') {
        this.filterSearch(event.target.value, val)
      } else {
        this.showSalesAccount = false
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL: this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`
      }
      this.$store.dispatch('clients/getCustomRequest', payload)
          .then(res => {
            this.accounts = res.data.results
            if (mode === 'sales') {
              this.showSalesAccount = true
            }
          })
          .catch(err => {

          })
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'costcenters'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.costCenter = res.data.results
          })
          .catch(err => {
          })
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + 'projects'
      this.$store.dispatch('clients/getCustomRequest', {URL})
          .then(res => {
            this.project = res.data.results
          })
          .catch(err => {
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .headers ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .headers ~ a svg {
  margin-top: -2px;
}

.content .headers ~ a svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .headers ~ a:hover {
  color: #4946ae;
}

.content .headers ~ a:hover svg path {
  fill: #4946ae;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box.segment-details h3 {
  background-image: url("./../../assets/images/table/segment.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box.e-document h3 {
  background-image: url("./../../assets/images/table/e-document.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  margin: 0;
}

.content .box form {
  padding: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .content .box form.segment-details .col {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.content .box form .parent-small .desc:not(:first-of-type) {
  margin-left: 10px;
}

.content .box form h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.content .box form .desc {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 5px 5px 5px 0;
}

@media (min-width: 992px) {
  .content .box form .desc {
    margin-bottom: 20px;
  }
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 195px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

@media (max-width: 575.98px) {
  .content .box form .desc select {
    width: 150px;
  }
}

.content .box form .desc select.small {
  width: 94px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 195px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 767.98px) {
  .content .box form .desc input {
    width: 90%;
  }
}

.content .box form .desc input.small {
  width: 94px;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 12.469px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box form textarea {
  background: #f0eff5;
  width: 345px;
  height: 90px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
}

@media (max-width: 575.98px) {
  .content .box form textarea {
    width: 100%;
  }
}

.content .box form textarea:focus {
  border: none;
  outline: none;
}

.content .box h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box .left {
  margin-right: 40px;
}

@media (min-width: 992px) {
  .content .box .parent-small.more-margin .desc:not(:first-of-type) {
    margin-left: 30px;
  }
}

.content .box:last-of-type {
  margin-bottom: 20px;
}

.content .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
}

.content .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 18px;
  width: 18px;
  z-index: 33;
  background: #fffbfb;
}

.content .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .checkbox input,
.content .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .checkbox input:checked ~ svg {
  display: block;
}

.content .checkbox svg {
  position: absolute;
  height: 20px;
  width: 20px;
  display: none;
  z-index: 1;
}

.content .checkbox ~ p {
  margin: 0;
  margin-left: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  margin-top: 20px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 120px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  color: #fff;
  background-color: #de4343;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  text-align: center;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button:last-of-type {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}
</style>
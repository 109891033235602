<template>
  <div class="col responsive-width">
    <FullScreenLoading v-if="loading"/>
    <div class="container-fluid">
      <div class="content">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t("contracts") }}</h1>
          <h6>{{ $t("create_new_contract") }}</h6>
        </div>
        <div class="contract-duration flex-wrap justify-content-between">
          <h2 class="mr-3">
            <!-- Contract 134 -->
          </h2>
          <div class="duration my-2 flex-wrap">
            <p>{{ $t("contract") }} {{ $t("start") }}:{{ contractStart }}</p>
            <p>{{ $t("contract") }} {{ $t("end") }}: {{ contractEnd }}</p>
          </div>
        </div>

        <div class="box contract-details">
          <h3>{{ $t("contract_detail") }}</h3>
          <form>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label
                >{{ $t("customer") }} <span class="asterisk">*</span></label
                >
                <input
                    class="w-full"
                    @input="searchField"
                    type="text"
                    v-model="choosenCustomerData.name"
                />
                <div
                    v-if="showCustomer"
                    style="z-index: 9999; width: 12%"
                    class="position-absolute bg-white rounded shadow-md border mt-5"
                >
                  <ul
                      style="padding-left: 0rem !important; list-style-type: none"
                  >
                    <li
                        class="border-bottom p-2 mb-1 cursor-pointer"
                        v-for="item in searchCustomer.slice(0, 5)"
                        :key="item.id"
                        @click="chooseCustomer(item)"
                    >
                      {{ item.customer_number }} - {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="desc">
                <label
                >{{ $t("contract_date") }}
                  <span class="asterisk">*</span></label
                >
                <Datepicker
                    v-model="contractData.contract_date"
                    format="yyyy-MM-dd"
                    @update:modelValue="computeContractEnd"
                ></Datepicker>
              </div>
              <div class="desc">
                <label
                >{{ $t("period_start") }}
                  <span class="asterisk">*</span></label
                >
                <Datepicker
                    v-model="contractData.period_start"
                    format="yyyy-MM-dd"
                    @update:modelValue="computeDate"
                ></Datepicker>
              </div>
              <div class="desc">
                <label
                >{{ $t("period_end") }} <span class="asterisk">*</span></label
                >
                <div class="period_end_desc">
                  <span>{{ contractData.period_end }}</span>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("active") }}</label>
                <!-- <SwitchButton v-model="contractData.active" :switchValue='contractData.active' /> -->
                <div class="radio">
                  <input
                      type="radio"
                      v-model="contractData.active"
                      :value="true"
                      name="active"
                      id="ac-yes-active"
                  />
                  <label for="ac-yes-active">{{ $t("yes") }}</label>
                  <input
                      type="radio"
                      v-model="contractData.active"
                      :value="false"
                      name="active"
                      id="ac-no-active"
                  />
                  <label for="ac-no-active">{{ $t("no") }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("ongoing") }}</label>
                <!-- <SwitchButtonContinouse v-model="contractData.continuous" :second="true" :switchValue='contractData.continuous' /> -->
                <div class="radio">
                  <input
                      type="radio"
                      v-model="contractData.continuous"
                      :value="true"
                      name="ongoing"
                      id="on-yes"
                  />
                  <label for="on-yes">{{ $t("yes") }}</label>
                  <input
                      type="radio"
                      v-model="contractData.continuous"
                      :value="false"
                      name="ongoing"
                      id="on-no"
                  />
                  <label for="on-no">{{ $t("no") }}</label>
                </div>
              </div>
              <div class="desc ms-3">
                <label>{{ $t("contract_length") }}</label>
                <input
                    type="number"
                    min="1"
                    v-model="contractData.contract_length"
                    v-on:focusout="computeContractEnd"
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice_interval") }}</label>
                <input
                    type="number"
                    min="1"
                    v-model="contractData.invoice_interval"
                    v-on:focusout="computeDate"
                />
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("our_reference") }}</label>
                <input type="text" v-model="contractData.our_reference"/>
              </div>
              <div class="desc">
                <label>{{ $t("your_reference") }}</label>
                <input type="text" v-model="contractData.your_reference"/>
              </div>
              <!--              <div class="desc">-->
              <!--                <label>Your order date</label>-->
              <!--                <input type="text" v-model="contractData.your_reference">-->
              <!--              </div>-->
              <div class="desc">
                <label>{{ $t("your_order_number") }}</label>
                <input type="text" v-model="contractData.your_order_number"/>
              </div>
              <div class="desc">
                <label>{{ $t("terms_of_payment") }}</label>
                <select v-model="contractData.terms_of_payment">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in paymentTerm"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("terms_of_delivery") }}</label>
                <select v-model="contractData.terms_of_delivery">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in deliveryTerm"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("way_of_delivery") }}</label>
                <select v-model="contractData.way_of_delivery">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in deliveryWay"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("cost_center") }}(CC)</label>
                <select v-model="contractData.cost_center">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in costCenter"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("project") }} (Pr no)</label>
                <select v-model="contractData.project">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in project"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("price_list") }}</label>
                <select v-model="contractData.price_list">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in pricelist"
                      :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("currency") }}</label>
                <select v-model="contractData.currency">
                  <option value=""></option>
                  <option
                      :value="item.code"
                      v-for="item in currency"
                      :key="item.id"
                  >
                    {{ item.code }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("vat_included") }}</label>
                <div class="radio">
                  <input
                      v-model="contractData.vat_included"
                      type="radio"
                      :value="true"
                      name="vat"
                      id="vat-yes"
                  />
                  <label for="vat-yes">{{ $t("yes") }}</label>
                  <input
                      v-model="contractData.vat_included"
                      type="radio"
                      :value="false"
                      name="vat"
                      id="vat-no"
                  />
                  <label for="vat-no">{{ $t("no") }}</label>
                </div>
              </div>
              <!--              <div class="desc">-->
              <!--                <label>VAT</label>-->
              <!--                <select>-->
              <!--                  <option value=""></option>-->
              <!--                  <option value="SE">SE</option>-->
              <!--                  <option value="SE reverse charge">SE reverse charge</option>-->
              <!--                  <option value="EU reverse charge">EU reverse charge</option>-->
              <!--                  <option value="Subject to EU VAT">Subject to EU VAT</option>-->
              <!--                  <option value="Export">Export</option>-->
              <!--                </select>-->
              <!--              </div>-->
              <!-- <div class="desc">
                <label>Type of VAT</label>
                <div class="radio">
                  <input type="radio" value="yes" name="vat" id="vat-yes" checked>
                  <label for="vat-yes">Yes</label>
                  <input type="radio" value="no" name="vat" id="vat-no">
                  <label for="vat-no">No</label>
                </div>
              </div> -->
              <!-- <img class="mt-2" src="./../../assets/images/table/create-chat.png"> -->
            </div>
          </form>
        </div>
        <div class="box customer-details">
          <h3>{{ $t("customer_details") }}</h3>
          <form>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("customer_name") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="choosenCustomerData.name"
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice_address") }} 1</label>
                <input
                    class="big"
                    type="text"
                    :value="choosenCustomerData.address1"
                />
              </div>
              <div class="desc">
                <label>{{ $t("zip_code") }}</label>
                <input type="text" :value="choosenCustomerData.zip_code"/>
              </div>
              <div class="desc">
                <label>{{ $t("city") }}</label>
                <input type="text" :value="choosenCustomerData.city"/>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("organization_number") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="choosenCustomerData.organisation_number"
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice_address") }} 2</label>
                <input
                    class="big"
                    type="text"
                    :value="choosenCustomerData.address2"
                />
              </div>
              <div class="desc">
                <label>{{ $t("country") }}</label>
                <!-- <input type="text" v-model="customerData.country"> -->
                <select v-model="choosenCustomerData.country">
                  <option
                      v-if="choosenCustomerData"
                      :value="choosenCustomerData.country"
                  >
                    {{ choosenCustomerData.country }}
                  </option>
                  <option
                      :selected="choosenCustomerData.country_code == item.key"
                      :value="item.key"
                      v-for="(item, i) in countries"
                      :key="i"
                  >
                    {{ item.name + " \ " + item }}
                  </option>
                </select>
              </div>
              <!-- <div class="desc">
                <label>{{$t('country')}}</label>
                <input class="big" type="text" v-model="countryInput" @input="showCountriesList = true">
                
                <option :value="item.key" v-for="(item, i) in countries" :key="i">{{ item.name }}</option>
              </div> -->
              <div class="desc">
                <label>{{ $t("phone1") }}</label>
                <input
                    class="big"
                    type="text"
                    :value="choosenCustomerData.phone1"
                />
              </div>
            </div>
          </form>
        </div>
        <custom-invoice-row-component
            :invoice_rows="contractData.invoice_rows"
            :key="sortKey"
            @removeRow="removeRow"
            @calculateRow="reCalculateValues"
            :invoice_country="choosenCustomerData.country_code"
            :vat_included="contractData.vat_included"
            :show-schedule="false"
            :submitForm="submitForm"
            @updateContract="addContract"
        />
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="box others-details">
              <h3>{{ $t("other_detail") }}</h3>
              <form>
                <div class="d-flex mt-xl-3 flex-wrap col-flex-wrap">
                  <div class="desc me-0 w-full">
                    <label>{{ $t("remarks") }}</label>
                    <textarea class="w-full" v-model="contractData.remarks">
                    </textarea>
                  </div>
                  <div class="desc me-0 w-full">
                    <label>{{ $t("comment") }}</label>
                    <textarea class="w-full" v-model="contractData.comments">
                    </textarea>
                  </div>
                  <div class="desc">
                    <label>{{ $t("print_template") }}</label>
                    <select class="big" v-model="contractData.print_template">
                      <option value=""></option>
                      <option
                          v-for="(item, i) in printOutTemplate"
                          :value="item.template"
                          :key="i"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col">
            <div class="box price">
              <ul class="list-unstyled">
                <!-- <li><span class="text">{{  $t('net') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ contractData.total }}</span>
                </li>
                <li><span class="text">{{ $t('gross') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ contractData.gross }}</span>
                </li> -->
                <li>
                  <span class="text">{{ $t("vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ contractData.total_vat }}</span>
                </li>
                <li>
                  <span class="text">{{ $t("total_exclude_vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ contractData.total }}</span>
                </li>
                <hr/>
                <li>
                  <span class="text">{{ $t("total") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ contractData.total_to_pay }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="buttons flex-wrap align-items-center">
          <!-- <button type="submit">Terminate contract</button> -->
          <div class="right flex-wrap my-2 my-sm-0">
            <router-link :to="{ name: 'contracts' }">{{
                $t("cancel")
              }}
            </router-link>
            <button
                @click="submitForm = true"
                :disabled="processing"
                type="submit"
            >
              <span v-if="processing">{{ $t("creating") }}...</span>
              <span v-else>{{ $t("create_contract") }}</span>
            </button>
            <!-- <button @click="addContract" :disabled='processing' type="submit">
              <span v-if="processing">{{$t('creating')}}...</span>
              <span v-else>{{$t('create_contract')}}</span>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullScreenLoading from "../../components/FullScreenLoading.vue";
import {mapGetters} from "vuex";
import debounce from "lodash.debounce";
import {contractObject, contractRow} from "./composables/contract.create";
import moment from "moment";
import Datepicker from "vue3-date-time-picker";
import CustomInvoiceRowComponent from "./reusableComponents/CustomInvoiceRowComponent";
import {getCountries} from "./composables/countries";

export default {
  name: "CreateContract",
  components: {Datepicker, CustomInvoiceRowComponent, FullScreenLoading},
  data() {
    return {
      sortKey: 0,
      loading: false,
      contractData: contractObject,
      row: {...contractRow},
      deliveryTerm: [],
      showCustomer: false,
      showArticle: false,
      contractEnd: "",
      contractStart: "",
      showAccount: false,
      searchCustomer: [],
      searchArticle: [],
      printOutTemplate: [],
      choosenCustomerData: {
        address1: "",
        address2: "",
        country: "",
        // currency: 'SEK',
        customer_number: "",
        email_offer: "",
        name: "",
        phone1: "",
        zip_code: "",
      },
      pricelist: [],
      paymentTerm: [],
      deliveryWay: [],
      project: [],
      currency: [],
      accounts: [],
      costCenter: [],
      units: [],
      processing: false,
      pseudo_contract_end: 0,
      countries: getCountries(),
      submitForm: false,
    };
  },
  watch: {
    "contractData.continuous": function (n, o) {
      if (n) {
        this.contractData.contract_length = 0;
      } else {
        this.contractData.contract_length = 1;
      }
      this.computeContractEnd();
    },
    "contractData.vat_included": function (newVal, oldVal) {
      // Identified BUG -- Results to 0 in Total after change in value
      this.reCalculateValues(null);
    },
  },
  beforeMount() {
    this.contractData = {...this.contractData, invoice_rows: []};
  },

  mounted() {
    this.getDeliveryTerm();
    this.getPriceList();
    this.getPaymentTerm();
    this.getDeliveryWay();
    this.getCurrency();
    this.getAccount();
    this.getCostCenter();
    this.getProjects();
    this.getUnit();
    this.getPrintOutTemplate();
    this.computeDate();
    this.computeContractEnd();
  },
  methods: {
    computeDate: function () {
      this.contractData.period_end = moment(this.contractData.period_start)
          .add(this.contractData.invoice_interval, "M")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      this.contractStart = moment(this.contractData.contract_date).format(
          "YYYY-MM-DD"
      );
      // this.contractData.period_start = moment(this.contractData.period_start).format('YYYY-MM-DD')
    },
    computeContractEnd: function () {
      this.contractStart = moment(this.contractData.contract_date).format(
          "YYYY-MM-DD"
      );
      if (this.contractData.continuous === false) {
        if (this.contractData.contract_date) {
          this.contractEnd = moment(this.contractData.contract_date)
              .add(this.contractData.contract_length, "M")
              .format("YYYY-MM-DD");
        } else {
          this.contractEnd = "";
        }
      } else {
        this.contractEnd = "";
      }
    },
    getDiscountedTotal(total, discount, discountType) {
      if (discount) {
        if (discountType === "AMOUNT") {
          total = total - discount
        } else {
          total = total *
          (
              1 -
              parseFloat(
                  this.$services.helpers.removePercentage(discount) / 100
              )
          )
        }
      }
      return parseFloat(total.toFixed(2));
    },
    searchField: debounce(function (event) {
      if (event.target.value.trim() !== "") {
        this.filterSearch(event.target.value);
      } else {
        this.showCustomer = false;
      }
    }, 500),
    filterSearch(data) {
      const payload = {
        URL: this.$services.endpoints.CUSTOMER_ENDPOINT + `?search=${data}`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showCustomer = true;
              this.searchCustomer = res.data.data.filter(
                  (item) => item.active === true
              );
            }
          })
          .catch((err) => {
          });
    },
    searchArticleField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticleSearch(event.target.value);
      } else {
        this.showArticle = false;
      }
    }, 500),
    searchAccountField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterAccountSearch(event.target.value);
      } else {
        this.showAccount = false;
      }
    }, 500),
    filterArticleSearch(data) {
      const payload = {
        URL:
            this.$services.endpoints.ARTICLE_ENDPOINT +
            `?search=${data}&customize=no&active=1`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.showArticle = true;
              this.searchArticle = res.data.data.filter(
                  (item) => item.active === true
              );
            }
          })
          .catch((err) => {
          });
    },
    filterAccountSearch(data) {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT + `accounts?search=${data}`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.showAccount = true;
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    chooseArticle(item) {
      this.row.row_id = item.article_number;
      this.row.description = item.description;
      this.row.price = (item.sales_price == null) ? 0 : item.sales_price;
      if (this.row.price && this.row.delivered_quantity) {
        this.row.total = this.row.delivered_quantity * this.row.price;
      }
      this.row.vat = item.vat;
      this.row.account_number = item.sales_account;
      this.row.unit = item.unit;
      this.showArticle = false;

      this.reCalculateValues(null);
    },
    chooseAccount(item) {
      this.row.account_number = item.number;
      this.showAccount = false;

      this.reCalculateValues(null);
    },
    chooseCustomer(customer) {
      // this.contractData.customer_name = customer.name;
      if (!customer.active) {
        this.$toast.warning(
            "This customer is not active. Please select another customer."
        );
        return;
      }
      this.contractData.customer_number = customer.customer_number;
      this.contractData.our_reference = customer.our_reference;
      this.contractData.your_reference = customer.your_reference;
      this.contractData.currency = customer.currency;
      this.contractData.cost_center = customer.cost_center;
      this.contractData.price_list = customer.price_list;
      this.contractData.terms_of_delivery = customer.terms_of_delivery;
      this.contractData.terms_of_payment = customer.terms_of_payment;
      this.contractData.way_of_delivery = customer.way_of_delivery;
      this.contractData.project = customer.project;

      this.choosenCustomerData = customer;
      this.showCustomer = false;
    },
    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.contractData.invoice_rows.forEach((element) => {
        let vat_amount = 0;
        let vat_rate;
        total_excluding_vat += parseFloat(element.total_excluding_vat);

        if (!element.vat) {
          vat_rate = 0;
        } else {
          vat_rate = element.vat
        }
        vat_amount =
            element.total_excluding_vat * (1 + vat_rate / 100) -
            element.total_excluding_vat;
        total += parseFloat(
            element.total_excluding_vat * (1 + vat_rate / 100)
        );
        total_vat += vat_amount;

        if (this.contractData.vat_included) {
          element.total = (
              parseFloat(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat)*
              parseFloat(1 + parseFloat(vat_rate) / 100)
          );
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);
          
          element.price = (
              parseFloat(element.price_excluding_vat) *
              parseFloat(1 + parseFloat(vat_rate) / 100)
          ).toFixed(2);
        } else {
          element.total = (
              parseFloat(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat) 
          );
          element.total = this.getDiscountedTotal(element.total, element.discount, element.discount_type);


          element.total_excluding_vat = parseFloat(
              element.total_excluding_vat
          ).toFixed(2);

          element.price_excluding_vat = parseFloat(
              element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        let vat_rate;
        if (!item.vat) {
          vat_rate = 0;
        } else {
          vat_rate = item.vat;
        }
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.contractData.vat_included) {
            item.total =
                parseFloat(item.delivered_quantity).toFixed(2) *
                parseFloat(item.price_per_unit).toFixed(2) *
                (
                    1 -
                    parseFloat( 
                        this.$services.helpers.removePercentage(item.discount) / 100
                    )
                ).toFixed(2);
            item.total_excluding_vat =
                item.total / (1 + parseFloat(vat_rate) / 100);
          } else {
            item.total_excluding_vat =
                parseFloat(item.delivered_quantity).toFixed(2) *
                parseFloat(
                    item.price_per_unit *
                    parseFloat(
                        1 -
                        parseFloat(
                            this.$services.helpers.removePercentage(item.discount) /
                            100
                        ).toFixed(2)
                    ).toFixed(2)
                ).toFixed(2);
            item.total =
                item.total_excluding_vat * (1 + parseFloat(vat_rate) / 100);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat);

          vat = item.total - item.total_excluding_vat;
          total_vat += vat;
        }
      }

      this.contractData.gross = parseFloat(total).toFixed(2);
      this.contractData.total_vat = parseFloat(total_vat).toFixed(2);
      this.contractData.total = parseFloat(total_excluding_vat).toFixed(2);
      this.contractData.total_to_pay = parseFloat(total).toFixed(2);
    },
    addContractInvoiceRow() {
      if (this.row.price && this.row.delivered_quantity) {
        if (!this.row.account_number && this.row.price) {
          // this.$services.helpers.notification('Kindly specify account number', 'error', this)
          this.$toast.warning(this.$t("select_account_number"));
          return false;
        } else if (this.row.description === "" && this.row.row_id === "") {
          // this.$services.helpers.notification('Description or Article number is required', 'error', this)
          this.$toast.warning(this.$t("description_article_required"));
          return false;
        } else {
          this.contractData.invoice_rows.push(
              JSON.parse(JSON.stringify(this.row))
          );
          // Object.keys(this.row).forEach(element => {
          //     this.row[element] = ''
          // })
          this.setDefaultRows();
          this.reCalculateValues(null);
        }
      } else {
        if (this.row.description || this.row.row_id) {
          this.contractData.invoice_rows.push(
              JSON.parse(JSON.stringify(this.row))
          );
          // Object.keys(this.row).forEach(element => {
          //     this.row[element] = ''
          // })
          this.setDefaultRows();
          this.reCalculateValues(null);
        } else {
          // this.$services.helpers.notification('Kindly specify a valid invoice row information', 'error', this)
          this.$toast.warning(this.$t("invalid_invoice_row"));
          return false;
        }
      }
      // if (this.row.row_id === '' || this.row.account_number === '') {
      //   this.$services.helpers.notification('Please fill up the invoice row', 'error', this)
      // } else {
      //   this.contractData.invoice_rows.push(JSON.parse(JSON.stringify(this.row)))
      //   // Object.keys(this.row).forEach(element => {
      //   //     this.row[element] = ''
      //   // })
      //   this.setDefaultRows()
      //   this.reCalculateValues()
      // }
    },
    addContract() {
      this.loading = true;
      this.submitForm = false;
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (
          this.contractData.contract_date === "" ||
          this.contractData.period_start === "" ||
          this.contractData.period_end === "" ||
          this.contractData.customer_name === ""
      ) {
        // this.$services.helpers.notification('Fields mark with astericks are required', 'error', this)
        this.$toast.warning(this.$t("starred_fields_required"));
      } else {
        // this.submitForm = true;
        let contractData = this.contractData;
        let invoice_row = this.contractData.invoice_rows;
        let new_invoice_rows = [];
        for (const [key, value] of Object.entries(contractData)) {
          if (value === "" || value === null) {
            delete contractData[key];
          }

          if (["period_end", "period_start", "contract_date"].includes(key)) {
            contractData[key] = moment(value).format("YYYY-MM-DD");
          }
        }
        invoice_row.map((item) => {
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete item[key];
            }
          }
          new_invoice_rows.push(JSON.parse(JSON.stringify(item)));
        });

        // new_invoice_rows.forEach((item) => {
        //   if (this.contractData.vat_included === false) {

        //     item.price = parseFloat(item.price / (1 + item.vat / 100)).toFixed(
        //       2
        //     );
        //     item.total = parseFloat(item.total / (1 + item.vat / 100)).toFixed(
        //       2
        //     );
        //   }
        // });

        contractData.invoice_rows = new_invoice_rows;
        // contractData = { ...contractData, ...this.choosenCustomerData };
        this.processing = true;
        this.$store
            .dispatch("clients/addContract", contractData)
            .then((res) => {
              this.processing = false;
              this.submitForm = false;

              this.$services.helpers.notification(
                  res.data[this.locale_message],
                  "success",
                  this
              );
              this.loading = false;

              // redirect to edit contract page
              this.$router.push({
                name: "editContract",
                params: {
                  num: res.data.data.document_number,
                },
              });
            })
            .catch((err) => {
              this.processing = false;
              this.$services.helpers.notification(
                  err.response.data[this.locale_message],
                  "error",
                  this
              );

              this.loading = false;
            });
      }
    },
    getPrintOutTemplate() {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT +
          `printtemplates?type=invoice`;
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.printOutTemplate = res.data.results;
          })
          .catch((err) => {
          });
    },
    getDeliveryTerm() {
      const URL =
          this.$services.endpoints.REGISTER_ENDPOINT + "termsofdelivery";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.deliveryTerm = res.data.results;
          })
          .catch((err) => {
          });
    },
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "pricelists";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.pricelist = res.data.results;
          })
          .catch((err) => {
          });
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "termsofpayment";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.paymentTerm = res.data.results;
          })
          .catch((err) => {
          });
    },
    getDeliveryWay() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "wayofdelivery";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.deliveryWay = res.data.results;
          })
          .catch((err) => {
          });
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "currencies";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.currency = res.data.results;
          })
          .catch((err) => {
          });
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "accounts";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.accounts = res.data.results;
          })
          .catch((err) => {
          });
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "costcenters";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.costCenter = res.data.results;
          })
          .catch((err) => {
          });
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "projects";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.project = res.data.results;
          })
          .catch((err) => {
          });
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.units = res.data.results;
          })
          .catch((err) => {
          });
    },
    removeRow(item) {
      this.contractData.invoice_rows = this.contractData.invoice_rows.filter(el => el.id !== item.id);
      this.sortKey++;
      this.reCalculateValues(null);
    },
    setDefaultRows() {
      this.row = {...contractRow};
    },
    rowChange(item) {
      this.reCalculateValues(null);
      // this.contractData.invoice_rows[index] = item
    },
  },
  computed: {
    ...mapGetters({
      CURRENT_USER: "clients/GET_CURRENT_USER",
      GET_LOCALE: 'locale/GET_LOCALE'
    }),
    locale_message() {
      return this.GET_LOCALE === 'se' ? 'message' : 'eng_message'
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .contract-duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
}

.content .contract-duration h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .contract-duration .duration p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin: 0;
}

.content .contract-duration .duration p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.contract-details h3 {
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.content .box.contract-details img {
  width: 55px;
  height: 55px;
  margin-left: auto;
  cursor: pointer;
}

.content .box.customer-details h3 {
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.invoice h3 {
  background-image: url("./../../assets/images/table/invoice-row.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.others-details h3 {
  background-image: url("./../../assets/images/table/others.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

@media (min-width: 992px) {
  .content .box form > div:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .box form .desc label {
  margin-bottom: 10px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 32px;
  width: 150px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 32px;
  width: 150px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .content .box.contract-details form > div:first-of-type {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .content .box.customer-details .desc {
  }
}

.content .box table {
  width: 94%;
  margin: 10px auto;
  background: #fff;
}

.content .box table thead th {
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table thead th:last-of-type {
  padding-right: 15px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: -webkit-calc(100% - 30px);
  width: calc(100% - 30px);
  height: 1px;
  background-color: #cbd4e1;
  left: 15px;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td input.small {
  width: 31px;
}

.content .box table tbody tr td:not(:last-of-type):after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box table tbody tr td:nth-of-type(1) input,
.content .box table tbody tr td:nth-of-type(2) input {
  margin-left: 5px;
  text-align: left;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

.content .box textarea:focus {
  border: none;
  outline: none;
}

.content .box.others-details {
  min-height: 320px;
}

.content .box.price {
  padding: 30px 20px;
}

.content .box.price ul {
  margin: 0;
}

.content .box.price ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box.price ul li .border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 10px;
  margin-top: 2px;
}

.content .box.price ul li:not(:first-of-type) {
  margin-top: 10px;
}

.content .box.price ul li:last-of-type {
  margin-top: 0;
}

.content .box.price ul li:last-of-type span:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box.price ul li:last-of-type span:last-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:first-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:last-of-type {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  background-color: #de4343;
  color: #fff;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.period_end_desc {
  background: #f0eff5;
  height: 34px;
  width: 100%;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
}

.period_end_desc > span {
  position: absolute;
  top: 10px;
  left: 27px;
}

.input-select {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  text-indent: 5px;
}

.col-form {
  background: #e6e5eb;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
  width: 100px !important;
}

.dp__main.dp__theme_light,
.dp__input_wrap {
  min-width: 130px;
}
</style>

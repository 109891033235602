<template>
  <td>
    <div class="checkbo">
      <input type="checkbox" v-model="props.data.checked" :value="props.data.checked"
             class="form-check-input">
      <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
      <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
      </svg> -->
    </div>
  </td>

  <td v-for="(item, i) in head" :key="i">
    <router-link class="unset-pad" :to="{ name : 'editInvoice', params : { id : props.data.invoice_number } }">
      <p v-if="item === 'invoice_amount'">{{ data['total'] }}</p>
      <p v-else>{{ data[item] }}</p>
    </router-link>
  </td>
  <td>
        <span style="white-space: nowrap" v-if="processing === false">
            <button class="cursor-pointer" type="button" v-on:click="processNoxFinansAction('pause')">
                 <svg width="8" height="15" viewbox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                 >
          <path
              d="M0.777303 0.612305C0.97996 0.612305 1.17432 0.69281 1.31762 0.83611C1.46092 0.97941 1.54142 1.17377 1.54142 1.37642V13.6023C1.54142 13.805 1.46092 13.9993 1.31762 14.1426C1.17432 14.2859 0.97996 14.3664 0.777303 14.3664C0.574646 14.3664 0.380289 14.2859 0.236989 14.1426C0.0936889 13.9993 0.0131836 13.805 0.0131836 13.6023V1.37642C0.0131836 1.17377 0.0936889 0.97941 0.236989 0.83611C0.380289 0.69281 0.574646 0.612305 0.777303 0.612305ZM6.89026 0.612305C7.09291 0.612305 7.28727 0.69281 7.43057 0.83611C7.57387 0.97941 7.65438 1.17377 7.65438 1.37642V13.6023C7.65438 13.805 7.57387 13.9993 7.43057 14.1426C7.28727 14.2859 7.09291 14.3664 6.89026 14.3664C6.6876 14.3664 6.49324 14.2859 6.34994 14.1426C6.20664 13.9993 6.12614 13.805 6.12614 13.6023V1.37642C6.12614 1.17377 6.20664 0.97941 6.34994 0.83611C6.49324 0.69281 6.6876 0.612305 6.89026 0.612305Z"
              fill="#5855D6"></path>
        </svg>
            </button>
            <button type="button" v-on:click="processNoxFinansAction('stop')">
              <img src="../../../assets/images/icons-clickable/stop-button.png" width="15" height="15"/>
            </button>
        </span>
    <icon_loading v-else/>
  </td>
  <ModalBoxVue v-if="openPauseForm" @handleClose="openPauseForm = false" :close="!true">
    <div class="popup-box">
      <h4 style="text-align:left !important">Pause invoice</h4>
      <div class="p-3">
        <div class="desc" style="display: flex;justify-content: space-between;margin-bottom: 15px;">
          <label>Pause until</label>
          <Datepicker v-model="pause_util"
                      format="dd-MM-yyyy"></Datepicker>
        </div>
        <div class="desc">
          <button @click="pauseNoxInvoice" :disabled='pauseProcessing' type="button" style="    float: left;
    margin-bottom: 13px;">
            <span v-if="pauseProcessing">Pausing...</span>
            <span v-else>Pause</span>
          </button>
        </div>
      </div>
    </div>
  </ModalBoxVue>
</template>

<script>
import {useStore} from 'vuex'
import ModalBoxVue from '../../../components/ModalBox.vue'
import Datepicker from 'vue3-date-time-picker';
import moment from 'moment'
import icon_loading from "../../../components/icon_loading";

export default {
  name: 'InvoiceTable',
  components: {ModalBoxVue, Datepicker, icon_loading},
  emits: ['registerPayment', 'createCopyInvoice', 'sendInvoice'],
  props: {
    data: Object,
    head: Array,
  },
  setup(props, {emit}) {
    const store = useStore()

    const checkboxOrder = (e) => store.commit('clients/TOGGLE_INVOICE_CHECKBOX', {
      id: props.data.id,
      value: props.data.checked
    })
    const registerPayment = () => emit('registerPayment', props.data)
    const createCopyInvoice = () => emit('createCopyInvoice', props.data)
    const sendInvoice = () => {
      // store.commit('clients/TOGGLE_INVOICE_CHECKBOX', { id : props.data.id, value : !props.data.checked  })
      emit('sendInvoice', props.data)
    }
    return {
      props,
      checkboxOrder,
      registerPayment,
      sendInvoice,
      createCopyInvoice
    }
  },
  data: function () {
    return {
      processing: false,
      pauseProcessing: false,
      openPauseForm: false,
      pause_util: ''
    }
  },
  methods: {
    pauseNoxInvoice: function () {
      this.pauseProcessing = true
      if (this.pause_util === '') {
        this.pauseProcessing = false
        // return this.$services.helpers.notification('kindly supply a valid date', 'error', this)
        return this.$toast.warning(this.$t('invalid_date'));
      }
      this.$store.dispatch('clients/postNoxFinanceAction', {
        'action': 'pause',
        'id': this.data.invoice_number,
        'paused_until_date': moment(this.pause_util).format('YYYY-MM-DD')
      }).then(res => {
        this.pauseProcessing = false
        // this.$services.helpers.notification('Nox finans paused successfully', 'success', this)
        this.$toast.success(this.$t('nox_paused'));

      }).catch(err => {
        this.pauseProcessing = false
        this.$services.helpers.notification(err.response.data.message, 'error', this)
      })
    },
    processNoxFinansAction: function (action) {
      if (action === 'pause') {
        this.openPauseForm = true
      } else if (action === 'stop') {
        this.processing = true
        this.$store.dispatch('clients/postNoxFinanceAction', {
          'action': 'stop',
          'id': this.data.invoice_number
        }).then(res => {
          this.processing = false
          // this.$services.helpers.notification('Nox finans stopped successfully', 'success', this)
          this.$toast.success(this.$t('nox_stopped'));
        }).catch(err => {
          this.processing = false
          this.$services.helpers.notification(err.response.data.message, 'error', this)
        })

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox svg span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table thead {
  border-bottom: 0.978073px solid #cbd4e1;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  //   white-space: nowrap;
}

.content .box table thead th:first-of-type {
  padding-left: 10px;
}

.content .box table thead th:last-of-type {
  padding-right: 10px;
}

.content .box table thead th span {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.content .box table tbody tr {
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}

.content .box table tbody tr:hover {
  background-color: #f8fbff !important;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f1f4f9;
}

.content .box table tbody td {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  //   white-space: nowrap;
}

.content .box table tbody td a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 10px 5px;
}

.content .box table tbody td:first-of-type {
  padding-left: 10px;
}

.content .box table tbody td button {
  background: transparent;
  border: none;
  padding: 0;
}

.content .box table tbody td button:not(:last-of-type) {
  margin-right: 10px;
}

.content .box table tbody td button svg path {
  -webkit-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
}

.content .box table tbody td button:hover svg path {
  fill: #4946ae;
}

.content .box table tbody tr.booked td a {
  color: #4d82f3;
}

.content .box table tbody tr.booked td button:nth-child(2) {
  opacity: 0.5;
  //   pointer-events: none;
}

.content .box table tbody tr.notbooked td a {
  color: #efb008;
}

.content .box table tbody tr.notbooked td:not(:last-of-type) path {
  fill: #64748b;
}

.content .box table tbody tr.unpaid td a {
  color: #fb4b4b;
}

.content .box table tbody tr.unpaid td button:nth-child(2) {
  opacity: 0.5;
  //   pointer-events: none;
}

.content .box table tbody tr.fullypaid td a {
  color: #419e6a;
}

.content .box table tbody tr.fullypaid td button:nth-child(1),
.content .box table tbody tr.fullypaid td button:nth-child(2) {
  opacity: 0.5;
  //   pointer-events: none;
}

.content .box table tbody tr.voided td a {
  color: #64748b;
}

.content .box table tbody tr.voided td:not(:last-of-type) path {
  fill: #64748b;
}

.content .actions {
  margin-top: 30px;
  margin-bottom: 20px;
}

.content .actions label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .actions select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .actions select:focus {
  outline: none;
}

.content .actions button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .actions button:hover {
  background-color: #4947b0;
  color: #fff;
}

button:hover {
  background-color: #4947b0;
  color: #fff;
}

@media (max-width: 575.98px) {
  .content .actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content .actions * {
    margin: 5px !important;
  }
}
</style>
export const ALL_COUNTRY_NAME_CODE = {
  AF: "Afghanistan",
  AL: "Albanien",
  DZ: "Algeriet",
  VI: "Amerikanska Jungfruöarna",
  AS: "Amerikanska Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktis",
  AG: "Antigua och Barbuda",
  AR: "Argentina",
  AM: "Armenien",
  AW: "Aruba",
  AU: "Australien",
  AZ: "Azerbajdzjan",
  AX: "Åland",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BE: "Belgien",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Plurinational State of Bolivia",
  BA: "Bosnien och Hercegovina",
  BW: "Botswana",
  BV: "Bouvetön",
  BR: "Brasilien",
  VG: "Brittiska Jungfruöarna",
  IO: "Brittiska territoriet i Indiska Oceanen",
  BN: "Brunei",
  BG: "Bulgarien",
  BF: "Burkina Faso",
  MM: "Burma",
  BI: "Burundi",
  KY: "Caymanöarna",
  CF: "Centralafrikanska republiken",
  CL: "Chile",
  CO: "Colombia",
  CK: "Cooköarna",
  CR: "Costa Rica",
  CY: "Cypern",
  DK: "Danmark",
  CD: "Demokratiska republiken Kongo",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominikanska republiken",
  EC: "Ecuador",
  EG: "Egypten",
  GQ: "Ekvatorialguinea",
  SV: "El Salvador",
  CI: "Elfenbenskusten",
  ER: "Eritrea",
  EE: "Estland",
  ET: "Etiopien",
  FK: "Falklandsöarna",
  FJ: "Fiji",
  PH: "Filippinerna",
  FI: "Finland",
  FX: "France métropolitaine (Frankrike europeiska delen)",
  FR: "Frankrike",
  GF: "Franska Guyana",
  PF: "Franska Polynesien",
  TF: "Franska södra territorierna",
  FO: "Färöarna",
  AE: "Förenade Arabemiraten",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgien",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grekland",
  GD: "Grenada",
  GL: "Grönland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard- och McDonaldsöarna",
  HN: "Honduras",
  HK: "Hongkong",
  IN: "Indien",
  ID: "Indonesien",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irland",
  IS: "Island",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italien",
  JM: "Jamaica",
  JP: "Japan",
  YE: "Jemen",
  JE: "Jersey",
  JO: "Jordanien",
  CX: "Julön",
  KH: "Kambodja",
  CM: "Kamerun",
  CA: "Kanada",
  CV: "Kap Verde",
  KZ: "Kazakstan",
  KE: "Kenya",
  CN: "Kina",
  KG: "Kirgizistan",
  KI: "Kiribati",
  "0CC": "Kokosöarna",
  KM: "Komorerna",
  CG: "Kongo-Brazzaville",
  HR: "Kroatien",
  CU: "Kuba",
  KW: "Kuwait",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettland",
  LB: "Libanon",
  LR: "Liberia",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MO: "Macau",
  MG: "Madagaskar",
  MK: "Makedonien",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldiverna",
  ML: "Mali",
  MT: "Malta",
  MA: "Marocko",
  MH: "Marshallöarna",
  MQ: "Martinique",
  MR: "Mauretanien",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexiko",
  FM: "Mikronesiska federationen",
  MZ: "Moçambique",
  MD: "Moldavien",
  MC: "Monaco",
  MN: "Mongoliet",
  ME: "Montenegro",
  MS: "Montserrat",
  NA: "Namibia",
  NR: "Nauru",
  NL: "Nederländerna",
  AN: "Nederländska Antillerna",
  NP: "Nepal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  KP: "Nordkorea",
  MP: "Nordmarianerna",
  NF: "Norfolkön",
  NO: "Norge",
  NC: "Nya Kaledonien",
  NZ: "Nya Zeeland",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PA: "Panama",
  PG: "Papua Nya Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PN: "Pitcairnöarna",
  // "PO": "Polen",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Rumänien",
  RW: "Rwanda",
  RU: "Ryssland",
  KN: "Saint Kitts och Nevis",
  LC: "Saint Lucia",
  VC: "Saint Vincent och Grenadinerna",
  BL: "Saint-Barthélemy",
  PM: "Saint-Pierre och Miquelon",
  SB: "Salomonöarna",
  WS: "Samoa",
  SM: "San Marino",
  SH: "Sankta Helena",
  ST: "São Tomé och Príncipe",
  SA: "Saudiarabien",
  CH: "Schweiz",
  SN: "Senegal",
  RS: "Serbien",
  SC: "Seychellerna",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakien",
  SI: "Slovenien",
  SO: "Somalia",
  ES: "Spanien",
  LK: "Sri Lanka",
  GB: "Storbritannien",
  SD: "Sudan",
  SR: "Surinam",
  SJ: "Svalbard och Jan Mayen",
  SE: "Sverige",
  SZ: "Swaziland",
  ZA: "Sydafrika",
  KR: "Sydkorea",
  SY: "Syrien",
  TJ: "Tadzjikistan",
  TW: "Taiwan",
  TZ: "Tanzania",
  TD: "Tchad",
  TH: "Thailand",
  CZ: "Tjeckien",
  TG: "Togo",
  TK: "Tokelauöarna",
  TO: "Tonga",
  TT: "Trinidad och Tobago",
  TN: "Tunisien",
  TR: "Turkiet",
  TM: "Turkmenistan",
  TC: "Turks- och Caicosöarna",
  TV: "Tuvalu",
  DE: "Tyskland",
  UG: "Uganda",
  UA: "Ukraina",
  HU: "Ungern",
  UY: "Uruguay",
  US: "USA",
  UM: "USA:s yttre öar",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VA: "Vatikanstaten",
  VE: "Venezuela",
  VN: "Vietnam",
  BY: "Vitryssland",
  EH: "Västsahara",
  WF: "Wallis- och Futunaöarna",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  // "AX": "Åland",
  AT: "Österrike",
  TL: "Östtimor",
};

export const getCountries = () => {
  const countries = [];
  Object.keys(ALL_COUNTRY_NAME_CODE).forEach((el) => {
    const obj = {};
    obj.key = el;
    obj.name = ALL_COUNTRY_NAME_CODE[el];
    countries.push(obj);
  });
  return countries;
};

export default {
  getCountries,
};

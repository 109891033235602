<template>
  <div class="wrapper">
    <div class="comment br animate " v-for="i in num" :key="i"></div>
  </div>
</template>

<script>
export default {
  name: "ShimmerLoading",
  props: {
    num: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style scoped>
.wrapper {
  padding: 10px;
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.comment {
  height: 20px;
  background: #777;
  margin-top: 20px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}


.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
<template>
  <div class="full-screen-loading">
    <icon_loading></icon_loading>
  </div>
</template>

<script>
import icon_loading from "./icon_loading";
export default {
  components: {
    icon_loading,
  },
};
</script>

<style scoped>
.full-screen-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.426);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

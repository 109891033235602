<template>
    <td> 
        <div class="checkbox">
            <input type="checkbox">
            <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
            <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
            </svg>
        </div>
    </td>
    <td> 
        <div class="d-flex align-items-center">
            <span class="user-img me-2">
                <img style="border-radius:9999px" class="user-img" :src="props.data.image">
            </span>
            {{ props.data.full_name }}
        </div>
    </td>
    <td>{{ props.data.email }}</td>
    <td><span v-if="props.data.role">{{ $t(`${props.data.role}`) }}</span></td>
    <td><span v-if="props.data.access">{{ $t(`${props.data.access}`) }}</span></td>
    <td>
        <div class="edit d-inline-block" @click="updateUserInfo"> 
            <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.90068 15.3672C3.94756 15.3672 3.99443 15.3625 4.04131 15.3555L7.9835 14.6641C8.03037 14.6547 8.0749 14.6336 8.10772 14.5984L18.0429 4.66328C18.0646 4.6416 18.0818 4.61584 18.0936 4.58749C18.1054 4.55914 18.1114 4.52874 18.1114 4.49805C18.1114 4.46735 18.1054 4.43696 18.0936 4.4086C18.0818 4.38025 18.0646 4.3545 18.0429 4.33281L14.1476 0.435156C14.103 0.390625 14.0444 0.367188 13.9812 0.367188C13.9179 0.367188 13.8593 0.390625 13.8147 0.435156L3.87959 10.3703C3.84443 10.4055 3.82334 10.4477 3.81396 10.4945L3.12256 14.4367C3.09976 14.5623 3.10791 14.6915 3.14629 14.8132C3.18468 14.9349 3.25216 15.0454 3.34287 15.1352C3.49756 15.2852 3.69209 15.3672 3.90068 15.3672ZM5.48037 11.2797L13.9812 2.78125L15.6991 4.49922L7.19834 12.9977L5.11475 13.3656L5.48037 11.2797ZM18.4858 17.3359H1.23584C0.820996 17.3359 0.48584 17.6711 0.48584 18.0859V18.9297C0.48584 19.0328 0.570215 19.1172 0.67334 19.1172H19.0483C19.1515 19.1172 19.2358 19.0328 19.2358 18.9297V18.0859C19.2358 17.6711 18.9007 17.3359 18.4858 17.3359Z" fill="#5855D6"></path>
            </svg>
        </div>
        <div class="delete d-inline-block" @click="deletedUser"> 
            <svg width="18" height="19" viewbox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.84766 8.24219V14.2422C6.84766 14.4411 6.92667 14.6319 7.06733 14.7725C7.20798 14.9132 7.39874 14.9922 7.59766 14.9922C7.79657 14.9922 7.98733 14.9132 8.12799 14.7725C8.26864 14.6319 8.34766 14.4411 8.34766 14.2422V8.24219C8.34766 8.04328 8.26864 7.85251 8.12799 7.71186C7.98733 7.57121 7.79657 7.49219 7.59766 7.49219C7.39874 7.49219 7.20798 7.57121 7.06733 7.71186C6.92667 7.85251 6.84766 8.04328 6.84766 8.24219Z" fill="#FB4B4B"></path>
            <path d="M10.5977 7.49219C10.7966 7.49219 10.9873 7.57121 11.128 7.71186C11.2686 7.85251 11.3477 8.04328 11.3477 8.24219V14.2422C11.3477 14.4411 11.2686 14.6319 11.128 14.7725C10.9873 14.9132 10.7966 14.9922 10.5977 14.9922C10.3987 14.9922 10.208 14.9132 10.0673 14.7725C9.92667 14.6319 9.84766 14.4411 9.84766 14.2422V8.24219C9.84766 8.04328 9.92667 7.85251 10.0673 7.71186C10.208 7.57121 10.3987 7.49219 10.5977 7.49219Z" fill="#FB4B4B"></path>
            <path d="M12.0977 3.74219H16.5977C16.7966 3.74219 16.9873 3.82121 17.128 3.96186C17.2686 4.10251 17.3477 4.29328 17.3477 4.49219C17.3477 4.6911 17.2686 4.88187 17.128 5.02252C16.9873 5.16317 16.7966 5.24219 16.5977 5.24219H15.7682L14.6402 15.4062C14.5383 16.3235 14.1017 17.171 13.4139 17.7865C12.7262 18.402 11.8356 18.7423 10.9127 18.7422H7.28266C6.35971 18.7423 5.46913 18.402 4.78139 17.7865C4.09365 17.171 3.65705 16.3235 3.55516 15.4062L2.42566 5.24219H1.59766C1.39874 5.24219 1.20798 5.16317 1.06733 5.02252C0.926674 4.88187 0.847656 4.6911 0.847656 4.49219C0.847656 4.29328 0.926674 4.10251 1.06733 3.96186C1.20798 3.82121 1.39874 3.74219 1.59766 3.74219H6.09766C6.09766 2.94654 6.41373 2.18348 6.97634 1.62087C7.53894 1.05826 8.30201 0.742188 9.09766 0.742188C9.89331 0.742188 10.6564 1.05826 11.219 1.62087C11.7816 2.18348 12.0977 2.94654 12.0977 3.74219ZM9.09766 2.24219C8.69983 2.24219 8.3183 2.40022 8.037 2.68153C7.75569 2.96283 7.59766 3.34436 7.59766 3.74219H10.5977C10.5977 3.34436 10.4396 2.96283 10.1583 2.68153C9.87701 2.40022 9.49548 2.24219 9.09766 2.24219ZM3.93616 5.24219L5.04616 15.2412C5.10743 15.7915 5.36945 16.2998 5.78208 16.669C6.19471 17.0382 6.72898 17.2423 7.28266 17.2422H10.9127C11.4661 17.2419 12 17.0377 12.4123 16.6685C12.8246 16.2994 13.0864 15.7912 13.1477 15.2412L14.2607 5.24219H3.93766H3.93616Z" fill="#FB4B4B"></path>
            </svg>
        </div>
    </td>
</template>

<script>
export default {
    props: {
        data: Object
    },
    setup (props, { emit }) {
        const updateUserInfo = () => emit('updateUserInfo', props) 
        const deletedUser = () => emit('deletedUser', props)
        return {
            props,
            updateUserInfo,
            deletedUser         
        }
    },
  data: function () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.user-img{
    height: 3.8rem;
    width: 3.8rem;
}
content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}
.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
  margin-bottom: 20px;
}
.content .headers h1 ~ p {
  font-size: 18px;
  line-height: 28px;
  color: #333269;
}
.content .headers h1 ~ p a {
  text-decoration: none;
  color: #5855d6;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}
.content .headers h1 ~ p a:hover {
  color: #4946ae;
}
.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
          box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}
.content .box .box-header {
  background: #5855d6;
  padding: 15px 30px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("./../../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: center right;
}
.content .box .box-header h3 {
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  margin: 0;
}
.content .box .box-header a {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  color: #5855d6;
  background: #fffdfd;
  padding: 12px 16px;
  border: 1px solid #fffdfd;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.content .box .box-header a:hover {
  color: #4946ae;
  background-color: #fbfafb;
  border-color: #fbfafb;
}
.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}
.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}
.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}
.content .box table .checkbox input:checked ~ svg {
  display: block;
}
.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.content .box table thead th:first-of-type {
  padding-left: 7px;
}
.content .box table thead th:last-of-type {
  padding-right: 7px;
}
.content .box table tbody tr:nth-child(odd) {
  background: #f6f8fc;
}
.content .box table tbody td {
  font-size: 16px;
  line-height: 24px;
  color: #1d1c40;
  padding: 15px 0;
}
.content .box table tbody td .edit {
  margin-right: 10px;
}
.content .box table tbody td .edit,
.content .box table tbody td .delete {
  cursor: pointer;
}
.content .box table tbody td:first-of-type {
  padding-left: 7px;
}
.content .box table tbody td:last-of-type {
  padding-right: 7px;
}
</style>
<template>
  <div>
    <!-- <shimmer-loading v-if="false" :num="10"></shimmer-loading> -->
    <div class="row">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("contracts_created_12_months") }}
                </h2>
                <div style="position: relative">
                  <span
                    @click="showLast12MonthInfo = !showLast12MonthInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showLast12MonthInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.last12Month.eng
                          : infoMessages.last12Month.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>
              <apexchart
                v-else
                type="line"
                :options="last12MonthGraph.options"
                :series="last12MonthGraph.series"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("average_recurring_billing") }}
                </h2>
                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="vat_included"
                      type="radio"
                      :value="true"
                      name="vat"
                      id="vat-yes"
                    />
                    <label for="vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="vat_included"
                      type="radio"
                      :value="false"
                      name="vat"
                      id="vat-no"
                    />
                    <label for="vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showRecurringBillingInfo = !showRecurringBillingInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>
                  <div
                    v-if="showRecurringBillingInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0px;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.recurringRevenue.eng
                          : infoMessages.recurringRevenue.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else-if="vat_included"
                type="bar"
                :options="recurringRevenueGraph.options"
                :series="recurringRevenueGraph.series"
              ></apexchart>
              <apexchart
                v-else
                type="bar"
                :options="recurringRevenueGraph.options"
                :series="recurringRevenueGraph.series_excl_vat"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("contracts_per_length") }}
                </h2>

                <div style="position: relative">
                  <span
                    @click="
                      showNumberOfContractInfo = !showNumberOfContractInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>
                  <div
                    v-if="showNumberOfContractInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0px;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.numberOfContract.eng
                          : infoMessages.numberOfContract.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else
                type="bar"
                :options="numberOfContractGraph.options"
                :series="numberOfContractGraph.series"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("most_common_invoice_intervals") }}
                </h2>

                <div style="position: relative">
                  <span
                    @click="showCommonIntervalsInfo = !showCommonIntervalsInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showCommonIntervalsInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.commonInvoiceIntervals.eng
                          : infoMessages.commonInvoiceIntervals.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else
                type="pie"
                :options="commonInvoiceIntervalsGraph.options"
                :series="commonInvoiceIntervalsGraph.series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShimmerLoading from "../reusableComponents/ShimmerLoading";

export default {
  name: "ContractStatistics",
  components: {
    ShimmerLoading,
  },
  // delete later
  async created() {
    this.getLast12MonthContractStatistics();
    this.getRecurringRevenueStatistics();
    this.getNumberOfContractStatistics();
    this.getCommonInvoiceIntervalsStatistics();
  },

  mounted() {
    this.addFontAwesomeToHead();
  },

  computed: {
    ...mapGetters({
      locale: "locale/GET_LOCALE",
    }),
  },

  data() {
    return {
      showLast12MonthInfo: false,

      showRecurringBillingInfo: false,

      showNumberOfContractInfo: false,

      showCommonIntervalsInfo: false,

      loading: false,

      vat_included: true,

      last12MonthData: {},

      recurringRevenueData: {},

      numberOfContractData: {},

      infoMessages: {
        last12Month: {},
        recurringRevenue: {},
        numberOfContract: {},
        commonInvoiceIntervals: {},
      },

      last12MonthGraph: {
        options: {
          chart: {
            id: "last12-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("month"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("number_of_contracts"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          legend: {
            show: true,
          },

          markers: {
            size: 5,
          },
          stroke: {
            curve: "straight",
            width: 1.5,
          },
        },
        series: [
          {
            name: this.$t("created"),
            type: "line",
            data: [],
          },
        ],
      },

      recurringRevenueGraph: {
        options: {
          chart: {
            id: "recurring-revenue",
          },

          xaxis: {
            categories: [],
            title: {
              text: this.$t("period"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("amount_billed"),
            },

            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "55%",
            },
          },
          colors: ["#8236FF"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
          },

          dataLabels: {
            enabled: false,
          },
        },

        series: [
          {
            name: this.$t("billed"),
            type: "bar",
            data: [],
          },
        ],
        series_excl_vat: [
          {
            name: this.$t("billed"),
            type: "bar",
            data: [],
          },
        ],
      },

      numberOfContractGraph: {
        options: {
          chart: {
            id: "number_of_contract_per_length",
          },

          xaxis: {
            categories: [],
            title: {
              text: this.$t("period"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("number_of_contracts"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "55%",
            },
          },

          dataLabels: {
            enabled: false,
          },
        },

        series: [
          {
            name: this.$t("number_of_contracts"),
            type: "bar",
            data: [],
          },
        ],
      },
      commonInvoiceIntervalsGraph: {
        options: {
          chart: {
            height: "340",
            id: "common_invoice_intervals",
            type: "pie",
          },

          colors: ["#216D64", "#FDEA21", "#4239FF", "2998FF"],

          plotOptions: {
            pie: {
              customScale: 0.8,
            },
          },

          legend: {
            show: true,
            position: "right",
          },

          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
            },
          },

          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2) + "%";
            },
            style: {
              colors: ["#fff"],
              fontSize: "16px",
            },
          },
        },

        series: [],
        labels: [],
      },
    };
  },

  methods: {
    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },

    async getLast12MonthContractStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getLast12MonthContractStatistics"
      );
      this.loading = false;
      this.infoMessages.last12Month = {
        eng: res.data.eng_info,
        swe: res.data.swe_info,
      };
      this.last12MonthData = res.data.data;
      const data = this.last12MonthData;
      const categories = [];
      const series = [];

      for (const key in data) {
        categories.push(key);
        series.push(data[key]);
      }

      for (let i = 0; i < categories.length; i++) {
        categories[i] = categories[i].substring(0, 3);
      }

      this.last12MonthGraph.options = {
        ...this.last12MonthGraph.options,
        xaxis: {
          ...this.last12MonthGraph.options.xaxis,
          type: "category",
          categories,
        },
      };
      this.last12MonthGraph.series[0].data = series;
    },

    async getRecurringRevenueStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getAverageRecurringContractStatistics"
      );
      this.loading = false;
      this.infoMessages.recurringRevenue = {
        eng: res.data.eng_info,
        swe: res.data.swe_info,
      };
      const categories = [];
      const series = [];
      const series_excl_vat = [];
      this.recurringRevenueData = res.data.data;
      for (const key in this.recurringRevenueData.ex_vat) {
        categories.push(key);
        series_excl_vat.push(this.recurringRevenueData.ex_vat[key]);
      }
      for (const key in this.recurringRevenueData.inc_vat) {
        series.push(this.recurringRevenueData.inc_vat[key]);
      }
      this.recurringRevenueGraph.options = {
        ...this.recurringRevenueGraph.options,
        xaxis: {
          ...this.recurringRevenueGraph.options.xaxis,
          type: "category",
          categories,
        },
      };
      this.recurringRevenueGraph.series[0].data = series;
      this.recurringRevenueGraph.series_excl_vat[0].data = series_excl_vat;
    },

    async getNumberOfContractStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getNumberOfContractStatistics"
      );
      this.loading = false;
      this.infoMessages.numberOfContract = {
        eng: res.data.eng_info,
        swe: res.data.swe_info,
      };
      const categories = [];
      const series = [];
      this.numberOfContractData = res.data.data;
      for (const key in this.numberOfContractData) {
        let suf =
          key.toUpperCase() === "Löpande".toUpperCase() ? "" : " Månader";
        categories.push(key + suf);
        series.push(this.numberOfContractData[key].amount_of_contracts);
      }
      this.numberOfContractGraph.options = {
        ...this.numberOfContractGraph.options,
        xaxis: {
          type: "category",
          categories,
        },
      };
      this.numberOfContractGraph.series[0].data = series;
    },

    async getCommonInvoiceIntervalsStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getCommonInvoiceIntervalsStatistics"
      );
      this.loading = false;
      this.infoMessages.commonInvoiceIntervals = {
        eng: res.data.eng_info,
        swe: res.data.swe_info,
      };
      const labels = [];
      const series = [];
      this.commonInvoiceIntervalsData = res.data.data;
      for (const key in this.commonInvoiceIntervalsData) {
        labels.push(key + " månad(er)");
        series.push(this.commonInvoiceIntervalsData[key].percentage);
      }
      this.commonInvoiceIntervalsGraph.options = {
        ...this.commonInvoiceIntervalsGraph.options,
        series,
        labels,
      };
    },
  },
};
</script>

<style scoped>
.desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;
  /* line-height: 14px; */
  color: #000000;
  width: min-content;
}

.desc label {
  margin-bottom: 2px;
  font-size: 11px;
}

.desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.desc .radio input {
  display: none;
}

.desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.desc .radio label:first-of-type {
  margin-right: 10px;
}

.box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
}

.box h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e1566;
  margin-bottom: 0;
}

.box h6 {
  font-weight: 400;
  font-size: 14px;
  color: #0e1566;
}
</style>

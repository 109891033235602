<template>
  <div>
    <!-- <shimmer-loading v-if="false" :num="10"></shimmer-loading> -->
    <div class="row">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("sales_prognosis_coming_12_months") }}
                </h2>
                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="vat_included"
                      type="radio"
                      :value="true"
                      name="vat"
                      id="vat-yes"
                    />
                    <label for="vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="vat_included"
                      type="radio"
                      :value="false"
                      name="vat"
                      id="vat-no"
                    />
                    <label for="vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>

                <div style="position: relative">
                  <span
                    @click="showSalesPrognosisInfo = !showSalesPrognosisInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showSalesPrognosisInfo"
                    style="
                      z-index: 9999;
                      width: 200px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.salesPrognosis.eng
                          : infoMessages.salesPrognosis.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else-if="vat_included"
                type="line"
                :options="salesPrognosis12Months.options"
                :series="salesPrognosis12Months.vat_series"
              ></apexchart>
              <apexchart
                v-else
                type="line"
                :options="salesPrognosis12Months.options"
                :series="salesPrognosis12Months.ex_vat_series"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2
                  v-if="salesFor == 'article'"
                  style="margin: 10px 10px 5px 10px"
                >
                  {{ $t("sales_prognosis_for_article") }} "{{
                    chosenArticle.description || "..."
                  }}"
                </h2>
                <h2 v-else style="margin: 10px 10px 5px 10px">
                  {{ $t("sales_prognosis_for_segment") }} "{{
                    chosenSegment.name || "..."
                  }}"
                </h2>
                <div
                  class="d-flex flex-row align-items-end justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showSalesCategorySelector = !showSalesCategorySelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showSalesCategorySelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 article-segment-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseSalesCategory('article')"
                        >
                          {{ $t("article") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseSalesCategory('segment')"
                        >
                          {{ $t("segment") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-if="salesFor == 'article'" style="position: relative">
                    <label style="font-size: 11px" for="search-article">{{
                      $t("search") + " " + $t("article")
                    }}</label>
                    <input
                      @input="searchArticle"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="articleKey"
                    />
                    <div
                      v-if="showArticleSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in articles.slice(0, 5)"
                          :key="item.id"
                          @click="chooseArticle(item)"
                        >
                          {{ item.article_number + " - " + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-else style="position: relative">
                    <label style="font-size: 11px" for="search-segment">{{
                      $t("search") + " " + $t("article") + " " + $t("segment")
                    }}</label>
                    <input
                      @input="searchSegment"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="segmentKey"
                    />
                    <div
                      v-if="showSegmentSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in segments.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSegment(item)"
                        >
                          {{ item.name + " - " + item.id }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="sales_vat_included"
                      type="radio"
                      :value="true"
                      name="sales_vat"
                      id="sales_vat-yes"
                    />
                    <label for="sales_vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="sales_vat_included"
                      type="radio"
                      :value="false"
                      name="sales_vat"
                      id="sales_vat-no"
                    />
                    <label for="sales_vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>

                <div style="position: relative">
                  <span
                    @click="
                      showArticlePrognosisInfo = !showArticlePrognosisInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showArticlePrognosisInfo"
                    style="
                      z-index: 9999;
                      width: 200px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.articlePrognosis.eng
                          : infoMessages.articlePrognosis.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading
                v-if="loading || salesLoading"
                :num="6"
              ></shimmer-loading>
              <div v-else>
                <div v-if="salesFor == 'article'">
                  <apexchart
                    v-if="sales_vat_included"
                    type="line"
                    :options="salesPrognosisForArticleGraph.options"
                    :series="salesPrognosisForArticleGraph.article_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesPrognosisForArticleGraph.options"
                    :series="
                      salesPrognosisForArticleGraph.article_ex_vat_series
                    "
                  ></apexchart>
                </div>
                <div v-else>
                  <apexchart
                    v-if="sales_vat_included"
                    type="line"
                    :options="salesPrognosisForArticleGraph.options"
                    :series="salesPrognosisForArticleGraph.segment_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesPrognosisForArticleGraph.options"
                    :series="
                      salesPrognosisForArticleGraph.segment_ex_vat_series
                    "
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2
                  v-if="salesCustomerFor == 'customer'"
                  style="margin: 10px 10px 5px 10px"
                >
                  {{ $t("sales_prognosis_for_customer") }} "{{
                    chosenCustomer.name || "..."
                  }}"
                </h2>
                <h2 v-else style="margin: 10px 10px 5px 10px">
                  {{ $t("sales_prognosis_for_segment") }} "{{
                    chosenCustomerSegment.name || "..."
                  }}"
                </h2>
                <div
                  class="d-flex flex-row align-items-end justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showCustomerCategorySelector =
                          !showCustomerCategorySelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showCustomerCategorySelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseCustomerSalesCategory('customer')"
                        >
                          {{ $t("customer") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseCustomerSalesCategory('segment')"
                        >
                          {{ $t("segment") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    v-if="salesCustomerFor == 'customer'"
                    style="position: relative"
                  >
                    <label style="font-size: 11px" for="search-customer">{{
                      $t("search") + " " + $t("customer")
                    }}</label>
                    <input
                      @input="searchCustomer"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="customerKey"
                    />
                    <div
                      v-if="showCustomerSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in customers.slice(0, 5)"
                          :key="item.id"
                          @click="chooseCustomer(item)"
                        >
                          {{ item.customer_number + " - " + item.name }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-else style="position: relative">
                    <label style="font-size: 11px" for="search-segment">{{
                      $t("search") + " " + $t("customer") + " " + $t("segment")
                    }}</label>
                    <input
                      @input="searchCustomerSegment"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="customerSegmentKey"
                    />
                    <div
                      v-if="showCustomerSegmentSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in customerSegments.slice(0, 5)"
                          :key="item.id"
                          @click="chooseCustomerSegment(item)"
                        >
                          {{ item.name + " - " + item.id }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="customer_sales_vat_included"
                      type="radio"
                      :value="true"
                      name="c_sales_vat"
                      id="c_sales_vat-yes"
                    />
                    <label for="c_sales_vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="customer_sales_vat_included"
                      type="radio"
                      :value="false"
                      name="c_sales_vat"
                      id="c_sales_vat-no"
                    />
                    <label for="c_sales_vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showCustomerPrognosisInfo = !showCustomerPrognosisInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showCustomerPrognosisInfo"
                    style="
                      z-index: 9999;
                      width: 200px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.customerPrognosis.eng
                          : infoMessages.customerPrognosis.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading
                v-if="loading || customerSalesLoading"
                :num="6"
              ></shimmer-loading>
              <div v-else>
                <div v-if="salesCustomerFor == 'customer'">
                  <apexchart
                    v-if="customer_sales_vat_included"
                    type="line"
                    :options="salesPrognosisForCustomerGraph.options"
                    :series="salesPrognosisForCustomerGraph.customer_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesPrognosisForCustomerGraph.options"
                    :series="
                      salesPrognosisForCustomerGraph.customer_ex_vat_series
                    "
                  ></apexchart>
                </div>
                <div v-else>
                  <apexchart
                    v-if="customer_sales_vat_included"
                    type="line"
                    :options="salesPrognosisForCustomerGraph.options"
                    :series="salesPrognosisForCustomerGraph.segment_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesPrognosisForCustomerGraph.options"
                    :series="
                      salesPrognosisForCustomerGraph.segment_ex_vat_series
                    "
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShimmerLoading from "../reusableComponents/ShimmerLoading";
import debounce from "lodash/debounce";

export default {
  name: "ContractStatistics",
  components: {
    ShimmerLoading,
  },
  async created() {
    // TODO: fetch stat data for each chart
    this.getSalesPrognosisStatistics();
  },

  computed: {
    ...mapGetters({
      locale: "locale/GET_LOCALE",
    }),
  },

  data() {
    return {
      showSalesPrognosisInfo: false,

      showArticlePrognosisInfo: false,

      showCustomerPrognosisInfo: false,

      infoMessages: {
        salesPrognosis: {},
        articlePrognosis: {},
        customerPrognosis: {},
      },

      salesCustomerFor: "customer",
      customerKey: "",
      customerSegmentKey: "",
      showCustomerSearch: false,
      showCustomerSegmentSearch: false,
      customerSalesLoading: false,
      customer_sales_vat_included: true,
      showCustomerCategorySelector: false,
      chosenCustomer: {},
      chosenCustomerSegment: {},

      salesFor: "article",

      loading: false,

      salesLoading: false,

      vat_included: true,

      sales_vat_included: true,

      articles: [],

      segments: [],

      customers: [],

      customerSegments: [],

      showArticleSearch: false,

      showSegmentSearch: false,

      showSalesCategorySelector: false,

      articleKey: "",

      segmentKey: "",

      chosenArticle: {},

      chosenSegment: {},

      salesCategories: {
        article_vat_categories: [],
        article_ex_vat_categories: [],
        segment_vat_categories: [],
        segment_ex_vat_categories: [],
      },

      customerSalesCategories: {
        customer_vat_categories: [],
        customer_ex_vat_categories: [],
        segment_vat_categories: [],
        segment_ex_vat_categories: [],
      },

      salesPrognosis12Months: {
        options: {
          chart: {
            id: "sales-prognosis-12-months",
          },

          xaxis: {
            categories: [],
            title: {
              text: this.$t("month"),
            },
          },

          stroke: {
            width: 1.5,
          },

          markers: {
            size: 5,
          },

          yaxis: {
            title: {
              text: this.$t("sales"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {},
          colors: ["#8236FF"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
          },

          dataLabels: {
            enabled: false,
          },
        },

        vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],
        ex_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],
      },

      salesPrognosisForCustomerGraph: {
        options: {
          chart: {
            id: "sales-prognosis-for-customer-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("month"),
            },
          },
          yaxis: {
            title: {
              text: this.$t("sales"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          stroke: {
            width: 1.5,
          },

          markers: {
            size: 5,
          },
        },
        customer_vat_series: [
          {
            name: this.$t("sales"),
            data: [],
          },
        ],
        customer_ex_vat_series: [
          {
            name: this.$t("sales"),
            data: [],
          },
        ],
        segment_vat_series: [
          {
            name: this.$t("sales"),
            data: [],
          },
        ],
        segment_ex_vat_series: [
          {
            name: this.$t("sales"),
            data: [],
          },
        ],
      },

      salesPrognosisForArticleGraph: {
        options: {
          chart: {
            id: "sales-prognosis-for-article-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("month"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("sales"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          stroke: {
            width: 1.5,
          },

          markers: {
            size: 5,
          },

          colors: ["#FE3E7F"],

          dataLabels: {
            enabled: false,
          },
        },
        article_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        article_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        segment_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        segment_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],
      },
    };
  },

  mounted() {
    this.addFontAwesomeToHead();
  },

  methods: {
    chooseCustomerSalesCategory(category) {
      this.salesCustomerFor = category;
      this.showCustomerCategorySelector = false;
    },

    chooseCustomer(customer) {
      if (Object.keys(customer).length == 0) {
        return;
      }
      this.showCustomerSearch = false;
      this.customerSalesLoading = true;
      this.customerKey = customer.name;
      const payload = {
        type: "customer",
        customer_number: customer.customer_number,
      };
      this.chosenCustomer = customer;
      this.$store
        .dispatch("clients/getSalesPrognosisForCustomerStatistics", payload)
        .then((res) => {
          this.getSalesPrognosisForCustomer("customer", res.data.data);
          this.customerSalesLoading = false;
          this.infoMessages.customerPrognosis = {
            eng: res.data.eng_info,
            swe: res.data.swe_info,
          };
        })
        .catch((err) => {
          this.customerSalesLoading = false;
        });
    },

    chooseCustomerSegment(segment) {
      if (Object.keys(segment).length == 0) {
        return;
      }
      this.showCustomerSegmentSearch = false;
      this.customerSalesLoading = true;
      this.customerSegmentKey = segment.name;
      const payload = {
        type: "segment",
        segment_id: segment.id,
      };
      this.chosenCustomerSegment = segment;
      this.$store
        .dispatch("clients/getSalesPrognosisForCustomerStatistics", payload)
        .then((res) => {
          this.getSalesPrognosisForCustomer("segment", res.data.data);
          this.customerSalesLoading = false;
          this.infoMessages.customerPrognosis = {
            eng: res.data.eng_info,
            swe: res.data.swe_info,
          };
        })
        .catch((err) => {
          this.customerSalesLoading = false;
        });
    },

    searchCustomer: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterCustomers(event.target.value);
      } else {
        this.showCustomerSearch = false;
      }
    }, 500),

    searchCustomerSegment: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterCustomerSegments(event.target.value);
      } else {
        this.showSegmentSearch = false;
      }
    }, 500),

    filterCustomers(q) {
      let payload = {
        URL: this.$services.endpoints.CUSTOMER_ENDPOINT + `?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showCustomerSearch = true;
          this.customers = res.data.data;
        })
        .catch((err) => {
          // left blank
        });
    },

    filterCustomerSegments(q) {
      let payload = {
        URL:
          this.$services.endpoints.SEGMENT_ENDPOINT + `customers?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.customerSegments = res.data.results;
          this.showCustomerSegmentSearch = true;
        })
        .catch((err) => {
          // left blank
        });
    },

    chooseSalesCategory(salesCategory) {
      this.showSalesCategorySelector = false;
      this.salesFor = salesCategory;
      if (salesCategory === "article") {
        this.chooseArticle(this.chosenArticle);
      } else {
        this.chooseSegment(this.chosenSegment);
      }
    },

    searchArticle: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticles(event.target.value);
      } else {
        this.showArticleSearch = false;
      }
    }, 500),

    searchSegment: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSegments(event.target.value);
      } else {
        this.showSegmentSearch = false;
      }
    }, 500),

    filterArticles(q) {
      let payload = {
        URL: this.$services.endpoints.ARTICLE_ENDPOINT + `?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showArticleSearch = true;
          this.articles = res.data.data;
        })
        .catch((err) => {
          // left blank
        });
    },

    filterSegments(q) {
      let payload = {
        URL: this.$services.endpoints.SEGMENT_ENDPOINT + `articles?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showSegmentSearch = true;
          this.segments = res.data.results;
        })
        .catch((err) => {
          // left blank
        });
    },

    async getSalesPrognosisStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getSalesPrognosisStatistics"
      );
      this.loading = false;

      this.infoMessages.salesPrognosis = {
        eng: res.data.eng_info,
        swe: res.data.swe_info,
      };

      const data = res.data.data;
      if (Object.keys(data).length === 1) {
        this.$toast.warning(data.Info);
        return;
      }
      const categories = [];
      const vat_series = [];
      const ex_vat_series = [];

      for (const key in data) {
        categories.push(key);
        vat_series.push(data[key].sales_inc_vat);
        ex_vat_series.push(data[key].sales_ex_vat);
      }

      for (let i = 0; i < categories.length; i++) {
        categories[i] = categories[i].substring(0, 3);
      }

      this.salesPrognosis12Months.options.xaxis = {
        categories,
        title: {
          text: this.$t("month"),
        },
      };

      this.salesPrognosis12Months.vat_series[0].data = vat_series;
      this.salesPrognosis12Months.ex_vat_series[0].data = ex_vat_series;
    },

    async getSalesPrognosisForCustomer(type, data) {
      const innnerData = data[Object.keys(data)[0]];
      if (Object.keys(innnerData).length === 1 && innnerData.Info) {
        this.$toast.warning(innnerData.Info);
        return;
      }

      const categories = [];
      const vat_series = [];
      const ex_vat_series = [];

      for (const key in innnerData) {
        categories.push(key);
        vat_series.push(innnerData[key].sales_inc_vat);
        ex_vat_series.push(innnerData[key].sales_ex_vat);
      }

      if (type == "customer") {
        this.salesPrognosisForCustomerGraph.customer_vat_series[0].data =
          vat_series;
        this.salesPrognosisForCustomerGraph.customer_ex_vat_series[0].data =
          ex_vat_series;
        this.customerSalesCategories.customer_vat_categories = categories;
        this.customerSalesCategories.customer_ex_vat_categories = categories;
      } else {
        this.salesPrognosisForCustomerGraph.segment_vat_series[0].data =
          vat_series;
        this.salesPrognosisForCustomerGraph.segment_ex_vat_series[0].data =
          ex_vat_series;
        this.customerSalesCategories.segment_vat_categories = categories;
        this.customerSalesCategories.segment_ex_vat_categories = categories;
      }
    },

    async getSalesStatistics(type, data) {
      this.salesLoading = true;
      const article_ex_vat_categories = [];
      const article_ex_vat_series = [];

      const article_vat_categories = [];
      const article_vat_series = [];

      const segment_ex_vat_categories = [];
      const segment_ex_vat_series = [];

      const segment_vat_categories = [];
      const segment_vat_series = [];

      if (type == "article") {
        if (typeof data !== "object" || data[Object.keys(data)[0]].Info) {
          this.$toast.warning("No prior sales for this article");
          return;
        }

        const articleData = {
          ...data[Object.keys(data)[0]],
        };
        for (const key in articleData) {
          const shortenedKey = key.substring(0, 3);
          article_vat_categories.push(shortenedKey);
          article_vat_series.push(articleData[key].sales_inc_vat);
          article_ex_vat_categories.push(shortenedKey);
          article_ex_vat_series.push(articleData[key].sales_ex_vat);
        }

        this.salesCategories.article_vat_categories = article_vat_categories;
        this.salesCategories.article_ex_vat_categories =
          article_ex_vat_categories;

        this.salesPrognosisForArticleGraph.options = {
          ...this.salesPrognosisForArticleGraph.options,
          xaxis: {
            ...this.salesPrognosisForArticleGraph.options.xaxis,
            type: "category",
            categories: article_vat_categories,
          },
        };

        this.salesPrognosisForArticleGraph.article_vat_series[0].data =
          article_vat_series;
        this.salesPrognosisForArticleGraph.article_ex_vat_series[0].data =
          article_ex_vat_series;
      } else {
        if (typeof data !== "object" || data[Object.keys(data)[0]].Info) {
          this.$toast.warning("No prior sales for this segment");

          return;
        }
        const innerData = data[Object.keys(data)[0]];

        if (
          Object.keys(innerData).length === 1 &&
          Object.keys(innerData)[0] === "Info"
        ) {
          this.$toast.warning(innerData.Info);

          return;
        }
        const segmentData = { ...data[Object.keys(data)[0]] };
        for (const key in segmentData) {
          const shortenedKey = key.substring(0, 3);
          segment_vat_categories.push(shortenedKey);
          segment_ex_vat_categories.push(shortenedKey);
          segment_vat_series.push(
            this.fixDecimals(parseFloat(segmentData[key].sales_inc_vat))
          );
          segment_ex_vat_series.push(
            this.fixDecimals(parseFloat(segmentData[key].sales_ex_vat))
          );
        }

        this.salesCategories.segment_vat_categories = segment_vat_categories;
        this.salesCategories.segment_ex_vat_categories =
          segment_ex_vat_categories;

        this.salesPrognosisForArticleGraph.options = {
          ...this.salesPrognosisForArticleGraph.options,
          xaxis: {
            ...this.salesPrognosisForArticleGraph.options.xaxis,
            type: "category",
            categories: segment_vat_categories,
          },
        };
        this.salesPrognosisForArticleGraph.segment_vat_series[0].data =
          segment_vat_series;
        this.salesPrognosisForArticleGraph.segment_ex_vat_series[0].data =
          segment_ex_vat_series;
      }
    },

    chooseArticleSalesCategory(category) {
      this.showArticleSalesCategorySelector = false;
      this.articleSalesCategory = category;
    },

    chooseArticle(article) {
      if (Object.keys(article).length == 0) {
        return;
      }

      this.showArticleSearch = false;
      this.salesLoading = true;
      this.articleKey = article.description;
      const payload = {
        type: "article",
        article_number: article.article_number,
      };
      this.chosenArticle = article;
      this.$store
        .dispatch("clients/getSalesPrognosisForArticleStatistics", payload)
        .then((res) => {
          this.getSalesStatistics("article", res.data.data);
          this.salesLoading = false;
          this.infoMessages.articlePrognosis = {
            eng: res.data.eng_info,
            swe: res.data.swe_info,
          };
        })
        .catch((err) => {
          this.salesLoading = false;
        });
    },

    chooseSegment(segment) {
      // check if segment object has no keys
      if (Object.keys(segment).length === 0) {
        return;
      }

      this.showSegmentSearch = false;
      this.salesLoading = true;
      this.segmentKey = segment.name;
      const payload = {
        type: "segment",
        segment_id: segment.id,
      };
      this.chosenSegment = segment;
      this.$store
        .dispatch("clients/getSalesPrognosisForArticleStatistics", payload)
        .then((res) => {
          this.getSalesStatistics("segment", res.data.data);
          this.infoMessages.articlePrognosis = {
            eng: res.data.eng_info,
            swe: res.data.swe_info,
          };
          this.salesLoading = false;
        })
        .catch((err) => {
          this.salesLoading = false;
        });
    },

    fixDecimals(value) {
      return value.toFixed(2);
    },

    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },
  },

  watch: {
    customer_sales_vat_included(val) {
      if (val) {
        this.salesPrognosisForCustomerGraph.options = {
          ...this.salesPrognosisForCustomerGraph.options,
          xaxis: {
            ...this.salesPrognosisForCustomerGraph.options.xaxis,
            type: "category",
            categories:
              this.salesCustomerFor == "customer"
                ? this.customerSalesCategories.customer_vat_categories
                : this.customerSalesCategories.segment_vat_categories,
          },
        };
      } else {
        this.salesPrognosisForCustomerGraph.options = {
          ...this.salesPrognosisForCustomerGraph.options,
          xaxis: {
            ...this.salesPrognosisForCustomerGraph.options.xaxis,
            type: "category",
            categories:
              this.salesCustomerFor == "customer"
                ? this.customerSalesCategories.customer_ex_vat_categories
                : this.customerSalesCategories.segment_ex_vat_categories,
          },
        };
      }
    },
  },
};
</script>

<style scoped>
.desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;
  /* line-height: 14px; */
  color: #000000;
  width: min-content;
}

.desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;
  /* line-height: 14px; */
  color: #000000;
  width: min-content;
}

.desc label {
  margin-bottom: 2px;
  font-size: 11px;
}

.desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.desc .radio input {
  display: none;
}

.desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.desc .radio label:first-of-type {
  margin-right: 10px;
}

.box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
}

.box h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e1566;
  margin-bottom: 0;
}

.box h6 {
  font-weight: 400;
  font-size: 14px;
  color: #0e1566;
}

input.filter-input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.article-segment-dropdown ul {
  margin-bottom: 0 !important;
}
</style>

<template>
  <div class="col responsive-width">
    <div class="content">
      <div class="container-fluid">
        <div class="headers d-flex flex-wrap justify-content-between">
          <div>
            <h1 class="mb-0">{{ $t("configuration") }}</h1>
            <h6 v-if="tab === 1">{{ $t("contract_configuration") }}</h6>
            <h6 v-if="tab === 2">{{ $t("invoice_configuration") }}</h6>
          </div>
          <div class="configuration-showcase">
            <ul
              class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center"
            >
              <li :class="tab === 1 ? 'active' : ''">
                <a @click="switchTab(1)" href="javascript:void(0);">{{
                  $t("contract_configuration")
                }}</a>
              </li>
              <li :class="tab === 2 ? 'active' : ''">
                <a @click="switchTab(2)" href="javascript:void(0);">{{
                  $t("invoice_configuration")
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="box p-4 mb-5" v-if="tab === 1">
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <h5 class="header">{{ $t("create_invoice") }}</h5>
            </div>
            <router-link :to="{ name: 'contractGuide' }" class="my-3 my-sm-0">
              {{ $t("go_to_information_quide") }}
              <svg
                width="7"
                height="14"
                viewbox="0 0 7 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.16154 -0.000275612C1.31093 -0.00078392 1.45854 0.0321884 1.59352 0.0962172C1.7285 0.160246 1.84742 0.253705 1.94154 0.369725L6.77154 6.36972C6.91862 6.54866 6.99902 6.7731 6.99902 7.00472C6.99902 7.23635 6.91862 7.46079 6.77154 7.63972L1.77154 13.6397C1.6018 13.8439 1.35789 13.9724 1.09346 13.9967C0.829033 14.0211 0.565752 13.9395 0.361536 13.7697C0.157319 13.6 0.0288949 13.3561 0.00451517 13.0916C-0.0198646 12.8272 0.0617967 12.5639 0.231535 12.3597L4.70154 6.99972L0.381536 1.63972C0.259253 1.49294 0.181576 1.3142 0.157696 1.12465C0.133817 0.935102 0.164733 0.742677 0.246789 0.570149C0.328846 0.397622 0.458605 0.252211 0.620716 0.15112C0.782826 0.0500298 0.970502 -0.00250721 1.16154 -0.000275612Z"
                  fill="#5855D6"
                ></path>
              </svg>
            </router-link>
          </div>
          <form @submit.prevent="processContractConfigurationForm">
            <div class="row mb-4">
              <div class="col-lg-6 d-flex">
                <p>
                  {{ $t("auto_create_invoice_by_system") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("auto_create_invoice")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("auto_create_invoice")
                    }}</span>
                  </span>
                </p>
                <div class="d-flex ms-5">
                  <div class="d-flex flex-row mr-3 align-items-center me-3">
                    <label
                      class="label-text m-0 mx-1 fs-7"
                      for="radio-switch-contract-one"
                      >{{ $t("yes") }}</label
                    >
                    <input
                      v-model="contractConfigForm.auto_create_invoice"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-switch-contract-one"
                      name="switch-contract-auto"
                    />
                  </div>
                  <div class="d-flex flex-row mr-3 align-items-center">
                    <label
                      class="label-text m-0 mx-1 fs-7"
                      for="radio-switch-contract-two"
                      >{{ $t("no") }}</label
                    >
                    <input
                      v-model="contractConfigForm.auto_create_invoice"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-switch-contract-two"
                      name="switch-contract-auto"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- <div class="d-flex">
                    <div class="d-flex flex-column align-items-center me-3">
                        <label class="label-text fs-7" for="radio-switch-contract-one">Yes</label>
                        <input v-model="contractConfigForm.auto_create_invoice" v-bind:value="true" class="checkbox" type="radio" id="radio-switch-contract-one" name="switch-contract-auto"/>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                        <label class="label-text fs-7" for="radio-switch-contract-two">No</label>
                        <input v-model="contractConfigForm.auto_create_invoice" v-bind:value="false" class="checkbox" type="radio" id="radio-switch-contract-two" name="switch-contract-auto" />
                    </div>
                </div> -->
              </div>
            </div>
            <div class="mt-3 mb-2">
              <h6 class="header">
                {{ $t("create_invoice_based_on") }}
                <span class="tooltip-custom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                    getFieldHelptext("create_invoice_based_on")
                  }}</span>
                  <span class="tooltiptext" v-else>{{
                    getFieldHelptext("create_invoice_based_on")
                  }}</span>
                </span>
              </h6>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <div class="d-flex mb-3">
                  <input
                    :disabled="!contractConfigForm.auto_create_invoice"
                    v-model="contractConfigForm.create_invoice_based_on"
                    value="Period start date"
                    class="checkbox unset-box"
                    type="radio"
                    id="radio-invoice-type-one"
                    name="switch-invoice-type"
                  />
                  <label class="label-text" for="radio-invoice-type-one">
                    {{ $t("contract_period_start") }}
                  </label>
                </div>
                <div class="d-flex mb-3">
                  <input
                    :disabled="!contractConfigForm.auto_create_invoice"
                    v-model="contractConfigForm.create_invoice_based_on"
                    value="Calendar date"
                    class="checkbox unset-box"
                    type="radio"
                    id="radio-invoice-type-two"
                    name="switch-invoice-type"
                  />
                  <label class="label-text" for="radio-invoice-type-two">
                    {{ $t("the_day_of_month") }}
                  </label>
                </div>
                <div class="d-flex mb-3">
                  <input
                    :disabled="!contractConfigForm.auto_create_invoice"
                    v-model="contractConfigForm.create_invoice_based_on"
                    value="Day in month"
                    class="checkbox unset-box"
                    type="radio"
                    id="radio-invoice-type-two"
                    name="switch-invoice-type"
                  />
                  <label class="label-text" for="radio-invoice-type-two">
                    {{ $t("workday_of_month") }}
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span class="me-2">{{ $t("create_invoice") }}</span>
                  <input
                    :disabled="
                      !contractConfigForm.auto_create_invoice ||
                      contractConfigForm.create_invoice_based_on !==
                        'Period start date'
                    "
                    type="number"
                    v-model.number="contractConfigForm.days_before_period_start"
                  />
                </div>
              </div>
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span class="ms-2">
                    {{ $t("days_before_period_start") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("days_before_period_start")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("days_before_period_start")
                      }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span class="me-2">{{ $t("create_invoices_on_the") }}</span>
                  <input
                    :disabled="
                      !contractConfigForm.auto_create_invoice ||
                      contractConfigForm.create_invoice_based_on !==
                        'Calendar date'
                    "
                    type="number"
                    v-model.number="contractConfigForm.day_of_every_month"
                  />
                </div>
              </div>
              <div class="desc">
                <span class="ms-2 me-2">
                  {{ $t("of_every_month_for_contract") }}
                </span>
              </div>
              <div class="desc">
                <div class="d-flex align-items-center">
                  <input
                    :disabled="
                      !contractConfigForm.auto_create_invoice ||
                      contractConfigForm.create_invoice_based_on !==
                        'Calendar date'
                    "
                    type="number"
                    v-model.number="contractConfigForm.days_from_period_start"
                  />
                  <span class="ms-2">
                    {{ $t("days_of_that_day") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("days_from_period_start")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("days_from_period_start")
                      }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>


            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span class="me-2">{{ $t("create_invoices_on_the") }}</span>
                    <select 
                      v-model="contractConfigForm.day_to_bill"
                      class="me-2"
                      :disabled="
                        !contractConfigForm.auto_create_invoice ||
                        contractConfigForm.create_invoice_based_on !==
                          'Day in month'
                      "
                    >
                      <option v-for="option in workdayOptions" :value="option.key" :key="option.key">
                        {{ option.name }}
                      </option>
                    </select>
                    <span class="ms-2">
                      {{ $t("of_every_month_for_contract_workday") }}
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("day_to_bill")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("day_to_bill")
                        }}</span>
                      </span>
                    </span>
                </div>
              </div>
            </div>
            <hr />

            <div class="mt-3 mb-2">
              <h5 class="header">
                {{
                  $services.helpers.capitalizeNames(
                    $t("auto_bookkeep_invoices")
                  )
                }}
              </h5>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("let_application_book_invoice") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("auto_book_invoices")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("auto_book_invoices")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="!contractConfigForm.auto_create_invoice"
                      v-model="
                        contractConfigForm.auto_bookkeep_created_invoices
                      "
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-book-contract-one"
                      name="switch-book-contract"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-book-contract-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="!contractConfigForm.auto_create_invoice"
                      v-model="
                        contractConfigForm.auto_bookkeep_created_invoices
                      "
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-book-contract-two"
                      name="switch-book-contract"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-book-contract-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3 mb-2">
              <h5 class="header">
                {{ $services.helpers.capitalizeNames($t("send_invoice")) }}
              </h5>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("let_application_send_invoice") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("auto_send_invoices")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("auto_send_invoices")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="!contractConfigForm.auto_create_invoice"
                      v-model="contractConfigForm.auto_send_invoices"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-send-contract-one"
                      name="switch-send-contract"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-contract-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="!contractConfigForm.auto_create_invoice"
                      v-model="contractConfigForm.auto_send_invoices"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-send-contract-two"
                      name="switch-send-contract"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-contract-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("send_invoice_with_fortnox_finance") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("send_invoice_with_fortnox_finance")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("send_invoice_with_fortnox_finance")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="
                        contractConfigForm.send_invoice_with_fortnox_finance
                      "
                      value="All"
                      class="checkbox"
                      type="radio"
                      id="radio-send-fortnox-category-one"
                      name="switch-fortnox-category"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-fortnox-category-one"
                      >{{ $t("all") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="
                        contractConfigForm.send_invoice_with_fortnox_finance
                      "
                      value="Some"
                      class="checkbox"
                      type="radio"
                      id="radio-send-fortnox-category-two"
                      name="switch-fortnox-category"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-fortnox-category-two"
                      >{{ $t("some") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="
                        contractConfigForm.send_invoice_with_fortnox_finance
                      "
                      value="None"
                      class="checkbox"
                      type="radio"
                      id="radio-send-fortnox-category-three"
                      name="switch-fortnox-category"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-fortnox-category-three"
                      >{{ $t("none") }}</label
                    >
                  </div>
                </div>
              </div>

              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("send_failed_nox_as_reg") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("send_failed_nox_as_reg")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("send_failed_nox_as_reg")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.send_invoice_with_fortnox_finance ===
                          'None' ||
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="contractConfigForm.send_failed_nox_as_reg"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-send-failed-nox-as-reg"
                      name="radio-send-failed-nox-as-reg"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-failed-nox-as-reg"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.send_invoice_with_fortnox_finance ===
                          'None' ||
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="contractConfigForm.send_failed_nox_as_reg"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-send-failed-nox-as-reg"
                      name="radio-send-failed-nox-as-reg"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-send-failed-nox-as-reg"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 mb-3">
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("use_customer_default_send_method") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("use_customer_default_send_method")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("use_customer_default_send_method")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="
                        contractConfigForm.use_customer_default_send_method
                      "
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-use-default-one"
                      name="switch-default-method"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-use-default-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      v-model="
                        contractConfigForm.use_customer_default_send_method
                      "
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-use-default-two"
                      name="switch-default-method"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-use-default-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("send_all_invoices_through") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("send_all_invoices_through")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("send_all_invoices_through")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.use_customer_default_send_method ||
                        !contractConfigForm.auto_send_invoices
                      "
                      v-model="contractConfigForm.send_all_invoices_through"
                      value="Email"
                      class="checkbox"
                      type="radio"
                      id="radio-invoice-channel-one"
                      name="switch-invoice-channel"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-invoice-channel-one"
                      >{{ $t("email") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.use_customer_default_send_method ||
                        !contractConfigForm.auto_send_invoices
                      "
                      v-model="contractConfigForm.send_all_invoices_through"
                      value="Letter"
                      class="checkbox"
                      type="radio"
                      id="radio-invoice-channel-two"
                      name="switch-invoice-channel"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-invoice-channel-two"
                      >{{ $t("letter") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("use_eprint_when_customer_missing_email") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("use_print_service_if_no_email")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("use_print_service_if_no_email")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.send_all_invoices_through !==
                          'Email' ||
                        contractConfigForm.send_invoice_with_fortnox_finance ===
                          'All' ||
                        !contractConfigForm.auto_send_invoices
                      "
                      v-model="contractConfigForm.use_print_service_if_no_email"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="use_print_service_if_no_email_id"
                      name="use_print_service_if_no_email_name"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="use_print_service_if_no_email"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.send_all_invoices_through !==
                          'Email' ||
                        contractConfigForm.send_invoice_with_fortnox_finance ===
                          'All' ||
                        !contractConfigForm.auto_send_invoices
                      "
                      v-model="contractConfigForm.use_print_service_if_no_email"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="use_print_service_if_no_email_id"
                      name="use_print_service_if_no_email_name"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="use_print_service_if_no_email"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("use_eprint_for_print") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("use_eprint_for_print")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("use_eprint_for_print")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.send_all_invoices_through !==
                          'Letter' ||
                        contractConfigForm.send_invoice_with_fortnox_finance ===
                          'All' ||
                        !contractConfigForm.auto_send_invoices
                      "
                      v-model="contractConfigForm.use_print_service_for_print"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="use_eprint_for_print_id"
                      name="use_eprint_for_print_name"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="use_print_service_for_print"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.send_all_invoices_through !==
                          'Letter' ||
                        contractConfigForm.send_invoice_with_fortnox_finance ===
                          'All' ||
                        !contractConfigForm.auto_send_invoices
                      "
                      v-model="contractConfigForm.use_print_service_for_print"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="use_eprint_for_print_id"
                      name="use_eprint_for_print_name"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="use_print_service_for_print"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex align-items-center flex-wrap col-flex-wrap mb-2"
              v-for="(condition, i) in contractConfigForm.config_rows"
              :key="i"
            >
              <div class="desc">
                <span class="me-2">{{ $t("send_with_condition") }}</span>
              </div>
              <div class="desc">
                <div class="d-flex flex-wrap">
                  <div class="position-relative">
                    <span
                      class="tooltip-custom"
                      style="margin-top: -0.9rem !important"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("comparison")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("comparison")
                      }}</span>
                    </span>
                  </div>
                  <select
                    v-model="condition.type"
                    class="me-2"
                    :disabled="
                      contractConfigForm.send_invoice_with_fortnox_finance !==
                        'Some' ||
                      !contractConfigForm.auto_send_invoices ||
                      !contractConfigForm.auto_create_invoice
                    "
                  >
                    <option value="terms_of_payment">
                      {{ $t("terms_of_payment") }}
                    </option>
                    <option value="contract_amount">
                      {{ $t("amount") }}
                    </option>
                    <option value="customer_type">
                      {{ $t("customer_type") }}
                    </option>
                  </select>
                  <!-- <select v-if="condition.type === 'payment'" class="me-2" :disabled="contractConfigForm.send_invoice_with_fortnox_finance !== 'Some' || !contractConfigForm.auto_send_invoices">
                      <option :value="item.code" v-for="item in paymentTerm" :key="item.id">{{ item.description }}</option>
                  </select> -->
                  <select
                    v-model="condition.comparison"
                    class="me-2"
                    :disabled="
                      contractConfigForm.send_invoice_with_fortnox_finance !==
                        'Some' ||
                      !contractConfigForm.auto_send_invoices ||
                      !contractConfigForm.auto_create_invoice
                    "
                  >
                    <option :value="values[0].key">{{ values[0].name }}</option>
                    <option
                      v-if="condition.type !== 'terms_of_payment' && condition.type !== 'customer_type'"
                      :value="values[1].key"
                    >
                      {{ values[1].name }}
                    </option>
                    <option
                      v-if="condition.type !== 'terms_of_payment' && condition.type !== 'customer_type'"
                      :value="values[2].key"
                    >
                      {{ values[2].name }}
                    </option>
                  </select>
                  <div>
                    <select
                      v-if="condition.type === 'terms_of_payment'"
                      v-model="condition.condition"
                      class="me-2"
                      :disabled="
                        contractConfigForm.send_invoice_with_fortnox_finance !==
                          'Some' ||
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                    >
                      <option
                        :value="item.code"
                        v-for="item in paymentTerm"
                        :key="item.id"
                      >
                        {{ item.description }}
                      </option>
                    </select>
                    <select
                      v-else-if="condition.type === 'customer_type'"
                      v-model="condition.condition"
                      class="me-2"
                      :disabled="
                        contractConfigForm.send_invoice_with_fortnox_finance !==
                          'Some' ||
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                    >
                      <option
                        :value="item.key"
                        v-for="item in customerTypes"
                        :key="item.key"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <input
                      v-else
                      v-model="condition.condition"
                      :disabled="
                        contractConfigForm.send_invoice_with_fortnox_finance !==
                          'Some' ||
                        !contractConfigForm.auto_send_invoices ||
                        !contractConfigForm.auto_create_invoice
                      "
                      class="me-2 mb-2"
                      type="number"
                      style="width: 132px !important"
                    />
                  </div>
                </div>
              </div>
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span class="ms-2">
                    {{ $t("with_fortnox_finance") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("config_rows")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("config_rows")
                      }}</span>
                    </span>
                  </span>
                  <span @click="removeConfigRow(i)" class="ms-4 cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="color: red"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <span
                @click="addConfigRow"
                class="border rounded shadow-md p-1 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="text-xs">{{ $t("add_config") }}</span>
              </span>
            </div>
            <hr />

            <div class="mt-3 mb-2">
              <h5 class="header">
                {{
                  $services.helpers.capitalizeNames(
                    $t("contract_price_updates")
                  )
                }}
              </h5>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("let_application_update_prices") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("auto_update_contract_prices")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("auto_update_contract_prices")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      v-model="contractConfigForm.auto_update_contract_prices"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-update-price-one"
                      name="switch-update-price"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-update-price-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      v-model="contractConfigForm.auto_update_contract_prices"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-update-price-two"
                      name="switch-update-price"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-update-price-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("prices_and_vat") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("article_prices_are_ex_vat")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("article_prices_are_ex_vat")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.auto_update_contract_prices !== true
                      "
                      v-model="contractConfigForm.article_prices_are_ex_vat"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-update-price-excl-vat"
                      name="switch-update-price-excl"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-update-price-excl-vat"
                      >{{ $t("excl_vat") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="
                        contractConfigForm.auto_update_contract_prices !== true
                      "
                      v-model="contractConfigForm.article_prices_are_ex_vat"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-update-price-incl-vat"
                      name="switch-update-price-incl"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-update-price-incl-vat"
                      >{{ $t("incl_vat") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <!-- ################################################# -->
            <div class="mt-3 mb-2">
              <h5 class="header">
                {{
                  $services.helpers.capitalizeNames(
                    $t("update_contracts_with_template_data")
                  )
                }}
              </h5>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("let_application_update_contract_data") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("template_auto_update_contract_data")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("template_auto_update_contract_data")
                      }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      v-model="contractConfigForm.auto_update_contract_with_template_data"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-update-data-one"
                      name="switch-update-data"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-update-data-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      v-model="contractConfigForm.auto_update_contract_with_template_data"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-update-data-two"
                      name="switch-update-data"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-update-data-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-2">
                <h6 class="header">
                  {{
                    $services.helpers.capitalizeNames(
                      $t("data_to_update")
                    )
                  }}
                </h6>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.contract_length"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("contract_length") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_contract_length")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_contract_length")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.terms_of_payment"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("terms_of_payment") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_terms_of_payment")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_terms_of_payment")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.terms_of_delivery"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("terms_of_delivery") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_terms_of_delivery")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_terms_of_delivery")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.way_of_delivery"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("way_of_delivery") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_way_of_delivery")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_way_of_delivery")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.our_reference"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("our_reference") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_our_reference")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_our_reference")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.remarks"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("remarks") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_remarks")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_remarks")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.freight"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("freight") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_freight")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_freight")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.administration_fee"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("administration_fee") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_administration_fee")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_administration_fee")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="contractConfigForm.print_template"
                    :disabled="
                      contractConfigForm.auto_update_contract_with_template_data ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <p>
                      <label class="label-text me-2" for="radio-apply-three"
                        >{{ $t("print_template") }}
                      </label>
                    </p>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext("template_print_template")
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext("template_print_template")
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <!-- ######################################################## -->

            <div class="mt-3 mb-2">
              <h5 class="header">{{ $t("renew_contract") }}</h5>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("let_application_renew_contract") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("auto_renew_contracts")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("auto_renew_contracts")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      v-model="contractConfigForm.auto_renew_contracts"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-auto-renew-one"
                      name="switch-auto-renew"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-auto-renew-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      v-model="contractConfigForm.auto_renew_contracts"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-auto-renew-two"
                      name="switch-auto-renew"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-auto-renew-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("apply_current_price_new_contract") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("use_current_article_data")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("use_current_article_data")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div>
                    <input
                      :disabled="!contractConfigForm.auto_renew_contracts"
                      v-model="contractConfigForm.use_current_article_data"
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-article-prices-one"
                      name="switch-article-prices"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-article-prices-one"
                      >{{ $t("yes") }}</label
                    >
                  </div>
                  <div>
                    <input
                      :disabled="!contractConfigForm.auto_renew_contracts"
                      v-model="contractConfigForm.use_current_article_data"
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-article-prices-two"
                      name="switch-article-prices"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-article-prices-two"
                      >{{ $t("no") }}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-3">
                <p>
                  {{ $t("row_with_inactive_article_text") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext("use_current_article_data")
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext("use_current_article_data")
                    }}</span>
                  </span>
                </p>
                <div>
                  <div style="display: flex">
                    <input
                      v-model="contractConfigForm.todo_with_inactive_articles"
                      v-bind:value="'include'"
                      class="checkbox"
                      :disabled="
                        contractConfigForm.auto_renew_contracts === false
                      "
                      type="radio"
                      id="radio-article-todo-one"
                      name="todo_with_inactive_articles"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      style="position: relative; top: -6px"
                      for="radio-article-prices-one"
                      >{{
                        $t("row_with_inactive_article_text_option_1")
                      }}</label
                    >
                  </div>
                  <div>
                    <input
                      v-model="contractConfigForm.todo_with_inactive_articles"
                      v-bind:value="'exclude'"
                      class="checkbox"
                      :disabled="
                        contractConfigForm.auto_renew_contracts === false
                      "
                      type="radio"
                      id="radio-article-todo-two"
                      name="todo_with_inactive_articles"
                    />
                    <label
                      class="label-text fs-8 ms-2"
                      for="radio-article-prices-two"
                      >{{
                        $t("row_with_inactive_article_text_option_2")
                      }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap mt-3">
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span
                    ><a
                      class="link-click"
                      @click="openExcludeContract = true"
                      href="javascript:void(0);"
                      >{{ $t("click_here") }}</a
                    >
                    {{ $t("exclude_contract_text") }}</span
                  >
                </div>
              </div>
            </div>
            <!-- <hr> -->
            <!-- <div class="mt-3 mb-2"><h5 class="header">{{ $t('cancelled_contract') }}</h5></div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div>
                <div class="desc mb-2">
                  <p>{{ $t('cancelled_contract_text_2') }}</p>
                </div>
                <div class="d-flex flex-wrap mb-1">
                  <div class="d-flex align-items-center">
                    <input :disabled="!contractConfigForm.auto_create_invoice"
                           v-model="contractConfigForm.continuous_terminated_contracts" class="checkbox me-1"
                           type="checkbox" name="checkbox-one"/>
                    <div>
                                        <span class="fs-8">{{ $t('cancelled_contract_text_2') }}
                                            <span class="tooltip-custom">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                     viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
                                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                      clip-rule="evenodd"/>
                                                </svg>
                                                <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                                                    getFieldHelptext('continuous_terminated_contracts')
                                                  }}</span>
                                                <span class="tooltiptext" v-else>{{
                                                    getFieldHelptext('continuous_terminated_contracts')
                                                  }}</span>
                                            </span>
                                        </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap mb-1">
                  <div class="d-flex align-items-center">
                    <input :disabled="!contractConfigForm.auto_create_invoice"
                           v-model="contractConfigForm.continuous_inactivated_contracts" class="checkbox me-1"
                           type="checkbox" name="checkbox-one"/>
                    <div>
                                        <span class="fs-8">{{ $t('cancelled_contract_text_3') }}
                                            <span class="tooltip-custom">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                     viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
                                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                      clip-rule="evenodd"/>
                                                </svg>
                                                <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                                                    getFieldHelptext('continuous_inactivated_contracts')
                                                  }}</span>
                                                <span class="tooltiptext" v-else>{{
                                                    getFieldHelptext('continuous_inactivated_contracts')
                                                  }}</span>
                                            </span>
                                        </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap mb-1">
                  <div class="d-flex align-items-center">
                    <input :disabled="!contractConfigForm.auto_create_invoice"
                           v-model="contractConfigForm.fixed_contracts_terminated_prior_to_end" class="checkbox me-1"
                           type="checkbox" name="checkbox-one"/>
                    <div>
                                        <span class="fs-8">{{ $t('cancelled_contract_text_4') }}
                                            <span class="tooltip-custom">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                     viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
                                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                      clip-rule="evenodd"/>
                                                </svg>
                                                <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                                                    getFieldHelptext('fixed_contracts_terminated_prior_to_end')
                                                  }}</span>
                                                <span class="tooltiptext" v-else>{{
                                                    getFieldHelptext('fixed_contracts_terminated_prior_to_end')
                                                  }}</span>
                                            </span>
                                        </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap mb-1">
                  <div class="d-flex align-items-center">
                    <input :disabled="!contractConfigForm.auto_create_invoice"
                           v-model="contractConfigForm.fixed_contracts_terminated_on_end" class="checkbox me-1"
                           type="checkbox" name="checkbox-one"/>
                    <div>
                                        <span class="fs-8">{{ $t('cancelled_contract_text_5') }}
                                            <span class="tooltip-custom">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                                     viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
                                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                      clip-rule="evenodd"/>
                                                </svg>
                                                <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                                                    getFieldHelptext('fixed_contracts_terminated_on_end')
                                                  }}</span>
                                                <span class="tooltiptext" v-else>{{
                                                    getFieldHelptext('fixed_contracts_terminated_on_end')
                                                  }}</span>
                                            </span>
                                        </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-wrap col-flex-wrap">
                  <input :disabled="!contractConfigForm.auto_create_invoice" v-model="contractConfigForm.all_contracts"
                         class="checkbox me-1" type="checkbox" name="checkbox-one"/>
                  <span class="fs-8 me-2">{{ $t('all_contract_that_are') }}</span>
                  <div class="desc">
                    <div class="d-flex"> -->
            <!-- THIS SHOULD NOT BE UNCOMMENTED! <input type="text"  required/> -->
            <!-- <select :disabled="!contractConfigForm.auto_create_invoice"
                              v-model="contractConfigForm.all_contracts_that_are">
                        <option value="terminated">{{ $t('terminated') }}</option>
                        <option value="inactivated">{{ $t('inactivated') }}</option>
                      </select>
                    </div>
                  </div>
                  <span class="fs-8 ms-2 me-2"> {{ $t('with_comments_containing_text') }}</span>
                  <div class="desc">
                    <div class="d-flex justify-content-between">
                      <input :disabled="!contractConfigForm.auto_create_invoice" class="w-full" type="text"
                             v-model="contractConfigForm.contract_comment_containing"/>
                    </div>
                  </div>
                  <div class="desc">
                    <div class="d-flex justify-content-between">
                                        <span class="tooltip-custom">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                                                 fill="currentColor">
                                            <path fill-rule="evenodd"
                                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                  clip-rule="evenodd"/>
                                            </svg>
                                            <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                                                getFieldHelptext('continuous_terminated_contracts')
                                              }}</span>
                                            <span class="tooltiptext" v-else>{{
                                                getFieldHelptext('continuous_terminated_contracts')
                                              }}</span>
                                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="d-flex justify-content-end flex-wrap">
              <div class="buttons flex-wrap align-items-center">
                <div class="right flex-wrap my-2 my-sm-0">
                  <button :disabled="processing" type="submit">
                    <span v-if="processing">{{ $t("saving") }}...</span>
                    <span v-else>{{ $t("save_changes") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="box p-4 mb-5" v-if="tab === 2">
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <!-- <h5 class="header">{{ $t('fortnox_finans_invoices') }}</h5> -->
            </div>
            <router-link :to="{ name: 'invoiceGuide' }" class="my-3 my-sm-0">
              {{ $t("go_to_information_quide") }}
              <svg
                width="7"
                height="14"
                viewbox="0 0 7 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.16154 -0.000275612C1.31093 -0.00078392 1.45854 0.0321884 1.59352 0.0962172C1.7285 0.160246 1.84742 0.253705 1.94154 0.369725L6.77154 6.36972C6.91862 6.54866 6.99902 6.7731 6.99902 7.00472C6.99902 7.23635 6.91862 7.46079 6.77154 7.63972L1.77154 13.6397C1.6018 13.8439 1.35789 13.9724 1.09346 13.9967C0.829033 14.0211 0.565752 13.9395 0.361536 13.7697C0.157319 13.6 0.0288949 13.3561 0.00451517 13.0916C-0.0198646 12.8272 0.0617967 12.5639 0.231535 12.3597L4.70154 6.99972L0.381536 1.63972C0.259253 1.49294 0.181576 1.3142 0.157696 1.12465C0.133817 0.935102 0.164733 0.742677 0.246789 0.570149C0.328846 0.397622 0.458605 0.252211 0.620716 0.15112C0.782826 0.0500298 0.970502 -0.00250721 1.16154 -0.000275612Z"
                  fill="#5855D6"
                ></path>
              </svg>
            </router-link>
          </div>
          <form @submit.prevent="processInvoiceConfigurationForm">
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <p>
                  {{ $t("auto_pause_unpaid_and_overdue_invoices") }}
                  <span class="tooltip-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                      getFieldHelptext(
                        "auto_pause_unpaid_and_overdue_invoices",
                        "invoice"
                      )
                    }}</span>
                    <span class="tooltiptext" v-else>{{
                      getFieldHelptext(
                        "auto_pause_unpaid_and_overdue_invoices",
                        "invoice"
                      )
                    }}</span>
                  </span>
                </p>
              </div>
              <div class="desc ms-5">
                <div class="d-flex">
                  <div class="d-flex flex-row mr-3 align-items-center me-3">
                    <label class="label-text m-0 mx-1 fs-7" for="radio-one">{{
                      $t("yes")
                    }}</label>
                    <input
                      v-model="
                        invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices
                      "
                      v-bind:value="true"
                      class="checkbox"
                      type="radio"
                      id="radio-two"
                      name="switch-two"
                    />
                  </div>
                  <div class="d-flex flex-row mr-3 align-items-center me-3">
                    <label class="label-text m-0 mx-1 fs-7" for="radio-two">{{
                      $t("no")
                    }}</label>
                    <input
                      v-model="
                        invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices
                      "
                      v-bind:value="false"
                      class="checkbox"
                      type="radio"
                      id="radio-two"
                      name="switch-two"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <span class="me-2">{{ $t("pause_invoice_for") }}</span>
              </div>
              <div class="desc">
                <div class="d-flex align-items-center">
                  <input
                    style="width: 60px !important"
                    type="number"
                    min="1"
                    max="60"
                    v-model="invoiceConfigForm.pause_invoice_days_count"
                  />
                  <span class="ms-2 me-2">{{ $t("days") }},</span>
                </div>
              </div>
              <div class="desc">
                <div class="d-flex align-items-center">
                  <input
                    type="number"
                    min="1"
                    v-model="
                      invoiceConfigForm.pause_invoice_days_after_due_date
                    "
                  />
                  <span class="ms-2"
                    >{{ $t("pause_invoice_days_after_due_date") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext(
                          "pause_invoice_days_after_due_date",
                          "invoice"
                        )
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext(
                          "pause_invoice_days_after_due_date",
                          "invoice"
                        )
                      }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-3 mb-2">
              <h5 class="header">{{ $t("apply_this_to") }}</h5>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <div class="d-flex mb-3">
                  <input
                    v-model="invoiceConfigForm.all_invoices"
                    style="box-shadow: none !important"
                    :disabled="
                      invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices ===
                      false
                    "
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-one"
                    name="switch-two"
                  />
                  <label class="label-text" for="radio-apply-one"
                    >{{ $t("all_invoice") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("all_invoices", "invoice")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("all_invoices", "invoice")
                      }}</span>
                    </span>
                  </label>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="customers_segment"
                    :disabled="
                      invoiceConfigForm.all_invoices === true ||
                      invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-two"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <label class="label-text me-2" for="radio-apply-two"
                      >{{ $t("invoice_for_customer_segment") }}
                    </label>
                    <div class="me-1">
                      <input
                        v-model="showSegmentName"
                        type="text"
                        @input="searchField"
                        :disabled="
                          invoiceConfigForm.all_invoices === true ||
                          invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices ===
                            false
                        "
                      />
                      <div
                        v-if="showSegment"
                        style="z-index: 9999"
                        class="position-absolute bg-white rounded shadow-md border p-2"
                      >
                        <ul
                          style="
                            padding-left: 0.3rem !important;
                            list-style-type: none;
                          "
                        >
                          <li
                            class="border-b mb-1 cursor-pointer"
                            v-for="item in searchSegment"
                            :key="item.id"
                            @click="chooseCustomerSegment(item)"
                          >
                            {{ item.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext(
                            "invoices_for_customers_segment",
                            "invoice"
                          )
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext(
                            "invoices_for_customers_segment",
                            "invoice"
                          )
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <input
                    v-model="terms_of_payment"
                    :disabled="
                      invoiceConfigForm.all_invoices === true ||
                      invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices ===
                        false
                    "
                    style="box-shadow: none !important"
                    class="checkbox"
                    type="checkbox"
                    id="radio-apply-three"
                    name="switch-two"
                  />
                  <div class="d-flex flex-wrap">
                    <label class="label-text me-2" for="radio-apply-three"
                      >{{ $t("invoice_with_terms_of_payment") }}
                    </label>
                    <select
                      v-model="invoiceConfigForm.terms_of_payment"
                      :disabled="
                        invoiceConfigForm.all_invoices === true ||
                        invoiceConfigForm.auto_pause_unpaid_and_overdue_invoices ===
                          false
                      "
                    >
                      <option
                        :value="item.code"
                        v-for="item in paymentTerm"
                        :key="item.id"
                      >
                        {{ item.description }}
                      </option>
                    </select>
                    <div>
                      <span class="tooltip-custom">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                          getFieldHelptext(
                            "invoices_with_terms_of_payment",
                            "invoice"
                          )
                        }}</span>
                        <span class="tooltiptext" v-else>{{
                          getFieldHelptext(
                            "invoices_with_terms_of_payment",
                            "invoice"
                          )
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3 mb-2">
              <h5 class="header">{{ $t("lost_revenue") }}</h5>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span class="me-2"
                    >{{ $t("we_consider_invoice_unpaid") }}
                  </span>
                  <input
                    type="number"
                    v-model.number="invoiceConfigForm.lost_revenue_days"
                  />
                </div>
              </div>
              <div class="desc">
                <div class="d-flex align-items-center">
                  <span
                    >{{ $t("we_consider_invoice_unpaid_1") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext("lost_revenue_days", "invoice")
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext("lost_revenue_days", "invoice")
                      }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-3 mb-2">
              <h5 class="header">{{ $t("notifications") }}</h5>
            </div>
            <div class="d-flex align-items-center flex-wrap col-flex-wrap">
              <div>
                <div class="des d-flex flex-wrap mb-2">
                  <p style="font-size: 13px" class="me-2">
                    {{ $t("receive_alert") }}
                  </p>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 16%"
                  >
                    <div class="d-flex flex-row mr-3 align-items-center me-3">
                      <label class="label-text m-0 mx-1 fs-7" for="radio-one">{{
                        $t("yes")
                      }}</label>
                      <input
                        v-model="invoiceConfigForm.receive_notification"
                        v-bind:value="true"
                        class="checkbox"
                        type="radio"
                        id="radio-one"
                        name="switch-one"
                      />
                    </div>
                    <div class="d-flex flex-row mr-3 align-items-center me-3">
                      <label class="label-text m-0 mx-1 fs-7" for="radio-two">{{
                        $t("no")
                      }}</label>
                      <input
                        v-model="invoiceConfigForm.receive_notification"
                        v-bind:value="false"
                        class="checkbox"
                        type="radio"
                        id="radio-two"
                        name="switch-one"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-wrap col-flex-wrap">
                  <input
                    :disabled="!invoiceConfigForm.receive_notification"
                    v-model="invoiceConfigForm.receive_notification"
                    class="checkbox me-1"
                    type="checkbox"
                    name="checkbox-one"
                  />
                  <span class="fs-8 me-2">{{
                    $t("an_invoice_is_unpaid")
                  }}</span>
                  <div class="desc">
                    <div class="d-flex">
                      <input
                        type="text"
                        :disabled="!invoiceConfigForm.receive_notification"
                        v-model="
                          invoiceConfigForm.receive_notification_days_after_due_date
                        "
                      />
                    </div>
                  </div>
                  <span class="fs-8 ms-2 me-2">
                    {{ $t("receive_notification_days_after_due_date") }}
                    <span class="tooltip-custom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="tooltiptext" v-if="GET_LOCALE === 'end'">{{
                        getFieldHelptext(
                          "receive_notification_days_after_due_date",
                          "invoice"
                        )
                      }}</span>
                      <span class="tooltiptext" v-else>{{
                        getFieldHelptext(
                          "receive_notification_days_after_due_date",
                          "invoice"
                        )
                      }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end flex-wrap">
              <div class="buttons flex-wrap align-items-center">
                <div class="right flex-wrap my-2 my-sm-0">
                  <button :disabled="processing" type="submit">
                    <span v-if="processing">{{ $t("saving") }}...</span>
                    <span v-else>{{ $t("save_changes") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ModalBoxVue
    v-if="openExcludeContract === true"
    customize="customize large-popup"
    @handleClose="openExcludeContract = false"
    :close="!true"
  >
    <div class="d-flex justify-content-between p-2">
      <div class="mt-2 d-flex flex-column w-full px-3">
        <span class="fw-bold fs-5">{{ $t("contracts_to_exclude") }}</span>
        <div class="d-flex justify-content-between flex-wrap">
          <small class="text-xs">{{
            $t("contracts_to_exclude_helptext")
          }}</small>
          <span style="width: 20%" class="d-flex justify-content-end">
            <input
              @input="searchContractField"
              type="text"
              class="w-full rounded input-search text-xs"
              style="padding: 1px 10px !important"
              :placeholder="$t('search')"
            />
          </span>
        </div>
      </div>
    </div>
    <hr />
    <div class="container p-3">
      <div class="row">
        <div class="col-md-12 col-md-lg">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        v-model="toggleChangeCheckbox"
                        class="form-check-input"
                        @change="toggleAllCheckbox"
                      />
                      <!-- <svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.28201" y="0.664818" width="18.5834" height="18.5834" rx="3.42325" fill="#5855D6" stroke="#5855D6" stroke-width="0.978073"></rect>
                        <path d="M14.8057 5.95575C15.0124 5.75899 15.2874 5.65022 15.5727 5.65238C15.8581 5.65453 16.1314 5.76746 16.3351 5.96732C16.5387 6.16718 16.6568 6.43834 16.6643 6.72359C16.6719 7.00884 16.5683 7.28587 16.3755 7.49621L10.5217 14.8171C10.4211 14.9255 10.2996 15.0125 10.1645 15.0729C10.0295 15.1333 9.88365 15.1658 9.73574 15.1686C9.58782 15.1713 9.44087 15.1442 9.30368 15.0888C9.16648 15.0335 9.04186 14.951 8.93726 14.8464L5.05529 10.9645C4.94718 10.8637 4.86047 10.7422 4.80033 10.6073C4.74019 10.4723 4.70786 10.3266 4.70525 10.1789C4.70264 10.0311 4.72982 9.88435 4.78516 9.74734C4.8405 9.61033 4.92287 9.48587 5.02736 9.38139C5.13184 9.2769 5.2563 9.19453 5.39331 9.13919C5.53033 9.08385 5.67708 9.05667 5.82482 9.05928C5.97256 9.06189 6.11827 9.09422 6.25324 9.15436C6.38821 9.2145 6.50969 9.30121 6.61042 9.40932L9.68255 12.48L14.7778 5.98802C14.787 5.97673 14.7968 5.96595 14.8072 5.95575H14.8057Z" fill="#F6F8FC"></path>
                    </svg> -->
                    </div>
                  </th>
                  <!-- <th v-for="(item, i) in tableHeader" :key="i"><span
                    class="whitespace-nowrap text-xs">{{ $services.helpers.removeUnderscoreAndCapString(item) }}</span>
                </th> -->
                  <th v-for="(item, i) in tableHeader" :key="i">
                    <div style="display: flex">
                      <span>{{ $t(item) }}</span>
                      <span
                        class="th-soring"
                        v-if="sortableColumns.includes(item)"
                      >
                        <i
                          class="bx bx-chevron-up bx-xs el-clickable"
                          v-on:click="sortAscBy(item)"
                        ></i>
                        <i
                          class="bx bx-chevron-down bx-xs el-clickable"
                          v-on:click="sortDescBy(item)"
                        ></i>
                      </span>
                    </div>
                  </th>
                  <!-- <th> <span>Actions</span></th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(contract, i) in CONTRACTS.data" :key="i">
                  <ContractTable
                    :data="contract"
                    :head="tableHeader"
                    :hide="true"
                    :extra="
                      contractConfigForm.contracts_to_exclude_from_auto_renewal
                    "
                  />
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end w-full">
            <v-pagination
              v-model="CONTRACTS.page"
              :pages="CONTRACTS.total_pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="changePageNumber"
            />
          </div>
        </div>
        <div class="col-md-12 col-md-lg">
          <div
            class="buttons flex-wrap align-items-center"
            style="float: right"
          >
            <div class="right flex-wrap my-2 my-sm-0">
              <button
                :disabled="exclude_processing"
                type="button"
                v-on:click="updateExcludeContract"
              >
                <span v-if="exclude_processing">{{ $t("saving") }}...</span>
                <span v-else>{{ $t("save_changes") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalBoxVue>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
import ContractTable from "./reusableComponents/ContractTable.vue";
import ModalBoxVue from "../../components/ModalBox.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
// import config_rows from "./reusableComponents/config_rows"

export default {
  name: "Configurations",
  components: { ModalBoxVue, ContractTable, VPagination },
  data() {
    return {
      tab: 1,
      exclude_processing: false,
      processing: false,
      openExcludeContract: false,
      showSegment: false,
      paymentTerm: [],
      searchSegment: [],
      values: [
        {
          key: "=",
          name: this.$t("equal"),
        },
        {
          key: "<",
          name: this.$t("less_than"),
        },
        {
          key: ">",
          name: this.$t("greater_than"),
        },
      ],
      customerTypes: [
        {
          key: "PRIVATE",
          name: this.$t("private"),
        },
        {
          key: "COMPANY",
          name: this.$t("company"),
        },
      ],
      workdayOptions: [
        {
          key: "last",
          name: this.$t("last_workday_of_month"),
        },
        {
          key: "first",
          name: this.$t("first_workday_of_month"),
        },
      ],
      toggleChangeCheckbox: false,
      searchValue: "",
      helptextContract: [],
      helptextInvoice: [],
      customers_segment: false,
      terms_of_payment: false,
      invoiceConfigForm: {
        auto_pause_unpaid_and_overdue_invoices: false,
        customers_segment: "",
        all_invoices: false,
        receive_notification_days_after_due_date: 0,
        terms_of_payment: "",
        lost_revenue_days: "",
        pause_invoice_days_count: "",
        pause_invoice_days_after_due_date: "",
        // apply_settings_to : [],
        apply_settings_to: [],
        receive_notification: "",
      },
      contractConfigForm: {
        all_contracts_settings: {
          category: "",
          comment: "",
        },
        days_before_period_start: "",
        day_of_every_month: "",
        days_from_period_start: "",
        use_x_day_before_period_start: "",
        auto_create_invoice: true,
        todo_with_inactive_articles: "include",
        create_invoice_based_on: "",
        day_to_bill: "",
        auto_send_invoices: false,
        use_customer_default_send_method: false,
        send_invoice_with_fortnox_finance: "",
        send_all_invoices_through: "",
        contract_comment_containing: "",
        auto_renew_contracts: false,
        use_current_article_data: false,
        contracts_to_exclude_from_auto_renewal: [],
        continuous_terminated_contracts: false,
        continuous_inactivated_contracts: false,
        fixed_contracts_terminated_prior_to_end: false,
        fixed_contracts_terminated_on_end: false,
        all_contracts: false,
        config_rows: [],
      },
      showSegmentName: "",
      orderByColumn: null, // which column to order
      orderByQuery: "",
      sortableColumns: [
        "category",
        "customer_name",
        "contract_date",
        "period_start",
        "period_end",
        "invoices_remaining",
        "contract_length",
        "invoice_interval",
        "currency",
        "total",
      ],
    };
  },
  watch: {
    GET_CONTRACT_CHECKED: function (newVal, oldVal) {
      if (newVal && newVal.data.length > 0) {
        newVal.data.forEach((element) => {
          if (
            this.contractConfigForm.contracts_to_exclude_from_auto_renewal.includes(
              parseInt(element.document_number)
            )
          ) {
            this.removeItemOnce(
              this.contractConfigForm.contracts_to_exclude_from_auto_renewal,
              parseInt(element.document_number)
            );
          } else {
            this.contractConfigForm.contracts_to_exclude_from_auto_renewal.push(
              parseInt(element.document_number)
            );
          }
        });
      }
    },
    "invoiceConfigForm.all_invoices": function (newval, oldval) {
      if (newval === true) {
        this.customers_segment = false;
        this.terms_of_payment = false;
      }
    },
    "contractConfigForm.create_invoice_based_on": function (newval, oldval) {
      if (newval === "Period start date") {
        this.contractConfigForm.use_x_day_before_period_start = true;
      } else {
        this.contractConfigForm.use_x_day_before_period_start = false;
      }
    },
    "invoiceConfigForm.pause_invoice_days_count": function (newval, oldval) {
      if (newval > 60) {
        this.invoiceConfigForm.pause_invoice_days_count = oldval;
      }
    },
  },
  computed: {
    ...mapGetters({
      GET_CONFIG_CONTRACT_CONFIG: "clients/GET_CONFIG_CONTRACT_CONFIG",
      GET_CONFIG_INVOICE_CONFIG: "clients/GET_CONFIG_INVOICE_CONFIG",
      CONTRACTS: "clients/GET_CONTRACTS",
      GET_CONTRACT_CHECKED: "clients/GET_CONTRACT_CHECKED",
      GET_LOCALE: "locale/GET_LOCALE",
      GET_PACKAGE: "clients/GET_PLAN_DETAIL",
      GET_USER_SUBSCRIPTION: "clients/GET_USER_SUBSCRIPTION",
    }),
  },
  mounted() {
    this.openAccess();
    this.getConfigContract();
    this.getPaymentTerm();
    this.getContracts();
    this.getContractCustomizeHeader();
    this.getHelptext("contract_config");
    this.getHelptext("invoice_config");
    this.getConfigInvoice();
  },
  methods: {
    openAccess() {
      this.$store.dispatch("clients/getCurrentUserInfo").then((res) => {
        if (res.data.data.access === "Limited") {
          window.Bus.emit("access-denied", "billings");
        } else {
          this.getUserSubscription();
        }
      });
    },
    getUserSubscription() {
      this.$store
        .dispatch("clients/getUserSubscription")
        .then((res) => {
          this.getPackageInfo();
        })
        .catch((err) => {});
    },
    getPackageInfo() {
      if (this.GET_USER_SUBSCRIPTION.length > 0) {
        if (
          !["free", "Free", "FREE"].includes(
            this.GET_USER_SUBSCRIPTION[0].current_package
          )
        ) {
          const payload = {
            URL:
              this.$services.endpoints.PACKAGE_ENDPOINT +
              `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
          };
          this.$store
            .dispatch("clients/getPlanDetails", payload)
            .then((res) => {
              if (
                !res.data.results[0].dashboard_page_access.includes(
                  this.$route.name
                )
              ) {
                window.Bus.emit("access-denied", "configuration");
              }
            });
        } else {
          window.Bus.emit("access-denied", "configuration");
        }
      } else {
        this.getUserSubscription();
      }
    },
    updateExcludeContract: function () {
      this.exclude_processing = true;
      let exclude_array = [];
      this.GET_CONTRACT_CHECKED.data.map((item) => {
        if (!exclude_array.includes(item.document_number)) {
          exclude_array.push(item.document_number);
        }
      });
      let payload = this.contractConfigForm;
      for (const [key, value] of Object.entries(payload)) {
        if (value === "" || value === null) {
          delete payload[key];
        }
      }
      payload.contracts_to_exclude_from_auto_renewal = exclude_array;
      this.$store
        .dispatch("clients/updateConfiguration", {
          mode: "contract",
          payload: payload,
        })
        .then((res) => {
          this.exclude_processing = false;
          this.$services.helpers.notification(
            res.data.message,
            "success",
            this
          );
        })
        .catch((err) => {
          this.exclude_processing = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    searchContractField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSearchContract(event.target.value);
      } else {
        this.getContracts();
      }
    }, 500),
    filterSearchContract(data) {
      const payload = `search=${data}`;
      this.$store.commit("clients/UPDATE_CONTRACTS", []);
      this.filter = payload;
      this.$store.dispatch("clients/getContracts", payload);
    },
    getContracts() {
      let payload = this.orderByQuery;
      this.$store.dispatch("clients/getContracts", payload);
    },
    sortAscBy(item) {
      this.orderByColumn = item;
      this.orderByQuery = `&ordering=${this.orderByColumn}`;
      this.getContracts();
      // this.$store.commit('clients/SORT_ASC', {'item': item, 'state': 'contract', 'copy': 'contractCopy'})
    },
    sortDescBy(item) {
      this.orderByColumn = item;
      this.orderByQuery = `&ordering=-${this.orderByColumn}`;
      this.getContracts();
      // this.$store.commit('clients/SORT_DESC', {'item': item, 'state': 'contract', 'copy': 'contractCopy'})
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "termsofpayment";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.paymentTerm = res.data.results;
        })
        .catch((err) => {});
    },
    searchField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSearch(event.target.value);
      } else {
        this.showSegment = false;
      }
    }, 500),
    filterSearch(data) {
      const payload = {
        URL:
          this.$services.endpoints.SEGMENT_ENDPOINT +
          `customers?search=${data}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          if (res.data.results.length > 0) {
            this.showSegment = true;
            this.searchSegment = res.data.results;
          }
        })
        .catch((err) => {});
    },
    chooseCustomerSegment(item) {
      this.invoiceConfigForm.customers_segment = item.id;
      this.showSegmentName = item.name;
      this.searchValue = item.name;
      this.showSegment = false;
    },
    changePageNumber(number) {
      let payload = `page=${number}`;
      this.$store.dispatch("clients/getContracts", payload + this.orderByQuery);
      this.computeExcludedCntract();
    },
    getHelptext(data) {
      const payload = {
        URL:
          this.$services.endpoints.HELPTEXT_ENDPOINT +
          `?dashboard_page=${data}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          if (data === "contract_config") {
            this.helptextContract = res.data.results;
          } else {
            this.helptextInvoice = res.data.results;
          }
        })
        .catch((err) => {});
    },
    getContractCustomizeHeader() {
      this.$store
        .dispatch("clients/getContractCustomizeHeader")
        .then((res) => {
          this.tableHeader = res.data.data;
        })
        .catch((err) => {});
    },
    getConfigContract() {
      this.$store
        .dispatch("clients/getConfigurationUtility", { mode: "contract" })
        .then((res) => {
          this.$store.commit(
            "clients/UPDATE_CONTRACT_CONFIG",
            res.data.results[0]
          );
          this.contractConfigForm = this.GET_CONFIG_CONTRACT_CONFIG;
          this.contractConfigForm.send_all_invoices_through =
            this.$services.helpers.capitalizeNames(
              this.GET_CONFIG_CONTRACT_CONFIG.send_all_invoices_through
            );
          this.contractConfigForm.send_invoice_with_fortnox_finance =
            this.$services.helpers.capitalizeNames(
              this.GET_CONFIG_CONTRACT_CONFIG.send_invoice_with_fortnox_finance
            );
          if (!this.GET_CONFIG_CONTRACT_CONFIG.all_contracts_settings) {
            this.contractConfigForm.all_contracts_settings = {};
            this.contractConfigForm.all_contracts_settings.category = "";
            this.contractConfigForm.all_contracts_settings.comment = "";
          } else {
            this.contractConfigForm.all_contracts_settings.category = "";
            this.contractConfigForm.all_contracts_settings.comment = "";
          }
          this.computeExcludedCntract();
        })
        .catch((err) => {});
    },
    computeExcludedCntract() {
      if (
        this.GET_CONFIG_CONTRACT_CONFIG.contracts_to_exclude_from_auto_renewal
          .length > 0
      ) {
        this.GET_CONFIG_CONTRACT_CONFIG.contracts_to_exclude_from_auto_renewal.map(
          (item) => {
            let conIndex = this.CONTRACTS.data.findIndex(
              (conItem) => conItem.document_number === item
            );
            if (conIndex !== -1) {
              this.CONTRACTS.data[conIndex].checked = true;
            }
          }
        );
      }
    },
    getConfigInvoice() {
      this.$store
        .dispatch("clients/getConfigurationUtility", { mode: "invoice" })
        .then((res) => {
          this.$store.commit(
            "clients/UPDATE_INVOICE_CONFIG",
            res.data.results[0]
          );
          this.invoiceConfigForm = this.GET_CONFIG_INVOICE_CONFIG;
          if (this.invoiceConfigForm.all_invoices === true) {
            this.customers_segment = false;
            this.terms_of_payment = false;
          } else {
            if (res.data.results[0].terms_of_payment) {
              this.terms_of_payment = true;
            }
            if (res.data.results[0].customers_segment) {
              this.customers_segment = true;
              this.getCustomerSegmentDetail(
                res.data.results[0].customers_segment
              );
            }
          }
        })
        .catch((err) => {});
    },
    processContractConfigurationForm() {
      if (
        this.contractConfigForm.send_invoice_with_fortnox_finance === "Some" &&
        !this.contractConfigForm.config_rows.length
      ) {
        this.$toast.warning(this.$t("Please add at least one config row."));

        return;
      }
      this.processing = true;
      let payload = this.contractConfigForm;
      console.log("PAYLOAD", payload)
      for (const [key, value] of Object.entries(payload)) {
        if (value === "" || value === null) {
          delete payload[key];
        }
      }
      this.$store
        .dispatch("clients/updateConfiguration", {
          mode: "contract",
          payload: payload,
        })
        .then((res) => {
          this.processing = false;
          // this.$services.helpers.notification('Contract configuration updated successfully', 'success', this)
          this.$toast.success(this.$t("configuration_updated"));
          this.getConfigContract();
        })
        .catch((err) => {
          this.processing = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    processInvoiceConfigurationForm() {
      this.processing = true;
      // this.invoiceConfigForm.apply_settings_to = [this.invoiceConfigForm.apply_settings_to]
      let payload = this.invoiceConfigForm;
      let data_to_save = {};
      for (const [key, value] of Object.entries(payload)) {
        if (value !== "" && value !== null && value !== undefined) {
          data_to_save[key] = value;
        }
      }
      if ("subscription" in data_to_save) {
        delete data_to_save["subscription"];
      }
      this.$store
        .dispatch("clients/updateConfiguration", {
          mode: "invoice",
          payload: data_to_save,
        })
        .then((res) => {
          this.processing = false;
          // this.$services.helpers.notification('Invoice configuration updated successfully', 'success', this)
          this.$toast.success(this.$t("configuration_updated"));
        })
        .catch((err) => {
          this.processing = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    appendSettingsValue(e) {
      if (e.target.value === "All invoices") {
        this.invoiceConfigForm.apply_settings_to = ["All invoices"];
        this.invoiceConfigForm.all_invoices = true;
        this.invoiceConfigForm.customers_segment = "";
        this.invoiceConfigForm.terms_of_payment = "";
      } else {
        this.invoiceConfigForm.all_invoices = false;
        if (
          !this.invoiceConfigForm.apply_settings_to.includes(e.target.value)
        ) {
          this.invoiceConfigForm.apply_settings_to.push(e.target.value);
        }
        if (this.invoiceConfigForm.apply_settings_to.includes("All invoices")) {
          let allIndex = this.invoiceConfigForm.apply_settings_to.findIndex(
            (item) => item === "All invoices"
          );
          if (allIndex !== -1) {
            this.invoiceConfigForm.apply_settings_to.splice(allIndex, 1);
          }
        }
      }
      this.invoiceConfigForm.apply_settings_to = e.target.value;
    },
    getFieldHelptext(field, mode = "contract") {
      if (mode === "contract") {
        if (this.helptextContract.length > 0) {
          let response = "";
          this.helptextContract.forEach((element) => {
            if (element.field === field) {
              // switch lang
              if (this.GET_LOCALE === "en") {
                response = element.english_text;
              } else {
                response = element.swedish_text;
              }
            }
          });
          return response;
        }
      } else {
        if (this.helptextInvoice.length > 0) {
          let response = "";
          this.helptextInvoice.forEach((element) => {
            if (element.field === field) {
              // switch lang
              if (this.GET_LOCALE === "en") {
                response = element.english_text;
              } else {
                response = element.swedish_text;
              }
            }
          });
          return response;
        }
      }
    },
    removeConfigRow(condition) {
      if (
        this.contractConfigForm.send_invoice_with_fortnox_finance === "Some" &&
        this.contractConfigForm.auto_send_invoices &&
        this.contractConfigForm.auto_create_invoice
      ) {
        const data = this.contractConfigForm.config_rows[condition];
        if ("id" in data) {
          const payload = {
            URL:
              this.$services.endpoints.CONFIGURATION_ENDPOINT +
              `/sub-contract/${data.id}`,
          };
          this.$store
            .dispatch("clients/customDeleteRequest", payload)
            .then((res) => {
              this.contractConfigForm.config_rows.splice(condition, 1);
            })
            .catch((err) => {
              // this.$services.helpers.notification('Configuration could not be remove,try again', 'error', this)
              this.$toast.error(this.$t("configuration_not_updated"));
            });
        } else {
          this.contractConfigForm.config_rows.splice(condition, 1);
        }
      }
    },
    addConfigRow() {
      if (
        this.contractConfigForm.send_invoice_with_fortnox_finance === "Some" &&
        this.contractConfigForm.auto_send_invoices &&
        this.contractConfigForm.auto_create_invoice
      ) {
        const payload = {
          type: "payment",
          comparison: "",
        };
        this.contractConfigForm.config_rows.push(payload);
      }
    },
    deleteConfigRow(id) {
      const payload = {
        URL:
          this.$services.endpoints.CONFIGURATION_ENDPOINT +
          `configurations/sub-contract/${id}`,
      };
      this.$store.dispatch("clients/customDeleteRequest");
    },
    switchTab(number) {
      this.tab = parseInt(number);
    },
    toggleAllCheckbox(e) {
      this.$store.commit(
        "clients/TOGGLE_ALL_CONTRACT_CHECKBOX",
        this.toggleChangeCheckbox
      );
    },
    removeItemOnce(arr, value) {
      const index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    getCustomerSegmentDetail(segment_id) {
      const payload = {
        URL:
          this.$services.endpoints.SEGMENT_ENDPOINT + `customers/${segment_id}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showSegmentName = res.data.name;
          this.invoiceConfigForm.customers_segment = segment_id;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .configuration-showcase ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.content .configuration-showcase ul li.active {
  pointer-events: none;
}

.content .configuration-showcase ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.content .configuration-showcase ul li:first-of-type a {
  margin-right: 10px;
  padding-right: 10px;
}

.content .configuration-showcase ul li:last-of-type a {
  margin-left: 10px;
  padding-left: 10px;
}

.content .configuration-showcase ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.content .box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content div ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  cursor: pointer;
}

.link-click {
  color: #5855d6;
  text-decoration: none;
}

.content div ~ a:hover {
  color: #4946ae;
  border-color: #4946ae;
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px 0px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .box form .desc p {
  margin-bottom: 5px;
  color: #27364b;
  font-size: 14px;
}

.content .box form .row p {
  margin-bottom: 5px;
  color: #27364b;
  font-size: 14px;
}

.content .box form .header {
  color: #333269 !important;
}

.content .box form h6 {
  font-size: 0.8rem !important;
}

.content .header,
.fs-8 {
  color: #27364b;
}

.content .box form .desc span {
  margin-bottom: 5px;
  color: #27364b;
  font-size: 13px;
}

.content .box form .desc label {
  margin-bottom: 10px;
  color: #27364b;
  font-size: 14px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 132px;
  text-indent: 10px;
  padding-right: 5px;
}

.unset-box {
  box-shadow: unset !important;
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input.checkbox {
  width: 43px;
  height: 15px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 132px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.input-search:focus {
  outline: none;
}
.content {
  padding: 90px 30px 10px !important;
}
.col-flex-wrap {
  align-items: flex-start;
  justify-content: flex-start;
}
.col-flex-wrap .desc {
  flex: none !important;
}
</style>

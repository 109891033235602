<template>
  <div class="col responsive-width bg-light" style="min-height: 100vh">
    <div class="content" style="margin-top: 10px">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h4>Statistics</h4>
          </div>
        </div>

        <div class="headers d-flex flex-wrap justify-content-center">
          <div class="stats">
            <ul
              class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center"
            >
              <li
                v-for="(tab, i) in tabs"
                :key="i"
                :class="selected == tab ? 'active' : ''"
              >
                <a @click="switchTab(tab)" href="javascript:void(0);">{{
                  $t(tab)
                }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <contract-statistics v-if="selected == 'contracts'" />
          <customer-statistics v-if="selected == 'customers'" />
          <invoice-statistics v-if="selected == 'invoices'" />
          <product-statistics v-if="selected == 'products'" />
          <general-statistics v-if="selected == 'general'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractStatistics from "./ContractStatistics.vue";
import CustomerStatistics from "./CustomerStatistics.vue";
import InvoiceStatistics from "./InvoiceStatistics.vue";
import ProductStatistics from "./ProductStatistics.vue";
import GeneralStatistics from "./GeneralStatistics.vue";
export default {
  name: "Statistics",

  data() {
    return {
      selected: "contracts",
      tabs: ["contracts", "customers", "invoices", "products"],  //, "general"],
    };
  },

  components: {
    ContractStatistics,
    CustomerStatistics,
    InvoiceStatistics,
    ProductStatistics,
    GeneralStatistics,
  },

  methods: {
    switchTab(tab) {
      this.selected = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 10px 0 10px;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .stats ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.content .stats ul li.active {
  pointer-events: none;
}

.content .stats ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.content .stats ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.content .box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content div ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  cursor: pointer;
}

.link-click {
  color: #5855d6;
  text-decoration: none;
}

.content div ~ a:hover {
  color: #4946ae;
  border-color: #4946ae;
}

.content .box form > div .desc:last-of-type {
  margin-right: 0;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 7px 0px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.content .box form .desc p {
  margin-bottom: 5px;
  color: #27364b;
  font-size: 14px;
}

.content .box form .row p {
  margin-bottom: 5px;
  color: #27364b;
  font-size: 14px;
}

.content .box form .header {
  color: #333269 !important;
}

.content .box form h6 {
  font-size: 0.8rem !important;
}

.content .header,
.fs-8 {
  color: #27364b;
}

.content .box form .desc span {
  margin-bottom: 5px;
  color: #27364b;
  font-size: 13px;
}

.content .box form .desc label {
  margin-bottom: 10px;
  color: #27364b;
  font-size: 14px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 132px;
  text-indent: 10px;
  padding-right: 5px;
}

.unset-box {
  box-shadow: unset !important;
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input.checkbox {
  width: 43px;
  height: 15px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 132px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.input-search:focus {
  outline: none;
}
.content {
  padding: 90px 30px 10px !important;
}
.col-flex-wrap {
  align-items: flex-start;
  justify-content: flex-start;
}
.col-flex-wrap .desc {
  flex: none !important;
}
</style>

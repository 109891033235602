<template>
  <header>
    <div class="container">
      <router-link to="/" class="logo">
        <img src="./../../../assets/likvio_logo.png" alt="" />
      </router-link>
      <nav>
        <div class="bubble" id="profile-locale">
          <span class="flag-image-box">
            <img
              v-if="locale === 'en'"
              src="./../../../assets/images/flags/gb.svg"
              alt="British flag"
            />
            <img
              v-if="locale === 'se'"
              src="./../../../assets/images/flags/se.svg"
              alt="British flag"
            />
          </span>
          <!-- <svg width="4" height="15" viewbox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="1.6649" cy="1.49619" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
              <ellipse cx="1.6649" cy="7.48057" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
              <ellipse cx="1.6649" cy="13.4659" rx="1.5023" ry="1.49619" fill="#9592A6"></ellipse>
          </svg> -->
          <nav id="locale-menu">
            <a @click="changeLocale('en')">
              <span
                ><img
                  class="me-2"
                  src="./../../../assets/images/flags/4x3/GB.svg"
                  alt="British flag"
                />English</span
              >
            </a>
            <a @click="changeLocale('se')">
              <span
                ><img
                  class="me-2"
                  src="./../../../assets/images/flags/4x3/SE.svg"
                  alt="Swedish flag"
                />Swedish</span
              >
            </a>
          </nav>
        </div>
        <ul class="menu">
          <li>
            <router-link to="/"> Hem </router-link>
          </li>
          <li>
            <a
              class="links"
              href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fintegration-partner%2Fapp-market%2Fautomatisera-mera%2Flikvio"
              >Kom igång</a
            >
          </li>

          <li>
            <router-link :to="{ name: 'login' }" class="buttons"
              >Logga in</router-link
            >
          </li>
        </ul>
        <a href="#" class="menu-trigger">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  mounted() {
    this.initializeLocaleDropdown();
  },

  beforeUnmount() {
    this.clearEventListeners();
  },

  data() {
    return {
      locale: "en",
    };
  },

  computed: {},

  methods: {
    initializeLocaleDropdown() {
      const profileLocale = document.getElementById("profile-locale");
      const localMenu = document.getElementById("locale-menu");
      profileLocale.addEventListener("click", function (e) {
        localMenu.classList.toggle("active");
      });
    },

    clearEventListeners() {
      const profileLocale = document.getElementById("profile-locale");
      document.removeEventListener("click", profileLocale);
    },

    changeLocale(locale) {
      this.locale = locale;
      this.$emit("change-locale", locale);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
header {
  padding: 10px 0;
  position: relative;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  header {
    padding: 25px 0;
  }
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .container .logo img {
  height: 45px;
  position: relative;
  z-index: 2;
}
@media (max-width: 2000px) {
  header .container .logo img {
    height: 60px;
  }
}
header .container .menu {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  header .container .menu {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #045e62;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 300ms;
    z-index: 2;
  }
  header .container .menu.active {
    opacity: 1;
    visibility: visible;
  }
}
header .container .menu li {
  margin-left: 45px;
  position: relative;
}
@media (max-width: 992px) {
  header .container .menu li {
    margin-left: 0;
    margin-bottom: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header .container .menu li {
    margin-left: 30px;
  }
}
header .container .menu li.current-menu-item::before {
  width: 100%;
}
header .container .menu li::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #1a232d;
  width: 100%;
  height: 1px;
  z-index: -1;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.5s;
}

@media (min-width: 992px) and (max-width: 1199px) {
  header .container .menu li a {
    font-size: 13px;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.buttons {
  width: 150px;
  height: 45px;
  border: 1px solid #3840f5;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3840f5;
  transition: 0.5s;
  /* margin: 0px 10px; */
}

.links {
  /* margin: 0px 10px; */
  color: #3840f5;
}

.bubble {
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  margin-top: -5px;
}

.bubble nav {
  padding: 10px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: -101px;
  width: 140px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 5px rgba(9, 30, 66, 0.2),
    0px 1px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 5px 5px rgba(9, 30, 66, 0.2),
    0px 1px 1px rgba(9, 30, 66, 0.31);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 33;
  min-height: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}

.bubble nav.active {
  visibility: visible;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.bubble nav a {
  position: relative;
  text-decoration: none;
  color: #0a043c;
}

.bubble nav a:hover:before,
.bubble nav a:hover:after {
  width: 100%;
}

.bubble nav a span {
  position: relative;
  z-index: 3;
  padding: 10px 10px;
  padding: 5px 10px;
  display: block;
  font-size: 12px;
  opacity: 0.7;
}

.bubble nav a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0;
  background-color: #e3eefc;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.bubble nav a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #f8fbff;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0.4;
}

.bubble nav a:not(:last-of-type) {
  margin-bottom: 10px;
}

.flag-image-box {
  display: block;
  width: 31px;
  height: 31px;
}

.flag-image-box img {
  border-radius: 50%;
}
</style>

export const customerData = {
    name: '',
    customer_number: '',
    default_delivery_types: {
      invoice: 'PRINT',
      offer: 'PRINT',
      order: 'PRINT'
    },
    default_templates: {
      cash_invoice: '',
      invoice: '',
      offer: '',
      order: ''
    },
    // tags: [],
    active: true,
    address1: '',
    address2: '',
    city: '',
    comments: '',
    cost_center: '',
    country: '',
    country_code: '',
    currency: '',
    delivery_address1: '',
    delivery_address2: '',
    delivery_city: '',
    delivery_country: '',
    delivery_country_code: '',
    delivery_fax: '',
    delivery_name: '',
    delivery_phone1: '',
    delivery_phone2: '',
    delivery_zip_code: '',
    email: '',
    email_invoice: '',
    email_invoice_bcc: '',
    email_invoice_cc: '',
    email_offer: '',
    email_offer_bcc: '',
    email_offer_cc: '',
    email_order: '',
    email_order_bcc: '',
    email_order_cc: '',
    external_reference: '',
    fax: '',
    gln: '',
    gln_delivery: '',
    invoice_administration_fee: 0,
    invoice_discount: 0,
    invoice_freight: 0,
    invoice_remark: '',
    organisation_number: '',
    our_reference: '',
    phone1: '',
    phone2: '',
    price_list: '',
    project: '',
    sales_account: '',
    show_price_vat_included: true,
    terms_of_delivery: '',
    terms_of_payment: '',
    type: 'PRIVATE',
    vat_number: '',
    vat_type: 'SEVAT',
    visiting_address: '',
    visiting_city: '',
    visiting_country: '',
    visiting_country_code: '',
    visiting_zip_code: '',
    www: '',
    way_of_delivery: '',
    your_reference: '',
    zip_code: ''
}
export default { customerData }

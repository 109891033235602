<template>
  <div>
    <!-- <shimmer-loading v-if="false" :num="10"></shimmer-loading> -->
    <div class="row">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("amount_billed") }}
                </h2>

                <div
                  class="d-flex flex-row align-items-center justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showBillingPeriodSelector = !showBillingPeriodSelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showBillingPeriodSelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border amount-dropdown"
                    >
                      <ul style="list-style-type: none">
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseBillingPeriod('monthly')"
                        >
                          {{ $t("monthly") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseBillingPeriod('quarterly')"
                        >
                          {{ $t("quarterly") }}
                        </li>

                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseBillingPeriod('semiannually')"
                        >
                          {{ $t("semi_annually") }}
                        </li>

                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseBillingPeriod('annually')"
                        >
                          {{ $t("yearly") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h2>
                    {{
                      billedPeriod == "monthly"
                        ? "Månadsvis"
                        : billedPeriod == "quarterly"
                        ? "3 Månader"
                        : billedPeriod == "semiannually"
                        ? "6 Månader"
                        : "12 Månader"
                    }}
                  </h2>
                </div>

                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="billed_vat_included"
                      type="radio"
                      :value="true"
                      name="sales_vat"
                      id="sales_vat-yes"
                    />
                    <label for="sales_vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="billed_vat_included"
                      type="radio"
                      :value="false"
                      name="sales_vat"
                      id="sales_vat-no"
                    />
                    <label for="sales_vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>

                <div style="position: relative">
                  <span
                    @click="showAmountBilledInfo = !showAmountBilledInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showAmountBilledInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.amountBilled.eng
                          : infoMessages.amountBilled.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading
                v-if="loading || salesLoading"
                :num="6"
              ></shimmer-loading>
              <div v-else>
                <div v-if="billedPeriod == 'monthly'">
                  <apexchart
                    v-if="billed_vat_included"
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.monthly_billed_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.monthly_billed_ex_vat_series"
                  ></apexchart>
                </div>

                <div v-else-if="billedPeriod == 'quarterly'">
                  <apexchart
                    v-if="billed_vat_included"
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.quarterly_billed_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.quarterly_billed_ex_vat_series"
                  ></apexchart>
                </div>

                <div v-else-if="billedPeriod == 'semiannually'">
                  <apexchart
                    v-if="billed_vat_included"
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.semiannually_billed_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="
                      billedInvoiceGraph.semiannually_billed_ex_vat_series
                    "
                  ></apexchart>
                </div>

                <div v-else>
                  <apexchart
                    v-if="billed_vat_included"
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.annually_billed_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="billedInvoiceGraph.options"
                    :series="billedInvoiceGraph.annually_billed_ex_vat_series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <div
                  class="d-flex flex-row align-items-center justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showReceivedPaymentsPeriodSelector =
                          !showReceivedPaymentsPeriodSelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showReceivedPaymentsPeriodSelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseReceivedPeriodSelector('monthly')"
                        >
                          {{ $t("monthly") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseReceivedPeriodSelector('quarterly')"
                        >
                          {{ $t("quarterly") }}
                        </li>

                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseReceivedPeriodSelector('semiannually')"
                        >
                          {{ $t("semi_annually") }}
                        </li>

                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseReceivedPeriodSelector('annually')"
                        >
                          {{ $t("yearly") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h2>
                    {{
                      receivedPeriod == "monthly"
                        ? "Månadsvis"
                        : receivedPeriod == "quarterly"
                        ? "3 Månader"
                        : receivedPeriod == "semiannually"
                        ? "6 Månader"
                        : "12 Månader"
                    }}
                  </h2>
                </div>

                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("payments_received")}}
                </h2>

                <div style="position: relative">
                  <span
                    @click="
                      showPaymentsReceivedInfo = !showPaymentsReceivedInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showPaymentsReceivedInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.paymentsReceived.eng
                          : infoMessages.paymentsReceived.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading
                v-if="loading || salesLoading"
                :num="6"
              ></shimmer-loading>
              <div v-else>
                <apexchart
                  v-if="receivedPeriod == 'monthly'"
                  type="line"
                  :options="amountReceivedGraph.options"
                  :series="amountReceivedGraph.monthly_received_series"
                ></apexchart>
                <apexchart
                  v-if="receivedPeriod == 'quarterly'"
                  type="line"
                  :options="amountReceivedGraph.options"
                  :series="amountReceivedGraph.quarterly_received_series"
                ></apexchart>
                <apexchart
                  v-if="receivedPeriod == 'semiannually'"
                  type="line"
                  :options="amountReceivedGraph.options"
                  :series="amountReceivedGraph.semiannually_received_series"
                ></apexchart>
                <apexchart
                  v-if="receivedPeriod == 'annually'"
                  type="line"
                  :options="amountReceivedGraph.options"
                  :series="amountReceivedGraph.annually_received_series"
                ></apexchart>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("lost_revenue") }} (12 {{ $t("months") }})
                </h2>
                <div style="position: relative">
                  <span
                    @click="showLostRevenueInfo = !showLostRevenueInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showLostRevenueInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.lostRevenue.eng
                          : infoMessages.lostRevenue.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else
                type="bar"
                :options="lostRevenueGraph.options"
                :series="lostRevenueGraph.series"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px">
                  {{ $t("overdue_unpaid_invoices") }} ({{ $t("current") + " " + $t("month").toLowerCase() }})
                </h2>
                <div style="position: relative">
                  <span
                    @click="showOverdueInfo = !showOverdueInfo"
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showOverdueInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.overdue.eng
                          : infoMessages.overdue.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else
                type="bar"
                :options="overdueUnpaidGraph.options"
                :series="overdueUnpaidGraph.series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShimmerLoading from "../reusableComponents/ShimmerLoading";

export default {
  name: "ContractStatistics",
  components: {
    ShimmerLoading,
  },

  async created() {
    this.getBilledInvoiceData();
    this.getReceivedPaymentsData();
    this.getLostRevenueData();
    this.getUnpaidOverdueData();
  },

  computed: {
    ...mapGetters({
      locale: "locale/GET_LOCALE",
    }),
  },

  data() {
    return {
      showAmountBilledInfo: false,
      showPaymentsReceivedInfo: false,
      showLostRevenueInfo: false,
      showOverdueInfo: false,

      infoMessages: {
        amountBilled: {},
        paymentsReceived: {},
        lostRevenue: {},
        overdue: {},
      },

      billedCategories: {
        monthly_billed_categories: [],
        quarterly_billed_categories: [],
        semiannually_billed_categories: [],
        annually_billed_categories: [],
      },

      receivedCategories: {
        monthly_received_categories: [],
        quarterly_received_categories: [],
        semiannually_received_categories: [],
        annually_received_categories: [],
      },

      billedPeriod: "monthly",

      receivedPeriod: "monthly",

      showBillingPeriodSelector: false,

      showReceivedPaymentsPeriodSelector: false,

      loading: false,

      billed_vat_included: true,

      billedInvoiceGraph: {
        options: {
          chart: {
            id: "billed-invoice-graph",
          },
          xaxis: {
            title: {
              text: this.$t("period"),
            },
            categories: [],
          },

          yaxis: {
            title: {
              text: this.$t("amount_billed"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          stroke: {
            width: 1.5,
            curve: "straight",
          },

          markers: {
            size: 5,
          },

          colors: ["#FFF825"],

          dataLabels: {
            enabled: false,
          },
        },

        monthly_billed_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],

        monthly_billed_ex_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],

        quarterly_billed_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],

        quarterly_billed_ex_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],

        semiannually_billed_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        semiannually_billed_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        annually_billed_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],

        annually_billed_ex_vat_series: [
          {
            name: this.$t("billed"),
            type: "line",
            data: [],
          },
        ],
      },

      amountReceivedGraph: {
        options: {
          chart: {
            id: "amount-received-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("period"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("amount_received"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          stroke: {
            width: 1.5,
            curve: "straight",
          },

          markers: {
            size: 5,
          },

          dataLabels: {
            enabled: false,
          },
        },
        monthly_received_series: [
          {
            name: this.$t("received"),
            type: "line",
            data: [],
          },
        ],
        quarterly_received_series: [
          {
            name: this.$t("received"),
            type: "line",
            data: [],
          },
        ],
        semiannually_received_series: [
          {
            name: this.$t("received"),
            type: "line",
            data: [],
          },
        ],
        annually_received_series: [
          {
            name: this.$t("received"),
            type: "line",
            data: [],
          },
        ],
      },

      lostRevenueGraph: {
        options: {
          chart: {
            id: "lost-revenue-graph",
          },

          xaxis: {
            categories: [],
            title: {
              text: this.$t("months"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("amount_billed"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          colors: ["#00FF93"],

          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "55%",
            },
          },

          dataLabels: {
            enabled: false,
          },
        },
        series: [
          {
            name: this.$t("lost_revenue"),
            type: "bar",
            data: [],
          },
        ],
      },

      overdueUnpaidGraph: {
        options: {
          chart: {
            id: "unpaid-overdue-graph",
            type: "bar",
          },

          xaxis: {
            categories: [],
            title: {
              text: this.$t("amount"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          yaxis: {
            title: {
              text: `${this.$t("days_since_due_date")}`,
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          colors: ["#FFBF00"],

          plotOptions: {
            bar: {
              horizontal: true,
            },
          },

          dataLabels: {
            enabled: false,
            hideOverflowingLabels: true,
          },
        },
        series: [
          {
            name: this.$t("invoice") + " " + this.$t("amount"),
            type: "bar",
            data: [],
          },
        ],
      },
    };
  },

  mounted() {
    this.addFontAwesomeToHead();
  },

  methods: {
    async getBilledInvoiceData() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getBilledInvoiceStatistics"
      );
      this.loading = false;
      this.infoMessages.amountBilled.eng = res.data.eng_info;
      this.infoMessages.amountBilled.swe = res.data.swe_info;
      const data = res.data.data;

      const monthly_billed_categories = [];
      const quarterly_billed_categories = [];
      const semiannually_billed_categories = [];
      const annually_billed_categories = [];

      const monthly_billed_vat_series = [];
      const monthly_billed_ex_vat_series = [];
      const quarterly_billed_vat_series = [];
      const quarterly_billed_ex_vat_series = [];
      const semiannually_billed_vat_series = [];
      const semiannually_billed_ex_vat_series = [];
      const annually_billed_vat_series = [];
      const annually_billed_ex_vat_series = [];

      for (const key in data) {
        for (const data_key in data[key]) {
          if (key == "monthly") {
            monthly_billed_categories.push(data_key);
            monthly_billed_vat_series.push(
              data[key][data_key].total_amount_inc_vat
            );
            monthly_billed_ex_vat_series.push(
              data[key][data_key].total_amount_ex_vat
            );
          } else if (key == "quarterly") {
            quarterly_billed_categories.push(data_key);
            quarterly_billed_vat_series.push(
              data[key][data_key].total_amount_inc_vat
            );
            quarterly_billed_ex_vat_series.push(
              data[key][data_key].total_amount_ex_vat
            );
          } else if (key == "semi_annual") {
            semiannually_billed_categories.push(data_key);
            semiannually_billed_vat_series.push(
              data[key][data_key].total_amount_inc_vat
            );
            semiannually_billed_ex_vat_series.push(
              data[key][data_key].total_amount_ex_vat
            );
          } else if (key == "annual") {
            annually_billed_categories.push(data_key);
            annually_billed_vat_series.push(
              data[key][data_key].total_amount_inc_vat
            );
            annually_billed_ex_vat_series.push(
              data[key][data_key].total_amount_ex_vat
            );
          }
        }
      }

      this.billedCategories = {
        monthly_billed_categories,
        quarterly_billed_categories,
        semiannually_billed_categories,
        annually_billed_categories,
      };

      this.billedInvoiceGraph.options = {
        ...this.billedInvoiceGraph.options,
        xaxis: {
          ...this.billedInvoiceGraph.options.xaxis,
          type: "category",
          categories: monthly_billed_categories,
        },
      };

      this.billedInvoiceGraph.monthly_billed_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: monthly_billed_vat_series,
        },
      ];

      this.billedInvoiceGraph.monthly_billed_ex_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: monthly_billed_ex_vat_series,
        },
      ];

      this.billedInvoiceGraph.quarterly_billed_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: quarterly_billed_vat_series,
        },
      ];

      this.billedInvoiceGraph.quarterly_billed_ex_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: quarterly_billed_ex_vat_series,
        },
      ];

      this.billedInvoiceGraph.semiannually_billed_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: semiannually_billed_vat_series,
        },
      ];

      this.billedInvoiceGraph.semiannually_billed_ex_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: semiannually_billed_ex_vat_series,
        },
      ];

      this.billedInvoiceGraph.annually_billed_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: annually_billed_vat_series,
        },
      ];

      this.billedInvoiceGraph.annually_billed_ex_vat_series = [
        {
          name: this.$t("billed"),
          type: "line",
          data: annually_billed_ex_vat_series,
        },
      ];
    },

    async getReceivedPaymentsData() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getReceivedPaymentsStatistics"
      );
      this.loading = false;
      this.infoMessages.paymentsReceived.eng = res.data.eng_info;
      this.infoMessages.paymentsReceived.swe = res.data.swe_info;
      const data = res.data.data;

      const monthly_received_categories = [];
      const quarterly_received_categories = [];
      const semiannually_received_categories = [];
      const annually_received_categories = [];

      const monthly_received_series = [];
      const quarterly_received_series = [];
      const semiannually_received_series = [];
      const annually_received_series = [];

      for (const key in data) {
        for (const data_key in data[key]) {
          if (key == "monthly") {
            monthly_received_categories.push(data_key);
            const val = data[key][data_key].total_amount_inc_vat;

            monthly_received_series.push(val);
          } else if (key == "quarterly") {
            quarterly_received_categories.push(data_key);
            const val = data[key][data_key].total_amount_inc_vat;

            quarterly_received_series.push(val);
          } else if (key == "semi_annual") {
            semiannually_received_categories.push(data_key);
            const val = data[key][data_key].total_amount_inc_vat;

            semiannually_received_series.push(val);
          } else if (key == "annual") {
            annually_received_categories.push(data_key);
            const val = data[key][data_key].total_amount_inc_vat;

            annually_received_series.push(val);
          }
        }
      }

      this.receivedCategories = {
        monthly_received_categories,
        quarterly_received_categories,
        semiannually_received_categories,
        annually_received_categories,
      };

      this.amountReceivedGraph.options = {
        ...this.amountReceivedGraph.options,
        xaxis: {
          ...this.amountReceivedGraph.options.xaxis,
          type: "category",
          categories: monthly_received_categories,
        },
      };

      this.amountReceivedGraph.monthly_received_series = [
        {
          name: this.$t("received"),
          type: "line",
          data: monthly_received_series,
        },
      ];

      this.amountReceivedGraph.quarterly_received_series = [
        {
          name: this.$t("received"),
          type: "line",
          data: quarterly_received_series,
        },
      ];

      this.amountReceivedGraph.semiannually_received_series = [
        {
          name: this.$t("received"),
          type: "line",
          data: semiannually_received_series,
        },
      ];

      this.amountReceivedGraph.annually_received_series = [
        {
          name: this.$t("received"),
          type: "line",
          data: annually_received_series,
        },
      ];
    },

    async getLostRevenueData() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getLostRevenueStatistics"
      );
      this.loading = false;
      this.infoMessages.lostRevenue.eng = res.data.eng_info;
      this.infoMessages.lostRevenue.swe = res.data.swe_info;

      const categories = [];
      const series = [];

      for (const key in res.data.data) {
        categories.push(key);
        series.push(res.data.data[key].balance);
      }

      this.lostRevenueGraph.options = {
        ...this.lostRevenueGraph.options,
        xaxis: {
          ...this.lostRevenueGraph.options.xaxis,
          type: "category",
          categories: categories,
        },
      };

      this.lostRevenueGraph.series[0].data = series;
    },

    async getUnpaidOverdueData() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getUnpaidOverdueStatistics"
      );
      this.loading = false;
      this.infoMessages.overdue.eng = res.data.eng_info;
      this.infoMessages.overdue.swe = res.data.swe_info;

      const categories = [];
      const series = [];

      for (const key in res.data.data) {
        categories.push(key);
        series.push(res.data.data[key].Total_invoice_amount);
      }

      this.overdueUnpaidGraph.options = {
        ...this.overdueUnpaidGraph.options,
        xaxis: {
          ...this.overdueUnpaidGraph.options.xaxis,
          type: "category",
          categories: categories.reverse(),
        },
      };

      this.overdueUnpaidGraph.series[0].data = series.reverse();
    },

    fixDecimals(value) {
      return value.toFixed(2);
    },

    chooseBillingPeriod(period) {
      this.billedPeriod = period;
      this.showBillingPeriodSelector = false;
    },

    chooseReceivedPeriodSelector(period) {
      this.receivedPeriod = period;
      this.showReceivedPaymentsPeriodSelector = false;
    },

    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },
  },

  watch: {
    billedPeriod(val) {
      if (val == "monthly") {
        this.billedInvoiceGraph.options = {
          ...this.billedInvoiceGraph.options,
          xaxis: {
            ...this.billedInvoiceGraph.options.xaxis,
            type: "category",
            categories: this.billedCategories.monthly_billed_categories,
          },
        };
      } else if (val == "quarterly") {
        this.billedInvoiceGraph.options = {
          ...this.billedInvoiceGraph.options,
          xaxis: {
            ...this.billedInvoiceGraph.options.xaxis,
            type: "category",
            categories: this.billedCategories.quarterly_billed_categories,
          },
        };
      } else if (val == "semiannually") {
        this.billedInvoiceGraph.options = {
          ...this.billedInvoiceGraph.options,
          xaxis: {
            ...this.billedInvoiceGraph.options.xaxis,
            type: "category",
            categories: this.billedCategories.semiannually_billed_categories,
          },
        };
      } else if (val == "annually") {
        this.billedInvoiceGraph.options = {
          ...this.billedInvoiceGraph.options,
          xaxis: {
            ...this.billedInvoiceGraph.options.xaxis,
            type: "category",
            categories: this.billedCategories.annually_billed_categories,
          },
        };
      }
    },

    receivedPeriod(val) {
      if (val == "monthly") {
        this.amountReceivedGraph.options = {
          ...this.amountReceivedGraph.options,
          xaxis: {
            ...this.amountReceivedGraph.options.xaxis,
            type: "category",
            categories: this.receivedCategories.monthly_received_categories,
          },
        };
      } else if (val == "quarterly") {
        this.amountReceivedGraph.options = {
          ...this.amountReceivedGraph.options,
          xaxis: {
            ...this.amountReceivedGraph.options.xaxis,
            type: "category",
            categories: this.receivedCategories.quarterly_received_categories,
          },
        };
      } else if (val == "semiannually") {
        this.amountReceivedGraph.options = {
          ...this.amountReceivedGraph.options,
          xaxis: {
            ...this.amountReceivedGraph.options.xaxis,
            type: "category",
            categories:
              this.receivedCategories.semiannually_received_categories,
          },
        };
      } else if (val == "annually") {
        this.amountReceivedGraph.options = {
          ...this.amountReceivedGraph.options,
          xaxis: {
            ...this.amountReceivedGraph.options.xaxis,
            type: "category",
            categories: this.receivedCategories.annually_received_categories,
          },
        };
      }
    },
  },
};
</script>

<style scoped>
.desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;
  /* line-height: 14px; */
  color: #000000;
  width: min-content;
}

.desc label {
  margin-bottom: 2px;
  font-size: 11px;
}

.desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.desc .radio input {
  display: none;
}

.desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.desc .radio label:first-of-type {
  margin-right: 10px;
}

.box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
}

.box h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e1566;
  margin-bottom: 0;
}

.box h6 {
  font-weight: 400;
  font-size: 14px;
  color: #0e1566;
}

.amount-dropdown {
  right: 0;
}
#sales-for-selector > .amount-dropdown {
  right: auto;
  left: 0;
}
.amount-dropdown .text-xs {
  font-size: 11px !important;
  line-height: 120%;
  padding: 2px 0;
}
.amount-dropdown ul {
  margin-bottom: 0 !important;
}
.amount-dropdown ul li:last-child {
  border: 0 !important;
}

input.filter-input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}
</style>

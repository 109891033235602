<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="headers d-flex">
          <h1>{{ $t('videoGuides') }}</h1>

        </div>
        <div class="">
          <div
              v-if="loading"
              class="d-flex justify-content-center"
          >
            <div class="loader">{{ $t("loading") }}...</div>
          </div>
          <div class="row" v-else id="el-video">
            <div class="col-md-4" v-for="(video,i) in videoSources.results" :key="i">
              <div class="bg-white shadow-lg border rounded">
                <div class="player-container">
                  <video-player :options="video.options"/>
                </div>
                <div class="p-2">
                  <p class="text-base lg:text-xl">
                    {{ video[getTitle] }}
                  </p>
                  <p class="text-sm">
                    {{ video[getDescription] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="actions d-flex align-items-center">
            <!--          <label>{{ $t("actions") }} </label>-->
            <div class="d-flex justify-content-end w-full">
              <!--              <div style="margin-right: 20px">-->
              <!--                <p class="list-summary">-->
              <!--                  <select-->
              <!--                      v-model="scheduleAddon.limit"-->
              <!--                      v-on:change="onChange"-->
              <!--                      style="width: 54px"-->
              <!--                  >-->
              <!--                    <option value="50">50</option>-->
              <!--                    <option value="100">100</option>-->
              <!--                    <option value="200">200</option>-->
              <!--                  </select>-->
              <!--                  {{ $t("of") }} {{ scheduleAddon.total_resources }}-->
              <!--                </p>-->
              <!--              </div>-->
              <v-pagination
                  v-model="addon.page"
                  :pages="addon.total_page"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="changePageNumber"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import videoPlayer from "../../components/videoPlayer";
import 'video.js/dist/video-js.css'
import {mapGetters} from "vuex"
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "videoGuides",
  components: {
    videoPlayer, VPagination
  },
  data: function () {
    return {
      videoList: [{
        options: {
          sources: [
            {
              src: 'https://staging.golfigo.se/media/videos/configuration.mp4',
              type: 'video/mp4'
            }
          ],
          autoplay: true,
          controls: true,

        },

        title: "Sample text",
        content: "I love coding"
      }],
      videoSources: {
        results: []
      },
      addon: {
        page: 1,
        total_page: 1,
        limit: 12,
      },
      loading: true
    }
  },
  methods: {
    getUserSubscription() {
      this.$store
          .dispatch("clients/getUserSubscription")
          .then((res) => {
            this.getPackageInfo();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async getPackageInfo() {
      const payload = {
        URL:
            this.$services.endpoints.PACKAGE_ENDPOINT +
            `?package=${this.GET_USER_SUBSCRIPTION[0].current_package.toLowerCase()}`,
      };
      if (!this.GET_PACKAGE.dashboard_page_access) {
        await this.$store.dispatch("clients/getPlanDetails", payload);
      }
      if (
          !this.GET_PACKAGE.dashboard_page_access.includes(this.$route.name) &&
          this.$route.name !== "planDetails" &&
          this.$route.name !== "faq" //&&
          //this.$route.name !== "support"
      ) {
        this.notFound = true;
        return;
      }
    },
    getVideos: function () {
      this.loading = true
      this.$store.dispatch('clients/getVideoGuide', '?limit=12').then(resp => {
        let results = []
        resp.data.results.map(item => {
          let el = {
            autoplay: false,
            controls: true,
            responsive: true,
            sources: [
              {
                src: item.file_url,
                type: 'video/mp4'
              }
            ]
          }
          let dt = {
            ...item,
            options: el
          }
          results.push(dt)
        })
        this.videoSources.results = results
        this.computePageNation(resp)
        this.loading = false
        setTimeout(function () {
          let player = document.getElementById('el-video')
          if (player) {
            if (player.addEventListener) {
              player.addEventListener('contextmenu', function (e) {
                e.preventDefault();
              }, false);
            } else {
              player.attachEvent('oncontextmenu', function () {
                window.event.returnValue = false;
              });
            }
          }
        }, 500)
      }).catch(() => {
        this.loading = false
      })
    },
    changePageNumber: function (number) {
      this.$store.dispatch('clients/getVideoGuide', `?limit=12=page=${number}`).then(resp => {
        let results = []
        resp.data.results.map(item => {
          let el = {
            autoplay: false,
            controls: true,
            responsive: true,
            sources: [
              {
                src: item.file_url,
                type: 'video/mp4'
              }
            ]
          }
          let dt = {
            ...item,
            options: el
          }
          results.push(dt)
        })
        this.videoSources.results = results
        this.computePageNation(resp)

      })
    },
    computePageNation: function (res) {
      if (res.data.count > this.addon.limit) {
        this.addon.total_page = Math.ceil(res.data.count / this.addon.limit)
      } else {
        this.addon.total_page = 1
      }
    },
  },
  mounted() {
    this.getUserSubscription()
    this.getVideos()


  },
  computed: {
    ...mapGetters({
      GET_LOCALE: 'locale/GET_LOCALE',
      GET_PACKAGE: "clients/GET_PLAN_DETAIL",
      GET_USER_SUBSCRIPTION: "clients/GET_USER_SUBSCRIPTION",
    }),
    getTitle: function () {
      return this.GET_LOCALE === 'se' ? 'swe_title' : 'eng_title'
    },
    getDescription: function () {
      return this.GET_LOCALE === 'se' ? 'swe_description' : 'eng_description'
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
  margin-bottom: 20px;
}

.content .headers h1 ~ p {
  font-size: 18px;
  line-height: 28px;
  color: #333269;
}

.content .headers h1 ~ p a {
  text-decoration: none;
  color: #5855d6;
  -webkit-transition: color 0.4s ease-out;
  -o-transition: color 0.4s ease-out;
  transition: color 0.4s ease-out;
}

.content .headers h1 ~ p a:hover {
  color: #4946ae;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
}

.content .box .box-header {
  background: #5855d6;
  padding: 15px 30px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box .box-header h3 {
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  margin: 0;
}

.content .box .box-header a {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  color: #5855d6;
  background: #fffdfd;
  padding: 12px 16px;
  border: 1px solid #fffdfd;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box .box-header a:hover {
  color: #4946ae;
  background-color: #fbfafb;
  border-color: #fbfafb;
}

.content .box table {
  width: 100%;
  text-align: center;
  min-width: 970px;
}

.content .box table .checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content .box table .checkbox input {
  border: 0.978073px solid #64748b;
  border-radius: 3.91229px;
  height: 15px;
  width: 15px;
  position: relative;
  z-index: 33;
  background: #fffbfb;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table .checkbox input:checked {
  border: none;
  background: transparent;
}

.content .box table .checkbox input,
.content .box table .checkbox input:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

.content .box table .checkbox input:checked ~ svg {
  display: block;
}

.content .box table .checkbox svg {
  position: absolute;
  height: 17px;
  width: 17px;
  display: none;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.content .box table thead th {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.content .box table thead th:first-of-type {
  padding-left: 7px;
}

.content .box table thead th:last-of-type {
  padding-right: 7px;
}

.content .box table tbody tr:nth-child(odd) {
  background: #f6f8fc;
}

.content .box table tbody td {
  font-size: 16px;
  line-height: 24px;
  color: #1d1c40;
  padding: 15px 0;
}

.content .box table tbody td .edit {
  margin-right: 10px;
}

.content .box table tbody td .edit,
.content .box table tbody td .delete {
  cursor: pointer;
}

.content .box table tbody td:first-of-type {
  padding-left: 7px;
}

.content .box table tbody td:last-of-type {
  padding-right: 7px;
}

.users-edit-popup .popup-box h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0e1566;
  padding: 25px 40px 20px;
  border-bottom: 1px solid #cbd4e1;
  margin: 0;
  text-align: center;
}

.users-edit-popup .popup-box .popup-body {
  padding: 30px 40px 40px;
  margin: 0;
}

.users-edit-popup .popup-box .popup-body label {
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.users-edit-popup .popup-box .popup-body label span {
  color: #ff3333;
  font-weight: bold;
}

.users-edit-popup .popup-box .popup-body input,
.users-edit-popup .popup-box .popup-body select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  height: 37.83px;
  color: #0f1a2a;
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100%;
  margin: 10px 0 20px;
  padding: 0 10px;
}

.users-edit-popup .popup-box .popup-body input:focus,
.users-edit-popup .popup-box .popup-body select:focus {
  outline: none;
  border: none;
}

.users-edit-popup .popup-box .popup-bottons {
  text-align: right;
  margin-top: 20px;
}

.users-edit-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}

.users-edit-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-edit-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.users-edit-popup .popup-box .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-edit-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

.users-invite-popup .popup-box .popup-footer {
  position: relative;
}

.users-invite-popup .popup-box .popup-footer img {
  position: absolute;
  left: -30px;
  bottom: 0;
  max-width: 100%;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons {
  text-align: right;
  margin-top: 20px;
  width: -webkit-calc(100% - 92px);
  width: calc(100% - 92px);
  margin-left: auto;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  line-height: 22px;
  border: none;
  margin: 5px 0;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:last-of-type {
  background: #4d82f3;
  color: #ffffff;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.users-invite-popup .popup-box .popup-footer .popup-bottons button:last-of-type:hover {
  background-color: #4947b0;
  color: #fff;
}

.general-user-confirm-popup {
  position: relative;
  display: flex;
  background: rgba(10, 4, 28, 0.36);
}

.general-user-confirm-popup .popup-box {
  min-width: 300px;
  max-width: 90%;
  width: 430px;
  background: #ffffff;
  position: relative;
  z-index: 9;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px 40px 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.general-user-confirm-popup .popup-box h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 25px;
  text-align: center;
}

.general-user-confirm-popup .popup-box .popup-bottons {
  text-align: center;
}

.general-user-confirm-popup .popup-box .popup-bottons button {
  font-weight: normal;
  font-size: 14px;
  border-radius: 4px;
  min-width: 140px;
  height: 32px;
  line-height: 22px;
  border: none;
  margin: 5px 10px;
}

.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type {
  border: 1px solid #64748b;
  color: #64748b;
  background: #fff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.general-user-confirm-popup .popup-box .popup-bottons button:first-of-type:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type {
  background: #419e6a;
  color: #ffffff;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.general-user-confirm-popup .popup-box .popup-bottons button:last-of-type:hover {
  background-color: #1f8c4d;
}

.content {
  padding: 90px 30px 10px !important;
}
</style>
import moment from 'moment'

export const contractObject = {
    email_information: {
        email_address_from: '',
        email_address_to: '',
        email_address_cc: '',
        email_address_bcc: '',
        email_subject: '',
        email_body: ''
    },
    customer_number: '',
    invoice_rows: [],
    period_end: new Date().getDate() === 1 ? moment().add(12, 'd').format('YYYY-MM-DD') : moment(moment().add(1, 'M').startOf('month').format('YYYY-MM-DD')).add(12, 'd').format('YYYY-MM-DD'),
    category: 'Active',
    tags: [],
    active: true,
    administration_fee: '',
    comments: '',
    continuous: false,
    contract_date: moment().format('YYYY-MM-DD'),
    contract_length: 12,
    cost_center: '',
    currency: 'SEK',
    document_number: '',
    external_invoice_reference1: '',
    external_invoice_reference2: '',
    freight: '',
    gross: 0,
    house_work: true,
    invoice_discount: 0,
    invoice_interval: 1,
    language: '',
    our_reference: '',
    period_start: new Date().getDate() === 1 ? moment().format('YYYY-MM-DD') : moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
    price_list: '',
    print_template: '',
    project: '',
    remarks: '',
    status: '',
    tax_reduction_type: '',
    template_number: '',
    terms_of_delivery: '',
    terms_of_payment: '',
    total: 0,
    total_to_pay: 0,
    total_vat: 0,
    vat_included: true,
    way_of_delivery: '',
    your_order_number: '',
    your_reference: '',
    exclude_from_auto_renewal: true
}

export const contractRow = {
    article_number: '',
    delivered_quantity: 1,
    account_number: '',
    cost_center: '',
    description: '',
    discount: '0',
    discount_type: 'AMOUNT',
    house_work: false,
    house_work_hours_to_report: 0,
    house_work_type: '',
    price: 0,
    price_excluding_vat: 0,
    project: '',
    total: 0,
    total_excluding_vat: 0,
    unit: '',
    vat: 25,
    price_per_unit: 0
}

export default {contractObject, contractRow}

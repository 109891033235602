<template>
  <tr>
    <td>
      <span v-if="item.article">
        <input
            v-model="item.article.article_number"
            class="mid"
            disabled
            type="text"
        /></span>
    </td>
    <td>
      <input
          @input="searchPriceListField"
          class=""
          type="text"
          placeholder="search price list"
          :value="selected_pricelist"
      />
      <div
          v-if="showPrice"
          style="z-index: 9999; width: 150px"
          class="position-absolute bg-white rounded shadow-md border p-2"
      >
        <ul style="padding-left: 0.3rem !important; list-style-type: none">
          <li
              class="border-bottom mb-1 cursor-pointer text-xs"
              v-for="item in pricelist.slice(0, 5)"
              :key="item.code"
              disabled
              @click="choosePriceList(item)"
          >
            {{ item.code }} - {{ item.description }}
          </li>
        </ul>
      </div>
      <!--      <select v-model="item.price_list.code" v-if="item.price_list" class="input-select">-->
      <!--        <option :value="item.code" v-for="item in pricelist" :key="item.id">{{ item.description }}</option>-->
      <!--      </select>-->
      <!-- <input class="mid" type="text" :value="item.price_list.code"> -->
    </td>
    <td colspan="2">
      <input
          class="big"
          type="text"
          disabled
          :value="item.price_list.description"
          v-if="item.price_list"
      />
    </td>
    <td>
      <input
          class="mid"
          type="text"
          v-model="item.price"
          v-on:focusout="computeMargin"
      />
    </td>
    <td>
      <input class="mid" type="number" v-model="item.from_quantity" disabled
             readonly/>
    </td>
    <td>
      <span
          class="margin input-select"
          style="padding: 5px"
          v-if="item.article"
      >{{ margin }}</span
      >
    </td>
    <td>
      <div v-if="updating === false">
        <span class="cursor-pointer" @click="updateArticlePrice(item)">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="#5855d6"
          >
            <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
            />
          </svg>
        </span>
        <span
            @click="removeArticlePrice(item.id)"
            class="margin cursor-pointer"
            v-if="isNotSalesPrice"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-danger"
              viewBox="0 0 20 20"
              fill="currentColor"
          >
            <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
            />
          </svg>
        </span>
      </div>
      <icon_loading v-else/>
    </td>
  </tr>
</template>

<script>
import icon_loading from "../../../components/icon_loading";
import debounce from "lodash.debounce";

export default {
  name: "ArticleRow",
  components: {icon_loading},
  props: {
    data: {
      type: Object,
    },
    index: {
      type: Number,
    },
    article_instance: {
      required: false,
      type: Object,
    },
  },
  data: function () {
    return {
      item: {},
      pricelist: [],
      margin: 0,
      updating: false,
      showPrice: false,
      selected_pricelist: "",
      article: {purchase_price: 0},
      isNotSalesPrice: false,
    };
  },

  methods: {
    computeMargin() {
      let margin = this.item.price - this.article.purchase_price;
      if (!isNaN(margin)) {
        this.margin = margin;
      } else {
        this.margin = "-";
      }
    },
    filterPriceListSearch(data) {
      const payload = {
        URL:
            this.$services.endpoints.REGISTER_ENDPOINT +
            "pricelists" +
            `?search=${data}`,
      };
      this.$store
          .dispatch("clients/getCustomRequest", payload)
          .then((res) => {
            if (res.data.results.length > 0) {
              this.showPrice = true;
              this.pricelist = res.data.results;
            }
          })
          .catch((err) => {
          });
    },
    searchPriceListField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterPriceListSearch(event.target.value);
      } else {
        this.showPrice = false;
      }
    }, 500),
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "pricelists";
      this.$store
          .dispatch("clients/getCustomRequest", {URL})
          .then((res) => {
            this.pricelist = res.data.results;
          })
          .catch((err) => {
          });
    },
    removeArticlePrice(id) {
      this.$emit("remove-article-price", id);
    },
    updateArticlePrice(item) {
      this.updating = true;
      const payload = {
        article_number: item.article.article_number,
        price_list: this.selected_pricelist,
        price: item.price,
        from_quantity: item.from_quantity,
        id: item.id,
      };
      this.$store
          .dispatch("clients/updateArticlePrice", payload)
          .then((res) => {
            this.updating = false;
            // this.$services.helpers.notification('Article Price updated successfully', 'success', this)
            this.$toast.success(this.$t("price_updated"));
          })
          .catch((err) => {
            this.updating = false;
            this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
            );
          });
    },
    choosePriceList(item) {
      this.selected_pricelist = item.code;
      this.item.description = item.description;
      this.showPrice = false;
    },
  },
  mounted() {
    this.item = this.data;
    this.isNotSalesPrice = !this.item.price_list.pre_selected;
    this.selected_pricelist = this.data.price_list.code;
    // if (this.item.price_list !== null && this.item.price_list.pre_selected) {
    //
    // }
    this.article = this.article_instance;

    this.getPriceList();
    this.computeMargin();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .product-showcase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 638px) {
  .content .product-showcase {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.content .product-showcase h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .product-showcase ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.content .product-showcase ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.content .product-showcase ul li:first-of-type a {
  margin-right: 10px;
  padding-right: 10px;
}

.content .product-showcase ul li:last-of-type a {
  margin-left: 10px;
  padding-left: 10px;
}

.content .product-showcase ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.content .product-showcase ul p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  height: 100%;
}

.content .box h3 {
  background: #5855d6;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

.content .box form .desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc input,
.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 192px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.small,
.content .box form .desc select.small {
  width: 95px;
}

.content .box form .desc input:focus,
.content .box form .desc select:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box form textarea {
  background: #f0eff5;
  width: 176px;
  height: 100px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
}

.content .box form textarea:focus {
  border: none;
  outline: none;
}

.content .box table {
  width: 94%;
  margin: 40px auto;
  background: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
}

.content .box table thead th {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table thead th:last-of-type {
  padding-right: 15px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .box table tbody tr td:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table tbody tr td:nth-of-type(2) input {
  margin: 0;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 47px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
  0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 50px 0 20px;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  background-color: #de4343;
  color: #fff;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.input-select {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  text-indent: 5px;
}
</style>

<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div>
            <h1 class="mb-0">{{ $t('account_settings')}}</h1>
          </div>
          <router-link :to="{ name : 'dashboard' }" class="my-3 my-sm-0">
            <svg class="me-2" width="7" height="14" viewbox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                 data-v-66db0d06="">
              <path
                  d="M5.83749 14.0003C5.68809 14.0008 5.54048 13.9678 5.4055 13.9038C5.27052 13.8398 5.15161 13.7463 5.05749 13.6303L0.227488 7.63028C0.0804062 7.45134 0 7.2269 0 6.99528C0 6.76365 0.0804062 6.53921 0.227488 6.36028L5.22749 0.360276C5.39723 0.156059 5.64114 0.0276347 5.90556 0.00325494C6.16999 -0.0211248 6.43327 0.0605371 6.63749 0.230276C6.8417 0.400014 6.97013 0.643926 6.99451 0.908352C7.01889 1.17278 6.93723 1.43606 6.76749 1.64028L2.29749 7.00028L6.61749 12.3603C6.73977 12.5071 6.81745 12.6858 6.84133 12.8753C6.86521 13.0649 6.83429 13.2573 6.75223 13.4299C6.67018 13.6024 6.54042 13.7478 6.37831 13.8489C6.2162 13.95 6.02852 14.0025 5.83749 14.0003Z"
                  fill="#5855D6" data-v-66db0d06=""></path>
            </svg>
            {{ $t('go_back_to_home_page') }}
          </router-link>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4 mb-3">
            <h3>{{ $t('update_your_information') }}</h3>
            <form @submit.prevent="updateUserInfo">
              <!-- <div class="form-group mt-3">
                  <label class="mb-2" for="name">Display name</label>
                  <input type="text" class="form-field">
              </div> -->
              <div class="form-group mt-4 mb-3">
                <label class="mb-2" for="name">{{ $t('full_name') }}</label>
                <input type="text" v-model="userInfo.full_name" class="form-field" required>
              </div>
              <div class="form-group mt-4 mb-3">
                <label class="mb-2" for="name">{{ $t('email') }}</label>
                <input type="text" v-model="userInfo.email" class="form-field" required>
              </div>
              <div class="form-group mt-4 mb-4">
                <label class="mb-2" for="name">{{ $t('phone_number') }}</label>
                <input type="text" v-model="userInfo.phone" class="form-field">
              </div>
              <div class="form-group mt-4 mb-3">
                <div class="buttons">
                  <button :disabled='processing' type="submit">
                    <span v-if="processing">{{ $t('updating') }}...</span>
                    <span v-else>{{ $t('update_profile') }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-4 mb-3">
            <h3>{{ $t('change_password') }}</h3>
            <form @submit.prevent="updateUserPassword">
              <div class="form-group mt-3">
                <label class="mb-2" for="name">{{ $t('current_password') }}</label>
                <input type="password" v-model="currentPassword" class="form-field" required>
              </div>
              <div class="form-group mt-4 mb-3">
                <label class="mb-2" for="name">{{ $t('new_password') }}</label>
                <input type="password" v-model="newPassword" class="form-field" required>
              </div>
              <div class="form-group mt-4 mb-3">
                <label class="mb-2" for="name">{{ $t('confirm_password') }}</label>
                <input type="password" v-model="confirmPassword" class="form-field" required>
              </div>
              <div class="form-group mt-4 mb-3">
                <div class="buttons">
                  <button :disabled='updating' type="submit">
                    <span v-if="updating">{{ $t('updating') }}...</span>
                    <span v-else>{{ $t('update_password') }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-4 mb-3">
            <h3>{{ $t('update_profile_picture') }}</h3>
            <form @submit.prevent="uploadUserPicture">
              <div class="box-responsive">
                <div class="d-flex flex-column">
                  <div>
                    <label for="image">{{ $t('current_profile_picture') }}</label>
                    <div class="d-flex justify-content-center">
                      <img v-if="GET_CURRENT_USER.image" :src="GET_CURRENT_USER.image" class="rounded-full-image"
                           alt="">
                      <!-- <img v-else src="" class="rounded-full-image" alt=""> -->
                      <div v-else class="rounded-full-image">

                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="image">{{ $t('choose_a_new_picture') }}</label>
                    <label class="image-section d-flex mt-2 px-4 py-2">
                      <input type="file" id="upload" class="inputfile form-control-file" @change="changeImageUploader"
                             accept="image/png, image/jpeg, image/jpg"/>
                      <span><img src="./../../assets/images/icons-clickable/upload.png" alt="upload image"></span>
                      <label :class="fileLabel.error ? 'invalid-style' : ''" class="ml-4">{{ fileLabel.name }}</label>
                    </label>
                    <!-- <div class="image-section d-flex mt-2 px-4 py-2">
                    </div> -->
                  </div>

                </div>
              </div>

              <div class="form-group mt-4 mb-3">
                <div class="buttons">
                  <button :disabled='uploading' type="submit">
                    <span v-if="uploading">Uploading...</span>
                    <span v-else>{{ $t('update_profile_picture') }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'AccountSettings',
  data() {
    return {
      userInfo: {
        email: '',
        full_name: '',
        phone: '',
      },
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      fileLabel: {
        name: this.$t('upload_image'),
        error: false
      },
      selectedFile: null,
      updating: false,
      uploading: false,
      processing: false
    }
  },
  computed: {
    ...mapGetters({
      GET_CURRENT_USER: 'clients/GET_CURRENT_USER'
    })
  },
  mounted() {
    this.getUserInformation()
  },
  methods: {
    getUserInformation() {
      this.$store.dispatch('clients/getCurrentUserInfo')
          .then(_ => {
            this.userInfo = this.GET_CURRENT_USER
          })
          .catch(_ => {
          })
    },
    updateUserInfo() {
      if (this.userInfo.full_name === '' || this.userInfo.email === '') {
        // return  this.$services.helpers.notification('Kindly supplied all the required fields', 'error', this)
        return this.$toast.warning(this.$t('fields_required'));
      } else {
        this.processing = true
        this.$store.dispatch('clients/updateUser', this.userInfo)
            .then(res => {
              this.processing = false
              // this.$services.helpers.notification('Information updated successfully', 'success', this)
              this.$toast.success(this.$t('information_updated'));
            })
            .catch(err => {
              this.processing = false
              this.$services.helpers.notification(err.response.data, 'success', this)
            })
      }
    },
    updateUserPassword() {
      if (this.currentPassword === '' || this.newPassword === '' || this.confirmPassword === '') {
        // return  this.$services.helpers.notification('All fields are required', 'error', this)
        return this.$toast.warning(this.$t('all_fields_are_required'));
      } else if (this.newPassword !== this.confirmPassword) {
        // this.$services.helpers.notification('Password do not match', 'error', this)
        this.$toast.error(this.$t('password_do_not_match'));
      } else if (this.newPassword.length < 6) {
        // this.$services.helpers.notification('Password should be greater than 6', 'error', this)
        this.$toast.error(this.$t('password_not_valid'));
      } else {
        this.updating = true
        const payload = {
          current_password: this.currentPassword,
          new_password1: this.newPassword,
          new_password2: this.confirmPassword
        }
        this.$store.dispatch('clients/updateUserPassword', payload)
            .then(_ => {
              this.updating = false
              // this.$services.helpers.notification('Password updated successfully', 'success', this)
              this.$toast.error(this.$t('password_updated'));
              this.$store.commit('auth/CLEAR_AUTH_USER', null)
              window.Bus.emit('logout')
            })
            .catch(err => {
              this.updating = false
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            })
      }
    },
    changeImageUploader(e) {
      const acceptedType = ['png', 'jpg', 'jpeg']
      const fileType = e.target.files[0].type.split('/')[1]

      if (acceptedType.includes(fileType)) {
        this.fileLabel.name = 'File Selected'
        this.fileLabel.error = false
        this.selectedFile = e.target.files[0]
      } else {
        document.getElementById('upload').value = ''
        this.fileLabel.name = 'Invalid file'
        this.fileLabel.error = true
        // this.$services.helpers.notification('Invalid file type', 'error', this)
        this.$toast.error(this.$t('invalid_file_type'));
      }
    },
    uploadUserPicture() {
      if (!this.selectedFile) {
        // this.$services.helpers.notification('Select a file to upload', 'error', this)
        this.$toast.warning(this.$t('select_file'));
      } else {
        this.uploading = true
        const formData = new FormData()
        formData.append('image', this.selectedFile)
        this.$store.dispatch('clients/uploadUserPicture', formData)
            .then(res => {
              this.uploading = false
              this.getUserInformation()
              this.fileLabel.name = 'Upload picture'
              this.fileLabel.error = false
              document.getElementById('upload').value = ''
              this.selectedFile = null
              // this.$services.helpers.notification('Image uploaded successfully', 'success', this)
              this.$toast.success(this.$t('image_uploaded'));
            })
            .catch(err => {
              this.uploading = false
              this.fileLabel.name = 'Server error'
              this.fileLabel.error = true
              this.$services.helpers.notification(err.response.data.message, 'error', this)
            })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content h1 {
  margin: 20px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content h1 ~ span {
  font-size: 18px;
  line-height: 28px;
  color: #27364b;
}

.content div ~ a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5855d6;
  text-decoration: none;
  cursor: pointer;
}

.content div ~ a:hover {
  color: #4946ae;
  border-color: #4946ae;
}

.content .row h3 {
  font-size: 18px;
  line-height: 28px;
  color: #333269;
}

.form-field {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;

  /* Others/white */
  background: #FFFFFF;

  /* Secondary/40 */
  border: 1px solid #D2D1D7;
  box-sizing: border-box;

  /* Shadow/small */
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.content .buttons button {
  background: #5855d6;
  color: #f6f8fc;
  border: none;
  width: 80%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:hover {
  background-color: #4947b0;
  color: #fff;
}

label {
  font-size: 14px;
  line-height: 22px;
  color: #333269;
}

.box-responsive {
  border-radius: 5px;
  width: 80%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
}

.image-section {
  border: 1px dashed #5855D6;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.invalid-style {
  color: #c02244;
  font-weight: 800;
}
</style>
<template>
  <div class="col responsive-width">
    <div class="container-fluid">
      <div class="content" v-if="tab === 1">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t("products") }}</h1>
          <h6>{{ $t("edit_product") }}</h6>
        </div>
        <div class="product-showcase">
          <h2>
            {{ $t("product") }} {{ articleData.article_number }} -
            {{ articleData.description }}
          </h2>
          <ul
            class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center"
          >
            <li :class="tab === 1 ? 'active' : ''">
              <a href="javascript:void(0);" @click="tab = 1">{{
                $t("basic_detail")
              }}</a>
            </li>
            <li
              :class="tab === 2 ? 'active' : ''"
              v-if="articleData.stock_goods"
            >
              <a href="javascript:void(0);" @click="tab = 2" disabled>{{
                $t("stock_detail")
              }}</a>
            </li>
          </ul>
        </div>
        <div class="box product">
          <h3>{{ $t("product_detail") }}</h3>
          <form class="row">
            <div class="col">
              <div class="desc">
                <label>{{ $t("article_number") }}</label>
                <input
                  type="text"
                  v-model="articleData.article_number"
                  disabled
                  readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("ean") }}</label>
                <input type="text" v-model="articleData.ean" />
              </div>
              <div class="desc">
                <label>{{ $t("supplier") }}</label>
                <select v-model="articleData.supplier_number">
                  <option value=""></option>
                  <option
                    :value="item.supplier_number"
                    v-for="item in suppliers"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="desc">
                <label>{{ $t("description") }}</label>
                <input
                  type="text"
                  class="w-full"
                  v-model="articleData.description"
                />
              </div>
              <div class="desc">
                <label>{{ $t("note") }}</label>
                <textarea class="w-full" v-model="articleData.note"></textarea>
              </div>
            </div>

            <div class="col">
              <div class="col-flex-wrap">
                <div class="desc desc-min-width">
                  <label>{{ $t("active") }}</label>
                  <!-- <SwitchButton v-model="articleData.active" :switchValue='articleData.active' /> -->

                  <div class="radio">
                    <input
                      type="radio"
                      v-model="articleData.active"
                      :value="true"
                      name="active"
                      id="ac-yes"
                    />
                    <label for="ac-yes">{{ $t("yes") }}</label>
                    <input
                      type="radio"
                      v-model="articleData.active"
                      :value="false"
                      name="active"
                      id="ac-no"
                    />
                    <label for="ac-no">{{ $t("no") }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t("item_type") }}</label>
                  <!-- <SwitchButton v-model="articleData.type" :switchLong="articleData.type" :long="true" :longValues="['STOCK', 'SERVICE']" /> -->

                  <div class="radio">
                    <input
                      type="radio"
                      v-model="articleData.type"
                      v-on:change="handleTypeChange"
                      value="STOCK"
                      name="item-type"
                      id="item-goods"
                    />
                    <label for="item-goods">{{ $t("goods") }}</label>
                    <input
                      type="radio"
                      v-model="articleData.type"
                      v-on:change="handleTypeChange"
                      value="SERVICE"
                      name="item-type"
                      id="item-services"
                    />
                    <label for="item-services">{{ $t("services") }}</label>
                  </div>
                </div>
              </div>
              <div class="col-flex-wrap">
                <div class="desc desc-min-width">
                  <label>{{ $t("webshop_article") }}</label>

                  <div class="radio">
                    <input
                      type="radio"
                      v-model="articleData.webshop_article"
                      :value="true"
                      name="external-webshop"
                      id="external-yes"
                      checked
                    />
                    <label for="external-yes">{{ $t("yes") }}</label>
                    <input
                      type="radio"
                      v-model="articleData.webshop_article"
                      :value="false"
                      name="external-webshop"
                      id="external-no"
                    />
                    <label for="external-no">{{ $t("no") }}</label>
                  </div>
                </div>

                <div class="desc">
                  <label>{{ $t("unit") }}</label>
                  <select v-model="articleData.unit" class="small">
                    <option value=""></option>
                    <option
                      :value="item.code"
                      v-for="item in units"
                      :key="item.id"
                    >
                      {{ item.code }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-flex-wrap">
                <div class="desc desc-min-width">
                  <label>{{ $t("stock_item") }}</label>
                  <!-- <SwitchButtonContinouse v-model="articleData.stock_goods" :second="true" :switchValue='articleData.stock_goods' /> -->
                  <div class="radio">
                    <input
                      type="radio"
                      v-model="articleData.stock_goods"
                      v-on:change="handleTypeChange"
                      :value="true"
                      name="stock-item"
                      id="stock-yes"
                      checked
                    />
                    <label for="stock-yes">{{ $t("yes") }}</label>
                    <input
                      type="radio"
                      v-model="articleData.stock_goods"
                      v-on:change="handleTypeChange"
                      :value="false"
                      name="stock-item"
                      id="stock-no"
                    />
                    <label for="stock-no">{{ $t("no") }}</label>
                  </div>
                </div>
                <div class="desc">
                  <label>{{ $t("discontinued") }}</label>
                  <!-- <SwitchButtonContinouse v-model="articleData.deleted" :id="['radio-deleted-one', 'radio-deleted-two']" :second="true" :switchValue='articleData.deleted' /> -->

                  <div class="radio">
                    <input
                      type="radio"
                      v-model="articleData.deleted"
                      :value="true"
                      name="discontinued"
                      id="discontinued-yes"
                    />
                    <label for="discontinued-yes">{{ $t("yes") }}</label>
                    <input
                      type="radio"
                      v-model="articleData.deleted"
                      :value="false"
                      name="discontinued"
                      id="discontinued-no"
                    />
                    <label for="discontinued-no">{{ $t("no") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col">
            <div class="box price">
              <h3>{{ $t("prices") }}</h3>
              <div class="">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">{{ $t("article") }}</th>
                      <th scope="col">{{ $t("price_list") }}</th>
                      <th colspan="2">{{ $t("description") }}</th>
                      <th scope="col">{{ $t("price") }}</th>
                      <th scope="col">{{ $t("quantity") }}</th>
                      <th scope="col">{{ $t("margin") }}</th>
                      <th scope="col">{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <article-row
                      @remove-article-price="removeArticlePrice"
                      :data="item"
                      :index="i"
                      :article_instance="articleData"
                      v-for="(item, i) in articlePrices"
                      :key="i"
                    />
                    <tr>
                      <td>
                        <input
                          v-model="articlePriceForm.article_number"
                          class="mid"
                          disabled
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          @input="searchPriceListField"
                          class=""
                          type="text"
                          :placeholder="$t('search')"
                          v-model="articlePriceForm.price_list"
                        />
                        <div
                          v-if="showPrice"
                          style="z-index: 9999; width: 150px"
                          class="position-absolute bg-white rounded shadow-md border p-2"
                        >
                          <ul
                            style="
                              padding-left: 0.3rem !important;
                              list-style-type: none;
                            "
                          >
                            <li
                              class="border-bottom mb-1 cursor-pointer text-xs"
                              v-for="item in pricelist.slice(0, 5)"
                              :key="item.code"
                              @click="choosePriceList(item)"
                            >
                              {{ item.code }} - {{ item.description }}
                            </li>
                          </ul>
                        </div>
                        <!--                      <select v-model="articlePriceForm.price_list" class="input-select"-->
                        <!--                              v-on:change="updateDescription">-->
                        <!--                        <option :value="item.code" v-for="item in pricelist" :key="item.id">{{-->
                        <!--                            item.description-->
                        <!--                          }}-->
                        <!--                        </option>-->
                        <!--                      </select>-->
                      </td>
                      <td colspan="2">
                        <input
                          class="big"
                          disabled
                          v-model="articlePriceForm.description"
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          class="mid"
                          v-model="articlePriceForm.price"
                          type="text"
                          v-on:focusout="computeMargin"
                        />
                      </td>
                      <td>
                        <input
                          class="mid"
                          type="text"
                          v-model="articlePriceForm.from_quantity"
                        />
                      </td>
                      <td>
                        <span
                          class="margin input-select"
                          style="padding: 5px"
                          v-if="GET_SINGLE_ARTICLE"
                          >{{ margin }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button
                :disabled="priceAdding"
                type="submit"
                @click="addArticlePrice"
              >
                <span v-if="priceAdding">{{ $t("adding") }}...</span>
                <span v-else>{{ $t("add") }}</span>
              </button>
            </div>
          </div>

          <div class="col">
            <div
              @click="removeAccountBox"
              v-if="
                showSalesAccount ||
                showSalesEU ||
                showExportAccount ||
                showPurchaseAccount
              "
              class="position-absolute inset-0"
              style="z-index: 999"
            ></div>

            <div class="box account">
              <h3>{{ $t("account_detail") }}</h3>
              <form class="row">
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("sales_account") }}</label>
                    <!-- <select v-model="articleData.sales_account">
                        <option :value="item.number" v-for="item in accounts" :key="item.id">{{ item.description }}</option>
                    </select> -->
                    <input
                      @input="searchField($event, 'sales')"
                      type="text"
                      v-model="articleData.sales_account"
                    />
                    <div
                      v-if="showSalesAccount"
                      style="z-index: 9999999; width: 12%"
                      class="position-absolute bg-white rounded shadow-md border mt-2"
                    >
                      <ul
                        style="
                          padding-left: 0rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom p-2 mb-1 cursor-pointer"
                          v-for="item in accounts.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSales(item, 'sales')"
                        >
                          {{ item.number + " " + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- <div class="desc">
                      <label>Sales SE (reverse tax)</label>
                      <select>
                      <option> </option>
                      </select>
                  </div> -->
                  <div class="desc">
                    <label>{{ $t("euvat_account") }}</label>
                    <!-- <select v-model="articleData.eu_vat_account">
                        <option :value="item.number" v-for="item in accounts" :key="item.id">{{ item.description }}</option>
                    </select> -->
                    <input
                      @input="searchField($event, 'eu')"
                      type="text"
                      v-model="articleData.euvat_account"
                    />
                    <div
                      v-if="showSalesEU"
                      style="z-index: 9999999; width: 12%"
                      class="position-absolute bg-white rounded shadow-md border mt-2"
                    >
                      <ul
                        style="
                          padding-left: 0rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom p-2 mb-1 cursor-pointer"
                          v-for="item in accounts.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSales(item, 'eu')"
                        >
                          {{ item.number + " " + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="desc">
                    <label>{{ $t("purchase_account") }}</label>
                    <input
                      @input="searchField($event, 'purchase')"
                      type="text"
                      v-model="articleData.purchase_account"
                    />
                    <div
                      v-if="showPurchaseAccount"
                      style="z-index: 9999999; width: 12%"
                      class="position-absolute bg-white rounded shadow-md border mt-2"
                    >
                      <ul
                        style="
                          padding-left: 0rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom p-2 mb-1 cursor-pointer"
                          v-for="item in accounts.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSales(item, 'purchase')"
                        >
                          {{ item.number + " " + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("vat") }}</label>
                    <input type="text" :value="articleData.vat" readonly />
                  </div>
                  <!-- <div class="desc">
                      <label>Sales EU (reverse tax)</label>
                      <select>
                      <option> </option>
                      </select>
                  </div> -->
                  <div class="desc">
                    <label>{{ $t("export_account") }}</label>
                    <!-- <select v-model="articleData.export_account">
                        <option :value="item.number" v-for="item in accounts" :key="item.id">{{ item.description }}</option>
                    </select> -->
                    <input
                      @input="searchField($event, 'export')"
                      type="text"
                      v-model="articleData.export_account"
                    />
                    <div
                      v-if="showExportAccount"
                      style="z-index: 9999999; width: 12%"
                      class="position-absolute bg-white rounded shadow-md border mt-2"
                    >
                      <ul
                        style="
                          padding-left: 0rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom p-2 mb-1 cursor-pointer"
                          v-for="item in accounts.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSales(item, 'export')"
                        >
                          {{ item.number + " " + item.description }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="buttons flex-wrap align-items-center">
          <button type="submit" @click="showDeleteModal = true">
            {{ $t("delete_product") }}
          </button>
          <div class="right flex-wrap my-2 my-sm-0">
            <router-link :to="{ name: 'products' }">{{
              $t("cancel")
            }}</router-link>
            <button :disabled="processing" @click="updateArticle">
              <span v-if="processing">{{ $t("updating") }}...</span>
              <span v-else>{{ $t("update_product") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="content" v-if="tab === 2">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t("products") }}</h1>
          <h6>{{ $t("edit_product") }}</h6>
        </div>
        <div class="product-showcase">
          <h2>
            Article {{ articleData.article_number }} -
            {{ articleData.description }}
          </h2>
          <ul
            class="list-unstyled my-2 my-md-0 flex-wrap justify-content-center"
          >
            <li :class="tab === 1 ? 'active' : ''">
              <a href="javascript:void(0);" @click="tab = 1">{{
                $t("basic_detail")
              }}</a>
            </li>
            <li :class="tab === 2 ? 'active' : ''">
              <a href="javascript:void(0);" @click="tab = 2">{{
                $t("stock_detail")
              }}</a>
            </li>
          </ul>
        </div>
        <div class="box stock">
          <h3>{{ $t("stock_detail") }}</h3>
          <form class="row">
            <div class="col">
              <div class="desc">
                <label>{{ $t("purchase_price") }}</label>
                <input
                  min="1"
                  type="number"
                  v-model="articleData.purchase_price"
                />
              </div>
              <div class="desc">
                <label>{{ $t("stock_value") }}</label>
                <input
                  type="number"
                  :value="articleData.stock_value"
                  readonly
                />
              </div>
            </div>
            <div class="col">
              <div class="desc">
                <label>{{ $t("in_warehouse") }}</label>
                <input
                  type="number"
                  v-model="articleData.quantity_in_stock"
                  min="01"
                />
              </div>
              <div class="desc">
                <label>{{ $t("reserved_quantity") }}</label>
                <input
                  type="text"
                  :value="articleData.reserved_quantity"
                  readonly
                />
              </div>
              <div class="desc">
                <label>{{ $t("available_amount") }}</label>
                <input
                  :value="
                    articleData.quantity_in_stock -
                    articleData.reserved_quantity
                  "
                  type="text"
                  readonly
                />
              </div>
            </div>
            <div class="col">
              <!-- <div class="desc">
                  <label>Order point</label>
                  <input type="text" value="0.00">
              </div>
              <div class="desc">
                  <label>Stock point</label>
                  <select v-model='articleData.default_stock_point'>
                      <option></option>
                  </select>
              </div> -->
            </div>
            <div class="col">
              <div class="parent-small d-flex">
                <div class="desc me-2">
                  <label>{{ $t("width") }} (mm)</label>
                  <input
                    class="small"
                    type="number"
                    min="1"
                    v-model="articleData.width"
                  />
                </div>
                <div class="desc me-2">
                  <label>{{ $t("height") }} (mm)</label>
                  <input
                    class="small"
                    type="number"
                    min="1"
                    v-model="articleData.height"
                  />
                </div>
                <div class="desc me-2">
                  <label>{{ $t("depth") }} (mm)</label>
                  <input
                    class="small"
                    type="number"
                    min="1"
                    v-model="articleData.depth"
                  />
                </div>
              </div>
              <div class="parent-small d-flex">
                <div class="desc me-2">
                  <label>{{ $t("weight") }} (grams)</label>
                  <input type="number" min="1" v-model="articleData.weight" />
                </div>
                <div class="desc">
                  <label>{{ $t("bulky") }}</label>
                  <div class="radio">
                    <input
                      type="radio"
                      v-model="articleData.bulky"
                      :value="true"
                      name="bulky"
                      id="bulky-yes"
                    />
                    <label for="bulky-yes">{{ $t("yes") }}</label>
                    <input
                      type="radio"
                      v-model="articleData.bulky"
                      :value="false"
                      name="bulky"
                      id="bulky-no"
                    />
                    <label for="bulky-no">{{ $t("no") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="buttons flex-wrap align-items-center">
          <button type="submit" @click="showDeleteModal = true">
            {{ $t("delete_product") }}
          </button>
          <div class="right flex-wrap my-2 my-sm-0">
            <router-link :to="{ name: 'products' }">{{
              $t("cancel")
            }}</router-link>
            <button :disabled="processing" @click="updateArticle">
              <span v-if="processing">{{ $t("updating") }}...</span>
              <span v-else>{{ $t("update_product") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAlertVue
    v-if="showDeleteModal"
    @handleClose="showDeleteModal = false"
    :close="!true"
  >
    <h4>
      {{ $t("delete_confirmation_text") }}
      <span class="user-confirm text-danger">{{ $t("product") }}</span
      >?
    </h4>
    <div class="popup-bottons">
      <button @click="showDeleteModal = false">
        {{ $t("cancel_delete_request") }}
      </button>
      <button :disabled="processingDelete" @click="deleteArticle">
        <span v-if="processingDelete">{{ $t("deleting") }}...</span>
        <span v-else>{{ $t("accept_delete_request") }}</span>
      </button>
    </div>
  </ModalAlertVue>
  <ModalAlertVue
    v-if="openDeleteModal"
    @handleClose="openDeleteModal = false"
    :close="!true"
  >
    <h4>
      {{ $t("delete_confirmation_text") }}
      <span class="user-confirm text-danger">{{ $t("price") }}</span
      >?
    </h4>
    <div class="popup-bottons">
      <button @click="openDeleteModal = false">No, take me back</button>
      <button :disabled="processingDelete" @click="deleteArticlePrice">
        <span v-if="processingDelete">{{ $t("deleting") }}...</span>
        <span v-else>{{ $t("accept_delete_request") }}</span>
      </button>
    </div>
  </ModalAlertVue>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
import ModalAlertVue from "../../components/ModalAlert.vue";
import ArticleRow from "./reusableComponents/ArticleRow";
// import SwitchButton from './reusableComponents/SwitchButton.vue'
// import SwitchButtonContinouse from './reusableComponents/SwitchButtonContinouse.vue'

export default {
  components: { ModalAlertVue, ArticleRow },
  data() {
    return {
      tab: 1,
      showDeleteModal: false,
      articleData: "",
      showArticle: false,
      processing: false,
      processingDelete: false,
      accounts: [],
      articlePrices: [],
      pricelist: [],
      suppliers: [],
      units: [],
      showPrice: false,
      showSalesAccount: false,
      margin: 0,
      showSalesEU: false,
      showExportAccount: false,
      showPurchaseAccount: false,
      articlePriceForm: {
        article_number: "",
        price_list: "",
        price: "",
        from_quantity: 0,
        description: "",
        margin: 0,
      },
      priceAdding: false,
      deleteArticlePriceId: "",
      openDeleteModal: false,
      deleting: false,
    };
  },
  watch: {
    "articleData.purchase_price": function (newVal, oldVal) {
      if (parseInt(newVal) || newVal === 0) {
        this.articleData.stock_value = parseFloat(
          this.articleData.purchase_price * this.articleData.quantity_in_stock
        ).toFixed(2);
      }
    },
    "articleData.quantity_in_stock": function (newVal, oldVal) {
      if (parseInt(newVal) || newVal === 0) {
        this.articleData.stock_value = parseFloat(
          this.articleData.purchase_price * this.articleData.quantity_in_stock
        ).toFixed(2);
        // this.articleData.stock_account = newVal - this.articleData.reserved_quantity
      }
    },
    // 'articleData.reserved_quantity': function (newVal, oldVal) {
    //   if (parseInt(newVal) || newVal === 0) {
    //     this.articleData.stock_account = newVal - this.articleData.reserved_quantity
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      GET_SINGLE_ARTICLE: "clients/GET_SINGLE_ARTICLE",
      CURRENT_USER: "clients/GET_CURRENT_USER",
    }),
  },
  mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.getSingleArticle(id);
      this.getArticlePrice(id);
    }
    this.getAccount();
    this.getPriceList();
    this.getSupplier();
    this.getUnit();
    this.filterPriceListSearch();
  },
  methods: {
    handleTypeChange: function () {
      if (this.articleData.type === "SERVICE") {
        this.articleData.stock_goods = false;
      }
    },
    computeMargin() {
      let margin =
        this.articlePriceForm.price - this.GET_SINGLE_ARTICLE.purchase_price;
      if (!isNaN(margin)) {
        this.margin = margin;
      } else {
        this.margin = "-";
      }
    },
    filterPriceListSearch(data = null) {
      let payload;
      if (data) {
        payload = {
          URL:
            this.$services.endpoints.REGISTER_ENDPOINT +
            "pricelists" +
            `?search=${data}`,
        };
      } else {
        payload = {
          URL: this.$services.endpoints.REGISTER_ENDPOINT + "pricelists",
        };
      }

      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          if (res.data.results.length > 0) {
            this.showPrice = true;
            this.pricelist = res.data.results;

            if (data === null) {
              this.showPrice = false;
              let preSelected = this.pricelist.filter(
                (item) => item.pre_selected
              );
              if (preSelected.length) {
                this.articlePriceForm.price_list = preSelected[0].code;
                this.articlePriceForm.description = preSelected[0].description;
              }
            }
          }
        })
        .catch((err) => {});
    },
    searchPriceListField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterPriceListSearch(event.target.value);
      } else {
        this.showPrice = false;
      }
    }, 500),
    updateDescription: function () {
      let priceIndex = this.pricelist.findIndex(
        (item) => this.articlePriceForm.price_list === item.code
      );
      if (priceIndex >= 0) {
        this.articlePriceForm.description =
          this.pricelist[priceIndex].description;
      }
    },
    searchField: debounce(function (event, val) {
      if (event.target.value !== "") {
        this.filterSearch(event.target.value, val);
      } else {
        this.showSalesAccount = false;
      }
    }, 500),
    filterSearch(data, mode) {
      const payload = {
        URL:
          this.$services.endpoints.REGISTER_ENDPOINT +
          `accounts?search=${data}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.accounts = res.data.results;
          if (mode === "sales") {
            this.showSalesAccount = true;
          } else if (mode === "eu") {
            this.showSalesEU = true;
          } else if (mode === "export") {
            this.showExportAccount = true;
          } else if (mode === "purchase") {
            this.showPurchaseAccount = true;
          }
        })
        .catch((err) => {});
    },
    chooseSales(item, mode) {
      if (mode === "sales") {
        this.articleData.sales_account = item.number;
        this.showSalesAccount = false;
      } else if (mode === "eu") {
        this.showSalesEU = false;
        this.articleData.eu_vat_account = item.number;
      } else if (mode === "export") {
        this.articleData.export_account = item.number;
        this.showExportAccount = false;
      } else if (mode === "purchase") {
        this.articleData.purchase_account = item.number;
        this.showPurchaseAccount = false;
      }
    },
    getSingleArticle(data) {
      this.$store.dispatch("clients/getSingleArticle", data).then((_) => {
        this.articleData = this.GET_SINGLE_ARTICLE;
        this.articlePriceForm.article_number = this.articleData.article_number;
        // this.articleData.stock_account = this.articleData.quantity_in_stock - this.articleData.reserved_quantity
      });
    },
    getArticlePrice(data) {
      this.$store
        .dispatch("clients/getSingleArticlePrice", data)
        .then((res) => {
          this.articlePrices = res.data.data;
          if (this.articlePrices.length === 0) {
            this.articlePriceForm.price = this.articleData.sales_price;
            if (this.articleData.purchase_price) {
              this.articlePriceForm.margin =
                this.articleData.sales_price - this.articleData.purchase_price;
            }
          }

          // this.articleData = this.GET_SINGLE_ARTICLE
        });
    },
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "pricelists";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.pricelist = res.data.results;
        })
        .catch((err) => {});
    },
    updateArticle() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      let exclude = [
        "tags",
        "construction_account",
        "direct_cost",
        "eu_account",
        "expired",
        "freight_cost",
        "housework",
        "housework_type",
        "other_cost",
        "stock_warning",
      ];
      let data_to_save = {};
      let articleData = this.articleData;
      for (const [key, value] of Object.entries(articleData)) {
        if (!exclude.includes(key)) {
          if (value !== "" && value !== null && value !== undefined) {
            data_to_save[key] = value;
          }
        }
      }
      this.processing = true;
      this.$store
        .dispatch("clients/updateArticle", data_to_save)
        .then((res) => {
          this.processing = false;
          // this.$services.helpers.notification('Article updated successfully', 'success', this)
          this.$toast.success(this.$t("article_updated"));
        })
        .catch((err) => {
          this.processing = false;
          this.errorMessage(err);
        });
    },
    errorMessage(err) {
      try {
        if ([400, 406].includes(err.response.status)) {
          if ("error" in err.response.data) {
            if (Array.isArray(err.response.data.error)) {
              this.$services.helpers.notification(
                err.response.data.error.join(","),
                "error",
                this
              );
            } else if (typeof err.response.data.error === "object") {
              for (const [key, value] of Object.entries(
                err.response.data.error
              )) {
                if (Array.isArray(value)) {
                  this.$services.helpers.notification(
                    key.replace("_", " ") + ": " + value.join(","),
                    "error",
                    this
                  );
                } else {
                  this.$services.helpers.notification(value, "error", this);
                }
                return false;
              }
            } else {
              this.$services.helpers.notification(
                err.response.data.error,
                "error",
                this
              );
            }
          } else if ("message" in err.response.data) {
            if (Array.isArray(err.response.data.message)) {
              this.$services.helpers.notification(
                err.response.data.message.join(""),
                "error",
                this
              );
            } else if (typeof err.response.data.message === "object") {
              if ("message" in err.response.data.message) {
                if ("ErrorInformation" in err.response.data.message.message) {
                  this.$services.helpers.notification(
                    err.response.data.message.message.ErrorInformation.message,
                    "error",
                    this
                  );
                }
              }
            } else {
              this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
              );
            }
          }
        } else {
          // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
          this.$toast.error(this.$t("request_failure"));
        }
      } catch (e) {
        // this.$services.helpers.notification('Action could not be perform', 'error', this)
        this.$toast.error(this.$t("action_failed"));
      }
    },
    deleteArticle() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.processingDelete = true;
      this.$store
        .dispatch("clients/removeArticle", { id: this.$route.params.id })
        .then((res) => {
          this.processingDelete = false;
          // this.$services.helpers.notification('Article removed successfully', 'success', this)
          this.$toast.success(this.$t("article_deleted"));
          this.$router.replace({ name: "products" });
        })
        .catch((err) => {
          this.processingDelete = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "accounts";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.accounts = res.data.results;
        })
        .catch((err) => {});
    },
    addArticlePrice() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (
        this.articlePriceForm.article_number === "" ||
        this.articlePriceForm.price_list === "" ||
        this.articlePriceForm.price === ""
      ) {
        // this.$services.helpers.notification('Article number, pricelist and price are required', 'error', this)
        this.$toast.warning(this.$t("number_pricelist_price_required"));
      } else {
        this.priceAdding = true;
        this.$store
          .dispatch("clients/addArticlePrice", this.articlePriceForm)
          .then((res) => {
            this.priceAdding = false;
            const { id } = this.$route.params;
            if (id) {
              this.getArticlePrice(id);
            }
            this.removePriceField();
            this.articlePriceForm.description = "";
            // this.$services.helpers.notification('Article Price added successfully', 'success', this)
            this.$toast.success(this.$t("price_added"));
          })
          .catch((err) => {
            this.priceAdding = false;
            this.$services.helpers.notification(
              err.response.data.message,
              "error",
              this
            );
          });
      }
    },
    updateArticlePrice(item) {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      const payload = {
        article_number: item.article.article_number,
        price_list: item.price_list.code,
        price: item.price,
        from_quantity: item.from_quantity,
        id: item.id,
      };
      this.$store
        .dispatch("clients/updateArticlePrice", payload)
        .then((res) => {
          const { id } = this.$route.params;
          if (id) {
            this.getArticlePrice(id);
          }
          // this.$services.helpers.notification('Article Price updated successfully', 'success', this)
          this.$toast.success(this.$t("price_updated"));
        })
        .catch((err) => {
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    deleteArticlePrice() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.processingDelete = true;
      this.$store
        .dispatch("clients/removeArticlePrice", {
          id: this.deleteArticlePriceId,
        })
        .then((res) => {
          this.processingDelete = false;
          this.openDeleteModal = false;
          const { id } = this.$route.params;
          if (id) {
            this.getArticlePrice(id);
          }
          // this.$services.helpers.notification('Article Price removed successfully', 'success', this)
          this.$toast.success(this.$t("price_deleted"));
        })
        .catch((err) => {
          this.processingDelete = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    getSupplier() {
      const URL = this.$services.endpoints.BASE_ENDPOINT + "suppliers";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.suppliers = res.data.results;
        })
        .catch((err) => {});
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.units = res.data.results;
        })
        .catch((err) => {});
    },
    removeArticlePrice(id) {
      this.deleteArticlePriceId = id;
      this.openDeleteModal = true;
    },
    removePriceField() {
      this.articlePriceForm.price = this.GET_SINGLE_ARTICLE.sales_price;
      this.articlePriceForm.from_quantity = 0;
      this.articlePriceForm.dispatch = "";
      this.articlePriceForm.price_list = "";
    },
    removeAccountBox() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.showSalesAccount = false;
      this.showSalesEU = false;
      this.showExportAccount = false;
    },
    searchArticleField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticleSearch(event.target.value);
      } else {
        this.showArticle = false;
      }
    }, 500),
    filterArticleSearch(data) {
      const payload = {
        URL:
          this.$services.endpoints.ARTICLE_ENDPOINT +
          `?search=${data}&customize=no`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.showArticle = true;
            this.searchArticle = res.data.data;
          }
        })
        .catch((err) => {});
    },
    chooseArticle(item) {
      this.row.row_id = item.article_number;
      this.row.description = item.description;
      this.row.price = item.purchase_price;
      // this.row.total = item.
      // this.row.cost_center = item.
      this.row.vat = item.vat;
      this.row.account_number = item.sales_account;
      this.row.unit = item.unit;
      this.showArticle = false;

      this.reCalculateValues();
    },
    choosePriceList(item) {
      this.articlePriceForm.price_list = item.code;
      this.articlePriceForm.description = item.description;
      this.showPrice = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .product-showcase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 638px) {
  .content .product-showcase {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.content .product-showcase h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .product-showcase ul {
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #333269;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  text-align: center;
}

.content .product-showcase ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-decoration: none;
  color: #d2d1d7;
  display: inline-block;
  padding: 8px 25px;
}

.content .product-showcase ul li:first-of-type a {
  margin-right: 10px;
  padding-right: 10px;
}

.content .product-showcase ul li:last-of-type a {
  margin-left: 10px;
  padding-left: 10px;
}

.content .product-showcase ul li.active a {
  background: #f6f8fc;
  color: #333269;
  padding: 9px 25px;
}

.content .product-showcase ul p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
  height: 100%;
}

.content .box.product h3 {
  background-image: url("./../../assets/images/table/product.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box.price h3 {
  background-image: url("./../../assets/images/table/price.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box.account h3 {
  background-image: url("./../../assets/images/table/account.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box h3 {
  background: #5855d6;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
}

.content .box form {
  padding: 30px;
}

.content .box form .desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc label {
  margin-bottom: 10px;
  display: block;
}

.content .box form .desc input,
.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 192px;
  text-indent: 10px;
  padding-right: 5px;
}

.content .box form .desc input.small,
.content .box form .desc select.small {
  width: 95px;
}

.content .box form .desc input:focus,
.content .box form .desc select:focus {
  outline: none;
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box form textarea {
  background: #f0eff5;
  width: 176px;
  height: 100px;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  resize: none;
  border: none;
}

.content .box form textarea:focus {
  border: none;
  outline: none;
}

.content .box table {
  width: 94%;
  margin: 40px auto;
  background: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
}

.content .box table thead th {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table thead th:last-of-type {
  padding-right: 15px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .box table tbody tr td:nth-of-type(2) {
  text-align: left;
  padding-left: 15px;
}

.content .box table tbody tr td:nth-of-type(2) input {
  margin: 0;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 47px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 50px 0 20px;
}

.content .buttons button,
.content .buttons a {
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.content .buttons > button {
  background: #fb4b4b;
  color: #f6f8fc;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons > button:hover {
  background-color: #de4343;
  color: #fff;
}

.content .buttons .right a {
  border: 1px solid #64748b;
  color: #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right a:hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons .right button {
  background: #5855d6;
  color: #f6f8fc;
  margin-left: 10px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons .right button:hover {
  background-color: #4947b0;
  color: #fff;
}

.input-select {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  text-indent: 5px;
}
.content .box form .desc.desc-min-width {
  width: 110px !important;
  flex: inherit;
}
</style>

<template>
  <div>
    <!-- <shimmer-loading v-if="false" :num="10"></shimmer-loading> -->
    <div class="row">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2
                  style="margin: 10px 10px 5px 10px"
                  v-if="category == 'highest'"
                >
                  {{ $t("highest_recurring_billings_per_customer") }}
                </h2>
                <h2 style="margin: 10px 10px 5px 10px" v-else>
                  {{ $t("lowest_recurring_billings_per_customer") }}
                </h2>

                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="vat_included"
                      type="radio"
                      :value="true"
                      name="vat"
                      id="vat-yes"
                    />
                    <label for="vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="vat_included"
                      type="radio"
                      :value="false"
                      name="vat"
                      id="vat-no"
                    />
                    <label for="vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>

                <div class="desc">
                  <label>{{ $t("category") }}</label>
                  <div class="radio">
                    <input
                      v-model="category"
                      type="radio"
                      value="highest"
                      name="category"
                      id="highest"
                    />
                    <label for="highest">{{ $t("highest") }}</label>

                    <input
                      v-model="category"
                      type="radio"
                      value="lowest"
                      name="category"
                      id="lowest"
                    />
                    <label for="lowest">{{ $t("lowest") }}</label>
                  </div>
                </div>

                <div style="position: relative">
                  <span
                    @click="
                      showHighestRecurringBillingInfo =
                        !showHighestRecurringBillingInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showHighestRecurringBillingInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.highestRecurringBilling.eng
                          : infoMessages.highestRecurringBilling.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>
              <div v-else>
                <div v-if="category == 'highest'">
                  <apexchart
                    v-if="vat_included"
                    type="bar"
                    :options="highestRecurringlyBilledCustomersGraph.options"
                    :series="
                      highestRecurringlyBilledCustomersGraph.highest_vat_series
                    "
                  ></apexchart>
                  <apexchart
                    v-else
                    type="bar"
                    :options="highestRecurringlyBilledCustomersGraph.options"
                    :series="
                      highestRecurringlyBilledCustomersGraph.highest_ex_vat_series
                    "
                  ></apexchart>
                </div>
                <div v-else>
                  <apexchart
                    v-if="vat_included"
                    type="bar"
                    :options="highestRecurringlyBilledCustomersGraph.options"
                    :series="
                      highestRecurringlyBilledCustomersGraph.lowest_vat_series
                    "
                  ></apexchart>
                  <apexchart
                    v-else
                    type="bar"
                    :options="highestRecurringlyBilledCustomersGraph.options"
                    :series="
                      highestRecurringlyBilledCustomersGraph.lowest_ex_vat_series
                    "
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2
                  v-if="salesFor == 'customer'"
                  style="margin: 10px 10px 5px 10px"
                >
                  {{ $t("sales_for_customer") }} "{{
                    chosenCustomer.name || "..."
                  }}"
                </h2>
                <h2 v-else style="margin: 10px 10px 5px 10px">
                  {{ $t("sales_for_segment") }} "{{
                    chosenSegment.name || "..."
                  }}"
                </h2>
                <div
                  class="d-flex flex-row align-items-end justify-content-start"
                >
                  <div
                    style="position: relative; margin-right: 10px"
                    id="sales-for-selector"
                  >
                    <span
                      style="font-size: 30px; cursor: pointer"
                      @click="
                        showSalesCategorySelector = !showSalesCategorySelector
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </span>
                    <div
                      v-if="showSalesCategorySelector"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseSalesCategory('customer')"
                        >
                          {{ $t("customer") }}
                        </li>
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          @click="chooseSalesCategory('segment')"
                        >
                          {{ $t("segment") }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-if="salesFor == 'customer'" style="position: relative">
                    <label style="font-size: 11px" for="search-customer"
                      >{{ $t("search") + " " + $t("customer") }}
                    </label>
                    <input
                      @input="searchCustomer"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="customerKey"
                    />
                    <div
                      v-if="showCustomerSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in customers.slice(0, 5)"
                          :key="item.id"
                          @click="chooseCustomer(item)"
                        >
                          {{ item.customer_number + " - " + item.name }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div v-else style="position: relative">
                    <label style="font-size: 11px" for="search-segment">{{
                      $t("search") + " " + $t("customer") + " " + $t("segment")
                    }}</label>
                    <input
                      @input="searchSegment"
                      class="mid-2 filter-input"
                      type="text"
                      v-model="segmentKey"
                    />
                    <div
                      v-if="showSegmentSearch"
                      style="z-index: 9999; width: 150px"
                      class="position-absolute bg-white rounded shadow-md border p-2 amount-dropdown"
                    >
                      <ul
                        style="
                          padding-left: 0.3rem !important;
                          list-style-type: none;
                        "
                      >
                        <li
                          class="border-bottom mb-1 cursor-pointer text-xs"
                          v-for="item in segments.slice(0, 5)"
                          :key="item.id"
                          @click="chooseSegment(item)"
                        >
                          {{ item.name + " - " + item.id }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="desc">
                  <label>{{ $t("vat") }}</label>
                  <div class="radio">
                    <input
                      v-model="sales_vat_included"
                      type="radio"
                      :value="true"
                      name="sales_vat"
                      id="sales_vat-yes"
                    />
                    <label for="sales_vat-yes">{{ $t("incl") }}</label>
                    <input
                      v-model="sales_vat_included"
                      type="radio"
                      :value="false"
                      name="sales_vat"
                      id="sales_vat-no"
                    />
                    <label for="sales_vat-no">{{ $t("excl") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showSalesForCustomerInfo = !showSalesForCustomerInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showSalesForCustomerInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.salesForCustomer.eng
                          : infoMessages.salesForCustomer.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <shimmer-loading
                v-if="loading || salesLoading"
                :num="6"
              ></shimmer-loading>
              <div v-else>
                <div v-if="salesFor == 'customer'">
                  <apexchart
                    v-if="sales_vat_included"
                    type="line"
                    :options="salesByCustomerGraph.options"
                    :series="salesByCustomerGraph.customer_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesByCustomerGraph.options"
                    :series="salesByCustomerGraph.customer_ex_vat_series"
                  ></apexchart>
                </div>
                <div v-else>
                  <apexchart
                    v-if="sales_vat_included"
                    type="line"
                    :options="salesByCustomerGraph.options"
                    :series="salesByCustomerGraph.segment_vat_series"
                  ></apexchart>
                  <apexchart
                    v-else
                    type="line"
                    :options="salesByCustomerGraph.options"
                    :series="salesByCustomerGraph.segment_ex_vat_series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="border-bottom: 1px solid #cbd4e199; padding-bottom: 10px"
              >
                <h2 style="margin: 10px 10px 5px 10px; padding-bottom: 15px">
                  {{ $t("late_paying_customers") }}
                </h2>
                <div style="position: relative">
                  <span
                    @click="
                      showLatePayingCustomersInfo = !showLatePayingCustomersInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showLatePayingCustomersInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.latePayingCustomers.eng
                          : infoMessages.latePayingCustomers.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else
                type="bar"
                :options="latePayingCustomersGraph.options"
                :series="latePayingCustomersGraph.series"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="
                  padding: 5px 1rem 1rem;
                  border-bottom: 1px solid #cbd4e199;
                  padding-bottom: 10px;
                "
              >
                <h2
                  style="margin: 10px 10px 5px 10px"
                  v-if="ratio_category === 'best'"
                >
                  {{ $t("best") + " " + $t("benefit_cost_ratio") }}
                </h2>
                <h2 style="margin: 10px 10px 5px 10px" v-else>
                  {{ $t("worst") + " " + $t("benefit_cost_ratio") }}
                </h2>
                <div class="desc">
                  <label>{{ $t("category") }}</label>
                  <div class="radio">
                    <input
                      v-model="ratio_category"
                      type="radio"
                      value="best"
                      name="ratio_category"
                      id="best"
                    />
                    <label for="best">{{ $t("best") }}</label>
                    <input
                      v-model="ratio_category"
                      type="radio"
                      value="worst"
                      name="ratio_category"
                      id="worst"
                    />
                    <label for="worst">{{ $t("worst") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showBenefitCostRatioInfo = !showBenefitCostRatioInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showBenefitCostRatioInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.benefitCostRatio.eng
                          : infoMessages.benefitCostRatio.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else-if="ratio_category === 'best'"
                type="bar"
                :options="benefitCostRatioGraph.options"
                :series="benefitCostRatioGraph.series_best"
              ></apexchart>
              <apexchart
                v-else
                type="bar"
                :options="benefitCostRatioGraph.options"
                :series="benefitCostRatioGraph.series_worst"
              ></apexchart>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="box">
              <div
                class="w-100 d-flex flex-row align-items-end justify-content-between"
                style="
                  padding: 5px 1rem 1rem;
                  border-bottom: 1px solid #cbd4e199;
                  padding-bottom: 10px;
                "
              >
                <h2
                  style="margin: 10px 10px 5px 10px"
                  v-if="history_category === 'most'"
                >
                  {{ $t("historically_most_late_paying_customers") }}
                </h2>
                <h2 style="margin: 10px 10px 5px 10px" v-else>
                  {{ $t("historically_least_late_paying_customers") }}
                </h2>
                <div class="desc">
                  <label>{{ $t("category") }}</label>
                  <div class="radio">
                    <input
                      v-model="history_category"
                      type="radio"
                      value="most"
                      name="history_category"
                      id="most"
                    />
                    <label for="most">{{ $t("most") }}</label>
                    <input
                      v-model="history_category"
                      type="radio"
                      value="least"
                      name="history_category"
                      id="least"
                    />
                    <label for="least">{{ $t("least") }}</label>
                  </div>
                </div>
                <div style="position: relative">
                  <span
                    @click="
                      showHistoricallyLatePayingCustomersInfo =
                        !showHistoricallyLatePayingCustomersInfo
                    "
                    style="color: #333269; cursor: pointer"
                    class="fas fa-question-circle"
                  ></span>

                  <div
                    v-if="showHistoricallyLatePayingCustomersInfo"
                    style="
                      z-index: 9999;
                      width: 150px;
                      font-size: 11px;
                      right: 0;
                    "
                    class="position-absolute bg-white rounded shadow-md border p-2"
                  >
                    <p>
                      {{
                        locale == "en"
                          ? infoMessages.historicallyLatePayingCustomers.eng
                          : infoMessages.historicallyLatePayingCustomers.swe
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <shimmer-loading v-if="loading" :num="6"></shimmer-loading>

              <apexchart
                v-else-if="history_category === 'most'"
                type="bar"
                :options="latePayingHistoryGraph.options"
                :series="latePayingHistoryGraph.series_most"
              ></apexchart>
              <apexchart
                v-else
                type="bar"
                :options="latePayingHistoryGraph.options"
                :series="latePayingHistoryGraph.series_least"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShimmerLoading from "../reusableComponents/ShimmerLoading";
import debounce from "lodash.debounce";

export default {
  name: "ContractStatistics",
  components: {
    ShimmerLoading,
  },
  // delete later
  async created() {
    this.getHighestRecurringlyBilledCustomersStatistics();
    this.getLatePayingCustomersStatistics();
    this.getBenefitCostRatioStatistics();
    this.getLatePayingHistoryStatistics();
  },

  data() {
    return {
      showHighestRecurringBillingInfo: false,

      showSalesForCustomerInfo: false,

      showLatePayingCustomersInfo: false,

      showBenefitCostRatioInfo: false,

      showHistoricallyLatePayingCustomersInfo: false,

      infoMessages: {
        highestRecurringBilling: {},
        salesForCustomer: {},
        latePayingCustomers: {},
        benefitCostRatio: {},
        historicallyLatePayingCustomers: {},
      },

      salesCategories: {
        customer_vat_categories: [],
        customer_ex_vat_categories: [],
        segment_vat_categories: [],
        segment_ex_vat_categories: [],
      },

      historyCategories: {
        most_late_categories: [],
        least_late_categories: [],
      },

      history_category: "most",

      salesFor: "customer",

      showSalesCategorySelector: false,

      chosenCustomer: {},

      chosenSegment: {},

      customerSegment: "",

      customers: [],

      segments: [],

      customerKey: "",
      segmentKey: "",

      showCustomerSearch: false,

      showSegmentSearch: false,

      loading: false,

      salesLoading: false,

      category: "highest",

      ratio_category: "best",

      chartCategories: {},

      ratioCategories: {},

      vat_included: true,

      sales_vat_included: true,

      recurringRevenueData: {},

      highestRecurringlyBilledCustomersGraph: {
        options: {
          chart: {
            id: "highest-recurringly-billed-customers-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("customer"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("amount_billed"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "55%",
            },
          },

          colors: ["#8236FF"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
            // color
          },

          dataLabels: {
            enabled: false,
          },
        },
        highest_vat_series: [
          {
            name: this.$t("billed"),
            type: "bar",
            data: [],
          },
        ],

        highest_ex_vat_series: [
          {
            name: this.$t("billed"),
            type: "bar",
            data: [],
          },
        ],

        lowest_vat_series: [
          {
            name: this.$t("billed"),
            type: "bar",
            data: [],
          },
        ],

        lowest_ex_vat_series: [
          {
            name: this.$t("billed"),
            type: "bar",
            data: [],
          },
        ],
      },

      latePayingCustomersGraph: {
        options: {
          chart: {
            id: "late-paying-customers-graph",
          },

          xaxis: {
            categories: [],
            title: {
              text: this.$t("customer"),
            },
          },

          yaxis: {
            title: {
              text: `${this.$t("late")} ${this.$t("days")}`,
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "55%",
            },
          },
          colors: ["#FF8A53"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
          },

          dataLabels: {
            enabled: false,
          },
        },

        series: [
          {
            name: `${this.$t("late")} ${this.$t("days")}`,
            type: "bar",
            data: [],
          },
        ],
      },

      benefitCostRatioGraph: {
        options: {
          chart: {
            id: "benefit-cost-ratio-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("customer"),
            },
          },

          yaxis: {
            title: {
              text: `${this.$t("benefit")} / ${this.$t("cost")}`,
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
            },
          },
          colors: ["#3BFF9B"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
          },
          dataLabels: {
            enabled: false,
          },
        },
        series_best: [
          {
            name: this.$t("benefit_cost_ratio"),
            type: "bar",
            data: [],
          },
        ],

        series_worst: [
          {
            name: this.$t("benefit_cost_ratio"),
            type: "bar",
            data: [],
          },
        ],
      },

      salesByCustomerGraph: {
        options: {
          chart: {
            id: "sales-by-customer-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("month"),
            },
          },

          yaxis: {
            title: {
              text: this.$t("sales"),
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          stroke: {
            curve: "smooth",
            width: 1.5,
            lineCap: "round",
          },

          markers: {
            size: 5,
          },

          colors: ["#FE3E7F"],

          dataLabels: {
            enabled: false,
          },
        },
        customer_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        customer_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        segment_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],

        segment_ex_vat_series: [
          {
            name: this.$t("sales"),
            type: "line",
            data: [],
          },
        ],
      },

      latePayingHistoryGraph: {
        options: {
          chart: {
            id: "late-paying-history-graph",
          },
          xaxis: {
            categories: [],
            title: {
              text: this.$t("customer"),
            },
          },

          yaxis: {
            title: {
              text: `${this.$t("total")} ${this.$t("invoices")}`,
            },
            labels: {
              formatter: function (val) {
                return val.toLocaleString();
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 10,
            },
          },
          colors: ["#FF0000"],

          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0.25,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.8,
              stops: [0, 100],
            },
            opacity: 0.8,
          },
          dataLabels: {
            enabled: false,
          },
        },
        series_most: [
          {
            name: `${this.$t("total")} ${this.$t("invoices")}`,
            type: "bar",
            data: [],
          },
        ],

        series_least: [
          {
            name: `${this.$t("total")} ${this.$t("invoices")}`,
            type: "bar",
            data: [],
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      locale: "locale/GET_LOCALE",
    }),
  },

  mounted() {
    this.addFontAwesomeToHead();
  },

  methods: {
    async getHighestRecurringlyBilledCustomersStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getHighestRecurringlyBilledCustomersStatistics"
      );

      this.infoMessages.highestRecurringBilling.eng = res.data.eng_info;
      this.infoMessages.highestRecurringBilling.swe = res.data.swe_info;

      this.loading = false;
      this.highestRecurringlyBilledCustomers = res.data.data;
      const data = this.highestRecurringlyBilledCustomers;
      const highest_ex_vat_categories = [];
      const highest_ex_vat_series = [];

      const highest_vat_categories = [];
      const highest_vat_series = [];

      const lowest_ex_vat_categories = [];
      const lowest_vat_categories = [];

      const lowest_ex_vat_series = [];
      const lowest_vat_series = [];

      for (const key in data.highest) {
        if (key === "ex_vat_sorted") {
          data.highest[key].forEach((el) => {
            highest_ex_vat_categories.push(el.customer_name);
            highest_ex_vat_series.push(
              this.fixDecimals(el.total_monthly_average_ex_vat)
            );
          });
        } else {
          data.highest[key].forEach((el) => {
            highest_vat_series.push(
              this.fixDecimals(el.total_monthly_average_inc_vat)
            );
            highest_vat_categories.push(el.customer_name);
          });
        }
      }
      for (const key in data.lowest) {
        if (key === "ex_vat_sorted") {
          data.lowest[key].forEach((el) => {
            lowest_ex_vat_categories.push(el.customer_name);
            lowest_ex_vat_series.push(
              this.fixDecimals(el.total_monthly_average_ex_vat)
            );
          });
        } else {
          data.lowest[key].forEach((el) => {
            lowest_vat_series.push(
              this.fixDecimals(el.total_monthly_average_inc_vat)
            );
            lowest_vat_categories.push(el.customer_name);
          });
        }
      }

      this.chartCategories = {
        highest_ex_vat_categories,
        highest_vat_categories,
        lowest_ex_vat_categories,
        lowest_vat_categories,
      };

      this.highestRecurringlyBilledCustomersGraph.options = {
        ...this.highestRecurringlyBilledCustomersGraph.options,
        xaxis: {
          ...this.highestRecurringlyBilledCustomersGraph.options.xaxis,
          type: "category",
          categories: highest_vat_categories,
        },
      };
      this.highestRecurringlyBilledCustomersGraph.highest_vat_series[0].data =
        highest_vat_series;

      this.highestRecurringlyBilledCustomersGraph.highest_ex_vat_series[0].data =
        highest_ex_vat_series;

      this.highestRecurringlyBilledCustomersGraph.lowest_vat_series[0].data =
        lowest_vat_series;

      this.highestRecurringlyBilledCustomersGraph.lowest_ex_vat_series[0].data =
        lowest_ex_vat_series;
    },

    async getLatePayingCustomersStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getLatePayingCustomersStatistics"
      );
      this.loading = false;
      this.infoMessages.latePayingCustomers.eng = res.data.eng_info;
      this.infoMessages.latePayingCustomers.swe = res.data.swe_info;
      const data = res.data.data;
      const categories = [];
      const series = [];
      data.forEach((el) => {
        categories.push(el.customer_name);
        series.push(el.average_lateness);
      });
      this.latePayingCustomersGraph.options = {
        ...this.latePayingCustomersGraph.options,
        xaxis: {
          ...this.latePayingCustomersGraph.options.xaxis,
          type: "category",
          categories: categories,
        },
      };
      this.latePayingCustomersGraph.series[0].data = series;
    },

    async getBenefitCostRatioStatistics() {
      this.loading = true;
      const res = await this.$store.dispatch(
        "clients/getBenefitCostRatioStatistics"
      );
      this.loading = false;
      this.infoMessages.benefitCostRatio.eng = res.data.eng_info;
      this.infoMessages.benefitCostRatio.swe = res.data.swe_info;
      const data = res.data.data;
      const categories_best = [];
      const categories_worst = [];
      const series_best = [];
      const series_worst = [];

      for (const key in data) {
        data[key].forEach((el) => {
          if (key === "best") {
            categories_best.push(el.customer_name);
            series_best.push(el.benefit_cost_ratio);
          } else {
            categories_worst.push(el.customer_name);
            series_worst.push(el.benefit_cost_ratio);
          }
        }),
          (this.benefitCostRatioGraph.options = {
            ...this.benefitCostRatioGraph.options,
            xaxis: {
              ...this.benefitCostRatioGraph.options.xaxis,
              type: "category",
              categories: categories_best,
            },
          });
        this.benefitCostRatioGraph.series_best[0].data = series_best;
        this.benefitCostRatioGraph.series_worst[0].data = series_worst;
        this.ratioCategories.best = categories_best;
        this.ratioCategories.worst = categories_worst;
      }
    },

    async getSalesStatistics(type, data) {
      const customer_ex_vat_categories = [];
      const customer_ex_vat_series = [];

      const customer_vat_categories = [];
      const customer_vat_series = [];

      const segment_ex_vat_categories = [];
      const segment_ex_vat_series = [];

      const segment_vat_categories = [];
      const segment_vat_series = [];

      if (type == "customer") {
        // if data is not an object, show error message and return
        if (typeof data !== "object") {
          this.$services.helpers.notification(
            "No prior sales for this customer",
            "error"
          );
          return;
        }
        const customerData = {
          ...data.customer_number[Object.keys(data.customer_number)[0]],
        };
        for (const key in customerData) {
          customer_vat_categories.push(key);
          customer_vat_series.push(customerData[key].total_amount_inc_vat);
          customer_ex_vat_categories.push(key);
          customer_ex_vat_series.push(customerData[key].total_amount_ex_vat);
        }

        this.salesCategories.customer_vat_categories = customer_vat_categories;
        this.salesCategories.customer_ex_vat_categories =
          customer_ex_vat_categories;

        this.salesByCustomerGraph.options = {
          ...this.salesByCustomerGraph.options,
          xaxis: {
            ...this.salesByCustomerGraph.options.xaxis,
            type: "category",
            categories: customer_vat_categories,
          },
        };

        this.salesByCustomerGraph.customer_vat_series[0].data =
          customer_vat_series;
        this.salesByCustomerGraph.customer_ex_vat_series[0].data =
          customer_ex_vat_series;
      } else {
        const segmentData = { ...data.customer_segment };
        for (const key in segmentData) {
          segment_vat_categories.push(key);
          segment_ex_vat_categories.push(key);
          segment_vat_series.push(
            this.fixDecimals(parseFloat(segmentData[key].total_inc_vat))
          );
          segment_ex_vat_series.push(
            this.fixDecimals(parseFloat(segmentData[key].total_ex_vat))
          );
        }

        this.salesCategories.segment_vat_categories = segment_vat_categories;
        this.salesCategories.segment_ex_vat_categories =
          segment_ex_vat_categories;

        this.salesByCustomerGraph.options = {
          ...this.salesByCustomerGraph.options,
          xaxis: {
            ...this.salesByCustomerGraph.options.xaxis,
            type: "category",
            categories: segment_vat_categories,
          },
        };
        this.salesByCustomerGraph.segment_vat_series[0].data =
          segment_vat_series;
        this.salesByCustomerGraph.segment_ex_vat_series[0].data =
          segment_ex_vat_series;
      }
    },

    async getLatePayingHistoryStatistics() {
      this.loading = true;
      const data = this.$store
        .dispatch("clients/getLatePayingHistoryStatistics")
        .then((res) => {
          this.loading = false;
          this.infoMessages.historicallyLatePayingCustomers.eng =
            res.data.eng_info;
          this.infoMessages.historicallyLatePayingCustomers.swe =
            res.data.swe_info;
          const data = res.data.data;

          const most_late_categories = [];
          const most_late_series = [];
          const least_late_categories = [];
          const least_late_series = [];

          for (const key in data) {
            if (key == "Most") {
              for (const el of data[key]) {
                most_late_categories.push(el.customer_number);
                most_late_series.push(el.total_invoices);
              }
            } else {
              for (const el of data[key]) {
                least_late_categories.push(el.customer_number);
                least_late_series.push(el.total_invoices);
              }
            }
          }
          this.historyCategories.most_late_categories = most_late_categories;
          this.historyCategories.least_late_categories = least_late_categories;
          this.latePayingHistoryGraph.options = {
            ...this.latePayingHistoryGraph.options,
            xaxis: {
              ...this.latePayingHistoryGraph.options.xaxis,
              type: "category",
              categories: most_late_categories,
            },
          };
          this.latePayingHistoryGraph.series_most[0].data = most_late_series;
          this.latePayingHistoryGraph.series_least[0].data = least_late_series;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    fixDecimals(value) {
      return value.toFixed(2);
    },

    chooseCustomer(customer) {
      if (customer == {}) {
        return;
      }
      this.showCustomerSearch = false;
      this.salesLoading = true;
      this.customerKey = customer.name;
      const payload = {
        type: "customer",
        customer_number: customer.customer_number,
      };
      this.chosenCustomer = customer;
      this.$store
        .dispatch("clients/getHistoricSalesStatistics", payload)
        .then((res) => {
          this.infoMessages.salesForCustomer.eng = res.data.eng_info;
          this.infoMessages.salesForCustomer.swe = res.data.swe_info;
          this.getSalesStatistics("customer", res.data.data);
          this.salesLoading = false;
        })
        .catch((err) => {
          this.salesLoading = false;
        });
    },

    chooseSegment(segment) {
      if (segment == {}) {
        return;
      }
      this.showSegmentSearch = false;
      this.salesLoading = true;
      this.segmentKey = segment.name;
      const payload = {
        type: "segment",
        segment_id: segment.id,
      };
      this.chosenSegment = segment;
      this.$store
        .dispatch("clients/getHistoricSalesStatistics", payload)
        .then((res) => {
          this.getSalesStatistics("segment", res.data.data);
          this.salesLoading = false;
        })
        .catch((err) => {
          this.salesLoading = false;
        });
    },

    chooseSalesCategory(salesCategory) {
      this.showSalesCategorySelector = false;
      this.salesFor = salesCategory;
      if (salesCategory === "customer") {
        this.chooseCustomer(this.chosenCustomer);
      } else {
        this.chooseSegment(this.chosenSegment);
      }
    },

    searchCustomer: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterCustomers(event.target.value);
      } else {
        this.showCustomerSearch = false;
      }
    }, 500),

    searchSegment: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSegments(event.target.value);
      } else {
        this.showSegmentSearch = false;
      }
    }, 500),

    filterCustomers(q) {
      let payload = {
        URL: this.$services.endpoints.CUSTOMER_ENDPOINT + `?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showCustomerSearch = true;
          this.customers = res.data.data;
        })
        .catch((err) => {
          //left blank
        });
    },

    filterSegments(q) {
      let payload = {
        URL:
          this.$services.endpoints.SEGMENT_ENDPOINT + `customers?search=${q}`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          this.showSegmentSearch = true;
          this.segments = res.data.results;
        })
        .catch((err) => {
          // left blank
        });
    },

    addFontAwesomeToHead() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://kit.fontawesome.com/f179d79512.js"
      );
      document.head.appendChild(recaptchaScript);
    },
  },

  watch: {
    vat_included(val) {
      if (val) {
        this.highestRecurringlyBilledCustomersGraph.options = {
          ...this.highestRecurringlyBilledCustomersGraph.options,
          xaxis: {
            ...this.highestRecurringlyBilledCustomersGraph.options.xaxis,
            type: "category",
            categories:
              this.category == "highest"
                ? this.chartCategories.highest_vat_categories
                : this.chartCategories.lowest_vat_categories,
          },
        };
      } else {
        this.highestRecurringlyBilledCustomersGraph.options = {
          ...this.highestRecurringlyBilledCustomersGraph.options,
          xaxis: {
            ...this.highestRecurringlyBilledCustomersGraph.options.xaxis,
            type: "category",
            categories:
              this.category == "highest"
                ? this.chartCategories.highest_ex_vat_categories
                : this.chartCategories.lowest_ex_vat_categories,
          },
        };
      }
    },

    category(val) {
      if (val == "highest") {
        this.highestRecurringlyBilledCustomersGraph.options = {
          ...this.highestRecurringlyBilledCustomersGraph.options,
          xaxis: {
            ...this.highestRecurringlyBilledCustomersGraph.options.xaxis,
            type: "category",
            categories: this.vat_included
              ? this.chartCategories.highest_vat_categories
              : this.chartCategories.highest_ex_vat_categories,
          },
        };
      } else {
        this.highestRecurringlyBilledCustomersGraph.options = {
          ...this.highestRecurringlyBilledCustomersGraph.options,
          xaxis: {
            ...this.highestRecurringlyBilledCustomersGraph.options.xaxis,
            type: "category",
            categories: this.vat_included
              ? this.chartCategories.lowest_vat_categories
              : this.chartCategories.lowest_ex_vat_categories,
          },
        };
      }
    },

    ratio_category(val) {
      if (val == "best") {
        this.benefitCostRatioGraph.options = {
          ...this.benefitCostRatioGraph.options,
          xaxis: {
            ...this.benefitCostRatioGraph.options.xaxis,
            type: "category",
            categories: this.ratioCategories.best,
          },
        };
      } else {
        this.benefitCostRatioGraph.options = {
          ...this.benefitCostRatioGraph.options,
          xaxis: {
            ...this.benefitCostRatioGraph.options.xaxis,
            type: "category",
            categories: this.ratioCategories.worst,
          },
        };
      }
    },

    sales_vat_included(val) {
      if (val) {
        this.salesByCustomerGraph.options = {
          ...this.salesByCustomerGraph.options,
          xaxis: {
            ...this.salesByCustomerGraph.options.xaxis,
            type: "category",
            categories:
              this.salesFor == "customer"
                ? this.salesCategories.customer_vat_categories
                : this.salesCategories.segment_vat_categories,
          },
        };
      } else {
        this.salesByCustomerGraph.options = {
          ...this.salesByCustomerGraph.options,
          xaxis: {
            ...this.salesByCustomerGraph.options.xaxis,
            type: "category",
            categories:
              this.salesFor == "customer"
                ? this.salesCategories.customer_ex_vat_categories
                : this.salesCategories.segment_ex_vat_categories,
          },
        };
      }
    },

    history_category(val) {
      if (val == "most") {
        this.latePayingHistoryGraph.options = {
          ...this.latePayingHistoryGraph.options,
          colors: ["#FF0000"],
          xaxis: {
            ...this.latePayingHistoryGraph.options.xaxis,
            type: "category",
            categories: this.historyCategories.most_late_categories,
          },
        };
      } else {
        this.latePayingHistoryGraph.options = {
          ...this.latePayingHistoryGraph.options,
          colors: ["#E6FF00"],
          xaxis: {
            ...this.latePayingHistoryGraph.options.xaxis,
            type: "category",
            categories: this.historyCategories.least_late_categories,
          },
        };
      }
    },
  },
};
</script>

<style scoped>
.desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;
  /* line-height: 14px; */
  color: #000000;
  width: min-content;
}

.desc label {
  margin-bottom: 2px;
  font-size: 11px;
}

.desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

.desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.desc .radio input {
  display: none;
}

.desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.desc .radio label:first-of-type {
  margin-right: 10px;
}

.box {
  background: #fffdfd;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
}

.box h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e1566;
  margin-bottom: 0;
}

.box h6 {
  font-weight: 400;
  font-size: 14px;
  color: #0e1566;
}

.amount-dropdown {
  right: 0;
}
#sales-for-selector > .amount-dropdown {
  right: auto;
  left: 0;
}
.amount-dropdown .text-xs {
  font-size: 11px !important;
  line-height: 120%;
  padding: 2px 0;
}
.amount-dropdown ul {
  margin-bottom: 0 !important;
}
.amount-dropdown ul li:last-child {
  border: 0 !important;
}

input.filter-input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}
</style>
